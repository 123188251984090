import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Avatar, Typography, Button } from '@material-ui/core';
import Menu from '../ui/Menu';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from '../Layout/header/styles';
import { useGetMenuNotifications } from '../Layout/header/useGetMenuNotifications';
import Badge from '@material-ui/core/Badge';
import NotificationMenu from 'components/Notification/NotificationsMenu';
import { useProvider } from 'pages/PatientNotificationPage/Provider';
import { ReactComponent as UserIcon } from 'assets/userDropDown/userIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/userDropDown/logoutIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/userDropDown/settingsIcon.svg';
import { ReactComponent as NewEggmedLogo } from 'assets/NewEggmedLogo.svg';
import { ReactComponent as NotificationsActiveOutlinedIcon } from 'assets/NewNotif.svg';
import AddEventPage from 'pages/PatientAppointmentPage/AddEventPage';
import { useProvider as useSchedule } from 'pages/SchedulePage/state/SchedulePageProvider';
import { ReactComponent as BlueCalendar } from 'assets/BlueCalendar.svg';
import Text from 'Text';
import clsx from 'clsx';
import Skeleton from '../ui/Skeleton';
import { useTranslation } from 'react-i18next';

interface IPatientProps {
  firstname: string;
  lastname: string;
  middlename: string;
  email: string;
  picture: string;
  loading: boolean;
}

const Header = ({
  firstname,
  lastname,
  middlename,
  email,
  picture,
  loading,
}: IPatientProps) => {
  const {
    loadingAddAppointment,
    loadingEditAppointment,
    handleAddAppointment,
    handleUpdateAppointment,
    currentEvent,
    handleCloseEditMode,
    editMode,
    handleOpenAddModal,
  } = useSchedule();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [userDropDownMenu, setUserDropDownMenu] =
    React.useState<null | HTMLElement>(null);
  const [notificationMenuAnchor, setNotificationMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const [state, setState] = React.useState({
    left: false,
  });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserDropDownMenu(event.currentTarget);
  };
  const handleOpenNotifMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setUserDropDownMenu(null);
    setNotificationMenuAnchor(null);
  };
  const isMenuOpen = Boolean(userDropDownMenu);
  const isNotifMenuOpen = Boolean(notificationMenuAnchor);
  const { patient, user } = useAuth();
  const userId = user?._id;
  const MenuElementsData = [
    {
      name: t('Profile'),
      avatar: <UserIcon />,
      onClick: () => {
        history.push(`/patient/${patient?._id}`);
      },
      route: `/patient/${patient?._id}`,
    },
    {
      name: t('Settings'),
      avatar: <SettingsIcon />,
      onClick: () => {
        history.push('/patient/settings');
      },
      route: '/patient/settings',
    },
    {
      name: t('Log out'),
      avatar: <LogoutIcon />,
      onClick: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('patient');
        window.location.reload();
      },
    },
  ];
  const { start, limit } = useProvider();
  const { menuNotifElements, count, unreadNotifications } =
    useGetMenuNotifications(userId, { start, limit });
  const toggleDrawer = useCallback(
    (anchor: string, open: boolean) => (event: any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    },
    [state]
  );
  return (
    <Box display="flex" position="relative" className={classes.root}>
      {/* <Box className={classes.root}>
        <Typography className={classes.locationTitle}>{title}</Typography>

        <SideBarDrawer toggleDrawer={toggleDrawer} state={state}>
          <SideBar sideRespons="notNormal" />
        </SideBarDrawer>
      </Box> */}
      <Box display="flex" alignItems="center">
        <NewEggmedLogo
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/doctor')}
        />
        <Box
          display="flex"
          alignItems="center"
          ml={7}
          mr={2}
          // width={smallerSize ? '25vw' : '30vw'}
        ></Box>
      </Box>

      <Box className={classes.rightHeader}>
        <Box mr={2}>
          <Button
            className={classes.booksess}
            onClick={() => handleOpenAddModal()}
          >
            <BlueCalendar className={classes.blueCalendar} />
            <Text
              style={{ fontSize: '16px', fontWeight: 500 }}
              i18nKey="bookSession"
            >
              Book session
            </Text>
          </Button>
        </Box>
        {count > 0 ? (
          <Box>
            <Badge
              badgeContent={count < 9 ? count : '9+'}
              color="error"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Box
                onClick={handleOpenNotifMenu}
                className={classes.largeScreensNotif}
              >
                <NotificationsActiveOutlinedIcon
                  className={classes.iconsNotif}
                />
              </Box>
            </Badge>
          </Box>
        ) : (
          <>
            <Box
              onClick={handleOpenNotifMenu}
              className={classes.largeScreensNotif}
            >
              <NotificationsActiveOutlinedIcon className={classes.iconsNotif} />
            </Box>
          </>
        )}

        <Box onClick={handleClick}>
          <Skeleton
            loading={!!!firstname}
            type="circle"
            width="2.5rem"
            height="2.5rem"
          >
            <Avatar
              style={{ marginLeft: '25px' }}
              src={picture}
              className={clsx(
                classes.iconPatient
                // userDropDownMenu && classes.picOutline
              )}
            >
              {`${firstname && firstname[0]}${lastname && lastname[0]}`}
            </Avatar>
          </Skeleton>
        </Box>

        <Menu
          anchorEl={userDropDownMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          handleMenuClose={handleMenuClose}
          MenuElements={MenuElementsData}
          className={classes.menu}
          style={{ marginTop: '8px' }}
        />
        <NotificationMenu
          anchorEl={notificationMenuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isNotifMenuOpen}
          handleMenuClose={handleMenuClose}
          menuElements={menuNotifElements}
          unreadNotifications={unreadNotifications}
        />
      </Box>
      <AddEventPage
        closeModal={handleCloseEditMode}
        editMode={editMode}
        patientId={patient?._id}
        eventData={currentEvent}
        addEvent={editMode ? handleUpdateAppointment : handleAddAppointment}
        loadingAppointment={
          editMode ? loadingEditAppointment : loadingAddAppointment
        }
      />
    </Box>
  );
};

export default Header;
