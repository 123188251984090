import React, { ReactElement, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import useStyles from '../styles';
import ScrollBar from 'components/ui/ScrollBar';
import EditButtons from 'components/EditButtons';
import DraftjsInput from 'components/ui/Inputs/DraftjsInput';
import { Controller, FieldErrors, Control, Ref } from 'react-hook-form';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { options, langOptions, focusAreas } from '../constants';
import ErrorIcon from '@material-ui/icons/Error';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';

interface IAboutDoctorProps {
  register?: (ref?: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  handleClose: () => void;
  handleEditDoctor: () => void;
  loading?: boolean;
  supportVideo: boolean;
}

const AboutDoctor = ({
  register,
  control,
  handleClose,
  handleEditDoctor,
  errors,
  loading,
  supportVideo,
}: IAboutDoctorProps): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const commonProps = { register, control };
  return (
    <Box className={classes.scroll}>
      <ScrollBar sx={{ flex: 1 }}>
        <Box style={{ paddingInline: '2rem' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <FormControl style={{ width: '100%', marginTop: '25px' }}>
                <Text
                  i18nKey="aboutMe"
                  className={classes.label}
                  style={{ marginBottom: '10px' }}
                >
                  About me
                </Text>
                <DraftjsInput
                  name="about"
                  variant="filled"
                  data-cy="about"
                  className={classes.draft}
                  inputRef={register()}
                  control={control}
                  required
                  type="draftjs"
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold'],
                    },
                  }}
                />
                <Box alignSelf="flex-start" mb={1}>
                  {errors && errors.about && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.about?.message}
                    </span>
                  )}
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <FormControl style={{ width: '100%' }}>
                <Text
                  i18nKey="patientGroupsTypes"
                  className={classes.label}
                  style={{ marginBottom: '10px' }}
                >
                  Patient groups/types
                </Text>
                <Controller
                  name="patientGroup"
                  render={({ onChange, value }) => {
                    return (
                      <Symptoms
                        options={options}
                        values={value}
                        setValues={onChange}
                      />
                    );
                  }}
                  {...commonProps}
                />
                <Box alignSelf="flex-start" mb={1}>
                  {errors && errors.patientGroup && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.patientGroup?.message}
                    </span>
                  )}
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <FormControl style={{ width: '100%' }}>
                <Text
                  i18nKey="focusAreas"
                  className={classes.label}
                  style={{ marginBottom: '10px' }}
                >
                  Focus areas
                </Text>
                <Controller
                  name="focusArea"
                  render={({ onChange, value }) => {
                    return (
                      <Symptoms
                        options={focusAreas}
                        values={value}
                        setValues={onChange}
                      />
                    );
                  }}
                  {...commonProps}
                />
                <Box alignSelf="flex-start" mb={1}>
                  {errors && errors.focusArea && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.focusArea?.message}
                    </span>
                  )}
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <FormControl style={{ width: '100%', marginBlock: '25px' }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    inputRef={register}
                    name="supportVideo"
                    size="medium"
                    defaultChecked={supportVideo}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    style={{ backgroundColor: 'transparent' }}
                    disabled
                  />
                  <Text i18nKey="supportsVideoCalls" className={classes.label}>
                    Supports video calls
                  </Text>
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </ScrollBar>
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="0.5px solid #EAECF0"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <EditButtons
          handleOpen={handleClose}
          editable
          handleEdit={handleEditDoctor}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
export default AboutDoctor;
