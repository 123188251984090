export const fontFamilies = [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Noto Sans',
    'Raleway',
    'Source Sans',
    'Ubuntu',
    'PT Sans',
    'Merriweather',
    'Oswald',
    'Poppins',
    'Nunito',
    'Quicksand'
]