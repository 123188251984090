import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Conversation from 'pages/InboxPage/components/Conversation/Conversation';
import Message from 'pages/InboxPage/components/Message';
import Modals from 'pages/InboxPage/modals';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
import useStyles from 'pages/InboxPage/style';
import PatientInboxPage from './PatientInbox';

const InboxPage = ({ patient }: { patient?: boolean }): ReactElement => {
  const classes = useStyles();
  return (
    <InboxPageProvider>
      <PatientInboxPage />
    </InboxPageProvider>
  );
};

export default InboxPage;
