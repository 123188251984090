import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as ConflictIcon } from 'assets/conflicts/conflicts-icon.svg';
import { IConflict } from './types';
import { ConflictCard } from './ConflictCard';
import { convertObjTimes } from 'utils';

type IConflictComponent = {
  conflictedEvents: IConflict[];
  onClose?: () => void;
};

export const ConflictComponent = ({
  conflictedEvents,
  onClose,
}: IConflictComponent) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      gridGap={50}
      width="100%"
      position="relative"
      justifyContent="space-between"
    >
      <ConflictCard
        event={convertObjTimes(conflictedEvents[0])}
        onClose={onClose}
      />
      <ConflictCard
        event={convertObjTimes(conflictedEvents[1])}
        onClose={onClose}
      />
      <ConflictIcon className={classes.conflictIcon} />
    </Box>
  );
};
