import {
  Typography,
  Box,
  Grid,
  Button as Btn,
  Snackbar,
} from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import Checked from '../Payment/Checked';
import TimeRange from 'components/ui/Inputs/TimeRange';
import { ReactComponent as DeleteIcon } from 'assets/deleteIcon.svg';
import { ReactComponent as AddTime } from 'assets/AddTime.svg';
import BasicModal from 'components/ui/Modal';
import OverrideModal from './OverrideModal';
import Button from 'components/ui/Button';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import {
  transformDate,
  defaultArray,
  changeDateFormat,
  changeDateTime,
  checkDateValidity,
  getHoursArray,
  convertDateFormat,
  convertTimeZone,
  convertDatesToUtc,
} from '../utils';
import useUpdateDoctor from '@eggmed/graphql-client/operations/doctorOperations/useUpdateDoctor';
import { useToast } from 'hooks/useToast';
import { log } from 'config';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

const WorkingHour = () => {
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language || 'en';
  const dateFormat = userLanguage === 'fr' ? 'HH:mm' : 'hh:mm a';
  const { triggerSnack } = useSnackbar();
  const { doctor } = useAuth();
  const { data } = useGetDoctor({ id: doctor._id });
  const { editDoctor } = useUpdateDoctor(doctor._id);
  const availability = data?.doctor?.availability;
  const overrideData = data?.doctor?.override;
  const { __typename, ...rest } = availability || {};
  const [state, setState] = React.useState(transformDate(rest));
  const errorState = checkDateValidity(state);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [indexToDelete, setIndexToDelete] = React.useState(null);
  const [initialState, setInitialState] = React.useState(transformDate(rest));
  const [initialOverride, setInitialOverride] = React.useState(
    convertDateFormat(overrideData)
  );

  function handleOpen(index) {
    setOpenDelete(true);
    setIndexToDelete(index);
  }
  function handleCloseDelete() {
    setOpenDelete(false);
  }
  React.useEffect(() => {
    if (data?.doctor?.availability) {
      setState(transformDate(rest));
      setInitialState(transformDate(rest));
      setDays(defaultArray(rest));
    }
  }, [data?.doctor?.availability]);

  React.useEffect(() => {
    if (data?.doctor?.override) {
      setDateOverride(convertDateFormat(overrideData));
      setInitialOverride(convertDateFormat(overrideData));
    }
  }, [data?.doctor?.override]);
  async function handleSave() {
    try {
      if (!errorState) {
        await editDoctor({
          variables: {
            doctorID: doctor._id,
            DoctorInput: {
              availability: convertDatesToUtc(state),
              override: dateOverride,
            },
          },
        });
        triggerSnack();
      }
    } catch (error) {
      log(error);
    }
  }

  function handleAdd(day: string) {
    setState((previous) => {
      const dayArray = previous[day] || [];
      const lastItem =
        dayArray.length > 0 ? dayArray[dayArray.length - 1] : null;

      const startDate = lastItem
        ? new Date(
            new Date(
              new Date(lastItem.endDate).setHours(
                new Date(lastItem.endDate).getHours() + 1
              )
            ).setMinutes(0, 0, 0)
          )
        : new Date(
            new Date(new Date(new Date().setHours(9)).setMinutes(0, 0, 0))
          );

      const endDate = lastItem
        ? new Date(
            new Date(startDate).setHours(new Date(startDate).getHours() + 3)
          )
        : new Date(new Date(startDate).setHours(12));

      const newDayArray = [...dayArray, { startDate, endDate }];

      return {
        ...previous,
        [day]: newDayArray,
      };
    });
    setDays((prevDays) =>
      prevDays?.map((prevDay) =>
        prevDay.day === day ? { ...prevDay, checked: true } : prevDay
      )
    );
  }
  const [indexing, setIndex] = React.useState(0);

  function handleRemove(_index, day) {
    const copied = [...state[day]];
    const filterArr = copied.filter((el, index) => index !== _index);
    setState((prev) => ({ ...prev, [day]: filterArr }));
    if (filterArr?.length === 0) {
      setDays((prevDays) =>
        prevDays?.map((prevDay) =>
          prevDay.day === day ? { ...prevDay, checked: false } : prevDay
        )
      );
    }
  }
  const [days, setDays] = React.useState(defaultArray(rest));
  const classes = useStyles();
  const [dayName, setDayName] = React.useState('Monday');
  function handleChangeDate(
    name: 'endDate' | 'startDate',
    value: string | number
  ): void {
    setState((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange[dayName] = [...oldDateRange[dayName]];
      newDateRange[dayName][indexing][name] = value;
      return newDateRange;
    });
  }
  const handleCheckChange = (dayIndex) => {
    setDays((prevDays) => {
      const updatedDays = [...prevDays];
      updatedDays[dayIndex] = {
        ...updatedDays[dayIndex],
        checked: !updatedDays[dayIndex].checked,
      };
      return updatedDays;
    });
    if (days[dayIndex].checked === true) {
      setState((prev) => ({ ...prev, [days[dayIndex]?.day]: [] }));
    } else {
      handleAdd(days[dayIndex]?.day);
    }
  };
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  const [dateOverride, setDateOverride] = React.useState(
    convertDateFormat(overrideData)
  );
  const [isChanged, setIsChanged] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    new Date(new Date(new Date().setHours(9)).setMinutes(0, 0, 0))
  );
  const [endDate, setEndDate] = React.useState(null);
  const [override, setOverride] = React.useState([
    {
      startDate,
      endDate: endDate
        ? new Date(new Date(endDate).setMinutes(0, 0, 0))
        : new Date(startDate).setHours(12),
    },
  ]);
  React.useEffect(() => {
    if (startDate) {
      setOverride([
        {
          startDate,
          endDate: endDate
            ? new Date(
                new Date(new Date(endDate).setHours(17)).setMinutes(0, 0, 0)
              )
            : new Date(startDate).setHours(17),
        },
      ]);
    }
  }, [startDate, endDate]);

  function handleDeleteOverride(indexOverride) {
    const copied = [...dateOverride];
    const filterArr = copied.filter((el, index) => index !== indexOverride);
    setDateOverride(filterArr);
  }
  function excludeTimes(day: string) {
    return getHoursArray(state[day])?.map(
      (el) => new Date(new Date().setHours(el, 0, 0, 0))
    );
  }
  React.useEffect(() => {
    const isSameState = JSON.stringify(initialState) === JSON.stringify(state);
    const isSameOverride =
      JSON.stringify(initialOverride) === JSON.stringify(dateOverride);
    const isDiff = !isSameState || !isSameOverride;
    if (isDiff) {
      handleSave();
      setInitialState(null);
      setInitialOverride(null);
    }
  }, [state, dateOverride]);

  return (
    <Box>
      <Box display="flex">
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '60%',
          }}
        >
          {days?.map((element, index) => (
            <Grid
              container
              style={{
                display: 'flex',
                borderBottom: '0.5px solid #BCC6D3',
                paddingBlock: '20px',
                alignItems: state[element?.day]?.length === 0 && 'center',
                borderRight: '0.5px solid #BCC6D3',
              }}
              spacing={0}
            >
              <Grid item xl={3} lg={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  mt={state[element?.day]?.length !== 0 && 1}
                >
                  <Checked
                    checkedValue={element?.checked}
                    handleChange={() => {
                      handleCheckChange(index);
                    }}
                  />
                  <Typography>
                    {<Typography>{t(element.day as string)}</Typography>}
                  </Typography>
                </Box>
              </Grid>
              <Box display="flex" width="70%">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="100%"
                >
                  {state[element?.day]?.length > 0 ? (
                    state[element?.day]?.map((el, _index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={state[element?.day]?.length > 1 && 1}
                        className={classes.timeBox}
                      >
                        <TimeRange
                          onChange={handleChangeDate}
                          onInputClick={() => {
                            setDayName(element?.day);
                            setIndex(_index);
                          }}
                          showTimeSelect
                          dateFormat={dateFormat}
                          range={[
                            state[element?.day][_index]?.startDate,
                            state[element?.day][_index]?.endDate,
                          ]}
                          // excludeTimes={excludeTimes(element?.day)}
                          name="timeRange"
                        />
                        <Box ml={2}>
                          <DeleteIcon
                            width="31px"
                            height="33px"
                            onClick={() => handleRemove(_index, element?.day)}
                            cursor="pointer"
                            data-cy="remove-time"
                          />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#425364',
                      }}
                    >
                      {t('Unavailable')}
                    </Typography>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  width="100%"
                  mt={2}
                >
                  <AddTime
                    onClick={() => handleAdd(element?.day)}
                    cursor="pointer"
                    width="21px"
                    height="23px"
                    data-cy="add-time"
                  />
                </Box>
              </Box>
            </Grid>
          ))}{' '}
        </Grid>
        <Grid
          container
          style={{
            width: '40%',
            borderLeft: '0.5px solid #BCC6D3',
            padding: '20px',
          }}
        >
          <Box>
            <Typography
              style={{
                color: 'var(--2-sd-blue, #425364)',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: 600,
                marginBottom: '10px',
              }}
            >
              {t('Add date override')}
            </Typography>
            <Typography
              style={{
                color: 'var(--2-sd-blue, #425364)',
                fontFamily: 'Roboto',
                fontSize: '15px',
                fontWeight: 400,
                marginBottom: '10px',
              }}
            >
              {t(
                'Add dates when your availability changes from your weekly hours'
              )}
            </Typography>
            <Btn
              style={{
                backgroundColor: '#EFF6FF',
                border: ' 0.5px solid var(--2-sd-blue, #425364)',
                borderRadius: '5px',
                padding: '10px 100px',
                marginTop: '20px',
                color: 'var(--2-sd-blue, #425364)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 400,
                fontSize: '14px',
                textTransform: 'none',
              }}
              data-cy="add-override"
              onClick={() => {
                setOpen(true);
                if (dateOverride?.length === 0) {
                  setIsChanged(false);
                } else {
                  setStartDate(
                    new Date(
                      new Date(new Date().setHours(9)).setMinutes(0, 0, 0)
                    )
                  );
                  setEndDate(
                    new Date(
                      new Date(new Date().setHours(17)).setMinutes(0, 0, 0)
                    )
                  );
                }
              }}
            >
              {t('Add date override')}
            </Btn>
            <Box mt={4}>
              {isChanged &&
                dateOverride?.length !== 0 &&
                dateOverride?.map((el, index) => (
                  <Box
                    py={2}
                    borderTop="0.5px solid #BCC6D3"
                    borderBottom={
                      index === dateOverride?.length - 1 &&
                      '0.5px solid #BCC6D3'
                    }
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        fontWeight: 600,
                        color: 'var(--black, #273142)',
                      }}
                    >
                      {changeDateFormat(el && el[0]?.startDate)}
                      {changeDateFormat(el && el[0]?.startDate) !==
                        changeDateFormat(el && el[0]?.endDate) &&
                        ' ' +
                          `-` +
                          ' ' +
                          changeDateFormat(el && el[0]?.endDate)}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" flexDirection="column">
                        {el?.map((element) => (
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '15px',
                              fontWeight: 400,
                              color: 'var(--2-sd-blue, #425364)',
                            }}
                          >
                            {changeDateTime(element?.startDate)}
                            {changeDateTime(element?.startDate) !==
                              changeDateTime(element?.endDate) &&
                              ' ' +
                                '-' +
                                ' ' +
                                changeDateTime(element?.endDate)}
                          </Typography>
                        ))}
                      </Box>
                      <Box ml={2}>
                        <DeleteIcon
                          width="31px"
                          height="33px"
                          onClick={() => {
                            handleOpen(index);
                          }}
                          cursor="pointer"
                          data-cy="remove-overrideData"
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Grid>
      </Box>
      {errorState && (
        <Box display="flex" alignItems="center" mt={2}>
          <ErrorIcon style={{ color: 'red', marginRight: '10px' }} />
          <Typography className={classes.error}>{errorState}</Typography>
        </Box>
      )}
      <BasicModal
        open={open}
        onClose={handleClose}
        isRegularModal
        title="Set working hours for a specific date"
        titlePadding="3rem"
        handleClose={handleClose}
        className={classes.modal}
        titleClass={classes.title}
      >
        <OverrideModal
          setOverride={setOverride}
          override={override}
          setIsChanged={setIsChanged}
          handleClose={handleClose}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setDateOverride={setDateOverride}
        />
      </BasicModal>
      <BasicModal
        open={openDelete}
        isRegularModal
        handleClose={handleCloseDelete}
        onClose={handleCloseDelete}
        className={classes.deleteModal}
      >
        <Box p={1} style={{ outline: 0 }} bgcolor="white">
          <DeleteModalContent
            text="override date"
            handleClose={handleCloseDelete}
            handleDelete={() => {
              handleDeleteOverride(indexToDelete);
              setStartDate(new Date(new Date().setMinutes(0, 0, 0)));
              setEndDate(null);
              handleCloseDelete();
            }}
            padding={2}
          />
        </Box>
      </BasicModal>
    </Box>
  );
};

export default WorkingHour;
