import React, { ReactElement } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { fromNow } from 'utils';
import { useHistory } from 'react-router-dom';
import useUpdateNotification from '@eggmed/graphql-client/operations/notificationOperations/useUpdateNotifications';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useUpdateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useChangeGoingStat';
import useStyles from './styles';
import { ReactComponent as MoreIcon } from 'assets/more.svg';

export enum IS_GOING {
  yes = 'yes',
  no = 'no',
}
interface IElements {
  id: string;
  title: string;
  content: string;
  date: string;
  read: boolean;
  type: string;
  action: string;
  appointmentId?: string;
  onClick?: () => void;
  avatar?: string;
  patientId?: string;
}
interface INotificationCardProps {
  element: IElements;
  setDeleteId: (id: string) => void;
  setAnchor: (event: null | HTMLElement) => void;
  id?: number;
  setCardId?: (id: number) => void;
  setAppointmentId?: (id: string) => void;
  anchor: null | HTMLElement;
}

const NotificationCard = ({
  element,
  setDeleteId,
  id,
  setCardId,
  setAnchor,
  setAppointmentId,
  anchor,
}: INotificationCardProps): ReactElement => {
  const { patient } = useAuth();
  const IsPatient = !!patient;
  const classes = useStyles({ patient: IsPatient });
  const history = useHistory();
  const [cardAction, setCardAction] = React.useState<string>();
  const [cardActionId, setCardActionId] = React.useState<string>();
  const { handleUpdateNotificationAction } = useUpdateNotification();
  const {
    handleDoctorIsNotGoing,
    handleDoctorIsGoing,
    handlePatientIsGoing,
    handlePatientIsNotGoing,
  } = useUpdateAppointment();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
    setDeleteId(element.id);
    setCardId(id);
    setAppointmentId(element.appointmentId);
  };
  const updateNotif = () => {
    handleUpdateNotificationAction(element.id, IS_GOING.yes);
    setCardAction(IS_GOING.yes);
    setCardActionId(element?.id);
    setTimeout(() => {
      patient
        ? handlePatientIsGoing(element.appointmentId)
        : handleDoctorIsGoing(element.appointmentId);
    }, 500);
  };
  const HasMoreActions = ['new_Appointment', 'update_Appointment'].some(
    (e: string) => e === element?.type
  );
  const mainButton = {
    new_Appointment: {
      title: 'Accept',
      onclick: () => {
        updateNotif();
      },
    },
    new_PatientRequest: {
      title: 'See Request',
      onclick: () => {
        IsPatient
          ? history.push('/patient/access_control')
          : history.push('/doctor/patient');
      },
    },
    update_Appointment: {
      title: 'accept',
      onclick: () => {
        updateNotif();
      },
    },
    accept_PatientRequest: {
      title: 'See Request',
      onclick: () => {
        history.push(`/doctor/patient/${element.patientId}`);
      },
    },
    add_RefillRequest: {
      title: 'See Request',
      onclick: () => {
        history.push(`/doctor/patient/${element.patientId}`);
      },
    },
  };
  const secondButton = {
    new_Appointment: {
      title: 'Reschedule',
      onclick: () => {
        IsPatient
          ? history.push('/patient/appointment', {
              id: element.appointmentId,
            })
          : history.push('/doctor/schedule', {
              id: element.appointmentId,
            });
      },
    },
    update_Appointment: {
      title: 'Reschedule',
      onclick: () => {
        IsPatient
          ? history.push('/patient/appointment', {
              id: element.appointmentId,
            })
          : history.push('/doctor/schedule', {
              id: element.appointmentId,
            });
      },
    },
    add_RefillRequest: {
      title: 'See All Requests',
      onclick: () => {
        history?.push('/doctor/refillRequests');
      },
    },
  };
  const declineAppointment = () => {
    handleUpdateNotificationAction(element.id, IS_GOING.no);
    setCardAction(IS_GOING.no);
    setCardActionId(element?.id);
    setTimeout(() => {
      patient
        ? handlePatientIsNotGoing(element.appointmentId)
        : handleDoctorIsNotGoing(element.appointmentId);
    }, 500);
  };
  const [more, setMore] = React.useState(false);
  const handleMouseEnter = () => {
    setMore(true);
  };

  const handleMouseLeave = () => {
    !anchor && setMore(false);
  };
  return (
    <Box
      className={classes.Card}
      onClick={element.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box className={classes.readBox}>
        {!element?.read && <div className={classes.read} />}
      </Box>
      <Avatar src={element?.avatar} className={classes.cardIcon} />
      <Box className={classes.card}>
        <Typography className={classes.cardTitle}>{element?.title}</Typography>
        <Typography className={classes.notificationContent}>
          {element?.content}
        </Typography>
        <Typography className={classes.date}>
          {fromNow(element?.date)}
        </Typography>
      </Box>
      <Box className={classes.moreButton} onClick={more && handleClick}>
        {more && <MoreIcon className={classes.moreIcon} />}
      </Box>
      {/* <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              md={12}
              sm={12}
              className={classes.buttonGrid}
            >
              {(cardAction === IS_GOING.yes && cardActionId === element?.id) ||
              element?.action === IS_GOING.yes ? (
                <Typography className={classes.actionMessage}>
                  {patient
                    ? 'You have accepted this appointment'
                    : 'Request accepted'}
                </Typography>
              ) : (cardAction === IS_GOING.no &&
                  cardActionId === element?.id) ||
                element?.action === IS_GOING.no ? (
                <Typography className={classes.actionMessage}>
                  {patient
                    ? 'You have canceled this appointment'
                    : 'Request declined'}
                </Typography>
              ) : (
                <>
                  {mainButton[element?.type] && patient && (
                    <button
                      className={clsx(classes.cardButton, classes.mainButton)}
                      onClick={mainButton[element?.type]?.onclick}
                      style={{ color: 'white' }}
                    >
                      {mainButton[element?.type]?.title}
                    </button>
                  )}
                  {secondButton[element?.type] && (
                    <button
                      style={{ fontWeight: 600 }}
                      className={classes.cardButton}
                      onClick={secondButton[element?.type]?.onclick}
                    >
                      {secondButton[element?.type]?.title}
                    </button>
                  )}
                  {HasMoreActions && (
                    <button
                      className={classes.cardButton}
                      onClick={declineAppointment}
                    >
                      {patient ? 'Cancel' : 'Decline'}
                    </button>
                  )}
                </>
              )}
            </Grid> */}
    </Box>
  );
};

export default NotificationCard;
