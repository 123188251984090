import React, { ReactElement, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Avatar,
  Button,
} from '@material-ui/core';
import NotificationTabBar from './NotificationsTabBar';
import Tabs from 'components/ui/Tabs';
import { ReactComponent as SettingsIcon } from 'assets/settingsIcon.svg';
import { useGetMenuNotifications } from 'components/Layout/header/useGetMenuNotifications';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import NotificationsTab from './notificationTabs/NotificationTab';
import { useProvider } from './Provider';

const useStyles = makeStyles((theme) => ({
  largeScreen: {
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  smallScreen: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tab: {
    width: '100%',
    marginLeft: '2.2rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '3.2rem',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#273142',
    textTransform: 'none',
    marginLeft: '1.2rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '2.2rem',
    },
  },
}));

const NotificationPage = (): ReactElement => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };
  const { patient, user } = useAuth();
  const userId = user?._id;
  const { start, limit, setStart, setLimit, count, setPage, page } =
    useProvider();
  const { newNotifications, newCount } = useGetMenuNotifications(userId, {
    start,
    limit,
  });
  return (
    <Grid
      container
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.largeScreen}
    >
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <NotificationsTab
          elements={newNotifications}
          title="Notifications"
          handlePage={setPage}
          handleStart={setStart}
          handleLimit={setLimit}
          page={page}
          count={count}
          counting={newCount}
        />
      </Grid>
    </Grid>
  );
};

export default NotificationPage;
