import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {
  editLabRequest,
  editLabRequestVariables,
} from './__generated__/editLabRequest';

const EDIT_TASK = gql`
  mutation editLabRequest($labRequestId: ID, $labInput: EditLabRequestInput) {
    editLabRequest(labRequestId: $labRequestId, labInput: $labInput) {
      _id
      name
      timeFrame
      notes
      doctorId
      patientId
    }
  }
`;

export default function useEditLabRequest(): {
  editLabRequest: (
    options?:
      | MutationFunctionOptions<editLabRequest, editLabRequestVariables>
      | undefined
  ) => Promise<FetchResult<editLabRequest, any, any>>;
} {
  const [editLabRequestCall, result] = useMutation<
    editLabRequest,
    editLabRequestVariables
  >(EDIT_TASK, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          labRequestByAppointment(labs) {
            try {
              const newLabs = labs.map((lab: any) => {
                if (
                  lab.__ref.split('LabRequest:')[1] ===
                  data?.editLabRequest?._id
                ) {
                  return data?.editLabRequest;
                }
                return lab;
              });
              return newLabs;
            } catch (e) {
              return labs;
            }
          },
        },
      });
    },
  });

  return { editLabRequest: editLabRequestCall, ...result };
}
