import { useQuery, gql } from '@apollo/client';

export const GET_PATIENT_ACCESS = gql`
  query patientAccess {
    patientAccess {
      patient {
        firstname
      }
      access {
        doctor {
          firstname
          middlename
          lastname
          picture
          email
          phone
          _id
          focusArea
          languages
          patientGroup
          experience
          specialty
          gender
          about
          state
          country
          suite
          zipcode
          city
          address
          birthday
          certifications {
            certificationName
            yearOfDegree
            certificationLocation
            educationLevel
          }
        }
        hasAccess
        permissions
      }
    }
  }
`;

const useGetPatientAccess = () => {
  const { data, error, loading } = useQuery(GET_PATIENT_ACCESS);
  return { data, error, loading };
};

export default useGetPatientAccess;
