import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';

const SEND_RTC_ANSWER_MUTATION = gql`
  mutation SendRtcAnswer(
    $answer: SessionDescriptionInput!
    $recipientId: String!
    $roomId: String!
  ) {
    sendRtcAnswer(
      args: { answer: $answer, recipientId: $recipientId }
      roomId: $roomId
    ) {
      success
    }
  }
`;

const useSendRtcAnswer = () => {
  const [sendRtcAnswer] = useMutation<unknown, any>(SEND_RTC_ANSWER_MUTATION);

  async function handleSendRtcAnswer(
    answer: any,
    recipientId: string,
    roomId: string
  ) {
    try {
      return await sendRtcAnswer({
        variables: { answer, recipientId, roomId },
      });
    } catch (e) {
      log('error', 'Error while sending rtc answer', e);
      return e;
    }
  }

  return handleSendRtcAnswer;
};

export default useSendRtcAnswer;
