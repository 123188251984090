import React, { useState, useRef } from 'react';
import {
  Box,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import Input from 'components/ui/Inputs';
import { useStyles } from './styles';

function Address(): React.ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.addressWrapper}>
      <Box className={classes.wrapperBox}>
        <Typography className={classes.titleStyle}>Address</Typography>
        <Input
          variant="filled"
          type="text"
          style={{
            height: '40px',
            maxWidth: '100%',
            marginBottom: '9px',
          }}
          inputProps={{
            style: {
              padding: '10px 14px',
              fontSize: '14px',
              width: '100%',
            },
          }}
          disabled
        />
      </Box>
      <Box className={classes.wrapperBoxCountry}>
        <Box className={classes.lowerBox}>
          <Typography className={classes.titleStyle}>City</Typography>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
        <Box className={classes.lowerBox}>
          <Typography className={classes.titleStyle}>State</Typography>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
      </Box>
      <Box className={classes.wrapperBoxCountry}>
        <Box className={classes.lowerBox}>
          <Typography className={classes.titleStyle}>ZIP Code</Typography>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
        <Box className={classes.lowerBox}>
          <Typography className={classes.titleStyle}>Country</Typography>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Address;
