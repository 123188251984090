import React, { useState } from 'react';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import BasicModal from 'components/ui/Modal';
import { Box, Typography, Avatar, Button } from '@material-ui/core';
import CustomButton from 'components/ui/Button';
import useStyles from './styles';
import SelectMenu from './MenuName';
import Table from 'components/ui/Table';
import { useProvider } from 'pages/AppointmentListPage/state/Provider';
import dayjs from 'dayjs';
import { IColumn } from 'components/ui/Table/types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReminderLogo } from 'assets/ReminderLogo.svg';
import useSetReminder from '@eggmed/graphql-client/operations/appointmentsOperations/useSetReminder';
import useWaivePayment from '@eggmed/graphql-client/operations/appointmentsOperations/useWaivePayment';
import Skeleton from 'components/ui/Skeleton';
import Modals from 'pages/InboxPage/modals/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import useGetFinances from '@eggmed/graphql-client/operations/appointmentsOperations/useGetFinances';
import WaiveModal from './WaiveModal';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'hooks/useSnackbar';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyPayment } from 'assets/emptyState/payment.svg';
import Text from 'Text';

import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import { ReactComponent as SettingsIcon } from 'assets/dashboard/finances/settings-02.svg';
import { CreateInvoiceFlow } from './invoice/CreateInvoiceFlow';
import { transformArrayTimeZone } from 'utils';
import { FinancesHeader } from 'components/FinancesHeader';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DotsIcon } from 'assets/dashboard/finances/dots.svg';
import { InvoicePreview } from './invoice/InvoicePreview';
import Menu from 'components/ui/Menu';
import { ReactComponent as InvoiceIcon } from 'assets/dashboard/finances/invoice.svg';
import { ReactComponent as PaymentIcon } from 'assets/dashboard/finances/coins-hand.svg';
import { ReactComponent as MessageIcon } from 'assets/dashboard/finances/message.svg';
import { ReactComponent as WaiveIcon } from 'assets/dashboard/finances/waive.svg';
import { ReactComponent as ReminderIcon } from 'assets/dashboard/finances/bell.svg';
import { SearchClients } from './SearchClients';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { useQuery } from '@apollo/client';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { FormProvider, useForm } from 'react-hook-form';
import { RefundModal } from 'pages/AppointmentPage/RefundModal';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import useRefundAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useRefundAppointemnt';
import useEditSession from '@eggmed/graphql-client/operations/appointmentsOperations/useEditSession';

interface DoctorPatientInfo {
  firstname: string;
  lastname: string;
  phone?: string;
  address?: string;
  suite?: string;
  city?: string;
  zipcode?: string;
  country?: string;
  state?: string;
}

interface IAppointement {
  patient: {
    firstname: string;
    middlename: string;
    lastname: string;
    picture: string;
    _id: string;
  };
  startDate: string;
  endDate: string;
  sessionType: {
    duration: string;
    session: string;
    rate: string;
    __typename?: string;
  };
  isPaid: string;
  isPrepay: boolean;
  _id: string;
  doctor: {
    timeZone: string;
  };
  invoice?: {
    invoiceRef: number;
    doctorInfo: DoctorPatientInfo;
    patientInfo: DoctorPatientInfo;
    amountPaid: number;
    issueDate: string;
    dueDate: string;
    invoicePdf?: string;
  };
}
export enum Payment {
  paid = 'paid',
  overdue = 'overdue',
  pending = 'pending',
  waived = 'waived',
  refunded = 'refunded',
}
export enum Period {
  week = 'Week',
  month = 'Month',
  day = 'Day',
  year = 'Year',
}
const FinancePage = ({
  isAll,
  patientId,
}: {
  isAll?: boolean;
  patientId?: string;
}) => {
  const { handleRefund, loadingRefund } = useRefundAppointment();
  const { updateSession } = useEditSession();
  const [invoiceTitle, setInvoiceTitle] = useState('Create invoice');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<any>();
  const location = useLocation<{
    Time: string;
  }>();
  React.useEffect(() => {
    if (location.state?.Time) {
      setTime(location.state?.Time);
      setName(capitalizeFirstLetter(location.state?.Time.toLowerCase()));
    }
  }, [location.state]);
  const { triggerSnack } = useSnackbar();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Finances' });
  }, [headerTitleDispatcher]);
  const classes = useStyles();
  const { handleOpenCreateNewThreadModal } = useInobx();
  const [name, setName] = React.useState<string>(Period.week);
  const [Time, setTime] = React.useState<string>(Period.week.toUpperCase());
  const { t } = useTranslation();
  const [menuName, setMenuName] = React.useState(t('Status'));
  const [menuValue, setMenuValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openWaive, setOpenWaive] = React.useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [switchStates, setSwitchStates] = React.useState<{
    [key: string]: boolean;
  }>({});
  const { data: patientsRelated } = useQuery(PATIENTS_RELATED);

  const handleSwitchChange = (id: string, value: boolean) => {
    const localValue = value === null ? false : value;
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: localValue,
    }));
  };

  function handleCloseInvoice() {
    setOpenInvoice(false);
  }
  function handleCloseInvoicePreview() {
    setOpenInvoicePreview(false);
  }
  function handleCloseWaive() {
    setOpenWaive(false);
  }
  function handleClose() {
    setOpen(false);
  }
  const { data, error, currentPage, handleChangePage, loadingData } =
    useProvider();
  const {
    data: finances,
    loading,
    refetch,
  } = useGetFinances({
    limit: '5',
    page: `${currentPage}`,
    filter: menuValue,
    patient: isAll ? '' : patientId,
    searchValue: searchQuery,
  });
  const [value, setValue] = React.useState('');

  const refundForms = useForm();
  const [toggled, setToggle] = React.useState(false);
  const [openRefund, setOpenRefund] = React.useState(false);

  function handleChangeToggle(e) {
    setToggle((togl) => !togl);
  }

  function handleCloseRefund() {
    setOpenRefund(false);
    setToggle(false);
  }

  React.useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);
  const [id, setId] = React.useState('');
  const [row, setRow] = React.useState<{
    patient: {
      firstname: string;
      lastname: string;
      middlename: string;
      _id: string;
      email: string;
      picture: string;
    };
    startDate?: string;
    isPaid?: string;
  }>({
    patient: {
      firstname: '',
      lastname: '',
      middlename: '',
      _id: '',
      email: '',
      picture: '',
    },
  });
  function handleChange(e, row) {
    e.stopPropagation();
    setId(row?._id);
    setRow(row);
  }
  const { firstname, lastname, middlename, _id, email, picture } =
    row?.patient || {};
  const { startDate } = row || {};
  const subject = `${dayjs(startDate).format(
    'MMMM DD'
  )}th appointment with  ${capitalizeFirstLetter(
    firstname
  )} ${capitalizeFirstLetter(lastname)}`;
  const columns: IColumn<IAppointement>[] = [
    {
      id: 'firstname',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="client">Client</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 190,
      format: (value, row) => {
        const { firstname, middlename, lastname, picture, _id } =
          row?.patient || {};
        const avatarPicture = picture?.split('/')[3];
        return (
          <Box display="flex" alignItems="center" gridGap="0.5rem" ml={-1}>
            {/* <Checked
              checkedValue={id === row?._id}
              handleChange={(e) => handleChange(e, row)}
              onClick={(e) => e.stopPropagation()}
              color="#0265DC"
            /> */}
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Avatar
                alt="Patient picture"
                src={avatarPicture !== 'undefined' ? picture : ''}
              >
                {`${firstname && firstname[0].toUpperCase()}${
                  lastname && lastname[0].toUpperCase()
                }`}
              </Avatar>
              <Box className={classes.rowText} pl={2}>{`${firstname} ${
                middlename || ''
              } ${lastname}`}</Box>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 210,
      format: (value, { doctor }) => {
        return (
          <Typography className={classes.rowText}>
            {dayjs(value).format('dddd, MMMM D, hh:mm A')}
          </Typography>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="service">Service</Text>
        </Box>
      ),
      minWidth: 190,
      align: 'left',
      format: (value) =>
        value && (
          <Typography className={classes.rowText}>
            {value?.duration} min {value?.session}
          </Typography>
        ),
    },
    {
      id: 'isPaid',
      label: <Box fontSize="1rem">{t('Payment status')}</Box>,
      minWidth: 190,
      align: 'left',
      format: (value) =>
        value === Payment.paid ? (
          <Text i18nKey="paid" className={classes.paid}>
            Paid
          </Text>
        ) : value === Payment.overdue ? (
          <Text i18nKey="overdue" className={classes.overdue}>
            Overdue
          </Text>
        ) : value === Payment.waived ? (
          <Text i18nKey="waived" className={classes.waived}>
            Waived
          </Text>
        ) : value === Payment.refunded ? (
          <Text i18nKey="refunded" className={classes.refunded}>
            Refunded
          </Text>
        ) : (
          <Text i18nKey="pending" className={classes.pending}>
            Pending
          </Text>
        ),
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="amount">Amount</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) =>
        value && (
          <Typography className={classes.rowText}>US${value?.rate}</Typography>
        ),
    },
    {
      id: 'invoice',
      label: <></>,
      minWidth: 100,
      align: 'left',
      format: (value, current) => (
        <Box className={classes.lastCol}>
          {current?.invoice?.invoiceRef && (
            <Typography
              className={classes.invoiceText}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentRow(current);
                setOpenInvoicePreview(true);
              }}
            >{`INV #${current?.invoice?.invoiceRef}`}</Typography>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="40px"
            minHeight="40px"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setCurrentRow(current);
              handleChange(e, current);
            }}
          >
            <DotsIcon className={classes.dots} />
          </Box>
        </Box>
      ),
    },
  ];
  const columnsTable = isAll
    ? columns
    : columns?.filter((el) => el?.id !== 'firstname');

  const history = useHistory();

  const timezone =
    finances?.finances?.result &&
    finances?.finances?.result[0]?.doctor?.timeZone;
  const filteredAppointments = transformArrayTimeZone(
    finances?.finances?.result,
    timezone
  );
  const { setReminder } = useSetReminder();
  const { waivePayment } = useWaivePayment();
  async function handleReminder() {
    await setReminder({
      variables: {
        appointmentId: id,
      },
    });
    handleClose();
  }
  async function handleWaive() {
    await waivePayment({
      variables: {
        appointmentId: id,
      },
    });
    handleCloseWaive();
  }

  const MenuElementsDataOverduePending = [
    {
      cy: 'waive',
      avatar: <WaiveIcon />,
      name: t('Waive payment'),
      onClick: () => {
        setOpenWaive(true);
        handleCloseMenu();
      },
    },
    {
      cy: 'reminder',
      avatar: <ReminderIcon />,
      name: t('Send reminder'),
      onClick: () => {
        setOpen(true);
        handleCloseMenu();
      },
    },
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
  ].filter(Boolean);

  const MenuElementsDataWaivedRefunded = [
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
  ].filter(Boolean);

  const MenuElementsDataPaid = [
    {
      cy: 'refund',
      avatar: <PaymentIcon />,
      name: t('Refund'),
      onClick: () => {
        setOpenRefund(true);
        handleCloseMenu();
      },
    },
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    currentRow?.invoice?.invoiceRef && {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
  ].filter(Boolean);

  const MenuSelector = {
    [Payment.paid]: MenuElementsDataPaid,
    [Payment.overdue]: MenuElementsDataOverduePending,
    [Payment.pending]: MenuElementsDataOverduePending,
    [Payment.waived]: MenuElementsDataWaivedRefunded,
    [Payment.refunded]: MenuElementsDataWaivedRefunded,
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const refundColumns = [
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>
            {dayjs(value).format('MMM D, YYYY')}
          </Typography>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="fees">Fees</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value) => {
        return (
          <Typography className={classes.textRow}>{value?.rate} USD</Typography>
        );
      },
    },
    {
      id: 'isPaid',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="paymentStatus">Payment status</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => {
        return (
          <Box
            style={{
              width: '50%',
              borderRadius: '16px',
              background: chipColor[value]?.background,
              border: chipColor[value]?.border,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 8px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                color: chipColor[value]?.color,
                textTransform: 'capitalize',
              }}
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'sessionType',
      label: <Box fontSize="1rem">SessionType</Box>,
      minWidth: 170,
      align: 'left',
      format: (value) => (
        <Typography className={classes.textRow}>{value?.session}</Typography>
      ),
    },
  ];

  async function onRefund(data) {
    const { paymentIntentId } = currentRow || {};

    if (data?.refund) {
      await handleRefund(paymentIntentId as string, Number(data?.refund) * 100);
    }

    await updateSession(currentRow?._id, {
      isEnded: true,
      endSessionDate: new Date(),
    });

    handleCloseRefund();
  }

  return (
    <Box mt={isAll ? 4 : 0}>
      {isAll && (
        <FinancesHeader
          dataFinance={data}
          loading={loadingData}
          isOnFinancePage
          time={Time}
          setTime={setTime}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mt={isAll ? 6 : 2}
      >
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          {/* <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={
                id == '' ||
                row?.isPaid === Payment.paid ||
                row?.isPaid === Payment.waived
              }
              onClick={() => setOpen(true)}
            >
              {id == '' ||
              row?.isPaid === Payment.paid ||
              row?.isPaid === Payment.waived ? (
                <ReminderDisabled className={classes.svgMarginRight} />
              ) : (
                <Reminder className={classes.svgMarginRight} />
              )}
              <Text i18nKey='sendReminder'>Send reminder</Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={id == ''}
              onClick={() => id && handleOpenCreateNewThreadModal()}
            >
              {id == '' ? (
                <MessageDisabled className={classes.svgMarginRight} />
              ) : (
                <Message className={classes.svgMarginRight} />
              )}
              <Text i18nKey='message'>Message</Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <Button
              className={classes.btn}
              disabled={id == '' || row?.isPaid === Payment.waived}
              onClick={() => setOpenWaive(true)}
            >
              {id == '' || row?.isPaid === Payment.waived ? (
                <WaiveDisabled className={classes.svgMarginRight} />
              ) : (
                <Waive className={classes.svgMarginRight} />
              )}
              <Text i18nKey='waivePayment'>Waive payment</Text>
            </Button>
          </Skeleton> */}
          {isAll && (
            <Skeleton loading={loading}>
              <SearchClients
                options={convertObjectsFormat(
                  patientsRelated?.patientsRelated,
                  ''
                )}
                value={value}
                handleChangePage={handleChangePage}
                setValue={setValue}
                setSearchQuery={setSearchQuery}
                filterOptions={(options, { inputValue }) => {
                  const inputValueLowerCase = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.email
                        .toLowerCase()
                        .includes(inputValueLowerCase) ||
                      option.name.toLowerCase().includes(inputValueLowerCase)
                  );
                }}
              />
            </Skeleton>
          )}
          <Skeleton loading={loading}>
            <SelectMenu
              menuName={menuName}
              setMenuName={setMenuName}
              setMenuValue={setMenuValue}
            />
          </Skeleton>
        </Box>
        <Box display="flex">
          <Skeleton loading={loading}>
            <Button
              className={classes.btnSettings}
              onClick={() =>
                history.push('/doctor/admin?query=paymentsettings')
              }
            >
              <SettingsIcon />
              <Text i18nKey="paymentSettings" style={{ fontWeight: 500 }}>
                Payment settings
              </Text>
            </Button>
          </Skeleton>
          <Skeleton loading={loading}>
            <CustomButton
              className={classes.addBtn}
              buttonType="reset"
              onClick={() => setOpenInvoice(true)}
            >
              <AddIcon />
              <Text i18nKey="createInvoice" style={{ fontWeight: 500 }}>
                Create invoice
              </Text>
            </CustomButton>
          </Skeleton>
        </Box>
      </Box>
      <Box my={2}>
        <Table<any>
          loading={loading}
          page={currentPage}
          columns={columnsTable}
          limit={5}
          handlePagination={handleChangePage}
          data={filteredAppointments || []}
          pagination
          EmptyState={
            <Box py="15rem">
              <EmptyState
                icon={<EmptyPayment />}
                title={t('No payment history yet')}
                hasAction={false}
              />
            </Box>
          }
          count={finances?.finances?.count}
          fullData={false}
          onRowClick={(row) =>
            history.push(
              `/doctor/patient/${row?.patient?._id}?event=${row?._id}&tab=2`
            )
          }
        />
      </Box>
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={handleCloseMenu}
        MenuElements={MenuSelector[currentRow?.isPaid]}
      />
      <BasicModal
        open={open}
        onClose={handleClose}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleClose}
        isIcon={false}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          px={8}
          pb={4}
        >
          <ReminderLogo />
          <Box>
            <Text
              i18nKey="Send Payment Reminder"
              className={classes.paymentTitle}
            >
              Send Payment Reminder
            </Text>
            <Text
              i18nKey="Are you sure you want to send reminders to"
              className={classes.paymentText}
            >
              Are you sure you want to send reminders to
            </Text>
            <Text
              i18nKey="complete payment process?"
              className={classes.paymentText}
            >
              complete payment process?
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Button className={classes.btnC} onClick={handleClose}>
              <Text i18nKey="cancel">Cancel</Text>
            </Button>
            <Button className={classes.btnSC} onClick={() => handleReminder()}>
              <Text i18nKey="send">Send</Text>
            </Button>
          </Box>
        </Box>
      </BasicModal>

      <BasicModal
        open={openWaive}
        onClose={handleCloseWaive}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleCloseWaive}
        isIcon={false}
      >
        <WaiveModal
          handleCloseWaive={handleCloseWaive}
          handleWaive={handleWaive}
        />
      </BasicModal>
      <Modals
        subject={subject}
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
      <BasicModal
        open={openInvoice}
        onClose={handleCloseInvoice}
        isRegularModal
        title={invoiceTitle}
        titlePadding="2rem"
        handleClose={handleCloseInvoice}
        isSlide
        divider
      >
        <CreateInvoiceFlow
          handleClose={handleCloseInvoice}
          setInvoiceTitle={setInvoiceTitle}
          page={currentPage}
          filter={menuValue}
          patient={isAll ? '' : patientId}
        />
      </BasicModal>
      <BasicModal
        open={openInvoicePreview}
        onClose={handleCloseInvoicePreview}
        isRegularModal
        title={t('Invoice Preview')}
        titlePadding="2rem"
        handleClose={handleCloseInvoicePreview}
        isSlide
        divider
      >
        <InvoicePreview
          currentRow={currentRow}
          handleClose={handleCloseInvoicePreview}
        />
      </BasicModal>
      <FormProvider {...refundForms}>
        <BasicModal
          open={openRefund}
          onClose={handleCloseRefund}
          handleClose={handleCloseRefund}
          isFromModal
          isSlide
          divider
          title={t('Complete your session')}
          titlePadding="2rem"
          onSubmit={onRefund}
          loading={loadingRefund}
        >
          <RefundModal
            isLoading={false}
            appointment={currentRow}
            columns={refundColumns}
            toggled={toggled}
            handleChange={handleChangeToggle}
            refundForms={refundForms}
          />
        </BasicModal>
      </FormProvider>
    </Box>
  );
};

export default FinancePage;
