import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
} from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import { useEditorContext } from '../EditorContextProvider';
import useStyles from './styles';
import { IListOption } from '.';

interface GenericProps {
  options: IListOption[];
  selectedIndex: number;
  toggleListVisibilityList: (listName: string) => void;
}
const GenericList: React.FC<GenericProps> = ({
  options,
  selectedIndex,
  toggleListVisibilityList,
}) => {
  const listRef = useRef(null);
  const classes = useStyles();
  const { addBlock } = useEditorContext();
  const [selectedItem, setSelectedIndex] = useState(null);

  const handleClickOutside = (event: MouseEvent | null) => {
    if (listRef.current && !listRef.current.contains(event?.target as Node)) {
      toggleListVisibilityList(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleListItemClick = (event, index, element) => {
    setSelectedIndex(index);
    addBlock(options[index].blockName, options[index].level);
    toggleListVisibilityList(null);
  };

  return (
    <div ref={listRef} className={classes.genericListBox}>
      <List component="nav" aria-label="main mailbox folders">
        {options.map((element, index) => (
          <ListItem
            key={`${element.blockName}-${index}`}
            button
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index, element)}
          >
            <ListItemIcon>{element.icon}</ListItemIcon>
            <ListItemText primary={element.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
};

export default GenericList;
