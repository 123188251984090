import React, { ReactElement } from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TrashIcon from 'assets/TrashIcon';
import { IRates, IPayout } from './type';
import { IuserCreditCard } from '../Billing/types';
import Text from 'Text';
interface IDropDownMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleDelete: () => void;
  handleEdit?: () => void;
  isDefault?: boolean;
  handleDefault?: (e) => void;
  row?: IRates | IuserCreditCard | IPayout;
}

export default function DropdownMenu({
  anchorEl,
  handleClose,
  handleEdit,
  handleDelete,
  isDefault = false,
  handleDefault,
  row,
}: IDropDownMenuProps): ReactElement {
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      disableScrollLock={false}
    >
      {handleEdit && (
        <MenuItem
          data-cy="view-profile"
          onClick={() => {
            handleEdit();
            handleClose();
          }}
        >
          <EditIcon style={{ marginRight: '10px' }} /> <Text i18nKey='edit'>Edit</Text>
        </MenuItem>
      )}
      {row && !row?.isDefault && (
        <MenuItem
          data-cy="delete-item"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
        >
          <TrashIcon
            width="20px"
            height="20px"
            style={{ marginRight: '10px' }}
          />
          <Text i18nKey='delete'>Delete</Text>
        </MenuItem>
      )}

      {isDefault && (
        <MenuItem
          data-cy="view-profile"
          onClick={(e) => {
            handleDefault(e);
            handleClose();
          }}
        >
          <EditIcon style={{ marginRight: '10px' }} /> Make default
        </MenuItem>
      )}
    </Menu>
  );
}
