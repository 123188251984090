export function convertType(type: string) {
  switch (type) {
    case 'sleepCheckin':
      return 'Sleep check in';
    case 'moodCheckin':
      return 'Mood check in';
    case 'reflection':
      return 'Reflection';
    case 'All':
      return 'All';
    case 'Type':
      return 'Type';
    case 'questionnaires':
      return 'Forms';
  }
}
