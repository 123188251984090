import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const DeleteButton = withStyles((theme) =>
  createStyles({
    root: {
      color: 'white',
      backgroundColor: '#FF0303',
      width: '4.625rem',
      height: '2.263125rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#FF0303',
        borderColor: '#FF0303',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#FF0303',
        borderColor: '#FF0303',
      },
    },
  })
)(Button);
export default DeleteButton;
