import React from 'react';
import { ReactComponent as ShortAnswerIcon } from 'assets/patientActivities/inputs/short.svg';
import { ReactComponent as LongAnswerIcon } from 'assets/patientActivities/inputs/long.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/patientActivities/inputs/multiple.svg';
import { ReactComponent as CheckboxesIcon } from 'assets/patientActivities/inputs/check.svg';
import { ReactComponent as DropdownIcon } from 'assets/patientActivities/inputs/dropdown.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/patientActivities/inputs/scale.svg';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';
import { ReactComponent as DateRangeIcon } from 'assets/patientActivities/inputs/date-range.svg';
import { ReactComponent as EmailIcon } from 'assets/patientActivities/inputs/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/patientActivities/inputs/phone.svg';
import { ReactComponent as AddressIcon } from 'assets/patientActivities/inputs/address.svg';

export enum QuestionTypeEnum {
  ShortAnswer = 'Short answer',
  LongAnswer = 'Long answer',
  MultipleChoice = 'Multiple choice',
  SingleChoice = 'Single choice',
  Dropdown = 'Dropdown',
  LinearScale = 'Linear scale',
  Date = 'Date',
  DateRange = 'Date range',
  TextBox = 'Text box',
  Email = 'Email',
  Phone = 'Phone',
  Address = 'Address',
  Signature = 'Signature',
  Table = 'table',
  List = 'list',
  OrderedList = 'Orderedlist',
}

export const Types = [
  {
    label: QuestionTypeEnum.ShortAnswer,
    icon: <ShortAnswerIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.LongAnswer,
    icon: <LongAnswerIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.MultipleChoice,
    icon: (
      <MultipleChoiceIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />
    ),
  },
  {
    label: QuestionTypeEnum.SingleChoice,
    icon: <CheckboxesIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Dropdown,
    icon: <DropdownIcon />,
  },
  {
    label: QuestionTypeEnum.LinearScale,
    icon: <LinearScaleIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Date,
    icon: <DateIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.DateRange,
    icon: (
      <DateRangeIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />
    ),
  },
  {
    label: QuestionTypeEnum.Email,
    icon: <EmailIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Phone,
    icon: <PhoneIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Address,
    icon: (
      <AddressIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />
    ),
  },
  {
    label: QuestionTypeEnum.Signature,
    icon: <DateIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
];
export const NotesTypes = [
  {
    label: QuestionTypeEnum.TextBox,
    icon: <LongAnswerIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },

  {
    label: QuestionTypeEnum.MultipleChoice,
    icon: (
      <MultipleChoiceIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />
    ),
  },
  {
    label: QuestionTypeEnum.SingleChoice,
    icon: <CheckboxesIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Dropdown,
    icon: <DropdownIcon />,
  },
  {
    label: QuestionTypeEnum.LinearScale,
    icon: <LinearScaleIcon style={{ maxWidth: '25px', maxHeight: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Date,
    icon: <DateIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.DateRange,
    icon: (
      <DateRangeIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />
    ),
  },
  {
    label: QuestionTypeEnum.Email,
    icon: <EmailIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Phone,
    icon: <PhoneIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />,
  },
  {
    label: QuestionTypeEnum.Address,
    icon: (
      <AddressIcon style={{ color: 'rgb(66, 83, 100)', maxWidth: '25px' }} />
    ),
  },
];
