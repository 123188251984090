import React, { ReactElement } from 'react';
import { Box, Grid } from '@material-ui/core';
import Input from '../components/ui/Inputs';

interface IInput {
  name?: string;
  col: number;
  type: string;
  label?: string | undefined;
  style?: any;
  options?: string[];
  variant?: string;
  helperText?: ReactElement | undefined;
  error?: boolean;
  Custom?: (props: any) => any;
}

export interface IUseFormGroupProps {
  inputs: IInput[]; // array contains inputs
  register?: any; // useForm hook register
  cols?: number; // colls number
  control?: any; // useForm hook control
  patient?: boolean;
}
/**
 *
 * useFormGroup a hook that create a group of forms with grid system
 * @param {IUseFormGroupProps} {
 *   inputs,
 *   register,
 *   cellHeight = 100,
 *   control,
 * }
 * @return {*}  {*}
 */
function useFormGroup({
  inputs,
  register,
  cols = 1,
  control,
  patient = false,
}: IUseFormGroupProps): any {
  const forms = (
    <Grid container spacing={2}>
      {inputs.map(
        (
          {
            label,
            type,
            rows,
            col,
            Custom,
            handleAction,
            handleDelete,
            labelClassname,
            style,
            className,
            stylePhone,
            inputClassName,
            md,
            sm,
            xl,
            xs,
            lg,
            ...props
          }: any,
          index: number
        ) => (
          <Grid
            style={style}
            className={className}
            item
            key={`${props.name}`}
            lg={lg}
            md={md}
            sm={sm}
            xl={col}
            xs={xs}
          >
            <Box key={props.name}>
              {type === 'custom' ? (
                <Custom
                  {...{
                    inputs,
                    index,
                    name: props.name,
                    value: inputs[index],
                    cols,
                    col,
                    inputLine: Math.round(index / cols),
                    handleDelete,
                    handleAction,
                    register,
                    control,
                  }}
                />
              ) : (
                <>
                  <span className={labelClassname}>{label}</span>
                  <Box mt={stylePhone}>
                    <Input
                      size="small"
                      style={{ marginTop: '10px' }}
                      control={control}
                      type={type}
                      inputRef={register}
                      className={patient && inputClassName}
                      {...props}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );

  return { forms };
}

export default useFormGroup;
