import { gql, useMutation } from '@apollo/client';
import { log } from '../../utils/log';

const JOIN_DOCTOR_WAITING_ROOM = gql`
  mutation joinDoctorWaitingRoom($roomId: String) {
    joinDoctorRoom(roomId: $roomId)
  }
`;

const useJoinDoctorWaitingRoom = () => {
  const [joinDoctorWaitingRoom, result] = useMutation(JOIN_DOCTOR_WAITING_ROOM);

  async function handleJoinDoctorWaitingRoom(
    roomId: string,
    handleError?: (e: any) => void
  ) {
    try {
      const resultData = await joinDoctorWaitingRoom({
        variables: { roomId },
      });
      log('log', 'Joined Doctor room');
      return resultData;
    } catch (e) {
      log('error', 'Error while Joining Doctor room', e);
      return handleError && handleError(e);
    }
  }
  return { ...result, handleJoinDoctorWaitingRoom };
};

export default useJoinDoctorWaitingRoom;
