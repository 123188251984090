import { Box, FormLabel } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import AssignedTo from 'components/ui/AssignedTo';
import InboxInput from '../components/input/input';
import { useProvider } from '../state/Provider';
import useStyles from './style';
import {
  getContact_getStaffsByDoctor,
  getContact_getStaffsByDoctor_user,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getContact';
import useGetContact from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetContact';
import Input from 'components/ui/Inputs';
import { enumToArray } from 'utils/enumToArry';
import { removeEmptyFields } from 'utils/removeEmptyFields';
import { typeConversationEnum } from '@eggmed/graphql-client/__generated__/globalTypes';
import BasicModal from 'components/ui/Modal';
import { useForm, FormProvider } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

export interface IContactProps {
  firstname: string;
  middlename: string;
  lastname: string;
  _id: string;
  email: string;
  picture: string;
}

const schema = yup.object().shape({
  subject: yup
    .string()
    .min(2, 'Title must be at least 2 characters')
    .required('Title is a required field'),
  contact: yup.array().required(),
  message: yup
    .string()
    .min(2, 'Message must be at least 2 characters')
    .required('Message is a required field'),
  files: yup.array(),
});

const CreateNewThreadModals = ({
  isPatient,
  ...props
}: {
  isPatient?: boolean;
  contact?: IContactProps[];
  subject?: string;
  message?: string;
}) => {
  const { t } = useTranslation();
  const { contact, subject, message } = props || {};
  const { data } = useQuery(USER_BY_DOCTOR, {
    variables: {
      id: contact && contact[0]?._id,
    },
  });
  const contactUser = data?.userById;
  const { triggerToast } = useToast();
  const { user } = useAuth();
  const classes = useStyles();
  const {
    handleCloseCreateNewThreadModal,
    handelCreateThreadConversation,
    modal,
    modalType,
    loading,
    handleSelectedThead,
  } = useProvider();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject,
      contact: contactUser
        ? convertObjectsFormat([contactUser] || [], 'Doctors')
        : [],
      message,
      files: [],
      type: null,
    },
  });
  const { triggerSnack } = useSnackbar();

  useEffect(() => {
    if (contactUser) {
      methods.reset({
        ...methods.watch(),
        contact: convertObjectsFormat([contactUser], 'Doctors'),
        subject,
      });
    }
  }, [contactUser, subject]);

  const { register, errors, control, reset } = methods;
  const { data: contactsList } = useGetContact('owner');
  const staffUser = (getStaffsByDoctor: getContact_getStaffsByDoctor[]) =>
    getStaffsByDoctor?.map(({ user }: getContact_getStaffsByDoctor) => {
      return { ...user };
    }) || [];

  const staffArray = useMemo(
    () =>
      convertObjectsFormat(
        staffUser(contactsList?.getStaffsByDoctor) || [],
        'Staff'
      ),
    [contactsList]
  );
  const patientArray = useMemo(
    () => convertObjectsFormat(contactsList?.patientUser || [], 'Patients'),
    [contactsList]
  );
  const doctorsArray = useMemo(
    () =>
      convertObjectsFormat(
        contactsList?.getUserByRole.filter(({ _id }) => _id != user._id) || [],
        'Providers'
      ),
    [contactsList]
  );
  const options = [...staffArray, ...doctorsArray, ...patientArray];
  const commonProps = { register, control, errors, variant: 'filled' };

  const onSubmit = async (form: any) => {
    const getIdContact = form.contact.map(
      (item: getContact_getStaffsByDoctor_user) => {
        return item._id;
      }
    );
    const thread = await handelCreateThreadConversation({
      variables: {
        ...removeEmptyFields(form),
        message: { text: form.message, files: form.files },
        contact: props?.contact?.[0]?._id ? contactUser?._id : getIdContact,
      },
    });
    handleSelectedThead(thread?.data?.createThreadConvertation?._id);
    triggerSnack();
    setTimeout(() => handleCloseCreateNewThreadModal(), 1000);
  };

  return (
    <FormProvider {...methods}>
      <BasicModal
        open={modal}
        onClose={handleCloseCreateNewThreadModal}
        handleClose={handleCloseCreateNewThreadModal}
        isFromModal
        className={classes.newThreadModal}
        onSubmit={onSubmit}
        title={t('New Message')}
        titlePadding="3rem"
        divider
        isPatient={isPatient}
        submitText={'send'}
        loading={loading}
      >
        <Box className={classes.modalsContainer}>
          <Box className={classes.filed}>
            <InboxInput
              {...commonProps}
              type="text"
              data-cy="inbox-subject"
              name="subject"
              label={t('Subject')}
              className={classes.inputBox}
            />
          </Box>
          {/* <Box className={classes.filed}>
            <FormLabel className={clsx(classes.label)}>Type</FormLabel>
            <Box className={classes.inputBox}>
              <Input
                type="select"
                name="type"
                register={register}
                control={control}
                variant="filled" 
                options={enumToArray(typeConversationEnum)}
              />
            </Box>
          </Box> */}
          <Box className={classes.filed}>
            <FormLabel className={clsx(classes.label)}>
              <Text className={clsx(classes.label)} i18nKey="recipient">
                Recipient
              </Text>
            </FormLabel>
            <Box className={classes.inputBox}>
              <Controller
                data-cy="add-contact"
                control={control}
                name="contact"
                render={({ onChange, value }) => (
                  <Symptoms
                    options={options}
                    values={value}
                    setValues={onChange}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={classes.filed}>
            <InboxInput
              {...commonProps}
              type="area"
              name="message"
              label={t('Message')}
              fullWidth
              multiline
              InputProps={{
                rows: 6,
              }}
            />
          </Box>

          <Box className={classes.filed}>
            <Controller
              data-cy="files"
              control={control}
              name={t('files')}
              render={({ onChange, value }) => (
                <InboxInput
                  type="uploadFiles"
                  name="files"
                  label={t('Files')}
                  files={value}
                  handleAction={(event: any) => {
                    onChange([...value, event.target?.files[0]]);
                  }}
                  isActionable
                  className={classes.inputBox}
                />
              )}
            />
          </Box>
        </Box>
      </BasicModal>
    </FormProvider>
  );
};

export default CreateNewThreadModals;

export const USER_BY_DOCTOR = gql`
  query getUserByDoctor($id: ID!) {
    userById(id: $id) {
      _id
      firstname
      lastname
      middlename
      email
      picture
    }
  }
`;
