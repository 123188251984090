import React from 'react';

function checkedIcon({ color = '#4CBD51' }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9001 7.75989L17.5701 5.42987C17.5394 5.39873 17.5029 5.374 17.4625 5.35712C17.4222 5.34024 17.3788 5.33154 17.3351 5.33154C17.2914 5.33154 17.2481 5.34024 17.2078 5.35712C17.1674 5.374 17.1308 5.39873 17.1001 5.42987L9.00012 13.5299L6.90015 11.4299C6.86944 11.3987 6.83282 11.374 6.79248 11.3571C6.75214 11.3402 6.70889 11.3315 6.66516 11.3315C6.62143 11.3315 6.57806 11.3402 6.53772 11.3571C6.49738 11.374 6.46088 11.3987 6.43018 11.4299L4.1001 13.7599C4.03659 13.8236 4.00098 13.9099 4.00098 13.9999C4.00098 14.0898 4.03659 14.1761 4.1001 14.2399L8.76013 18.8999C8.82386 18.9634 8.91015 18.999 9.00012 18.999C9.09009 18.999 9.17638 18.9634 9.24011 18.8999L19.9001 8.23987C19.9637 8.17614 19.9993 8.08985 19.9993 7.99988C19.9993 7.90991 19.9637 7.82362 19.9001 7.75989Z"
        fill="white"
      />
    </svg>
  );
}

export default checkedIcon;
