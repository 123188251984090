import React from 'react';
import { SaveTemplateAs } from './SaveTemplateAs';
import { BlocksPicker } from './BlocksPicker';

export const CustomSidebar = () => {
  return (
    <div style={{ flex: 2, marginLeft: '1rem', marginRight: '1rem' }}>
      <SaveTemplateAs />
      <BlocksPicker />
    </div>
  );
};
