import { useSubscription, gql } from '@apollo/client';
import { log } from '../../utils/log';

const LEAVE_VIDEO_ROOM_SUBSCRIPTION = gql`
  subscription onUserLeaveVideoRoom($roomId: String!) {
    userLeavedVideoRoom(roomId: $roomId) {
      isDoctor
    }
  }
`;

interface IUseListenLeaveVideoRoomArgs {
  roomId: string;
  handleAction: (data: any) => void;
}

const useListenLeaveVideoRoom = ({
  roomId,
  handleAction,
}: IUseListenLeaveVideoRoomArgs) => {
  const result = useSubscription(LEAVE_VIDEO_ROOM_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      log('log', { userLeaved: data });
      handleAction(data.subscriptionData.data.userLeavedVideoRoom);
    },
  });
  log('error', result?.error);
  return result;
};

export default useListenLeaveVideoRoom;
