import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateClients from '../animations/clients.json';

export const Clients = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateClients}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Clients</h3>
        <p className={classes.description}>
          Manage your client's records and stay up-to-date with their activities
          and sessions.
        </p>
      </div>
    </div>
  );
};
