import React, { ReactElement, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Grid, Box, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ReactComponent as FolderIcon } from 'assets/icons/foldericon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIconGray.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadIconGray.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/shareIconGray.svg';
import DeleteHandler from '../DeleteHandler';
import '../../App.css';
import ReportList from '../Report/ReportList';
import ListItemWithActions from '../ui/ListItemWithActions';
import PdfViewer from '../PdfViewer';
import ViewerHeader from './ViewerHeader';
import UploadFile from './UploadFile';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  document: {
    paddingTop: '10px',
    paddingLeft: '30px',
    width: '100%',
    height: '90%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '80vh',
    minWidth: '80vw',
    boxShadow: '0px 0px 13px 0px rgba(161, 176, 196, 1)',
    borderBottom: '5px solid #1F61DC',
    outline: '0px',
    position: 'relative',
    overflow: 'hidden',
  },
  text: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
  },
  listRoot: {
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    maxHeight: '50vh',
  },
  title: {
    color: 'white',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    paddingLeft: '10px',
  },
  icon: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    color: 'black',
  },
  Input: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  MenuItemText: {
    color: '#BCC6D3',
    fontSize: '14px',
    lineHeight: '16px',
    paddingLeft: theme.spacing(2),
    '& :hover': {
      color: '#41A8FF',
      background: 'transparent',
    },
  },
  MenuItem: {
    '& .MenuItem:hover': { backgroundColor: '#BCC6D3' },
  },
}));

interface Attachment {
  name: string;
  details: string;
  attachment: string;
  _id: string;
}
interface IAttachmentViewerProps {
  attachments: Attachment[];
  attachment: Attachment;
  handleAddAttachment: (file: File) => void;
  handleEditAttachment: (
    informations: { name: string; details: string },
    id: string
  ) => void;
  handleDeleteAttachment: (id: string) => void;
  closeModal: () => void;
}
export default function AttachmentViewer({
  attachments,
  attachment,
  handleAddAttachment,
  handleDeleteAttachment,
  handleEditAttachment,
  closeModal,
}: IAttachmentViewerProps): ReactElement {
  const [dropDownEL, setDropDownEl] = React.useState<null | HTMLElement>(null);
  const [currentPdf, setPdf] = useState(attachment);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const [openEditSection, setOpenEditSection] = useState(false);

  const classes = useStyles();
  function handlePrint() {
    fetch(currentPdf.attachment).then((data) => {
      return data.blob().then((blobResult) => {
        window.open(URL.createObjectURL(blobResult), '_blank');
      });
    });
  }
  function handleDownload() {
    fetch(currentPdf.attachment).then((data) => {
      return data.blob().then((blobResult) => {
        const linkElement = document.createElement('a');
        linkElement.href = URL.createObjectURL(blobResult);
        linkElement.setAttribute('download', currentPdf.name);
        linkElement.click();
      });
    });
  }

  const handleClick = (event: any, row: any) => {
    setDropDownEl(event.currentTarget);
    setPdf(row);
  };

  return (
    <Grid className={classes.paper} container>
      <Grid
        item
        style={{ backgroundColor: '#082CAF', height: '65px' }}
        lg={12}
        sm={12}
        md={12}
        xl={12}
        xs={12}
      >
        <ViewerHeader
          editSection={openEditSection}
          handleDownload={handleDownload}
          handlePrint={handlePrint}
          closeModal={closeModal}
        />
      </Grid>
      <Grid
        item
        style={{
          backgroundColor: '#ECF4F7',
          padding: '10px',
          height: '100vh',
        }}
        lg={4}
      >
        <UploadFile handleAddAttachment={handleAddAttachment} />
        <Box p={2}>
          <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id="simple-menu"
            anchorEl={dropDownEL}
            open={Boolean(dropDownEL)}
            onClose={() => setDropDownEl(null)}
          >
            <MenuItem
              onClick={() => {
                setEditableIndex(currentIndex);
                setDropDownEl(null);
              }}
              className={classes.MenuItem}
            >
              <EditIcon style={{ width: '14px' }} />
              <span className={classes.MenuItemText}>Edit</span>
            </MenuItem>
            <MenuItem className={classes.MenuItem}>
              <DeleteHandler
                handleDelete={() => {}}
                handleAction={(id: string) => {
                  handleDeleteAttachment(id);
                  setDropDownEl(null);
                  setPdf(attachments[0]);
                }}
                iconButton={false}
                value={currentPdf}
                DeleteIcon={
                  <Box>
                    <DeleteIcon />
                    <span className={classes.MenuItemText}>Delete</span>
                  </Box>
                }
              />
            </MenuItem>
            <MenuItem className={classes.MenuItem}>
              <ShareIcon /> <span className={classes.MenuItemText}>Share</span>
            </MenuItem>
            <MenuItem
              onClick={() => handleDownload()}
              className={classes.MenuItem}
            >
              <DownloadIcon />{' '}
              <span className={classes.MenuItemText}> Download</span>
            </MenuItem>
          </Menu>

          <ReportList>
            {attachments.map((element: any, index: number) => {
              return (
                <>
                  <ListItemWithActions
                    primaryData={element.name}
                    secondaryData={element.details}
                    element={element}
                    type="normal"
                    handleEdit={handleEditAttachment}
                    editable={index === editableIndex}
                    setEditableIndex={setEditableIndex}
                    AvatarIcon={<FolderIcon style={{ color: 'gray' }} />}
                    handleAction={() => {
                      setPdf(element);
                    }}
                    rightActions={
                      <MoreHorizIcon
                        style={{
                          cursor: 'pointer',
                          fontSize: '24px',
                          color: '#425364',
                        }}
                        onClick={(e) => {
                          handleClick(e, element);
                          setCurrentIndex(index);
                        }}
                      />
                    }
                  />
                </>
              );
            })}
          </ReportList>
        </Box>
      </Grid>
      <Grid item lg={8} style={{ position: 'relative' }}>
        <PdfViewer attachment={currentPdf?.attachment} />
      </Grid>
    </Grid>
  );
}
