export const _file = {
  isPdf: (str: string) => {
    return str === 'application/pdf';
  },
  isImage: (str: string) => {
    return str.includes('image');
  },
  isPowerPoint: (str: string) => {
    return (
      str === 'application/vnd.ms-powerpoint' ||
      str ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
  },
  isExcel: (str: string) => {
    return (
      str === 'application/vnd.ms-excel' ||
      str ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  },
  isMp3: (str: string) => {
    return str === 'audio/mpeg';
  },
  isTxt: (str: string) => {
    return str === 'text/plain';
  },
  isZip: (str: string) => {
    return str === 'application/zip';
  },
  isWord: (str: string) => {
    return (
      str === 'application/msword' ||
      str ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  },
};
