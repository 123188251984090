import { gql, useQuery } from '@apollo/client';

const GET_STAFF_BY_DOCTOR = gql`
  query getStaffByDoctor {
    getStaffsByDoctor {
      _id
      user {
        _id
        email
        firstname
        lastname
        middlename
        role
        picture
      }
      organization
      practice
      staffOf {
        _id
        noUsedFeatures
        firstname
        middlename
        username
        lastname
        email
        picture
      }
    }
  }
`;

const useGetStaffByDoctor = () => {
  const { data, loading, error } = useQuery(GET_STAFF_BY_DOCTOR);
  return { data, loading, error };
};

export { GET_STAFF_BY_DOCTOR };
export default useGetStaffByDoctor;
