import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBlock: theme.typography.pxToRem(50),
  },
  iconBox: {
    width: theme.typography.pxToRem(195),
    height: theme.typography.pxToRem(195),
  },
  emptyText: {
    marginTop: theme.typography.pxToRem(10),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(35),
  },
  button: {
    fontStyle: 'Poppins',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: theme.typography.pxToRem(5),
    minWidth: theme.typography.pxToRem(160),
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(31, 97, 220, 1)',
    paddingInline: '1rem',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  formModal: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '2rem',
    [theme.breakpoints.only('xl')]: {
      width: '60vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  defaultForms: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.typography.pxToRem(20),
  },
  modalSubTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: 'rgba(39, 49, 66, 1)',
    lineHeight: theme.typography.pxToRem(46),
  },
  formBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    overFlowX: 'scroll',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    cursor: 'pointer',
  },
  formIcon: {
    width: '115px',
    height: '85px',
  },
  formName: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    color: 'rgba(66, 83, 100, 1)',
    whiteSpace: 'nowrap',
  },
  modalFormIcon: {
    width: '170px',
    height: '170px',
  },
  reflectionModal: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xl')]: {
      width: '30vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: 'rgba(31, 97, 220, 1)',
    lineHeight: theme.typography.pxToRem(46),
  },
  guided: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected?: boolean }) =>
      selected
        ? '2px solid rgba(45, 199, 109, 1)'
        : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '16px 16px 0px 0px',
    cursor: 'pointer',
  },
  free: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected?: boolean }) =>
      !selected
        ? '2px solid rgba(45, 199, 109, 1)'
        : '1px solid rgba(188, 198, 211, 1)',
    borderRadius: '0px 0px 16px 16px',
    cursor: 'pointer',
  },
  guidedTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    color: 'rgba(66, 83, 100, 1)',
  },
  guidedText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(128, 151, 177, 1)',
  },
  backText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(45, 199, 109, 1)',
    marginLeft: '8px',
  },
  nextText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(86, 127, 189, 1)',
    marginInline: '8px',
  },
  scrollBar: {
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '3rem',
    maxHeight: '67vh',
  },
  PreviewScrollBar: {
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '3rem',
    maxHeight: '75vh',
  },
  labelText: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(21),
    color: 'rgba(66, 83, 100, 1)',
    marginLeft: '8px',
  },
  Desc: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(18),
    color: 'rgba(130, 130, 130, 1)',
  },
  inputDate: {
    width: '100%',
  },
  subLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '23px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.4rem',
    whiteSpace: 'nowrap',
  },
  input: {
    minWidth: '95px',
    width: 'fit-content',
    marginLeft: '2rem',
  },
  numberInput: {
    maxWidth: '80px',
  },
  previewBox: {
    boxSizing: 'border-box',
    marginTop: theme.typography.pxToRem(105),
    borderRadius: '20px',
    boxShadow: '0px 0px 13px 0px rgba(161, 176, 196, 0.35)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingInline: '2rem',
    marginBottom: theme.typography.pxToRem(50),
  },
  previewIllustration: {
    marginTop: theme.typography.pxToRem(-130),
  },
  previewQueston: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    color: 'rgba(66, 83, 100, 1)',
    marginLeft: '12px',
  },
  previewInput: {
    width: '100%',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: '20px',
    border: '1px solid rgba(161, 176, 196, 1)',
    '&:focus': {
      border: '1px solid #3f50b5',
    },
  },
  notchedOutline: {
    border: 'none !important',
  },
  previewButtons: {
    marginTop: '2rem',
    gap: '1rem',
  },
  CancelPreview: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    border: '1px solid rgba(215, 227, 241, 1)',
    color: 'rgba(31, 97, 220, 1)',
    textTransform: 'capitalize',
    fontSize: '18px',
    lineHeight: '21px',
    width: '100%',
    fontWeight: 400,
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(215, 227, 241, 1)',
    },
  },
  SavePreview: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 600,
    width: '100%',
    border: '1px solid #1F61DC',
    borderRadius: '25px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  moodBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    paddingBlock: theme.typography.pxToRem(10),
    borderRadius: '10px',
    border: '0.5px solid rgba(164, 164, 164, 1)',
    flex: '30%',
    maxWidth: '30%',
    height: theme.typography.pxToRem(80),
    cursor: 'pointer',
    marginTop: '1rem',
  },
  moodText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(18),
    color: 'rgba(66, 83, 100, 1)',
  },
  uploadIcon: {
    fontSize: '30px',
    marginRight: theme.spacing(2),
    backgroundColor: '#E4ECF7',
    padding: theme.spacing(0.5),
    borderRadius: '8px',
  },
  sleepBox: {
    borderRadius: '100%',
    border: 'none',
    zIndex: 10,
  },
  line: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  InputLabel: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: theme.typography.pxToRem(21),
  },
  TextFiled: {
    width: '100%',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: '5px',
    border: '1px solid rgba(164, 164, 164, 1)',
    '&:focus': {
      border: '1px solid rgba(31, 97, 220, 1)',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  errorBox: {
    position: 'absolute',
    bottom: '-1.5rem',
  },
  done: {
    backgroundColor: '#BEFFD7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#0AAB4D',
  },
  pending: {
    backgroundColor: '#FFD8AD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#AE6209',
  },
  overdue: {
    backgroundColor: '#FFC6C5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#D71300',
  },
  tableButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    border: '0.5px solid rgba(66, 83, 100, 1)',
  },
  viewButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    padding: '6px 8px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 1)',
    borderRadius: '5px 0px 0px 5px',
    borderRight: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  downloadButton: {
    backgroundColor: 'rgba(215, 227, 241, 1)',
    maxWidth: '27px',
    minWidth: '27px',
    height: '100%',
    boxSizing: 'border-box',
    border: '0.5px solid rgba(66, 83, 100, 1)',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(66, 83, 100, 1)',
    textTransform: 'none',
    marginLeft: theme.typography.pxToRem(5),
    userSelect: 'none',
  },
  menu: {
    width: '10rem',
    borderRadius: '8px',
    padding: '0',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    border: '1px solid #FFAA15',
    borderRadius: '6px',
    padding: '5px',
    backgroundColor: '#FFAA1510',
    color: '#FFAA15',
  },
  info: {
    fontSize: '14px',
  },
  infoIcon: {
    fontSize: '15px',
    cursor: 'pointer',
  },
  cta: {
    fontWeight: 'bold',
    marginLeft: '5px',
    color: '#1F61DC',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  radioWrapper: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '23px',
      fontWeight: 500,
      color: '#344054',
      marginBottom: 0,
    },
  },
  radio: {
    backgroundColor: 'transparent !important',
  },
  radioExtra: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '23px',
    fontWeight: 500,
    color: '#344054',
    marginLeft: '16px',
  },
  inputOcc: {
    width: '50px',
  },
  disabledRadio: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));
