import React, { ReactElement } from 'react';
import useStyles from './style';
import { Grid, Box } from '@material-ui/core';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import WorkingHour from './WorkingHour';
import Text from 'Text';

const WorkingHours = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <Text i18nKey="Working hours" className={classes.h3}>
            Availability
          </Text>
        </Grid>
      </Grid>
      <Box mt="2rem">
        <WorkingHour />
      </Box>
    </>
  );
};
export default WorkingHours;
