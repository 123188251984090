import React, { useState } from 'react';
import { ConflictComponent } from './ConflictComponent';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as BackIcon } from 'assets/conflicts/back.svg';
import Pagination from 'components/ui/Pagination';
import ScrollBar from 'components/ui/ScrollBar';
import {
  calculatePaginationTabs,
  getLimitData,
} from 'components/ui/Table/tableutils';
import { IConflict } from './types';
import Modals from 'pages/InboxPage/modals';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';

type IConflictModal = {
  onClose: () => void;
  data: IConflict[][];
  isBack?: boolean;
};
export const ConflictModal = ({
  onClose,
  data,
  isBack = true,
}: IConflictModal) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(1);
  const { patientAdded } = useProvider();

  const handlePagination = (e: unknown, pageNumber: number) => {
    setPage(pageNumber);
  };

  const limitedData = getLimitData<IConflict[]>(page, 4, data, true);

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {isBack && <BackIcon className={classes.back} onClick={onClose} />}
      </Box>
      <ScrollBar className={classes.scroller}>
        <Box className={classes.conflicts}>
          {limitedData?.map((el) => (
            <ConflictComponent conflictedEvents={el} onClose={onClose} />
          ))}
        </Box>
      </ScrollBar>
      <Box className={classes.paginationContainer}>
        <Pagination
          count={calculatePaginationTabs(4, data?.length)}
          onChange={handlePagination}
          page={page}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <Modals
        subject=""
        contact={[
          {
            firstname: patientAdded?.firstname,
            lastname: patientAdded?.lastname,
            middlename: patientAdded?.middlename,
            _id: patientAdded?._id,
            email: patientAdded?.email,
            picture: patientAdded?.picture,
          },
        ]}
      />
    </Box>
  );
};
