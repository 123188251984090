import { gql, useMutation } from '@apollo/client';
import {
  referralDoctorFragment,
  referralMainInformationsFragment,
} from './fragments';

const EDIT_REFERRAL = gql`
  mutation editReferral($referralInput: editReferralInput!, $referralId: ID!) {
    editReferral(referralInput: $referralInput, referralId: $referralId) {
      ...referral
      appointment
      referredTo {
        ...referralDoctor
      }
    }
  }
  ${referralDoctorFragment}
  ${referralMainInformationsFragment}
`;

export default function useEditReferral() {
  const [editReferral, result] = useMutation(EDIT_REFERRAL, {});
  return { editReferral, ...result };
}
