import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
  },
  beforetext:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  select: {
    width: '150px',
  },
  inputOcc: {
    width: '55px',
  },
  descReminder: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  titleReminder: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    color: '#273142',
  },
  sectionReminder:{
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    color: '#273142',
  },
  reminderText: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 600,
    color: '#101828',
  },
  noApointment: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
  },
  errorIcon: {
    fontSize: 14,
    marginRight: '1px',
  },
  subtitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#0054B6',
  },
  summary: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
  summaryTopArrow: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    '& .MuiAccordionSummary-root': {
      alignItems: 'flex-start',

      '& .MuiAccordionSummary-expandIcon': {
        top: 9,
        opacity: 0.5,
      },
    },
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.5rem',
  },
  divider: {
    marginTop: '0.8rem',
    marginBottom: '1.2rem',
  },
  sectionTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '1rem',
  },
}));

export default useStyles;
