import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';
import React from 'react';

interface IProgressLoaderProps {
  height?: number;
  width?: number;
  color?: string;
}

const ProgressLoader = styled(({ theme, ...props }: any) => (
  <CircularProgress {...props} />
))({
  color: ({ color = '#E0E6FF' }: IProgressLoaderProps) => color,
  width: ({ width }: IProgressLoaderProps) => `${width}px !important`,
  height: ({ height }: IProgressLoaderProps) => `${height}px !important`,
});

export default ProgressLoader;
