import React, { FC, useCallback } from 'react';
import {
  Avatar,
  Badge,
  Chip,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import TrashIcon from 'assets/TrashIcon';
import { getHourAndMinutesFromDate, formatDateMessage } from 'utils';
import concatName, { participantNames } from 'utils/userUtils';
import useStyles from './styles';
import ConversationItemPopover from './ConversationItemPopover';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useProvider } from 'pages/InboxPage/state/Provider';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { useLocation } from 'react-router-dom';
import { ReactComponent as GroupChatIcon } from 'assets/GroupChatIcon.svg';
import Text from 'Text';

interface IConversationItemProps {
  handleSelected: (threadId: string) => void;
  selected: string;
  data: GetAllThreadConversationByUser_getAllThreadConvertationByUser;
  messages: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
}
const ConversationItem: FC<IConversationItemProps> = ({
  data,
  handleSelected,
  selected,
  messages,
}) => {
  const classes = useStyles({ isPatient: false });
  const location = useLocation();
  const { setMessageHidden } = useProvider();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const {
    updatedAt,
    _id,
    creator,
    lastMessage,
    unReadItMessage,
    lastUpdateTime,
    participants,
  } = data;
  const isReadItMessage = (unReadItMessage, userId, lastUpdateTime) => {
    if (unReadItMessage) {
      const readItMessage = unReadItMessage.filter(
        (item) => item.user === userId
      );
      if (readItMessage.length === 0) {
        return false;
      }
      const { lastMessageReadIt } = readItMessage[0];
      return lastMessageReadIt >= lastUpdateTime;
    }
    return false;
  };

  const {
    content: { text },
  } = lastMessage;
  const { firstname, middlename, lastname, picture } = creator;
  const participant = participants
    ?.map((el) => el?.user)
    .filter((element) => element?._id !== (user?._id || user?.user?._id));
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleSelectedThead = useCallback(() => {
    handleSelected(_id);
    setMessageHidden(false);
  }, [handleSelected, _id]);
  const handleConfirmModalDelete = (event: any) => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { handelDeleteThreadConversation } = useProvider();
  const handleDeleteItem = () => {
    handelDeleteThreadConversation(_id);
  };
  const handleDelete = () => {
    handleDeleteItem();
    handleClose();
  };

  return (
    <ListItem
      className={clsx(selected === _id ? classes.itemIsSelected : classes.item)}
      button
      onClick={handleSelectedThead}
    >
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleConfirmModalDelete}>
          <TrashIcon width="20px" height="20px" className={classes.icon} />
          <Text i18nKey='delete'>Delete</Text>
        </MenuItem>
      </Menu>
      <ListItemAvatar>
        {participant?.length > 1 ? (
          <GroupChatIcon className={classes.Avatar} />
        ) : (
          <Avatar
            alt={participant && participant[0]?.firstname.toUpperCase()}
            src={participant && participant[0]?.picture}
            className={classes.Avatar}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        className={classes.ListItemTextConversationItem}
        primary={
          <div style={{ display: 'flex' }}>
            {/* {!isReadItMessage(unReadItMessage, user?._id, lastUpdateTime) && (
              <Badge
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                variant="dot"
                className={classes.Badge}
              />
            )} */}
            <Typography
              className={clsx(
                !isReadItMessage(unReadItMessage, user?._id, lastUpdateTime)
                  ? classes.TitleItemConversationUnread
                  : classes.TitleItemConversation
              )}
            >
              {participantNames(participant)}
            </Typography>
          </div>
        }
        secondary={
          <div>
            <div className={classes.noWarpText}>
              <Typography component="span" variant="subtitle2">
                {text || 'Attachment'}
              </Typography>
            </div>
          </div>
        }
      />
      <ListItemSecondaryAction style={{ top: '36%' }}>
        <Grid container direction="column">
          <Grid item style={{ marginBottom: '0px' }}>
            <Typography
              component="span"
              variant="caption"
              align="justify"
              className={classes.time}
            >
              {formatDateMessage(updatedAt)}
            </Typography>
          </Grid>
          {/* <Grid item>
            <IconButton
              aria-describedby={_id}
              color="secondary"
              onClick={handleClick}
              className={classes.iconButton}
            >
              <MoreHorizIcon
                data-cy="more-action"
                className={classes.MoreHorizIcon}
              />
            </IconButton>
          </Grid> */}
        </Grid>
      </ListItemSecondaryAction>
      <ModalDelete
        text="conversation"
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </ListItem>
  );
};

export default ConversationItem;
