import { Box, Grid, makeStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { gql, useQuery } from '@apollo/client';
import { config } from 'config';
import * as yup from 'yup';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import { usePatientList } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import {
  stringToDate,
  DisableMinuteWhenEmpty,
  changeHour,
  convertTime,
  getTimeDate,
  addStart,
} from 'utils';
import {
  convertStringToEditorState,
  convertToRawAndStringify,
} from '@eggmed/common/utils/DraftUtils';
import {
  convertObjectsFormat,
  editModeDefaultValues,
} from 'pages/SchedulePage/AddEventPage/utils';
import EventForm from './EventForm';
import {
  IAppointmentSubmitedData as IAppointmentSubmittedData,
  IDateRange,
} from 'pages/SchedulePage/AddEventPage/types';
import { convertDateLikeMeet } from 'utils/dateUtils';
import { EditorState } from 'draft-js';
import Dayjs from 'dayjs';
import { useToast } from 'hooks/useToast';
import { useGetDoctorAccess } from '@eggmed/graphql-client/operations/patientAccessControlOperations/useGetPatientDoctorAccess';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { EventType } from 'pages/SchedulePage/AddEventPage/Conflicts/ConflictExpanded';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : '#F8FAFB',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    outline: '0px',
    padding: theme.spacing(4),
    position: 'relative',
    '& ::-webkit-scrollbar': {
      webkitAppearance: 'none',
    },
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '10px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100vw',
      maxHeight: '100vh',
      padding: theme.spacing(1),
      borderRadius: '0px',
    },
  },
  eventForm: {
    maxHeight: '80vh',
    overflow: 'scroll',
    [theme.breakpoints.only('xl')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100vw',
      maxHeight: '98.5vh',
    },
  },
  modalContent: {
    backgroundColor: 'white',
    width: '74vw',
    maxWidth: '74vw',
    height: '87vh',
    maxHeight: '87vh',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      width: '75vw',
      maxWidth: '75vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '77vw',
      maxWidth: '77vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '91vw',
      maxWidth: '91vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0px',
    },
  },
  succesfullIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
  },
  textAppointment: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(27),
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#1F61DC',
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  appointmentModal: {
    width: '35vw',
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
      maxWidth: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
    },
    // [theme.breakpoints.only('xl')]: {
    //   height: '70vh',
    //   width: '35vw',
    // },
    // [theme.breakpoints.only('lg')]: {
    //   height: '65vh',
    //   width: '35vw',
    // },
    // [theme.breakpoints.down('md')]: {
    //   height: '60vh',
    //   width: '80vw',
    // },
  },
}));

const DOCTOR_VIDEO_ROOM_QUERY = gql`
  query GetDoctorVideoRoomLink($doctorId: ID!) {
    videoRoom(doctorId: $doctorId) {
      _id
      room_id
    }
  }
`;
const PATIENTS_OWNERS = gql`
  query patientOwners {
    patients {
      _id
      firstname
      lastname
      email
      phone
      country
    }
  }
`;
export const DOCTOR_FREE_TIMES = gql`
  query doctorFreeTime($doctorId: ID!, $startDate: String, $endDate: String) {
    availableTimes(
      doctorId: $doctorId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

interface IData {
  title: string;
  description: EditorState;
  location: string;
  locationLink: string;
  conditionType: string[];
  doctor: { _id: string };
  members: string[];
  sessionType: {
    rate: string;
    currency: string;
    duration: string;
    session: string;
    __typename?: string;
  };
}
interface IEventData {
  startDate: string;
  endDate: string;
}
interface IAddEventPageProps {
  addEvent: any;
  doctorId?: string;
  closeModal: () => void;
  editMode: boolean;
  eventData?: IEventData;
  loadingAppointment?: boolean;
  patientId: string;
  clickableEvent?: { startDate?: string };
  isClickable?: boolean;
  setIsClickable?: (isClickable?: boolean) => void;
}
const schema = yup.object().shape({
  locationLink: yup.string(),
  doctor: yup.object().required('Doctor is a required field'),
  members: yup.array(),
});
export default function AddEventPage({
  addEvent,
  editMode,
  doctorId,
  patientId,
  eventData,
  closeModal,
  clickableEvent,
  isClickable,
  setIsClickable,
  loadingAppointment,
}: IAddEventPageProps): JSX.Element {
  const timeValues = addStart(isClickable ? clickableEvent : eventData);
  const {
    modalAddOpen,
    handleCloseEditMode,
    handleOpenDelete,
    handelDelete,
    openDeleteModal,
    handleCloseDeleteModal,
    currentEvent,
    handleChangeCurrentDate,
    doctorData,
    patient,
  } = useProvider();
  const { triggerSnack } = useSnackbar();
  const [activeMeeting, setActiveMeeting] = useState<boolean>(
    currentEvent?.locationLink ? true : false
  );
  React.useEffect(() => {
    setActiveMeeting(currentEvent?.locationLink ? true : false);
  }, [currentEvent]);

  const { openSuccess, triggerToast } = useToast();

  const [time, setTime] = useState<string>('');
  useEffect(() => {
    setTime(timeValues);
  }, [eventData, isClickable]);

  const classes = useStyles({ openSuccess });
  const { t } = useTranslation();
  // const { data: doctorsData, loading } = useGetDoctorAccess();
  const { data: doctorsData, loading } = useQuery(PATIENTS_RELATED);
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: editMode
      ? new Date(stringToDate(eventData.startDate))
      : !isClickable
      ? new Date(Date.now())
      : new Date(stringToDate(clickableEvent?.startDate)),
    endDate: editMode
      ? new Date(stringToDate(eventData.endDate))
      : new Date(
          new Date(convertDateLikeMeet(Date.now())).setHours(
            convertDateLikeMeet(Date.now()).getHours() + 1
          )
        ),
  });
  const getDateWithOutTime = new Date(dateRange?.startDate).toDateString();
  const startDateTime = new Date(
    new Date(new Date(dateRange?.startDate).setMinutes(0)).setHours(10)
  )
    .toISOString()
    .replace('.935Z', '.000+00:00');
  const endDateTime = stringToDate(
    new Date(startDateTime).setHours(20).toString()
  )
    .toISOString()
    .replace('.837Z', '.000+00:00');
  const dateNow = Dayjs(new Date()).format('DD/MM/YY');
  const dateBgin = Dayjs(new Date(dateRange.startDate)).format('DD/MM/YY');
  const alwaysDate = new Date(
    new Date(new Date(dateRange?.startDate).setHours(8)).setMinutes(0)
  )
    .toISOString()
    .replace('.000Z', '.000+00:00');

  const doctorsArray = React.useMemo(
    () => convertObjectsFormat(doctorsData!?.patientsRelated, 'Doctors'),
    [doctorsData]
  );

  const latestDoctor = doctorsArray && doctorsArray[doctorsArray.length - 1];
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: editMode
      ? editModeDefaultValues(eventData)
      : !editMode && latestDoctor
      ? editModeDefaultValues(eventData, latestDoctor, true)
      : {},
  });
  const { register, errors, handleSubmit, control, getValues, watch } = methods;
  const { data: freeTimes, loading: loadingFreeTimes } = useQuery(
    DOCTOR_FREE_TIMES,
    {
      variables: {
        doctorId: watch('doctor')?._id,
        startDate: dateNow === dateBgin ? startDateTime : alwaysDate,
        endDate: endDateTime,
      },
    }
  );
  const { data: doctorVideoRoomData } = useQuery(DOCTOR_VIDEO_ROOM_QUERY, {
    variables: { doctorId: watch('doctor')?._id },
  });
  const doctorFreeTimes = freeTimes?.availableTimes;
  const { reset } = methods;

  const handleDefaultValues = useCallback(
    (eventData) => {
      reset(
        editMode
          ? editModeDefaultValues(eventData)
          : !editMode && latestDoctor
          ? editModeDefaultValues(eventData, latestDoctor, true)
          : {}
      );
      setDateRange({
        startDate: editMode
          ? new Date(stringToDate(eventData.startDate))
          : !isClickable
          ? convertDateLikeMeet(Date.now())
          : new Date(stringToDate(clickableEvent?.startDate)),
        endDate: editMode
          ? new Date(stringToDate(eventData.endDate))
          : new Date(
              new Date(convertDateLikeMeet(Date.now())).setHours(
                convertDateLikeMeet(Date.now()).getHours() + 1
              )
            ),
      });
    },
    [editMode, reset, isClickable, latestDoctor]
  );

  useEffect(() => {
    handleDefaultValues(eventData);
  }, [eventData, handleDefaultValues, isClickable]);
  function handleChangeDate(
    name: 'endDate' | 'startDate',
    value: string | number
  ): void {
    setDateRange((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange[name] = value;
      handleChangeCurrentDate(newDateRange[name]);
      return { ...oldDateRange, [name]: value };
    });
  }
  const [error, setError] = useState('');
  async function onSubmit(data: IData) {
    const {
      title,
      description,
      location,
      locationLink,
      conditionType,
      doctor,
      members,
    } = data;
    const submitData: IAppointmentSubmittedData = {
      title,
      description: description && convertToRawAndStringify(description),
      location,
      locationLink: activeMeeting
        ? `${config.EGGMED_DOCTOR_ROOM_LINK}${doctorVideoRoomData?.videoRoom?.room_id}`
        : '',
      conditionType,
      patient: patientId,
      members: members ? members.map((member: any) => member.email) : [],
      doctor: doctor._id,
      doctorGoing: 'maybe',
      startDate: convertTime(getDateWithOutTime, time, patient?.timeZone),
    };
    const result: void | any = await addEvent(submitData);
    if (result instanceof Error) {
      return;
    }
    triggerSnack();
    setTimeout(() => closeModal(), 1000);
  }
  function handleClose() {
    handleCloseEditMode();
    setIsClickable(false);
  }
  return (
    <>
      <FormProvider {...methods}>
        <BasicModal
          open={modalAddOpen}
          onClose={() => handleClose()}
          isFromModal
          onSubmit={onSubmit}
          handleClose={() => handleClose()}
          className={classes.appointmentModal}
          title={editMode ? t('Update session') : t('Book session')}
          titlePadding="2rem"
          divider
          disablePadding
          loading={loadingAppointment}
          isPatient
          editMode={editMode}
          handleDelete={handleOpenDelete}
          isSlide
        >
          <Grid
            container
            spacing={0}
            style={{
              marginBottom: '5vh',
              paddingInline: '2rem',
              paddingTop: '10px',
            }}
          >
            <EventForm
              getValues={getValues}
              activeMeeting={activeMeeting}
              setActiveMeeting={setActiveMeeting}
              doctors={doctorsArray}
              loadingData={loading}
              handleChangeDate={handleChangeDate}
              dateRange={dateRange}
              errors={errors}
              error={error}
              control={control}
              setError={setError}
              register={register}
              doctorFreeTimes={doctorFreeTimes}
              meetingLink={
                currentEvent?.locationLink
                  ? `${config.EGGMED_DOCTOR_ROOM_LINK}${doctorVideoRoomData?.videoRoom?.room_id}`
                  : ''
              }
              time={time}
              setTime={setTime}
              loadingFreeTimes={loadingFreeTimes}
              timeValues={timeValues}
              editMode={editMode}
              watch={watch}
              isClickable={isClickable}
              clickableEvent={clickableEvent}
            />
          </Grid>
        </BasicModal>
      </FormProvider>
      <ModalDelete
        text={currentEvent?.title ? EventType.EVENT : EventType.APPOINTMENT}
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => {
          handelDelete(currentEvent?._id);
          handleClose();
        }}
      />
    </>
  );
}
