import React from 'react';

function UncheckedIcon({ color = '#39B54A' }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4C0.5 2.067 2.067 0.5 4 0.5Z"
        fill="#ECF4F7"
        stroke={'#D0D5DD'}
      />
    </svg>
  );
}

export default UncheckedIcon;
