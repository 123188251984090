import { createContext, useContext } from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  snackBar: {
    minWidth: 'auto',
  },
}));
export const Context = createContext(undefined);
interface SnackBarProps {
  children: React.ReactNode;
}
export default function SnackProvider({ children }: SnackBarProps) {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState(t('Changes saved successfully'));
  function triggerSnack(message?: string) {
    setOpenSnackbar(true);
    if (message) {
      setMessage(message);
    }
  }
  const classes = useStyles();
  return (
    <Context.Provider
      value={{
        triggerSnack,
      }}
    >
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message={t(message)}
        ContentProps={{
          classes: {
            message: classes.snackBar,
            root: classes.snackBar,
          },
        }}
      />
    </Context.Provider>
  );
}
export function useSnackbar() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useSnack must be used within a snackProvider');
  }

  return context;
}
