import React, { ReactElement } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ScrollBar from 'components/ui/ScrollBar';
import NotificationCard from './accessCard';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Menu from 'components/ui/Menu';
import useDeleteNotification from '@eggmed/graphql-client/operations/notificationOperations/useDeleteNotification';
import Tabs from 'components/ui/Tabs';
import AccessRequests from './AccessRequestsPage';
import PatientAccessPage from './AccessPermissionsPage';
import AccessListHeader from './AccessListHeader';
import useGetPatientAccess from '@eggmed/graphql-client/operations/patientAccessControlOperations/useGetPatientAccess';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  accessControlBox: {
    minHeight: '50vh',
    paddingBottom: '1rem',
    background: 'white',
    borderRadius: '45px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      height: '100%',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '46px',
    letterSpacing: '0.44px',
    color: '#425364',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#273142',
      textTransform: 'none',
    },
  },
  tabs: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
}));

const AccessControlPage = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const [customValue, setCustomValue] = React.useState(0);
  const [limit, setLimit] = React.useState<number>(10);
  const handleNavigate = () => {
    history.push('/patient/access_control');
  };
  const { data } = useGetPatientAccess();
  const doctors = data?.patientAccess?.access.map(
    ({ doctor, permissions, hasAccess }) => {
      const { _id, firstname, lastname, middlename, picture } = doctor || {};
      return {
        _id,
        firstname,
        lastname,
        middlename,
        picture,
        permissions,
        hasAccess,
      };
    }
  );
  return (
    <Box className={classes.accessControlBox} px={6}>
      <Grid container>
        <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
          <Typography className={classes.title}>Access control</Typography>
        </Grid>
        <Grid
          container
          xs={12}
          lg={12}
          xl={12}
          md={12}
          sm={12}
          style={{
            marginBlock: '15px',
          }}
        >
          <Tabs
            tabsMode="greenButtons"
            classNameTab={classes.tabs}
            tabsBar={['Access Requests', 'Access Permissions']}
            tabsContent={[
              <AccessRequests />,
              <PatientAccessPage limit={limit} />,
            ]}
            hasNoHeight
            customValue={customValue}
            setCustomValue={setCustomValue}
            ComponentAlignedWithTabs={
              customValue === 1 &&
              doctors &&
              doctors.length > 0 && (
                <AccessListHeader
                  handleOpen={handleNavigate}
                  limit={limit}
                  setLimit={setLimit}
                />
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessControlPage;
