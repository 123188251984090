import { gql, useMutation } from '@apollo/client';

export const UPDATE_PATIENT_REQUEST = gql`
  mutation updatePatientRequest($id: ID!) {
    updatePatientRequest(id: $id) {
      _id
    }
  }
`;

const useUpdatePatientRequest = () => {
  const [updatePatientRequest] = useMutation(UPDATE_PATIENT_REQUEST);
  async function handleUpdatePatientRequest(id: string) {
    try {
      return await updatePatientRequest({
        variables: { id },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleUpdatePatientRequest };
};

export default useUpdatePatientRequest;
