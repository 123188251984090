import { Box, Radio, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as VisaLogo } from 'assets/VisaLogo.svg';
import DropDownMenu from '../Payment/DropDownMenu';
import { ReactComponent as DropDownIcon } from 'assets/DropDownIcon.svg';
import { IuserCreditCard } from './types';
import { ReactComponent as MasterCard } from 'assets/MasterCardIcon.svg';
import { useForm } from 'react-hook-form';
import Switch from 'components/ui/Switch';
import Button from 'components/ui/Button';
import Text from 'Text';
import { useMutation, gql } from '@apollo/client';
import { GET_CREDIT_CARD } from 'pages/AdminPage/index';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';

type IBillingRows = {
  dropDownEL: null | HTMLElement;
  setDropDownEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  creditCards: { userCreditCard: IuserCreditCard[] };
  handleChange: (e, row: IuserCreditCard) => void;
  handleClickDrop: (e, row: IuserCreditCard) => void;
  handleEdit: () => void;
  handleOpenDelete: () => void;
  currentRow: IuserCreditCard;
  setCardId: (id: string) => void;
  editMode?: boolean;
  setEditMode?: (mode: boolean) => void;
  openForm?: boolean;
};

const useStyles = makeStyles((theme) => ({
  cardLabel: {
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: '#101828',
  },
  defaultText: {
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color: '#175CD3',
    fontSize: '12px',
  },
  defaultTexting: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
  },
  defaultBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFF8FF',
    border: '1px solid #B2DDFF',
    borderRadius: '16px',
    padding: '2px 8px',
  },
  expireLabel: {
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#475467',
    fontFamily: 'Inter',
  },
  canceling: {
    marginRight: theme.spacing(2),
    backgroundColor: 'transparent',
    border: '1px solid rgba(130, 130, 130, 1)',
    color: '#425364',
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '21px',
    minWidth: '94px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    borderRadius: '5px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(130, 130, 130, 1)',
    },
  },
  saveing: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    minWidth: '94px',
    border: '1px solid #1F61DC',
    borderRadius: '5px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#1D31AA',
    },
    '&:disabled': {
      backgroundColor: '#D0D5DD',
      color: 'white',
      border: '1px solid #D0D5DD',
    },
  },
  deleting: {
    backgroundColor: '#FFFFFF',
    color: '#D92D20',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    minWidth: '94px',
    border: '1px solid #D92D20',
    borderRadius: '8px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #D92D20',
    },
  },
  editbtnForm: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    color: '#101828',
    textDecoration: 'underline',
  },
}));

const BillingRows = ({
  creditCards,
  handleChange,
  handleClickDrop,
  dropDownEL,
  setDropDownEl,
  handleOpenDelete,
  handleEdit,
  currentRow,
  setCardId,
  editMode,
  setEditMode,
  openForm,
}: IBillingRows) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { register, control, errors, handleSubmit, reset, watch } = useForm({
    // defaultValues: {
    //   defaultCard: false,
    // },
  });
  const [indexing, setIndexing] = React.useState(null);
  const [card, setCard] = React.useState(null);
  const [setDefaultCard, { loading }] = useMutation(SET_DEFAULT_CARD, {
    refetchQueries: [
      {
        query: GET_CREDIT_CARD,
        variables: { userid: user?._id || user?.user?._id },
      },
    ],
  });
  async function onSubmit(data) {
    if (data?.defaultCard && !card?.defaultCard) {
      await setDefaultCard({
        variables: {
          paymentMethodId: card._id,
        },
      });
      setEditMode(false);
    } else {
      setEditMode(false);
    }
  }
  return (
    <>
      {creditCards?.userCreditCard
        ?.slice()
        ?.reverse()
        ?.map((el, index) => (
          <Box
            borderBottom="1px solid #F4F2EE"
            width="70%"
            pb={editMode && index === indexing && 2}
            style={{
              opacity: (editMode && index !== indexing) || openForm ? 0.5 : 1,
              cursor:
                (editMode && index !== indexing) || openForm
                  ? 'not-allowed'
                  : 'default',
            }}
          >
            <Box display="flex" justifyContent="space-between" py={2} mb={1}>
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <MasterCard />
                  <Box ml={1} display="flex" alignItems="center">
                    <Typography className={classes.cardLabel}>
                      **** {el.cardNumber?.substr(el.cardNumber?.length - 4)}{' '}
                    </Typography>
                    {el?.defaultCard && (
                      <Box className={classes.defaultBox} ml={1}>
                        <Typography
                          className={classes.defaultText}
                          style={{ fontSize: '12px' }}
                        >
                          {t('Default')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography className={classes.expireLabel}>
                  {t('Expires')} {el.month}/
                  {el.year?.substr(el.year?.length - 2)}{' '}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (!openForm) {
                    setEditMode(true);
                    setIndexing(index);
                    setCard(el);
                  }
                }}
                style={{ cursor: !openForm && 'pointer' }}
              >
                <Typography className={classes.editbtnForm}>
                  {t('Edit')}
                </Typography>
              </Box>
            </Box>
            {editMode && index === indexing && (
              <Box mt={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box width="100%">
                    <Box display="flex" alignItems="center">
                      <Switch
                        name={'defaultCard'}
                        inputRef={register}
                        defaultChecked={el?.defaultCard}
                        value={el?.defaultCard}
                        disabled={el?.defaultCard}
                      />
                      <Typography className={classes.defaultTexting}>
                        {t('Make default')}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Button
                        className={classes.deleting}
                        onClick={(e) => {
                          e.preventDefault();
                          setCardId(el._id);
                          handleOpenDelete();
                        }}
                        buttonType="reset"
                      >
                        {t('Delete')}
                      </Button>
                      <Box>
                        <Button
                          className={classes.canceling}
                          style={{ marginRight: '5px' }}
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(false);
                          }}
                          buttonType="reset"
                          type="cancel"
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          type="submit"
                          buttonType="submit"
                          data-cy="submit"
                          className={classes.saveing}
                        >
                          {loading ? (
                            <ProgressLoader width={20} height={20} />
                          ) : (
                            <Text i18nKey="save">Save</Text>
                          )}{' '}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            )}

            {/* <DropDownIcon
              data-cy="more-action"
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => handleClickDrop(e, el)}
            /> */}
            <DropDownMenu
              anchorEl={dropDownEL}
              handleClose={() => setDropDownEl(null)}
              handleDelete={() => {
                handleOpenDelete();
                setDropDownEl(null);
              }}
              handleDefault={(e) => {
                handleChange(e, currentRow);
              }}
              row={currentRow}
            />
          </Box>
        ))}
    </>
  );
};

const SET_DEFAULT_CARD = gql`
  mutation setDefaultCard($paymentMethodId: String) {
    setDefaultCard(paymentMethodId: $paymentMethodId)
  }
`;

export default BillingRows;
