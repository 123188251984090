import { gql, useMutation } from '@apollo/client';

export const referralDoctorFragment = gql`
  fragment referralDoctor on ReferredDoctor {
    firstname
    middlename
    lastname
    email
    specialty
  }
`;

export const referralMainInformationsFragment = gql`
  fragment referral on Referral {
    _id
    reason
    patientHistory
    otherDescription
  }
`;

export const referralRelationsFragment = gql`
  fragment referralRelations on Referral {
    doctor {
      _id
    }
    patient {
      _id
    }
    reason
    appointment
  }
`;
