import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';
import {
  deletePrescriptionDrug,
  deletePrescriptionDrugVariables,
} from './__generated__/deletePrescriptionDrug';

const DELETE_PRESCRIPTION_DRUG = gql`
  mutation deletePrescriptionDrug(
    $prescriptionDrugId: ID!
    $prescriptionId: ID!
  ) {
    deletePrescriptionDrug(
      prescriptionDrugId: $prescriptionDrugId
      prescriptionId: $prescriptionId
    ) {
      _id
      appointmentDate
      startDate
      endDate
      drugs {
        _id
        name
        type
        dosage
        duration
        frequency
        advice
      }
    }
  }
`;
export const DELETE_PRESCRIPTION_PATIENT = gql`
  mutation deletePatientPrescription(
    $prescriptionInput: editPrescriptionInput!
    $prescriptionId: ID
  ) {
    editPrescription(
      prescriptionInput: $prescriptionInput
      prescriptionId: $prescriptionId
    ) {
      isDisplayToPatient
    }
  }
`;

export function useDeletePrescriptionDrug() {
  const [deletePrescriptionDrugs, result] = useMutation<
    deletePrescriptionDrug,
    deletePrescriptionDrugVariables
  >(DELETE_PRESCRIPTION_DRUG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentPrescription() {
            try {
              return data?.deletePrescriptionDrug;
            } catch (e) {
              return log('error', e);
            }
          },
        },
      });
    },
  });

  return { deletePrescriptionDrugs, ...result };
}

export function useRemovePrescriptionPatient(patientId: string) {
  const [deletePrescription, result] = useMutation(
    DELETE_PRESCRIPTION_PATIENT,
    {
      refetchQueries: [
        { query: PRESCRIPTION_PATIENT, variables: { patientId } },
      ],
      awaitRefetchQueries: false,
    }
  );
  return { deletePrescription, ...result };
}

export const PRESCRIPTION_PATIENT = gql`
  query prescriptionPatient($patientId: ID!, $filter: Filter) {
    prescriptions(patientId: $patientId, filter: $filter) {
      prescriptions {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          frequency
          advice
          expireAt
        }
        createdAt
        prescriptionUri
      }
      current {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          frequency
          advice
          expireAt
        }
        createdAt
        prescriptionUri
      }
      previous {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          frequency
          advice
          expireAt
        }
        createdAt
        prescriptionUri
      }
    }
  }
`;
