import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Theme, makeStyles } from '@material-ui/core';
import InputBlock from './InputBlock';
import { IRates } from './type';
import { useTranslation } from 'react-i18next';

interface IRateModal {
  currentRow: IRates;
  editMode: boolean;
}

const useStyles = makeStyles<Theme>(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  equal: {
    flex: 1,
  },
}));

const RatesModal = ({ currentRow, editMode }: IRateModal) => {
  const { register, control, errors } = useFormContext();
  const { t } = useTranslation();
  const commonProps = { register, control, errors };
  const classes = useStyles();
  const RATES = ['15', '30', '45', '60'];
  return (
    <Box
      px="2rem"
      py={1}
      sx={{
        width: {
          xs: '50vw',
          lg: '43vw',
          xl: '35vw',
        },
      }}
      height="100%"
    >
      <InputBlock
        label={t('Name')}
        {...commonProps}
        name="session"
        currentRow={currentRow}
        editMode={editMode}
      />
      <InputBlock
        label={t('Session duration (Min)')}
        {...commonProps}
        name="duration"
        currentRow={currentRow}
        editMode={editMode}
        type="select"
        options={RATES}
      />
      <Box className={classes.container}>
        <InputBlock
          label={t('Payment currency')}
          {...commonProps}
          name="currency"
          type="select"
          options={['USD', 'EUR']}
          currentRow={currentRow}
          editMode={editMode}
          className={classes.equal}
        />
        <InputBlock
          label={t('Session rate')}
          {...commonProps}
          name="rate"
          currentRow={currentRow}
          editMode={editMode}
          type="number"
          className={classes.equal}
        />
      </Box>
    </Box>
  );
};

export default RatesModal;
