import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { InputLabel, Box, Grid } from '@material-ui/core';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Input from 'components/ui/Inputs';
import { convertToRawAndStringify } from '@eggmed/common/utils/DraftUtils';
import { timeConverter } from '../TimeConverter';
import { IUserProps } from './types';
import useStyles from './style';
import DataLoader from 'components/ui/DataLoader';
import Skeleton from 'components/ui/Skeleton';
import { EDIT_DOCTOR, GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';
import { InfoBlock } from './InfoBlock';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import { BlockNames } from './PersonalInformation';
import dayjs from 'dayjs';

const birthdateSchema = yup.object().shape({
  birthday: yup.string().required('Date of Birth is a required field'),
});

const Demographics = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const [editableBlock, setEditableBlock] = useState<BlockNames | null>(null);
  const [currentSchema, setCurrentSchema] = useState<yup.ObjectSchema>(
    yup.object().shape({})
  );

  const classes = useStyles();
  const { doctor } = useAuth();
  const { data, loading, error } = useQuery(GET_DOCTOR, {
    variables: { DoctorId: doctor._id },
  });
  const [editDoctor, { loading: editLoading }] = useMutation(EDIT_DOCTOR, {
    refetchQueries: [
      { query: GET_DOCTOR, variables: { DoctorId: doctor._id } },
    ],
    awaitRefetchQueries: false,
  });

  const { register, control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(currentSchema),
    defaultValues: useMemo(
      () => ({
        birthday: timeConverter(
          data?.doctor?.birthday || new Date().getMilliseconds().toString()
        ),
        gender: data?.doctor?.gender,
        pronouns: data?.doctor?.pronouns,
        raceAndEthnicity: data?.doctor?.raceAndEthnicity,
      }),
      [data]
    ),
  });

  const initialValues = useMemo(
    () => ({
      birthday: timeConverter(
        data?.doctor?.birthday || new Date().getMilliseconds().toString()
      ),
      gender: data?.doctor?.gender,
      pronouns: data?.doctor?.pronouns,
      raceAndEthnicity: data?.doctor?.raceAndEthnicity,
    }),
    [data]
  );

  const watchedVals = watch();

  const birthdayValues = useMemo(() => {
    return {
      formattedDate: dayjs(watchedVals?.birthday).format(
        i18n.language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY'
      ),
      age: dayjs().diff(dayjs(watchedVals?.birthday), 'year'),
    };
  }, [watchedVals?.birthday, i18n.language]);

  const hasChanged = useMemo(() => {
    switch (editableBlock) {
      case BlockNames.birth:
        return watchedVals.birthday !== initialValues.birthday;
      case BlockNames.gender:
        return watchedVals.gender !== initialValues.gender;
      case BlockNames.pronouns:
        return watchedVals.pronouns !== initialValues.pronouns;
      case BlockNames.race:
        return watchedVals.raceAndEthnicity !== initialValues.raceAndEthnicity;
      default:
        return false;
    }
  }, [watchedVals, editableBlock, initialValues]);

  useEffect(() => {
    if (data?.doctor) {
      reset(initialValues);
    }
  }, [reset, data?.doctor, initialValues]);

  useEffect(() => {
    switch (editableBlock) {
      case BlockNames.birth:
        setCurrentSchema(birthdateSchema);
        break;
      default:
        setCurrentSchema(yup.object().shape({}));
    }
  }, [editableBlock]);

  const onSubmit = async (formData: IUserProps) => {
    const {
      description,
      provinceState,
      streetadress1,
      streetadress2,
      phoneNumber,
      ...rest
    } = formData;

    const _editData = {
      ...data?.doctor,
      ...rest,
      state: provinceState ?? data?.doctor?.state,
      about: description
        ? convertToRawAndStringify(description)
        : data?.doctor?.about,
      suite: streetadress2 ?? data?.doctor?.suite,
      address: streetadress1 ?? data?.doctor?.address,
      phone: phoneNumber ?? data?.doctor?.phone,
    };

    // extract unused data
    const {
      __typename,
      _id,
      certifications,
      experience,
      focusArea,
      languages,
      noUsedFeatures,
      patientGroup,
      picture,
      specialty,
      username,
      signature,
      ...editData
    } = _editData;

    try {
      await editDoctor({
        variables: {
          doctorID: doctor._id,
          DoctorInput: editData,
        },
      });
      triggerSnack();
      setEditableBlock(null);
    } catch (error) {
      console.error(error);
    }
  };

  const commonInputProps = {
    inputRef: register,
    variant: 'filled',
    control,
  };

  return (
    <DataLoader loading={loading} error={error} data={data?.doctor}>
      <Box className="cyTestPerconalInfo">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
              <Skeleton loading={loading}>
                <Text i18nKey="Personal info" className={classes.h3}>
                  Demographics
                </Text>
              </Skeleton>
              <Skeleton loading={loading}>
                <Text i18nKey="Personal info desc" className={classes.subtitle}>
                  This information will be displayed on your profile if you
                  choose to
                </Text>
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={12}
              xl={4}
              lg={5}
              md={6}
              sm={12}
              style={{ marginLeft: '2rem' }}
            >
              <InfoBlock
                title={t('dateOfBirth')}
                content={
                  data?.doctor?.birthday
                    ? `${birthdayValues?.formattedDate} (${
                        birthdayValues?.age
                      } ${i18n.language === 'fr' ? 'ans' : 'years old'})`
                    : '-'
                }
                blockName={BlockNames.birth}
                isEditable={editableBlock === BlockNames.birth}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={editLoading}
              >
                <>
                  <Skeleton loading={loading}>
                    <InputLabel className={classes.label}>
                      <Text i18nKey="dateOfBirth">Date of birth</Text>
                    </InputLabel>
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Input
                      className={clsx(classes.input, classes.marginTop)}
                      type="date"
                      name="birthday"
                      data-cy="date-input"
                      {...commonInputProps}
                      required
                    />
                  </Skeleton>
                </>
              </InfoBlock>
              <InfoBlock
                title={t('Gender')}
                content={t(data?.doctor?.gender)}
                blockName={BlockNames.gender}
                isEditable={editableBlock === BlockNames.gender}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={editLoading}
              >
                <>
                  <Skeleton loading={loading}>
                    <InputLabel className={clsx(classes.label)}>
                      <Text i18nKey="gender">Gender</Text>
                    </InputLabel>
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Input
                      className={clsx(classes.input, classes.marginTop)}
                      type="select"
                      name="gender"
                      data-cy="gender-input"
                      {...commonInputProps}
                      options={['male', 'female', 'other', 'prefer not to say']}
                    />
                  </Skeleton>
                </>
              </InfoBlock>
              <InfoBlock
                title={t('Pronouns')}
                content={t(data?.doctor?.pronouns)}
                blockName={BlockNames.pronouns}
                isEditable={editableBlock === BlockNames.pronouns}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={editLoading}
              >
                <>
                  <Skeleton loading={loading}>
                    <InputLabel className={clsx(classes.label)}>
                      <Text i18nKey="pronouns">Pronouns</Text>
                    </InputLabel>
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Input
                      className={clsx(classes.input, classes.marginTop)}
                      type="select"
                      name="pronouns"
                      data-cy="pronouns-input"
                      {...commonInputProps}
                      options={['he/him', 'she/her', 'they/them', 'other']}
                    />
                  </Skeleton>
                </>
              </InfoBlock>
              <InfoBlock
                title={t('Ethnicity and race')}
                content={t(data?.doctor?.raceAndEthnicity)}
                blockName={BlockNames.race}
                isEditable={editableBlock === BlockNames.race}
                editableBlock={editableBlock}
                setEditableBlock={setEditableBlock}
                loading={loading}
                disabledButton={!hasChanged}
                buttonLoading={editLoading}
              >
                <>
                  <Skeleton loading={loading}>
                    <InputLabel className={clsx(classes.label)}>
                      <Text i18nKey="Race and ethnicity">
                        Ethnicity and race
                      </Text>
                    </InputLabel>
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Input
                      className={clsx(classes.input, classes.marginTop)}
                      type="select"
                      name="raceAndEthnicity"
                      data-cy="ethnicity-input"
                      {...commonInputProps}
                      options={[
                        'white',
                        'black or african american',
                        'asian',
                        'native american or alaska native',
                        'native hawaiian or other pacific islander',
                        'middle eastern or north african',
                        'biracial multiracial',
                        'prefer not to say',
                        'other',
                      ]}
                    />
                  </Skeleton>
                </>
              </InfoBlock>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DataLoader>
  );
};
export default Demographics;
