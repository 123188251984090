import React, { ReactElement } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as PhoneIcon } from 'assets/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/letter.svg';
import { ReactComponent as AddressIcon } from 'assets/pingIcon.svg';
import Skeleton from 'components/ui/Skeleton';
import { IDoctor } from './PersonalInformation';
import Text from 'Text';
interface IPersonalProps {
  doctor: IDoctor;
  isPatient: boolean;
  loading?: boolean;
}

const ContactInfo = ({
  doctor,
  isPatient,
  loading,
}: IPersonalProps): ReactElement => {
  const classes = useStyles({ isPatient });
  const { email, country, address, suite, city, zipcode, phone, state } =
    doctor || {};

  return (
    <Box className={classes.personalInfo}>
      <Box style={{ padding: '24px 25px 25px 25px', boxSizing: 'border-box' }}>
        <Box>
          <Skeleton loading={loading} type="text" width="8rem" height="2.5rem">
            <Text i18nKey='contactInformation' className={classes.title}>
              Contact information
            </Text>
          </Skeleton>
          <Box mt="2rem" />
          <Box className={classes.info}>
            <Box className={classes.infoItem}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <PhoneIcon />
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="7rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {phone || '—'}
                </Typography>
              </Skeleton>
            </Box>
            <Box className={classes.infoItem}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <EmailIcon />
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="7rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {email || '—'}
                </Typography>
              </Skeleton>
            </Box>
            <Box className={classes.infoItemStart}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <AddressIcon />
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="7rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {address && `${address}, `}
                  {city && `${city}, `}
                  {state && `${state} `}
                  {zipcode && `${zipcode}, `}
                  {country && `${country}`}
                  {!!!address &&
                    !!!city &&
                    !!!state &&
                    !!!zipcode &&
                    !!!country &&
                    '—'}
                </Typography>
              </Skeleton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ContactInfo;
