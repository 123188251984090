import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';
import EggmedPagination from '../ui/Pagination';
import '../../App.css';

const useStyles = makeStyles((theme) => ({
  document: {
    paddingTop: theme.spacing(3),
    paddingLeft: '30px',
    width: '100%',
    height: '80%',
    borderColor: 'black',
    borderRadius: '10px',
  },
}));
interface IPdfViewerProps {
  attachment: string;
}
export default function PdfViewer({ attachment }: IPdfViewerProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  function onDocumentLoadSuccess({ numPages: numPage }: any) {
    setNumPages(numPage);
  }

  function handlePageChange(event: any, page: number) {
    setPageNumber(page);
  }

  return (
    <div className={classes.document}>
      <Document
        className="document"
        file={attachment}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page className="document" pageNumber={pageNumber} />

        <EggmedPagination
          onChange={handlePageChange}
          page={pageNumber}
          count={numPages}
          variant="outlined"
          shape="rounded"
        />
      </Document>
    </div>
  );
}
