import { gql, useMutation } from '@apollo/client';

const DELETE_THREAD_CONVERSATION = gql`
  mutation DeleteThreadConversation($threadConversationID: ID!) {
    deleteThreadConversation(threadConversationID: $threadConversationID)
  }
`;

const useDeleteThreadConversation = () => {
  const [deleteThreadConversation] = useMutation(DELETE_THREAD_CONVERSATION);

  async function handleDeleteThreadConversation(threadConversationID: string) {
    try {
      return await deleteThreadConversation({
        variables: { threadConversationID },
      });
    } catch (e) {
      // log('error', 'Error while deleting thread conversation', e);
      return e;
    }
  }

  return handleDeleteThreadConversation;
};

export { DELETE_THREAD_CONVERSATION };
export default useDeleteThreadConversation;
