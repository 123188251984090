import React, { ReactElement } from 'react';
import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu as MenuMUI,
  MenuItem,
  MenuProps,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  selected: {
    color: '#2DC76D',
  },
  red: {
    color: '#B42318',
  },
  isDisabled: {
    width: 140,
  },
  avatar: {
    width: '50px',
    height: '50px',
    margin: '0px',
    borderRadius: '50%',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

interface Props<MenuType> extends MenuProps {
  handleMenuClose: () => void;
  selected?: boolean;
  notifMenu?: boolean;
  MenuElements?: MenuType[];
  width?: number;
}

const Menu = <T extends unknown>({
  anchorEl,
  open,
  handleMenuClose,
  notifMenu,
  MenuElements,
  className,
  width,
  ...props
}: Props<T>): ReactElement => {
  const classes = useStyles();
  const elements = MenuElements?.map((element: any, key: any) => {
    const {
      onClick,
      name,
      accessoryLeft,
      accessoryRight,
      selected,
      notification,
      avatar,
      cy,
      isRed,
      isDisabled,
      route,
    } = element;
    function Click(event) {
      onClick(event);
    }

    if (!route) {
      return (
        <MenuItem
          key={key}
          onClick={Click}
          data-cy={cy && cy}
          disabled={isDisabled}
        >
          {accessoryLeft && <ListItemIcon>{accessoryLeft}</ListItemIcon>}
          <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
            {avatar && avatar}
            {!notification && (
              <ListItemText
                className={clsx(
                  selected && classes.selected,
                  isRed && classes.red,
                  isDisabled && classes.isDisabled
                )}
              >
                {name}
              </ListItemText>
            )}
          </Box>
          {accessoryRight && (
            <span style={{ float: 'right', marginLeft: '5px' }}>
              {accessoryRight}
            </span>
          )}
        </MenuItem>
      );
    }

    return (
      <Link to={route} key={key} className={classes.link}>
        <MenuItem data-cy={cy && cy} disabled={isDisabled}>
          {accessoryLeft && <ListItemIcon>{accessoryLeft}</ListItemIcon>}
          <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
            {avatar && avatar}
            {!notification && (
              <ListItemText
                className={clsx(
                  selected && classes.selected,
                  isRed && classes.red,
                  isDisabled && classes.isDisabled
                )}
              >
                {name}
              </ListItemText>
            )}
          </Box>
          {accessoryRight && (
            <span style={{ float: 'right', marginLeft: '5px' }}>
              {accessoryRight}
            </span>
          )}
        </MenuItem>
      </Link>
    );
  });
  return (
    <MenuMUI
      {...props}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleMenuClose}
      disableScrollLock={false}
      PaperProps={{
        style: {
          width: width, // Apply the width here
        },
      }}
      classes={{
        paper: className,
        list: className,
      }}
    >
      {elements}
    </MenuMUI>
  );
};

export default Menu;
