import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { Context } from './context';
import useDoctorWaitingRoomActions from './useDoctorWaitingRoomActions';

export default function Provider({ children }: any) {
  const {
    doctor: { _id: doctorId, username },
  } = useAuth();
  const [waitingPatients, setWaitingPatients] = useState<any>([]);
  const { roomId: roomSlug, id } = useParams<{ roomId: string; id?: string }>();
  const {
    handleJoinDoctorWaitingRoom,
    handleLeaveDoctorWaitingRoom,
    handleLeaveWaitingRoom,
    roomData,
    waitingMembersData,
    roomId,
    roomError,
    roomLoading,
  } = useDoctorWaitingRoomActions({
    doctorId,
    roomSlug: id ? username : roomSlug,
    handleSetWaitingMembers: (patients) => setWaitingPatients(patients),
  });

  return (
    <Context.Provider
      value={{
        waitingPatients,
        handleJoinDoctorWaitingRoom,
        handleLeaveDoctorWaitingRoom,
        handleLeaveWaitingRoom,
        waitingMembersData,
        roomError,
        roomLoading,
        setWaitingPatients,
        roomData,
        isWidget: !!id,
        roomId,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useDoctorMeetingWaitingPageProvider must be used within a DoctorMeetingWaitingPageProvider'
    );
  }

  return context;
}
