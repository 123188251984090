import React, { useEffect } from 'react';
import { useStyles } from './styling';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Input from 'components/ui/Inputs';
import { useFormContext, Controller } from 'react-hook-form';
import DaysChip from 'pages/PatientActivities/commun/DaysChip';
import { days } from 'pages/PatientActivities/commun/constants';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as RadioCheckedIcon } from 'assets/Schedule/new/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/Schedule/new/radio-unchecked.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

enum DateFormat {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

enum FrequencyType {
  ON = 'on',
  OCCURRENCE = 'occurrence',
  NEVER = 'never',
}

function FrequencyComponent({
  control,
  register,
  errors,
  watch,
  defaultValue,
  setOpenConflict,
  data,
  dateRange,
  name,
  isEvent = false,
}) {
  const { t } = useTranslation();
  const frequentType = watch()?.occurence
    ? FrequencyType.OCCURRENCE
    : FrequencyType.ON;
  const classes = useStyles({ selected: false });
  const commonProps = { control, errors, register, variant: 'filled' };
  const [hasConflict, setHasConflict] = React.useState<boolean>(false);
  useEffect(() => {
    if (data && data.length > 0 && !hasConflict) {
      setHasConflict(true);
    }
  }, [data]);

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box minWidth="7rem">
          <Typography className={classes.subLabel}>
            {t('Repeat every')}
          </Typography>
        </Box>
        <Box width="100%" display="flex" style={{ gap: '1rem' }}>
          <Input
            type="number"
            name="numberOfRepeat"
            inputRef={register}
            defaultValue={1}
            InputProps={{
              inputProps: {
                max: 99,
                min: 1,
              },
            }}
            {...commonProps}
          />
          <Input
            type="select"
            name="repeatEvery"
            defaultValue="week"
            options={['day', 'week', 'month']}
            {...commonProps}
          />
        </Box>
      </Box>
      {watch()?.repeatEvery === DateFormat.WEEK && (
        <Box display="flex" flexDirection="column" mt={2}>
          <Box minWidth="7rem">
            <Typography className={classes.subLabel}>
              {t('Repeat on')}
            </Typography>
          </Box>
          <Controller
            name="repeatOn"
            control={control}
            register={register}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
              <DaysChip handleChange={onChange} value={value} options={days} />
            )}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={2}>
        <Box minWidth="7rem">
          <Typography className={classes.subLabel}>{t('Ends')}</Typography>
        </Box>
        <Box>
          <Controller
            name="frequencyType"
            control={control}
            register={register}
            defaultValue={frequentType}
            render={({ onChange, value }) => (
              <RadioGroup
                aria-label="frequencyType"
                name="frequencyType"
                defaultValue={frequentType}
                defaultChecked={true}
                onChange={onChange}
              >
                {/* <Box display="flex" alignItems="center" mb={1.5}>
                  <FormControlLabel
                    className={classes.radioWrapper}
                    value={FrequencyType.NEVER}
                    control={
                      <Radio
                        className={classes.radio}
                        disableRipple
                        color="default"
                        icon={<RadioUncheckedIcon />}
                        checkedIcon={<RadioCheckedIcon />}
                        disabled
                      />
                    }
                    label="Never"
                  />
                </Box> */}
                <Box display="flex" alignItems="center" mb={1.5}>
                  <FormControlLabel
                    className={classes.radioWrapper}
                    value={FrequencyType.ON}
                    control={
                      <Radio
                        className={classes.radio}
                        disableRipple
                        color="default"
                        icon={<RadioUncheckedIcon />}
                        checkedIcon={<RadioCheckedIcon />}
                      />
                    }
                    label={t('On')}
                  />
                  <Input
                    type="date"
                    name={name}
                    className={classes.inputDate}
                    minDate={new Date(dateRange?.startDate)}
                    disabled={FrequencyType.ON !== value}
                    {...commonProps}
                  />
                </Box>
                {!isEvent && (
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      className={classes.radioWrapper}
                      value={FrequencyType.OCCURRENCE}
                      control={
                        <Radio
                          className={classes.radio}
                          disableRipple
                          color="default"
                          icon={<RadioUncheckedIcon />}
                          checkedIcon={<RadioCheckedIcon />}
                        />
                      }
                      label={t('After')}
                    />
                    <Input
                      inputRef={register}
                      type="number"
                      name="occurence"
                      defaultValue={1}
                      className={classes.inputOcc}
                      disabled={FrequencyType.OCCURRENCE !== value}
                      {...commonProps}
                    />
                    <Typography className={classes.radioExtra}>
                      occurrences
                    </Typography>
                  </Box>
                )}
              </RadioGroup>
            )}
          />
        </Box>
      </Box>
      {hasConflict && (
        <Box mt={4} className={classes.infoContainer}>
          <ErrorIcon className={classes.infoIcon} />
          <Typography variant="body2" className={classes.info}>
            Future session conflict with existing ones, would you like to
            <span className={classes.cta} onClick={() => setOpenConflict(true)}>
              manage conflicts
            </span>
            ?
          </Typography>
          <CloseIcon
            className={classes.infoIcon}
            onClick={() => setHasConflict(false)}
          />
        </Box>
      )}
    </Box>
  );
}

export default FrequencyComponent;
