import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useStyles } from './styles';
import Radio from './inputs/Radio';
import Input from 'components/ui/Inputs';
import { SaveOptions } from './FormsPage';

interface ISaveModalProps {
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
  setTitle: (title: string) => void;
  selectedValue: SaveOptions;
  setSelectedValue: (selectedValue: SaveOptions) => void;
  newForm: boolean;
}

function SaveModal({
  handleClose,
  handleSubmit,
  title,
  setTitle,
  selectedValue,
  setSelectedValue,
  newForm,
}: ISaveModalProps): React.ReactElement {
  const classes = useStyles({ selected: false });
  return (
    <Box className={classes.saveModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        px="3rem"
        boxSizing="border-box"
      >
        <Typography className={classes.savetitle}>
          You are about to save “{title}”
        </Typography>
        {newForm ? (
          <Typography>Please pick a new title for your form</Typography>
        ) : (
          <Radio
            options={[SaveOptions.Update, SaveOptions.Save]}
            name="options"
            color="#1F61DC"
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        )}
        <Box
          width="60%"
          display="flex"
          style={{
            boxSizing: 'border-box',
            marginTop: '1rem',
          }}
        >
          {(selectedValue === SaveOptions.Save || newForm) && (
            <Input
              variant="filled"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )}
        </Box>
      </Box>

      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="0.5px solid rgba(161, 176, 196, 1)"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          marginTop: '60px',
        }}
      >
        <Box
          className={classes.Bottonscontainer}
          style={{ paddingInline: '2rem' }}
        >
          <Button className={classes.cancelButton} onClick={handleClose}>
            <Typography className={classes.cancelText}>Cancel</Typography>
          </Button>
          <Button className={classes.assignbutton} onClick={handleSubmit}>
            <Typography className={classes.assignText}>Save</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SaveModal;
