import React, { ReactElement, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from './style';
import HelpContent from './HelpContent';

const HelpPage = (): ReactElement => {
  const classes = useStyles();
  return (
    <Grid container className={classes.bottomGridContainer}>
      <HelpContent title="Reset password failed?">
        <Box width="100%">
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Old email address?
              <br />
              You must access the email address associated with your Eggmed
              account to open the password reset link we emailed you.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              If you don't have access, here are some options:
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              If possible, regain access to the email address.
              <br />
              Create a new account and start over.
              <br />
              No account associated with this email address?
              <br />
              Try logging in with other email addresses you may have used to
              register.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Note: There are several ways to register, for example with an
              email, phone number, Facebook, Apple or Google account. Try
              logging in with these different ways to find your account.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              If you're worried that your email has been changed without your
              permission, see Think your account has been hacked?.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Didn't receive the reset email?
              <br />
              Check your spam folders or other filtered folders.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              If you don't have access to your email address, see "Old email
              address?" ".
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Password reset link not valid?
              <br />
              Reset your password, but this time open the emailed link in an
              incognito window.
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Reset your password
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              Too many requests?
              <br />
              Try again later or use another device.
            </Typography>
          </Box>
        </Box>
      </HelpContent>
      <HelpContent title="Is the online refilled prescription recognized by pharmacies?">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>
            You can use your online prescription at any pharmacy, it is
            recognized by the minister of health as well as accepted by
            pharmacists. Eggmed make sure to provide you services that are legal
            and usefull
          </Typography>
        </Box>
      </HelpContent>
      <HelpContent title="Can I get an online sick leave to provide it to work? ">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>
            Eggmed provide you the opportunity to contact your provider easily
            through the direct messages! you can chat with your Doctotor and ask
            him to send a scanned sick slip so you can print it and provide it
            to work as official document for your abscence reasons.
          </Typography>
        </Box>
      </HelpContent>
      <HelpContent title="What should I do if the provider didn’t join the video call?">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>
            When the provider didn't join the video call consultation, first
            please wait 15 min at least to make sure that gave him as much time
            as it is possible to join the consultation. If not, you can direct
            message him through the inbox feature or the button "message".You
            can also reschedule your appointment in other available date and
            time.
          </Typography>
        </Box>
      </HelpContent>
      <HelpContent title="Is the online payment secure?">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>
            We ensure protect payment information by encrypting the data before
            transmitting it to provide you with a secure online payment that can
            be ease your user experience on Eggmed. You can use your card
            number, you CVV with no issues.
          </Typography>
        </Box>
      </HelpContent>
    </Grid>
  );
};

export default HelpPage;
