import React, { ReactElement } from 'react';
import {
  makeStyles,
  MenuItem,
  Box,
  Typography,
  Avatar,
} from '@material-ui/core';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { fromNow } from 'utils';
import { useHistory } from 'react-router-dom';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import NotifCard from './NotifCard';

const useStyles = makeStyles((theme) => ({
  NotifItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBlock: '8px',
    paddingInline: '16px',
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#EFF6FF',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: ({ patient }: { patient: boolean }) =>
      patient ? '#1F61DC' : '#425364',
  },
  notifContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: theme.typography.pxToRem(468),
    color: '#475467',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  button: {
    cursor: 'pointer',
    minWidth: '19px',
  },
  notifIcon: {
    minHeight: '52px',
    minWidth: '52px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
    marginLeft: '15px',
  },
  date: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#1F61DC',
  },
  NotifButton: {
    background: 'transparent',
    border: '1px solid #1F61DC',
    color: '#1F61DC',
    borderRadius: '16px',
    paddingInline: '12px',
    height: theme.typography.pxToRem(30),
    fontWeight: 500,
    fontSize: '12px',
    cursor: 'pointer',
    marginTop: '5px',
    textTransform: 'none',
  },
  unread: {
    width: '8px',
    aspectRatio: '1/1',
    borderRadius: '100%',
    background: '#147AF3',
  },
}));

export interface MenuElements {
  onClick: () => void;
  title: string;
  content: string;
  notification: boolean;
  type: string;
  id: string;
  date: string;
  read: boolean;
  action: string;
  appointmentId: string;
  avatar: string;
  patientId: string;
  event?: string;
}
export enum Types {
  add_RefillRequest = 'add_RefillRequest',
  delete_Appointment = 'delete_Appointment',
}
interface IElementsProps {
  menuElements: MenuElements[];
  setAnchor: (event: null | HTMLElement) => void;
  setDeleteId: (id: string) => void;
  setCardId: (id: number) => void;
  anchor: null | HTMLElement;
}

const Elements = ({
  menuElements,
  setAnchor,
  setDeleteId,
  setCardId,
  anchor,
}: IElementsProps): ReactElement => {
  const { patient } = useAuth();
  const IsPatient = !!patient;

  return (
    <>
      {menuElements?.map((element: MenuElements, key: number) => (
        <NotifCard
          key={key}
          element={element}
          setAnchor={setAnchor}
          setDeleteId={setDeleteId}
          setCardId={setCardId}
          IsPatient={IsPatient}
          anchor={anchor}
        />
      ))}
    </>
  );
};
export default Elements;
