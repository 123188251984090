import { gql, useMutation } from '@apollo/client';
import {
  addLabRequest,
  addLabRequestVariables,
} from './__generated__/addLabRequest';

const ADD_LAB_REQUEST = gql`
  mutation addLabRequest($labInput: NewLabRequestInput!) {
    addNewLabRequest(labInput: $labInput) {
      name
      timeFrame
      notes
      doctorId
      patientId
    }
  }
`;

export default function useAddLabRequest() {
  const [createLabRequest, result] = useMutation<
    addLabRequest,
    addLabRequestVariables
  >(ADD_LAB_REQUEST, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          labRequestByAppointment(labs) {
            return [...labs, data?.addNewLabRequest];
          },
        },
      });
    },
  });
  return { createLabRequest, ...result };
}
