import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client';

const ADD_PATIENT = gql`
  mutation createPatient($PatientInput: PatientInput!, $picture: Upload) {
    addPatient(PatientInput: $PatientInput, picture: $picture) {
      _id
      birthday
      email
      phone
      picture
      firstname
      middlename
      lastname
      address
    }
  }
`;
interface IArgs {
  data: any;
  picture?: File;
}
interface IUseAddPatient extends MutationResult<any> {
  addPatient: (
    { data, picture }: IArgs,
    sideEffectCallback: (e: Error) => void
  ) => Promise<FetchResult<
    any,
    Record<string, any>,
    Record<string, any>
  > | void>;
}

export default function useAddPatient(): IUseAddPatient {
  const [createPatient, result] = useMutation(ADD_PATIENT, {
    update: (cache, { data: { addPatient: addPatientData } }) => {
      cache.modify({
        fields: {
          patients(patients) {
            return [...patients, addPatientData];
          },
        },
      });
    },
  });

  async function addPatient(
    { data, picture }: IArgs,
    sideEffectCallback: (e: Error) => void
  ): Promise<FetchResult<
    any,
    Record<string, any>,
    Record<string, any>
  > | void> {
    try {
      const resultData = await createPatient({
        variables: {
          PatientInput: { ...data },
          picture,
        },
      });
      return resultData;
    } catch (e) {
      return sideEffectCallback(e as Error);
    }
  }
  return { addPatient, ...result };
}
