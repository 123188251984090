import { gql, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS } from './useGetNotifications';

const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation updateNotificationSetting(
    $updateNotificationSetting: UpdateNotificationSettingInput
  ) {
    updateNotificationSetting(
      updateNotificationSetting: $updateNotificationSetting
    ) {
      _id
      user {
        _id
      }
    }
  }
`;

const useUpdateNotificationSetting = () => {
  const [updateNotificationSettings] = useMutation(
    UPDATE_NOTIFICATION_SETTINGS_MUTATION,
    {
      refetchQueries: [{ query: GET_NOTIFICATIONS }],
    }
  );
  async function handleUpdateNotificationSetting(updateNotificationSetting) {
    try {
      return await updateNotificationSettings({
        variables: { updateNotificationSetting },
      });
    } catch (e) {
      return e;
    }
  }
  return handleUpdateNotificationSetting;
};

export { UPDATE_NOTIFICATION_SETTINGS_MUTATION };
export default useUpdateNotificationSetting;
