import React, { ReactElement } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ScrollBar from 'components/ui/ScrollBar';
import AccessCard from './accessCard';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useUpdatePatientRequest from '@eggmed/graphql-client/operations/patientRequestOperations/useUpdatePatientRequest';
import { gql, useQuery } from '@apollo/client';
import { ReactComponent as NoAccessRequestsIcon } from 'assets/illustrations/noAccessRequest.svg';

const AccessRequests = (): ReactElement => {
  const { patient } = useAuth();
  const { handleUpdatePatientRequest } = useUpdatePatientRequest();
  const { data, error, loading } = useQuery(GET_PATIENT_REQUESTS_BY_PATIENT, {
    variables: { id: patient?._id },
  });

  const elements = data?.patientRequestsByPatient
    ?.slice(0)
    .reverse()
    .map(({ _id, doctor, date, read, action }) => ({
      title: doctor?.firstname + ' ' + doctor?.lastname,
      content:
        doctor?.firstname +
        ' ' +
        doctor?.lastname +
        ' has requested to add you to his patient list.',
      date,
      read,
      type: action,
      id: _id,
      avatar: doctor?.picture,
      avatarAlt: `${doctor?.firstname.charAt(0)} ${doctor?.lastname.charAt(0)}`,
      doctorId: doctor?._id,
      onClick: () => {
        handleUpdatePatientRequest(_id);
      },
    }));
  return (
    <Box
      style={{
        minHeight: '50vh',
        paddingBottom: '1rem',
        background: 'white',
        borderRadius: '45px',
      }}
    >
      <Grid container xs={12} lg={12} xl={12} md={12} sm={12}>
        <ScrollBar maxHeight="70vh" width="99%">
          {elements?.length > 0 ? (
            elements?.map((element) => <AccessCard element={element} />)
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              style={{ paddingInline: '1rem' }}
            >
              <NoAccessRequestsIcon
                style={{ width: '400px', height: '400px' }}
              />
            </Grid>
          )}
        </ScrollBar>
      </Grid>
    </Box>
  );
};

export const GET_PATIENT_REQUESTS_BY_PATIENT = gql`
  query patientRequestsByPatient($id: ID!) {
    patientRequestsByPatient(id: $id) {
      _id
      doctor {
        _id
        firstname
        lastname
        picture
      }
      patient {
        _id
      }
      read
      date
    }
  }
`;

export default AccessRequests;
