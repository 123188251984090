import {
  OperationVariables,
  QueryHookOptions,
  gql,
  useQuery,
} from '@apollo/client';

const GET_NOTIFICATIONS = gql`
  query getNotifications($start: Int, $limit: Int) {
    notifications(start: $start, limit: $limit) {
      allNotifications {
        _id
        type
        title
        content
        event
        date
        read
        action
        appointmentId
        avatar
        updatedAt
        appointmentEndedAt
        patientId
      }

      pastNotifications {
        _id
        type
        title
        content
        event
        date
        read
        action
        appointmentId
        avatar
        updatedAt
        appointmentEndedAt
        patientId
      }
      newNotifications {
        _id
        type
        title
        content
        event
        date
        read
        action
        appointmentId
        avatar
        updatedAt
        appointmentEndedAt
        patientId
        doctor {
          _id
        }
        patient {
          _id
        }
      }
      newCount
      pastCount
    }

    notificationChannels {
      _id
      name
      label
    }
    notificationEvents {
      _id
      title
      patientTitle
      description
      patientDescription
      event
    }
    getNotificationSettingsByUser {
      _id
      user {
        _id
      }
      channelSettings {
        channel {
          _id
        }
        events {
          _id
        }
      }
    }
  }
`;

const useGetNotifications = (
  options?: QueryHookOptions<any, OperationVariables>
) => useQuery(GET_NOTIFICATIONS, options);

export { GET_NOTIFICATIONS };
export default useGetNotifications;
