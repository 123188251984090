import { gql, useSubscription } from '@apollo/client';
import {
  subscriptionNewMessage,
  subscriptionNewMessageVariables,
  subscriptionNewMessage_subscriptionNewMessage,
} from './__generated__/subscriptionNewMessage';

const SUBSCRIPTION_TO_NEW_MESSAGE = gql`
  subscription subscriptionNewMessage($userId: ID!) {
    subscriptionNewMessage(userId: $userId) {
      _id
      content {
        text
        files {
          _id
          url
          fileName
          mimeType
        }
      }
      createdAt
      updatedAt
      user {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      idThreadConversation
      parentMessageId
    }
  }
`;

const useSubscriptionNewMessage = (
  userId: string,
  event: (data: subscriptionNewMessage_subscriptionNewMessage) => void
) => {
  const result = useSubscription<
    subscriptionNewMessage,
    subscriptionNewMessageVariables
  >(SUBSCRIPTION_TO_NEW_MESSAGE, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      if (subscriptionData?.data?.subscriptionNewMessage)
        event(subscriptionData?.data?.subscriptionNewMessage);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_NEW_MESSAGE };
export default useSubscriptionNewMessage;
