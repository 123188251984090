import { gql, useMutation } from '@apollo/client';

const CREATE_DOCTOR_TEMPLATE = gql`
  mutation createDoctorTemplate($doctorTemplateInput: DoctorTemplateInput) {
    createDoctorTemplate(doctorTemplateInput: $doctorTemplateInput) {
      _id
    }
  }
`;

const useCreateDoctorTemplate = () => {
  return useMutation(CREATE_DOCTOR_TEMPLATE);
};
export default useCreateDoctorTemplate;
