import { gql, useMutation } from '@apollo/client';
import {
  addConsultation,
  addConsultationVariables,
} from './__generated__/addConsultation';

const ADD_CONSULTATION_REQUEST = gql`
  mutation addConsultation($consultationInput: addConsultationInput!) {
    addConsultation(consultationInput: $consultationInput) {
      _id
      patientInput
      personalNotes
      symptoms
    }
  }
`;

export default function useAddConsultation() {
  const [createConsultation, result] = useMutation<
    addConsultation,
    addConsultationVariables
  >(ADD_CONSULTATION_REQUEST, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentConsultation() {
            return data?.addConsultation;
          },
        },
      });
    },
  });
  return { createConsultation, ...result };
}
