import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles, Box, Snackbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NotificationSettings from '../../PatientProfile/NotificationSettings';
import { useToast } from 'hooks/useToast';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import EditButtons from 'components/EditButtons';
import useUpdateNotificationSettings from '@eggmed/graphql-client/operations/notificationOperations/useUpdateNotificationSettings';
import { log } from 'config/log';
import { useSnackbar } from 'hooks/useSnackbar';
import Text from 'Text';

const getPayload = (el: INotificationInput) => {
  const {
    PushChannel,
    EmailChannel,
    TextChannel,
    pushEvents,
    emailEvents,
    textEvents,
  } = el || {};
  const payload = [];
  if (PushChannel) {
    payload.push({
      channel: PushChannel,
      events: Array.isArray(pushEvents) ? pushEvents : [pushEvents],
    });
  }

  if (EmailChannel) {
    payload.push({
      channel: EmailChannel,
      events: Array.isArray(emailEvents) ? emailEvents : [emailEvents],
    });
  }

  if (TextChannel) {
    payload.push({
      channel: TextChannel,
      events: Array.isArray(textEvents) ? textEvents : [textEvents],
    });
  }
  return payload;
};
interface INotificationInput {
  PushChannel: string;
  EmailChannel: string;
  TextChannel: string;
  pushEvents: string[];
  emailEvents: string[];
  textEvents: string[];
}
const useStyles = makeStyles((theme) => ({
  h3: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
    marginBottom: '2rem',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  requiredField: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  snackBar: {
    minWidth: 'auto',
  },
}));

const EmailNotifications = ({
  isPatient,
}: {
  isPatient?: boolean;
}): ReactElement => {
  const { triggerSnack } = useSnackbar();
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, isPatient });
  const methods = useForm();
  const updateNotificationSetting = useUpdateNotificationSettings();
  const [notifSettings, setNotifSettings] = React.useState<INotificationInput>(
    {} as INotificationInput
  );
  const [updateAble, setUpdateAble] = useState(false);
  useEffect(() => {
    const currentSettings = methods.watch();
    if (JSON.stringify(currentSettings) !== JSON.stringify(notifSettings)) {
      setUpdateAble(true);
      setNotifSettings(currentSettings as INotificationInput);
    }
  }, [methods.watch()]);
  useEffect(() => {
    if (
      notifSettings &&
      Object.keys(notifSettings).length !== 0 &&
      updateAble
    ) {
      onSubmit(notifSettings);
    }
  }, [notifSettings]);
  const onSubmit = (el: INotificationInput) => {
    const payload = getPayload(el);
    console.log(payload);
    try {
      updateNotificationSetting({ channelSettings: payload });
      triggerSnack();
    } catch (error) {
      log('error', error);
    }
  };
  return (
    <Box data-cy="cyTestEmailNotification">
      <Grid container spacing={2} className={classes.gridContainerBilling}>
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          md={12}
          sm={12}
          style={{ marginTop: isPatient && '6rem' }}
        >
          <Text i18nKey="notificationSettings" className={classes.h3}>
            Notification Settings
          </Text>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box px={1}>
              <NotificationSettings />
            </Box>
          </form>
        </FormProvider>
      </Grid>
    </Box>
  );
};

export default EmailNotifications;
