import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import dayjs from 'dayjs';
import CustomButton from 'components/ui/Button';
import { ReactComponent as Boost } from 'assets/Boost.svg';
import { ReactComponent as CloseSubscribe } from 'assets/CloseSubscribe.svg';
import { ReactComponent as EggmedWhitelg } from 'assets/EggmedWhitelg.svg';
import { ReactComponent as CheckSubscribeIcon } from 'assets/CheckSubscribeIcon.svg';
import useSubscribe from '@eggmed/graphql-client/operations/subscriptionOperations/useSubscribe';
import BasicModal from 'components/ui/Modal';
import ProgressLoader from 'components/ui/ProgressLoader';
import DeleteAccountModal from '../CancelSubscriptionModal';
import useCancelSubscription from '@eggmed/graphql-client/operations/subscriptionOperations/useCancelSubscription';
import useChangePlan from '@eggmed/graphql-client/operations/subscriptionOperations/useChangePlan';
import { FormProvider, useForm } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import Lottie from 'lottie-react';
import CreditCard from './animation/card.json';
import { useTranslation } from 'react-i18next';
import EggmedLogoSubscribe from 'assets/EggmedSubscribeLogo.svg';
import SubscriptionCanceled from './SubscriptionStatus/SubscriptionCanceled';
import { subscriptionProps } from 'contexts/AuthContext';
import LastDayTrial from './SubscriptionStatus/LastDayTrial';
import { gql, useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

interface Props {
  data: {
    subscription: subscriptionProps;
  };
  loading: boolean;
}
export const useStyles = makeStyles((theme) => ({
  boxxx: {
    backgroundImage: `url(${EggmedLogoSubscribe})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    height: '80vh',
  },
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
  },
  subscriptionSucccessLabel: {
    fontWeight: 600,
    fontSize: '18px',
    fontFamily: 'Inter',
    color: '#101828',
  },
  labelForm: {
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#344054',
    marginBottom: '6px',
  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  topsection: {
    backgroundColor: '#F5FBFF',
    paddingBlock: '16px',
    borderBottom: '1px solid #D0D5DD',
  },
  rootRadio: {
    maxWidth: '22px',
    marginLeft: '-8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconPatient: {
    cursor: 'pointer',
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'unset',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    paddingBlock: '9px',
    paddingInline: '18px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  radioLabel: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#475467',
  },
  label: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#344054',
  },
  clearTxt: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#B42318',
  },
  paragraph: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: theme.spacing(1),

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  selectPlanBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 18px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: theme.spacing(1),
    width: '100%',
    height: '48px',

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  delete: {
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 22px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    boxShadow: ' 0px 1px 2px rgba(16, 24, 40, 0.05)',

    '&:hover': {
      backgroundColor: '#D92D20',
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: '#D92D20',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  activateBtn: {
    backgroundColor: 'white',
    border: '1px solid #0265DC',
    color: '#0265DC',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: 'white',
      color: '#0265DC',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  date: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    // marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(1),
  },
  freeTrialBox: {
    padding: theme.spacing(2),
    backgroundColor: '#F0F9FF',
    borderLeft: '5px solid #0265DC',
  },
  canceledBox: {
    padding: theme.spacing(2),
    backgroundColor: '#FEF3F2',
    borderLeft: '5px solid #B42318',
  },
  trialLabel: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
  },
  trialDescription: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#182230',
  },
  planLabel: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '20px',
    color: '#344054',
  },
  plansDesc: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#667085',
  },
  currentPln: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
  },
  expireLabel: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
  },
  headerLabel: {
    fontWeight: 700,
    fontSize: '30px',
    color: 'white',
    fontFamily: 'Inter',
  },
  headerDesc: {
    fontWeight: 500,
    fontSize: '20px',
    color: 'white',
    fontFamily: 'Inter',
  },
  billingType: {
    backgroundColor: '#CAE8FF',
    width: '293px',
    height: '56px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
  },
  billingCard: {
    border: '1px solid #EAECF0',
    backgroundColor: 'white',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '16px',
  },
  billingName: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  billingDs: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  pricing: {
    display: 'flex',
    alignItems: 'baseline',
  },
  currency: {
    fontWeight: 600,
    fontSize: '36px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  price: {
    fontWeight: 600,
    fontSize: '60px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  priceLabel: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  features: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  featureName: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
    marginLeft: '10px',
  },
  trialDate: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#878787',
    fontFamily: 'Inter',
  },
  trialValue: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  editbt: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#101828',
    fontFamily: 'Inter',
    textDecoration: 'underline',
  },
  savesInfo: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#147AF3',
    fontFamily: 'Inter',
  },
  saveBox: {
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '52px',
    padding: '8px 40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  cancel: {
    marginRight: theme.spacing(2),
    backgroundColor: 'transparent',
    border: '1px solid rgba(130, 130, 130, 1)',
    color: '#425364',
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '21px',
    minWidth: '94px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    borderRadius: '5px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(130, 130, 130, 1)',
    },
  },
  save: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    minWidth: '94px',
    border: '1px solid #1F61DC',
    borderRadius: '5px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#1D31AA',
    },
    '&:disabled': {
      backgroundColor: '#D0D5DD',
      color: 'white',
      border: '1px solid #D0D5DD',
    },
  },
  doneBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    minWidth: '94px',
    border: '1px solid #147AF3',
    borderRadius: '8px',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    '&:hover': {
      backgroundColor: '#147AF3',
    },
  },
  subscriptionSpanLabel: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#878787',
  },
  subscriptionSpanValue: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#101828',
  },
}));
const Subscription = ({ data, loading }: Props) => {
  const { data: subscriptionProducts, loading: loadingPrice } = useQuery(
    SUBSCRIPTION_PRODUCTS
  );
  const { t, i18n } = useTranslation();
  const products = subscriptionProducts?.getEggmedProduct;
  const [activeTab, setActiveTab] = React.useState(0);
  const { subscribeEggmed, loading: loadingSubscribe } = useSubscribe();

  const classes = useStyles();
  const subscriptionData = data?.subscription;
  const { cancelSubscription } = useCancelSubscription();
  async function handleCancelSubscription() {
    await cancelSubscription({
      variables: {
        subscriptionId: subscriptionData?.subscription,
      },
    });
    window.location.reload();
  }
  async function RenewPlan() {
    const result = await subscribeEggmed({
      variables: {
        subscribeInput: {
          priceId: billingInfo[activeTab]?.priceId,
          interval: subscriptionData?.type,
        },
      },
    });
    window.location.href = result?.data?.subscribeEggmed;
  }

  function handleOpen() {
    setOpen(true);
  }
  const getProductDependOnInterval = (interval) => {
    return (
      products?.length > 0 && products?.find((el) => el.interval === interval)
    );
  };

  const billingInfo = {
    0: {
      label: 'Annualy',
      description: 'Billed annualy (USD)',
      value: getProductDependOnInterval('year')?.unit_amount / 100,
      interval: getProductDependOnInterval('year')?.interval,
      valueMonth: getProductDependOnInterval('year')?.unit_amount / 1200,
      priceId: getProductDependOnInterval('year')?.priceId,
    },
    1: {
      label: 'Monthly',
      description: 'Billed monthly (USD)',
      value: getProductDependOnInterval('month')?.unit_amount / 100,
      interval: getProductDependOnInterval('month')?.interval,
      priceId: getProductDependOnInterval('month')?.priceId,
    },
  };

  const { setOpen } = useAuth();
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const { register, control, errors, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      type: subscriptionData?.type + 'ly',
    },
  });
  const commonProps = { register, control, errors };
  React.useEffect(() => {
    if (data?.subscription) {
      reset({
        type: data?.subscription?.type + 'ly',
      });
    }
  }, [reset, data?.subscription]);
  const { changeSubscriptionPlan, loading: loadingChangePlan } =
    useChangePlan();
  const [openConfirmModal, setOenConfirmModal] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  function chooseInterval(intervaly: string) {
    if (intervaly === 'monthly') return 'month';
    else return 'year';
  }
  async function onSubmit(data) {
    const intervalData = chooseInterval(data?.type);
    const priceId = products?.find(
      (el) => el?.interval === intervalData
    )?.priceId;
    const result = await changeSubscriptionPlan({
      variables: {
        subscriptionId: subscriptionData?.subscription,
        interval: intervalData,
        priceId,
      },
    });
    setEditMode(false);
    setInfo(result?.data?.changeSubscriptionPlan);
    setOenConfirmModal(true);
  }

  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        <Skeleton loading={loading}>
          <Text
            i18nKey="Subscription"
            style={{
              fontFamily: 'Inter, sans-serif',
              fontStyle: 'normal',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#344054',
              marginBottom: '1rem',
            }}
          >
            Subscription
          </Text>
        </Skeleton>
      </Grid>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        <Box className={classes.divider} />
      </Grid>
      {dayjs(new Date(subscriptionData?.endDate)).isSame(dayjs(new Date())) &&
        dayjs(new Date()).format('HH:mm') <
          dayjs(new Date(subscriptionData?.endDate)).format('HH:mm') && (
          <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
            <LastDayTrial
              loading={loading}
              subscriptionData={subscriptionData}
              handleOpen={handleOpen}
            />
          </Grid>
        )}{' '}
      {!subscriptionData && (
        <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
          <SubscriptionCanceled
            loading={loading}
            subscriptionData={subscriptionData}
            handleOpen={handleOpen}
          />
        </Grid>
      )}
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        {subscriptionData?.status === 'trialing' && !loading && (
          <>
            <Skeleton loading={loading}>
              <Box className={classes.freeTrialBox}>
                <Skeleton loading={loading}>
                  <Typography className={classes.trialLabel}>
                    {t('Your trial ends in')}{' '}
                    {dayjs(new Date(subscriptionData?.endDate)).diff(
                      dayjs(new Date()),
                      'days'
                    )}{' '}
                    {t('days')}
                  </Typography>
                </Skeleton>
                <Typography className={classes.trialDescription}>
                  {t(
                    'Your trial is ending. Activate your plan to keep using Eggmed.'
                  )}
                </Typography>
                <CustomButton
                  className={classes.addBtn}
                  buttonType="reset"
                  onClick={handleOpen}
                >
                  <Text i18nKey="activatePlan" style={{ fontWeight: 500 }}>
                    {t('Activate your plan')}
                  </Text>
                </CustomButton>
              </Box>
            </Skeleton>
            <Box
              my={6}
              width="100%"
              height="1px"
              style={{ backgroundColor: '#F4F2EE' }}
            />{' '}
          </>
        )}
        {subscriptionData?.status === 'active' &&
          subscriptionData?.cancelAtPeriodEnd &&
          !loading && (
            <>
              <Skeleton loading={loading}>
                <Box className={classes.freeTrialBox}>
                  <Skeleton loading={loading}>
                    <Typography className={classes.trialLabel}>
                      {dayjs(new Date(subscriptionData?.endDate)).diff(
                        dayjs(new Date()),
                        'days'
                      )}{' '}
                      {t('days left in your subscription')}
                    </Typography>
                  </Skeleton>
                  <Typography className={classes.trialDescription}>
                    {t(
                      'You canceled your subscription. You wont be able to use Eggmed after'
                    )}{' '}
                    {dayjs(subscriptionData?.endDate).format(
                      i18n.language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY'
                    )}
                    . {t('Please renew to continue your access.')}{' '}
                  </Typography>
                  <CustomButton
                    className={classes.addBtn}
                    buttonType="reset"
                    onClick={RenewPlan}
                  >
                    <Text
                      i18nKey="RenewSubscription"
                      style={{ fontWeight: 500 }}
                    >
                      {t('Renew subscription')}
                    </Text>
                  </CustomButton>
                </Box>
              </Skeleton>
              <Box
                my={6}
                width="100%"
                height="1px"
                style={{ backgroundColor: '#F4F2EE' }}
              />{' '}
            </>
          )}
        {subscriptionData?.status === 'trialing' && !loading ? (
          <>
            <Box>
              <Typography className={classes.planLabel}>
                {t('Subscription information')}
              </Typography>
              <Box
                mt={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="35%"
              >
                <Box>
                  <Typography className={classes.currentPln}>
                    {t('Free trial')}
                  </Typography>
                  <Typography className={classes.expireLabel}>
                    {t('Activate your plan to continue using Eggmed')}{' '}
                  </Typography>
                </Box>
                <CustomButton
                  className={classes.activateBtn}
                  buttonType="reset"
                  onClick={handleOpen}
                >
                  <Boost />
                  <Text i18nKey="Activate" style={{ fontWeight: 500 }}>
                    Activate
                  </Text>
                </CustomButton>
              </Box>
            </Box>
            <Box borderBottom="1px solid #EAECF0" pb={4} width="35%">
              <Box px={4} mt={2} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.trialDate}>
                    {t('Trial end date')}
                  </Typography>
                  <Typography className={classes.trialValue}>
                    {dayjs(subscriptionData?.endDate).format(
                      i18n.language === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
                    )}
                  </Typography>
                </Box>
                <Box ml={4}>
                  <Typography className={classes.trialDate}>
                    {t('Cost')} (USD)
                  </Typography>
                  <Typography className={classes.trialValue}>$0</Typography>
                </Box>
              </Box>{' '}
            </Box>
          </>
        ) : (
          !loading &&
          subscriptionData?.status === 'active' &&
          !subscriptionData?.cancelAtPeriodEnd && (
            <Box>
              <Box display="flex" justifyContent="space-between" width={'35%'}>
                <Box>
                  <Typography className={classes.planLabel}>
                    {t('Renewal information')}
                  </Typography>
                  <Typography className={classes.expireLabel}>
                    {t('Your subscription will be automatically renewed')}{' '}
                  </Typography>
                </Box>
                {!editMode && (
                  <Typography
                    style={{ cursor: 'pointer' }}
                    className={classes.editbt}
                    onClick={() => setEditMode(true)}
                  >
                    {t('Edit')}
                  </Typography>
                )}
              </Box>
              <Box borderBottom="1px solid #EAECF0" pb={4} width="35%">
                {!editMode ? (
                  <Box
                    px={4}
                    mt={2}
                    display="flex"
                    alignItems="center"
                    style={{ gap: '3rem' }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Box>
                        <Typography className={classes.trialDate}>
                          {t('Your plan')}
                        </Typography>
                        <Typography className={classes.trialValue}>
                          {t('Professional')}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Typography className={classes.trialDate}>
                          {t('Billing cycle')}
                        </Typography>
                        <Typography
                          className={classes.trialValue}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t(`${subscriptionData?.type}ly`)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box>
                        <Typography className={classes.trialDate}>
                          {t('Cost')} (USD)
                        </Typography>
                        <Typography className={classes.trialValue}>
                          ${subscriptionData?.cost}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Typography className={classes.trialDate}>
                          {t('Next billing date')}
                        </Typography>
                        <Typography className={classes.trialValue}>
                          {dayjs(subscriptionData?.endDate).format(
                            i18n.language === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mt={2} pl={4}>
                      <Typography className={classes.labelForm}>
                        {t('Billing cycle')}
                      </Typography>
                      <Input
                        // className={classes.inputMui2}
                        type="select"
                        name="type"
                        variant="filled"
                        options={['monthly', 'yearly']}
                        {...commonProps}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        mt={3}
                        style={{ gap: '2rem' }}
                      >
                        <Box>
                          <Typography className={classes.trialDate}>
                            {t('Cost')} (USD)
                          </Typography>
                          <Typography className={classes.trialValue}>
                            ${subscriptionData?.cost}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.trialDate}>
                            {t('Next billing date')}
                          </Typography>
                          <Typography className={classes.trialValue}>
                            {dayjs(subscriptionData?.endDate).format(
                              i18n.language === 'fr'
                                ? 'DD/MM/YYYY'
                                : 'MM/DD/YYYY'
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                          className={classes.cancel}
                          style={{ marginRight: '5px' }}
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(false);
                          }}
                          buttonType="reset"
                          type="cancel"
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          type="submit"
                          buttonType="submit"
                          data-cy="submit"
                          className={classes.save}
                          disabled={
                            watch('type') === subscriptionData?.type + 'ly'
                          }
                        >
                          {loadingChangePlan ? (
                            <ProgressLoader width={20} height={20} />
                          ) : (
                            <Text i18nKey="save">Save</Text>
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}{' '}
              </Box>
              <Box mt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="35%"
                  style={{
                    cursor: editMode && 'not-allowed',
                    opacity: editMode && 0.5,
                  }}
                >
                  <Box>
                    <Typography className={classes.planLabel}>
                      {t('Cancel subscription')}
                    </Typography>
                    <Typography className={classes.expireLabel}>
                      {t('accessPeriodText')}
                    </Typography>
                  </Box>
                  <CustomButton
                    className={classes.delete}
                    buttonType="reset"
                    onClick={() => {
                      setOpenCancelModal(true);
                    }}
                    disabled={editMode}
                  >
                    <Text i18nKey="cancel" style={{ fontWeight: 500 }}>
                      Cancel
                    </Text>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          )
        )}
        {subscriptionData?.status === 'active' &&
          subscriptionData?.cancelAtPeriodEnd &&
          !loading && (
            <Box>
              <Box display="flex" justifyContent="space-between" width={'35%'}>
                <Box>
                  <Typography className={classes.planLabel}>
                    {t('Renewal information')}
                  </Typography>
                  <Typography className={classes.expireLabel}>
                    {t('Your subscription will not be renewed automatically')}{' '}
                  </Typography>
                </Box>
              </Box>
              <Box borderBottom="1px solid #EAECF0" pb={4} width="35%">
                <Box
                  px={4}
                  mt={2}
                  display="flex"
                  alignItems="center"
                  style={{ gap: '3rem' }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Typography className={classes.trialDate}>
                        {t('Your plan')}
                      </Typography>
                      <Typography className={classes.trialValue}>
                        {t('Professional')}
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography className={classes.trialDate}>
                        {t('Billing cycle')}
                      </Typography>
                      <Typography
                        className={classes.trialValue}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t(subscriptionData?.type + 'ly')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Typography className={classes.trialDate}>
                        {t('Cost')} (USD)
                      </Typography>
                      <Typography className={classes.trialValue}>
                        ${subscriptionData?.cost}
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography className={classes.trialDate}>
                        {t('Next billing date')}
                      </Typography>
                      <Typography className={classes.trialValue}>- </Typography>
                    </Box>
                  </Box>
                </Box>{' '}
              </Box>
              {subscriptionData?.status === 'active' &&
                !subscriptionData?.cancelAtPeriodEnd && (
                  <Box mt={3}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="35%"
                    >
                      <Box>
                        <Typography className={classes.planLabel}>
                          {t('Cancel subscription')}
                        </Typography>
                        <Typography className={classes.expireLabel}>
                          {t(
                            "You'll have access until the billing period ends"
                          )}
                        </Typography>
                      </Box>
                      <CustomButton
                        className={classes.delete}
                        buttonType="reset"
                        onClick={() => {
                          setOpenCancelModal(true);
                        }}
                      >
                        <Text i18nKey="cancel" style={{ fontWeight: 500 }}>
                          Cancel
                        </Text>
                      </CustomButton>
                    </Box>
                  </Box>
                )}
            </Box>
          )}
      </Grid>
      <BasicModal
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        isRegularModal
        handleClose={() => setOpenCancelModal(false)}
      >
        <DeleteAccountModal
          label="Cancel subscription?"
          description="Sorry to see you go. You will be able to access your account until the billing period ends."
          deleteBtn="Cancel subscription"
          handleClose={() => setOpenCancelModal(false)}
          handleDelete={() => {
            handleCancelSubscription();
            setOpenCancelModal(false);
          }}
          loading={loading}
        />
      </BasicModal>
      <BasicModal
        open={openConfirmModal}
        onClose={() => setOenConfirmModal(false)}
        isRegularModal
        handleClose={() => setOenConfirmModal(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          px={'4rem'}
          pb={'1rem'}
        >
          <Lottie
            animationData={CreditCard}
            loop={true}
            style={{ width: '100px', height: '100px' }}
          />
          <Typography className={classes.subscriptionSucccessLabel}>
            {t('Subscription updated successfully')}
          </Typography>
          <Box my={3} textAlign="center">
            <Typography className={classes.subscriptionSpanLabel}>
              {t('Your plan')}
            </Typography>
            <Typography className={classes.subscriptionSpanValue}>
              {t('Professional')}
            </Typography>
          </Box>
          <Box mb={3} textAlign="center">
            <Typography className={classes.subscriptionSpanLabel}>
              {t('Cost')} (USD)
            </Typography>
            <Typography className={classes.subscriptionSpanValue}>
              {info?.cost}$
            </Typography>
          </Box>
          <Box mb={3} textAlign="center">
            <Typography className={classes.subscriptionSpanLabel}>
              {t('Next billing date')}
            </Typography>
            <Typography className={classes.subscriptionSpanValue}>
              {dayjs(info?.endDate).format(
                i18n.language === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
              )}
            </Typography>
          </Box>
          <Box mb={3} textAlign="center">
            <Typography className={classes.subscriptionSpanLabel}>
              {t('Billing cycle')}
            </Typography>
            <Typography
              className={classes.subscriptionSpanValue}
              style={{ textTransform: 'capitalize' }}
            >
              {t(`${info?.type}ly`)}
            </Typography>
          </Box>
          <Button
            className={classes.doneBtn}
            onClick={() => setOenConfirmModal(false)}
          >
            {t('Done')}
          </Button>
        </Box>
      </BasicModal>
    </Grid>
  );
};

export const SUBSCRIPTION_PRODUCTS = gql`
  query getEggmedProduct {
    getEggmedProduct {
      name
      priceId
      unit_amount
      currency
      interval
    }
  }
`;

export default Subscription;
