import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import useGetAppointments from '@eggmed/graphql-client/operations/appointmentsOperations/useGetAppointments';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React, { useContext, useReducer } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS } from 'pages/DashboardPage';
import {
  CLOSE_DROPDOWN_MENU,
  OPEN_DROPDOWN_MENU,
  SET_CURRENT_ROW,
  SET_PAGE,
} from './actions';
import { Context } from './context';
import { reducer } from './reducer';

export default function Provider({ children, initialValue }: any) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    currentPage: 1,
  });
  const { deleteAppointment } = useDeleteAppointment();
  const { user, doctor } = useAuth();
  const { doctorId } = user;
  const queryResult = useGetAppointments({
    limit: '5',
    page: `${state.currentPage}`, // startDate: dayjs().startOf('week').valueOf(),
    // endDate: dayjs().endOf('week').valueOf(),
  });
  const { data: doctorPastOrUpcomingAppointments, loading: loadingData } =
    useQuery(GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS);

  function handleOpenDropDownMenu(event: any, row: any) {
    dispatch({ type: OPEN_DROPDOWN_MENU, payload: event?.currentTarget });
    dispatch({ type: SET_CURRENT_ROW, payload: row });
  }
  function handleCloseDropDown() {
    dispatch({ type: CLOSE_DROPDOWN_MENU });
  }

  function handleChangePage(e: unknown, pageNumber: number) {
    dispatch({ type: SET_PAGE, payload: pageNumber });
  }

  return (
    <Context.Provider
      value={{
        ...state,
        handleOpenDropDownMenu,
        handleCloseDropDown,
        handleChangePage,
        deleteAppointment,
        user,
        doctor,
        ...queryResult,
        doctorPastOrUpcomingAppointments,
        loadingData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useAppointmentListPageProvider must be used within a AppointmentListPageProvider'
    );
  }

  return context;
}
