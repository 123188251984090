import { Grid } from '@material-ui/core';
import FeatureNotUsed from 'components/FeatureNotUsed';
import BasicModal from 'components/ui/Modal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React, { ReactElement, useState } from 'react';
import Conversation from './components/Conversation/Conversation';
import Message from './components/Message';
import Modals from './modals';
import InboxPageProvider from './state/Provider';
import useStyles from './style';
import { useProvider } from './state/Provider';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const Inbox = (props): ReactElement => {
  const { t } = useTranslation();
  const [firstTimeModal, setFirstTimeModal] = useState(true);
  const [createNewThread, setCreateNewThread] = useState(true);
  const { doctor } = useAuth();
  const { noUsedFeatures } = doctor || {};
  const classes = useStyles();
  const { messageHidden } = useProvider();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Messaging') });
  }, [headerTitleDispatcher]);

  const { firstname, lastname, middlename, _id, email, picture } =
    props?.data?.patient || {};

  return (
    <div className={classes.container}>
      {/* {noUsedFeatures.indexOf('message') !== -1 && (
        <BasicModal open={firstTimeModal}>
          <FeatureNotUsed
            onClose={() => setFirstTimeModal(false)}
            type="message"
          />
        </BasicModal>
      )} */}

      <Modals
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
      <Grid container className={classes.fullHeight}>
        <Grid
          item
          xl={3}
          lg={3}
          md={5}
          xs={12}
          sm={12}
          className={messageHidden ? classes.fullHeight : classes.hidden}
        >
          <Conversation />
        </Grid>
        <Grid
          item
          xl={9}
          lg={9}
          md={7}
          xs={12}
          sm={12}
          className={messageHidden ? classes.hidden : classes.fullHeight}
        >
          <Message />
        </Grid>
      </Grid>
    </div>
  );
};

export default Inbox;
