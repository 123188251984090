import { Box, Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';

interface IRequirePrepayment {
  register: React.Ref<HTMLInputElement>;
  isPrepayChecked: boolean;
  setIsPrepayChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isActivePayout?: boolean;
}

export const RequirePrepayment = ({
  register,
  isPrepayChecked,
  setIsPrepayChecked,
  isActivePayout,
}: IRequirePrepayment) => {
  const classes = useStyles({ isActivePayout });
  React.useEffect(() => {
    if (!isActivePayout) {
      setIsPrepayChecked(false);
    }
  }, []);
  return (
    <Box className={classes.requirePrepayContainer} mb={2}>
      <Box>
        <Typography className={classes.requirePrepayTitle}>
          <Text i18nKey="requirePrePayment">Require prepayment</Text>
        </Typography>
        <Text
          i18nKey="Client must pay session fees in order to join"
          className={classes.requirePrepayDesc}
        >
          Client must pay session fees in order to join{' '}
        </Text>
      </Box>
      <Checkbox
        inputRef={register}
        name="isPrepay"
        size="medium"
        checked={isPrepayChecked}
        onChange={() => setIsPrepayChecked((s) => !s)}
        checkedIcon={<CheckedIcon />}
        icon={<UncheckedIcon />}
        style={{ backgroundColor: 'transparent' }}
        disabled={!isActivePayout}
      />
    </Box>
  );
};
