import React, { useState } from 'react';
import useStyles from './styles';
import {
  Box,
  Button,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ReactComponent as PreviewIcon } from 'assets/form/toolbar/preview.svg';
import { ReactComponent as ShareIcon } from 'assets/form/toolbar/share.svg';
import { ReactComponent as DisabledShareIcon } from 'assets/form/toolbar/disabledShare.svg';
import { ReactComponent as SaveIcon } from 'assets/form/toolbar/save.svg';
import { ReactComponent as PlusIcon } from 'assets/form/toolbar/plus.svg';
import clsx from 'clsx';
import EditableText from './EditableText';
import { ReactComponent as PrevIcon } from 'assets/form/toolbar/reverse-left.svg';
import { ReactComponent as NextIcon } from 'assets/form/toolbar/reverse-right.svg';
import { ReactComponent as BoldIcon } from 'assets/form/toolbar/bold.svg';
import { ReactComponent as ItalicIcon } from 'assets/form/toolbar/italic.svg';
import { ReactComponent as UnderlineIcon } from 'assets/form/toolbar/underline.svg';
import { ReactComponent as MinusTextIcon } from 'assets/form/toolbar/minus-text.svg';
import { ReactComponent as PlusTextIcon } from 'assets/form/toolbar/plus-text.svg';
import { ReactComponent as HeadingSign } from 'assets/form/toolbar/headingSign.svg';
import { ReactComponent as ListDots } from 'assets/form/toolbar/list-dots.svg';
import { ReactComponent as AlignmentSymbol } from 'assets/form/toolbar/alignment-symbol.svg';
import { ReactComponent as TextColor } from 'assets/form/toolbar/text-color.svg';
import { ReactComponent as HighlightSymbol } from 'assets/form/toolbar/highlight.svg';
import { ReactComponent as LinkSymbol } from 'assets/form/toolbar/link-icon.svg';
import { ReactComponent as MicroIcon } from 'assets/form/toolbar/micro-icon.svg';
import { ReactComponent as Italic } from 'assets/form/toolbar/italic-icon.svg';
import { ReactComponent as ClickedBold } from 'assets/form/toolbar/clicked-bold.svg';
import { ReactComponent as NotClickedBold } from 'assets/form/toolbar/not-clicked-bold.svg';
import { useEditorContext } from '../EditorContextProvider';
import { ReactComponent as Underline } from 'assets/form/toolbar/underline-icon.svg';
import { ReactComponent as BulletlistIcon } from 'assets/form/toolbar/bullet-list.svg';
import { ReactComponent as NumberedlistIcon } from 'assets/form/toolbar/numbered-lis.svg';
import { ReactComponent as LeftAlign } from 'assets/form/toolbar/left-align.svg';
import { ReactComponent as CenterAlign } from 'assets/form/toolbar/center-align.svg';
import { ReactComponent as RightAlign } from 'assets/form/toolbar/right-align.svg';
import { ReactComponent as Justify } from 'assets/form/toolbar/align-justify.svg';
import { ReactComponent as IncreaseIndent } from 'assets/form/toolbar/right-intend.svg';
import { ReactComponent as DecreaseIndent } from 'assets/form/toolbar/left-intend.svg';
import { ReactComponent as QuoteIcon } from 'assets/form/toolbar/quote.svg';
import { ReactComponent as Heading1 } from 'assets/form/toolbar/heading1.svg';
import { ReactComponent as Heading2 } from 'assets/form/toolbar/heading2.svg';
import { ReactComponent as Heading3 } from 'assets/form/toolbar/heading3.svg';
import { ReactComponent as Paragraph } from 'assets/form/toolbar/parag.svg';

import { ReactComponent as TableIcon } from 'assets/form/toolbar/table.svg';
import { ReactComponent as ImageIcon } from 'assets/form/toolbar/image.svg';
import { ReactComponent as HorizontalLineIcon } from 'assets/form/toolbar/horizontal-line.svg';
import { ReactComponent as PageBreakIcon } from 'assets/form/toolbar/page-break.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/form/toolbar/multiple-choice.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/form/toolbar/single-choice.svg';
import { ReactComponent as DropdownIcon } from 'assets/form/toolbar/dropdown.svg';
import { ReactComponent as DateIcon } from 'assets/form/toolbar/date.svg';
import { ReactComponent as DateRangeIcon } from 'assets/form/toolbar/date-range.svg';
import { ReactComponent as ShortTextIcon } from 'assets/form/toolbar/short-text.svg';
import { ReactComponent as LongTextIcon } from 'assets/form/toolbar/long-text.svg';
import { ReactComponent as SignatureIcon } from 'assets/form/toolbar/signature.svg';
import { ReactComponent as AddressIcon } from 'assets/form/toolbar/address.svg';
import { ReactComponent as EmailIcon } from 'assets/form/toolbar/email.svg';
import { ReactComponent as PhoneNumberIcon } from 'assets/form/toolbar/phone.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/form/toolbar/linear-scale.svg';
import { ReactComponent as Valid } from 'assets/form/toolbar/valid.svg';
import { ReactComponent as NotValid } from 'assets/form/toolbar/not-valid.svg';

import GenericList from './List';
import { fontFamilies } from './Font';

export interface IListOption {
  name: string;
  icon: React.ReactNode;
  blockName: string;
  level?: number;
}
export const CustomToolbar = ({ handleClose, handleSave }) => {
  const classes = useStyles();
  const { selectedTemplate, addBlock, editorRef, handleCloseTemplate } =
    useEditorContext();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openList, setOpenList] = useState(null);
  const [link, setLink] = useState('');

  const addLinkToEditor = () => {
    addBlock('linkTool');
    setOpenList(null);
    setLink('');
  };
  const toggleListVisibilityList = (listName) => {
    setOpenList((prev) => (prev === listName ? null : listName));
  };
  const handlCloseToolbar = () => {
    handleCloseTemplate();
    handleClose();
  };
  const listOptions: IListOption[] = [
    { name: 'Bullet list', icon: <BulletlistIcon />, blockName: 'list' },
    {
      name: 'Numbered list',
      icon: <NumberedlistIcon />,
      blockName: 'Orderedlist',
    },
  ];
  const alignOptions: IListOption[] = [
    { name: 'Left align', icon: <LeftAlign />, blockName: 'list' },
    { name: 'Center align', icon: <CenterAlign />, blockName: 'list' },
    { name: 'Rignt align', icon: <RightAlign />, blockName: 'list' },
    { name: 'Justify', icon: <Justify />, blockName: 'list' },
    { name: 'Increase indent', icon: <IncreaseIndent />, blockName: 'list' },
    { name: 'Decrease indent', icon: <DecreaseIndent />, blockName: 'list' },
  ];
  const headingOptions: IListOption[] = [
    {
      name: '',
      icon: <Heading1 />,
      blockName: 'header',
      level: 1,
    },
    {
      name: '',
      icon: <Heading2 />,
      blockName: 'header',
      level: 2,
    },
    {
      name: '',
      icon: <Heading3 />,
      blockName: 'header',
      level: 3,
    },
    { name: '', icon: <Paragraph />, blockName: 'paragraph' },
    { name: '', icon: <QuoteIcon />, blockName: 'quote' },
  ];
  const insertOptions: IListOption[] = [
    {
      name: 'Multiple choice',
      icon: <MultipleChoiceIcon />,
      blockName: 'Multiple_choice',
    },
    {
      name: 'Single choice',
      icon: <SingleChoiceIcon />,
      blockName: 'Single_choice',
    },
    {
      name: 'Linear scale',
      icon: <LinearScaleIcon />,
      blockName: 'LineaireScale',
    },
    { name: 'Dropdown', icon: <DropdownIcon />, blockName: 'DropDown' },
    { name: 'Date', icon: <DateIcon />, blockName: 'Date' },
    { name: 'Date range', icon: <DateRangeIcon />, blockName: 'Date' },
    { name: 'Short text', icon: <ShortTextIcon />, blockName: 'Short_answer' },
    { name: 'Long text', icon: <LongTextIcon />, blockName: 'Long_answer' },
    { name: 'Email', icon: <EmailIcon />, blockName: 'Email' },
    { name: 'Phone', icon: <PhoneNumberIcon />, blockName: 'Phone' },
    { name: 'Address', icon: <AddressIcon />, blockName: 'Address' },
    { name: 'Signature', icon: <SignatureIcon />, blockName: 'Signature' },
  ];
  return (
    <div className={classes.toolbar}>
      <EditableText />
      <div className={classes.toolbarCenter} style={{ position: 'relative' }}>
        <Button
          className={clsx(classes.button, classes.plusButton)}
          onClick={() => toggleListVisibilityList('insertOptions')}
        >
          <PlusIcon className={classes.btnIcon} />
          Insert
        </Button>

        {openList === 'insertOptions' && (
          <GenericList
            options={insertOptions}
            toggleListVisibilityList={toggleListVisibilityList}
            selectedIndex={selectedIndex}
          />
        )}
        <div className={clsx(classes.centerPart, classes.arrows)}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Undo"
            placement="top-start"
          >
            <PrevIcon className={classes.textIcon} />
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Redo"
            placement="top-start"
          >
            <NextIcon className={classes.textIcon} />
          </Tooltip>
        </div>
        <div className={classes.centerPart}>
          {/* <ClickedBold className={classes.textIcon} /> */}{' '}
          {/** to be added
            when it's dynamic */}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Bold"
            placement="top-start"
          >
            <NotClickedBold className={classes.textIcon} />
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Italic"
            placement="top-start"
          >
            <Italic className={classes.textIcon} />
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Underline"
            placement="top-start"
          >
            <Underline className={classes.textIcon} />
          </Tooltip>
        </div>
        <div className={classes.centerPart}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Decrease size"
            placement="top-start"
          >
            <MinusTextIcon className={classes.textIcon} />
          </Tooltip>
          <input type="text" placeholder="22" className={classes.inputSize} />
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Increase size"
            placement="top-start"
          >
            <PlusTextIcon className={classes.textIcon} />
          </Tooltip>
        </div>
        <div className={classes.centerPart} style={{ position: 'relative' }}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Text style"
            placement="top-start"
          >
            <HeadingSign
              className={classes.textIcon}
              onClick={() => {
                toggleListVisibilityList('headingOptions');
              }}
            />
          </Tooltip>
          {openList === 'headingOptions' && (
            <GenericList
              options={headingOptions}
              selectedIndex={selectedIndex}
              toggleListVisibilityList={toggleListVisibilityList}
            />
          )}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Font"
            placement="top-start"
          >
            <FormControl>
              <Select
                defaultValue={'Playfair Display'}
                disableUnderline
                className={classes.textType}
                MenuProps={{
                  disableScrollLock: false,
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value="Playfair Display">
                  <em>Playfair Display</em>
                </MenuItem>
                {fontFamilies?.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Typography style={{ fontFamily: option }}>
                      {' '}
                      {option}{' '}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </div>
        <div className={classes.centerPart} style={{ position: 'relative' }}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="List"
            placement="top-start"
          >
            <ListDots
              className={classes.textIcon}
              onClick={() => toggleListVisibilityList('listOptions')}
            />
          </Tooltip>
          {openList === 'listOptions' && (
            <GenericList
              options={listOptions}
              selectedIndex={selectedIndex}
              toggleListVisibilityList={toggleListVisibilityList}
            />
          )}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Text allignment"
            placement="top-start"
          >
            <AlignmentSymbol
              className={classes.textIcon}
              onClick={() => toggleListVisibilityList('alignOptions')}
            />
          </Tooltip>
          {openList === 'alignOptions' && (
            <GenericList
              options={alignOptions}
              toggleListVisibilityList={toggleListVisibilityList}
              selectedIndex={selectedIndex}
            />
          )}
        </div>
        <div className={classes.centerPart}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Text color"
            placement="top-start"
          >
            <TextColor className={classes.textIcon} />
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Highlight color"
            placement="top-start"
          >
            <HighlightSymbol className={classes.textIcon} />
          </Tooltip>
        </div>
        <div className={classes.centerPart} style={{ position: 'relative' }}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Insert link"
            placement="top-start"
          >
            <LinkSymbol
              className={classes.textIcon}
              onClick={() => toggleListVisibilityList('link')}
            />
          </Tooltip>
          {openList == 'link' && (
            <Box
              style={{
                position: 'absolute',
                top: '30px',
                left: '0',
                zIndex: 40,
              }}
            >
              <List>
                <ListItem className={classes.inputLinkWrapper}>
                  <input
                    type="text"
                    placeholder="https://"
                    onChange={(e) => setLink(e.target.value)}
                    className={classes.inputLink}
                  />
                  <ListItemIcon
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Valid onClick={addLinkToEditor} />
                    <NotValid onClick={() => setOpenList(null)} />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Box>
          )}
        </div>
        <div className={classes.endPart}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Transcript"
            placement="top-start"
          >
            <MicroIcon className={classes.textIcon} />
          </Tooltip>
        </div>
      </div>
      <Box className={classes.buttons}>
        <Button className={clsx(classes.button, classes.previewBtn)}>
          <PreviewIcon className={classes.btnIcon} />
          Preview
        </Button>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title="Select a location to save and share your template"
          placement="bottom-end"
        >
          <div>
            <Button
              className={clsx(
                classes.button,
                selectedTemplate ? classes.shareBtn : classes.disabledShareBtn
              )}
              disabled={!selectedTemplate}
            >
              {!selectedTemplate ? (
                <DisabledShareIcon className={classes.btnIcon} />
              ) : (
                <ShareIcon className={classes.btnIcon} />
              )}
              Share
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title="Select a location to save your template"
          placement="bottom-end"
        >
          <div>
            <Button
              className={clsx(
                classes.button,
                selectedTemplate ? classes.saveBtn : classes.disabledBtn
              )}
              data-cy="submit"
              type="submit"
              onClick={handleSave}
              disabled={!selectedTemplate}
            >
              <SaveIcon className={classes.btnIcon} />
              Save
            </Button>
          </div>
        </Tooltip>
        <Button
          className={clsx(classes.button, classes.closeBtn)}
          onClick={handlCloseToolbar}
        >
          Close
        </Button>
      </Box>
    </div>
  );
};
