import { gql, useQuery } from '@apollo/client';

export const GET_INVOICE_REFERENCE = gql`
  query getInvoiceReference {
    getInvoiceReference
  }
`;

export default function useGetInvoiceReference() {
  const { data, error, loading } = useQuery(GET_INVOICE_REFERENCE, {
    fetchPolicy: 'network-only',
  });
  return { data, error, loading };
}
