import { Box, Divider, Typography, Button } from '@material-ui/core';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import React from 'react';
import useStyles from '../styles';
import QuestionPreview from 'pages/FormsPage/QuestionPreview';
import { QuestionTypeEnum } from 'pages/FormsPage/Constants';
import DropdownIcon from 'assets/patientActivities/dropdown';
import { ReactComponent as ManageIcon } from 'assets/session/file-search-02.svg';
import Menu from 'components/ui/Menu';
import AddNotesModal from './AddNotesModal';
import BasicModal from 'components/ui/Modal';
import AssignModal from 'pages/PatientActivities/Questionaires/AssignModal';
import useEditNotes from '@eggmed/graphql-client/operations/appointmentsOperations/useEditNotes';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import useDeleteDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteDoctorTemplate';
import { useTranslation } from 'react-i18next';

export interface Itemplate {
  _id?: string;
  title: string;
  questions: IQuestion[];
  isDefault?: boolean;
}

function NotesTab({
  patientId,
  appointmentId,
  doctorTemplates,
  notes,
}: {
  patientId: string;
  appointmentId: string;
  doctorTemplates: Itemplate[];
  notes: Itemplate;
}) {
  const classes = useStyles({});
  const [currentTemplate, setCurrentTemplate] = React.useState<Itemplate>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef(null);
  const [dropdownWidth, setDropdownWidth] = React.useState<number>();
  React.useEffect(() => {
    if (anchorRef.current) {
      setDropdownWidth(anchorRef.current.offsetWidth);
    }
  }, [anchorEl]);
  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState<Itemplate[]>([]);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [formToAssign, setFormToAssign] = React.useState<Itemplate>(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { t } = useTranslation();
  const [notesTitle, setNotesTitle] = React.useState<string>(t('Notes'));
  const [handleEditNotes] = useEditNotes();
  React.useEffect(() => {
    if (notes && notes._id) {
      const questions = currentTemplate?.questions?.map((question) => {
        const { _id, __typename, ...rest } = question || {};
        return rest;
      });
      handleEditNotes({
        variables: {
          notesId: notes?._id,
          notesInput: {
            title: currentTemplate?.title,
            questions,
          },
        },
      });
    }
  }, [currentTemplate]);
  React.useEffect(() => {
    setTemplates(doctorTemplates);
  }, [doctorTemplates]);
  const menuElems =
    templates &&
    templates.map((template) => {
      return {
        name: template.title,
        onClick: () => {
          setFormToAssign(template);
          setAnchorEl(null);
          setOpenAssign(true);
        },
      };
    });
  React.useEffect(() => {
    if (notes) {
      setCurrentTemplate(notes);
    }
  }, [notes]);

  const handleInputChange = (questionIndex: number, value: string) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        responses: [value],
      };
      return { ...prevQuestions, questions: updatedQuestions };
    });
  };

  const handleCheckboxChange = (questionIndex, option) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      const currentResponses = updatedQuestions[questionIndex].responses || [];

      const updatedQuestion = {
        ...updatedQuestions[questionIndex],
        responses: currentResponses.includes(option)
          ? currentResponses.filter((item) => item !== option)
          : [...currentResponses, option],
      };

      updatedQuestions[questionIndex] = updatedQuestion;

      return { ...prevQuestions, questions: updatedQuestions };
    });
  };
  const handleAddress = (
    questionIndex: number,
    value: string,
    field: string
  ) => {
    setCurrentTemplate((prevQuestions) => {
      const updatedQuestions = [...prevQuestions.questions];
      const currentResponses = updatedQuestions[questionIndex].responses || [];

      const updatedResponses = {
        ...currentResponses,
        address: {
          ...currentResponses.address,
          [field]: value,
        },
      };
      const updatedQuestion = {
        ...updatedQuestions[questionIndex],

        responses: updatedResponses,
      };
      updatedQuestions[questionIndex] = updatedQuestion;
      return { ...prevQuestions, blocks: JSON.stringify(updatedQuestions) };
    });
  };
  const [handleDeleteDoctorTemplate] = useDeleteDoctorTemplate();
  const handleDelete = async () => {
    await handleDeleteDoctorTemplate({
      variables: { id: formToAssign._id },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setOpenDeleteModal(false);
  };
  const handleDeleteModal = (form: Itemplate) => {
    setOpenDeleteModal(true);
    setFormToAssign(form);
  };
  return (
    <Box width="100%" mb="2rem">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div ref={anchorRef}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ cursor: 'pointer' }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={classes.dropdown}
          >
            <Typography className={classes.notesTitle}>
              {currentTemplate?.title}
            </Typography>
            <DropdownIcon width="17" height="9" />
          </Box>
        </div>
        <Button className={classes.manageButton} onClick={() => setOpen(true)}>
          <ManageIcon style={{ marginRight: '5px' }} />
          <Typography>Templates</Typography>
        </Button>
      </Box>
      {currentTemplate?.questions?.map((question, index) => (
        <Box
          mt="1rem"
          style={{
            background: 'white',
            border: '1px solid #D0D5DD',
            borderRadius: '16px',
          }}
        >
          <QuestionPreview
            question={question}
            isNotes
            ignoreRequired
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            handleAddress={handleAddress}
            index={index}
            editMode={true}
            noMargin
          />
        </Box>
      ))}
      <BasicModal
        isRegularModal
        isSlide
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        title={notesTitle}
        divider
      >
        <AddNotesModal
          setNotesTitle={setNotesTitle}
          handleClose={() => setOpen(false)}
          openDeleteModal={handleDeleteModal}
          templates={templates}
          patientId={patientId}
          appointmentId={appointmentId}
          setFormToAssign={setFormToAssign}
          setOpenAssign={setOpenAssign}
        />
      </BasicModal>
      <ModalDelete
        text="Template"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
      <BasicModal
        isRegularModal
        open={openAssign}
        onClose={() => setOpenAssign(false)}
        handleClose={() => setOpenAssign(false)}
      >
        <AssignModal
          text="Are you sure you want to change template? all progress will be lost"
          handleSubmit={() => {
            setCurrentTemplate(formToAssign);
            setOpenAssign(false);
          }}
          handleClose={() => setOpenAssign(false)}
        />
      </BasicModal>
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        width={dropdownWidth}
        style={{ borderRadius: '50px' }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={
          menuElems?.length > 0
            ? menuElems
            : [{ name: 'No notes', isDisabled: true }]
        }
      />
    </Box>
  );
}

export default NotesTab;
