import React from 'react';
import {
  makeStyles,
  Box,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  BoxProps,
  Typography,
  Avatar,
  Chip,
} from '@material-ui/core';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as EditIcon } from 'assets/EditThick.svg';
import { ReactComponent as DeleteIcon } from 'assets/deleteIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/Vector.svg';
import { getTasks_tasks } from './__generated__/getTasks';
import { formatDates } from 'utils';
import PriorityChip from './components/PriorityChip';
import {
  editorStateFromStringifiedRaw,
  limitTextFromState,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { Editor } from 'draft-js';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import AttachmentPreview from './components/AttachmentPreview';
import { convertString } from './List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '25px',
    overflow: 'hidden',
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '55vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  scrollBar: {
    maxWidth: '100%',
    width: '100%',
    overflowX: 'hidden',
    maxHeight: '79vh',
  },
  ScrollBox: {
    boxSizing: 'border-box',
    maxWidth: '100%',
    paddingInline: '2rem',
    paddingTop: '1rem',
    paddingBottom: '3rem',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: 'rgba(39, 49, 66, 1)',
    lineHeight: '42px',
    marginTop: '1rem',
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'rgba(66, 83, 100, 1)',
    lineHeight: '21px',
  },
  avatar: {
    maxWidth: '25px',
    maxHeight: '25px',
    marginRight: '8px',
  },
  username: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgba(130, 130, 130, 1)',
    whiteSpace: 'nowrap',
  },
  chip: {
    color: '#AF77E0',
    fontSize: '13px',
    height: '28px',
    fontWeight: 500,
    backgroundColor: 'rgba(249, 105, 153, 0.2)',
    width: 'fit-content',
    [theme.breakpoints.only('lg')]: {
      height: '28px',
      fontSize: '12px',
      width: 'fit-content',
    },
  },
  assigneeWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  priorityChip: {
    marginTop: '-5px',
    height: '28px',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  actionIcons: {
    height: '21px',
    width: '21px',
  },
  closeIcon: {
    height: '15px',
    width: '15px',
  },
}));

interface ItaskPreviewProps extends getTasks_tasks, Omit<BoxProps, 'title'> {
  closeModal: () => void;
  openEditModal: (task: getTasks_tasks) => void;
  handleDelete: () => void;
  setSelectedTask(id: string): void;
  handleDeleteTaskAttachment: (
    taskId: string,
    attachmentId: string,
    closeModal: () => void
  ) => void;
  taskList: Task[];
}
interface Task {
  tasks: TaskValue[];
  name: string;
}
interface TaskValue {
  _id: string;
}

const TaskPreview = ({
  closeModal,
  openEditModal,
  title,
  _id,
  description,
  status,
  dueDate,
  priority,
  attachments,
  createdAt,
  updatedAt,
  assignedTo,
  patients,
  handleDelete,
  setSelectedTask,
  handleDeleteTaskAttachment,
  taskList,
}: ItaskPreviewProps) => {
  const { t } = useTranslation();
  const task: getTasks_tasks = {
    __typename: 'Task',
    _id,
    title,
    description,
    status,
    dueDate,
    priority,
    attachments,
    assignedTo,
    createdAt,
    updatedAt,
    patients,
  };

  const statut = React.useMemo(
    () => taskList?.find((el) => el.tasks.some((t) => t._id === _id))?.name,
    [taskList]
  );
  const classes = useStyles();
  const { doctor } = useAuth();
  const handleOpenEditModal = () => {
    closeModal();
    openEditModal(task);
  };
  const handleOpenDeleteModal = () => {
    setSelectedTask(_id);
    closeModal();
    handleDelete();
  };
  const actions: any[] = [
    {
      label: t('Close'),
      value: 'close',
      icon: <CloseIcon className={classes.closeIcon} />,
      action: closeModal,
    },
    {
      label: t('Delete'),
      value: 'delete',
      icon: <DeleteIcon className={classes.actionIcons} />,
      action: handleOpenDeleteModal,
    },
    {
      label: t('Edit'),
      value: 'edit',
      icon: <EditIcon className={classes.actionIcons} />,
      action: handleOpenEditModal,
    },
  ];
  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        borderBottom="1px solid #D7E3F1"
        flexDirection="row-reverse"
        alignContent="center"
        maxWidth="100%"
        style={{ paddingRight: '1rem', paddingBlock: '0.7rem' }}
      >
        {actions.map((actionOption) => {
          return (
            <Tooltip key={actionOption.value} title={actionOption.label}>
              <IconButton onClick={actionOption.action && actionOption.action}>
                {actionOption.icon}
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>

      <ScrollBar className={classes.scrollBar}>
        <Box width="100%" className={classes.ScrollBox}>
          <Typography className={classes.title}>
            {title[0].toUpperCase() +
              title.slice(1, title.length).toLowerCase()}
          </Typography>

          <Box
            display="flex"
            alignContent="center"
            style={{ marginTop: '2.5rem' }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="assignees" className={classes.subTitle}>
                Assignees
              </Text>
            </Box>
            <Box className={classes.assigneeWrapper}>
              {assignedTo &&
                assignedTo.map((user) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar src={user?.picture} className={classes.avatar}>
                      {user.firstname[0].toUpperCase()}
                    </Avatar>
                    <Typography className={classes.username}>
                      {user?.firstname} {user?.lastname}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{ marginTop: '1.3rem' }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="dueDate" className={classes.subTitle}>
                Due Date
              </Text>
            </Box>
            <Chip className={classes.chip} label={formatDates(dueDate)} />
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{ marginTop: '1.3rem' }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="priority" className={classes.subTitle}>
                Priority
              </Text>
            </Box>
            <PriorityChip
              className={classes.priorityChip}
              priority={priority}
            />
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{ marginTop: '1.3rem' }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="status" className={classes.subTitle}>
                Status
              </Text>
            </Box>
            <PriorityChip
              className={classes.priorityChip}
              priority={convertString[status]}
            />
          </Box>
          <Box mt={6} mb={2}>
            {!tryParseJSON(description!) ? (
              <Typography>{description?.substr(0, 50)}</Typography>
            ) : (
              <Editor
                onChange={() => {}}
                editorState={limitTextFromState(
                  editorStateFromStringifiedRaw(description!, false),
                  100
                )}
                blockRendererFn={(content) => {
                  return {
                    ...content,
                    text: 'Baha',
                  };
                }}
                readOnly
              />
            )}
          </Box>
          <Box mt={6}>
            <Box mt={2} display="flex" flexDirection="column">
              {attachments && attachments?.length > 0 ? (
                attachments.map((attachment) => {
                  const { url, createdAt, fileType, fileName } =
                    attachment || {};
                  const { firstname, lastname, picture } = doctor || {};
                  return (
                    <AttachmentPreview
                      data-cy="attachment-preview"
                      my={1}
                      name={`${firstname} ${lastname}`}
                      url={url || ''}
                      pictureUrl={picture || ''}
                      type={fileType || 'image/nothing'}
                      date={new Date(createdAt)}
                      handleDeleteTaskAttachment={handleDeleteTaskAttachment}
                      fileName={fileName || ''}
                      taskId={_id}
                      closeModal={closeModal}
                    />
                  );
                })
              ) : (
                <Text i18nKey="noAttachement">No attachment</Text>
              )}
            </Box>
          </Box>
        </Box>
      </ScrollBar>
    </Box>
  );
};
export default TaskPreview;
