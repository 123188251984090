import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Switch from 'components/ui/Switch';
import Skeleton from 'components/ui/Skeleton';
import useStyles from './styles';
import Text from 'Text';

const getI18nKey = (label) => {
  const labelToKeyMap = {
    Push: 'push',
    Text: 'text',
  };
  return labelToKeyMap[label] || label; // Fallback to label itself if no key is found
};

interface IProps {
  loading: boolean;
  icon?: React.ReactElement;
  label?: string;
  id?: string;
  register?: any;
  defaultChecked?: boolean;
  index?: number;
  checked?: boolean;
  setChecked?: any;
}

const channelName = {
  0: 'PushChannel',
  1: 'EmailChannel',
  2: 'TextChannel',
};

function NotificationChannelCard({
  loading,
  icon,
  label,
  id,
  register,
  defaultChecked,
  index,
  checked,
  setChecked,
}: IProps) {
  const i18nKey = getI18nKey(label);
  const classes = useStyles();
  return (
    <Box className={classes.channel}>
      <Skeleton loading={loading}>
        <Text i18nKey={i18nKey} className={classes.label}>
          {label}
        </Text>

        <Switch
          name={channelName[index]}
          value={id}
          inputRef={register}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </Skeleton>
    </Box>
  );
}

export default NotificationChannelCard;
