import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import { useStyles } from '../styles';
import { IMoodSleepTags } from './tags';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';

interface IOptionsProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  options?: IMoodSleepTags;
  response: string[];
  isEditMode: boolean;
  isEditable: boolean;
}

export const Options = ({
  control,
  register,
  watch,
  setValue,
  options,
  response,
  isEditMode,
  isEditable,
}: IOptionsProps) => {
  const classes = useStyles();
  const watchedEmoji = watch('selectedEmoji');
  const prevEmojiRef = useRef(watchedEmoji);
  const { t } = useTranslation();

  useEffect(() => {
    if (watchedEmoji !== prevEmojiRef.current) {
      if (prevEmojiRef.current) {
        options[prevEmojiRef.current].forEach((el) => {
          setValue(el, false);
        });
      }

      prevEmojiRef.current = watchedEmoji;
    }
  }, [watchedEmoji]);

  return (
    <Box mb={3}>
      <FormGroup className={classes.checkboxContainer}>
        {watchedEmoji &&
          options[watchedEmoji].map((el) => (
            <Controller
              key={el + watchedEmoji}
              name={el}
              control={control}
              register={register}
              defaultValue={
                isEditMode && !isEditable ? response?.includes(el) : false
              }
              render={({ onChange, onBlur, value, name, ref }) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      onBlur={onBlur}
                      checked={value}
                      inputRef={ref}
                      name={name}
                      checkedIcon={<CheckedIcon />}
                      icon={<UncheckedIcon />}
                      disableRipple
                    />
                  }
                  label={t(el as TranslationKeys)}
                />
              )}
            />
          ))}
      </FormGroup>
    </Box>
  );
};
