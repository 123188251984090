import { gql, useQuery, useLazyQuery } from '@apollo/client';

const GET_THREAD_CONVERSATION_BY_ID = gql`
  query getThreadConversationById($threadConversationID: ID!) {
    getThreadConvertationById(threadConvertationID: $threadConversationID) {
      _id
      subject
      lastUpdateTime
      isArchived
      type
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
        createdAt
        updatedAt
      }
    }
  }
`;

const useLazyGetThreadConversationById = () => {
  const lazy = useLazyQuery(GET_THREAD_CONVERSATION_BY_ID);
  return lazy;
};

const useGetThreadConversationById = (threadConversationID: string) => {
  const { data, loading, error } = useQuery(GET_THREAD_CONVERSATION_BY_ID, {
    variables: {
      threadConversationID,
    },
  });
  return { data, loading, error };
};

export { GET_THREAD_CONVERSATION_BY_ID, useLazyGetThreadConversationById };
export default useGetThreadConversationById;
