
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: '1rem',
    display: 'flex',
    height: '6rem',
    alignItems: 'center',
    backgroundColor: '#FFF',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    justifyContent: 'space-around'
    // gap: '12.125rem'

  },
  toolbarContainer: { 
    backgroundColor: '#FFF', 
    width: '100%',
    height: '100%',
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  toolbarCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    marginRight: '12.125rem'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '0px',
  },
  btnIcon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
  },
  button: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    minWidth: '5.625rem',
    minHeight: '40px',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  
  },
  previewBtn: {
    color: '#2F80ED',
    backgroundColor: 'transparent',
  },
  shareBtn: {
    color: '#2F80ED',
    backgroundColor: 'transparent',
  },
  saveBtn: {
    color: '#FFF',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    '&:hover': {
      backgroundColor: '#1a5bbf',
      border: '1px solid #1a5bbf',
    },
  },
  disabledBtn: {
    color: '#FFF !important' ,
    backgroundColor: '#D0D5DD',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#D0D5DD',
      border: '1px solid ##D0D5DD',
    },
  },
  disabledShareBtn: {
    color: '#D0D5DD',
    backgroundColor: '#FFFFFF',
    '&:hover': {
    color: '#D0D5DD',
      backgroundColor: '#FFFFFF',
      border: '1px solid ##D0D5DD',

    },
  },
  closeBtn: {
    color: '#475467',
    backgroundColor: 'transparent',
    marginRight: 0,
  },
  plusButton: {
    color: '#FFF',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    borderRadius: '6px',
    padding: '8px 12px',

    '&:hover': {
      backgroundColor: '#1a5bbf',
      border: '1px solid #1a5bbf',
    },
  },
  textIcon: {
    cursor: 'pointer',
    height: '32px'
  },
  centerPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    borderRight: '1px solid #D0D5DD',
    padding: '4px 12px',
  },
  endPart: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '4px 12px',
  },
  inputSize:{
    width: '2rem',
    height: '2rem',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    color: '#4F4F4F',
    lineHeight: '20px',
    fontSize: '16px',
    textAlign: 'center'
  },
  arrows:{
    gap: '10px'
  },
  textType:{
    display: 'flex',
    justifyContent: 'space-between',
    width: '10rem',
    height: '2rem',
    padding: '0px 10px 0px 10px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    fontSize: '14px',
    fontFamily: 'Playfair Display',
    fontWeight: 400,
    color: '#475467',
    lineHeight:'21.33px',
  },
  tooltip:{
    borderRadius: '8px',
    backgroundColor: '#101828',
    fontFamily: 'Inter, sans-serif',
    color: '#FFFFFF',
    fontWeight:600,
    lineHeight: '18px',
    fontSize: '12px',
    textAlign: 'left'
  },
  inputLinkWrapper:{
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    width: '34rem',
    height: '3rem',
    padding: '10px',
    gap: '10px',
    borderRadius: '8px',
  },
  inputLink:{
    width: '35rem',
    height: '1rem',
    padding: '10px 14px',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
  },
  genericListBox:{
    position: 'absolute',
          top: '100%',
          width: '200px',
          backgroundColor: '#fff',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          zIndex: 10,
          opacity: 1,
  }

}));

export default useStyles;
