import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_FILES_BY_THREAD_CONVERSATION = gql`
  query getFilesByThreadConversation($threadConversationID: ID!) {
    getFilesByThreadConversation(threadConversationID: $threadConversationID) {
      _id
      user {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      parentMessageId
      content {
        text
        files {
          _id
          url
          fileName
          mimeType
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const useGetFilesByThreadConversation = (threadConversationId: string) => {
  const { data, loading, error } = useQuery(GET_FILES_BY_THREAD_CONVERSATION, {
    variables: { threadConversationId },
  });

  return {
    data,
    loading,
    error,
  };
};

export default useGetFilesByThreadConversation;
export { GET_FILES_BY_THREAD_CONVERSATION };
