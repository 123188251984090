import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';

/**
 * Old cell
 */
export const BorderedRow = withStyles(() =>
  createStyles({
    root: {},
  })
)(TableRow);

const NormalRow = withStyles(() =>
  createStyles({
    root: {
      border: '0.5px solid #EAECF0',
      borderRadius: '10px',
    },
  })
)(TableRow);
const RowWithOutBorder = withStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      left: '77%',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
)(TableRow);

const GrayRow = withStyles(() =>
  createStyles({
    root: {
      borderRadius: '10px',
      color: '#425364',
      backgroundColor: '#EFF6FF',
    },
  })
)(TableRow);

interface IRowProps extends TableRowProps {
  type:
    | 'rounded'
    | 'normal'
    | 'gray'
    | 'cellWidthOutBorder'
    | 'rowWithOutBorder';
}

export default function Row({ type, ...props }: IRowProps): JSX.Element {
  switch (type) {
    case 'rounded':
      return <NormalRow {...props} />;
    case 'gray':
      return <GrayRow {...props} />;
    case 'rowWithOutBorder':
      return <RowWithOutBorder {...props} />;
    default:
      return <BorderedRow {...props} />;
  }
}
