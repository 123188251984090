import React from 'react';
import useStyles from '../styles';
import ErrorIcon from '@material-ui/icons/Error';

const Errors = ({ errors, name, array }) => {
  const classes = useStyles();
  return (
    <>
      {' '}
      {errors &&
        errors[array] &&
        errors[array].length > 0 &&
        errors[array][0][name] && (
          <span className={classes.error}>
            <ErrorIcon className={classes.errorIcon} />
            <span className={classes.errorText}>
              {errors[array][0][name].message}
            </span>
          </span>
        )}
    </>
  );
};

export default Errors;
