import { useState, useEffect, useMemo } from 'react';
import useSubscriptionNewNotification from '@eggmed/graphql-client/operations/notificationOperations/useSubscriptionNewNotification';
import useGetNotifications from '@eggmed/graphql-client/operations/notificationOperations/useGetNotifications';
import useUpdateNotification from '@eggmed/graphql-client/operations/notificationOperations/useUpdateNotifications';
import { MenuElements } from '../../Notification/MenuElement';

function addMenuElementToNotification(
  handleUpdateNotification: (id: string) => void
): (props: any) => MenuElements {
  return ({
    title,
    content,
    type,
    _id,
    read,
    date,
    appointmentId,
    action,
    avatar,
    patientId,
    event,
  }) => {
    return {
      notification: true,
      title,
      content,
      type,
      id: _id,
      date,
      appointmentId,
      read,
      action,
      avatar,
      patientId,
      event,
      onClick: () => {
        handleUpdateNotification(_id);
      },
    };
  };
}

export function useGetMenuNotifications(userId, { start, limit }) {
  const { data } = useGetNotifications({
    variables: {
      start,
      limit,
    },
  });

  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  const { handleUpdateNotification } = useUpdateNotification();
  const newNotifications = data?.notifications?.newNotifications.map(
    addMenuElementToNotification(handleUpdateNotification)
  );
  const pastNotifications = data?.notifications?.pastNotifications.map(
    addMenuElementToNotification(handleUpdateNotification)
  );
  const newCount = data?.notifications?.newCount;
  const pastCount = data?.notifications?.pastCount;
  useEffect(() => {
    const notifications = data?.notifications?.allNotifications.map(
      addMenuElementToNotification(handleUpdateNotification)
    );
    const unreadNotification = notifications?.filter(
      (item: MenuElements) => !item.read
    );
    setUnreadNotifications(unreadNotification);
    setNotifications(notifications);
    setCount(unreadNotification?.length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.notifications]);

  const handleNewNotification = (data) => {
    setCount((count) => count + 1);
    setNotifications((notifications) => [
      addMenuElementToNotification(handleUpdateNotification)(data),
      ...notifications,
    ]);
  };
  useSubscriptionNewNotification(userId, handleNewNotification);

  return {
    menuNotifElements: notifications,
    unreadNotifications,
    count,
    newNotifications,
    pastNotifications,
    newCount,
    pastCount,
  };
}
