import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { formatToFullDate, formatFullDate } from 'utils';
import concatName from 'utils/userUtils';
import {
  GetThreadMessages_getThreadMessages,
  GetThreadMessages_getThreadMessages_content_files,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import useStyles from './style';
import { _file } from './utils';
import { getIconByMimeTypeAndUrl } from './FileList';
interface IMessageBubble {
  isOwner: boolean;
  data: GetThreadMessages_getThreadMessages;
  isAvatarHidden?: boolean;
}
const MessageBubble: React.FC<IMessageBubble> = ({
  isOwner,
  data,
  isAvatarHidden,
}) => {
  const { updatedAt, content, user } = data;
  const { text, files } = content;
  const { firstname, lastname, middlename, picture } = user;
  const classes = useStyles({ patient: false });
  const direction = isOwner ? 'row' : 'row-reverse';
  const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.click();
  };

  const fileCard = (
    file: GetThreadMessages_getThreadMessages_content_files
  ) => {
    const { url, fileName, mimeType } = file;
    return (
      <Box className={classes.fileCardContainer}>
        <Box className={classes.fileTypeIcon}>
          {getIconByMimeTypeAndUrl(mimeType)}
        </Box>
        <Box onClick={() => onDownload(url, fileName)}>{fileName}</Box>
      </Box>
    );
  };

  const renderFiles = (
    files: GetThreadMessages_getThreadMessages_content_files[]
  ) => {
    if (files.length > 0) {
      const fileContainer = files.map((file) => {
        const { fileName, url, mimeType } = file;
        return (
          <Grid item key={fileName}>
            {mimeType.split('/')[0] === 'image' ? (
              <img
                className={classes.imageInBubble}
                src={url}
                alt={fileName}
                loading="lazy"
              />
            ) : (
              fileCard(file)
            )}
          </Grid>
        );
      });

      return fileContainer;
    }
  };
  return (
    <Grid container direction={direction} className={classes.bubbleContainer}>
      <Tooltip title={formatToFullDate(updatedAt)}>
        <Box className={classes.bubbleMessage} display="flex">
          <Box
            display="flex"
            justifyContent="flex-direction"
            alignItems="flex-end"
            mr={1}
          >
            {!isOwner && !isAvatarHidden && (
              <Avatar alt={firstname.toUpperCase()} src={picture} />
            )}
          </Box>
          <Box
            className={clsx(
              classes.bubble,
              isOwner ? classes.bubbleRight : classes.bubbleLeft
            )}
            ml={isAvatarHidden && 1 + 4}
          >
            {!isOwner && !isAvatarHidden && (
              <Typography className={classes.fullName}>
                {concatName(firstname, lastname, middlename)}
              </Typography>
            )}
            <Box>{renderFiles(files)}</Box>
            <Box>
              <Box>
                <Typography className={classes.bubbleText}>{text}</Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  className={isOwner ? classes.dateOwner : classes.date}
                >
                  {formatFullDate(updatedAt)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};

export default MessageBubble;
