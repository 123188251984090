import React, { ReactElement, ReactNode } from 'react';
import SkeletonMUI from '@material-ui/lab/Skeleton';

interface IProps {
  loading?: boolean;
  children: ReactNode;
  type?: 'circle' | 'rect' | 'text' | undefined;
  width?: number | string;
  height?: number | string;
  className?: string;
}

const Skeleton = ({
  loading,
  children,
  type,
  width,
  height,
  className,
}: IProps): ReactElement => {
  if (loading)
    return (
      <SkeletonMUI
        style={{ margin: '2px' }}
        variant={type}
        width={width}
        height={height}
        className={className}
      >
        {children}
      </SkeletonMUI>
    );
  return <>{children}</>;
};

export default Skeleton;
Skeleton.defaultProps = {
  type: undefined,
  width: undefined,
  height: undefined,
  loading: false,
};
