import { Box, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ReactComponent as LoginIcon } from 'assets/terms/loginIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useHistory } from 'react-router-dom';
import Menu from 'components/ui/Menu';
import { makeStyles } from '@material-ui/core/styles';
import TermsContent from './TermsContent';
import useStyles from './styles';
import TermsHeader from './TermsHeader';
import TermsSections from './TermsSections';
import { createElement, FC, useEffect } from 'react';

const Terms = ({
  isLoggedIn = false,
}: {
  isLoggedIn?: boolean;
}): ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuElementsData = [
    {
      avatar: <LoginIcon />,
      name: 'Login',
      onClick: () => history.push('/login'),
    },
    {
      avatar: <EditIcon />,
      name: 'Signup',
      onClick: () => history.push('/signup'),
    },
  ];
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const section = [
    {
      id: 1,
      title: '1.The data policy',
    },
    {
      id: 2,
      title: '2.Confidentiality',
    },
    {
      id: 3,
      title: '3.Using fees',
    },
    {
      id: 4,
      title: '4.Our responsibilities',
    },
    {
      id: 5,
      title: '5.Your commitments',
    },
    {
      id: 6,
      title: '6.Application law and jurisdiction',
    },
  ];
  const goToSeletedSection = (id) => {
    const scrollToSection = document.getElementById(id);
    scrollToSection.scrollIntoView({
      behavior: 'smooth',
    });
  };
  const dataId = '45f05ac7-a14a-4a4f-a633-9b2d9d517b98';
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  if (!dataId) {
    return null;
  }
  return createElement('div', {
    name: 'termly-embed',
    'data-id': dataId,
    'data-type': 'iframe',
  });
};

export default Terms;
