import React, { ReactElement } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ScrollBar from 'components/ui/ScrollBar';
import NotificationCard from '../components/notificationCard';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Menu from 'components/ui/Menu';
import useDeleteNotification from '@eggmed/graphql-client/operations/notificationOperations/useDeleteNotification';
import { ReactComponent as RemoveNotification } from 'assets/notifications/removeNotification.svg';
import { ReactComponent as TurnOffTopic } from 'assets/notifications/turnOffNotification.svg';
import { ReactComponent as NoNotificationIcon } from 'assets/illustrations/noNotifications.svg';
import { ReactComponent as NotifBellIcon } from 'assets/NotifBell.svg';
import { MenuElements } from 'components/Notification/MenuElement';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import EggmedPagination from 'components/ui/Pagination';
import { useEffect } from 'react';
import useHandleTopic from './useHandleTopic';
import { ReactComponent as TopicOnIcon } from 'assets/topicOn.svg';
import { Link } from 'react-router-dom';
import settingsIcon from 'assets/settingsIcon.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '20px',
    letterSpacing: '0.44px',
    color: '#425364',
  },
  hidden: {
    display: 'none',
  },
  notificationTab: {
    minHeight: '50vh',
    height: '85vh',
    paddingBlock: '30px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh',
      paddingBottom: '0',
    },
  },
  scrollbar: {
    flex: 1,
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      paddingBottom: '1rem',
    },
  },
  emptynotif: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '10px',
    marginBottom: '2rem',
    color: '#464665',
  },
  notificationTabTitle: {
    paddingLeft: '5rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5rem',
    },
  },
  divider: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
    marginBottom: '1rem',
  },
  emptynotifIllustation: {
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface INotificationsTabProps {
  elements: MenuElements[];
  title?: string;
  handlePage: (page: number) => void;
  handleStart: (start: number) => void;
  handleLimit: (limit: number) => void;
  page: number;
  count: number;
  counting?: number;
}

const NotificationsTab = ({
  elements,
  handlePage,
  handleStart,
  handleLimit,
  count,
  page,
  title = null,
  counting,
}: INotificationsTabProps): ReactElement => {
  const { user, patient } = useAuth();
  const IsPatient = !!patient;
  const classes = useStyles({ patient: IsPatient });
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [deleteId, setDeleteId] = React.useState<string>();
  const [cardId, setCardId] = React.useState<number>();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  useEffect(() => {
    handlePage(1);
    handleStart(0);
    handleLimit(count);
  }, []);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleClose = () => {
    setAnchor(null);
  };
  function handlePagination(e: unknown, page: number) {
    handlePage(page);
    handleStart(count * (page - 1));
    handleLimit(page * count);
  }

  const isMenuOpen = Boolean(anchor);
  const { handleDeleteNotification } = useDeleteNotification();
  const handleUpdateMenuItems = () => {
    elements?.splice(cardId, 1);
  };
  const { turnOffNotification, turnOnNotification, topicOn } = useHandleTopic({
    elements,
    deleteId,
    setAnchor,
    setDeleteId,
  });
  const MenuElementsData = [
    {
      avatar: <RemoveNotification />,
      name: 'Delete notification',
      onClick: () => {
        handleClose();
        handleOpenDeleteModal();
      },
    },
    {
      avatar: topicOn ? <TurnOffTopic /> : <TopicOnIcon />,
      name: `Turn ${topicOn ? 'off' : 'on'} this notification type`,
      onClick: () => {
        if (topicOn) {
          turnOffNotification();
        } else {
          turnOnNotification();
        }
      },
    },
  ];

  const DoctorMenuElementsData = [
    {
      avatar: <RemoveNotification />,
      name: 'Delete notification',
      onClick: () => {
        handleClose();
        handleOpenDeleteModal();
      },
    },
    {
      avatar: topicOn ? <TurnOffTopic /> : <TopicOnIcon />,
      name: `Turn ${topicOn ? 'off' : 'on'} this notification type`,
      onClick: () => {
        if (topicOn) {
          turnOffNotification();
        } else {
          turnOnNotification();
        }
      },
    },
  ];
  const link = !IsPatient
    ? '/doctor/admin?query=Notification'
    : '/patient/settings?query=Notification';
  return (
    <Box className={classes.notificationTab}>
      <Box
        style={{ display: 'flex', alignItems: 'center', paddingRight: '25px' }}
      >
        <NotifBellIcon style={{ marginRight: '8px' }} />
        <Typography className={classes.title}>{title}</Typography>
        <Link to={link} style={{ marginLeft: 'auto', marginTop: '5px' }}>
          <img src={settingsIcon} style={{ width: '19px' }} />
        </Link>
      </Box>
      <ScrollBar className={classes.scrollbar}>
        {elements?.length > 0 ? (
          elements?.map((element, index) => (
            <NotificationCard
              element={element}
              setDeleteId={setDeleteId}
              setAnchor={setAnchor}
              id={index}
              setCardId={setCardId}
              anchor={anchor}
            />
          ))
        ) : (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{ paddingInline: '1rem' }}
          >
            <NoNotificationIcon className={classes.emptynotifIllustation} />
          </Grid>
        )}
      </ScrollBar>
      <Box display="flex" justifyContent="center" width="100%">
        <EggmedPagination
          onChange={handlePagination}
          page={page}
          count={Math.ceil(counting / count)}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        handleMenuClose={handleClose}
        MenuElements={
          user?.doctorId ? DoctorMenuElementsData : MenuElementsData
        }
      />
      <ModalDelete
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        text="notification"
        onDelete={() => {
          handleDeleteNotification(deleteId);
          handleUpdateMenuItems();
          handleCloseDeleteModal();
        }}
      />
    </Box>
  );
};

export default NotificationsTab;
