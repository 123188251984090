import { Box, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as UpVar } from 'assets/dashboard/finances/up.svg';
import { ReactComponent as DownVar } from 'assets/dashboard/finances/down.svg';
import { ReactComponent as StableVar } from 'assets/dashboard/finances/stable.svg';
import Skeleton from 'components/ui/Skeleton';
import React, { useState } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import {
  calculateAmount,
  calculatePercentage,
  calculateAmountPaid,
} from 'pages/DoctorFinancePage/utils';
import { ReactComponent as Received } from 'assets/Received.svg';
import { ReactComponent as Pending } from 'assets/Pending.svg';
import { ReactComponent as Overdue } from 'assets/Overdue.svg';
import { ReactComponent as Waived } from 'assets/Waived.svg';
import { ReactComponent as CardIcon } from 'assets/dashboard/finances/credit-card-shield.svg';
import { Payment, Period } from 'pages/DoctorFinancePage/DoctorFinance';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IFinanceHeaderProps {
  dataFinance: any;
  loading: boolean;
  isOnFinancePage?: boolean;
  time: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
}

export const FinancesHeader = ({
  dataFinance,
  loading,
  isOnFinancePage = false,
  time,
  setTime,
}: IFinanceHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const paymentStatus = [
    {
      title: t('Received'),
      amount: calculateAmountPaid(dataFinance, time) || 0,
      icon: <Received />,
      color: '#6FCF97',
      percentage: calculatePercentage(dataFinance, Payment.paid, time) || 0,
    },
    {
      title: t('Pending'),
      amount: calculateAmount(dataFinance, Payment.pending, time) || 0,
      icon: <Pending />,
      color: '#F2C94C',
      percentage: calculatePercentage(dataFinance, Payment.pending, time) || 0,
    },
    {
      title: t('Refunded'),
      amount: calculateAmount(dataFinance, Payment.refunded, time) || 0,
      icon: <Overdue />,
      color: '#EB5757',
      percentage: calculatePercentage(dataFinance, Payment.refunded, time) || 0,
    },
    {
      title: t('Waived'),
      amount: calculateAmount(dataFinance, Payment.waived, time) || 0,
      icon: <Waived />,
      color: '#567FBD',
      percentage: calculatePercentage(dataFinance, Payment.waived, time) || 0,
    },
  ];

  const financesFilter = [
    {
      title: t('12 months'),
      time: Period.year,
    },
    { title: t('30 days'), time: Period.month },
    { title: t('7 days'), time: Period.week },
    { title: t('24 hours'), time: Period.day },
  ];

  return (
    <Box mt={2} px={3} py={3} className={classes.boxWhite}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Skeleton type="text" loading={loading} width="120px" height="25px">
          <Box>
            <Box display="flex" alignItems="center" gridGap="0.5rem">
              {isOnFinancePage && <CardIcon />}
              <Text
                i18nKey="myFinances"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '18px',
                  fontWeight: 600,
                  color: '#101828',
                }}
              >
                My finances
              </Text>
            </Box>
            {!isOnFinancePage && (
              <Text
                i18nKey="overviewOfPayments"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#667085',
                }}
              >
                Overview of payments
              </Text>
            )}
          </Box>
        </Skeleton>
        <Box
          border="1px solid #D0D5DD"
          display="flex"
          alignItems="center"
          borderRadius="8px"
          overflow="hidden"
        >
          {financesFilter.map((finance, key) => (
            <Box
              borderRight={key !== 3 && '1px solid#D0D5DD'}
              px={2}
              py={1}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  finance?.time.toUpperCase() === time && !loading && '#147AF3',
                width: '75px',
                textAlign: 'center',
              }}
              className={classes.hover}
              onClick={() => setTime(finance.time.toUpperCase())}
            >
              <Skeleton
                type="text"
                loading={loading}
                width="80px"
                height="21px"
              >
                <Typography
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 600,
                    fontSize: '14px',
                    color:
                      finance?.time.toUpperCase() === time
                        ? '#FFFFFF'
                        : '#344054',
                  }}
                >
                  {finance.title}
                </Typography>
              </Skeleton>
            </Box>
          ))}
        </Box>
      </Box>
      <Grid container style={{ marginTop: '30px' }}>
        {paymentStatus?.map((payment, key) => (
          <Grid item xl={3} lg={3}>
            <Box
              sx={{
                paddingLeft: {
                  lg: key !== 0 && 1,
                  xl: key !== 0 && 2,
                },
                paddingRight: {
                  lg: key !== paymentStatus?.length - 1 && 1,
                  xl: key !== paymentStatus?.length - 1 && 2,
                },
                borderRight:
                  key !== paymentStatus?.length - 1 && '1px solid #EAECF0',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={2}
              >
                <Skeleton
                  type="text"
                  loading={loading}
                  width="100px"
                  height="21px"
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      fontWeight: 700,
                      color: '#475467',
                    }}
                  >
                    {payment?.title}
                  </Typography>
                </Skeleton>
                <Skeleton
                  type="text"
                  loading={loading}
                  width="30px"
                  height="21px"
                >
                  <Text
                    i18nKey="report"
                    style={{
                      paddingLeft: '10px',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '12px',
                      fontWeight: 600,
                      color: '#147AF3',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      history.push('/doctor/finance', { Time: time })
                    }
                  >
                    Report
                  </Text>
                </Skeleton>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Skeleton
                  type="text"
                  loading={loading}
                  width="70px"
                  height="40px"
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '30px',
                      fontWeight: 600,
                      color: '#101828',
                    }}
                  >
                    ${payment?.amount}
                  </Typography>
                </Skeleton>
                <Skeleton
                  type="text"
                  loading={loading}
                  width="50px"
                  height="37px"
                  className={classes.loadingVar}
                >
                  <Typography
                    className={clsx(
                      classes.commonVar,
                      payment?.percentage === 0 && classes.stableVar,
                      payment?.percentage > 0 && classes.upVar,
                      payment?.percentage < 0 && classes.downVar
                    )}
                  >
                    {payment?.percentage}%
                    {payment?.percentage === 0 && <StableVar />}
                    {payment?.percentage > 0 && <UpVar />}
                    {payment?.percentage < 0 && <DownVar />}
                  </Typography>
                </Skeleton>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
