import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import FormsPage from './FormsPage';

function FormPage({
  isNotes = false,
  forms,
  handleClose,
  settings,
  editMode=true,
}: {
  isNotes?: boolean;
  forms?: any;
  handleClose?: () => void;
  settings?: boolean;
  editMode?: boolean;
}) {
  return (
    <Box>
      <FormsPage
        isNotes={isNotes}
        forms={forms}
        handleClose={handleClose}
        settings={settings}
        editMode={editMode}
      />
    </Box>
  );
}

export default FormPage;
