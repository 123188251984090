import { useQuery } from '@apollo/client';
import React, { useReducer, useContext, useState } from 'react';
import { log } from 'config/log';
import { toast } from 'react-toastify';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import useDeleteConsultation from '@eggmed/graphql-client/operations/consultationOperations/useDeleteConsultation';
import useAddPrescription from '@eggmed/graphql-client/operations/prescriptionOperations/useAddPrescription';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useAddReferral from '@eggmed/graphql-client/operations/referralOperations/useAddReferral';
import useDeleteReferral from '@eggmed/graphql-client/operations/referralOperations/useDeleteReferral';
import useEditReferral from '@eggmed/graphql-client/operations/referralOperations/useEditReferral';
import useAddLabRequest from '@eggmed/graphql-client/operations/labRequestOperations/useAddLabRequest';
import useEditLabRequest from '@eggmed/graphql-client/operations/labRequestOperations/useEditLabRequest';
import useAddConsultation from '@eggmed/graphql-client/operations/consultationOperations/useAddConsultation';
import useAddImaging from '@eggmed/graphql-client/operations/imagingOperations/useAddImaging';
import useEditImaging from '@eggmed/graphql-client/operations/imagingOperations/useEditImaging';
import useDeleteImaging from '@eggmed/graphql-client/operations/imagingOperations/useDeleteImaging';
import useEditConsultation from '@eggmed/graphql-client/operations/consultationOperations/useEditConsultation';
import useDeleteLabRequest from '@eggmed/graphql-client/operations/labRequestOperations/useDeleteLabRequest';
import useAddPrescriptionDrug from '@eggmed/graphql-client/operations/prescriptionOperations/useAddPrescriptionDrug';
import { useDeletePrescriptionDrug } from '@eggmed/graphql-client/operations/prescriptionOperations/useDeletePrescriptionDrug';
import useDeletePrescription from '@eggmed/graphql-client/operations/prescriptionOperations/useDeletePrescription';
import useEditPrescriptionDrug from '@eggmed/graphql-client/operations/prescriptionOperations/useEditPrescriptionDrug';
import useLocalStateDispatcher from './useLocalStateDispatcher';
import { GET_PATIENT } from '../AppointmentPage';
import { reducer, Context } from './ContextReducer';
import {
  addConsultationInput,
  addImagingInput,
  editConsultationInput,
  editImagingInput,
  NewLabRequestInput,
} from '@eggmed/graphql-client/__generated__/globalTypes';

export default function Provider({ children, initialValue }: any) {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { push } = useHistory();
  const { createPrescription } = useAddPrescription();
  const { createConsultation } = useAddConsultation();
  const { createImaging } = useAddImaging();
  const { createReferral } = useAddReferral();
  const { editReferral } = useEditReferral();
  const { deleteReferral } = useDeleteReferral();
  const { createLabRequest } = useAddLabRequest();
  const { editLabRequest } = useEditLabRequest();
  const { updateConsultation } = useEditConsultation();
  const { updateImaging } = useEditImaging();
  const { deleteLabRequest } = useDeleteLabRequest();
  const { createPrescriptionDrug } = useAddPrescriptionDrug();
  const { editPrescriptionDrugs } = useEditPrescriptionDrug();
  const { deletePrescriptionDrugs } = useDeletePrescriptionDrug();
  const { deletePrescription } = useDeletePrescription();
  const { deleteConsultation } = useDeleteConsultation();
  const { deleteImaging } = useDeleteImaging();
  const { user, doctor } = useAuth();
  const { doctorId } = user;
  const appointmentId = search.split('=')[1].replace('&tab', '');
  const queryResult = useQuery(GET_PATIENT, {
    variables: { patientId: id, appointmentId },
  });
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    currentTab: 0,
    modal: false,
    modalType: 'prescription',
    referralEditMode: false,
    consultationEditMode: false,
  });
  const dispatchers = useLocalStateDispatcher(dispatch);

  const { handleCloseDeleteModal, handleCloseAddPrescription } = dispatchers;
  function handleToastError(e: any) {
    log('error', e);
    toast.error(e.message, {
      position: 'bottom-center',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function handleGoBack() {
    push('/patient');
  }
  async function handleDeleteReferral() {
    try {
      await deleteReferral({
        variables: { referralId: state.currentReferral._id },
      });
      handleCloseDeleteModal();
    } catch (e) {
      log('error', e);
    }
  }

  async function handleDeleteLabRequest(labRequestId: string) {
    deleteLabRequest({ variables: { labRequestId } })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  async function handleCreateLabRequest(labRequest: NewLabRequestInput) {
    createLabRequest({ variables: { labInput: labRequest } })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  async function handleEditLabRequest(labRequestId: any, labInput: any) {
    editLabRequest({ variables: { labInput, labRequestId } })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  async function handleCreateImaging(imagingInput: addImagingInput) {
    createImaging({
      variables: {
        imagingInput: {
          ...imagingInput,
          appointment: appointmentId,
          patient: id,
          doctor: doctorId,
        },
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  function handleEditImaging(
    imagingId: string,
    imagingInput: editImagingInput
  ) {
    updateImaging({
      variables: {
        imagingId,
        imagingInput,
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  function handleDeleteImaging() {
    deleteImaging({
      variables: { imagingId: state.currentImaging._id },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }

  async function handleCreateConsultation(
    consultationInput: addConsultationInput
  ) {
    createConsultation({
      variables: {
        consultationInput: {
          ...consultationInput,
          appointment: appointmentId,
          patient: id,
          doctor: doctorId,
        },
      },
    })
      .then(() => {
        setTimeout(() => handleCloseAddPrescription(), 200);
      })
      .catch(handleToastError);
  }
  function handleEditConsultation(
    consultationId: string,
    consultationInput: editConsultationInput
  ) {
    updateConsultation({
      variables: {
        consultationId,
        consultationInput,
      },
    })
      .then(() => {
        setTimeout(() => handleCloseAddPrescription(), 200);
      })
      .catch(handleToastError);
  }
  function handleDeleteConsultation(consultationId: string) {
    deleteConsultation({
      variables: { consultationId },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  function handleAddPrescriptionDrug(
    prescriptionId: string,
    prescriptionDrugInput: any
  ) {
    createPrescriptionDrug({
      variables: {
        prescriptionDrugInput,
        prescriptionId,
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  function handleDeletePrescriptionDrug() {
    deletePrescriptionDrugs({
      variables: {
        prescriptionDrugId: state.currentPrescriptionDrug._id,
        prescriptionId: queryResult?.data?.appointmentPrescription._id,
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }
  function handleDeletePrescription(prescriptionId) {
    deletePrescription({
      variables: {
        prescriptionId,
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }

  function handleEditPrescriptionDrug(prescriptionDrugInput: any) {
    editPrescriptionDrugs({
      variables: {
        prescriptionDrugId: state.currentPrescriptionDrug._id,
        prescriptionId: queryResult?.data?.appointmentPrescription._id,
        prescriptionDrugInput,
      },
    })
      .then(() => {
        handleCloseAddPrescription();
      })
      .catch(handleToastError);
  }

  return (
    <Context.Provider
      value={{
        ...state,
        ...queryResult,
        ...dispatchers,
        handleDeleteLabRequest,
        handleEditLabRequest,
        handleDeletePrescriptionDrug,
        handleEditPrescriptionDrug,
        handleEditConsultation,
        patientId: id,
        appointmentId,
        doctorId,
        handleDeleteReferral,
        handleGoBack,
        createPrescription,
        createReferral,
        editReferral,
        handleCreateLabRequest,
        handleCreateConsultation,
        handleAddPrescriptionDrug,
        handleDeleteConsultation,
        handleDeletePrescription,
        doctor,
        handleCreateImaging,
        handleEditImaging,
        handleDeleteImaging,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useAppointmentPageProvider must be used within a AppointmentPageProvider'
    );
  }

  return context;
}
