import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Typography,
  BoxProps,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/deleteModalIcon.svg';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { ReactComponent as TrashDelete } from 'assets/TrashDelete.svg';
// import ProgressLoader from 'components/ui/ProgressLoader';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#475467',
    fontWeight: 400,
    fontSize: '14px',
  },
  confirmButton: {
    color: 'white',
    backgroundColor: '#FE443F',
    marginLeft: '10px',
    paddingBlock: '3px',
    paddingInline: '20px',
    // width: '94px',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '5px',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    border: '1px solid #FE443F',
    '&:hover': {
      backgroundColor: '#DC443D',
    },
  },
  cancelButton: {
    color: '#425364',
    backgroundColor: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? 'rgba(242, 242, 242, 1)' : 'transparent',
    border: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '1px solid rgba(242, 242, 242, 1)' : '1px solid #828282',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '5px',
    width: '94px',
    paddingBlock: '3px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(222, 222, 222, 1)',
      border: ({ isPatient }: { isPatient: boolean }) =>
        isPatient ? '1px solid rgba(242, 242, 242, 1)' : '1px solid #828282',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
  },
  modalBox: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
  },
}));
interface IDeleteModalContentProps extends BoxProps {
  handleDelete: () => void;
  handleClose: () => void;
  text?: string;
  isPatient?: boolean;
  message?: string;
  label?: string;
  description?: string;
  deleteBtn?: string;
  loading?: boolean;
}
export default function DeleteAccountModal({
  handleDelete,
  handleClose,
  text,
  isPatient = false,
  label,
  description,
  deleteBtn,
  loading,
  ...props
}: IDeleteModalContentProps): ReactElement {
  const classes = useStyles({ isPatient });
  return (
    <Box
      className={classes.modalBox}
      borderRadius="16px"
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Box display="flex" alignItems="center">
        <TrashDelete />
        <Box ml={2}>
          <Typography className={classes.title}>{label}</Typography>
          <Typography className={classes.text}>{description}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={4}>
        <Button className={classes.cancelButton} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={classes.confirmButton}
          data-cy="delete-confirm"
          onClick={handleDelete}
        >
          {deleteBtn}
          {/* {loading ? <ProgressLoader width={20} height={20} /> : deleteBtn} */}
        </Button>
      </Box>
    </Box>
  );
}
