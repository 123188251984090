/* eslint-disable*/
import { useMutation } from '@apollo/client';
import { DELETE_EVENT } from '../../queries/appointment';

export default function useDeleteEvent() {
  const [removeEvent, result] = useMutation(DELETE_EVENT, {
    update: (cache, { data: { deleteEvent } }) => {
      cache.modify({
        fields: {
          eventsByDate(events) {
            const newEvents = events?.result?.filter((event) => {
              const eventId = event.__ref.split('Event:')[1];
              return eventId !== deleteEvent?._id;
            });
            return newEvents;
          },
          conflictsAppointments(event) {
            const newEvent = event
              ?.map((event) => {
                const shouldRemoveInnerArray = event?.some((el) => {
                  const eventId = el.__ref?.split('Appointment:')[1];
                  return eventId === deleteEvent?._id;
                });

                return shouldRemoveInnerArray ? [] : event;
              })
              .filter((element) => element.length > 0);

            return newEvent;
          },
          availableTimes(times) {
            const date = new Date(deleteEvent?.startDate);
            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: deleteEvent?.doctor?.timeZone,
            });

            const doctorFormattedDate = doctorFormatter.format(date);

            return [...times, doctorFormattedDate];
          },
        },
      });
    },
  });
  async function deleteEvent(
    id: string,
    isOne: boolean,
    sideEffectCall: (error: Error) => void
  ) {
    try {
      const result = await removeEvent({
        variables: { eventId: id, isOne },
      });
      return result;
    } catch (e) {
      return sideEffectCall(e as Error);
    }
  }

  return { deleteEvent, ...result };
}
