import React, { ReactElement } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Control, Controller } from 'react-hook-form';
import CheckedIcon from 'assets/icons/checkedIcon';
import UnCheckedIcon from 'assets/icons/uncheckedIcon';

interface ICheckBoxInputProps {
  onChange?: (e: any) => any;
  name: string;
  value: boolean;
}
interface IControllerCheckBoxInputProps extends ICheckBoxInputProps {
  control: Control;
}

export default function CheckboxInput({
  control,
  name,
  value,
  ...props
}: IControllerCheckBoxInputProps): ReactElement {
  return (
    <Controller
      defaultValue={value}
      control={control}
      name={name}
      {...props}
      render={({ onChange, onBlur, value: checkedValue }) => {
        function handleChange(e: any) {
          onChange(e.target.checked);
        }
        return (
          <Checkbox
            checkedIcon={<CheckedIcon />}
            onBlur={onBlur}
            icon={<UnCheckedIcon />}
            checked={checkedValue}
            {...props}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        );
      }}
    />
  );
}

export function UncontrollerCheckBox({
  onChange,
  name,
  checked,
  ...props
}: CheckboxProps): ReactElement {
  return (
    <Checkbox
      checkedIcon={<CheckedIcon />}
      icon={<UnCheckedIcon />}
      checked={checked}
      {...props}
      name={name}
      onChange={onChange}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
}
CheckboxInput.defaultProps = {
  onChange: (e: any) => {},
};
