import React from 'react';
import { Controller } from 'react-hook-form';
import getYear from 'date-fns/getYear';
import DatePicker from 'react-datepicker';
import range from 'lodash.range';
import getMonth from 'date-fns/getMonth';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core';
import { FormInput } from './FormInput';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    '& div.react-datepicker-wrapper': {
      width: '100%',
    },
  },
}));

export default function ReactDatePicker({
  control,
  name,
  defaultValue = new Date(),
  label,
  style,
  value,
  ...props
}: any) {
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const classes = useStyles();
  return (
    <Controller
      defaultValue={new Date(defaultValue)}
      control={control}
      name={name}
      render={({ onChange, onBlur, value }) => (
        <div className={classes.datePicker}>
          <DatePicker
            customInput={
              <FormInput
                fullWidth
                name={name}
                style={{ width: '100%', ...style }}
                {...props}
                variant="filled"
              />
            }
            name={name}
            onBlur={onBlur}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }: any) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
            selected={new Date(value)}
            onChange={onChange}
          />
        </div>
      )}
    />
  );
}
