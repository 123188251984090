import { gql, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS } from './useGetNotifications';

const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId) {
      _id
    }
  }
`;

const useDeleteNotification = () => {
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    update: (cache, { data: { deleteNotification } }) => {
      cache.modify({
        fields: {
          notifications(notification) {
            const newNotification = notification?.allNotifications?.filter(
              (notification: any) => {
                const notificationId =
                  notification.__ref.split('Notification:')[1];
                return notificationId !== deleteNotification?._id;
              }
            );
            return newNotification;
          },
        },
      });
    },
  });

  async function handleDeleteNotification(notificationId: string) {
    return await deleteNotification({
      variables: { notificationId },
    });
  }

  return { handleDeleteNotification };
};

export { DELETE_NOTIFICATION };
export default useDeleteNotification;
