import * as React from 'react';

type Action =
  | { type: 'open' }
  | { type: 'close' }
  | { type: 'general' }
  | { type: 'closeGeneral' }
  | { type: 'notes' };

type Dispatch = (action: Action) => void;

type State = { count: number };

type ModalProviderProps = { children: React.ReactElement };

export const ModalStateContext = React.createContext<State | undefined>(
  undefined
);

export const ModalDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function modalReducer(state: State, action: Action): any | Error {
  switch (action.type) {
    case 'open': {
      return { ...state, open: true, general: false, notes: false };
    }
    case 'notes': {
      return { ...state, open: true, general: false, notes: true };
    }

    case 'close': {
      return { ...state, open: false, general: false, notes: false };
    }
    case 'general': {
      return { ...state, open: false, general: true, notes: false };
    }
    case 'closeGeneral': {
      return { ...state, general: false, open: false, notes: false };
    }
    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}

function ModalProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    general: false,
    notes: false,
  });

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
}

function useModalState() {
  const context = React.useContext(ModalStateContext);

  if (context === undefined) {
    throw new Error('useModalState must be used within a ModalProvider');
  }

  return context;
}

function useModalDispatch() {
  const context = React.useContext(ModalDispatchContext);

  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider');
  }

  return context;
}

export default function useModal(): any {
  const state = useModalState();
  const dispatch = useModalDispatch();
  return { dispatch, state };
}

export { ModalProvider, useModalState, useModalDispatch };
