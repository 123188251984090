import PeerConnection from 'utils/RTC/PeerConnection';

interface ICreateNewPeerConnectionArgs {
  peerSet: Set<PeerConnection>;
  userId: string;
  onLocalStreamReceived: any;
  onRemoteStreamReceived: any;
  handleIceCandidate: any;
  handleSendDescription: any;
}

export function createNewPeerConnection({
  peerSet,
  userId,
  onLocalStreamReceived,
  onRemoteStreamReceived,
  handleIceCandidate,
  handleSendDescription,
}: ICreateNewPeerConnectionArgs) {
  const currentPeer = new PeerConnection(
    userId,
    onLocalStreamReceived,
    onRemoteStreamReceived,
    handleIceCandidate,
    handleSendDescription
  );
  peerSet.add(currentPeer);
  currentPeer.start();
  return currentPeer;
}

export function handleMuteAndUnmuteAllPeers(peerSet: Set<PeerConnection>) {
  handleActionOnSet<PeerConnection>(peerSet, (connection: PeerConnection) =>
    connection.handleMute()
  );
}
export function handleToggleVideoOnAllPeers(peerSet: Set<PeerConnection>) {
  handleActionOnSet<PeerConnection>(peerSet, (connection: PeerConnection) => {
    connection.handleToggleVideo();
  });
}
export function handleStopAllPeers(peerSet: Set<PeerConnection>) {
  handleActionOnSet<PeerConnection>(peerSet, (connection: PeerConnection) => {
    if (connection.mediaDevice) {
      connection.mediaDevice.stop();
    }
    if (connection.mediaDevice.stream) {
      connection.mediaDevice.stream
        .getTracks()
        .forEach((track: MediaStreamTrack) => {
          track.stop();
        });
    }
    if (connection.mediaDevice.stream) {
      connection.mediaDevice.stream = null;
    }
    connection.stop('tochangelater');
  });
}
export function filterPeerConnection(
  peerSet: Set<PeerConnection>,
  userId: string
) {
  return Array.from(peerSet).find((peer) => peer.getUserId() === userId);
}
export function handleActionOnSet<T>(
  set: Set<T>,
  handleAction: (arrayElement: T) => void
): void {
  const arrayFromSet = Array.from(set);
  arrayFromSet.forEach((element) => handleAction(element));
}
