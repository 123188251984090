import React, { useRef } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/tasks/addIcon.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/foldericon.svg';
import clsx from 'clsx';
import ListItemWithActions from '../ListItemWithActions';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #BCC6D3',
    padding: theme.spacing(0.7),
    backgroundColor: '#F8FAFB',
    boxSizing: 'border-box',
    borderRadius: theme.typography.pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      border: '1px solid #BCC6D3',
    },
  },
  sideActions: {
    marginLeft: 'auto',
    padding: theme.typography.pxToRem(5),
  },
  folderIcon: {
    fontSize: theme.typography.pxToRem(30),
    marginRight: theme.spacing(2),
    backgroundColor: '#E4ECF7',
    padding: theme.spacing(0.5),
    borderRadius: theme.typography.pxToRem(8),
    color: '#8ca7ce',
  },
  fileName: {
    color: '#707090',
  },
  date: {
    fontSize: theme.typography.pxToRem(12),
  },
  addIcon: {
    color: '#C9C9C9',
    // fontSize: '50px',
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30),
    cursor: 'pointer',
  },
}));

interface IUploadFilesInputProps {
  handleAction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  files?: React.ChangeEvent<HTMLInputElement>[];
  name: string;
  createdAt?: Date;
  isActionable?: boolean;
  className?: React.CSSProperties;
}

export default function UploadFilesInput({
  handleAction,
  files,
  name,
  isActionable = false,
  createdAt,
  className,
  ...props
}: IUploadFilesInputProps) {
  const classes = useStyles();

  return (
    <Box>
      <label htmlFor="raised-button-file">
        <Box className={clsx(classes.root, className)} {...props}>
          <Box ml={2} display="flex" alignItems="center">
            <FolderOpenIcon className={classes.folderIcon} />
            <Box display="flex" flexDirection="column">
              <Typography className={classes.fileName}>{name}</Typography>
            </Box>
          </Box>

          {isActionable && (
            <Box className={classes.sideActions}>
              <AddCircleOutlineIcon className={classes.addIcon} />
              <input
                id="raised-button-file"
                type="file"
                onChange={handleAction}
                style={{ display: 'none' }}
              />
            </Box>
          )}
        </Box>
      </label>

      {files &&
        files.map((file: any) => {
          return (
            <ListItemWithActions
              primaryData={file.name}
              secondaryData={file.type}
              AvatarIcon={<FolderIcon />}
              type="normal"
            />
          );
        })}
    </Box>
  );
}
