import { gql, useMutation } from '@apollo/client';

export const ADD_TAG = gql`
  mutation addTag($tagInput: addTagInput) {
    addTag(tagInput: $tagInput) {
      tag
      color
      _id
    }
  }
`;
export default function useAddTag() {
  const [addTag, result] = useMutation(ADD_TAG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          doctorTags() {
            return data?.addTag;
          },
        },
      });
    },
  });
  return { addTag, ...result };
}
