import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import initializeSentry from 'config/sentry';
import './i18n';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import client from './graphql/index';
import theme from './theme';

initializeSentry();

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <Sentry.ErrorBoundary
    onError={(e) => {
      console.error('ERROR: URGENT: An error has occurred.', e.message, e);
    }}
    fallback={myFallback}
    showDialog
  >
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback="">
          <App />
        </React.Suspense>
      </ThemeProvider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/concepts/hot-module-replacement
// if (import.meta.hot) {
//   import.meta.hot.accept();
// }
