import { gql, useMutation } from '@apollo/client';

const GENERATE_APPOINTEMNT_SUMMARY = gql`
  mutation generateAppointmentSummary($appointmentId: ID) {
    generateAppointmentSummary(appointmentId: $appointmentId) {
      appointmentUri
    }
  }
`;

const useGenerateAppointment = () => {
  const [
    generateAppointment,
    { loading: loadingGenerate, error: errorGenerate },
  ] = useMutation(GENERATE_APPOINTEMNT_SUMMARY);
  async function handleGenerate(appointmentId: string) {
    try {
      const updated = await generateAppointment({
        variables: { appointmentId },
      });
      return (window.location.href = `${updated?.data?.generateAppointmentSummary?.appointmentUri}`);
    } catch (e) {
      return e;
    }
  }
  return { handleGenerate, loadingGenerate, errorGenerate };
};
export { GENERATE_APPOINTEMNT_SUMMARY };
export default useGenerateAppointment;
