import React from 'react';
import { useForm } from 'react-hook-form';
import {
  IPatientActivities,
  GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
} from '../index';
import { TypeEnum } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import useCreatePatientActivity, {
  TypeEnum as patientActivity,
} from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import { useToast } from 'hooks/useToast';
import useUpdatePatientActivity from '@eggmed/graphql-client/operations/patientActivitiesOperations/useUpdatePatientActivity';
import {
  GET_PATIENT_ACTIVITIES_BY_FILTER,
  TypeEnum as activityType,
} from './Activity';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useSnackbar } from 'hooks/useSnackbar';

export enum ReflectionType {
  Guided = 'guided',
  Free = 'free',
}

const schema = yup.object().shape({
  question: yup.string().required('Question is a required field'),
});

const useHandlePatientActivities = ({
  defaultValues,
  type,
  doctorAndPatientId,
  handleClose,
  handleCloseAssign,
  filter,
}: {
  defaultValues: IPatientActivities;
  type: activityType | string;
  doctorAndPatientId: { doctor: string; patient: string };
  handleClose?: () => void;
  handleCloseAssign?: () => void;
  filter?: patientActivity;
}) => {
  const [step, setStep] = React.useState(0);
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState(true);

  React.useEffect(() => {
    if (
      defaultValues &&
      defaultValues?.reflectionType === ReflectionType.Guided &&
      type === activityType.REFLECTION
    ) {
      setSelected(true);
      setStep(1);
    }
    if (
      defaultValues &&
      defaultValues?.reflectionType === ReflectionType.Free &&
      type === activityType.REFLECTION
    ) {
      setSelected(false);
      setStep(1);
    }
  }, [defaultValues]);

  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    formState: { errors },
  } = methods;
  React.useEffect(() => {
    if (type === activityType.REFLECTION) {
      return;
    }
    methods.reset();
  }, [type]);
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    step > 0 && setStep(step - 1);
  };
  const { triggerSnack } = useSnackbar();
  const [handleCreatePatientActivity, { loading: loadingCreateActivity }] =
    useCreatePatientActivity();
  const [handleUpdatePatientActivity, { loading: loadingUpdateActivity }] =
    useUpdatePatientActivity();

  const loading = loadingCreateActivity || loadingUpdateActivity;
  const refetchQueries = [
    {
      query: GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
      variables: {
        patientId: doctorAndPatientId.patient,
        doctorId: doctorAndPatientId.doctor,
      },
    },
    {
      query: GET_PATIENT_ACTIVITIES_BY_FILTER,
      variables: {
        patientId: doctorAndPatientId.patient,
        doctorId: doctorAndPatientId.doctor,
        filter: filter,
        page: '1',
        limit: '5',
      },
    },
  ];

  const uploadValue = (value) => {
    if (typeof value === 'boolean') {
      return value;
    } else {
      if (value.length === 0) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const data = methods.getValues();
    await methods.trigger();

    const { doctor, patient } = doctorAndPatientId;
    const upload = uploadValue(data.upload);
    const frequency = {
      startDate: new Date(data.dueDate[0]),
      endDate: data.dueDate[1]
        ? new Date(data.dueDate[1])
        : new Date(data.dueDate[0]),
      frequencyType: data.repeat,
      repetition: {
        value: parseInt(data.numberOfRepeat),
        unit: data.repeatEvery,
      },
      repeatOn: data.repeatOn,
    };

    const payloadType =
      type === activityType.REFLECTION
        ? TypeEnum.Reflection
        : type === activityType.SLEEP
        ? TypeEnum.Sleep
        : TypeEnum.Mood;

    const patientActivitiesInput = {
      question:
        type === activityType.REFLECTION && !selected
          ? 'Free Reflection'
          : data.question,
      options: selectedValues,
      type: payloadType,
      reflectionType: selected ? ReflectionType.Guided : ReflectionType.Free,
      photo: upload,
      notes: data.notes || false,
      sleep: data.sleep || false,
      frequency,
      assignedAt: defaultValues ? defaultValues?.assignedAt : new Date(),
      doctor,
      patient,
    };

    if (!errors['question']) {
      if (defaultValues) {
        await handleUpdatePatientActivity({
          variables: { id: defaultValues._id, patientActivitiesInput },
          refetchQueries,
        });
      } else {
        await handleCreatePatientActivity({
          variables: { patientActivitiesInput },
          refetchQueries,
        });
      }

      triggerSnack();
      setTimeout(() => {
        handleClose(), 500;
        handleCloseAssign && handleCloseAssign();
      });
    }
  };
  const onSubmit = () => {
    step === 0 ? handleNext() : handleSubmit();
  };
  const handleBookMark = async (id: string, bookmarked: Boolean) => {
    const patientActivitiesInput = {
      bookmarked,
      patient: doctorAndPatientId.patient,
    };
    await handleUpdatePatientActivity({
      variables: { id, patientActivitiesInput },
      refetchQueries,
    });
  };

  return {
    selected,
    setSelected,
    selectedValues,
    setSelectedValues,
    step,
    methods,
    handleNext,
    handleBack,
    onSubmit,
    handleSubmit,
    handleBookMark,
    loading,
  };
};

export default useHandlePatientActivities;
