import { gql, useSubscription } from '@apollo/client';
import { subscriptionNewThreadCreated_subscriptionNewThreadCreated } from './__generated__/subscriptionNewThreadCreated';

const SUBSCRIPTION_TO_NEW_THREAD_CREATED = gql`
  subscription subscriptionNewThreadCreated($userId: ID!) {
    subscriptionNewThreadCreated(userId: $userId) {
      updatedAt
      createdAt
      _id
      lastUpdateTime
      subject
      type
      isArchived
      unReadItMessage {
        id
        threadConversation
        user
        lastMessageReadIt
      }
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      lastMessage {
        user {
          _id
          firstname
          middlename
          username
        }
        content {
          text
          files {
            _id
            url
            fileName
            mimeType
          }
        }
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
        createdAt
        updatedAt
      }
    }
  }
`;

const useSubscriptionNewThreadCreated = (
  userId: string,
  event: (
    data: subscriptionNewThreadCreated_subscriptionNewThreadCreated
  ) => void
) => {
  const result = useSubscription(SUBSCRIPTION_TO_NEW_THREAD_CREATED, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      event(subscriptionData?.data?.subscriptionNewThreadCreated);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_NEW_THREAD_CREATED };
export default useSubscriptionNewThreadCreated;
