
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // BlockPicker:
  bpRoot: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    border: '1px solid #FFF',
    padding: '20px',
    marginBottom:'1rem',
    gap:'33px'
  },
  input: {
    width: '100%',
    padding: '5px 15px',
    borderRadius: '10px',
    backgroundColor: '#F7F8FA',
    border: '1px solid #CBD5E1',
    color: '#94A3B8',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',

    '&::placeholder': {
      color: '#94A3B8',
      opacity: 1,
    },

    '&:hover': {
      borderColor: '#A0AEC0',
    },

    '&:focus': {
      borderColor: '#3182CE',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 0px 3px rgba(66, 153, 225, 0.6)',
    },
  },
  sections: {},
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  bpTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: '#98A2B3',
    margin: '20px 0 10px',
  },
  elements: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '7px',
  },
  element: {
    backgroundColor: '#F8F9FC',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    padding: '10px 12px',
    width: '8.8rem',
    height: '3.31rem',

    '&:hover': {
      backgroundColor: '#F8F9FC',
    },
  },
  elementName: {
    textAlign: 'left',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#475467',
    textTransform: 'none',
  },

  // SaveTemplateAs:
  stpRoot: {
  
    width:'19vw',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    border: '1.5px solid #FFF',
    padding: '20px',
    gap:'33px',
    marginBottom: '1rem',
    '&:hover': {
      border: '1px solid #147AF3',
    },

  },
  stpTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: '#98A2B3',
  },
  templates: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
  },
  templateItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    border: '1px solid #D0D5DD',
    padding: '8px 12px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#E0F2FE',
    border: '1px solid #0265DC',
  },
  templateName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    color: '#0265DC',
  },
  inputBase:{
    borderRadius: '8px',
    padding:'10px',
    color: '#98A2B3',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    border: '1px solid #D0D5DD'
  }
}));

export default useStyles;
