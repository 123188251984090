import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateSchedule from '../animations/schedule.json';

export const Appointment = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateSchedule}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Sessions</h3>
        <p className={classes.description}>Book sessions with your provider.</p>
        <p className={classes.description}>
          View <span className={classes.bold}>past</span> and{' '}
          <span className={classes.bold}>upcoming</span> appointments, and
          easily <span className={classes.bold}>reschedule</span> sessions as
          needed.
        </p>
      </div>
    </div>
  );
};
