import { DateTime } from 'luxon';

export const timezoneLabels: { [key: string]: string } = {
  'Africa/Abidjan': 'Greenwich Mean Time',
  'Africa/Accra': 'Greenwich Mean Time',
  'Africa/Addis_Ababa': 'East Africa Time',
  'Africa/Algiers': 'Central European Time',
  'Africa/Asmara': 'East Africa Time',
  'Africa/Bamako': 'Greenwich Mean Time',
  'Africa/Bangui': 'West Africa Time',
  'Africa/Banjul': 'Greenwich Mean Time',
  'Africa/Bissau': 'Greenwich Mean Time',
  'Africa/Blantyre': 'Central Africa Time',
  'Africa/Brazzaville': 'West Africa Time',
  'Africa/Bujumbura': 'Central Africa Time',
  'Africa/Cairo': 'Eastern European Time',
  'Africa/Casablanca': 'Western European Time',
  'Africa/Ceuta': 'Central European Time',
  'Africa/Conakry': 'Greenwich Mean Time',
  'Africa/Dakar': 'Greenwich Mean Time',
  'Africa/Dar_es_Salaam': 'East Africa Time',
  'Africa/Djibouti': 'East Africa Time',
  'Africa/Douala': 'West Africa Time',
  'Africa/El_Aaiun': 'Western European Time',
  'Africa/Freetown': 'Greenwich Mean Time',
  'Africa/Gaborone': 'Central Africa Time',
  'Africa/Harare': 'Central Africa Time',
  'Africa/Johannesburg': 'South Africa Standard Time',
  'Africa/Juba': 'East Africa Time',
  'Africa/Kampala': 'East Africa Time',
  'Africa/Khartoum': 'East Africa Time',
  'Africa/Kigali': 'Central Africa Time',
  'Africa/Kinshasa': 'West Africa Time',
  'Africa/Lagos': 'West Africa Time',
  'Africa/Libreville': 'West Africa Time',
  'Africa/Lome': 'Greenwich Mean Time',
  'Africa/Luanda': 'West Africa Time',
  'Africa/Lubumbashi': 'Central Africa Time',
  'Africa/Lusaka': 'Central Africa Time',
  'Africa/Malabo': 'West Africa Time',
  'Africa/Maputo': 'Central Africa Time',
  'Africa/Maseru': 'South Africa Standard Time',
  'Africa/Mbabane': 'South Africa Standard Time',
  'Africa/Mogadishu': 'East Africa Time',
  'Africa/Monrovia': 'Greenwich Mean Time',
  'Africa/Nairobi': 'East Africa Time',
  'Africa/Ndjamena': 'West Africa Time',
  'Africa/Niamey': 'West Africa Time',
  'Africa/Nouakchott': 'Greenwich Mean Time',
  'Africa/Ouagadougou': 'Greenwich Mean Time',
  'Africa/Porto-Novo': 'West Africa Time',
  'Africa/Sao_Tome': 'Greenwich Mean Time',
  'Africa/Tripoli': 'Eastern European Time',
  'Africa/Tunis': 'Central European Time',
  'Africa/Windhoek': 'West Africa Time',
  'America/Adak': 'Hawaii-Aleutian Standard Time',
  'America/Anchorage': 'Alaska Standard Time',
  'America/Anguilla': 'Atlantic Standard Time',
  'America/Antigua': 'Atlantic Standard Time',
  'America/Araguaina': 'Brasilia Standard Time',
  'America/Argentina/Buenos_Aires': 'Argentina Standard Time',
  'America/Argentina/Catamarca': 'Argentina Standard Time',
  'America/Argentina/Cordoba': 'Argentina Standard Time',
  'America/Argentina/Jujuy': 'Argentina Standard Time',
  'America/Argentina/La_Rioja': 'Argentina Standard Time',
  'America/Argentina/Mendoza': 'Argentina Standard Time',
  'America/Argentina/Rio_Gallegos': 'Argentina Standard Time',
  'America/Argentina/Salta': 'Argentina Standard Time',
  'America/Argentina/San_Juan': 'Argentina Standard Time',
  'America/Argentina/San_Luis': 'Argentina Standard Time',
  'America/Argentina/Tucuman': 'Argentina Standard Time',
  'America/Argentina/Ushuaia': 'Argentina Standard Time',
  'America/Aruba': 'Atlantic Standard Time',
  'America/Asuncion': 'Paraguay Standard Time',
  'America/Atikokan': 'Eastern Standard Time',
  'America/Bahia': 'Brasilia Standard Time',
  'America/Bahia_Banderas': 'Central Standard Time',
  'America/Barbados': 'Atlantic Standard Time',
  'America/Belem': 'Brasilia Standard Time',
  'America/Belize': 'Central Standard Time',
  'America/Blanc-Sablon': 'Atlantic Standard Time',
  'America/Boa_Vista': 'Amazon Standard Time',
  'America/Bogota': 'Colombia Standard Time',
  'America/Boise': 'Mountain Standard Time',
  'America/Cambridge_Bay': 'Mountain Standard Time',
  'America/Campo_Grande': 'Amazon Standard Time',
  'America/Cancun': 'Eastern Standard Time',
  'America/Caracas': 'Venezuela Standard Time',
  'America/Cayenne': 'French Guiana Time',
  'America/Cayman': 'Eastern Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/Chihuahua': 'Mountain Standard Time',
  'America/Costa_Rica': 'Central Standard Time',
  'America/Creston': 'Mountain Standard Time',
  'America/Cuiaba': 'Amazon Standard Time',
  'America/Curacao': 'Atlantic Standard Time',
  'America/Danmarkshavn': 'Greenwich Mean Time',
  'America/Dawson': 'Pacific Standard Time',
  'America/Dawson_Creek': 'Mountain Standard Time',
  'America/Denver': 'Mountain Standard Time',
  'America/Detroit': 'Eastern Standard Time',
  'America/Dominica': 'Atlantic Standard Time',
  'America/Edmonton': 'Mountain Standard Time',
  'America/Eirunepe': 'Acre Time',
  'America/El_Salvador': 'Central Standard Time',
  'America/Fort_Nelson': 'Mountain Standard Time',
  'America/Fortaleza': 'Brasilia Standard Time',
  'America/Glace_Bay': 'Atlantic Standard Time',
  'America/Godthab': 'Western Greenland Time',
  'America/Goose_Bay': 'Atlantic Standard Time',
  'America/Grand_Turk': 'Eastern Standard Time',
  'America/Grenada': 'Atlantic Standard Time',
  'America/Guadeloupe': 'Atlantic Standard Time',
  'America/Guatemala': 'Central Standard Time',
  'America/Guayaquil': 'Ecuador Time',
  'America/Guyana': 'Guyana Time',
  'America/Halifax': 'Atlantic Standard Time',
  'America/Havana': 'Cuba Standard Time',
  'America/Hermosillo': 'Mountain Standard Time',
  'America/Indiana/Indianapolis': 'Eastern Standard Time',
  'America/Indiana/Knox': 'Central Standard Time',
  'America/Indiana/Marengo': 'Eastern Standard Time',
  'America/Indiana/Petersburg': 'Eastern Standard Time',
  'America/Indiana/Tell_City': 'Central Standard Time',
  'America/Indiana/Vevay': 'Eastern Standard Time',
  'America/Indiana/Vincennes': 'Eastern Standard Time',
  'America/Indiana/Winamac': 'Eastern Standard Time',
  'America/Inuvik': 'Mountain Standard Time',
  'America/Iqaluit': 'Eastern Standard Time',
  'America/Jamaica': 'Eastern Standard Time',
  'America/Juneau': 'Alaska Standard Time',
  'America/Kentucky/Louisville': 'Eastern Standard Time',
  'America/Kentucky/Monticello': 'Eastern Standard Time',
  'America/Kralendijk': 'Atlantic Standard Time',
  'America/La_Paz': 'Bolivia Time',
  'America/Lima': 'Peru Standard Time',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Lower_Princes': 'Atlantic Standard Time',
  'America/Maceio': 'Brasilia Standard Time',
  'America/Managua': 'Central Standard Time',
  'America/Manaus': 'Amazon Standard Time',
  'America/Marigot': 'Atlantic Standard Time',
  'America/Martinique': 'Atlantic Standard Time',
  'America/Matamoros': 'Central Standard Time',
  'America/Mazatlan': 'Mountain Standard Time',
  'America/Menominee': 'Central Standard Time',
  'America/Merida': 'Central Standard Time',
  'America/Metlakatla': 'Alaska Standard Time',
  'America/Mexico_City': 'Central Standard Time',
  'America/Miquelon': 'Saint Pierre and Miquelon Standard Time',
  'America/Moncton': 'Atlantic Standard Time',
  'America/Monterrey': 'Central Standard Time',
  'America/Montevideo': 'Uruguay Standard Time',
  'America/Montserrat': 'Atlantic Standard Time',
  'America/Nassau': 'Eastern Standard Time',
  'America/New_York': 'Eastern Standard Time',
  'America/Nipigon': 'Eastern Standard Time',
  'America/Nome': 'Alaska Standard Time',
  'America/Noronha': 'Fernando de Noronha Standard Time',
  'America/North_Dakota/Beulah': 'Central Standard Time',
  'America/North_Dakota/Center': 'Central Standard Time',
  'America/North_Dakota/New_Salem': 'Central Standard Time',
  'America/Nuuk': 'Western Greenland Time',
  'America/Ojinaga': 'Mountain Standard Time',
  'America/Panama': 'Eastern Standard Time',
  'America/Pangnirtung': 'Eastern Standard Time',
  'America/Paramaribo': 'Suriname Time',
  'America/Phoenix': 'Mountain Standard Time',
  'America/Port-au-Prince': 'Eastern Standard Time',
  'America/Port_of_Spain': 'Atlantic Standard Time',
  'America/Porto_Velho': 'Amazon Standard Time',
  'America/Puerto_Rico': 'Atlantic Standard Time',
  'America/Punta_Arenas': 'Chile Standard Time',
  'America/Rainy_River': 'Central Standard Time',
  'America/Rankin_Inlet': 'Central Standard Time',
  'America/Recife': 'Brasilia Standard Time',
  'America/Regina': 'Central Standard Time',
  'America/Resolute': 'Central Standard Time',
  'America/Rio_Branco': 'Acre Time',
  'America/Santarem': 'Brasilia Standard Time',
  'America/Santiago': 'Chile Standard Time',
  'America/Santo_Domingo': 'Atlantic Standard Time',
  'America/Sao_Paulo': 'Brasilia Standard Time',
  'America/Scoresbysund': 'Eastern Greenland Time',
  'America/Sitka': 'Alaska Standard Time',
  'America/St_Barthelemy': 'Atlantic Standard Time',
  'America/St_Johns': 'Newfoundland Standard Time',
  'America/St_Kitts': 'Atlantic Standard Time',
  'America/St_Lucia': 'Atlantic Standard Time',
  'America/St_Thomas': 'Atlantic Standard Time',
  'America/St_Vincent': 'Atlantic Standard Time',
  'America/Swift_Current': 'Central Standard Time',
  'America/Tegucigalpa': 'Central Standard Time',
  'America/Thule': 'Atlantic Standard Time',
  'America/Thunder_Bay': 'Eastern Standard Time',
  'America/Tijuana': 'Pacific Standard Time',
  'America/Toronto': 'Eastern Standard Time',
  'America/Tortola': 'Atlantic Standard Time',
  'America/Vancouver': 'Pacific Standard Time',
  'America/Whitehorse': 'Pacific Standard Time',
  'America/Winnipeg': 'Central Standard Time',
  'America/Yakutat': 'Alaska Standard Time',
  'America/Yellowknife': 'Mountain Standard Time',
  'Antarctica/Casey': 'Western Standard Time (Australia)',
  'Antarctica/Davis': 'Davis Time',
  'Antarctica/DumontDUrville': "Dumont d'Urville Time",
  'Antarctica/Macquarie': 'Macquarie Island Time',
  'Antarctica/Mawson': 'Mawson Time',
  'Antarctica/McMurdo': 'New Zealand Standard Time',
  'Antarctica/Palmer': 'Chile Standard Time',
  'Antarctica/Rothera': 'Rothera Research Station Time',
  'Antarctica/Syowa': 'Syowa Time',
  'Antarctica/Troll': 'Greenwich Mean Time',
  'Antarctica/Vostok': 'Vostok Time',
  'Arctic/Longyearbyen': 'Central European Time',
  'Asia/Aden': 'Arabian Standard Time',
  'Asia/Almaty': 'East Kazakhstan Time',
  'Asia/Amman': 'Eastern European Time',
  'Asia/Anadyr': 'Anadyr Time',
  'Asia/Aqtau': 'West Kazakhstan Time',
  'Asia/Aqtobe': 'West Kazakhstan Time',
  'Asia/Ashgabat': 'Turkmenistan Time',
  'Asia/Atyrau': 'West Kazakhstan Time',
  'Asia/Baghdad': 'Arabian Standard Time',
  'Asia/Bahrain': 'Arabian Standard Time',
  'Asia/Baku': 'Azerbaijan Standard Time',
  'Asia/Bangkok': 'Indochina Time',
  'Asia/Barnaul': 'Russia Time (Novosibirsk)',
  'Asia/Beirut': 'Eastern European Time',
  'Asia/Bishkek': 'Kyrgyzstan Time',
  'Asia/Brunei': 'Brunei Darussalam Time',
  'Asia/Chita': 'Yakutsk Time',
  'Asia/Choibalsan': 'Choibalsan Standard Time',
  'Asia/Colombo': 'India Standard Time',
  'Asia/Damascus': 'Eastern European Time',
  'Asia/Dhaka': 'Bangladesh Standard Time',
  'Asia/Dili': 'Timor-Leste Time',
  'Asia/Dubai': 'Gulf Standard Time',
  'Asia/Dushanbe': 'Tajikistan Time',
  'Asia/Famagusta': 'Eastern European Time',
  'Asia/Gaza': 'Eastern European Time',
  'Asia/Hebron': 'Eastern European Time',
  'Asia/Ho_Chi_Minh': 'Indochina Time',
  'Asia/Hong_Kong': 'Hong Kong Standard Time',
  'Asia/Hovd': 'Hovd Time',
  'Asia/Irkutsk': 'Irkutsk Time',
  'Asia/Jakarta': 'Western Indonesia Time',
  'Asia/Jayapura': 'Eastern Indonesia Time',
  'Asia/Jerusalem': 'Israel Standard Time',
  'Asia/Kabul': 'Afghanistan Time',
  'Asia/Kamchatka': 'Kamchatka Time',
  'Asia/Karachi': 'Pakistan Standard Time',
  'Asia/Kathmandu': 'Nepal Time',
  'Asia/Khandyga': 'Yakutsk Time',
  'Asia/Kolkata': 'India Standard Time',
  'Asia/Krasnoyarsk': 'Krasnoyarsk Time',
  'Asia/Kuala_Lumpur': 'Malaysia Standard Time',
  'Asia/Kuching': 'Malaysia Standard Time',
  'Asia/Kuwait': 'Arabian Standard Time',
  'Asia/Macau': 'China Standard Time',
  'Asia/Magadan': 'Magadan Time',
  'Asia/Makassar': 'Central Indonesia Time',
  'Asia/Manila': 'Philippine Standard Time',
  'Asia/Muscat': 'Gulf Standard Time',
  'Asia/Nicosia': 'Eastern European Time',
  'Asia/Novokuznetsk': 'Krasnoyarsk Time',
  'Asia/Novosibirsk': 'Novosibirsk Time',
  'Asia/Omsk': 'Omsk Time',
  'Asia/Oral': 'West Kazakhstan Time',
  'Asia/Phnom_Penh': 'Indochina Time',
  'Asia/Pontianak': 'Western Indonesia Time',
  'Asia/Pyongyang': 'Korea Standard Time',
  'Asia/Qatar': 'Arabian Standard Time',
  'Asia/Qyzylorda': 'Qyzylorda Time',
  'Asia/Riyadh': 'Arabian Standard Time',
  'Asia/Sakhalin': 'Sakhalin Time',
  'Asia/Samarkand': 'Uzbekistan Time',
  'Asia/Seoul': 'Korea Standard Time',
  'Asia/Shanghai': 'China Standard Time',
  'Asia/Singapore': 'Singapore Standard Time',
  'Asia/Srednekolymsk': 'Srednekolymsk Time',
  'Asia/Taipei': 'Taipei Standard Time',
  'Asia/Tashkent': 'Uzbekistan Time',
  'Asia/Tbilisi': 'Georgia Standard Time',
  'Asia/Tehran': 'Iran Standard Time',
  'Asia/Thimphu': 'Bhutan Time',
  'Asia/Tokyo': 'Japan Standard Time',
  'Asia/Tomsk': 'Russia Time (Novosibirsk)',
  'Asia/Ulaanbaatar': 'Ulaanbaatar Standard Time',
  'Asia/Urumqi': 'China Standard Time',
  'Asia/Ust-Nera': 'Vladivostok Time',
  'Asia/Vientiane': 'Indochina Time',
  'Asia/Vladivostok': 'Vladivostok Time',
  'Asia/Yakutsk': 'Yakutsk Time',
  'Asia/Yangon': 'Myanmar Time',
  'Asia/Yekaterinburg': 'Yekaterinburg Time',
  'Asia/Yerevan': 'Armenia Standard Time',
  'Atlantic/Azores': 'Azores Standard Time',
  'Atlantic/Bermuda': 'Atlantic Standard Time',
  'Atlantic/Canary': 'Western European Time',
  'Atlantic/Cape_Verde': 'Cape Verde Time',
  'Atlantic/Faroe': 'Western European Time',
  'Atlantic/Madeira': 'Western European Time',
  'Atlantic/Reykjavik': 'Greenwich Mean Time',
  'Atlantic/South_Georgia': 'South Georgia Time',
  'Atlantic/St_Helena': 'Greenwich Mean Time',
  'Atlantic/Stanley': 'Falkland Islands Time',
  'Australia/Adelaide': 'Australian Central Standard Time',
  'Australia/Brisbane': 'Australian Eastern Standard Time',
  'Australia/Broken_Hill': 'Australian Central Standard Time',
  'Australia/Currie': 'Australian Eastern Standard Time',
  'Australia/Darwin': 'Australian Central Standard Time',
  'Australia/Eucla': 'Australian Central Western Standard Time',
  'Australia/Hobart': 'Australian Eastern Standard Time',
  'Australia/Lindeman': 'Australian Eastern Standard Time',
  'Australia/Lord_Howe': 'Lord Howe Standard Time',
  'Australia/Melbourne': 'Australian Eastern Standard Time',
  'Australia/Perth': 'Australian Western Standard Time',
  'Australia/Sydney': 'Australian Eastern Standard Time',
  'Canada/Atlantic': 'Atlantic Standard Time',
  'Canada/Central': 'Central Standard Time',
  'Canada/Eastern': 'Eastern Standard Time',
  'Canada/Mountain': 'Mountain Standard Time',
  'Canada/Newfoundland': 'Newfoundland Standard Time',
  'Canada/Pacific': 'Pacific Standard Time',
  'Europe/Amsterdam': 'Central European Time',
  'Europe/Andorra': 'Central European Time',
  'Europe/Astrakhan': 'Moscow Standard Time',
  'Europe/Athens': 'Eastern European Time',
  'Europe/Belgrade': 'Central European Time',
  'Europe/Berlin': 'Central European Time',
  'Europe/Bratislava': 'Central European Time',
  'Europe/Brussels': 'Central European Time',
  'Europe/Bucharest': 'Eastern European Time',
  'Europe/Budapest': 'Central European Time',
  'Europe/Busingen': 'Central European Time',
  'Europe/Chisinau': 'Eastern European Time',
  'Europe/Copenhagen': 'Central European Time',
  'Europe/Dublin': 'Greenwich Mean Time',
  'Europe/Gibraltar': 'Central European Time',
  'Europe/Guernsey': 'Greenwich Mean Time',
  'Europe/Helsinki': 'Eastern European Time',
  'Europe/Isle_of_Man': 'Greenwich Mean Time',
  'Europe/Istanbul': 'Turkey Time',
  'Europe/Jersey': 'Greenwich Mean Time',
  'Europe/Kaliningrad': 'Eastern European Time',
  'Europe/Kiev': 'Eastern European Time',
  'Europe/Kirov': 'Russia Time (Moscow)',
  'Europe/Lisbon': 'Western European Time',
  'Europe/Ljubljana': 'Central European Time',
  'Europe/London': 'Greenwich Mean Time',
  'Europe/Luxembourg': 'Central European Time',
  'Europe/Madrid': 'Central European Time',
  'Europe/Malta': 'Central European Time',
  'Europe/Mariehamn': 'Eastern European Time',
  'Europe/Minsk': 'Moscow Standard Time',
  'Europe/Monaco': 'Central European Time',
  'Europe/Moscow': 'Moscow Standard Time',
  'Europe/Oslo': 'Central European Time',
  'Europe/Paris': 'Central European Time',
  'Europe/Podgorica': 'Central European Time',
  'Europe/Prague': 'Central European Time',
  'Europe/Riga': 'Eastern European Time',
  'Europe/Rome': 'Central European Time',
  'Europe/Samara': 'Samara Time',
  'Europe/San_Marino': 'Central European Time',
  'Europe/Sarajevo': 'Central European Time',
  'Europe/Saratov': 'Moscow Standard Time',
  'Europe/Simferopol': 'Moscow Standard Time',
  'Europe/Skopje': 'Central European Time',
  'Europe/Sofia': 'Eastern European Time',
  'Europe/Stockholm': 'Central European Time',
  'Europe/Tallinn': 'Eastern European Time',
  'Europe/Tirane': 'Central European Time',
  'Europe/Ulyanovsk': 'Moscow Standard Time',
  'Europe/Uzhgorod': 'Eastern European Time',
  'Europe/Vaduz': 'Central European Time',
  'Europe/Vatican': 'Central European Time',
  'Europe/Vienna': 'Central European Time',
  'Europe/Vilnius': 'Eastern European Time',
  'Europe/Volgograd': 'Volgograd Time',
  'Europe/Warsaw': 'Central European Time',
  'Europe/Zagreb': 'Central European Time',
  'Europe/Zaporozhye': 'Eastern European Time',
  'Europe/Zurich': 'Central European Time',
  'Indian/Antananarivo': 'East Africa Time',
  'Indian/Chagos': 'Indian Ocean Time',
  'Indian/Christmas': 'Christmas Island Time',
  'Indian/Cocos': 'Cocos Islands Time',
  'Indian/Comoro': 'East Africa Time',
  'Indian/Kerguelen': 'French Southern & Antarctic Time',
  'Indian/Mahe': 'Seychelles Time',
  'Indian/Maldives': 'Maldives Time',
  'Indian/Mauritius': 'Mauritius Time',
  'Indian/Mayotte': 'East Africa Time',
  'Indian/Reunion': 'Reunion Time',
  'Pacific/Apia': 'Samoa Standard Time',
  'Pacific/Auckland': 'New Zealand Standard Time',
  'Pacific/Bougainville': 'Papua New Guinea Time',
  'Pacific/Chatham': 'Chatham Standard Time',
  'Pacific/Chuuk': 'Chuuk Time',
  'Pacific/Easter': 'Easter Island Time',
  'Pacific/Efate': 'Vanuatu Time',
  'Pacific/Enderbury': 'Phoenix Islands Time',
  'Pacific/Fakaofo': 'Tokelau Time',
  'Pacific/Fiji': 'Fiji Standard Time',
  'Pacific/Funafuti': 'Tuvalu Time',
  'Pacific/Galapagos': 'Galapagos Time',
  'Pacific/Gambier': 'Gambier Islands Time',
  'Pacific/Guadalcanal': 'Solomon Islands Time',
  'Pacific/Guam': 'Chamorro Standard Time',
  'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
  'Pacific/Kiritimati': 'Line Islands Time',
  'Pacific/Kosrae': 'Kosrae Time',
  'Pacific/Kwajalein': 'Marshall Islands Time',
  'Pacific/Majuro': 'Marshall Islands Time',
  'Pacific/Marquesas': 'Marquesas Time',
  'Pacific/Midway': 'Samoa Standard Time',
  'Pacific/Nauru': 'Nauru Time',
  'Pacific/Niue': 'Niue Time',
  'Pacific/Norfolk': 'Norfolk Island Time',
  'Pacific/Noumea': 'New Caledonia Time',
  'Pacific/Pago_Pago': 'Samoa Standard Time',
  'Pacific/Palau': 'Palau Time',
  'Pacific/Pitcairn': 'Pitcairn Standard Time',
  'Pacific/Pohnpei': 'Pohnpei Standard Time',
  'Pacific/Port_Moresby': 'Papua New Guinea Time',
  'Pacific/Rarotonga': 'Cook Islands Time',
  'Pacific/Saipan': 'Chamorro Standard Time',
  'Pacific/Tahiti': 'Tahiti Time',
  'Pacific/Tarawa': 'Gilbert Islands Time',
  'Pacific/Tongatapu': 'Tonga Standard Time',
  'Pacific/Wake': 'Wake Island Time',
  'Pacific/Wallis': 'Wallis and Futuna Time',
};
export const timezones = () => {
  const timeZoneData = (Intl as any)
    .supportedValuesOf('timeZone')
    .map((tz: string) => {
      const label = timezoneLabels[tz] || tz;

      const now = new Date();
      const localTime = new Date(now.toLocaleString('en-US', { timeZone: tz }));
      const localOffset = localTime.getTime() - now.getTime();

      // Calculate the offset in hours
      const offsetHours = localOffset / 3600000;

      // Format the offset as GMT+/-HH:MM
      const sign = offsetHours >= 0 ? '+' : '-';
      const formattedOffset = `GMT${sign}${Math.abs(offsetHours)
        .toFixed(2)
        .replace('.', ':')
        .padStart(5, '0')}`;

      return {
        label,
        formattedOffset,
        offsetHours, // store the numeric offset for sorting
      };
    });

  timeZoneData.sort((a, b) => a.offsetHours - b.offsetHours);

  return timeZoneData.map(
    ({ formattedOffset, label }) => `(${formattedOffset}) ${label}`
  );
};
export function removeDuplicates(arr) {
  let unique = {};
  arr.forEach((obj) => {
    unique[JSON.stringify(obj)] = obj;
  });
  return Object.values(unique);
}
export function findOffset(tz: string) {
  const offset = new Date()
    .toLocaleTimeString('en', { timeZone: tz, timeZoneName: 'short' })
    .split(' ')[2];
  return `(${offset})`;
}
export function getKeyByValue(
  object: Record<string, string>,
  value: string
): string | undefined {
  return Object.keys(object).find((key) => object[key] === value);
}
export function extractLabel(str: string): string | undefined {
  // Match the part of the string after the space
  let match = str.match(/\s(.+)/);
  if (match && match.length > 1) {
    return match[1]; // Return the part of the string after the space
  }
  return null; // Return null if no match is found
}

const gmtOffsetToNumber = (gmtString) => {
  const parts = /\(GMT([+-])(\d+):(\d+)\)/.exec(gmtString);
  if (!parts) return 0;

  const sign = parts[1] === '+' ? 1 : -1;
  const hours = parseInt(parts[2], 10);
  const minutes = parseInt(parts[3], 10) / 60;
  return sign * (hours + minutes);
};

export function buildTzOptions(timezones: any[]) {
  return timezones
    .map((tz) => {
      const dt = DateTime.now().setZone(tz?.name);
      const offset = dt.offset;
      const offsetHours = Math.floor(Math.abs(offset) / 60);
      const offsetMinutes = Math.abs(offset) % 60;
      const sign = offset >= 0 ? '+' : '-';
      const formattedOffset = `${sign}${String(offsetHours).padStart(
        2,
        '0'
      )}:${String(offsetMinutes).padStart(2, '0')}`;
      const name = tz?.alternativeName;
      const capital = tz?.mainCities[0];
      const result = `(GMT${formattedOffset}) - ${name} - ${capital}`;
      return result.length > 50 ? result.slice(0, 50) + '...' : result;
    })
    .sort((a, b) => {
      const offsetA = gmtOffsetToNumber(a);
      const offsetB = gmtOffsetToNumber(b);
      return offsetA - offsetB;
    });
}
