import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


Dayjs.extend(weekday);
const useStyles = makeStyles<Theme>((theme) => ({
  datePicker: {
    width: '100%',
    '& div.react-datepicker': {
      border: '0px solid white',
      width: '100%',
      backgroundColor: ({ changeColor }: { changeColor: boolean }) =>
        changeColor ? '#082CAF' : 'white',
    },
    '& div.react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__day--in-range': {
      backgroundColor: ({ color }: { color: string }) =>
        color ?? '#39C38D !important',
      color: 'rgba(255,255,255,0.8) !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day--range-start:hover, & .react-datepicker__day--range-end:hover, & .react-datepicker__day--in-range:hover, & .react-datepicker__day--in-selecting-range:hover':
      {
        backgroundColor: ({ color }: { color: string }) =>
          color ?? '#39C38D !important',
        borderRadius: '30px',
        cursor: 'pointer', // Add this to indicate the element is clickable
      },
    '& .react-datepicker__day--range-end': {
      color: '#70757a !important',
      fontWeight: 'bold',
    },
    '& .react-datepicker__day--in-selecting-range:hover': {
      color: 'rgba(255,255,255,0.8) !important',
    },
    '& .react-datepicker-popper': {},
    '& .react-datepicker__day--selected': {
      backgroundColor: ({ color }: { color: string }) =>
        color ?? '#39C38D !important',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      fontFamily: 'Inter, sans-serif',
      color: '#70757a',
      padding: '8px',
      [theme.breakpoints.only('lg')]: {
        padding: theme.typography.pxToRem(6),
      },
      [theme.breakpoints.only('md')]: {
        padding: theme.typography.pxToRem(2),
      },
    },
    '& .react-datepicker__header': {
      backgroundColor: ({ changeColor }: { changeColor: boolean }) =>
        changeColor ? '#082CAF' : 'white',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      fontFamily: 'Inter, sans-serif',
      color: 'rgb(60,64,67)',
      padding: '4px',
      outline: '0px solid white',
    },
    '& .react-datepicker__day--highlighted-custom-1': {
      // backgroundColor: '#EFF6FF',
    },
    '& .react-datepicker__week': {
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
    },
    '& .react-datepicker__month-container': {
      width: '100%',
    },
    '& .react-datepicker__day-names': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
    },
    '& .react-datepicker__day--outside-month': {
      fontFamily: 'Inter, sans-serif',
      color: '#70757a',
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#425364',
    backgroundColor: ({ changeColor }: { changeColor: boolean }) =>
      changeColor ? '#082CAF' : 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
    float: 'right',
  },
  margin: {
    margin: theme.spacing(1),
  },
  DateText: {
    fontFamily: 'Inter, sans-serif',
    color: ({ changeColor }: { changeColor: boolean }) =>
      changeColor ? 'rgba(255, 255, 255, 0.5)' : '#70757a',
    fontSize: '1.2rem',
  },
  HeaderElement: {
    padding: '5px',
    paddingLeft: '30px',
    paddingRight: '30px',
    fontWeight: 400,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
}));

const hightLightWithRanges = [
  {
    'react-datepicker__day--highlighted-custom-1': [
      new Date(Dayjs().weekday(6).valueOf()),
      new Date(Dayjs().weekday(5).valueOf()),
      new Date(Dayjs().weekday(4).valueOf()),
      new Date(Dayjs().weekday(3).valueOf()),
      new Date(Dayjs().weekday(2).valueOf()),
      new Date(Dayjs().weekday(1).valueOf()),
      new Date(Dayjs().weekday(0).valueOf()),
    ],
  },
];

interface ICalendarDatePicker {
  margin?: boolean;
  changeColor?: boolean;
  color?: string;
  selectsRange?: boolean;
  startDate: Date;
  endDate: Date | null;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date | null) => void;
  setIsChanged: (changed: boolean) => void;
  minDate?: Date;
}

export default function CalendarRange({
  margin,
  changeColor,
  color,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setIsChanged,
  minDate,
}: ICalendarDatePicker): JSX.Element {
  const classes = useStyles({ changeColor, color });
  const { t } = useTranslation(); 
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsChanged(true);
  };
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];
  return (
    <Box className={classes.datePicker}>
      <DatePicker
        highlightDates={hightLightWithRanges as any}
        inline
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        minDate={minDate}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: any) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            width="100%"
            mt={margin && 2}
          >
            <div
              style={{
                marginBottom: '10px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                alignContent: 'stretch',
              }}
            >
              <IconButton
                className={classes.nextButton}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </IconButton>

              <Typography className={classes.DateText}>{`${
                months[Dayjs(date).get('month')]
              } ${Dayjs(date).get('year')}`}</Typography>
              <IconButton
                className={classes.nextButton}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </IconButton>
            </div>
          </Box>
        )}
      />
    </Box>
  );
}
