import { Grid } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import Conversation from 'pages/InboxPage/components/Conversation/Conversation';
import Message from 'pages/InboxPage/components/Message';
import Modals from 'pages/InboxPage/modals';
import useStyles from 'pages/InboxPage/style';
import { useProvider } from 'pages/InboxPage/state/Provider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import { useTranslation } from 'react-i18next';

const PatientInboxPage = (): ReactElement => {
  const classes = useStyles();
  const { setMessageHidden, messageHidden } = useProvider();
  const { patient } = useAuth();
  const isPatient = !!patient;
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Messaging') });
  }, [headerTitleDispatcher]);
  return (
    <div className={classes.containerPatient}>
      <Modals isPatient={isPatient} />
      <Grid container className={classes.fullHeight}>
        <Grid
          item
          xl={3}
          lg={3}
          md={5}
          xs={12}
          sm={12}
          className={messageHidden ? classes.fullHeight : classes.hidden}
        >
          <Conversation />
        </Grid>
        <Grid
          item
          xl={9}
          lg={9}
          md={7}
          xs={12}
          sm={12}
          className={messageHidden ? classes.hidden : classes.fullHeight}
        >
          <Message patient={isPatient} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientInboxPage;
