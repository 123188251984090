import { MutationResult, useMutation } from '@apollo/client';
import { EDIT_EVENT } from '../../queries/appointment';

export default function useEditEvent() {
  const [editEvent, result] = useMutation(EDIT_EVENT, {
    update: (cache, { data: { editEvent: editEventResult } }) => {
      cache.modify({
        fields: {
          eventsByDate(events) {
            const editedEvents = events?.result?.map((event: any) => {
              if (event?._id === editEventResult?._id) return editEventResult;
              return events;
            });
            return { ...events, result: editedEvents };
          },
          conflictsAppointments(events) {
            return [...events, editEventResult];
          },
          availableTimes(times) {
            const startDate = new Date(editEventResult?.startDate);
            const endDate = new Date(editEventResult?.endDate);

            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: editEventResult?.doctor?.timeZone,
            });

            const doctorFormattedDateStart = doctorFormatter.format(startDate);
            const doctorFormattedDateEnd = doctorFormatter.format(endDate);

            if (editEventResult?.isImpact) {
              const existsBeforeStart = times?.some(
                (el) => el < doctorFormattedDateStart
              );
              const existsAfterEnd = times?.some(
                (el) => el > doctorFormattedDateEnd
              );

              if (!existsBeforeStart) {
                return times?.filter((el) => el > doctorFormattedDateEnd);
              } else if (!existsAfterEnd) {
                return times?.filter((el) => el < doctorFormattedDateStart);
              } else {
                return times?.filter(
                  (el) =>
                    el < doctorFormattedDateStart || el > doctorFormattedDateEnd
                );
              }
            } else {
              return times;
            }
          },
        },
      });
    },
  });
  const [editEventWithoutRender] = useMutation(EDIT_EVENT, {
    ignoreResults: true,
  });
  async function editEventWithoutUpdate(
    data: any,
    id: string,
    isOne: boolean,
    sideEffectCallback: (e: Error) => void,
    successCallback?: () => void
  ) {
    try {
      const resultEditEvent = await editEventWithoutRender({
        variables: { eventInput: data, eventId: id, isOne },
      });
      successCallback && successCallback();
      return resultEditEvent;
    } catch (e) {
      return sideEffectCallback(e as Error);
    }
  }
  async function updateEvent(
    data: any,
    id: string,
    isOne: boolean,
    sideEffectCallback: (e: Error) => void
  ) {
    try {
      const resultEditEvent = await editEvent({
        variables: { eventInput: data, eventId: id, isOne },
      });

      return resultEditEvent;
    } catch (e) {
      return sideEffectCallback(e as Error);
    }
  }

  return {
    updateEvent,
    editEventWithoutUpdate,
    ...result,
  };
}
