import React, { useRef } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  BoxProps,
  IconButton,
} from '@material-ui/core';
import { formatToFullDate } from 'utils';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/tasks/addIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #BCC6D3',
    padding: theme.spacing(0.7),
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      border: '1px solid #BCC6D3',
    },
  },
  sideActions: {
    marginLeft: 'auto',
    padding: '5px',
  },
  folderIcon: {
    fontSize: '30px',
    marginRight: theme.spacing(2),
    backgroundColor: '#E4ECF7',
    padding: theme.spacing(0.5),
    borderRadius: '8px',
    color: '#8ca7ce',
  },
  fileName: {
    color: '#707090',
  },
  date: {
    fontSize: '12px',
  },
  addIcon: {
    color: '#C9C9C9',
    // fontSize: '50px',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    zIndex: -1,
  },
}));

interface IAttachmentInputProps extends BoxProps {
  handleAction?: (e: any) => void;
  name: string;
  createdAt: Date;
  isActionable?: boolean;
  time?: boolean;
  custormIcon?: React.ReactNode;
}

export default function AttachmentInput({
  handleAction,
  name,
  isActionable = false,
  createdAt,
  time = true,
  custormIcon,
  ...props
}: IAttachmentInputProps) {
  const classes = useStyles();

  return (
    <label htmlFor="raised-button-file">
      <Box className={classes.root} {...props}>
        <Box ml={2} display="flex" alignItems="center">
          {custormIcon ? (
            custormIcon
          ) : (
            <FolderOpenIcon className={classes.folderIcon} />
          )}
          <Box display="flex" flexDirection="column">
            <Typography className={classes.fileName}>{name}</Typography>
            {time && (
              <Typography className={classes.date} color="textSecondary">
                {formatToFullDate(createdAt.toString())}
              </Typography>
            )}
          </Box>
        </Box>
        {isActionable && (
          <Box className={classes.sideActions}>
            {/* <IconButton className={classes.sideActions}> */}
            <AddCircleOutlineIcon className={classes.addIcon} />
            {/* </IconButton> */}
            <input
              id="raised-button-file"
              type="file"
              onChange={handleAction}
              style={{ display: 'none' }}
            />
          </Box>
        )}
      </Box>
    </label>
  );
}
