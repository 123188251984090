import { log, config } from 'config';
import MediaDevice from '../Media/MediaDevice';
import {
  setupReceiverTransform,
  setupSenderTransform,
} from '../mediaTransformers';
import { checkInsertableSupport } from '../navigatorSupportCheck';

const PC_CONFIG = {
  iceServers: [
    {
      urls: [
        'stun:stun.l.google.com:19302',
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
        'stun:stun3.l.google.com:19302',
        'stun:stun4.l.google.com:19302',
        'stun:stun.ekiga.net',
        'stun:stun.ideasip.com',
        'stun:stun.rixtelecom.se',
        'stun:stun.schlund.de',
        'stun:stun.stunprotocol.org:3478',
        'stun:stun.voiparound.com',
        'stun:stun.voipbuster.com',
        'stun:stun.voipstunt.com',
        'stun:stun.voxgratia.org',
      ],
    },
    {
      urls: `${config.URLS_STUN}`,
    },
    {
      urls: `${config.URLS_TURN}`,
      username: `${config.USERNAME}`,
      credential: `${config.CREDENTIAL}`,
    },
  ],
  encodedInsertableStreams: checkInsertableSupport(),
};
let preferredVideoCodecMimeType: any = undefined;

type RTCSignalingState =
  | 'stable'
  | 'have-local-offer'
  | 'have-remote-offer'
  | 'have-local-pranswer'
  | 'have-remote-pranswer'
  | 'closed';

export default class PeerConnection {
  pc: RTCPeerConnection | null;

  mediaDevice: MediaDevice;

  makingOffer = false;

  isSettingRemoteAnswerPending = false;

  friendID: string;

  onLocalStreamReceived: any;

  handleSendIceCandidate: any;
  onRemoteStreamReceived: any;
  state = 'new';

  handleSendDescription: (
    localDesc: RTCSessionDescriptionInit | null | undefined
  ) => void;

  /**
   * Creates an instance of PeerConnection.
   * @param {string} friendId
   * @param {void} onLocalStreamReceived
   * @param {void} onRemoteStreamReceived
   * @param {*} handleSendIceCandidate
   * @param {((
   *       localDesc: RTCSessionDescriptionInit | null | undefined
   *     ) => void)} handleSendDescription
   * @memberof PeerConnection
   */
  constructor(
    friendId: string,
    onLocalStreamReceived: any,
    onRemoteStreamReceived: any,
    handleSendIceCandidate: any,
    handleSendDescription: (
      localDesc: RTCSessionDescriptionInit | null | undefined
    ) => void
  ) {
    this.mediaDevice = new MediaDevice();
    this.friendID = friendId;
    this.onLocalStreamReceived = onLocalStreamReceived;
    this.handleSendIceCandidate = handleSendIceCandidate;
    this.handleSendDescription = handleSendDescription;
    this.onRemoteStreamReceived = onRemoteStreamReceived;

    this.pc = new RTCPeerConnection(PC_CONFIG);
    this.initializePeerConnection();
  }

  initializePeerConnection() {
    this.pc.onnegotiationneeded = async () => {
      try {
        this.makingOffer = true;
        this.createOffer();
      } catch (err) {
        log('error', 'Error while negotiating offer', err);
      } finally {
        this.makingOffer = false;
      }
    };
    this.pc.oniceconnectionstatechange = () => {
      log('Ice state changed', this?.pc?.iceConnectionState);
      if (this.pc && this.pc.iceConnectionState === 'failed') {
        (this.pc as any).restartIce();
        this.restartConnection();
      }
      if (this.pc && this.pc.iceConnectionState === 'disconnected') {
        // logic when connection is disconnected
      }
    };
    this.pc.onicecandidate = (event) => {
      log('Ice candidate event', event);
      if (event.candidate) {
        this.handleSendIceCandidate({
          type: 'candidate',
          candidate: event.candidate,
        });
      }
    };
    this.pc.ontrack = (event) => {
      setupReceiverTransform(event.receiver);
      this.onRemoteStreamReceived(event.streams[0]);
    };
  }

  restartConnection() {
    this.stop(false); // Close the existing connection

    // Reinitialize the peer connection
    this.pc = new RTCPeerConnection(PC_CONFIG);

    this.initializePeerConnection();

    // Start the new connection
    this.start();
  }

  getUserId() {
    return this.friendID;
  }

  setUserId(userId: string) {
    this.friendID = userId;
  }

  getOfferStatus() {
    return this.makingOffer;
  }

  getRemoteAnswerStatus() {
    return this.isSettingRemoteAnswerPending;
  }

  setRemoteAnswerStatus(status: boolean) {
    this.isSettingRemoteAnswerPending = status;
  }

  /**
   * Starting the call and media devices
   * @param {Boolean} isCaller
   * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
   */
  start() {
    this.mediaDevice.start((stream: MediaStream, isReplace = false) => {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        this.pc?.addTrack(track, stream);
      });
      this.onLocalStreamReceived(stream);
      if (!isReplace) {
        this.pc?.getSenders().forEach(setupSenderTransform);
        this.handleTransceiver(stream);
      }
    });

    return this;
  }

  /**
   *
   * Change the Stream to be Encrypted
   * @param {*} stream
   * @memberof PeerConnection
   */
  handleTransceiver(stream: any) {
    if (preferredVideoCodecMimeType) {
      const { codecs }: any = RTCRtpSender.getCapabilities('video');
      const selectedCodecIndex = codecs.findIndex(
        (c: any) => c.mimeType === preferredVideoCodecMimeType
      );
      const selectedCodec = codecs[selectedCodecIndex];
      codecs.splice(selectedCodecIndex, 1);
      codecs.unshift(selectedCodec);
      const transceiver: any = this.pc
        ?.getTransceivers()
        .find(
          (t: any) => t.sender && t.sender.track === stream.getVideoTracks()[0]
        );
      transceiver.setCodecPreferences(codecs);
    }
  }

  // Method to trigger renegotiation
  async renegotiate() {
    if (this.pc.signalingState !== 'stable') return;
    try {
      this.makingOffer = true;
      const offer = await this.pc.createOffer();
      await this.pc.setLocalDescription(offer);
      this.handleSendDescription(this.pc.localDescription);
    } finally {
      this.makingOffer = false;
    }
  }

  handleMute() {
    this.mediaDevice.toggle('Audio', null, (newStream: MediaStream) => {
      // Replace the video track in the existing peer connection
      const audioTrack = newStream.getAudioTracks()[0];
      const sender = this.pc.getSenders().find((s) => s.track.kind === 'audio');
      if (sender) {
        sender.replaceTrack(audioTrack);
      }
      this.onLocalStreamReceived(newStream);
      this.renegotiate();
    });
  }

  handleToggleVideo() {
    this.mediaDevice.toggle('Video', null, (newStream: MediaStream) => {
      // Replace the video track in the existing peer connection
      const videoTrack = newStream.getVideoTracks()[0];
      const sender = this.pc.getSenders().find((s) => s.track.kind === 'video');
      if (sender) {
        sender.replaceTrack(videoTrack);
      }
      this.onLocalStreamReceived(newStream);
      this.renegotiate();
    });
  }

  /**
   * Stop the call
   * @param {Boolean} isStarter
   */
  stop(isStarter: any) {
    if (this.mediaDevice.stream) {
      this.mediaDevice.stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
      this.mediaDevice.stream = null;
      this.mediaDevice = null;
    }
    this.mediaDevice.stop();
    this?.pc?.close();
    this.pc = null;
    this.state = 'closed';
    return this;
  }

  createOffer() {
    this.pc
      ?.createOffer()
      .then(this.getDescription.bind(this))
      .catch((err: any) => log('error', 'Error on offer creating', err));
    return this;
  }

  /**
   * Create an answer and send it
   *
   * @return {*}
   * @memberof PeerConnection
   */
  createAnswer() {
    this.pc
      ?.createAnswer()
      .then((value) => this.getDescription(value))
      .catch((err: any) => log('error', 'Error while Answer creating', err));
    return this;
  }

  /**
   *
   *
   * @param {(RTCSessionDescriptionInit | null | undefined)} desc
   * @memberof PeerConnection
   */
  sendDescription(desc: RTCSessionDescriptionInit | null | undefined) {
    const localDes = desc || this.pc?.localDescription;
    this.handleSendDescription(localDes);
  }

  /**
   *
   *
   * @param {(RTCSessionDescriptionInit | null | undefined)} desc
   * @return {*}
   * @memberof PeerConnection
   */
  getDescription(desc: RTCSessionDescriptionInit | null | undefined) {
    this.pc
      ?.setLocalDescription(desc as any)
      .catch((err) =>
        log('error', 'Error while setting local description', err)
      );
    this.sendDescription(desc);
    return this;
  }

  /**
   *
   *
   * @param {RTCSessionDescriptionInit} sdp
   * @return {*}
   * @memberof PeerConnection
   */
  setRemoteDescription(sdp: RTCSessionDescriptionInit) {
    const rtcSdp = new RTCSessionDescription(sdp);
    this.pc?.setRemoteDescription(rtcSdp);
    return this;
  }

  /**
   *
   * Returns the current peer connection state
   * @return {RTCSignalingState}  {RTCSignalingState}
   * @memberof PeerConnection
   */
  getSignalingState(): RTCSignalingState {
    return this.pc!.signalingState;
  }

  /**
   *
   * Adds an ice candidate
   * @param {(RTCIceCandidateInit | undefined)} candidate
   * @return {*}
   * @memberof PeerConnection
   */
  addIceCandidate(candidate: RTCIceCandidateInit | undefined) {
    // if (candidate) {
    const iceCandidate = new RTCIceCandidate(candidate);
    this?.pc
      ?.addIceCandidate(iceCandidate)
      .catch((err) => log('error', 'Error while adding ice candidate', err));
    // }
    return this;
  }
}
