import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  title: string;
  pricing: string;
  text: string;
  onClick: (type: string, amount: number) => void;
  type: string;
  amount: number;
}

function Card({ title, pricing, text, onClick, type, amount }: Props) {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.card}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.pricing}>{pricing}</Typography>
        <Typography className={classes.text}>{text}</Typography>
      </Box>
      <Button
        className={classes.subButton}
        onClick={() => onClick(type, amount)}
      >
        Get started
      </Button>
    </Box>
  );
}

export default Card;
