import {
  OPEN_PRESCRIPTION_ADD,
  OPEN_PRESCRIPTION_DRUG_ADD,
  CLOSE_PRESCRIPTION_ADD,
  OPEN_LAB_ADD,
  OPEN_REFERRAL_ADD,
  CLOSE_REFERRAL_ADD,
  OPEN_CONSULTATION_ADD,
  EDIT_REFERRAL_MODE,
  EDIT_CONSULTATION_MODE,
  DELETE_REFERRAL_MODE,
  CLOSE_DELETE_MODAL,
  CHANGE_TAB,
  DELETE_PRESCRIPTION_DRUG_MODE,
  EDIT_PRESCRIPTION_DRUG_MODE,
  EDIT_LAB_MODE,
  OPEN_IMAGING_ADD,
  EDIT_IMAGING_MODE,
  DELETE_IMAGING_MODE,
} from './actions';

function useLocalStateDispatcher(dispatch: any) {
  function handleOpenAddPrescription() {
    dispatch({ type: OPEN_PRESCRIPTION_ADD });
  }
  function handleOpenAddPrescriptionDrug() {
    dispatch({ type: OPEN_PRESCRIPTION_DRUG_ADD });
  }
  function handleCloseAddPrescription() {
    dispatch({ type: CLOSE_PRESCRIPTION_ADD });
  }
  function handleOpenAddLab() {
    dispatch({ type: OPEN_LAB_ADD });
  }
  function handleOpenAddReferral() {
    dispatch({ type: OPEN_REFERRAL_ADD });
  }
  function handleCloseAddReferral() {
    dispatch({ type: CLOSE_REFERRAL_ADD });
  }
  function handleOpenConsultationModal() {
    dispatch({ type: OPEN_CONSULTATION_ADD });
  }
  function handleOpenImagingModal() {
    dispatch({ type: OPEN_IMAGING_ADD });
  }

  function handleEditReferralModal(referral: any) {
    dispatch({ type: EDIT_REFERRAL_MODE, payload: referral });
  }
  function handleEditConsultationModal() {
    dispatch({ type: EDIT_CONSULTATION_MODE });
  }
  function handleEditImagingModal(imaging: any) {
    dispatch({ type: EDIT_IMAGING_MODE, payload: imaging });
  }
  function handleOpenDeleteReferralModal(referral: any) {
    dispatch({ type: DELETE_REFERRAL_MODE, payload: referral });
  }
  function handleOpenDeleteImagingModal(imaging: any) {
    dispatch({ type: DELETE_IMAGING_MODE, payload: imaging });
  }
  function handleCloseDeleteModal() {
    dispatch({ type: CLOSE_DELETE_MODAL });
  }
  const handleChangeCurrentTab = (event: unknown, newValue: number) => {
    dispatch({ type: CHANGE_TAB, payload: newValue });
  };
  function handleOpenDeletePrescriptionDrugModal(prescriptionDrug: any) {
    dispatch({
      type: DELETE_PRESCRIPTION_DRUG_MODE,
      payload: prescriptionDrug,
    });
  }
  async function handleOpenEditLabRequest(labRequest: any) {
    dispatch({ type: EDIT_LAB_MODE, payload: labRequest });
  }
  async function handleOpenEditPrescriptionDrug(prescriptionDrug: any) {
    dispatch({ type: EDIT_PRESCRIPTION_DRUG_MODE, payload: prescriptionDrug });
  }
  return {
    handleCloseDeleteModal,
    handleOpenDeleteReferralModal,
    handleEditConsultationModal,
    handleEditReferralModal,
    handleOpenConsultationModal,
    handleCloseAddReferral,
    handleOpenAddReferral,
    handleOpenAddLab,
    handleCloseAddPrescription,
    handleOpenAddPrescriptionDrug,
    handleOpenAddPrescription,
    handleChangeCurrentTab,
    handleOpenDeletePrescriptionDrugModal,
    handleOpenEditLabRequest,
    handleOpenEditPrescriptionDrug,
    handleOpenImagingModal,
    handleEditImagingModal,
    handleOpenDeleteImagingModal,
  };
}

export default useLocalStateDispatcher;
