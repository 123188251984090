import React, { ReactElement } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import clsx from 'clsx';
import { fromNow } from 'utils';
import useDeletePatientRequest from '@eggmed/graphql-client/operations/patientRequestOperations/useDeletePatientRequest';
import useAddDoctorAccess from '@eggmed/graphql-client/operations/patientAccessControlOperations/useAddDoctorAccess';
import useStyles from 'pages/PatientNotificationPage/components/styles';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { permissions } from './Permissions';

interface IElements {
  id: string;
  title: string;
  content: string;
  date: string;
  read: boolean;
  type: string;
  doctorId: string;
  avatar: string;
  avatarAlt: string;
  onClick: () => void;
}
interface INotificationCardProps {
  element: IElements;
}

const AccessCard = ({ element }: INotificationCardProps): ReactElement => {
  const { user, patient } = useAuth();
  const IsPatient = !!patient;
  const classes = useStyles({ patient: IsPatient });
  const date = new Date(parseInt(element?.date)).toString();
  const [cardState, setCardState] = React.useState(null);
  const { handleAddDoctorAccess } = useAddDoctorAccess();
  const { handleDeletePatientRequest } = useDeletePatientRequest();
  const acceptRequest = () => {
    setCardState(true);
    handleAddDoctorAccess({
      doctor: element?.doctorId,
      permissions,
      requestId: element?.id,
    });
  };
  const cancelRequest = () => {
    setCardState(false);
    handleDeletePatientRequest(element?.id);
  };
  return (
    <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
      <Box className={classes.Card} onClick={element?.onClick}>
        <Grid
          container
          xs={12}
          lg={12}
          xl={12}
          md={12}
          sm={12}
          alignItems="flex-start"
          wrap="nowrap"
          justifyContent="space-between"
        >
          <Grid
            container
            xs={2}
            lg={1}
            xl={1}
            md={2}
            sm={1}
            alignItems="center"
            wrap="nowrap"
            className={classes.leftSide}
          >
            <Box className={classes.readBox}>
              {!element?.read && <div className={classes.read} />}
            </Box>

            <Avatar
              src={element?.avatar ? element?.avatar : null}
              className={classes.cardIcon}
            >
              {element?.avatarAlt}
            </Avatar>
          </Grid>
          <Grid
            item
            xs={10}
            xl={11}
            lg={12}
            md={10}
            sm={11}
            className={classes.Middle}
            alignItems="flex-start"
          >
            <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="baseline" style={{ gap: '6px' }}>
                <Typography className={classes.cardTitle}>
                  {element?.title}
                </Typography>
                <Typography className={classes.date}>
                  {fromNow(date)}
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={11} lg={11} xl={11} md={11} sm={11}>
              <Typography className={classes.notificationContent}>
                {element?.content}
              </Typography>
              {cardState === true ? (
                <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                  <Typography className={classes.actionMessage}>
                    You have accepted this request
                  </Typography>
                </Grid>
              ) : cardState === false ? (
                <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
                  <Typography className={classes.actionMessage}>
                    You have declined this request
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  className={classes.buttonGrid}
                >
                  <button
                    className={clsx(
                      classes.AccessCardButton,
                      classes.AccessMainButton
                    )}
                    onClick={acceptRequest}
                  >
                    Accept
                  </button>

                  <button
                    className={clsx(
                      classes.AccessCardButton,
                      classes.cancelButton
                    )}
                    onClick={cancelRequest}
                  >
                    Cancel
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AccessCard;
