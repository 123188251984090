import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as NotFoundIllustrator } from 'assets/illustrations/404.svg';

const useStyles = makeStyles((theme) => ({
  notFoundText: {
    paddingTop: theme.spacing(2),
    color: theme.palette.black.dark,
    fontSize: theme.typography.pxToRem(20),
  },
}));

export function NotFoundIllustration(): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> {
  const classes = useStyles();
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center" display="flex" flexDirection="column">
        <NotFoundIllustrator />
        <Typography className={classes.notFoundText}>Page not found</Typography>
      </Box>
    </Box>
  );
}
