import { gql, useMutation } from '@apollo/client';

const DELETE_IMAGING = gql`
  mutation deleteImaging($imagingId: ID!) {
    deleteImaging(imagingId: $imagingId) {
      doctor
    }
  }
`;

export default function useDeleteImaging() {
  const [deleteImaging, result] = useMutation(DELETE_IMAGING, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentImaging() {
            return data?.deleteImaging;
          },
        },
      });
    },
  });
  return { deleteImaging, ...result };
}
