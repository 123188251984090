import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as FrequencyIcon } from 'assets/patient-profile/response/frequency.svg';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

interface FrequencyResultProps {
  endDate?: string;
  repetition?: any;
}

export const FrequencyResult = ({
  endDate,
  repetition,
}: FrequencyResultProps) => {
  const classes = useStyles({});
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  return (
    <Box className={clsx(classes.freqSummary, classes.frequencyResult)}>
      <Box>
        <FrequencyIcon />
      </Box>
      <Box>
        <Typography className={classes.freqTitle}>{t('Frequency')}</Typography>
        <Typography className={classes.responseHeaderDate}>
          {t('Repeat every')}{' '}
          <span className={classes.freqTitle}>
            {repetition?.value} {t(repetition?.unit)}
          </span>
          - {t('end on')}{' '}
          <span className={classes.freqTitle}>
            {dayjs(new Date(endDate)).format('D MMM YYYY')}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
