import { createContext } from 'react';
import { IAction } from 'shared';
import {
  IInboxPageContext,
  IModalType,
} from 'shared/interfaces/inbox.interface';
import {
  CHANGE_STATUS_EMPTY_CONVERSATION_LIST,
  CLOSE_MODAL_CREATE_NEW_THEAD,
  OPEN_MODAL_CREATE_NEW_THEAD,
  SELECTED_THREAD_ACTION,
} from './action';

export const Context = createContext<IInboxPageContext | undefined>(undefined);

export function reducer(state: IInboxPageContext, action: IAction): any {
  switch (action.type) {
    case OPEN_MODAL_CREATE_NEW_THEAD: {
      return { ...state, modal: true, modalType: IModalType.createThead };
    }
    case CLOSE_MODAL_CREATE_NEW_THEAD: {
      return { ...state, modal: false, modalType: IModalType.createThead };
    }
    case SELECTED_THREAD_ACTION: {
      return { ...state, selected: action.payload };
    }
    case CHANGE_STATUS_EMPTY_CONVERSATION_LIST: {
      return { ...state, isEmptyConversationList: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}
