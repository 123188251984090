import React, { ReactElement } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { Editor } from 'draft-js';
import {
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import { ReactComponent as PatientIcon } from 'assets/patienttype.svg';
import { ReactComponent as VideotIcon } from 'assets/video.svg';
import { ReactComponent as AboutDoctorIcon } from 'assets/abourDoctor.svg';
import { IDoctor } from './PersonalInformation';
import FocusArea from './FocusArea';
import { TypeEnum } from './DoctorProfile';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyClient } from 'assets/emptyState/about.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IAboutProps {
  doctor: IDoctor;
  setOpen: (value: TypeEnum) => void;
  isPatient: boolean;
  isEmpty: boolean;
  loading?: boolean;
  handleOpen: () => void;
}

const About = ({
  doctor,
  setOpen,
  isPatient,
  isEmpty,
  loading,
  handleOpen,
}: IAboutProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles({ isPatient });
  const { about, focusArea, patientGroup, supportVideo, firstname, lastname } =
    doctor || {};
  const hasAbout = convertStringToEditorState(about)
    .getCurrentContent()
    .hasText();
  return (
    <Box className={classes.personalInfo}>
      <Box
        style={{ padding: '24px 25px 25px 25px', boxSizing: 'border-box' }}
        height="100%"
      >
        <Box height="100%">
          <Skeleton loading={loading} type="text" width="5rem" height="2.5rem">
            <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
              <AboutDoctorIcon />
              <Text i18nKey="aboutMe" className={classes.cardtitle}>
                About
              </Text>
            </Box>
          </Skeleton>
          {isEmpty ? (
            <Box
              height="100%"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <EmptyState
                icon={<EmptyClient />}
                title={t('No information yet')}
                text={
                  isPatient
                    ? `${firstname} ${lastname} ${t(
                        'have yet to add their info'
                      )}`
                    : t('to add more about yourself')
                }
                onClick={handleOpen}
                isText={isPatient ? false : true}
                noActionText={isPatient ? true : false}
                hasAction={isPatient ? false : true}
              />
            </Box>
          ) : (
            <Box>
              <Skeleton
                type="text"
                loading={loading}
                width="100%"
                height="1.5rem"
              >
                {hasAbout && (
                  <Box className={classes.info}>
                    <Typography className={classes.aboutText}>
                      {!tryParseJSON(about) ? (
                        '-'
                      ) : (
                        <Editor
                          onChange={() => {}}
                          editorState={editorStateFromStringifiedRaw(
                            about,
                            false
                          )}
                          readOnly
                        />
                      )}
                    </Typography>
                  </Box>
                )}
              </Skeleton>
              <Box
                marginTop="2rem"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                style={{ gap: '10px', flexWrap: 'wrap' }}
              >
                {supportVideo && (
                  <Box
                    className={classes.focusArea}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Skeleton
                      type="rect"
                      loading={loading}
                      width="100px"
                      height="1.5rem"
                    >
                      <VideotIcon />
                      <Typography className={classes.aboutTitle}>
                        {t('Video call')}
                      </Typography>
                    </Skeleton>
                  </Box>
                )}
                <Skeleton
                  type="rect"
                  loading={loading}
                  width="100px"
                  height="1.5rem"
                >
                  {patientGroup?.length > 0 && (
                    <Box
                      className={classes.focusArea}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <PatientIcon />
                      <Typography className={classes.aboutTitle}>
                        {patientGroup &&
                          patientGroup.map((group) => t(group)).join(', ')}
                      </Typography>
                    </Box>
                  )}
                </Skeleton>
              </Box>
              {(focusArea?.length > 0 || loading) && (
                <FocusArea focusArea={focusArea} loading={loading} />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default About;
