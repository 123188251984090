import React, { ReactElement } from 'react';
import {
  Grid,
  Box,
  Typography,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import useStyles from '../styles';
import ScrollBar from 'components/ui/ScrollBar';
import InputBlock from '../../StaffProfile/editModalTabs/InputBlock';
import { Controller, FieldErrors, Control, Ref } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { langOptions } from '../constants';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IgeneralProps {
  register?: (ref?: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  showBirthday?: boolean;
  birthday?: string;
}

const General = ({
  register,
  control,
  errors,
  showBirthday,
  birthday,
}: IgeneralProps): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const { t } = useTranslation();

  const commonProps = { register, control, errors };
  return (
    <ScrollBar sx={{ flex: 1 }}>
      <Box style={{ paddingInline: '2rem', boxSizing: 'border-box' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="First name"
                name="firstname"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Middle name"
                name="middlename"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Last name"
                name="lastname"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Display name"
                name="displayName"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          {/*  */}
          {/*  */}
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Gender"
                name="gender"
                type="select"
                options={['male', 'female', 'other', 'prefer not to say']}
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Pronouns"
                name="pronouns"
                type="select"
                options={['he/him', 'she/her', 'they/them', 'other']}
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Specialty"
                name="specialty"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginBlock: '25px' }}>
              <InputBlock
                label="Experience (in years)"
                name="experience"
                type="number"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text i18nKey="dateOfBirth" className={classes.label}>
                Date of birth
              </Text>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  inputRef={register}
                  name="showBirthday"
                  size="medium"
                  defaultChecked={showBirthday}
                  checkedIcon={<CheckedIcon />}
                  icon={<UncheckedIcon />}
                  style={{ backgroundColor: 'transparent' }}
                  disabled
                />
                <Text i18nKey="visibleOnProfile" className={classes.label}>
                  Visible on profile
                </Text>
              </Box>
            </Box>
            <FormControl style={{ width: '100%' }}>
              <InputBlock name="birthday" type="date" {...commonProps} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginBlock: '25px' }}>
              <Text
                i18nKey="spokenLanguages"
                className={classes.label}
                style={{ marginBottom: '10px' }}
              >
                Spoken languages
              </Text>
              <Controller
                name="languages"
                render={({ onChange, value }) => {
                  return (
                    <Symptoms
                      options={langOptions}
                      values={value}
                      setValues={onChange}
                    />
                  );
                }}
                {...commonProps}
              />
              <Box alignSelf="flex-start" mb={1}>
                {errors && errors.languages && (
                  <span data-cy="error-message" className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.languages?.message}
                  </span>
                )}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </ScrollBar>
  );
};
export default General;
