import { Chip, ChipProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export const color = {
  high: 'red',
  completed: 'rgb(45, 199, 109)',
  Done: 'rgb(45, 199, 109)',
  overdue: 'rgb(254, 68, 63)',
  'To do': 'rgb(254, 68, 63)',
  low: '#FEC25A',
  default: 'rgb(247, 147, 30)',
};
const useStyles = makeStyles(() => ({
  chip: {
    color: 'white',
    fontSize: '14px',
    backgroundColor: ({ priority }: IPriorityChipProps) =>
      color[priority] ?? color.default,
    fontWeight: 500,
  },
}));

interface IPriorityChipProps extends ChipProps {
  priority: 'high' | string;
}
export default function PriorityChip({
  priority,
  className,
  ...props
}: IPriorityChipProps): JSX.Element {
  const classes = useStyles({ priority });
  const { t } = useTranslation();
  return (
    <Chip
      className={clsx(className, classes.chip)}
      label={
        priority === 'high'
          ? t('High')
          : priority === null
          ? t('Low')
          : t(priority)
      }
      {...props}
    />
  );
}
