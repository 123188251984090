import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';

const CHANGE_CAMERA_STATE_MUTATION = gql`
  mutation changeCameraState(
    $userId: String!
    $roomId: String!
    $state: String!
  ) {
    changeCameraState(userId: $userId, roomId: $roomId, state: $state)
  }
`;

const useChangeCameraState = () => {
  const [changeCameraState, result] = useMutation<unknown, any>(
    CHANGE_CAMERA_STATE_MUTATION
  );

  async function handleChangeCameraState(
    userId: string,
    roomId: string,
    state: 'established' | 'initial'
  ) {
    try {
      return await changeCameraState({
        variables: {
          userId,
          roomId,
          state,
        },
      });
    } catch (e) {
      return log('error', e);
    }
  }

  return { handleChangeCameraState, ...result };
};

export default useChangeCameraState;
