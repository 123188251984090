/**
 * Manage all media devices
 */

let streams = {};

class MediaDevice {
  stream: MediaStream;
  /**
   * Start media devices and send stream
   */
  isTesting: boolean;
  isVideoOff = false;
  addToLocalStream: any;
  isMute = false;

  start(addToLocalStream: any, isVideo = true, isTesting = false) {
    this.addToLocalStream = addToLocalStream;
    this.isTesting = isTesting;
    const constraints = {
      video: isVideo
        ? {
            facingMode: 'user',
            height: { min: 360, ideal: 720, max: 1080 },
          }
        : false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.stream = stream;
        streams[stream.id] = { stream: stream, isTesting };
        addToLocalStream(stream);
      })
      .catch((err) => {
        if (err instanceof DOMException) {
          // alert('Cannot open webcam and/or microphone');
          this.start(addToLocalStream, false);
        } else {
          console.log(err);
        }
      });
    return this;
  }

  stopTracks(type: 'Audio' | 'Video', stream: MediaStream) {
    if (type === 'Audio') this.stopAudioTrack(stream);
    if (type === 'Video') this.stopVideoTrack(stream);
  }

  /**
   * Turn on/off a device
   * @param {String} type - Type of the device
   * @param {Boolean} [on] - State of the device
   */
  toggle(type: 'Audio' | 'Video', on?: any, callback?: any) {
    const len = arguments.length;
    const isVideo = type === 'Video';
    const isAudio = type === 'Audio';
    if (this.stream) {
      const tracks = this.stream[`get${type}Tracks`]();
      if (tracks.length === 0) {
        if (isVideo) {
          this.isVideoOff = !this.isVideoOff;
        }

        if (isAudio) {
          this.isMute = !this.isMute;
        }

        this.startNewTrack({
          type,
          isTrackActive: false,
          audio: !this.isMute,
          video: !this.isVideoOff,
          callback,
        });
      }
      tracks.forEach((track: any) => {
        const isTrackActive = len === 2 ? on : track.enabled;

        if (isTrackActive) {
          this.stopTracks(type, this.stream);
          Object.values(streams).forEach(
            (_stream: { stream: MediaStream; isTesting: boolean }) => {
              this.stopTracks(type, _stream.stream);
            }
          );
        }
        const shouldStartNewTrack = this.isVideoOff || this.isMute;

        if (isVideo) {
          this.isVideoOff = isTrackActive;
        }

        if (isAudio) {
          this.isMute = isTrackActive;
        }

        if (shouldStartNewTrack && track.readyState === 'ended') {
          this.startNewTrack({
            type,
            isTrackActive,
            audio: !this.isMute,
            video: !this.isVideoOff,
            callback: callback,
          });
        }
        track.enabled = !track.enabled;
      });
    }
    return this;
  }

  /**
   * Start a new track of the specified type (video or audio)
   * @param {String} type - Type of the device (e.g., 'Video', 'Audio')
   */
  startNewTrack({
    type,
    isTrackActive,
    audio,
    video,
    callback,
  }: {
    type: string;
    isTrackActive: boolean;
    audio: boolean;
    video: boolean;
    callback?: any;
  }) {
    if (isTrackActive) return;

    const constraints = {
      video: video
        ? { facingMode: 'user', height: { min: 360, ideal: 720, max: 1080 } }
        : false,
      audio: audio ? true : false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((newStream) => {
        streams[newStream.id] = { stream: newStream, isTesting: false };
        this.stream = newStream;
        if (callback) {
          callback(newStream);
        } else {
          this.addToLocalStream(this.stream, false);
        }
      })
      .catch((err) => {
        console.log('Error starting new track:', err);
      });
  }

  stopStream(stream: MediaStream) {
    if (stream) {
      const tracks = stream.getTracks();

      this.stopVideoTrack(stream);
      this.stopAudioTrack(stream);

      tracks[0].stop();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  }
  stopVideoTrack(stream: MediaStream) {
    if (stream) {
      const tracks = stream.getVideoTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  }

  stopAudioTrack(stream: MediaStream) {
    if (stream) {
      const tracks = stream.getAudioTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  }

  /**
   * Stop all media track of devices
   */
  stop(action?: () => void) {
    if (this.stream) {
      try {
        this.stopStream(this.stream);

        this.stream = null;
        action?.();
      } catch (error) {
        console.error('Error stopping track:', error);
      } finally {
        this.stream = null;
      }
      this.stream = null;
    }
    const streamsValues = Object.values(streams);
    streamsValues.forEach(
      (_stream: { stream: MediaStream; isTesting: boolean }) => {
        if (this.isTesting && _stream.isTesting) {
          this.stopStream(_stream.stream);
        } else {
          this.stopStream(_stream.stream);
        }
        delete streams[_stream.stream.id];
      }
    );
    return this;
  }
}

export default MediaDevice;
