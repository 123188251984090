import { useMutation, gql } from '@apollo/client';

const SET_REMINDER = gql`
  mutation setReminder($appointmentId: ID!) {
    setReminder(appointmentId: $appointmentId) {
      patient {
        firstname
      }
    }
  }
`;

export default function useSetReminder() {
  const [setReminder, result] = useMutation(SET_REMINDER);
  return { setReminder, ...result };
}
