import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  tableHeaderText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontSize: '22px',
    lineHeight: '25px',
    color: '#425364',
  },
  tableColumnLabel: {
    fontSize: '20px',
    lineHeight: '25px',
    color: '#425364',
  },
  errorIcon: {
    fontSize: '1rem',
    color: 'red',
  },
  textError: {
    fontSize: '14px',
    color: 'red',
    marginLeft: theme.spacing(0.5),
  },
  modal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  modalBox: {
    backgroundColor: 'white',
    borderRadius: '12px',

    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '70vh',
    },
  },
  editButton: {
    display: 'flex',
    border: '1px solid #425364',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: '6px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '16px',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    color: '#425364',
    padding: '5.5px 16.5px',
    '&:hover ': {
      border: '1px solid White',
      backgroundColor: '#2DC76D',
      color: 'white',
      '& svg': {
        '& path': {
          stroke: 'white',
        },
      },
    },
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
  },
  editIcon: {
    width: '15px',
    height: '25px',
    paddingRight: '5px',
    paddingBottom: '2px',
  },
  drugBox: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    border: '0.5px solid #BCC6D3',
    overflow: 'hidden',
  },
  topBox: {
    padding: '22px',
    backgroundColor: '#F8FAFB',
  },
  bottomBox: {
    padding: '22px',
    backgroundColor: '#ECF4F7',
  },
  drugName: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  statusTitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#1F61DC',
    textTransform: 'none',
  },
  statusDescription: {
    fontSize: '15px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    textTransform: 'none',
    lineHeight: '20px',
    '& .public-DraftStyleDefault-block': {
      margin: 0,
      padding: 0,
    },
  },
  cardTitles: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    textTransform: 'none',
    lineHeight: '21px',
  },
  cardText: {
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    textTransform: 'none',
    lineHeight: '18px',
    '& .public-DraftStyleDefault-block': {
      margin: 0,
      padding: 0,
    },
  },
  cardSpan: {
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '18px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#1F61DC',
    textTransform: 'none',
    cursor: 'pointer',
  },
  approveButton: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    color: 'white',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontStyle: 'normal',
    '&:hover': {
      backgroundColor: '#1D41CB',
    },
  },
  declineButton: {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    border: '0.5px solid #425364',
    color: '#425364',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontStyle: 'normal',
  },
  buttonBox: {
    display: 'flex',
    gap: '10px',
  },
  iconClassName: {
    width: 15,
    height: 15,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  draftClassName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    maxHeight: theme.typography.pxToRem(200),
    height: theme.typography.pxToRem(200),
  },
  ApproveForm: {
    paddingInline: theme.spacing(4),
    width: '45vw',
    maxHeight: '60vh',
  },
  DeclineForm: {
    paddingInline: theme.spacing(4),
    width: '40vw',
    maxHeight: '60vh',
  },
  declineTitle: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    textTransform: 'none',
    lineHeight: '18px',
  },
  decline: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected: boolean }) =>
      selected ? '2px solid #273142' : '1.5px solid #A4A4A4',
    borderRadius: '16px 16px 0px 0px',
  },
  declineWithMsg: {
    position: 'relative',
    padding: '20px',
    border: ({ selected }: { selected: boolean }) =>
      !selected ? '2px solid #273142' : '1.5px solid #A4A4A4',
    borderRadius: '0px 0px 16px 16px',
  },
  declineText: {
    marginTop: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#8097B1',
    textTransform: 'none',
    lineHeight: '16px',
  },
  declineSelected: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '13px',
    height: '13px',
    border: '5px solid black',
    borderRadius: '100%',
  },
  declineNotselected: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '19px',
    height: '19px',
    border: '2px solid #A4A4A4',
    borderRadius: '100%',
  },
  subject: {
    marginTop: '2rem',
  },
  Inputs: {
    display: ({ selected }: { selected: boolean }) =>
      !selected ? 'block' : 'none',
  },
  CardState: {
    marginTop: '1rem',
    fontWeight: 600,
    textTransform: 'none',
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#203142',
  },
  noRefillRequest: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#464665',
    maxWidth: '20rem',
  },
  drugstModal: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '37vw',
    },
  },
}));
export default useStyles;
