import * as React from 'react';

const OPEN = { type: 'open' };
const CLOSE = { type: 'close' };
const MEDICAL_TAB = { type: 'medical' };
const FAMILY_TAB = { type: 'family' };
const SOCIAL_TAB = { type: 'social' };
const CHANGE_CURRENT_TAB = {
  number: 0,
  type: 'changeCurrentTab',
};

type Action =
  | typeof CHANGE_CURRENT_TAB
  | typeof OPEN
  | typeof CLOSE
  | typeof MEDICAL_TAB
  | typeof FAMILY_TAB
  | typeof SOCIAL_TAB;

type Dispatch = (action: Action) => void;

type State = { open: boolean; currentTab: number };

type HistoryTabProviderProps = {
  children: React.ReactElement;
  initialState?: State;
};

export const HistoryTabStateContext = React.createContext<State | undefined>(
  undefined
);

export const HistoryTabDispatchContext = React.createContext<
  Dispatch | undefined
>(undefined);

function HistoryTabReducer(state: State, action: any): any | Error {
  switch (action.type) {
    case 'open': {
      return { ...state, open: true };
    }

    case 'close': {
      return { ...state, open: false };
    }
    case 'changeCurrentTab': {
      return { ...state, currentTab: action.payload };
    }
    case 'medical': {
      return { ...state, open: true, currentTab: 0 };
    }
    case 'social': {
      return { ...state, open: true, currentTab: 1 };
    }
    case 'family': {
      return { ...state, open: true, currentTab: 2 };
    }

    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}

function HistoryTabProvider({
  children,
  initialState = { open: false, currentTab: 0 },
}: HistoryTabProviderProps) {
  const [state, dispatch] = React.useReducer(HistoryTabReducer, initialState);

  return (
    <HistoryTabStateContext.Provider value={state}>
      <HistoryTabDispatchContext.Provider value={dispatch}>
        {children}
      </HistoryTabDispatchContext.Provider>
    </HistoryTabStateContext.Provider>
  );
}

function useHistoryTabState() {
  const context = React.useContext(HistoryTabStateContext);

  if (context === undefined) {
    throw new Error(
      'useHistoryTabState must be used within a HistoryTabProvider'
    );
  }

  return context;
}

function useHistoryTabDispatch() {
  const context = React.useContext(HistoryTabDispatchContext);

  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider');
  }

  return context;
}

export default function useHistoryTab(): any {
  const state = useHistoryTabState();
  const dispatch = useHistoryTabDispatch();
  return { dispatch, state };
}

export {
  HistoryTabProvider,
  useHistoryTabState,
  useHistoryTabDispatch,
  OPEN,
  CLOSE,
  MEDICAL_TAB,
  SOCIAL_TAB,
  FAMILY_TAB,
};
