import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_SUBSCRIPTION } from './useGetSubscription';

export const EDIT_SUBSCRIPTION = gql`
  mutation editSubscription($subscriptionInput: editSubscriptionInput) {
    updateSubscription(subscriptionInput: $subscriptionInput) {
      hasTrial
      plan
      endDate
      doctor {
        _id
        firstname
        lastname
        email
        picture
      }
    }
  }
`;

export default function useUpdateSubscription(doctor: string) {
  const [editSubscription, { loading, error }] = useMutation(
    EDIT_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: GET_DOCTOR_SUBSCRIPTION,
          variables: {
            doctor,
          },
        },
      ],
    }
  );

  return { editSubscription, loading, error };
}
