import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import useDrugs from 'graphql/operations/patientOperations/useDrugs';
import MissingInformation from 'components/MissingInformation';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from 'components/ui/Menu';
import DataLoader from 'components/ui/DataLoader';
import { IPatientProps } from 'components/PatientProfile/PatientTabs/PatientTabsContent';
import useStyles from './styles';
import useGetRequestsByPatient from '@eggmed/graphql-client/operations/refillOperations/useGetRequestsByPatient';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TrashIcon from 'assets/TrashIcon';
import Skeleton from 'components/ui/Skeleton';
import DrugModal from './DrugModal';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { fromNow } from 'utils';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyDrugs } from 'assets/emptyState/drug.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

const schema = yup.object().shape({
  name: yup.string().required('Drug name is a required field'),
  type: yup.string().nullable().required('Type is a required field'),
  frequency: yup.string().required('Frequency is a required field'),
  dosage: yup.string().required('Dosage is a required field'),
  since: yup.string().required('Since is a required field'),
});

export interface IDrug {
  name: string;
  type: string;
  frequency: string;
  dosage: string;
  since: string;
  _id?: string;
}

export default function PatientDrugs({
  patientId,
  isPatient = false,
}: IPatientProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState('month');
  function onViewChange(view: string) {
    setView(view);
  }
  const [page, setPage] = React.useState(1);
  function handlePagination(e, _page) {
    setPage(_page);
  }
  const location = useLocation();
  const cardRef = useRef(null);
  const classes = useStyles({ isPatient });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleAddDrug,
    handleEditDrug,
    handleDeleteDrug,
    data: DrugsData,
    loading,
    error,
    loadingAddDrug,
    loadingEditDrug,
  } = useDrugs({ patientId });
  const handelDelete = (drug: string) => {
    handleDeleteDrug(drug);
    setOpenDeleteModal(false);
  };
  const handleOpenDeleteModal = () => {
    handleCloseMenu();
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const columns: IColumn<any>[] = [
    {
      id: 'name',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="drugName">Drug name</Text>
        </Box>
      ),
      align: 'left',
    },
    {
      id: 'type',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="drugType">Drug type</Text>
        </Box>
      ),
      align: 'left',
    },
    {
      id: 'frequency',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="frequency">Frequency</Text>
        </Box>
      ),
      align: 'left',
    },
    {
      id: 'dosage',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="dosage">Dosage</Text>
        </Box>
      ),
      align: 'left',
    },
    {
      id: 'since',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="since">Since</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => <>{fromNow(value)}</>,
    },
    {
      id: '_id',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="action">Action</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <IconButton onClick={(e) => onOpenMenu(e, value)}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];
  function closeEditModal() {
    setOpen(false);
  }

  function openModal() {
    setSelectedRow(null);
    setOpen(true);
  }
  function openEditModal() {
    handleCloseMenu();
    setOpen(true);
  }
  const MenuElementsData = [
    {
      cy: 'edit-drug',
      avatar: <EditIcon />,
      name: t('Edit'),
      onClick: () => openEditModal(),
    },
    {
      cy: 'delete-drug',
      avatar: <TrashIcon width="20px" height="20px" />,
      name: t('Delete'),
      onClick: () => handleOpenDeleteModal(),
    },
  ];
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    selectedId: string
  ) => {
    setSelectedRow(selectedId);
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cardId = queryParams.get('cardId');

    if (cardId && cardRef?.current) {
      const cardElement = cardRef?.current?.querySelector(
        `[data-card-id="${cardId}"]`
      );

      if (cardElement) {
        cardElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search, cardRef?.current, loading]);

  const onSubmit = (data: IDrug) => {
    selectedRow
      ? handleEditDrug({ ...data, _id: selectedRow })
      : handleAddDrug(data);
    setTimeout(() => closeEditModal(), 1000);
  };

  return (
    <DataLoader loading={loading} error={error} data={DrugsData?.drugs}>
      <>
        <Box display="flex" pb="15px" alignItems="center" pt="13px">
          <Skeleton loading={loading}>
            <Text i18nKey="drugs" className={classes.headerTitle}>
              Drugs
            </Text>
          </Skeleton>
          {!!DrugsData?.drugs?.length && (
            <Box
              display="flex"
              justifyContent={'flex-end'}
              alignContent={'center'}
              alignItems={'center'}
              width="100%"
            >
              <Button
                className={classes.addTask}
                onClick={openModal}
                data-cy="add-drug"
              >
                <AddTaskIcon style={{ paddingRight: '6px', width: '1.3rem' }} />
                <Text i18nKey="add">Add</Text>
              </Button>
            </Box>
          )}
        </Box>
        <Table
          loading={loading}
          // type="normal"
          limit={5}
          page={page}
          handlePagination={handlePagination}
          EmptyState={
            <Box py="5rem">
              <EmptyState
                icon={<EmptyDrugs />}
                title={t('No drugs information')}
                text={t('to add drugs information')}
                onClick={openModal}
                isText
                isClickLeft
              />
            </Box>
          }
          columns={columns}
          data={DrugsData?.drugs || []}
        />

        {/* {!isPatient && (
          <>
            <Box
              display="flex"
              mt={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Skeleton loading={loadingRefill}>
                <Typography className={classes.headerTitle}>
                  Refill requests
                </Typography>
              </Skeleton>
              <SmallSelect
                defaultValue={view}
                onChange={(e: any) => onViewChange(e.target.value)}
              >
                {viewOptions.map((value: any) => (
                  <MenuItem key={value} value={value.value}>
                    {value.label}
                  </MenuItem>
                ))}
              </SmallSelect>
            </Box>
            <div ref={cardRef}>
              {data?.refillRequestedByPatient?.filter((item) =>
                filteredRefillByDate(item, view)
              )?.length === 0 && (
                <Box
                  mt="5rem"
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  style={{ gap: '1rem' }}
                >
                  <NoRefillRequest />
                  <Typography className={classes.noRefillRequest}>
                    Its seems there are no refill requests at this moment
                  </Typography>
                </Box>
              )}
              {data?.refillRequestedByPatient
                ?.filter((item) => filteredRefillByDate(item, view))
                .map((el) =>
                  el?.drugs?.map((drug) => (
                    <DrugCard
                      id={el?._id}
                      appointment={el?.appointment}
                      drug={drug}
                      createdAt={el?.createdAt}
                      selector={el?._id}
                      prescriptionUri={el?.prescriptionUri}
                      patient={el?.patient}
                    />
                  ))
                )}
            </div>
          </>
        )} */}

        <Menu<any>
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={!!anchorEl}
          handleMenuClose={handleCloseMenu}
          MenuElements={MenuElementsData}
        />
        <ModalDelete
          text="drug"
          open={openDeleteModal}
          onClose={() => handleCloseDeleteModal()}
          onDelete={() => handelDelete(selectedRow)}
          isPatient={isPatient}
        />
        <FormProvider {...methods}>
          <BasicModal
            open={open}
            onClose={closeEditModal}
            className={classes.drugstModal}
            loading={loadingAddDrug || loadingEditDrug}
            title={selectedRow ? t('Edit drugs') : t('Add drug')}
            titlePadding="2rem"
            handleClose={closeEditModal}
            onSubmit={onSubmit}
            isFromModal
            isSlide
          >
            <DrugModal
              defaultValues={
                selectedRow &&
                DrugsData?.drugs.find((drug) => drug._id === selectedRow)
              }
            />
          </BasicModal>
        </FormProvider>
      </>
    </DataLoader>
  );
}
