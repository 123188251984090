import { createMuiTheme } from '@material-ui/core/styles';
// import { green, orange } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    black: Palette['primary'];
    blue: Palette['primary'];
    gray: Palette['primary'];
    secondBlue: Palette['primary'];
    green: Palette['primary'];
    red: Palette['primary'];
    purple: Palette['primary'];
    orange: Palette['primary'];
    white: Palette['primary'];
    done: Palette['primary'];
  }
  interface PaletteOptions {
    black: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
    secondBlue: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    purple: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    done: PaletteOptions['primary'];
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    success: {
      main: '#39B54A',
    },
    black: {
      main: '#273142',
      light: '#425364',
      dark: '#425364',
      contrastText: '#425364',
    },
    gray: {
      main: '#9CB2CD',
      light: '#D7E3F1',
      dark: '#BCC6D3',
      contrastText: '#F8FAFB',
    },
    secondBlue: {
      main: '#1F61DC',
      dark: '#425364',
    },
    blue: {
      main: '#082CAF',
      light: '#EFF6FF',
      dark: '#41A8FF',
      contrastText: '#567FBD',
    },
    green: {
      main: '#2DC76D',
      light: '#49DCBB',
      dark: '#39C38D',
    },
    red: {
      main: '#FE443F',
      light: '#F96999',
    },
    purple: {
      main: '#AF77E0',
    },
    orange: {
      main: '#FEC25A',
    },
    white: {
      main: '#FFFFFF',
    },
    done: {
      main: '#3794FC',
    },
  },
});
export default theme;
