import React, { ReactElement } from 'react';
import { Button as MaterialUiButton, ButtonProps } from '@material-ui/core';
import SuccessButton from './SuccesButton';
import DoneButton from './DoneButton';
import CancelButton from './CancelButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import { useTranslation } from 'react-i18next';


interface ICustomButtonProps extends Omit<ButtonProps, 'size' | 'type'> {
  type?:
    | 'submit'
    | 'normal'
    | 'success'
    | 'done'
    | 'button'
    | 'reset'
    | undefined
    | 'edit'
    | string;
  buttonType?: string;
  size?: 'small' | 'medium' | 'large' | undefined | 'normal' | 'regular';
  component?: string;
  children?: ReactElement;
}

export default function Button({
  
  type,
  buttonType = 'normal',
  size = 'medium',
  ...props
}: any | ICustomButtonProps) {
  const bigger = { width: '11.625rem', height: '2.9375rem' };
  const { t } = useTranslation();
  switch (type) {
    case 'success':
      return (
        <SuccessButton
          size={size}
          style={size === t('normal') ? bigger : {}}
          type={buttonType}
          {...props}
        />
      );
    case 'done':
      return <DoneButton {...props}>{t('done')}</DoneButton>;
    case 'edit':
      return <EditButton {...props}>{t('edit')}</EditButton>;
    case 'cancel':
      return <CancelButton {...props}>{t('cancel')}</CancelButton>;
    case 'delete':
      return <DeleteButton {...props}>{t('Delete')}</DeleteButton>;
    default:
      return <MaterialUiButton type={buttonType} {...props} />;
  }
}
