import { IMoodSleepTags } from './CreateEditForm/tags';

export const getCheckedTags = (
  tags: IMoodSleepTags,
  selectedEmoji: string,
  data: Record<string, unknown>
): string[] => {
  const selected = tags[selectedEmoji].filter((tag) => data[tag] === true);
  return selected;
};
