import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT_ACCESS } from './useGetPatientAccess';

const GIVE_ALL_ACCESS = gql`
  mutation giveAllAccessToDoctor($doctor: String) {
    giveAllAccessToDoctor(doctor: $doctor) {
      _id
    }
  }
`;
const useGiveAllAccessToDoctor = () => {
  const [giveAccess] = useMutation(GIVE_ALL_ACCESS, {
    refetchQueries: [{ query: GET_PATIENT_ACCESS }],
  });
  async function handleGiveAllAccessToDoctor(doctor: String) {
    try {
      return await giveAccess({
        variables: { doctor },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleGiveAllAccessToDoctor };
};

export { GIVE_ALL_ACCESS };
export default useGiveAllAccessToDoctor;
