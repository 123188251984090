import { gql, useMutation } from '@apollo/client';

const GNERATE_PATIENT_ACTIVITY_PDF = gql`
  mutation generatePatientActivityPDF($id: ID!) {
    generatePatientActivityPDF(id: $id) {
      activityUrl
    }
  }
`;

const useGeneratePatientActivityPdf = () => {
  return useMutation(GNERATE_PATIENT_ACTIVITY_PDF);
};

export { GNERATE_PATIENT_ACTIVITY_PDF };
export default useGeneratePatientActivityPdf;
