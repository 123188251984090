import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import { ReactComponent as RadioCheckedIcon } from 'assets/Schedule/new/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/Schedule/new/radio-unchecked.svg';

interface IFormRadio {
  register: any;
  control: any;
  question: string;
  options: string[];
  name: string;
  handleInputChange: any;
  questionIndex: number;
}

export const FormRadio = ({
  name,
  register,
  control,
  question,
  options,
  handleInputChange,
  questionIndex,
}: IFormRadio) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <Controller
        name={name}
        control={control}
        register={register}
        defaultValue={'Yes'}
        render={({ onChange, value }) => (
          <RadioGroup
            aria-label={name}
            name={name}
            defaultValue={'Yes'}
            defaultChecked={true}
            onChange={(e) => handleInputChange(questionIndex, e.target.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                className={classes.formRadio}
                value={option}
                control={
                  <Radio
                    disableRipple
                    color="default"
                    icon={<RadioUncheckedIcon />}
                    checkedIcon={<RadioCheckedIcon />}
                  />
                }
                label={option}
              />
            ))}
          </RadioGroup>
        )}
      />{' '}
    </Box>
  );
};
