import { gql, useMutation } from '@apollo/client';
import { GET_STAFF_BY_DOCTOR } from './useGetStaffByDoctor';

const REMOVE_DOCTOR_FROM_STAFF = gql`
  mutation removeDoctorFromStaff($doctorId: ID!, $staffId: ID!) {
    removeDoctorFromStaff(doctorId: $doctorId, staffId: $staffId) {
      _id
    }
  }
`;
const useRemoveDoctorFromStaff = () => {
  const [removeDoctorFromStaff] = useMutation(REMOVE_DOCTOR_FROM_STAFF, {
    update: (cache, { data: { removeDoctorFromStaff } }) => {
      cache.modify({
        fields: {
          getStaffsByDoctor(getStaffByDoctor) {
            const newStaff = getStaffByDoctor.filter((Staff) => {
              const staffId = Staff.__ref.split('Staff:')[1];
              return staffId !== removeDoctorFromStaff._id;
            });
            return newStaff;
          },
        },
      });
    },
  });
  async function handleRemoveDoctorFromStaff(doctorId, staffId) {
    try {
      return await removeDoctorFromStaff({
        variables: { doctorId, staffId },
      });
    } catch (e) {
      return e;
    }
  }
  return handleRemoveDoctorFromStaff;
};

export { REMOVE_DOCTOR_FROM_STAFF };
export default useRemoveDoctorFromStaff;
