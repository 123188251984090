import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { ReactComponent as ArrowDown } from 'assets/patientActivities/chevron-down.svg';
import Menu from 'components/ui/Menu';
import { useTranslation } from 'react-i18next';

interface IEmptyProps {
  icon: any;
  title: string;
  description: string;
  isRecent?: boolean;
  anchorEl?: any;
  handleMenuClose?: any;
  MenuElements?: any;
  handleClick?: any;
}

export const Empty = ({
  icon,
  title,
  description,
  isRecent = false,
  anchorEl,
  handleMenuClose,
  MenuElements,
  handleClick,
}: IEmptyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box textAlign="center" py={8}>
      <Box>{icon}</Box>
      <Box>
        <Typography className={classes.titleIllus}>{title}</Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap="0.3rem"
        >
          <Typography className={classes.descriptionIllus}>
            {description}
          </Typography>
          {isRecent && (
            <>
              <Typography className={classes.ctaIllus} onClick={handleClick}>
                {t('click here')}
                <ArrowDown />
              </Typography>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                handleMenuClose={handleMenuClose}
                MenuElements={MenuElements}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
