import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '1.5rem',
    paddingLeft: '3rem',
  },
  back: {
    position: 'absolute',
    top: 18,
    left: 32,
    cursor: 'pointer',
  },
  titleText: {
    color: '#273142',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
  },
  scroller: {
    height: '80vh',
    maxHeight: '80vh',
  },
  conflicts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  summary: {
    border: '1px solid #A4A4A4',
    borderRadius: '10px',
    overflow: 'hidden',

    '&:not(:last-child)': {
      borderBottom: '1px solid #A4A4A4',
    },

    '&.Mui-expanded': {
      margin: 'unset !important',
    },

    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '20px 0 0 !important',
    },
  },
  children: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 52px 0 60px',
    gap: '1rem',
    position: 'relative',
  },
  infoIcon: {
    color: '#FFAA15',
    width: '28px',
    height: '28px',
  },
  two: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
    position: 'relative',
  },
  conflictIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  user: {
    backgroundColor: '#F2F2F2',
    borderRadius: '10px',
    borderBottomLeftRadius: ({ expanded }: { expanded: boolean }) =>
      expanded && '0',
    borderBottomRightRadius: ({ expanded }: { expanded: boolean }) =>
      expanded && '0',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    flex: 1,
  },
  userInfo: {
    color: '#425364',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.4rem',
    fontWeight: 800,
    marginTop: '0.3rem',
  },
  dateText: {
    fontWeight: 700,
  },
  expanded: {
    backgroundColor: '#F2F2F2',
    flex: 1,
    padding: '0 1rem 1rem calc(2rem + 40px)',
    marginBottom: '20px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    color: '#425364',
  },
  sessionLabel: {
    backgroundColor: '#F96999',
    color: 'white',
    width: 'fit-content',
    borderRadius: '6px',
    padding: '6px',
    marginBottom: '1.5rem',
  },
  sessionType: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.4rem',
  },
  participants: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.4rem',
    marginTop: '1.5rem',
  },
  eventDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.4rem',
    marginTop: '1.5rem',
  },
  subtitles: {
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: '3px',
  },
  price: {
    color: '#273142',
    fontWeight: 900,
    fontSize: '20px',
    marginTop: '6px',
  },
  participantContainer: {
    marginTop: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  paticipantAvatar: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  paticipantStatus: {
    position: 'absolute',
    top: '18px',
    left: '20px',
    width: '20px',
    height: '20px',
  },
  paticipantName: {
    color: '#425364',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  paticipantConfirmation: {
    color: '#828282',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  extraMargin: {
    // calc explanation: 32px (height of sessionType) + 1.5rem (margin bottom of sessionType)
    marginTop: 'calc(32px + 1.5rem)',
  },
  meetingLink: {
    width: 'fit-content',
    backgroundColor: 'rgb(26,115,232)',
    color: '#fff',
    padding: '7px 16px',
    borderRadius: '4px',
    marginTop: '10px',
    fontSize: '16px',
    textDecoration: 'none',
    display: 'block',
  },
  description: {
    overflowWrap: 'anywhere',
  },
  remove: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFF6FF',
    border: '1px solid #2DC76D',
    color: '#2DC76D',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '3px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#EFF6FF',
    },
    height: '30px',
  },
  edit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2DC76D',
    border: '1px solid #2DC76D',
    color: 'white',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '3px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#2DC76D',
    },
    height: '30px',
  },
  conflictCard: {
    backgroundColor: '#F8F9FC',
    borderRadius: 10,
    padding: 16,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cUser: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
  },
  cName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    color: '#344054',
    textTransform: 'capitalize',
  },
  cTime: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '10px',
    marginBottom: '6px',
  },
  cDate: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
  },
  cDateText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    color: '#344054',
  },
  avatar: {
    textTransform: 'capitalize',
    fontSize: '16px',
  },
  removeBtn: {
    color: '#EB5757',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    border: '1px solid #EB5757',
    backgroundColor: '#F5FAFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    minWidth: '80px',
    paddingBlock: '6px',
    borderRadius: '8px',

    '&:hover': {
      background: '#E5EAEE',
    },

    '& svg': {
      marginRight: '0.3rem',
    },
  },
  messageBtn: {
    color: '#1F61DC',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    border: '1px solid #1F61DC',
    backgroundColor: '#F5FAFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    minWidth: '80px',
    paddingBlock: '6px',
    borderRadius: '8px',

    '&:hover': {
      background: '#E5EAEE',
    },

    '& svg': {
      marginRight: '0.3rem',
    },
  },
  editBtn: {
    color: '#F5FAFF',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    border: '1px solid #1F61DC',
    backgroundColor: '#1F61DC',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    minWidth: '80px',
    paddingBlock: '6px',
    borderRadius: '8px',

    '&:hover': {
      background: '#1F61DC',
    },

    '& svg': {
      marginRight: '0.3rem',
    },
  },
  conditionTag: {
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: 12,
    padding: '2px 7px',
  },
}));

export default useStyles;
