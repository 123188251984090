import React, { ReactElement } from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as EditIcon } from 'assets/edit-settings.svg';
import Skeleton from 'components/ui/Skeleton';

interface ICardProps {
  children: ReactElement;
  handleOpen: () => void;
  isPatient: boolean;
  cy?: string;
  editButton?: boolean;
  loading?: boolean;
}

const Card = ({
  children,
  handleOpen,
  isPatient,
  cy = 'edit',
  editButton = true,
  loading,
}: ICardProps): ReactElement => {
  const classes = useStyles({ isPatient });
  return (
    <div className={classes.card}>
      {!isPatient && editButton && (
        <Skeleton
          loading={loading}
          type="rect"
          width="84px"
          height="37px"
          className={classes.skeletonEdit}
        >
          <Box className={classes.editButton} onClick={handleOpen} data-cy={cy}>
            <EditIcon />
          </Box>
        </Skeleton>
      )}

      {children}
    </div>
  );
};
export default Card;
