import { useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React from 'react';
import Activity, { TypeEnum } from 'pages/PatientActivities/commun/Activity';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import Modals from 'pages/InboxPage/modals/index';
import Questionaires from 'pages/PatientActivities/Questionaires/Questionaires';
import SelectMenu from './SelectMenu';
import Skeleton from 'components/ui/Skeleton';
import { Box } from '@material-ui/core';
import PatientListHeader from 'pages/PatientListPage/PatientListHeader';
import FormModals from 'pages/PatientActivities/Questionaires/FormModals';
import { TypeEnum as ActivityTypeEnum } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';

export function getType(state: string) {
  switch (state) {
    case '':
      return 'reflection';
    case 'reflection':
      return 'reflection';
    case 'moodCheckin':
      return 'Mood';
    case 'sleepCheckin':
      return 'Sleep';
    default:
      return 'reflection';
  }
}

function ActivityTab({ patientId }: { patientId: string }) {
  const { doctor } = useAuth();
  const [menuName, setMenuName] = React.useState('Type');
  const [limit, setLimit] = React.useState<number>(10);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [menuValue, setMenuValue] = React.useState('');

  const { data: PatientData } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });
  const { firstname, lastname, middlename, _id, email, picture } =
    PatientData?.patient || {};

  const handleOpen = () => {
    setOpenAssign(true);
  };
  return (
    <>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row-reverse"
      >
        <Skeleton loading={false}>
          <PatientListHeader
            handleOpen={handleOpen}
            limit={limit}
            setLimit={setLimit}
            disableEntries
            btnText="Assign"
          />
        </Skeleton>
        <Skeleton loading={false}>
          <SelectMenu
            menuName={menuName}
            setMenuName={setMenuName}
            setMenuValue={setMenuValue}
          />
        </Skeleton>
      </Box>
      {menuName === 'questionnaires' ? (
        <Questionaires patient={PatientData?.patient} disableAdd />
      ) : (
        <Activity
          doctorAndPatientId={{ doctor: doctor?._id, patient: patientId }}
          type={getType(menuValue) as TypeEnum}
          activityType={menuValue as ActivityTypeEnum}
          patient={PatientData?.patient}
          openAssign={openAssign}
          setOpenAssign={setOpenAssign}
          limit={limit}
          appointmentActivity
        />
      )}
      <FormModals patient={PatientData?.patient} />
      <Modals
        subject="Activities"
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
    </>
  );
}

export default ActivityTab;
