import React, { useEffect } from 'react';
import Steps from 'pages/PatientActivities/commun/Steps';
import NewTemplate from './NewTemplate';
import FormType from 'pages/PatientActivities/Questionaires/FormType';
import { Itemplate } from './NotesTab';
import { useTranslation } from 'react-i18next';

function AddNotesModal({
  handleClose,
  templates,
  patientId,
  openDeleteModal,
  appointmentId,
  setFormToAssign,
  setOpenAssign,
  setNotesTitle,
}: {
  handleClose: () => void;
  templates: Itemplate[];
  patientId: string;
  openDeleteModal: (template: Itemplate) => void;
  appointmentId: string;
  setFormToAssign: (template: Itemplate) => void;
  setOpenAssign: (value: boolean) => void;
  setNotesTitle?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [step, setStep] = React.useState(0);
  const { t } = useTranslation();
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    step > 0 && setStep(step - 1);
  };
  const handleSubmit = () => {};
  const handleSetTemplate = (templates) => {
    setFormToAssign(templates);
    handleClose();
    setOpenAssign(true);
  };
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (step === 0) {
      setNotesTitle(t('Notes'));
    } else {
      setNotesTitle(t('Notes > Create new note'));
    }
  }, [step]);
  return (
    <Steps
      step={step}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      fullHeight
      steps={[
        <FormType
          handleNext={handleNext}
          title="Notes templates"
          patientId={patientId}
          appointmentId={appointmentId}
          assignText=""
          doctorTemplates={templates}
          openAssignModal={handleSetTemplate}
          openDeleteModal={openDeleteModal}
          isNotes
          open={open}
          setOpen={setOpen}
        />,
        <NewTemplate
          handleBack={handleBack}
          patientId={patientId}
          appointmentId={appointmentId}
        />,
      ]}
    />
  );
}

export default AddNotesModal;
