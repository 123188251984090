import { makeStyles } from '@material-ui/core/styles';
import doctorBackground from 'assets/background/signupmedicalbackground.png';

const useStyles = makeStyles((theme) => ({
  intro: {
    fontSize: '15px',
    lineHeight: '18px',
    color: 'rgba(39, 49, 66, 1)',
    fontStyle: 'italic',
    fontWeight: 300,
  },
  title: {
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 600,
    color: 'rgba(31, 97, 220, 1)',
  },
  sections: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 500,
    color: 'rgba(66, 83, 100, 1)',
  },
  sectionTitle: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    color: 'rgba(66, 83, 100, 1)',
  },
  sectionContent: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 400,
    color: 'rgba(66, 83, 100, 1)',
  },
  sectionBox: {
    marginTop: '2rem',
  },
}));

export default useStyles;
