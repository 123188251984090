import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CryptoJS from 'crypto-js';

import VideoActions from 'components/VideoActions';
import { useListenMessage } from '@eggmed/graphql-client/operations/roomMessagesOperations/useListenMessage';
import useAddMessage from '@eggmed/graphql-client/operations/roomMessagesOperations/useAddMessage';
import useListenLeaveVideoRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useListenLeaveVideoRoom';
import useLeaveVideoRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useLeaveVideoRoom';
import { useProvider } from './state/RoomContext';
import { useProvider as useMeetingProvider } from 'contexts/MeetingContext';
import VideoComponent from './VideoComponent';
import ChatContainer, { IMessage } from './Chat/ChatContainer';
import useNavigatorOnLine from 'hooks/useNavigatorOnLine';
import { toast } from 'react-toastify';
import { ReactComponent as FullScreenIcon } from 'assets/videoActions/fullscreen.svg';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    backgroundColor: '#101828',
    borderRadius: ({ height }: any) => (height === '100vh' ? '0' : '12px'),
    position: 'relative',
    maxHeight: ({ height }: any) => height || '100vh',
    maxWidth: ({ width }: any) => width || '100vw',
    height: ({ height }: any) => height || '100vh',
    width: ({ width }: any) => width || '100vw',
    overflow: 'hidden',
  },
  fullscreenIcon: {
    position: 'absolute',
    top: '1vh',
    right: ({ width }: any) => (width === '100vw' ? '23vw' : '4px'),
    color: 'white',
    fontSize: '2vw',
    zIndex: 10000000,
    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',
    },
  },
  sessionTitle: {
    position: 'absolute',
    top: '2.1vh',
    left: '15px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#EAECF0',
  },
}));

interface IMeetingRoomPage {
  endCallAction?: () => void;
  height?: string | number;
  width?: string | number;
  isWide?: boolean;
  handleFullScreen?: () => void;
}
export default function MeetingRoomPage({
  endCallAction,
  height,
  width,
  isWide = true,
  handleFullScreen,
}: IMeetingRoomPage): JSX.Element {
  const classes = useStyles({ height, width, isWide });
  // TODO: Check to delete this.
  const [chatOpened, setChatOpened] = useState(false);
  const [messagesData, setMessagesData] = useState<IMessage[]>([]);

  const {
    unMountConnections,
    videoRemoteRef,
    videoLocalRef,
    uniqueIdentifier,
    established,
    handleChangeMute,
    mute,
    handleToggleVideo,
    videoOff,
    roomId,
    setEstablished,
    userData,
    isDoctor,
    encryptionKey,
    remoteVideoOff,
    setRemoteVideoOff,
    initiateInitialStateConnection,
  } = useProvider();

  const { appointment } = useMeetingProvider();

  const network = useRef<{
    status: boolean;
  }>({
    status: true,
  });

  const networkStatus = useNavigatorOnLine();

  useEffect(() => {
    if (!networkStatus) {
      network.current.status = false;
      toast.warn('Connection lost.', {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!network.current.status) {
        toast.success('Connection restored.', {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        network.current.status = true;
        window.location.reload();
      }
    }
  }, [networkStatus]);

  const handleSendRoomMessage = useAddMessage();
  function handleNewMessage(message: any) {
    setMessagesData((messages: any) => [
      ...messages,
      {
        ...message,
        text: CryptoJS.AES.decrypt(message.text, encryptionKey).toString(
          CryptoJS.enc.Utf8
        ),
      },
    ]);
  }

  useListenMessage(handleNewMessage, roomId);

  const history = useHistory();

  const { handleLeaveVideoRoom } = useLeaveVideoRoom();

  const handleEndCall = useCallback(() => {
    handleLeaveVideoRoom(uniqueIdentifier, roomId);
    unMountConnections();
    if (isWide) {
      history.push({
        pathname: `/meeting/end/${roomId}`,
        state: { isDoctor: false },
      });
    } else {
      endCallAction?.();
    }
  }, [uniqueIdentifier, roomId]);

  useListenLeaveVideoRoom({
    roomId,
    handleAction: (data) => {
      setEstablished('initial');
      initiateInitialStateConnection();
      setRemoteVideoOff(false);
    },
  });
  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      handleLeaveVideoRoom(uniqueIdentifier, roomId);
    });
    return () => {
      handleLeaveVideoRoom(uniqueIdentifier, roomId);
      unMountConnections();
      window.removeEventListener('beforeunload', () => {});
    };
  }, [roomId]);

  return (
    <div style={{ position: 'absolute', top: 0, left: 0 }}>
      <div className={clsx(classes.videoContainer)}>
        {!isWide && (
          <div onClick={handleFullScreen} className={classes.fullscreenIcon}>
            <FullScreenIcon />
          </div>
        )}
        {!isWide && (
          <div className={classes.sessionTitle}>
            {appointment?.appointment?.sessionType.duration}min session
          </div>
        )}
        <VideoActions
          isWide={isWide}
          setVideoOff={handleToggleVideo}
          videoOff={videoOff}
          mute={mute}
          endCallFeature
          handleChangeMute={handleChangeMute}
          handleEndCall={handleEndCall}
        />
        <VideoComponent
          remoteVideoOff={remoteVideoOff}
          userData={isDoctor ? appointment?.appointment?.doctor : userData}
          remoteUserData={
            isDoctor ? appointment?.patient : appointment?.appointment?.doctor
          }
          established={established}
          localRef={videoLocalRef}
          remoteRef={videoRemoteRef}
          chatOpened={chatOpened}
          isVideoOff={videoOff}
          isWide={isWide}
        />
        {(isWide || (!isWide && (width || 0) === '100vw')) && (
          <ChatContainer
            sendText={(message: any) => {
              setMessagesData((messages) => [...messages, message]);
              handleSendRoomMessage(
                {
                  ...message,
                  text: CryptoJS.AES.encrypt(
                    message.text,
                    encryptionKey
                  ).toString(),
                },
                roomId
              );
            }}
            messages={messagesData}
          />
        )}
      </div>
    </div>
  );
}
