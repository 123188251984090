interface IUser {
  firstname: string;
  lastname?: string;
}
export function generateFullName(isLarge: boolean, user: IUser) {
  if (!isLarge) {
    return `${user?.firstname} ${user?.lastname}`;
  }
  return `${user?.firstname}`;
}
