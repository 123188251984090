import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '../ui/Button';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
  },
}));

interface IUploadFileProps {
  handleAddAttachment: any;
}

export default function UploadFile({ handleAddAttachment }: IUploadFileProps) {
  const classes = useStyles();
  return (
    <Box p={2} display="flex" flexDirection="row" alignItems="center">
      <span className={classes.text}>Files /Documents </span>
      <span className={classes.text} style={{ marginLeft: 'auto' }}>
        <label htmlFor="raised-button-file">
          <Button
            type="success"
            style={{
              marginLeft: 'auto',
            }}
            // variant="raised"
            component="span"
            size="small"
          >
            Upload
          </Button>
        </label>
        <input
          id="raised-button-file"
          type="file"
          name="file"
          onChange={(event: any) => {
            handleAddAttachment(event.target?.files[0]);
          }}
          style={{ display: 'none' }}
        />
      </span>
    </Box>
  );
}
