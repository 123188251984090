export const options = [
  { name: 'Infants', id: 'Infants' },
  { name: 'Children', id: 'Children' },
  { name: 'Adults', id: 'Adults' },
  { name: 'Elderly', id: 'Elderly' },
];
export const langOptions = [
  { name: 'English', id: 'English' },
  { name: 'French', id: 'French' },
  { name: 'Spanish', id: 'Spanish' },
  { name: 'German', id: 'German' },
];
export const focusAreas = [
  { name: 'ADDICTIONS', id: 'ADDICTIONS' },
  { name: 'RELATIONSHIP ISSUES', id: 'RELATIONSHIP ISSUES' },
  { name: 'DEPRESSION', id: 'DEPRESSION' },
  { name: 'TRAUMA AND ABUSE', id: 'TRAUMA AND ABUSE' },
  { name: 'Anxiety', id: 'Anxiety' },
  { name: 'Stress', id: 'Stress' },
  { name: 'Divorce and Separation', id: 'Divorce and Separation' },
  { name: 'Parenting issues', id: 'Parenting issues' },
  { name: 'Domestic Violence', id: 'Domestic Violence' },
  { name: 'Eating disorders', id: 'Eating disorders' },
  { name: 'Anger management', id: 'Anger management' },
  { name: 'Intimacy-related issues', id: 'Intimacy-related issues' },
  { name: 'Grief', id: 'Grief' },
  { name: 'Self esteem', id: 'Self esteem' },
];
export const certifications = [
  {
    name: 'MBBS',
    location: 'University of California, San Francisco',
    date: '2016',
  },
  {
    name: 'MD',
    location: 'University of California, San Francisco',
    date: '2096',
  },
  {
    name: 'Fellowship',
    location: 'University of California, San Francisco',
    date: '2012',
  },
];
