import React, { ReactElement } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';

const CellNormal = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#F9FAFB',
      color: '#475467',
      padding: '10px 24px 10px 16px',
      '&:nth-last-child(1)': {
        borderRight: '1px solid #EAECF0',
      },
      '&:nth-child(1)': {
        borderLeft: '1px solid #EAECF0',
      },
      fontFamily: 'Inter, sans-serif',
    },
    body: {
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
      color: '#475467',
      '&:nth-last-child(1)': {
        borderRight: '1px solid #EAECF0',
      },
      '&:nth-child(1)': {
        borderLeft: '1px solid #EAECF0',
      },
      padding: '11px 24px 11px 16px',
    },
  })
)(TableCell);

const CellNormalWidthOutBorder = withStyles((theme: Theme) =>
  createStyles({
    head: {},
    body: {},
  })
)(TableCell);

const CellRounded = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    head: {
      color: '#425364',
      borderTop: '0.5px solid #D7E3F1',
      borderBottom: '0.5px solid #D7E3F1',
      '&:nth-last-child(1)': {
        borderRight: '0.5px solid #D7E3F1',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      '&:nth-child(1)': {
        borderLeft: '0.5px solid #D7E3F1',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
    },
    body: {
      fontSize: 14,
      '&:nth-last-child(1)': {
        borderRight: '0.5px solid #D7E3F1',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
      },
      '&:nth-child(1)': {
        borderLeft: '0.5px solid #D7E3F1',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
    },
  })
)(TableCell);

const CellGray = withStyles((theme) =>
  createStyles({
    root: {
      border: '0px',
    },
    head: {
      backgroundColor: '#EFF6FF',
      color: '#425364',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#EFF6FF',
    },
  })
)(TableCell);

export interface ICellProps extends TableCellProps {
  type: 'rounded' | 'normal' | 'gray' | 'cellWidthOutBorder';
  align?: 'right' | 'center' | 'left' | undefined;
  borderTop?: string;
  borderBottom?: string;
  borderRight?: string;
  borderLeft?: string;
  onClick?: (row: unknown) => void;
  rowBgColor?: string;
}

export default function Cell({
  type,
  align,
  borderTop,
  borderBottom,
  borderRight,
  borderLeft,
  rowBgColor,
  ...props
}: ICellProps): ReactElement {
  const cellStyles = makeStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: '#F9FAFB',
        color: '#475467',
        padding: '10px 24px 10px 16px',
        '&:nth-last-child(1)': {
          borderRight: '1px solid #EAECF0',
        },
        '&:nth-child(1)': {
          borderLeft: '1px solid #EAECF0',
        },
        fontFamily: 'Inter, sans-serif',
      },
      body: {
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        color: '#475467',
        padding: '11px 24px 11px 16px',
        backgroundColor: rowBgColor && rowBgColor,

        '&:nth-last-child(1)': {
          borderRight: '1px solid #EAECF0',
        },
        '&:nth-child(1)': {
          borderLeft: '1px solid #EAECF0',
        },
      },
    })
  )();
  switch (type) {
    case 'rounded':
      return <CellRounded align={align} classes={cellStyles} {...props} />;
    case 'normal':
      return <CellNormal align={align} {...props} />;
    case 'gray':
      return <CellGray align={align} {...props} />;
    case 'cellWidthOutBorder':
      return <CellNormalWidthOutBorder align={align} {...props} />;
    default:
      return <CellNormal align={align} {...props} />;
  }
}
