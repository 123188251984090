import React, { ReactElement } from 'react';
// import { EditorState } from 'draft-js';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles, Theme } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: ({ isBigger }: { isBigger: boolean }) =>
      isBigger ? 'none' : '100%',
    zIndex: 1,
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    //top: '-6px',
    // marginTop: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    '& .rdw-option-wrapper': {
      background: '#FFFFFF',
      border: '1px solid #D0D5DD',
      minWidth: 26,
      padding: 6,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.action.hover,
      },
    },
    '& .rdw-option-active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.action.selected,
    },
    '& .rdw-dropdown-wrapper': {
      boxShadow: 'none',
      background: '#FFFFFF',
    },
    '& .rdw-dropdown-optionwrapper': {
      overflowY: 'auto',
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1),
    },
  },
  toolbar: {
    marginBottom: 0,
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#FFFFFF',
    position: 'sticky',
    top: '0',
    zIndex: 50,
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  editor: {
    paddingInline: theme.spacing(2),
    color: theme.palette.text.primary,
    minHeight: ({ isBigger }: { isBigger: boolean }) =>
      isBigger ? '330px' : '130px',
    maxHeight: ({ isBigger }: { isBigger: boolean }) =>
      isBigger ? '330px' : '130px',
    '&:hover': {
      cursor: 'text',
    },
  },
}));

export default function DraftjsInput({
  className,
  isBigger = false,
  editorClassName,
  ...props
}: any): ReactElement {
  const classes = useStyles({ isBigger });

  return (
    <Controller
      {...props}
      render={({ value, onChange, ...rest }) => (
        <Editor
          wrapperClassName={clsx(editorClassName, classes.root)}
          toolbarClassName={classes.toolbar}
          editorClassName={classes.editor}
          editorState={value}
          onEditorStateChange={onChange}
          placeholder=""
          toolbar={{
            options: ['inline', 'blockType', 'list', 'emoji'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
          }}
          {...rest}
          {...props}
        />
      )}
    />
  );
}

export const DraftjsInputNotControlled = ({ value, onChange }: any) => {
  const classes = useStyles();
  return (
    <Editor
      wrapperClassName={classes.root}
      toolbarClassName={classes.toolbar}
      editorClassName={classes.editor}
      editorState={value}
      onEditorStateChange={onChange}
      toolbar={{
        // options: ['inline', 'blockType', 'fontSize', 'list', 'emoji'],
        options: ['inline', 'blockType', 'list', 'emoji'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
      }}
    />
  );
};
