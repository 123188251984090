import React from 'react';
import { ApolloError } from '@eggmed/graphql-client';
import { Box, Typography } from '@material-ui/core';
import Error from 'pages/ErrorPage';

interface IDataLoaderProps<T> {
  data?: T[];
  loading?: boolean;
  error: ApolloError | undefined;
  children: React.ReactElement;
  isPatient?: boolean;
}

export default function DataLoader<T>({
  error,
  children,
  isPatient = false,
}: IDataLoaderProps<T>) {
  if (error)
    return (
      <Box p={4} display="flex" alignItems="center" justifyContent="center">
        <Error isPatient={isPatient} />
      </Box>
    );
  return children;
}
