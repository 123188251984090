import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import micCallIcon from 'assets/videoActions/mic-icon.svg';
import { ReactComponent as EndCallIcon } from 'assets/videoActions/phone-call.svg';
import videoIcon from 'assets/videoActions/vid-icon.svg';
import enableVideoIcon from 'assets/videoActions/no-vid-icon.svg';
import unMuteIcon from 'assets/videoActions/mic-icon-muted.svg';
import Timer from 'pages/AppointmentPage/Timer';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    // borderBottomRightRadius: '20px',
    // borderBottomLeftRadius: '20px',
    display: 'flex',
    bottom: '0',
    height: (props: any) => (props.isWide ? '100px' : '66px'),
    width: '100%',
    zIndex: 1000,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: ({ isWide }: any) => (isWide ? '90vw' : '100%'),
      height: 66,
      bottom: '1.2vh',
    },
  },
  img: {
    cursor: 'pointer',
  },
  imgDisabled: {
    cursor: 'pointer',
  },
  endBtn: {
    cursor: 'pointer',
    backgroundColor: '#EB5757',
    border: '1px solid #EB5757',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
    padding: '8px',
  },
  timer: {
    position: 'absolute',
    bottom: 20,
    right: 15,
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
  },
}));
interface IVideoActionsProps {
  handleChangeMute: () => void;
  mute: boolean;
  setVideoOff: () => void;
  videoOff: boolean;
  endCallFeature?: boolean;
  handleEndCall?: () => void;
  isWide?: boolean;
  isDoctor?: boolean;
  isPreview?: boolean;
}
const VideoActions = ({
  handleChangeMute,
  mute,
  setVideoOff,
  videoOff,
  endCallFeature = false,
  isWide = false,
  handleEndCall = () => {},
  isDoctor = true,
  isPreview = false,
}: IVideoActionsProps): JSX.Element => {
  const classes = useStyles({ endCallFeature, isWide });

  return (
    <div className={classes.root}>
      {!mute ? (
        <img
          data-cy="mute-icon"
          onClick={handleChangeMute}
          className={classes.img}
          src={micCallIcon}
          alt="micend-icon"
        />
      ) : (
        <img
          data-cy="unmute-icon"
          onClick={handleChangeMute}
          className={classes.imgDisabled}
          src={unMuteIcon}
          alt="micend-icon"
        />
      )}
      {!videoOff ? (
        <img
          data-cy="video-icon"
          onClick={() => {
            setVideoOff();
          }}
          className={classes.img}
          src={videoIcon}
          alt="video-end-icon"
        />
      ) : (
        <img
          data-cy="video-off-icon"
          onClick={() => {
            setVideoOff();
          }}
          style={{ paddingTop: '8px', paddingBottom: '8px' }}
          className={classes.imgDisabled}
          src={enableVideoIcon}
          alt="videomicend-icon"
        />
      )}
      {endCallFeature && (
        <button
          data-cy="end-call-icon"
          onClick={handleEndCall}
          className={classes.endBtn}
        >
          <EndCallIcon />
          End
        </button>
      )}
      {isDoctor && !isPreview && (
        <div className={classes.timer}>
          <Timer isRunning />
        </div>
      )}
    </div>
  );
};

export default VideoActions;
