import { Box } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import React from 'react';

function UpcomingMessagesSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      p="1rem"
      borderBottom="1px solid #EAECF0"
      borderRadius="12px"
      minHeight="99px"
      boxSizing="border-box"
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        width="100%"
      >
        <Skeleton loading={true} type="circle">
          <Box
            style={{
              width: '52px',
              height: '52px',
              borderRadius: '120%',
            }}
          />
        </Skeleton>
        <Box display="flex" flexDirection="column" ml={2}>
          <Skeleton loading={true} type="text" width="120px" height="21px">
            <Box
              style={{
                width: '100px',
                height: '21px',
                borderRadius: '8px',
              }}
            />
          </Skeleton>
          <Skeleton loading={true} type="text" width="120px" height="21px">
            <Box
              style={{
                width: '120px',
                height: '21px',
                borderRadius: '8px',
              }}
            />
          </Skeleton>
          <Skeleton loading={true} type="text" width="170px" height="21px">
            <Box
              style={{
                width: '120px',
                height: '21px',
                borderRadius: '8px',
              }}
            />
          </Skeleton>
        </Box>
      </Box>
      <Box>
        <Skeleton loading={true} type="text" width="80px" height="21px">
          <Box
            style={{
              width: '120px',
              height: '21px',
              borderRadius: '8px',
            }}
          />
        </Skeleton>
      </Box>
    </Box>
  );
}

export default UpcomingMessagesSkeleton;
