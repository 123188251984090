import React, { ReactElement, useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { log } from 'config/log';
import { Box, Grid } from '@material-ui/core';
import { ReactComponent as Error } from 'assets/illustrations/error.svg';
import BasicModal from 'components/ui/Modal';
import AttachmentViewer from 'components/AttachmentViewer';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import SchedulePageProvider from 'pages/SchedulePage/state/SchedulePageProvider';
import PatientInformations from './newDashboard/index';
import InboxProvider from 'pages/InboxPage/state/Provider';

export const GET_PATIENT = gql`
  query PatientData($patientId: ID!) {
    patient(patientId: $patientId) {
      ...Information
      _id
      formatDate
      timeZone
      signature {
        picture
        name
        isGenerated
        index
        updatedAt
      }
      description
      pronouns
      email
      phone
      firstname
      lastname
      birthday
      middlename
      country
      city
      suite
      picture
      createdAt
      zipcode
      completed
      bloodType
      state
      address
      gender
      emergencyName
      emergencyEmail
      emergencyPhone
      religion
      relationshipStatus
      employmentStatus
      raceAndEthnicity
      relationship
      preferredLanguage
      height {
        type
        value
      }
      weight {
        type
        value
      }
    }
  }
  ${PatientInformations.fragments.Information}
`;
export const GET_DOCTOR_ID = gql`
  query DoctorLogedIn {
    userData @client {
      user {
        doctorId
      }
    }
  }
`;

export const ADD_REPORT = gql`
  mutation addReport($ReportInput: ReportInput!, $attachment: Upload!) {
    addReport(ReportInput: $ReportInput, attachment: $attachment)
  }
`;
export const GET_REPORTS = gql`
  query Report($patientId: ID!) {
    reports(patientId: $patientId) {
      name
      _id
      attachment
      details
    }
  }
`;
export const EDIT_PATIENT = gql`
  mutation editPatientMutation(
    $patientId: ID!
    $EditPatientInput: EditPatientInput!
    $picture: Upload
  ) {
    editPatient(
      patientId: $patientId
      EditPatientInput: $EditPatientInput
      picture: $picture
    ) {
      _id
    }
  }
`;
export const DELETE_REPORT = gql`
  mutation deleteReport($reportId: ID!) {
    deleteReport(reportId: $reportId)
  }
`;
export const EDIT_REPORT = gql`
  mutation editReport($reportId: ID!, $EditReportInput: EditReportInput!) {
    editReport(reportId: $reportId, EditReportInput: $EditReportInput)
  }
`;

const PatientProfilePage = (): ReactElement => {
  const [editable, setEditable] = useState(false);
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  const [open, setOpen] = React.useState(false);
  const [currentReport, setCurrentReport] = React.useState();
  const { id } = useParams<{ id: string }>();
  const {
    data: PatientData,
    error,
    loading: PatientLoading,
  } = useQuery(GET_PATIENT, {
    variables: { patientId: id },
  });
  const { data: doctorData } = useQuery(GET_DOCTOR_ID);
  const { data: ReportsData } = useQuery(GET_REPORTS, {
    variables: { patientId: id },
  });

  const [addReport, { loading: addReportLoading }] = useMutation(ADD_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: { patientId: id } }],
    awaitRefetchQueries: false,
  });
  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: { patientId: id } }],
    awaitRefetchQueries: false,
  });
  const [editReport] = useMutation(EDIT_REPORT, {
    refetchQueries: [{ query: GET_REPORTS, variables: { patientId: id } }],
    awaitRefetchQueries: false,
  });

  const { register, reset } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Patient profile' });
  }, [headerTitleDispatcher]);
  register({ name: 'picture' });
  useEffect(() => {
    if (PatientData?.patient) {
      reset({
        ...PatientData?.patient,
      });
    }
  }, [reset, PatientData]);

  if (error)
    return (
      <Box p={4} display="flex" alignItems="center" justifyContent="center">
        <Error />
      </Box>
    );

  function handleOpen() {
    setEditable(!editable);
  }

  async function handleAddReport(file: File) {
    try {
      await addReport({
        variables: {
          attachment: file,
          ReportInput: {
            patientID: id,
            doctorID: doctorData.userData.user.doctorId,
          },
        },
      });
    } catch (error) {
      log('error', error);
    }
  }

  async function handleDeleteReport(reportId: string) {
    try {
      await deleteReport({ variables: { reportId } });
    } catch (error) {
      log('error', error);
    }
  }

  async function handleEditReport(reportEditedData: any, reportId: string) {
    try {
      await editReport({
        variables: { reportId, EditReportInput: reportEditedData },
      });
    } catch (error) {
      log('error', error);
    }
  }
  return (
    <InboxProvider>
      <Grid data-cy="patient-profile" container>
        <PatientInformations
          editable={editable}
          setOpen={setOpen}
          handleOpen={handleOpen}
          setCurrentReport={setCurrentReport}
          loading={PatientLoading}
          reports={ReportsData ? ReportsData?.reports : []}
          register={register}
          handleAddReport={handleAddReport}
          handleDeleteReport={handleDeleteReport}
          addReportLoading={addReportLoading}
          {...PatientData?.patient}
          patient={PatientData}
        />
        <BasicModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <AttachmentViewer
            attachment={currentReport!}
            attachments={ReportsData ? ReportsData?.reports : []}
            handleAddAttachment={handleAddReport}
            handleEditAttachment={handleEditReport}
            handleDeleteAttachment={handleDeleteReport}
            closeModal={() => setOpen(false)}
          />
        </BasicModal>
      </Grid>
    </InboxProvider>
  );
};

export default PatientProfilePage;
