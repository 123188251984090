import React from 'react';

const TableForm = ({ data }) => {
  return (
    <table
      style={{
        border: '1px solid black',
        borderCollapse: 'collapse',
        width: '100%',
      }}
    >
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                style={{ border: '1px solid black', padding: '8px' }}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableForm;
