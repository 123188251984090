import React, { useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { log } from 'config/log';
import clsx from 'clsx';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';

import MeetingTesting from 'components/MeetingTesting';
import { LoadingIllustration } from 'components/ui/LoadingIllustration';
import { NotFoundIllustration } from 'components/ui/NotFoundIllustration';
import WaitingRoom from './WaitingRoom';
import { useProvider } from './state/Provider';
import { ReactComponent as CheckVideo } from 'assets/CheckSound.svg';
import { useTranslation } from 'react-i18next';

export const DOCTOR_ROOM_QUERY = gql`
  query doctorRoomVerification($roomSlug: String, $doctorId: ID!) {
    doctorRoom(roomSlug: $roomSlug, doctorId: $doctorId) {
      _id
      room_id
      doctorId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  blueText: {
    color: '#425364',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '0.44px',
  },
  logo: {
    width: 150,
    margin: '20px',
    position: 'absolute',
    top: 10,
    right: '4vw',
    [theme.breakpoints.down('sm')]: {
      right: '40%',
      width: 100,
    },
    [theme.breakpoints.down('xs')]: {
      right: '35%',
      width: 100,
    },
    [theme.breakpoints.up('md')]: {
      right: '42%',
      width: 110,
    },
    [theme.breakpoints.up('lg')]: {
      width: 150,
      margin: '20px',
      position: 'absolute',
      top: 10,
      right: '4vw',
    },
  },
  topSpacing: {
    marginTop: '150px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  flex: {
    display: 'flex',
  },
  waitingRoomWrapper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  leftPart: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  gridWrapper: {
    marginTop: theme.spacing(2),
  },
  actionWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '24.61px',
    color: '#425364',
    marginBottom: theme.spacing(2),
  },
}));

export const GET_WAITING_ROOM_MEMBERS = gql`
  query waitingRoomMembers($roomId: String) {
    waitingRoomMembers(roomId: $roomId) {
      firstname
      lastname
      _id
      joinedWaitingRoomDate
      startDate
      appointmentId
      picture
    }
  }
`;

export default function DoctorWaitingMeetingPage() {
  const history = useHistory();
  const classes = useStyles();
  const [isStarted, setStarting] = useState(true);
  const {
    waitingPatients,
    roomId,
    roomData,
    roomError,
    roomLoading,
    isWidget,
    handleLeaveWaitingRoom,
  } = useProvider();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  const { t } = useTranslation();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Waiting Room' });
  }, [headerTitleDispatcher]);

  if (roomLoading) return <LoadingIllustration />;
  if (roomError) return <NotFoundIllustration />;

  return (
    <Box maxWidth="100vw" style={{ overflow: 'hidden' }} mb={4} mx={2}>
      <Grid className={classes.gridWrapper} container spacing={8}>
        <Grid
          item
          lg={isWidget ? 12 : 7}
          direction="column"
          md={12}
          sm={12}
          xs={12}
        >
          <Typography className={classes.title}>
            {t('Meet your patient')}
          </Typography>
          <Box display="flex" flexDirection="column">
            <MeetingTesting isStarted={isStarted} isDoctor />
            <Box className={classes.actionWrapper}>
              <Typography className={classes.blueText}>
                <CheckVideo style={{ marginRight: '5px' }} />
                {t('Please check your audio and video before starting')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          className={clsx(classes.flex)}
          item
          lg={isWidget ? 12 : 5}
          md={12}
          sm={12}
          xs={12}
          direction="column"
        >
          <Box className={classes.waitingRoomWrapper}>
            <Typography className={classes.title}>
              {t('Waiting Room')}
            </Typography>
            <WaitingRoom
              handleJoinRoom={async (
                patientId: string,
                appointmentId: string
              ) => {
                log('log', {
                  appointmentId,
                  patientId,
                });
                history.push(
                  `/doctor/patient/${patientId}?event=${appointmentId}&tab=2`,
                  {
                    isStarting: true,
                  }
                );
              }}
              handleLeave={(patientId: string) => {
                handleLeaveWaitingRoom(patientId, roomId);
              }}
              patients={waitingPatients}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
