import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Avatar,
  Button,
} from '@material-ui/core';
import {
  convertToRawAndStringify,
  createEmptyDraftjs,
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common';
import { gql, useQuery, useMutation } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useFormGroup from 'hooks/useFormGroup';
import { useForm } from 'react-hook-form';
import { GeneralInformationForm } from 'pages/AddPatientPage/AddPatientforms';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import EditButtons from 'components/EditButtons';
import { useParams } from 'react-router-dom';
import { GET_PATIENT_AUTH } from 'contexts/AuthContext';
import { IPatientProps } from 'components/PatientProfile/PatientTabs/PatientTabsContent';
import { useToast } from 'hooks/useToast';
import { heightTypeEnum } from 'pages/AddPatientPage/AddPatientModalForm';
import {
  cmToInches,
  cmTofeet,
  cmToFeetAndInches,
} from 'pages/PatientGeneralInfo/constant';
import { useSnackbar } from 'hooks/useSnackbar';

export const EDIT_PATIENT_OWNER = gql`
  mutation editPatientOwnerMutation(
    $patientId: ID!
    $EditPatientInput: EditPatientInput
    $picture: Upload
  ) {
    editPatientOwner(
      patientId: $patientId
      EditPatientInput: $EditPatientInput
      picture: $picture
    ) {
      _id
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  boxContainer: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '20px',
    padding: '40px 20px',
  },
  name: {
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: '29px',
    lineHeight: '24px',
    color: '#425364',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    border: '2px solid #41A8FF',
    width: '133px',
    height: '133px',
    marginBottom: theme.spacing(2),
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2DC76D',
    borderRadius: '40px',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    padding: '10px 70px',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: theme.spacing(2),
  },
  note: {
    display: 'flex',
    justifyContent: 'center',
    width: '65%',
    textAlign: 'center',
    alignItems: 'center',
    color: '#567FBD',
    backgroundColor: '#EFF6FF',
    borderRadius: '10px',
    padding: '15px 20px',
    marginBottom: theme.spacing(2),
  },
  member: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#9CB2CD',
  },
  box: {
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : '#FFFFFF',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    padding: '0px 20px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    gap: '2rem',
  },
  col: {
    maxWidth: 'calc(50% - 1rem)',
  },
}));
const GeneralInfoPatient = ({
  patientId,
  isPatient = false,
}: IPatientProps) => {
  const { openSuccess } = useToast();
  const { triggerSnack } = useSnackbar();
  const classes = useStyles({ openSuccess });
  const [heightType, setHeightType] = useState<heightTypeEnum>(
    heightTypeEnum.cm
  );
  const { patient } = useAuth();
  const {
    height,
    weight,
    bloodType,
    birthday,
    description: descrptionPatient,
    ...restPatient
  } = patient || {};

  const { feet, inches } = cmToFeetAndInches(parseInt(height?.value));
  const defaultValues = {
    ...restPatient,
    birthday: timeConverter(
      birthday || new Date().getMilliseconds().toString()
    ),
    description:
      editorStateFromStringifiedRaw(
        descrptionPatient,
        !tryParseJSON(patient?.description)
      ) || createEmptyDraftjs(),
    heightType: height?.type || 'cm',
    weightType: weight?.type || 'kg',
    bloodType: bloodType || 'A',
    height: height?.value,
    weight: weight?.value,
    ft: feet,
    inch: inches,
  };

  const { register, errors, handleSubmit, control, reset } = useForm({
    // resolver: yupResolver(schema),
    defaultValues,
  });
  useEffect(() => {
    if (patient)
      reset({
        ...restPatient,
        birthday: timeConverter(
          birthday || new Date().getMilliseconds().toString()
        ),
        description:
          editorStateFromStringifiedRaw(
            descrptionPatient,
            !tryParseJSON(patient?.description)
          ) || createEmptyDraftjs(),
        heightType: height?.type || 'cm',
        weightType: weight?.type || 'kg',
        bloodType: bloodType || 'A',
        height: height?.value,
        weight: weight?.value,
      });
  }, [patient, reset]);
  const { generalInfo, address, description, basicInfo } =
    GeneralInformationForm(errors, defaultValues, setHeightType, heightType);
  const { forms: generalInfoForm } = useFormGroup({
    inputs: generalInfo,
    register,
    control,
  });
  const { forms: addressForm } = useFormGroup({
    inputs: address,
    register,
    control,
  });
  const { forms: descriptionForm } = useFormGroup({
    inputs: description,
    register,
    control,
    patient: true,
  });
  const { forms: basicInfoForm } = useFormGroup({
    inputs: basicInfo,
    register,
    control,
  });
  const [editPatient] = useMutation(EDIT_PATIENT_OWNER, {
    refetchQueries: [{ query: GET_PATIENT_AUTH }],
    awaitRefetchQueries: false,
  });
  const handleAdd = (data) => {
    const { ft, inch, ...filteredData } = data;
    const cm = ft * cmTofeet + inch * cmToInches;
    const height =
      heightType === heightTypeEnum.cm ? data.height : Math.floor(cm);
    editPatient({
      variables: {
        patientId: patientId,
        EditPatientInput: {
          ...filteredData,
          height: {
            type: heightType,
            value: height.toString(),
          },
          weight: {
            type: data?.weightType,
            value: data?.weight,
          },
          description: convertToRawAndStringify(data?.description),
        },
        picture: typeof data?.picture === 'string' ? undefined : data?.picture,
      },
    }).then(() => triggerSnack());
  };
  useEffect(() => {
    setHeightType(defaultValues?.heightType as heightTypeEnum);
  }, [defaultValues?.heightType]);

  return (
    <form onSubmit={handleSubmit(handleAdd)}>
      <Box className={classes.box}>
        <Box className={classes.row}>
          <Box className={classes.col}>{generalInfoForm}</Box>
          <Box className={classes.col}>{basicInfoForm}</Box>
        </Box>
        <Box className={classes.row}>
          <Box className={classes.col}>{addressForm}</Box>
          <Box className={classes.col}>{descriptionForm}</Box>
        </Box>
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" ml={4} mt={3}>
        <EditButtons
          editable
          isPatient={isPatient}
          handleOpen={(e: any) => e.preventDefault()}
          px="3rem"
        />
      </Box>
    </form>
  );
};

export default GeneralInfoPatient;
