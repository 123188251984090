import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as ReflectionIcon } from 'assets/patient-profile/response/reflection.svg';
import { ReactComponent as MoodIcon } from 'assets/patient-profile/response/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patient-profile/response/sleep.svg';
import { ReactComponent as FormIcon } from 'assets/patientActivities/forms.svg';
import { useStyles } from '../styles';
import { modalTitle } from 'pages/PatientActivities/commun/ResultModal';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { IActivitySource, IActivityToOpen } from '..';
import { useTranslation } from 'react-i18next';

interface IFormHeadProps {
  activityType: IActivityToOpen;
  control: any;
  watch: any;
  assignedAt?: string;
}

export const FormHead = ({
  activityType,
  watch,
  control,
  assignedAt,
}: IFormHeadProps) => {
  const classes = useStyles();
  const submitDate = watch('submitDate');
  const { t } = useTranslation();

  const modalIcon = {
    [ActivitiesTypes.MOOD]: () => <MoodIcon />,
    [ActivitiesTypes.SLEEP]: () => <SleepIcon />,
    [ActivitiesTypes.REFLECTION]: () => <ReflectionIcon />,
    [ActivitiesTypes.FORM]: () => <FormIcon />,
  };

  const title = modalTitle(activityType?.type);

  if (activityType?.type === ActivitiesTypes.FORM) {
    return (
      <Box borderBottom="1px solid #D0D5DD">
        <Box display="flex" alignItems="center" gridGap="0.8rem" py="1rem">
          <>{modalIcon[activityType?.type]()}</>
          <Box>
            <Typography className={classes.viewTitle}>
              {title ? t(title) : ''}
            </Typography>
            <Typography className={classes.viewDate}>
              {dayjs(assignedAt).format('MMM D, YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderBottom="1px solid #EAECF0"
      pb={2}
      pt={5}
    >
      {modalIcon[activityType?.type]()}
      <Typography className={classes.formHeadTitle}>
        {title ? t(title) : ''}
      </Typography>
      <Box display="flex" alignItems="center" gridGap="0.3rem">
        <Typography className={classes.formHeadSubtitle}>
          {dayjs(submitDate).format('MMM D, YYYY')}{' '}
        </Typography>

        {activityType.source == IActivitySource.LOG && (
          <Box mb="2px">
            <Controller
              control={control}
              name="submitDate"
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  data-cy="date-input"
                  timeIntervals={60}
                  customInput={
                    <Typography className={classes.editFormHead}>
                      {t('Edit')}
                    </Typography>
                  }
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
