import {
  createStyles,
  Switch as SwitchMUI,
  SwitchClassKey,
  SwitchProps,
  withStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  classNameRoot?: string;
  classNameThumb?: string;
  classNameSwitchBase?: string;
  disabled?: boolean;
}

const Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 21,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 2,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#0265DC',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#0265DC',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 12,
      backgroundColor: '#F2F4F7',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {
      opacity: 0.5,
    },
    checked: {},
    focusVisible: {},
  })
)(
  ({
    classes,
    classNameRoot,
    classNameThumb,
    classNameSwitchBase,
    disabled,
    ...props
  }: Props) => {
    return (
      <SwitchMUI
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: clsx(classNameRoot, classes.root, {
            [classes.disabled]: disabled,
          }),
          switchBase: clsx(classes.switchBase, classNameSwitchBase),
          thumb: clsx(classNameThumb, classes.thumb),
          track: classes.track,
          checked: classes.checked,
        }}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default Switch;
