import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Redirect } from 'react-router-dom';
import { Editor } from 'draft-js';
import * as yup from 'yup';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import BasicModal from 'components/ui/Modal';
import AddEditForm from 'components/AddEditForm';
import MissingInformation from 'components/MissingInformation';
import useConditions from 'graphql/operations/patientOperations/useConditions';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  convertStringToEditorState,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertToRawAndStringify,
  convertToPlainText,
} from '@eggmed/common/utils/DraftUtils';
import { formatDate, changeKeyValue } from 'utils';
import { EditorState } from 'draft-js';
import { MedicationConditionsForm } from './ConditionForm';
import Button from 'components/ui/Button';
import IconButton from 'components/ui/IconButton';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Menu from 'components/ui/Menu';
import DataLoader from 'components/ui/DataLoader';
import { IPatientProps } from 'components/PatientProfile/PatientTabs/PatientTabsContent';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import TrashIcon from 'assets/TrashIcon';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyCondition } from 'assets/emptyState/conditions.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

const formSchema = {
  name: yup.string().required('Name is a required field'),
  type: yup.string().required('Type is a required field'),
  dateAdded: yup
    .date()
    .required('Data added field required')
    .when('type', {
      is: (type) => type === 'Past',
      then: yup
        .date()
        .max(yup.ref('dateEnded'), "Date can't be after date ended"),
    }),
  dateEnded: yup.date().when('type', {
    is: (type) => type === 'Past',
    then: yup.date().required('Date ended is a required field'),
  }),
};
export const fieldsSchema = yup.object().shape({
  array: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});

const useStyles = makeStyles((theme) => ({
  bigTitle: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: '#425364',
    marginTop: '0.5em',
  },
  tableHeaderText: {
    fontSize: '22px',
    lineHeight: '25px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: '#425364',
  },
  tableColumnLabel: {
    fontSize: '20px',
    lineHeight: '25px',
    color: '#425364',
  },
  secondary: {
    color: '#425364',
  },
  modal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '70vh',
    },
  },
  editButton: {
    display: 'flex',
    border: '1px solid #425364',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: '6px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '16px',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    color: '#425364',
    padding: '5.5px 16.5px',
    '&:hover ': {
      border: '1px solid White',
      backgroundColor: '#2DC76D',
      color: 'white',
      '& svg': {
        '& path': {
          stroke: 'white',
        },
      },
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
  },
  editIcon: {
    width: '15px',
    height: '25px',
    paddingRight: '5px',
    paddingBottom: '2px',
  },
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  treatmentToDate: {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '250px',
    overflow: 'hidden',
  },
  conditionstModal: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '37vw',
    },
  },
}));

export default function PatientConditions({
  patientId,
  isPatient = false,
}: IPatientProps): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ isPatient });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(1);
  function handlePagination(e, _page) {
    setPage(_page);
  }
  const {
    handleAddCondition,
    handleDeleteCondition,
    handleEditCondition,
    data: PatientConditionsData,
    error: ConditionsError,
    loading: ConditionsLoading,
    loadingAddMutation,
    loadingEditMutation,
  } = useConditions({ patientId });

  function closeEditModal() {
    setOpen(false);
  }
  function openModal() {
    setSelectedRow(null);
    setOpen(true);
  }
  function openEditModal() {
    handleCloseMenu();
    setOpen(true);
  }
  function getConditionsByType(conditions: any, type: string) {
    return (
      conditions &&
      conditions.filter((condition: any) => condition.type === type)
    );
  }

  function handleAddConditions(conditions: any[]) {
    const currentConditions = conditions || [];
    if (currentConditions.length < 0) return;
    currentConditions.forEach((condition: any) => {
      handleAddCondition(condition);
    });
  }
  function handleEditConditions(conditions: any[]) {
    const currentConditions = conditions || [];

    if (currentConditions.length < 0) return;
    currentConditions.forEach((condition: any) => {
      handleEditCondition(condition);
    });
  }
  function handleAddEditConditions(
    newConditions: any[],
    editedConditions: any[]
  ) {
    handleAddConditions(newConditions);
    handleEditConditions(editedConditions);
    setTimeout(() => closeEditModal(), 1000);
  }
  const handelDeleteCondition = (condition: string) => {
    handleDeleteCondition(condition);
    setOpenDeleteModal(false);
    handleCloseMenu();
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const MenuElementsData = [
    {
      cy: 'edit-condition',
      avatar: <EditIcon />,
      name: t('Edit'),
      onClick: () => openEditModal(),
    },
    {
      cy: 'delete-condition',
      avatar: <TrashIcon width="20px" height="20px" />,
      name: t('Delete'),
      onClick: () => handleOpenDeleteModal(),
    },
  ];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    selectedId: string
  ) => {
    setSelectedRow(selectedId);
    setAnchorEl(event.currentTarget);
  };
  const columns: IColumn<any>[] = [
    {
      id: 'name',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="conditions">Condition(s)</Text>
        </Box>
      ),
      align: 'left',
    },
    {
      id: 'dateAdded',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="from">From</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => <Box>{formatDate(value)}</Box>,
    },
    {
      id: 'dateEnded',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="to">To</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => <Box>{value ? formatDate(value) : t('Present')}</Box>,
    },
    {
      id: 'treatments',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="treatmentToDate">Treatment to date</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 400,
      format: (value) => (
        <Box>
          {!tryParseJSON(value) ? (
            <p className={classes.secondary}>-</p>
          ) : (
            <Typography className={classes.treatmentToDate}>
              {convertToPlainText(editorStateFromStringifiedRaw(value, false))}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      id: 'type',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="type">Type</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => <Box>{t(value)}</Box>,
    },
    {
      id: '_id',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="action">Action</Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <IconButton onClick={(e) => onOpenMenu(e, value)}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataLoader
      loading={ConditionsLoading}
      error={ConditionsError}
      data={PatientConditionsData}
    >
      <>
        <Box display="flex" pb="15px" alignItems="center" pt="13px">
          <Skeleton loading={ConditionsLoading}>
            <Text i18nKey="conditions" className={classes.title}>
              Conditions
            </Text>
          </Skeleton>
          {!!PatientConditionsData?.conditions?.length && (
            <Box
              display="flex"
              justifyContent={'flex-end'}
              alignContent={'center'}
              alignItems={'center'}
              width="100%"
            >
              <Button
                className={classes.addTask}
                onClick={openModal}
                data-cy="add-condition"
              >
                <AddTaskIcon style={{ paddingRight: '6px', width: '1.3rem' }} />
                <Text i18nKey="add">Add</Text>
              </Button>
            </Box>
          )}
        </Box>

        <>
          <Table<any>
            loading={ConditionsLoading}
            pagination
            limit={5}
            page={page}
            columns={columns}
            EmptyState={
              <Box py="5rem">
                <EmptyState
                  icon={<EmptyCondition />}
                  title={t('No conditions')}
                  text={t('to add a condition')}
                  onClick={openModal}
                  isText
                  isClickLeft
                />
              </Box>
            }
            handlePagination={handlePagination}
            data={
              PatientConditionsData ? PatientConditionsData?.conditions : []
            }
          />
          <Menu<any>
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={!!anchorEl}
            handleMenuClose={handleCloseMenu}
            MenuElements={MenuElementsData}
          />
        </>
        <ModalDelete
          text="condition"
          open={openDeleteModal}
          onClose={() => handleCloseDeleteModal()}
          onDelete={() => handelDeleteCondition(selectedRow)}
          isPatient={isPatient}
        />
        <BasicModal
          isSlide
          open={open}
          onClose={closeEditModal}
          isRegularModal
          title={selectedRow ? t('Edit condition') : t('Add a condition')}
          titlePadding="2rem"
          handleClose={closeEditModal}
          className={classes.conditionstModal}
        >
          <AddEditForm
            isConditions={true}
            isPatient={isPatient}
            wrapper
            loading={loadingAddMutation || loadingEditMutation}
            onClose={closeEditModal}
            styleScrollContainer={classes.ScrollContainer}
            defaultValues={
              selectedRow &&
              PatientConditionsData.conditions
                .map((condition: any) =>
                  changeKeyValue({
                    key: 'treatments',
                    callback: convertStringToEditorState,
                    object: condition,
                  })
                )
                .filter((condition) => condition._id === selectedRow)
            }
            schema={fieldsSchema}
            addButton={false}
            deleteButton={false}
            handleAdd={handleAddEditConditions}
            handleDelete={handleDeleteCondition}
            FormOptions={MedicationConditionsForm}
            isEditableDraftjs
            cols={5}
            xl={3}
            margin={4}
            marginTop={2}
            mx="auto"
            right="4rem"
            left="4rem"
            mode="edit"
            cellHeight={100}
            lastField="Treatment"
            fields={[
              { fieldName: 'Condition', size: 6 },
              { fieldName: 'Type', size: 3 },
              { fieldName: 'Date', size: 3 },
              // { fieldName: 'Treatment', size: 6 },
            ]}
            // width="95%"
          />
        </BasicModal>
      </>
    </DataLoader>
  );
}
