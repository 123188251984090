import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import useJoinDoctorWaitingRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useJoinDoctorWaitingRoom';
import useLeaveDoctorWaitingRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useLeaveDoctorWaitingRoom';
import useLeaveWaitingRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useLeaveWaitingRoom';
import useListenLeavedWaitingRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useListenLeavedWaitingRoom';
import useJoinVideoRoomWithPatient from '@eggmed/graphql-client/operations/videoRoomOperations/useJoinVideoRoomWithPatient';
import { usePatientJoinedWaitingRoom } from '@eggmed/graphql-client/operations/videoRoomOperations/usePatientJoinedWaitingRoom';
import { log } from 'config';
import {
  DOCTOR_ROOM_QUERY,
  GET_WAITING_ROOM_MEMBERS,
} from '../DoctorMeetingPage';

interface IUseDoctorWaitingRoomActions {
  doctorId: string;
  roomSlug: string;
  handleSetWaitingMembers: (patients: any, isMobile: boolean) => void;
}
export default function useDoctorWaitingRoomActions({
  doctorId,
  roomSlug,
  handleSetWaitingMembers,
}: IUseDoctorWaitingRoomActions) {
  // TODO: remove the waiting room part. It's not needed after the UX change
  const [isMobile, setIsMobile] = useState(false);
  const {
    data: roomData,
    error: roomError,
    loading: roomLoading,
  } = useQuery(DOCTOR_ROOM_QUERY, {
    variables: { roomSlug, doctorId },
  });
  const roomId = roomData?.doctorRoom?._id;
  const { data: waitingMembersData } = useQuery(GET_WAITING_ROOM_MEMBERS, {
    variables: { roomId },
  });
  const { handleJoinDoctorWaitingRoom } = useJoinDoctorWaitingRoom();
  const { handleLeaveDoctorWaitingRoom } = useLeaveDoctorWaitingRoom();
  const { handleLeaveWaitingRoom } = useLeaveWaitingRoom();
  usePatientJoinedWaitingRoom({
    roomId,
    handleNewPatientJoined: (patients: any, isMobile: boolean) => {
      handleSetWaitingMembers(patients, isMobile);
      setIsMobile(isMobile);
    },
  });
  useListenLeavedWaitingRoom({
    roomId,
    handlePatientWaitingListsJoined: (patients: any, isMobile: boolean) => {
      handleSetWaitingMembers(patients, false);
    },
  });

  function onPageLeave() {
    window.addEventListener('beforeunload', (ev) => {
      // ev.preventDefault();
      return handleLeaveDoctorWaitingRoom(roomId, (e: any) => {
        log('error', e);
      });
    });
  }
  useEffect(() => {
    handleSetWaitingMembers(waitingMembersData?.waitingRoomMembers, isMobile);
  }, [waitingMembersData]);
  useEffect(() => {
    onPageLeave();
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [roomId]);
  useEffect(() => {
    if (roomId) {
      handleJoinDoctorWaitingRoom(roomId);
    }
  }, [roomId, roomData]);
  return {
    handleJoinDoctorWaitingRoom,
    handleLeaveDoctorWaitingRoom,
    handleLeaveWaitingRoom,
    roomData,
    waitingMembersData,
    roomId,
    roomError,
    roomLoading,
    isMobile,
  };
}
