import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { Avatar as AvatarMUI } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: '10px',
  },
  title: {
    fontSize: '1.25rem',
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    color: '#425364',
  },
  dropZone: {
    width: '15vw',
    border: 'dashed 2px #39B54A',
  },
  dropZoneText: {
    color: '#39B54A',
  },
  dropZoneArea: {},
  fileUpload: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #D7E3F1',
    borderRadius: '12px',
    padding: theme.spacing(2),
    display: 'flex',
  },
  text: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
  },
}));
export default function Avatar({ open, onSave, onClose, filesLimit = 1 }: any) {
  const classes = useStyles();
  const [fileObjects, setFileObjects] = React.useState<any>([]);
  function onAddPicture(newFileObjs: any) {
    if (fileObjects.length > 0) return null;
    setFileObjects([].concat(fileObjects, newFileObjs));
    return null;
  }
  function onDeletePicture(deleteFileObj: any) {
    const filltredArray = fileObjects.filter(
      (value: any, index: any) => value !== deleteFileObj
    );
    setFileObjects(filltredArray);
  }
  function handleSave() {
    onSave(fileObjects);
  }

  return (
    <>
      <AvatarMUI
        src={fileObjects && fileObjects[0]?.data}
        className={classes.large}
        alt="Remy Sharp"
      ></AvatarMUI>
      <DropzoneDialogBase
        dialogTitle="Upload your picture"
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={fileObjects}
        filesLimit={filesLimit}
        cancelButtonText="cancel"
        submitButtonText="submit"
        maxFileSize={5000000}
        open={open}
        onAdd={onAddPicture}
        onDelete={onDeletePicture}
        onClose={onClose}
        onSave={handleSave}
        showPreviews
        showFileNamesInPreview={false}
        showAlerts={['error', 'info']}
      />
    </>
  );
}
