import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { log } from 'config/log';
import useAddAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useAddAppointment';
import useAddEvent from '@eggmed/graphql-client/operations/eventOperations/useAddEvent';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import useDeleteEvent from '@eggmed/graphql-client/operations/eventOperations/useDeleteEvent';
import useEditAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useEditAppointment';
import useEditEvent from '@eggmed/graphql-client/operations/eventOperations/useEditEvent';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { convertDateLikeMeet } from 'utils/dateUtils';
import React, { useReducer, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  DATE_ACTION,
  VIEW_ACTION,
  EVENT_ACTION,
  EDIT_MODE_ACTION,
  MODAL_ADD_ACTION,
  DATE_RANGE_ACTION,
  CURRENT_DATE_ACTION,
  CLOSE_NOT_USED,
  RESET_CURRENT_EVENT,
  SET_BACK,
  OPEN_PATIENT_MODAL,
  CLOSE_PATIENT_MODAL,
  CHANGE_TAGS,
  ADD_PATIENT,
  Manage_CONFLICT,
} from './actions';
import { GET_EVENT_DATA } from '../SchedulePage';
import reducer, { Context, initialState } from './reducer';
import { useLocation } from 'react-router-dom';
import { GET_APPOINTMENT } from 'graphql/queries/appointment';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import { useTranslation } from 'react-i18next';

// TODO: Split this
export default function Provider({ children, initialValue }: any) {
  const { t } = useTranslation();
  const initialModalTitle = t('Add new client');

  const params = useLocation<{
    id?: string;
    appointmentId?: string;
  }>();
  const [clickableEvent, setClickableEvent] = React.useState<{
    startDate?: string;
  }>({});
  const [patientModalTitle, setPatientModalTitle] =
    React.useState<string>(initialModalTitle);
  const [customValue, setCustomValue] = React.useState(0);
  const [getAppointment] = useLazyQuery(GET_APPOINTMENT, {
    onCompleted: (data) => {
      data?.appointment && handleEditCurrentEvent(data?.appointment);
    },
  });
  const [getAppointmentdate] = useLazyQuery(GET_APPOINTMENT, {
    onCompleted: (data) => {
      data?.appointment &&
        handleChangeCurrentDate(data?.appointment?.startDate);
    },
  });

  useEffect(() => {
    if (params?.state?.id) {
      getAppointment({ variables: { appointmentId: params?.state?.id } });
    }
  }, [params?.state?.id]);

  useEffect(() => {
    if (params?.state?.appointmentId) {
      getAppointmentdate({
        variables: { appointmentId: params?.state?.appointmentId },
      });
    }
  }, [params?.state?.appointmentId]);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialValue,
  });
  const { user, doctor: doctorData, patient } = useAuth();
  const { data: doctor } = useGetDoctor({ id: doctorData?._id });

  function handleOpenPatientModal(title?: string) {
    title
      ? setPatientModalTitle(title)
      : setPatientModalTitle(initialModalTitle);
    dispatch({ type: OPEN_PATIENT_MODAL });
  }
  function handleClosePatientModal() {
    dispatch({ type: CLOSE_PATIENT_MODAL });
  }

  const viewRange = {
    timeGridDay: {
      startDate: dayjs(state?.currentDate).startOf('day').valueOf(),
      endDate: dayjs(state?.currentDate).endOf('day').valueOf(),
    },
    timeGridWeek: {
      startDate: dayjs(state?.currentDate).startOf('week').valueOf(),
      endDate: dayjs(state?.currentDate).endOf('week').valueOf(),
    },
    dayGridMonth: {
      startDate: dayjs(state?.currentDate).startOf('month').valueOf(),
      endDate: dayjs(state?.currentDate).endOf('month').valueOf(),
    },
  };
  

  const { deleteAppointment, loading: loadingDeleteAppointment } =
    useDeleteAppointment();
  const { deleteEvent, loading: loadingDeleteEvent } = useDeleteEvent();
  const { addAppointment, loading: loadingAddAppointment } =
    useAddAppointment();
  const { addEvent, loading: loadingAddEvent } = useAddEvent();
  const {
    updateAppointment,
    editAppointmentWithoutUpdate,
    loading: loadingEditAppointment,
  } = useEditAppointment();
  const {
    updateEvent,
    editEventWithoutUpdate,
    loading: loadingEditEvent,
  } = useEditEvent();
  /** View methods */

  function handleChangeView(view: string) {
    dispatch({ type: VIEW_ACTION, payload: view });
  }
  function handleBack(state: boolean) {
    dispatch({ type: SET_BACK, payload: state });
  }

  /** Date state methods */
  function handleChangeCurrentDate(date: any) {
    dispatch({
      type: CURRENT_DATE_ACTION,
      payload: date,
    });
  }
  function handleChangeDate(date: any) {
    dispatch({ type: DATE_ACTION, payload: date });
  }
  function handleChangeRangeDates(startDate: any, endData: any) {
    dispatch({
      type: DATE_RANGE_ACTION,
      payload: [`${startDate}`, `${endData}`],
    });
  }

  /** Event form */

  function handleEditCurrentEvent(event: any) {
    dispatch({ type: EVENT_ACTION, payload: event });
    dispatch({ type: EDIT_MODE_ACTION, payload: true });
    dispatch({ type: MODAL_ADD_ACTION, payload: true });
    if (event?.title) {
      setCustomValue(1);
    } else {
      setCustomValue(0);
    }
  }
  function handleOpenConflictState(payload: boolean) {
    dispatch({ type: Manage_CONFLICT, payload });
  }
  function handleOpenAddModal() {
    setSelectedColorId(null);
    dispatch({ type: MODAL_ADD_ACTION, payload: true });
    if (state?.currentEvent?.title) {
      setCustomValue(1);
    } else {
      setCustomValue(0);
    }
  }

  function handleCloseAddModal() {
    dispatch({ type: MODAL_ADD_ACTION, payload: false });
  }

  function handleCloseEditMode() {
    dispatch({ type: MODAL_ADD_ACTION, payload: false });
    dispatch({ type: EDIT_MODE_ACTION, payload: false });
    dispatch({ type: RESET_CURRENT_EVENT });
    handleOpenConflictState(false);
  }

  async function handleAddAppointment(data: any) {
    const result = await addAppointment(data, (e: any) => {
      log('error', e);
      return e;
    });
    return result;
  }
  async function handleAddEvent(data: any) {
    const result = await addEvent(data, (e: any) => {
      log('error', e);
      return e;
    });
    return result;
  }

  async function handleDeleteAppointment(id: string, isOne: boolean) {
    const result = await deleteAppointment(id, isOne, (e: any) => {
      log('error', e);
      return e;
    });
    return result;
  }
  async function handleDeleteEvent(id: string, isOne: boolean) {
    const result = await deleteEvent(id, isOne, (e: any) => {
      log('error', e);
      return e;
    });
    return result;
  }
  async function handleUpdateAppointment(
    data: any,
    id: string,
    isOne: boolean
  ) {
    const result = await updateAppointment(
      state?.currentEvent?._id ?? id,
      data,
      (e: any) => {
        log('error', e);
        return e;
      },
      isOne
    );
    return result;
  }
  async function handleUpdateEvent(data: any, id: string, isOne: boolean) {
    const result = await updateEvent(
      data,
      state?.currentEvent?._id ?? id,
      isOne,
      (e: any) => {
        log('error', e);
        return e;
      }
    );
    return result;
  }
  const timezone = doctor?.doctor?.timeZone;
  const offsetMinutes = dayjs().tz(timezone).utcOffset();
  const offsetHours = -offsetMinutes / 60;
  async function handleDropEvent({
    event: {
      start,
      end,
      title,
      extendedProps: { _id },
    },
  }: any) {
    const startingTime = new Date(start);
    const currentStartingHour = startingTime.getHours();

    // Convert minutes to hours (and adjust for DST if needed)
    const adjustedHourStart = currentStartingHour + offsetHours; // Adjusted to EDT (GMT-4) from current timezone (GMT+2)
    const adjustedStartTime = new Date(startingTime);
    adjustedStartTime.setHours(adjustedHourStart);

    const endingTime = new Date(end);
    const currentHourEnd = endingTime.getHours();

    // Convert minutes to hours (and adjust for DST if needed)
    const adjustedHourEnd = currentHourEnd + offsetHours; // Adjusted to EDT (GMT-4) from current timezone (GMT+2)
    const adjustedEndTime = new Date(endingTime);
    adjustedEndTime.setHours(adjustedHourEnd);
    if (!title) {
      return await editAppointmentWithoutUpdate(
        _id,
        { startDate: adjustedStartTime, endDate: adjustedEndTime },
        (e: any) => {
          log('error', e);
        }
      );
    }
    return await editEventWithoutUpdate(
      { startDate: adjustedStartTime, endDate: adjustedEndTime },
      _id,
      true,
      (e: any) => {
        log('error', e);
      }
    );
  }

  async function handleResizeEvent({
    event: {
      start,
      end,
      title,
      extendedProps: { _id },
    },
  }: any) {
    const startingTime = new Date(start);
    const currentStartingHour = startingTime.getHours();

    // Convert minutes to hours (and adjust for DST if needed)
    const adjustedHourStart = currentStartingHour + offsetHours; // Adjusted to EDT (GMT-4) from current timezone (GMT+2)
    const adjustedStartTime = new Date(startingTime);
    adjustedStartTime.setHours(adjustedHourStart);

    const endingTime = new Date(end);
    const currentHourEnd = endingTime.getHours();

    // Convert minutes to hours (and adjust for DST if needed)
    const adjustedHourEnd = currentHourEnd + offsetHours; // Adjusted to EDT (GMT-4) from current timezone (GMT+2)
    const adjustedEndTime = new Date(endingTime);
    adjustedEndTime.setHours(adjustedHourEnd);
    if (!title) {
      return await editAppointmentWithoutUpdate(
        _id,
        { startDate: adjustedStartTime, endDate: adjustedEndTime },
        (e: any) => {
          log('error', e);
        }
      );
    }
    return await editEventWithoutUpdate(
      { startDate: adjustedStartTime, endDate: adjustedEndTime },
      _id,
      true,
      (e: any) => {
        log('error', e);
      }
    );
  }
  function handleCloseNotUsedModal() {
    dispatch({ type: CLOSE_NOT_USED });
  }
  const [testIndex, setTestIndex] = React.useState(false);
  const [expanded, setExpanded] = React.useState(
    state?.currentEvent?.repeatEvery ? true : false
  );
  function handleAccordionChange() {
    setExpanded((expand) => !expand);
  }
  const [frequencyExpand, setFrequencyExpand] = React.useState(
    state?.currentEvent?.repeatEvery ? true : false
  );
  React.useEffect(() => {
    if (state?.currentEvent?.repeatEvery) {
      setFrequencyExpand(true);
    }
  }, [state?.currentEvent?.repeatEvery]);
  function handleFrequencyAccordionChange() {
    setFrequencyExpand((expand) => !expand);
  }
  const [eventRange, setEventRange] = React.useState({
    startDate: convertDateLikeMeet(Date.now()),
    endDate: convertDateLikeMeet(Date.now()),
  });
  const [start, setStart] = React.useState(convertDateLikeMeet(Date.now()));
  const [end, setEnd] = React.useState(
    new Date(new Date(start).setHours(new Date(start).getHours() + 1))
  );
  useEffect(() => {
    if (start) {
      setEnd(
        new Date(new Date(start).setHours(new Date(start).getHours() + 1))
      );
    }
  }, [start]);
  React.useEffect(() => {
    setEventRange((events) => ({
      ...events,
      endDate: events?.startDate,
    }));
  }, [eventRange?.startDate]);
  useEffect(() => {
    if (state?.editMode && state?.currentEvent?.title) {
      setEventRange({
        startDate: new Date(state?.currentEvent?.startDate),
        endDate: new Date(state?.currentEvent?.endDate),
      });
      setStart(new Date(state?.currentEvent?.startDate));
      setEnd(new Date(state?.currentEvent?.endDate));
    }
  }, [state?.editMode, state?.currentEvent?.title]);
  function handleChangeStartDate(
    name: 'endDate' | 'startDate',
    value: any
  ): void {
    setEventRange((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange[name] = value;
      handleChangeCurrentDate(newDateRange[name]);
      return newDateRange;
    });
  }
  function handleChangeEndDate(
    name: 'endDate' | 'startDate',
    value: any
  ): void {
    setEventRange((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange['endDate'] = value;
      return newDateRange;
    });
  }
  function handleChangeStart(date) {
    setStart(date);
  }
  function handleChangeEnd(date) {
    setEnd(date);
  }
  const [weekend, setWeekEnd] = React.useState(true);
  function onChangeWeekEnd() {
    if (state?.view !== 'timeGridDay') {
      setWeekEnd((week) => !week);
    }
  }

  const { data: conflictsAppointments } = useQuery(CONFLICT_APPOINTMENT);

  const conflicts = conflictsAppointments?.conflictsAppointments;
  const handelDelete = async (id: string) => {
    if (state?.currentEvent?.title) {
      await deleteEvent(id, true, null);
    } else {
      await deleteAppointment(id, true, null);
    }
    setOpenDeleteModal(false);
  };
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  function handleCloseDeleteModal() {
    setOpenDeleteModal(false);
  }
  function handleOpenDelete() {
    setOpenDeleteModal(true);
  }
  function setSelectedColorId(payload) {
    dispatch({
      type: CHANGE_TAGS,
      payload,
    });
  }
  function setPatientAdded(payload) {
    dispatch({
      type: ADD_PATIENT,
      payload,
    });
  }
  React.useEffect(() => {
    if (state.editMode) {
      setSelectedColorId(state.currentEvent?.conditionType?._id);
    }
  }, [state.editMode, state.currentEvent?.conditionType?._id]);

  return (
    <Context.Provider
      value={{
        ...state,
        user,
        doctor: doctorData,
        patient: patient,
        doctorData: doctor?.doctor,
        // eventsData: data,
        // eventsLoading: loading,
        // eventsError: error,
        handleChangeCurrentDate,
        handleCloseNotUsedModal,
        handleChangeDate,
        handleChangeRangeDates,
        handleChangeView,
        handleEditCurrentEvent,
        handleOpenAddModal,
        handleCloseAddModal,
        handleCloseEditMode,
        handleAddAppointment,
        handleAddEvent,
        handleDeleteAppointment,
        handleUpdateAppointment,
        handleUpdateEvent,
        handleDropEvent,
        handleResizeEvent,
        loadingAddAppointment,
        loadingAddEvent,
        loadingEditAppointment,
        loadingEditEvent,
        currentSelectedEvent: params?.state?.appointmentId,
        testIndex,
        setTestIndex,
        expanded,
        handleAccordionChange,
        handleFrequencyAccordionChange,
        frequencyExpand,
        setFrequencyExpand,
        eventRange,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeStart,
        handleChangeEnd,
        start,
        end,
        handleDeleteEvent,
        customValue,
        setCustomValue,
        clickableEvent,
        setClickableEvent,
        weekend,
        onChangeWeekEnd,
        setWeekEnd,
        conflicts,
        handleBack,
        handelDelete,
        openDeleteModal,
        handleCloseDeleteModal,
        handleOpenDelete,
        patientModalTitle,
        handleOpenPatientModal,
        handleClosePatientModal,
        selectedColorId: state.selectedColorId,
        setSelectedColorId,
        patientAdded: state.patientAdded,
        setPatientAdded,
        loadingDeleteAppointment,
        loadingDeleteEvent,
        handleOpenConflictState,
        viewRange
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useShedulePageProvider must be used within a shedulePageProvider'
    );
  }

  return context;
}

export const CONFLICT_APPOINTMENT = gql`
  query conflictsAppointments {
    conflictsAppointments {
      _id
      description
      numberOfRepeat
      repeatEvery
      endOn
      title
      repeatOn
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
        phone
      }
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        email
        phone
        timeZone
      }
      startDate
      endDate
      conditionType {
        tag
        color
      }
      patientGoing
      doctorGoing
      invitation {
        invitation_id
      }
      sessionType {
        session
        duration
        rate
        currency
      }
      isPrepay
    }
  }
`;
