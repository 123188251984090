import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as LoadingIcon } from 'assets/illustrations/loading.svg';

const useStyles = makeStyles((theme) => ({
  notFoundText: {
    paddingTop: theme.spacing(2),
    color: theme.palette.black.dark,
    fontSize: theme.typography.pxToRem(20),
  },
}));

export function LoadingIllustration(): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> {
  const classes = useStyles();
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="#425364"
    >
      <Box textAlign="center" display="flex" flexDirection="column">
        <LoadingIcon style={{ color: '#425364' }} />
        <Typography className={classes.notFoundText}>Loading</Typography>
      </Box>
    </Box>
  );
}
