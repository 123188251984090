import React, { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { UncontrollerCustomTime } from './CustomTime';

type CustomDate = string | number | Date;

interface ICustomDateProps {
  control?: Control;
  name: string;
  defaultValue?: [CustomDate, CustomDate];
  label?: string;
  style?: StyleSheetList;
  range: [CustomDate, CustomDate] | [CustomDate];
  onChange?: (name: 'startDate' | 'endDate', value: string | number) => void;
  dateFormat?: string;
  showTimeSelect?: boolean;
  date?: boolean;
  excludedTimes?: Date[];
  selected?: any;
  onInputClick?: any;
  minTime?: Date;
  maxTime?: Date;
  excludeTimes?: Date[];
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function TimeRange({
  range,
  onChange,
  showTimeSelect,
  date = false,
  excludeTimes,
  onInputClick,
  minTime = new Date(new Date().setHours(0, 0, 0, 0)),
  maxTime = new Date(new Date().setHours(23, 0, 0, 0)),
  ...props
}: ICustomDateProps): ReactElement {
  const classes = useStyles();
  const [startDate, endDate] = range;
  function handleChangeStartDate(value: string | number) {
    if (onChange) onChange('startDate', value);
  }
  function handleChangeEndDate(value: string | number) {
    if (onChange) onChange('endDate', value);
  }
  return (
    <Box
      display="flex"
      width="100%"
      m={0}
      alignContent="center"
      alignItems="center"
    >
      <UncontrollerCustomTime
        onChange={handleChangeStartDate}
        value={startDate}
        showTimeSelect={showTimeSelect}
        minTime={minTime}
        maxTime={maxTime}
        onInputClick={onInputClick}
        excludeTimes={excludeTimes}
        {...props}
      />
      <>
        <Typography className={classes.text}>-</Typography>
        <UncontrollerCustomTime
          onChange={handleChangeEndDate}
          value={endDate}
          showTimeSelect={showTimeSelect}
          minTime={minTime}
          maxTime={maxTime}
          onInputClick={onInputClick}
          {...props}
        />
      </>
    </Box>
  );
}
