import { gql, useSubscription } from '@apollo/client';
import { log } from 'config';
import useJoinedVideoRoom from '@eggmed/graphql-client/operations/videoRoomOperations/useJoinedVideoRoom';
import { useEffect } from 'react';

const USER_LEAVED_ROOM_SUBSCRIPTION = gql`
  subscription userLeavedVideoRoom($roomId: String!) {
    userLeavedVideoRoom(roomId: $roomId) {
      isDoctor
    }
  }
`;

interface IUseRoomManagement {
  roomId: string;
  unMountConnections: () => void;
  uniqueIdentifier: string;
  isNotified: boolean;
  // TODO: change this type
  videoRoomMembers: any;
  refetch: (args?: any) => void;
  handleCreationNewConnection: (userId: string, isPolite: boolean) => void;
  polite: boolean;
  setIsMobile?: (isMobile: boolean) => void;
  isMobile?: boolean;
  disableEncryption?: () => void;
}

const useRoomManagement = ({
  roomId,
  unMountConnections,
  uniqueIdentifier,
  refetch,
  handleCreationNewConnection,
  isNotified,
  videoRoomMembers,
  isMobile,
  setIsMobile,
  polite,
  disableEncryption,
}: IUseRoomManagement) => {
  const { handleJoinedVideoRoom } = useJoinedVideoRoom();
  useSubscription(USER_LEAVED_ROOM_SUBSCRIPTION, {
    variables: {
      roomId,
    },
    onSubscriptionData: () => {
      unMountConnections();
    },
  });
  useEffect(() => {
    handleJoinedVideoRoom(roomId, uniqueIdentifier);
  }, [roomId, uniqueIdentifier]);
  useEffect(() => {
    // if (isNotified) return;
    if (videoRoomMembers && videoRoomMembers.videoRoomMembers.length < 2) {
      handleCreationNewConnection('id', false);
      refetch();
      return;
    }
    // Unmount connections when ever we will restart new connections
    unMountConnections();
    videoRoomMembers?.videoRoomMembers?.forEach((userId: string) => {
      const [id, _isMobile] = userId.split(':');
      if (_isMobile && !isMobile) {
        setIsMobile(true);
        disableEncryption();
      }
      if (userId === uniqueIdentifier)
        return log('Same as the current user', id, uniqueIdentifier);
      if (!polite) {
        setTimeout(() => handleCreationNewConnection(id, true), 2000);
      } else {
        return handleCreationNewConnection(id, false);
      }
      return null;
    });
  }, [videoRoomMembers]);
};

export default useRoomManagement;
