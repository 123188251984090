import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  labels: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#344054',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '19px',
    marginBottom: '12px',
    whiteSpace: 'nowrap',
  },
  InputGrid: {
    marginBottom: '35px',
  },
  button: {
    width: '100%',
    height: '50px',
    borderRadius: '5px',
    background: '#1F61DC',

    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '&:hover': {
      background: '#1D41BA',
    },
  },
  buttonText: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textTransform: 'none',
    marginRight: '20px',
  },
  backButtonText: {
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textTransform: 'none',
    color: '#425364',
    marginLeft: '20px',
  },
  BackButton: {
    width: '100%',
    height: '50px',
    borderRadius: '5px',
    background: '#E0E0E0',
    border: '0.5px solid #828282',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '24px',
    textTransform: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '&:hover': {
      background: '#C0C0C0',
    },
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '3px',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  srollBar: {
    height: '100%',
    overflowX: 'hidden',
    marginInline: '-3rem',
    paddingInline: '3rem',
    paddingBottom: '20px',
  },
}));
