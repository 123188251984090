import React from 'react';
import { Box, Button, Avatar, Typography } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as ProviderIcon } from 'assets/ProvidersIcon.svg';
import { ReactComponent as ShowProfileIcon } from 'assets/ShowProfileIcon.svg';
import { ReactComponent as MessageProvIcon } from 'assets/MessageProvIcon.svg';
import { useHistory } from 'react-router-dom';
import Modals from 'pages/InboxPage/modals/index';
import { useProvider as useInobx } from 'pages/InboxPage/state/Provider';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyDoctor } from 'assets/emptyState/inbox.svg';
import Skeleton from 'components/ui/Skeleton';
import ProviderSkeleton from './ProviderSkeleton';
import Text from 'Text';

const ProviderCard = ({ doctors, loadingDoctors }) => {
  const classes = useStyles();
  const history = useHistory();
  const [doctor, setDoctor] = React.useState(null);
  const { firstname, lastname, middlename, _id, email, picture } = doctor || {};
  const { handleOpenCreateNewThreadModal } = useInobx();
  function handlMessage(doc: string) {
    setDoctor(doc);
    handleOpenCreateNewThreadModal();
  }
  return (
    <Box
      className={classes.upcomingBox}
      p={3}
      width="100%"
      height="100%"
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center">
        <ProviderIcon style={{ marginRight: '10px' }} />
        <Text i18nKey='providers' className={classes.textTitle}>Providers</Text>
      </Box>
      <Box mt={0} display="flex" flexDirection="column">
        {loadingDoctors && [1, 2].map(() => <ProviderSkeleton />)}
        {doctors?.doctors?.length === 0 && !loadingDoctors && (
          <Box py="2rem" width="100%">
            <EmptyState
              icon={<EmptyDoctor />}
              title="No providers"
              hasAction={false}
            />
          </Box>
        )}
        {doctors?.doctors?.slice(0, 2)?.map((el, index) => (
          <Box
            display="flex"
            flexDirection="column"
            pb={index === 0 && 4}
            pt={2}
            borderBottom={index === 0 && '1px solid #EAECF0'}
          >
            <Box display="flex" alignItems="center">
              <Avatar src={el?.picture} className={classes.picture}>
                {el?.firstname && el?.firstname[0]}{' '}
                {el?.lastname && el?.lastname[0]}
              </Avatar>
              <Box display="flex" flexDirection="column" ml={2}>
                <Typography className={classes.doctorLastName}>
                  {el?.firstname} {el?.lastname}
                </Typography>
                <Typography>{el.specialty || '-'}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mt={2}
              style={{ gap: '20px' }}
            >
              <Button
                className={classes.WhiteBtn}
                style={{ width: '50%' }}
                onClick={() => history.push(`/patient/doctor/${el?._id}`)}
              >
                <ShowProfileIcon style={{ marginRight: '10px' }} /> <Text style={{ color: '#344054',textTransform: 'none',fontWeight: 600,fontSize: '16px',display: 'flex',justifyContent: 'center',alignItems: 'center',}} i18nKey='showProfil'>Show profile</Text>
              </Button>
              <Button
                className={classes.WhiteBtn}
                style={{ width: '50%' }}
                onClick={() => handlMessage(el)}
              >
                <MessageProvIcon style={{ marginRight: '10px' }} /> <Text style={{ color: '#344054',textTransform: 'none',fontWeight: 600,fontSize: '16px',display: 'flex',justifyContent: 'center',alignItems: 'center',}} i18nKey='message'>Message</Text>
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Modals
        subject=""
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
        isPatient
      />
    </Box>
  );
};

export default ProviderCard;
