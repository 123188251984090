import React, { ReactElement, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from 'components/ui/Switch';
import useGetNotifications from '@eggmed/graphql-client/operations/notificationOperations/useGetNotifications';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as EmailIcon } from 'assets/notificationSettings/email.svg';
import { ReactComponent as PushIcon } from 'assets/notificationSettings/push.svg';
import { ReactComponent as AppointmentIcon } from 'assets/notificationSettings/appointment.svg';
import { ReactComponent as PrescriptionIcon } from 'assets/notificationSettings/prescription.svg';
import { ReactComponent as MessageIcon } from 'assets/notificationSettings/messages.svg';
import { ReactComponent as AccessIcon } from 'assets/notificationSettings/access.svg';
import { ReactComponent as ActivityIcon } from 'assets/activity.svg';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Skeleton from 'components/ui/Skeleton';
import useStyles from './styles';
import NotificationChannelCard from './NotificationChannelCard';
import NotificationEventCard from './NotificationEventCard';
import Text from 'Text';
import NotificationBlock from './NotificationBlock';

interface IChannelSettings {
  channel: {
    _id: string;
  };
  events: {
    _id: string;
    event: string[];
  }[];
}

const NotificationSettings = (): ReactElement => {
  const { data, loading, error, refetch } = useGetNotifications();
  const { patient } = useAuth();
  const isPatient = !!patient;
  const classes = useStyles();
  const { register } = useFormContext();
  const settingsIcons = {
    Email: <EmailIcon className={classes.icon} />,
    Push: <PushIcon className={classes.icon} />,
    Sessions: <AppointmentIcon className={classes.icon} />,
    Prescriptions: <PrescriptionIcon className={classes.icon} />,
    Messages: <MessageIcon className={classes.icon} />,
    'Access requests': <AccessIcon className={classes.icon} />,
    Activities: <ActivityIcon className={classes.icon} />,
  };
  useEffect(() => {
    refetch();
  }, []);
  const loadingNotifs =
    !!!data?.notificationChannels && !!!data?.notificationEvents;

  return (
    <Box>
      <Grid item lg={12} xl={12} sm={12} xs={12}>
        <Box className={classes.channels}>
          {loadingNotifs &&
            [1, 2, 3].map((e) => (
              <Box
                mt="1rem"
                style={{
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                }}
              >
                <NotificationChannelCard loading={loadingNotifs} />
              </Box>
            ))}

          {data?.notificationChannels.map((channel: any, index) => (
            <NotificationBlock
              index={index}
              channel={channel}
              loadingNotifs={loadingNotifs}
              data={data}
              register={register}
              isPatient={isPatient}
              settingsIcons={settingsIcons}
            />
          ))}
        </Box>
      </Grid>
    </Box>
  );
};

export default NotificationSettings;
