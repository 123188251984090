import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import DateRange from 'components/ui/Inputs/DateRange';
import TimeInput from 'components/ui/Inputs/TimeInput';
import useStyles from '../EventForm/styles';
import Text from 'Text';

interface IDateAndTimeProps {
  eventRange: {
    startDate: Date;
    endDate: Date;
  };
  handleChangeStartDate: (name: string, value: any) => void;
  handleChangeEndDate: (name: string, value: any) => void;
  handleChangeStart: (date: Date) => void;
  handleChangeEnd: (date: Date) => void;
  start: Date;
  end: Date;
}

export const DateAndTime = ({
  eventRange,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeStart,
  handleChangeEnd,
  start,
  end,
}: IDateAndTimeProps) => {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Text i18nKey='dateAndTime' className={classes.label}>Date & time</Text>
      <Box className={classes.dateEventContainer}>
        <Grid container spacing={1} style={{ marginBottom: '0.5rem' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text i18nKey='start' className={classes.label} style={{ marginBottom: 0 }}>
              Start
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <DateRange
              dateFormat="MMMM d, yyyy"
              range={[eventRange.startDate]}
              name="daterange"
              onChange={handleChangeStartDate}
              date
              minDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Box className={classes.timePicker}>
              <TimeInput
                date={start}
                handleChange={handleChangeStart}
                timeIntervals={30}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Text i18nKey='end' className={classes.label} style={{ marginBottom: 0 }}>
              End
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <DateRange
              dateFormat="MMMM d, yyyy"
              range={[eventRange.endDate]}
              name="daterange"
              onChange={handleChangeEndDate}
              date
              minDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Box className={classes.timePicker}>
              <TimeInput
                date={end}
                handleChange={handleChangeEnd}
                timeIntervals={30}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
