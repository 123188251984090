import React, { ReactElement } from 'react';
import { useStyles } from '../styles';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/sleep.svg';
import { ReactComponent as SunIcon } from 'assets/patientActivities/sun.svg';
import { ReactComponent as FetherIcon } from 'assets/patientActivities/fether.svg';
import { ReactComponent as ImageIcon } from 'assets/patientActivities/image.svg';
import { ReactComponent as NotesIcon } from 'assets/patientActivities/notes.svg';
import { ReactComponent as CameraIcon } from 'assets/patientActivities/camera.svg';
import ScrollBar from 'components/ui/ScrollBar';
import AttachmentInput from 'pages/Taskpage/components/AttachmentInput';
import Question from './Question';
import Input from 'components/ui/Inputs';
import { ReactComponent as MoodPreviewIcon } from 'assets/MoodPreview.svg';
import { ReactComponent as MoodPreviewIconFr } from 'assets/MoodPreviewFr.svg';
import { ReactComponent as MoodTags } from 'assets/MoodTags.svg';
import { ReactComponent as MoodTagsFr } from 'assets/MoodTagsFr.svg';
import { ReactComponent as SleepTagsCheckin } from 'assets/SleepTagsCheckin.svg';
import { ReactComponent as SleepTagsCheckinFr } from 'assets/SleepTagsCheckinFr.svg';
import { ReactComponent as ComponentUploadShared } from 'assets/ComponentUploadShared.svg';
import { ReactComponent as ComponentUploadSharedFr } from 'assets/ComponentUploadSharedFr.svg';
import { ReactComponent as AllTogether } from 'assets/AllTogether.svg';
import { ReactComponent as SleepIllustration } from 'assets/SleepIllustration.svg';
import { ReactComponent as SleepIllustrationFr } from 'assets/SleepIllustrationFr.svg';
import { ReactComponent as SleepTimes } from 'assets/SleepTimes.svg';
import { ReactComponent as SleepTimesFr } from 'assets/SleepTimesFr.svg';
import { ReactComponent as ReflectionIllustration } from 'assets/ReflectionIllustration.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IPreview {
  question: string;
  handleBack: () => void;
  selectedValues?: string[];
  upload?: boolean;
  notes?: boolean;
  sleep?: boolean;
  reflectionPreview?: boolean;
  moodPreview?: boolean;
  sleepPreview?: boolean;
  illustration: ReactElement;
  selected?: boolean;
}

function Preview({
  question,
  handleBack,
  selectedValues,
  upload = false,
  notes = false,
  sleep = false,
  reflectionPreview = false,
  moodPreview = false,
  sleepPreview = false,
  selected = true,
  illustration,
}: IPreview) {
  const classes = useStyles({ selected: false });
  const { t, i18n } = useTranslation();
  return (
    <Box className={classes.reflectionModal}>
      <ScrollBar className={classes.PreviewScrollBar}>
        <Box
          width="100%"
          paddingY="2rem"
          className={classes.previewBox}
          data-cy="preview-box"
        >
          {reflectionPreview && (
            <Box>
              <ReflectionIllustration />
            </Box>
          )}
          {sleepPreview && (
            <Box display="flex" alignItems="center" flexDirection="column">
              {i18n.language === 'fr' ? (
                <SleepIllustrationFr />
              ) : (
                <SleepIllustration />
              )}
              <Box mt={4}>
                {i18n.language === 'fr' ? <SleepTimesFr /> : <SleepTimes />}
              </Box>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                  color: '#0054B6',
                  marginBottom: '16px',
                }}
              >
                {t('What impacted your sleep last night?')}
              </Typography>
              {i18n.language === 'fr' ? (
                <SleepTagsCheckinFr />
              ) : (
                <SleepTagsCheckin />
              )}
              <Box mt={4}>
                {notes && !upload ? (
                  <Box width="100%">
                    <TextField
                      disabled
                      className={classes.previewInput}
                      variant="outlined"
                      multiline
                      rows={1}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Box>
                ) : !notes && upload ? (
                  <>
                    {i18n.language === 'fr' ? (
                      <ComponentUploadSharedFr />
                    ) : (
                      <ComponentUploadShared />
                    )}
                  </>
                ) : notes && upload ? (
                  <>
                    {i18n.language === 'fr' ? <AllTogether /> : <AllTogether />}
                  </>
                ) : null}
              </Box>
            </Box>
          )}
          {moodPreview && (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box mb={6}>
                {i18n.language === 'fr' ? (
                  <MoodPreviewIconFr />
                ) : (
                  <MoodPreviewIcon />
                )}
              </Box>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                  color: '#0054B6',
                  marginBottom: '16px',
                }}
              >
                {t('Which words best captures this feeling?')}
              </Typography>
              {i18n.language === 'fr' ? <MoodTagsFr /> : <MoodTags />}
              <Box mt={4}>
                {notes && !upload ? (
                  <Box width="100%">
                    <TextField
                      disabled
                      className={classes.previewInput}
                      variant="outlined"
                      multiline
                      rows={1}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Box>
                ) : !notes && upload ? (
                  <>
                    {i18n.language === 'fr' ? (
                      <ComponentUploadSharedFr />
                    ) : (
                      <ComponentUploadShared />
                    )}
                  </>
                ) : notes && upload ? (
                  <>
                    {i18n.language === 'fr' ? <AllTogether /> : <AllTogether />}
                  </>
                ) : null}
              </Box>
            </Box>
          )}

          {/* <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="flex-start"
            mt={2}
          >
            {selected && question && (
              <>
                <FetherIcon />
                <Typography className={classes.previewQueston}>
                  {question[0].toUpperCase() +
                    question.slice(1, question.length).toLowerCase()}
                  {!selected && 'Free Reflection'}
                </Typography>
              </>
            )}
            {!selected && (
              <>
                <FetherIcon />
                <Typography className={classes.previewQueston}>
                  Free Reflection
                </Typography>
              </>
            )}
          </Box> */}
          {/* <Question
            selectedValues={selectedValues}
            sleepPreview={sleepPreview}
            moodPreview={moodPreview}
            reflectionPreview={reflectionPreview}
          /> */}

          {/* {sleep && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ gap: '1rem' }}
              mt={5}
            >
              <Box width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                >
                  <SleepIcon />
                  <Typography className={classes.previewQueston}>
                    Bedtime
                  </Typography>
                </Box>
                <Input
                  variant="filled"
                  type="text"
                  placeholder="hh:mm"
                  disabled
                />
              </Box>
              <Box width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                >
                  <SunIcon />
                  <Typography className={classes.previewQueston}>
                    Wake Up
                  </Typography>
                </Box>
                <Input
                  variant="filled"
                  type="text"
                  placeholder="hh:mm"
                  disabled
                />
              </Box>
            </Box>
          )} */}

          {/* {upload && (
            <Box width="100%" mt={6}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="flex-start"
              >
                <ImageIcon />
                <Typography className={classes.previewQueston}>
                  Photo (optional)
                </Typography>
              </Box>
              <AttachmentInput
                data-cy="file-add"
                mt={2}
                name="Upload photo"
                isActionable={false}
                createdAt={new Date()}
                handleAction={() => {}}
                time={false}
                custormIcon={<CameraIcon className={classes.uploadIcon} />}
              />
            </Box>
          )} */}
          {/* {notes && (
            <Box width="100%" mt={6}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="flex-start"
              >
                <NotesIcon />
                <Typography className={classes.previewQueston}>
                  Notes (optional)
                </Typography>
              </Box>
              <TextField
                disabled
                className={classes.previewInput}
                variant="outlined"
                multiline
                rows={4}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </Box>
          )} */}

          <Box
            display="flex"
            alignItems="center"
            width="100%"
            className={classes.previewButtons}
          >
            <Button className={classes.CancelPreview} onClick={handleBack}>
              <Text i18nKey="cancel">Cancel</Text>
            </Button>
            <Button className={classes.SavePreview}>
              <Text i18nKey="save">Save</Text>
            </Button>
          </Box>
        </Box>
      </ScrollBar>
    </Box>
  );
}

export default Preview;
