import { gql, useQuery } from '@apollo/client';
import { getDoctors, getRelatedDoctor } from './__generated__/getDoctors';

export default function useGetDoctors() {
  const result = useQuery<getDoctors>(
    gql`
      query getDoctors {
        doctors {
          firstname
          middlename
          lastname
          picture
          email
          phone
          _id
          focusArea
          languages
          patientGroup
          experience
          specialty
          gender
          about
          state
          country
          suite
          zipcode
          city
          address
          birthday
          certifications {
            certificationName
            yearOfDegree
            certificationLocation
            educationLevel
          }
        }
      }
    `
  );
  return result;
}

export function useDoctorRelated() {
  const result = useQuery<getRelatedDoctor>(RELATED_DOCTOR);
  return result;
}
export const RELATED_DOCTOR = gql`
  query patientsRelated {
    patientsRelated {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
      relationshipStatus
      employmentStatus
      raceAndEthnicity
      religion
      relationship
      preferredLanguage
      emergencyEmail
      emergencyPhone
      emergencyName
      conditions {
        patientId
        _id
        treatments
        name
        dateAdded
        dateEnded
        type
      }
    }
  }
`;
