import * as React from 'react';

const SET_TITLE = { type: 'setTitle', payload: '' };

type Action = typeof SET_TITLE;

type Dispatch = (action: Action) => void;

type State = { title: 'Dashboard' };

type HeaderPageNameProviderProps = {
  children: React.ReactElement;
  initialState?: State;
};

export const HeaderPageNameStateContext = React.createContext<
  State | undefined
>(undefined);

export const HeaderPageNameDispatchContext = React.createContext<
  Dispatch | undefined
>(undefined);

function HeaderPageNameReducer(state: State, action: any): any | Error {
  switch (action.type) {
    case 'setTitle': {
      document.title = action.payload;
      return { ...state, title: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}

function HeaderPageNameProvider({
  children,
  initialState = { title: 'Dashboard' },
}: HeaderPageNameProviderProps) {
  const [state, dispatch] = React.useReducer(
    HeaderPageNameReducer,
    initialState
  );

  return (
    <HeaderPageNameStateContext.Provider value={state}>
      <HeaderPageNameDispatchContext.Provider value={dispatch}>
        {children}
      </HeaderPageNameDispatchContext.Provider>
    </HeaderPageNameStateContext.Provider>
  );
}
HeaderPageNameProvider.defaultProps = {
  initialState: { title: 'Dashboard' },
};
function useHeaderPageNameState() {
  const context = React.useContext(HeaderPageNameStateContext);

  if (context === undefined) {
    throw new Error(
      'useHeaderPageNameState must be used within a HeaderPageNameProvider'
    );
  }

  return context;
}

function useHeaderPageNameDispatch() {
  const context = React.useContext(HeaderPageNameDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useHeaderPageNameDispatch must be used within a useHeaderPageNameProvider'
    );
  }

  return context;
}

export default function useHeaderPageName(): any {
  const state = useHeaderPageNameState();
  const dispatch = useHeaderPageNameDispatch();
  return { dispatch, state };
}

export {
  HeaderPageNameProvider,
  useHeaderPageNameState,
  useHeaderPageNameDispatch,
};
