import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateDashboard from '../animations/dashboard.json';

export const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateDashboard}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>

      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Dashboard</h3>
        <p className={classes.description}>
          <span className={classes.bold}>Get a snapshot</span> of your upcoming
          sessions, messages, finances, and tasks.
        </p>
        <p className={classes.description}>
          You can add your <span className={classes.bold}>clients,</span>{' '}
          schedule <span className={classes.bold}>sessions</span> and set your{' '}
          <span className={classes.bold}>availability</span> from here.
        </p>
      </div>
    </div>
  );
};
