import { SubscriptionHookOptions, gql, useSubscription } from '@apollo/client';

const PATIENT_JOINED_WAITING_ROOM_SUBSCRIPTION = gql`
  subscription OnPatientJoinedWaitingRoom($roomId: String) {
    patientJoinedWaitingRoom(roomId: $roomId) {
      roomId
      isMobile
      patient {
        _id
        firstname
        lastname
        email
        picture
      }
      waitingRoom {
        firstname
        lastname
        _id
        joinedWaitingRoomDate
        appointmentId
        startDate
        picture
      }
    }
  }
`;

const IS_MOBILE_SUBSCRIPTION = gql`
  subscription OnIsMobileChanged($roomId: String!) {
    isMobileSubscription(roomId: $roomId) {
      roomId
      isMobile
    }
  }
`;

export const useIsMobileSubscription = (
  args: SubscriptionHookOptions<
    any,
    {
      roomId: any;
    }
  >
) => {
  return useSubscription(IS_MOBILE_SUBSCRIPTION, args);
};

export const usePatientJoinedWaitingRoom = ({
  roomId,
  handleNewPatientJoined,
}: any) => {
  useSubscription(PATIENT_JOINED_WAITING_ROOM_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      handleNewPatientJoined(
        data.subscriptionData?.data?.patientJoinedWaitingRoom?.waitingRoom,
        data.subscriptionData?.data?.patientJoinedWaitingRoom?.isMobile
      );
    },
  });
};
