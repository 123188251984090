import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

const ReportList = withStyles({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowX: 'hidden',
    maxHeight: '50vh',
  },
})(List);

export default ReportList;
