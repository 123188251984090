import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import { useFormContext } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import EditButtons from 'components/EditButtons';
import useStyles from './styles';
interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  onSubmit?: (e: any) => void;
  divider?: boolean;
  loading?: boolean;
  className?: string;
  title?: string;
  titlePadding?: string;
  disablePadding?: boolean;
  isPatient?: boolean;
  submitText?: string;
  editMode?: boolean;
  handleDelete?: () => void;
  hasTitle?: boolean;
}

export default function ModalForm({
  children,
  handleClose,
  onSubmit,
  className,
  divider,
  loading,
  title,
  titlePadding,
  disablePadding,
  isPatient,
  submitText,
  editMode,
  handleDelete,
  hasTitle = true,
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, disablePadding });
  const methods = useFormContext();
  return (
    <Box className={classes.modalBox} data-cy="form-modal">
      <CancelIcon className={classes.cancelIcon} onClick={handleClose} />

      {hasTitle && (
        <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
          <Typography
            className={classes.title}
            style={{ paddingLeft: titlePadding }}
          >
            {title &&
              title[0].toUpperCase() +
                title.slice(1, title.length).toLowerCase()}
          </Typography>
        </Grid>
      )}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ScrollBar className={className}>{children}</ScrollBar>
        {divider && <Divider />}
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          borderTop="0.5px solid #EAECF0"
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={isPatient}
            type="submit"
            loading={loading}
            handleOpen={(e) => {
              e.preventDefault();
              handleClose();
            }}
            submitText={submitText}
            editMode={editMode}
            handleDelete={handleDelete}
            px="1rem"
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
}
