import { useEffect, useRef } from 'react';

export default function useHeightObserver(callback) {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          callback(entry.contentRect.height);
        }
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback]);

  return targetRef;
}
