import {
  createContext,
  useRef,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';

import Snackbar from 'components/ui/Modal/SuccessModal';
import React from 'react';

export const Context = createContext(undefined);
interface SnackBarProps {
  children: React.ReactNode;
}
export default function Provider({ children }: SnackBarProps) {
  const [openSuccess, setOpenSuccess] = React.useState(false);

  return (
    <Context.Provider
      value={{
        triggerToast: ({ start = 0, end = 1500, content }) => {
          setTimeout(() => setOpenSuccess(true), start);
          setTimeout(() => setOpenSuccess(false), end);
        },
        openSuccess,
      }}
    >
      {children}
      <Snackbar open={openSuccess} handleClose={() => setOpenSuccess(false)} />
    </Context.Provider>
  );
}
export function useToast() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      'usePatientProvider must be used within a PatientPageProvider'
    );
  }

  return context;
}
