import { Box } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import React from 'react';

function SessionSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      p="1rem"
      border="1px solid #D0D5DD"
      borderRadius="16px"
      minHeight="100px"
      flex="1"
    >
      <Box display="flex" flexDirection="column" ml={2}>
        <Skeleton loading={true} type="text" width="100px" height="21px">
          <Box
            style={{
              width: '100px',
              height: '21px',
              borderRadius: '8px',
            }}
          />
        </Skeleton>
        <Skeleton loading={true} type="text" width="120px" height="21px">
          <Box
            style={{
              width: '120px',
              height: '21px',
              borderRadius: '8px',
            }}
          />
        </Skeleton>
      </Box>
    </Box>
  );
}

export default SessionSkeleton;
