import React, { useState, useRef } from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';

import ChatMessage from './ChatMessage';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    backgroundColor: 'rgba(36,50,65,1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    right: '0',
    top: '0',
    height: '100vh',
    width: '20vw',
    borderBottom: '1px solid white',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    zIndex: 100000000,
  },
  scrollWrapper: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-resizer': {
      backgroundColor: 'black',
    },
  },
  chatInput: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'hsla(0, 0%, 0%, 0.25)',
      color: 'white',
      border: '1px solid white',
      '& .Mui-focused': {
        border: '1px solid white',
        borderRadius: '5px',
      },
    },
    width: '100%',
    height: '50px',
    '& .MuiFilledInput-root': {
      backgroundColor: ' hsla(0, 0%, 0%, 0.25)',
      color: 'white',
      borderRadius: '5px',
      border: '0.5px solid rgba(161, 176, 196, 1)',
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px',
      },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },
    '& .Mui-focused': {
      border: '1px solid white',
      borderRadius: '5px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
      {
        padding: '6px 6px 16px 12px',
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '8px',
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1),
    },
    '& .MuiFilledInput-underline:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #3f50b5',
    },
    '& .MuiFilledInput-underline:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '0px solid #3f50b5',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(0),
    },
  },
}));

export interface IMessage {
  from: string;
  date: Date;
  text: string;
}

interface IChatContainerProps {
  messages: IMessage[];
  sendText: (message: IMessage) => void;
}

export default function ChatContainer({
  messages,
  sendText,
}: IChatContainerProps): JSX.Element {
  const scrollContainerRef = useRef(null);
  const classes = useStyles();
  const [message, setMessage] = useState<IMessage>({
    from: '',
    date: new Date(),
    text: '',
  });
  const handleChange = (e: any) => {
    setMessage({
      text: e.target.value,
      date: new Date(),
      from: 'me',
    });
  };
  const handlePress = (event: any) => {
    if (event.key === 'Enter') {
      sendText(message);
      setMessage({
        from: '',
        date: new Date(),
        text: '',
      });
      scrollContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  };
  return (
    <Box className={classes.chatContainer}>
      <Box width="100%" position="relative" height="95vh">
        <Box
          position="relative"
          height="70vh"
          padding={4}
          className={classes.scrollWrapper}
        >
          {messages.map((_message: IMessage) => {
            const messageTime = `${new Date(
              _message.date
            )?.getHours()}:${new Date(_message.date)?.getMinutes()}`;
            return (
              <ChatMessage
                from={_message?.from}
                messageTime={messageTime}
                messageText={_message.text}
              />
            );
          })}

          <div
            style={{
              float: 'left',
              clear: 'both',
              height: '10px',
              marginTop: '100px',
            }}
            ref={scrollContainerRef}
          />
        </Box>

        <Box width="100%" position="absolute" bottom="-2vh">
          <TextField
            fullWidth
            color="primary"
            size="small"
            className={classes.chatInput}
            type="text"
            value={message.text}
            onChange={handleChange}
            onKeyPress={handlePress}
            variant="filled"
          />
        </Box>
      </Box>
    </Box>
  );
}
