import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useCallback,
} from 'react';

import EditorJS from '@editorjs/editorjs';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Header from 'editorjs-header-with-alignment';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Alert from 'editorjs-alert';
import ToggleBlock from 'editorjs-toggle-block';
import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';
import ColorPlugin from 'editorjs-text-color-plugin';
import DragDrop from 'editorjs-drag-drop';
import ImageTool from '@editorjs/image';

import MultipleChoice from './nodes/MultiChoice';
import SingleChoice from './nodes/SingleChoice';
import ShortAnswer from './nodes/ShortText';
import LongAnswer from './nodes/LongText';
import DropDown from './nodes/DropDown';
import LineaireScale from './nodes/LineaireScale';
import Date from './nodes/Date';
import Table from '@editorjs/table';
import Email from './nodes/Email';
import Phone from './nodes/Phone';
import Address from './nodes/Address';
import DateRange from './nodes/DateRange';
import Signature from './nodes/Signature';

import { reducer } from './ContextReducer';
import {
  SET_BLOCKS,
  TOGGLE_SIGNATURE,
  SET_TITLE,
  EDIT_MODE,
  RESET_BLOCK,
  SELECTED_TEMPLATE,
  SET_TEMPLATE_ID,
} from './actions';

export const EditorContext = createContext<any>(null);

export default function EditorContextProvider({ children }) {
  const editorRef = useRef<any>();
  function setSelectedTemplate(payload) {
    dispatch({ type: SELECTED_TEMPLATE, payload });
  }
  const handleSelectedTemplate = useCallback((templateValue: string) => {
    setSelectedTemplate(templateValue);
  }, []);
  const handleCloseTemplate = useCallback(() => {
    setSelectedTemplate(null);
  }, []);

  const addBlock = (blockName: string, level?: number) => {
    const editor = editorRef.current;
    if (editor) {
      editor.blocks.insert(blockName, level && { level });
    }
  };
  const [state, dispatch] = React.useReducer(reducer, {
    blocks: null,
    open: false,
    formTitle: 'Untitled template',
    editMode: false,
    selectedTemplate: null,
    templateId: null,
  });
  function setBlocks(blocks, payload, id) {
    dispatch({ type: EDIT_MODE, payload });
    dispatch({ type: SET_BLOCKS, payload: blocks });
    dispatch({ type: SET_TEMPLATE_ID, payload: id });
  }
  function toggleSignature(payload) {
    dispatch({ type: TOGGLE_SIGNATURE, payload });
  }
  function handleChangeText(payload) {
    dispatch({ type: SET_TITLE, payload });
  }
  function toggleEditMode(payload) {
    dispatch({ type: EDIT_MODE, payload });
  }
  function resetBlock() {
    dispatch({ type: RESET_BLOCK });
  }

  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      placeholder: 'Start type your text here',
      autofocus: false,
      onReady: () => {
        new DragDrop(editor);
      },
      data: {
        time: 1724934371391,
        version: '2.30.5',
        blocks: JSON.parse(state?.blocks),
      },
      tools: {
        Multiple_choice: MultipleChoice,
        Single_choice: SingleChoice,
        Short_answer: ShortAnswer,
        Long_answer: LongAnswer,
        DropDown: DropDown,
        LineaireScale: LineaireScale,
        Email: Email,
        Phone: Phone,
        Address: Address,
        Date: Date,
        DateRange: DateRange,
        Signature: Signature,
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: [
              '#EC7878',
              '#9C27B0',
              '#673AB7',
              '#3F51B5',
              '#0070FF',
              '#03A9F4',
              '#00BCD4',
              '#4CAF50',
              '#8BC34A',
              '#CDDC39',
              '#FFF',
            ],
            defaultColor: '#FF1300',
            type: 'text',
            customPicker: true, // add a button to allow selecting any colour
          },
        },
        table: {
          class: Table as any,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        list: {
          class: NestedList as any,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
          },
          toolbox: [
            {
              icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 12L9 12M21 6L9 6M21 18L9 18M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
              title: 'Unordered List',
              data: {
                style: 'unordered',
              },
            },
          ],
        },
        Orderedlist: {
          class: NestedList as any,
          inlineToolbar: true,
          config: {
            defaultStyle: 'ordered',
          },
          toolbox: [
            {
              icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 17.625H4.10526V18.1875H3.05263V19.3125H4.10526V19.875H2V21H5.15789V16.5H2V17.625ZM3.05263 7.5H4.10526V3H2V4.125H3.05263V7.5ZM2 10.875H3.89474L2 13.2375V14.25H5.15789V13.125H3.26316L5.15789 10.7625V9.75H2V10.875ZM7.26316 4.125V6.375H22V4.125H7.26316ZM7.26316 19.875H22V17.625H7.26316V19.875ZM7.26316 13.125H22V10.875H7.26316V13.125Z" fill="#475467"/>
                </svg>`,
              title: 'Ordered List',
              data: {
                style: 'ordered',
              },
            },
          ],
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3,
            defaultAlignment: 'left',
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: "Quote's author",
          },
        },
        warning: {
          class: Warning,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+W',
          config: {
            titlePlaceholder: 'Title',
            messagePlaceholder: 'Message',
          },
        },
        delimiter: Delimiter,
        alert: {
          class: Alert,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+A',
          config: {
            alertTypes: [
              'primary',
              'secondary',
              'info',
              'success',
              'warning',
              'danger',
              'light',
              'dark',
            ],
            defaultType: 'primary',
            messagePlaceholder: 'Enter something',
          },
        },
        toggle: {
          class: ToggleBlock,
          inlineToolbar: true,
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching,
          },
        },
        Marker: {
          class: ColorPlugin,
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
            icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
          },
          shortcut: 'CMD+SHIFT+M',
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        underline: Underline,
        strikethrough: Strikethrough,
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
              byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
            },
          },
        },
      },
    });
    editorRef.current = editor;
  };
  return (
    <EditorContext.Provider
      value={{
        initEditor,
        editorRef,
        selectedTemplate: state.selectedTemplate,
        handleSelectedTemplate,
        handleCloseTemplate,
        addBlock,
        setBlocks,
        ...state,
        toggleSignature,
        handleChangeText,
        toggleEditMode,
        resetBlock,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditorContext must be used within a editorProvider');
  }
  return context;
}
