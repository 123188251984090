import React, { ReactElement } from 'react';
import { withStyles } from '@material-ui/core';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';

export const NormalTabsWrapper = withStyles({
  root: {
    // borderBottom: '1px dashed #e8e8e8',
    // color: '#39B54A',
  },
  indicator: {
    backgroundColor: '#39B54A',
  },
})(Tabs);

export const SimpleWrapperTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);
export const SectionWrapperTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);
export const OtherWrapperTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
  flexContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})(Tabs);
export const GreenButtonsTabsWrapper = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    marginTop: '5px',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    alignItems: 'center',
    gap: theme.typography.pxToRem(10),
    height: '100%',
  },
}))(Tabs);

export const ButtonsTabsWrapper = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    height: '100%',
    width: '100%',
  },
}))(Tabs);

export const PatientTabsWrapper = withStyles((theme) => ({
  root: {
    borderRadius: '59px',
    backgroundColor: 'rgba(215, 227, 241, 1)',
    padding: '5px',
    marginInline: '1.5rem',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))(Tabs);

interface ITabsWrapperProps extends TabsProps {
  tabsMode?:
    | 'normal'
    | 'section'
    | 'other'
    | 'buttons'
    | 'greenButtons'
    | 'patient'
    | 'simple';
  onChange: any;
}
export default function TabWrapper({
  tabsMode = 'normal',
  ...props
}: ITabsWrapperProps): ReactElement {
  const wrapperTypes: any = {
    normal: <NormalTabsWrapper {...props} />,
    section: <SectionWrapperTabs {...props} />,
    simple: <SimpleWrapperTabs {...props} />,
    other: <OtherWrapperTabs {...props} />,
    greenButtons: <GreenButtonsTabsWrapper {...props} />,
    buttons: <ButtonsTabsWrapper {...props} />,
    patient: <PatientTabsWrapper {...props} />,
  };
  return wrapperTypes[tabsMode];
}
