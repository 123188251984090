import { useQuery, gql } from '@apollo/client';
import { Grid, Box, Typography } from '@material-ui/core';
import React from 'react';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import { useStyles } from './styles';
import ScrollBar from 'components/ui/ScrollBar';
import RefillCard from './RefillCard';
import { ReactComponent as NoRefillRequest } from 'assets/emptyState/emptyTasks.svg';

const RefillRequest = () => {
  const { data } = useQuery(PATIENT_REFILL);
  const classes = useStyles();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Refill Requests' });
  }, [headerTitleDispatcher]);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.item}
      >
        <Typography className={classes.title}>Refill Requests</Typography>
        <Box className={classes.divider} />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ScrollBar className={classes.scroll}>
          {data?.patientsRefill?.length > 0 ? (
            data?.patientsRefill?.map((el) => <RefillCard {...el} />)
          ) : (
            <Box
              mt="5rem"
              display="flex"
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              style={{ gap: '1rem' }}
            >
              <NoRefillRequest />
              <Typography className={classes.noRefillRequest}>
                Its seems there are no refill requests at this moment
              </Typography>
            </Box>
          )}
        </ScrollBar>
      </Grid>
    </Grid>
  );
};

export default RefillRequest;

export const PATIENT_REFILL = gql`
  query patientsRefill {
    patientsRefill {
      patient {
        firstname
        _id
        middlename
        lastname
        picture
      }
      drugs {
        createdAt
      }
      _id
    }
  }
`;
