import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { ReactComponent as TimerIcon } from 'assets/TimerIcon.svg';
import { ReactComponent as TimerRecIcon } from 'assets/session/rec-input.svg';
import { useProvider } from 'contexts/MeetingContext';

interface Props {
  isRunning?: boolean;
  isStarted?: boolean;
}
function Timer({ isRunning }: Props) {
  const { time } = useProvider();
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <Box display="flex" alignItems="center">
      {isRunning ? (
        <TimerRecIcon style={{ marginRight: '5px' }} />
      ) : (
        <TimerIcon style={{ marginRight: '5px' }} />
      )}
      <Typography
        style={{
          fontSize: '14px',
          fontWeight: 500,
          color: isRunning ? '#FFFFFF' : '#344054',
          fontFamily: 'Inter',
        }}
      >
        {formatTime(time)}
      </Typography>
    </Box>
  );
}

export default Timer;
