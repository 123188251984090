import React, { useState, useRef } from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as AddIcon } from 'assets/patientActivities/addQestion.svg';
import QestionCard from './QuestionCard';
import { IQuestion } from './FormsPage';
import QuestionPreview from './QuestionPreview';

interface IFormGeneratorProps {
  questions: IQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>;
  question: IQuestion;
  isNotes?: boolean;
  changeQuestion?: any;
  api?: any;
  deleteBlock?: (index: number) => void;
  getBlockIndex?: () => number;
}

function FormGenerator({
  questions,
  setQuestions,
  question,
  isNotes = false,
  changeQuestion,
  api,
  deleteBlock,
  getBlockIndex,
}: IFormGeneratorProps): React.ReactElement {
  const classes = useStyles();
  const [focusedQuestionIndex, setFocusedQuestionIndex] = useState(0);
  const [scrollToCardRef, setScrollToCardRef] = useState(null);

  const handleUpdateQuestion = (index, question) => {
    setQuestions((_questions) => {
      const newQuestions = [..._questions];
      newQuestions[index] = question;
      return newQuestions;
    });
  };

  const addNewQuestion = (type, question) => {
    console.log('type', type);
    const blockType = {
      'Multiple choice': 'Multiple_choice',
      'Single choice': 'Single_choice',
      'Short answer': 'Short_answer',
      'Long answer': 'Long_answer',
      Dropdown: 'DropDown',
      'Linear scale': 'LineaireScale',
      Date: 'Date',
      Email: 'Email',
      Phone: 'Phone',
      Address: 'Address',
      'Date range': 'DateRange',
      Signature: 'Signature',
    };
    // const currentIndex = focusedQuestionIndex;
    // setQuestions((currentQuestions) => {
    //   const newQuestions = [...currentQuestions];
    //   newQuestions.splice(currentIndex + 1, 0, question);
    //   return newQuestions;
    // });
    // setScrollToCardRef(currentIndex + 1);
    // setFocusedQuestionIndex((prevIndex) => prevIndex + 1);
    if (api) {
      api.blocks.insert(blockType[type], {
        // Optionally, pass initial data for the block
        questions: [
          {
            question: question,
            type,
            options: ['Option 1', 'Option 2'],
            description: '',
            required: false,
            isDescription: false,
            range: [1, 3],
          },
        ],
      });
    }
  };

  const handleRemoveQuestion = (cardIndex) => {
    const questionLength = questions.length;
    questionLength > 1 &&
      setQuestions((_questions) => {
        const newQuestions = _questions.filter(
          (_, index) => index !== cardIndex
        );
        return newQuestions;
      });
  };

  const handleCopyQuestion = async (cardIndex) => {
    await setQuestions((_questions) => {
      const newQuestions = [..._questions];
      newQuestions.splice(cardIndex + 1, 0, questions[cardIndex]);
      return newQuestions;
    });
    setScrollToCardRef(cardIndex + 1);
    setFocusedQuestionIndex(cardIndex + 1);
  };

  const newCardRef = useRef(null);

  React.useEffect(() => {
    if (scrollToCardRef !== null && newCardRef.current) {
      newCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setScrollToCardRef(null);
    }
  }, [scrollToCardRef]);
  return (
    <Box className={classes.root}>
      <Box width="100%" className={classes.padding}>
        {questions?.map((question, index) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="2rem"
          >
            <Box flex={1}>
              {index === focusedQuestionIndex ? (
                <div ref={newCardRef}>
                  <QestionCard
                    deleteBlock={deleteBlock}
                    getBlockIndex={getBlockIndex}
                    updateQuestion={handleUpdateQuestion}
                    removeQuestion={handleRemoveQuestion}
                    handleCopyQuestion={handleCopyQuestion}
                    question={question}
                    index={index}
                    setFocusedQuestionIndex={setFocusedQuestionIndex}
                    isNotes={isNotes}
                    changeQuestion={changeQuestion}
                    addNewQuestion={addNewQuestion}
                  />
                </div>
              ) : (
                <QuestionPreview
                  question={question}
                  index={index}
                  setFocusedQuestionIndex={setFocusedQuestionIndex}
                  isFastPreview
                  ignoreRequired={isNotes}
                  editMode={true}
                />
              )}
            </Box>
            <Box minWidth="33px" ml="5px">
              {index === focusedQuestionIndex && (
                <Box
                  style={{ cursor: 'pointer' }}
                  minWidth="33px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() =>
                    addNewQuestion(question.type, question.question)
                  }
                >
                  <AddIcon />
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default FormGenerator;
