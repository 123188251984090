import React, { ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useGetMenuNotifications } from 'components/Layout/header/useGetMenuNotifications';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import NotificationsTab from 'pages/PatientNotificationPage/notificationTabs/NotificationTab';
import { useProvider } from 'pages/PatientNotificationPage/Provider';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';

const useStyles = makeStyles((theme) => ({
  largeScreen: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  smallScreen: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tab: {
    width: '100%',
    marginLeft: '3rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '3.2rem',
    },
  },
}));

const NotificationPage = (): ReactElement => {
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Notifications' });
  }, [headerTitleDispatcher]);
  const { user } = useAuth();
  const userId = user?._id;
  const { start, limit, setStart, setLimit, page, setPage, count } =
    useProvider();
  const { newNotifications, newCount } = useGetMenuNotifications(userId, {
    start,
    limit,
  });
  return (
    <Box
      style={{
        backgroundColor: 'white',
        borderRadius: '16px',
        border: '1px solid #EAECF0',
      }}
    >
      <NotificationsTab
        elements={newNotifications}
        title="Notifications"
        handlePage={setPage}
        handleStart={setStart}
        handleLimit={setLimit}
        page={page}
        count={count}
        counting={newCount}
      />
    </Box>
  );
};

export default NotificationPage;
