import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from 'draft-js';

function convertToRawAndStringify(description: EditorState): string {
  return JSON.stringify(convertToRaw(description.getCurrentContent()));
}

function convertFromStringifiedRaw(
  rawStringifiedContent: string
): ContentState {
  return convertFromRaw(JSON.parse(rawStringifiedContent));
}

export function limitTextFromState(
  editorState: EditorState,
  charactersNumber: number
) {
  const currentContent = editorState.getCurrentContent();
  const plainText = currentContent.getPlainText();
  const newContentState = ContentState.createFromText(
    plainText.substr(0, charactersNumber)
  );
  const newEditorState = EditorState.createWithContent(newContentState);

  return newEditorState;
}
function tryParseJSON(jsonString: string): boolean {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return true;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return false;
}
function editorStateFromStringifiedRaw(
  content: string,
  loading: boolean
): EditorState {
  return loading
    ? EditorState.createEmpty()
    : EditorState.createWithContent(convertFromStringifiedRaw(content));
}

function convertStringToEditorState(value: any): any {
  return editorStateFromStringifiedRaw(value, !tryParseJSON(value));
}
export function convertToPlainText(description: EditorState): string {
  const blocks = convertToRaw(description.getCurrentContent()).blocks;
  const value = blocks
    .map((block) => (!block.text.trim() && '\n') || block.text)
    .join('\n');
  return value;
}
function formattingLists(value: any): any {
  const editorState = convertStringToEditorState(value);
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;

  let htmlValue = '';
  let isList = false;

  blocks.forEach((block) => {
    if (block.type === 'unordered-list-item') {
      if (!isList) {
        htmlValue += '<ul>';
        isList = true;
      }
      htmlValue += `<li>${block.text}</li>`;
    } else if (block.type === 'ordered-list-item') {
      if (!isList) {
        htmlValue += '<ol>';
        isList = true;
      }
      htmlValue += `<li>${block.text}</li>`;
    } else {
      if (isList) {
        htmlValue += block.type === 'unordered-list-item' ? '</ul>' : '</ol>';
        isList = false;
      }
      htmlValue += block.text ? `<p>${block.text}</p>` : '<br>';
    }
  });
  if (isList) {
    htmlValue += htmlValue.includes('<ul>') ? '</ul>' : '</ol>';
  }

  return htmlValue;
}

function createEmptyDraftjs() {
  return EditorState.createEmpty();
}

const draftJsUtils = {
  convertFromStringifiedRaw,
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
  createEmptyDraftjs,
  formattingLists,
};

export {
  convertFromStringifiedRaw,
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
  createEmptyDraftjs,
  formattingLists,
};

export default draftJsUtils;
