import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography, FormControl, Checkbox } from '@material-ui/core';
import { ReactComponent as FreqIcon } from 'assets/patientActivities/freq.svg';
import Input from 'components/ui/Inputs';
import { useFormContext, Controller } from 'react-hook-form';
import DaysChip from './DaysChip';
import { days } from './constants';
import { getDayName } from 'utils';
import { ReactComponent as FrequencyIcon } from 'assets/Schedule/new/freq.svg';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import { useTranslation } from 'react-i18next';

interface IFrequencyProps {
  defaultValues: {
    startDate: string;
    endDate: string;
    frequencyType: string;
    repetition: {
      value: number;
      unit: string;
    };
    repeatOn: string[];
  };
}

function Frequency({ defaultValues }: IFrequencyProps) {
  const { t } = useTranslation();
  const startDate = defaultValues?.startDate;
  const endDate = defaultValues?.endDate;
  const isSameDate =
    new Date(startDate).getTime() === new Date(endDate).getTime();
  const [frequencyExpand, setFrequencyExpand] = React.useState<boolean>(
    // !isSameDate
    false
  );
  const classes = useStyles({ selected: false });
  const [disabled, setDisabled] = React.useState(true);
  const { control, register, errors, getValues, watch, setValue } =
    useFormContext();
  const commonProps = {
    control,
    errors,
    register,
    variant: 'filled',
    disabled,
  };
  React.useEffect(() => {
    const startDate = getValues('dueDate') && getValues('dueDate')[0];
    const endDate = getValues('dueDate') && getValues('dueDate')[1];
    const isSameDate =
      new Date(startDate).getTime() === new Date(endDate).getTime();
    if (startDate) {
      setValue('repeatOn', getDayName(startDate));
    }
    if (startDate && endDate && !isSameDate) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [getValues('dueDate')]);
  return (
    <Box className={classes.frequencyContainer} mt={3} zIndex={2}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" sx={{ gridGap: '1rem' }}>
          <FrequencyIcon />
          <Typography className={classes.freqTitle}>
            {t('Frequency')}
          </Typography>
        </Box>
        <Checkbox
          name="frequencyExpanded"
          size="medium"
          defaultChecked={frequencyExpand}
          onChange={() => setFrequencyExpand((s) => !s)}
          checkedIcon={<CheckedIcon />}
          icon={<UncheckedIcon />}
          style={{ backgroundColor: 'transparent' }}
        />
      </Box>
      {/* <Typography
        className={classes.freqDesc}
        style={{
          height: !frequencyExpand ? 'unset' : 0,
          visibility: !frequencyExpand ? 'visible' : 'hidden',
          overflow: !frequencyExpand ? 'unset' : 'hidden',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        Set up recurring sessions
      </Typography> */}
      <Box
        pr="9px"
        pl="52px"
        py={frequencyExpand && 1.5}
        style={{
          height: frequencyExpand ? 'unset' : 0,
          visibility: frequencyExpand ? 'visible' : 'hidden',
          overflow: frequencyExpand ? 'unset' : 'hidden',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        <Box display="flex" alignItems="center" width="100%" mt={2}>
          <Box minWidth="7rem">
            <Typography className={classes.subLabel}>Date range</Typography>
          </Box>
          <Input
            type="dateRange"
            name="dueDate"
            className={classes.inputDate}
            defaultValue={
              defaultValues
                ? [defaultValues?.startDate, defaultValues?.endDate]
                : [new Date(), new Date()]
            }
            {...commonProps}
          />
        </Box>
        <Box display="flex" alignItems="center" width="100%" mt={4}>
          <Box minWidth="7rem">
            <Typography className={classes.subLabel}>
              {t('Repeat every')}
            </Typography>
          </Box>
          <Input
            type="number"
            name="numberOfRepeat"
            inputRef={register}
            className={classes.numberInput}
            defaultValue={defaultValues?.repetition.value || 1}
            InputProps={{
              inputProps: {
                max: 99,
                min: 1,
              },
            }}
            {...commonProps}
          />
          <Input
            type="select"
            name="repeatEvery"
            defaultValue={defaultValues?.repetition?.unit || 'day'}
            options={['day', 'week', 'month']}
            className={classes.input}
            {...commonProps}
          />
        </Box>
        {watch()?.repeatEvery === 'week' && (
          <Box display="flex" alignItems="center" width="100%" mt={4}>
            <Box minWidth="7rem">
              <Typography className={classes.subLabel}>
                {t('Repeat on')}
              </Typography>
            </Box>
            <Controller
              name="repeatOn"
              control={control}
              register={register}
              defaultValue={
                defaultValues?.repeatOn || getDayName(getValues('dueDate')[0])
              }
              render={({ onChange, value }) => (
                <DaysChip
                  handleChange={onChange}
                  value={value}
                  options={days}
                />
              )}
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" width="100%" mt={4}>
          <Box minWidth="7rem">
            <Typography className={classes.subLabel}>{t('Repeat')}</Typography>
          </Box>
          <Input
            type="select"
            name="repeat"
            defaultValue={defaultValues?.frequencyType || 'Once a day'}
            options={['Once a day', 'Twice a day']}
            className={classes.inputDate}
            {...commonProps}
          />
        </Box>
      </Box>{' '}
    </Box>
  );
}

export default Frequency;
