import React from 'react';
import useStyles from './styles';
import { Box, Typography, Avatar, Tooltip } from '@material-ui/core';
import { ReactComponent as SessionIcon } from 'assets/conflicts/session.svg';
import { ReactComponent as EventIcon } from 'assets/conflicts/event.svg';
import { ReactComponent as ParticipantsIcon } from 'assets/conflicts/participants.svg';
import { ReactComponent as RemoveConflict } from 'assets/DeleteConflict.svg';
import { ReactComponent as EditConflict } from 'assets/EditConflict.svg';
import CheckIcon from 'assets/CheckIcon.png';
import clsx from 'clsx';
import { IConflict } from './types';
import { config } from 'config';
import { Editor } from 'draft-js';
import {
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { PARTICIPANT_GOING_STATUS } from 'components/ui/Calendar/EventContent/CustomContentRender';
import Button from 'components/ui/Button';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import useDeleteEvent from '@eggmed/graphql-client/operations/eventOperations/useDeleteEvent';
import { colorsMapping } from 'pages/AdminPage/ManageTags/Constant';
import { useTranslation } from 'react-i18next';

type IConflictExpanded = {
  expanded: boolean;
  event: IConflict;
  onClose?: () => void;
};
export enum EventType {
  APPOINTMENT = 'session',
  EVENT = 'event',
}

export const ConflictExpanded = ({
  expanded,
  event,
  onClose,
}: IConflictExpanded) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    conditionType,
    sessionType,
    doctor,
    patient,
    patientGoing,
    invitation,
    description,
    __typename,
  } = event;
  const isAppointment = __typename == 'Appointment';
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { handleEditCurrentEvent, handleBack } = useProvider();
  const { deleteAppointment } = useDeleteAppointment();
  const { deleteEvent } = useDeleteEvent();
  function handleCloseDeleteModal() {
    setOpenDeleteModal(false);
  }
  function handleOpenDeleteModal() {
    if (event?.doctorGoing) {
      setOpenDeleteModal(true);
    } else {
      onClose();
    }
  }
  const handelDelete = async (id: string) => {
    if (!event?.title) {
      await deleteAppointment(id, true, null);
    } else {
      await deleteEvent(id, true, null);
    }
    setOpenDeleteModal(false);
  };
  return (
    <>
      <Box className={classes.expanded}>
        {conditionType?.tag && expanded && (
          <Typography
            variant="body2"
            component="h6"
            className={classes.sessionLabel}
            style={{
              backgroundColor: colorsMapping[conditionType?.color],
            }}
          >
            {conditionType?.tag}
          </Typography>
        )}
        {sessionType && (
          <Box
            className={clsx(
              classes.sessionType,
              !conditionType?.tag && classes.extraMargin
            )}
          >
            <SessionIcon />
            <Box>
              <Typography
                variant="body2"
                component="h6"
                className={classes.subtitles}
              >
                Service
              </Typography>
              <Typography variant="body2" component="h6">
                {`${sessionType.duration}min ${sessionType.session}`}
              </Typography>
              <Typography
                variant="body2"
                component="h6"
                className={classes.price}
              >
                {sessionType.currency === 'EUR' ? '€' : '$'} {sessionType.rate}
              </Typography>
            </Box>
          </Box>
        )}
        {isAppointment && patient && (
          <Box className={classes.participants}>
            <ParticipantsIcon />
            <Box>
              <Typography
                variant="body2"
                component="h6"
                className={classes.subtitles}
              >
                Participants
              </Typography>
              <Box className={classes.participantContainer}>
                <Tooltip title={doctor.email}>
                  <Avatar
                    src={doctor.picture ?? ''}
                    className={classes.paticipantAvatar}
                  />
                </Tooltip>
                <img src={CheckIcon} className={classes.paticipantStatus} />
                <Box display="flex" flexDirection="column" ml={1}>
                  <Typography className={classes.paticipantName}>
                    {`${doctor.firstname} ${doctor.lastname}`}
                  </Typography>
                  <Typography className={classes.paticipantConfirmation}>
                    {t('Yes')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.participantContainer}>
                <Tooltip title={patient?.email}>
                  <Avatar
                    src={patient?.picture ?? ''}
                    className={classes.paticipantAvatar}
                  />
                </Tooltip>
                {patientGoing &&
                  patientGoing === PARTICIPANT_GOING_STATUS.yes && (
                    <img src={CheckIcon} className={classes.paticipantStatus} />
                  )}
                <Box display="flex" flexDirection="column" ml={1}>
                  <Typography className={classes.paticipantName}>
                    {`${patient?.firstname} ${patient?.lastname}`}
                  </Typography>
                  {patientGoing && (
                    <Typography className={classes.paticipantConfirmation}>
                      {patientGoing}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box className={classes.participants}>
          <Box>
            <Typography
              variant="body2"
              component="h6"
              className={classes.subtitles}
            >
              Description
            </Typography>
            <Typography
              variant="body2"
              component="h6"
              className={classes.description}
            >
              {!tryParseJSON(description) ? (
                '-'
              ) : (
                <Editor
                  onChange={() => {}}
                  editorState={editorStateFromStringifiedRaw(
                    description,
                    false
                  )}
                  readOnly
                />
              )}
            </Typography>
          </Box>
        </Box>

        {isAppointment && invitation?.invitation_id && (
          <Box className={classes.eventDetails}>
            <EventIcon />
            <Box>
              <Typography
                variant="body2"
                component="h6"
                className={classes.subtitles}
              >
                {t('Event details')}
              </Typography>
              <Typography variant="body2" component="p">
                {invitation?.invitation_id ? (
                  <a
                    href={`${config.CLIENT}/doctor/patient/${patient?._id}?event=${event._id}&tab=2`}
                    target="_blank"
                    className={classes.meetingLink}
                  >
                    {t('Join meeting')}
                  </a>
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: '10px' }}
          mt={2}
        >
          <Button
            className={classes.remove}
            onClick={() => handleOpenDeleteModal()}
          >
            {' '}
            <RemoveConflict style={{ marginRight: '5px' }} />
            Remove
          </Button>

          <Button
            className={classes.edit}
            onClick={() => {
              onClose();
              handleBack(true);
              if (event?.doctorGoing) {
                handleEditCurrentEvent(event);
              }
            }}
          >
            <EditConflict style={{ marginRight: '5px' }} />
            Edit
          </Button>
        </Box>
      </Box>
      <ModalDelete
        text={event?.title ? EventType.EVENT : EventType.APPOINTMENT}
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => handelDelete(event?._id)}
      />
    </>
  );
};
