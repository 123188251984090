import { gql, useSubscription } from '@apollo/client';
import {
  subscriptionNewUpdateThread,
  subscriptionNewUpdateThreadVariables,
  subscriptionNewUpdateThread_subscriptionNewUpdateThread,
} from './__generated__/subscriptionNewUpdateThread';

const SUBSCRIPTION_TO_UPDATE_THREAD = gql`
  subscription subscriptionNewUpdateThread($userId: ID!) {
    subscriptionNewUpdateThread(userId: $userId) {
      _id
      updatedAt
      createdAt
      type
      subject
      lastUpdateTime
      unReadItMessage {
        id
        threadConversation
        user
        lastMessageReadIt
      }
      isArchived
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      lastMessage {
        user {
          _id
          firstname
          middlename
          username
        }
        content {
          text
          files {
            _id
            url
            fileName
            mimeType
          }
        }
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
        createdAt
        updatedAt
      }
    }
  }
`;

const useSubscriptionNewUpdateThread = (
  userId: string,
  event: (data: subscriptionNewUpdateThread_subscriptionNewUpdateThread) => void
) => {
  const result = useSubscription<
    subscriptionNewUpdateThread,
    subscriptionNewUpdateThreadVariables
  >(SUBSCRIPTION_TO_UPDATE_THREAD, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      event(subscriptionData?.data?.subscriptionNewUpdateThread);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_UPDATE_THREAD };
export default useSubscriptionNewUpdateThread;
