import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { ReactComponent as EggmedMobile } from 'assets/EggmedMobile.svg';
import { ReactComponent as MobileCheck } from 'assets/MobileCheck.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(45deg, #39A0FF 0%, #8FFF85 100%)',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '16px',
    width: '80vw',
    height: '50vh',
  },
  text: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#182230',
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#182230',
  },
  span: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#1890FF',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const DefaultMobileScreen = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.modal} p={2}>
        <EggmedMobile />
        <Box mt={4} display="flex" justifyContent="center">
          <MobileCheck />
        </Box>
        <Box mt={4}>
          <Typography className={classes.text}>
            Log in on laptop or desktop to use the platform
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography className={classes.description}>
            Email <span className={classes.span}>support@eggmed.com</span> if
            you have any questions{' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DefaultMobileScreen;
