import { gql, useSubscription } from '@apollo/client';

const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription OnNewMessage($roomId: String!) {
    newMessage(roomId: $roomId) {
      text
      date
      from
    }
  }
`;

export const useListenMessage = (handleNewMessage: any, roomId: string) => {
  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      handleNewMessage(data.subscriptionData.data.newMessage);
    },
  });
};
