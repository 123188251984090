import React, { ReactElement, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import dashboardHeader from '../../assets/dashboard/dashboardHeader.svg';
import Help from 'pages/HelpPage/Help';
import { useTranslation } from 'react-i18next';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    height: '300px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',

    [theme.breakpoints.only('lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(2),
      height: '200px',
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      height: '200px',
    },
  },
  title: {
    fontSize: '48px',
    fontWeight: 400,
    color: 'rgba(39, 49, 66, 1)',
    marginTop: '-5rem',
    marginLeft: '2rem',
  },
}));

const HelpPage = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Help center') });
  }, [headerTitleDispatcher]);

  return (
    <Box>
      <Box
        style={{ backgroundImage: `url(${dashboardHeader})` }}
        className={classes.img}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography className={classes.title}>How can we help you?</Typography>
      </Box>
      <Box mt="-5rem" mb="2rem">
        <Help />
      </Box>
    </Box>
  );
};
export default HelpPage;
