import React from 'react';
import { IColumn } from 'components/ui/Table/types';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/smallEye.svg';
import DropdownIcon from 'assets/patientActivities/dropdown';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useStyles } from 'pages/PatientActivities/styles';
import { convertMsToDate, formatToFullDate } from 'utils/dateUtils';
import { TypeEnum } from './Activity';
import { ReactComponent as Saved } from 'assets/Etoile.svg';
import { ReactComponent as SavedYellow } from 'assets/EtoileJaune.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as Upload } from 'assets/uploadD.svg';
import { ReactComponent as Unsaved } from 'assets/UnselectedStart.svg';
import { ReactComponent as SavedStart } from 'assets/SelectedStart.svg';

import Checked from 'pages/AdminPage/Payment/Checked';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
export interface ITableColumnsProps {
  type: TypeEnum | string;
  handleEmoji: (emoji: string, emojis: any[]) => JSX.Element;
  emojis?: any[];
  openDownloadMenu: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  setEdited: (isEdited: boolean) => void;
  setPdfUrl: (url: string) => void;
  setDeleteId: (id: string) => void;
  openActionsMenu: (event: any) => void;
  setBookmark: (bookmarked: boolean) => void;
  handleBookMark: (id: string, bookmarked: boolean) => void;
  setTypeValue?: (type: string) => void;
  setOpenResult?: (open: boolean) => void;
  handleEdit?: () => void;
  handleDeleteRow?: () => void;
}

function TableColumns({
  type,
  handleEmoji,
  emojis,
  openDownloadMenu,
  setEdited,
  setPdfUrl,
  setDeleteId,
  openActionsMenu,
  setBookmark,
  handleBookMark,
  setOpenResult,
  handleEdit,
  handleDeleteRow,
}: ITableColumnsProps) {
  const classes = useStyles({});
  const { t } = useTranslation();
  const columns: IColumn<any>[] = [
    {
      id: 'question',
      label: <Box fontSize="1rem">Reflection</Box>,
      align: 'left',
      minWidth: 240,
      format: (value, { reflectionType, patientResponse }) => {
        return (
          <Box>
            <Typography
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {reflectionType === 'guided'
                ? value
                : patientResponse[0]?.response || '-'}
            </Typography>
            {reflectionType === 'guided' && (
              <Typography
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                {patientResponse[0]?.response || '-'}
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      id: 'reflectionType',
      label: (
        <Box fontSize="1rem">
          {type === TypeEnum.REFLECTION ? 'Type' : t(`Mood`)}
        </Box>
      ),
      minWidth: 50,
      align: 'left',
      format: (value, { patientResponse }) => {
        const emoji =
          patientResponse.length > 0
            ? patientResponse[patientResponse.length - 1].response
            : '-';
        return (
          <>
            {type === TypeEnum.REFLECTION ? (
              <Typography style={{ textTransform: 'capitalize' }}>
                {t(value)}
              </Typography>
            ) : (
              <Box display="flex" alignItems="center" style={{ gap: '0.5rem' }}>
                {handleEmoji(emoji, emojis)}
                <Typography>{t(emoji)}</Typography>
              </Box>
            )}
          </>
        );
      },
    },
    {
      id: 'tags',
      label: <Box fontSize="1rem">Emotions</Box>,
      align: 'left',
      minWidth: 50,
      disableSorting: true,
      format: (value, { patientResponse }) => {
        const response =
          patientResponse?.length > 0 && patientResponse[0]?.tags;
        if (typeof response !== 'boolean') {
          if (response?.length > 0)
            return (
              <Box display="flex" alignItems="center" style={{ gap: '5px' }}>
                {response?.slice(0, 3)?.map((el) => (
                  <Typography className={classes.tagInside}>
                    {t(el) || '-'}
                  </Typography>
                ))}
                {response?.length > 3 && (
                  <Tooltip
                    title={response?.slice(3).join(', ')}
                    classes={{
                      popper: classes.customTooltip,
                    }}
                    placement="bottom"
                  >
                    <Typography className={classes.tagInside}>...</Typography>
                  </Tooltip>
                )}
              </Box>
            );
          return '-';
        } else {
          return '-';
        }
      },
    },
    {
      id: 'note',
      label: <Box fontSize="1rem">Note</Box>,
      align: 'left',
      minWidth: 50,
      disableSorting: true,
      format: (value, { patientResponse }) => {
        const notes = patientResponse.length > 0 && patientResponse[0].notes;
        return <Checked checkedValue={!!notes} disabled color="#98A2B3" />;
      },
    },
    {
      id: 'photo',
      label: <Box fontSize="1rem">Photo</Box>,
      align: 'left',
      minWidth: 50,
      disableSorting: true,
      format: (value, { patientResponse }) => {
        const photo = patientResponse.length > 0 && patientResponse[0].image;
        return <Checked checkedValue={!!photo} disabled color="#98A2B3" />;
      },
    },
    {
      id: 'Sleep',
      label: <Box fontSize="1rem">{t('Sleep hours')}</Box>,
      align: 'left',
      minWidth: 130,
      disableSorting: true,
      format: (value, { patientResponse }) => {
        const sleepHours =
          patientResponse.length > 0 && patientResponse[0].sleepHours;
        const wakeHours =
          patientResponse.length > 0 && patientResponse[0].wakeHours;
        return (
          <Box>
            <Typography>
              {sleepHours
                ? `from ${dayjs(sleepHours).format('ha')} to
              ${dayjs(wakeHours).format('ha')}`
                : '-'}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'assignedAt',
      label: <Box fontSize="1rem">{t('Assigned date')}</Box>,
      align: 'left',
      minWidth: 90,
      format: (value) => {
        return convertMsToDate(value);
      },
    },
    {
      id: 'entryDate',
      label: <Box fontSize="1rem">{t('Entry date')}</Box>,
      align: 'left',
      minWidth: 90,
      format: (value, { patientResponse }) => {
        const date = patientResponse.length > 0 && patientResponse[0].createdAt;
        return date ? convertMsToDate(date) : '-';
      },
    },
    {
      id: 'saved',
      label: '',
      align: 'left',
      minWidth: 80,
      disableSorting: true,
      format: (
        value,
        { _id, type, bookmarked, patientResponse, activityUrl, isEdited }
      ) => {
        const date = patientResponse.length > 0;
        const handleClick = (event) => {
          openDownloadMenu(event);
          setPdfUrl(activityUrl);
          setEdited(isEdited);
          setDeleteId(_id);
        };
        return (
          <Box
            onClick={(event) => {
              setDeleteId(_id);
              setBookmark(bookmarked);
            }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {bookmarked ? (
              <SavedStart
                onClick={() => handleBookMark(_id, !bookmarked)}
                style={{ marginRight: '15px', cursor: 'pointer' }}
              />
            ) : (
              <Unsaved
                onClick={() => handleBookMark(_id, !bookmarked)}
                style={{ marginRight: '15px', cursor: 'pointer' }}
              />
            )}
            {/* <Box
              style={{ marginRight: '15px', cursor: 'pointer' }}
              display="flex"
              alignItems="center"
            >
              <Box
                style={{ cursor: 'pointer' }}
                onClick={() => handleBookMark(_id, !value)}
              >
                {value ? <SavedStart /> : <Unsaved />}
              </Box>
            </Box> */}
            {date && (
              <Eye
                style={{ marginRight: '15px', cursor: 'pointer' }}
                onClick={() => {
                  setDeleteId(_id);
                  setOpenResult(true);
                  setPdfUrl(activityUrl);
                }}
              />
            )}
            {date && (
              <Box
                style={{ marginRight: '15px', cursor: 'pointer' }}
                onClick={(event) => handleClick(event)}
                display="flex"
                alignItems="center"
              >
                <Upload />
              </Box>
            )}
            <DeleteIcon
              style={{ marginRight: '15px', cursor: 'pointer' }}
              onClick={handleDeleteRow}
            />
            <EditIcon onClick={handleEdit} style={{ cursor: 'pointer' }} />
          </Box>
        );
      },
    },
  ];

  const columnsByType = {
    Mood: columns?.filter(
      (el) => el?.id !== TypeEnum.SLEEP && el?.id !== 'question'
    ),
    Sleep: columns?.filter((el) => el?.id !== 'question'),
    reflection: columns?.filter(
      (el) =>
        el?.id !== TypeEnum.SLEEP && el?.id !== 'tags' && el?.id !== 'note'
    ),
  };

  return columnsByType[type];
}

export default TableColumns;
