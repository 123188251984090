export const CHANGE_TAB = 'CHANGE_TAB';
export const OPEN_PRESCRIPTION_ADD = 'ADD_PRESCRIPTION';
export const CLOSE_PRESCRIPTION_ADD = 'CLOSE_PRESCRIPTION';
export const OPEN_REFERRAL_ADD = 'ADD_REFERRAL';
export const CLOSE_REFERRAL_ADD = 'CLOSE_REFERRAL';
export const EDIT_REFERRAL_MODE = 'EDIT_REFERRAL_MODE';
export const DELETE_REFERRAL_MODE = 'DELETE_REFERRAL_MODE';
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL';
export const EDIT_LAB_MODE = 'EDIT_LAB_MODE';
export const OPEN_LAB_ADD = 'OPEN_LAB_ADD';
export const OPEN_CONSULTATION_ADD = 'OPEN_CONSULTATION_ADD';
export const EDIT_CONSULTATION_MODE = 'EDIT_CONSULTATION_MODE';
export const OPEN_PRESCRIPTION_DRUG_ADD = 'OPEN_PRESCRIPTION_DRUG_ADD';
export const DELETE_PRESCRIPTION_DRUG_MODE = 'DELETE_PRESCRIPTION_DRUG_MODE';
export const EDIT_PRESCRIPTION_DRUG_MODE = 'EDIT_PRESCRIPTION_DRUG_MODE';
export const OPEN_IMAGING_ADD = 'OPEN_IMAGING_ADD';
export const EDIT_IMAGING_MODE = 'EDIT_IMAGING_MODE';
export const DELETE_IMAGING_MODE = 'DELETE_IMAGING_MODE';
