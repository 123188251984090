import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_CREDIT_CARD } from 'pages/AdminPage/index';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useCreateCreditCard from '@eggmed/graphql-client/operations/creditCardOperations/useCreateCreditCard';
import useEditCreditCardbyID from '@eggmed/graphql-client/operations/creditCardOperations/useUpdateCreditCardbyID';
import useDeleteCreditCardByID from '@eggmed/graphql-client/operations/creditCardOperations/useDeleteCreditCardByID';
import { IuserCreditCard } from './types';

const useBilling = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [dropDownEL, setDropDownEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = React.useState<IuserCreditCard>();

  const { createCreditCard, loading: loadingAdd } = useCreateCreditCard();
  const { updateCreditCardbyID, loading: loadingEdit } =
    useEditCreditCardbyID();
  const { deleteCreditCardbyID } = useDeleteCreditCardByID();

  const { user } = useAuth();

  const {
    data: creditCards,
    loading,
    error,
  } = useQuery(GET_CREDIT_CARD, {
    variables: { userid: user?._id || user?.user?._id },
  });

  const handleClick = () => {
    setOpen(true);
    setEditMode(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setOpen(true);
    setEditMode(true);
    setDropDownEl(null);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickDrop = (event, row: IuserCreditCard) => {
    setDropDownEl(event.currentTarget);
    setCurrentRow(row);
  };

  async function handleChange(_, row: IuserCreditCard) {
    await updateCreditCardbyID({
      variables: {
        creditCardId: row?._id,
        updateCreditCard: {},
        isSelect: true,
      },
    });
  }

  const handleDelete = (id) => {
    deleteCreditCardbyID({
      variables: {
        creditCardId: id,
      },
    });
    handleCloseDelete();
  };

  return {
    open,
    setOpen,
    openDelete,
    setOpenDelete,
    editMode,
    setEditMode,
    dropDownEL,
    setDropDownEl,
    currentRow,
    setCurrentRow,
    createCreditCard,
    updateCreditCardbyID,
    deleteCreditCardbyID,
    user,
    creditCards,
    loading,
    error,
    handleClick,
    handleClose,
    handleEdit,
    handleOpenDelete,
    handleCloseDelete,
    handleClickDrop,
    handleChange,
    handleDelete,
    loadingAdd,
    loadingEdit,
  };
};

export default useBilling;
