import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT_ACCESS } from './useGetPatientAccess';

const REMOVE_ALL_ACCESS = gql`
  mutation removeAllAccessFromDoctor($doctorId: String, $patientId: String) {
    removeAllAccessFromDoctor(doctorId: $doctorId, patientId: $patientId) {
      _id
    }
  }
`;
const useRemoveAllAccessToDoctor = () => {
  const [RemoveAccess] = useMutation(REMOVE_ALL_ACCESS, {
    refetchQueries: [{ query: GET_PATIENT_ACCESS }],
  });
  async function handleRemoveAllAccessToDoctor(
    doctorId: String,
    patientId?: String
  ) {
    try {
      return await RemoveAccess({
        variables: { doctorId, patientId },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleRemoveAllAccessToDoctor };
};

export { REMOVE_ALL_ACCESS };
export default useRemoveAllAccessToDoctor;
