import NotificationProvider from 'pages/PatientNotificationPage/Provider';
import React from 'react';
import NotificationPage from './NotificationPage';

const Index = () => {
  return (
    <NotificationProvider>
      <NotificationPage />
    </NotificationProvider>
  );
};

export default Index;
