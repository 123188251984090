import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const SuccessButton = withStyles((theme) =>
  createStyles({
    root: {
      color: 'white',
      backgroundColor: '#2DC76D',
      // width: '11.625rem',
      // height: '2.9375rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#39B54A',
        borderColor: '#39B54A',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#2DC76D',
        borderColor: '#2DC76D',
      },
      //   '&:focus': {
      //     boxShadow: '0 0 0 0.2rem #2DC76D',
      //   },
    },
  })
)(Button);
export default SuccessButton;
