import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import EditGenralInfo from 'pages/PatientGeneralInfo/EditGenralInfo';
import PatientGeneral from 'pages/PatientGeneral/PatientGeneral';
import { changeRes } from 'utils';
import DataLoader from 'components/ui/DataLoader';
interface IwrapperProps {
  children: React.ReactElement;
  patientId: string;
  isPatient?: boolean;
}
const WrapperComponent = ({
  children,
  patientId,
  isPatient,
}: IwrapperProps) => {
  const {
    data: PatientData,
    error,
    loading,
  } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });
  return (
    <DataLoader error={error} data={PatientData}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {React.cloneElement(children, { patientId })}
        </Grid>
      </Grid>
    </DataLoader>
  );
};

export default WrapperComponent;
