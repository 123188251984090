import { Box } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import React from 'react';

function ActivitySkeleton() {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      p="1rem"
      border="1px solid #EAECF0"
      borderRadius="12px"
      minHeight="200px"
      flex="1"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Skeleton loading={true} type="circle">
          <Box
            style={{
              width: '36px',
              height: '36px',
              borderRadius: '100%',
            }}
          />
        </Skeleton>
        <Box display="flex" flexDirection="column" ml={2}>
          <Skeleton loading={true} type="text" width="100px" height="21px">
            <Box
              style={{
                width: '100px',
                height: '21px',
                borderRadius: '8px',
              }}
            />
          </Skeleton>
          <Skeleton loading={true} type="text" width="120px" height="21px">
            <Box
              style={{
                width: '120px',
                height: '21px',
                borderRadius: '8px',
              }}
            />
          </Skeleton>
        </Box>
      </Box>
    </Box>
  );
}

export default ActivitySkeleton;
