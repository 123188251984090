import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_BY_ID } from './useGetDoctor';

export const EDIT_DOCTOR = gql`
  mutation editDoctor(
    $doctorID: ID!
    $DoctorInput: DoctorEditInput!
    $picture: Upload
  ) {
    editDoctor(
      doctorID: $doctorID
      DoctorInput: $DoctorInput
      picture: $picture
    )
  }
`;

export const UPDATE_DOCTOR_TIMEZONE = gql`
  mutation updateDoctorTimeZone($timeZone: String!) {
    updateDoctorTimeZone(timeZone: $timeZone)
  }
`;

export default function useUpdateDoctor(doctorId?: string) {
  const [editDoctor, { loading, error }] = useMutation(EDIT_DOCTOR, {
    refetchQueries: [
      { query: GET_DOCTOR_BY_ID, variables: { DoctorId: doctorId } },
    ],
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          availableTimes(times) {
            return times;
          },
        },
      });
    },
  });

  return { editDoctor, loading, error };
}

export function useUpdateDoctorTimeZone() {
  return useMutation(UPDATE_DOCTOR_TIMEZONE, {
    update: (cache, { data: { timeZone } }) => {
      cache.modify({
        fields: {
          doctor() {
            return timeZone;
          },
        },
      });
    },
  });
}
