import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
  Theme,
} from '@material-ui/core';
import { ReactComponent as Participant } from 'assets/users-01.svg';
import { ReactComponent as Icon } from 'assets/video-recorder.svg';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Skeleton from 'pages/PatientGeneralInfo/../../components/ui/Skeleton';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as ReminderLogo } from 'assets/ReminderLogo.svg';
import useSetReminder from '@eggmed/graphql-client/operations/appointmentsOperations/useSetReminder';
import useGenerateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateAppointment';
import { ReactComponent as CalendarAppt } from 'assets/CalendarAppt.svg';
import { ReactComponent as ClockAppt } from 'assets/ClockAppt.svg';
import { ReactComponent as CurrencyAppt } from 'assets/CurrencyAppt.svg';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { ReactComponent as VideoAppt } from 'assets/VideoAppt.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { TranslationKeys } from 'shared/interfaces/i18';
import ProgressLoader from 'components/ui/ProgressLoader';
import LoadingSummary from './LoadingSummary';
import { ReactComponent as DownloadSummary } from 'assets/downloadIcon.svg';
import { ReactComponent as ViewSession } from 'assets/viewSession.svg';
import ErrorSnackBar from './ErrorSnackBar';
import SuccessSnackBar from './SuccessSnackBar';

export const chipColor = {
  paid: {
    background: '#ECFDF3',
    color: '#067647',
    border: '1px solid #ABEFC6',
  },
  pending: {
    background: '#FFFAEB',
    color: '#B54708',
    border: '1px solid #FEDF89 ',
  },
  overdue: {
    background: '#FFC6C5',
    color: '#D71300',
    border: '1px solid #FFC6C5 ',
  },
  waived: {
    background: 'rgba(86, 127, 189, 0.40)',
    color: '#567FBD',
    border: '1px solid rgba(86, 127, 189, 0.40) ',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    background: '#F4F3FF',
  },
};
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    border: '1px solid #D0D5DD',
    borderRadius: '16px',
    minHeight: '100px',
  },
  paymentTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '0.300349px',
    color: '#425364',
    marginBlock: theme.spacing(1),
  },
  paymentText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '0.300349px',
    color: '#425364',
  },
  btnC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '20px',
    color: '#425364',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  btnSC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '30px',
    minWidth: '104px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: '#1F61DC',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  header: {
    background: ({ isPrevious }: { isPrevious: boolean }) =>
      isPrevious ? '#EAECF0' : 'white',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
  lower: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  chip: {
    borderRadius: '16px',
    background: ({ isPaid }: { isPaid: string }) =>
      chipColor[isPaid]?.background,
    border: ({ isPaid }: { isPaid: string }) => chipColor[isPaid]?.border,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 8px',
  },
  chipText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    color: ({ isPaid }: { isPaid: string }) => chipColor[isPaid]?.color,
    textTransform: 'capitalize',
  },
  appointmentType: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#0C111D',
  },
  date: {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: 700,
    color: '#101828',
  },
  session: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
}));
const ApptCard = ({ event, loading }) => {
  const { t } = useTranslation();
  const { deleteAppointment } = useDeleteAppointment();
  const { handleGenerate, loadingGenerate, errorGenerate } =
    useGenerateAppointment();

  const { handleEditCurrentEvent } = useProvider();
  const { setReminder, loading: loadingReminder } = useSetReminder();

  const history = useHistory();
  const {
    startDate,
    endDate,
    locationLink,
    isPaid,
    patient,
    _id,
    sessionType,
    doctor,
    isEnded,
  } = event || {};
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
  }
  const handelDelete = (id: string) => {
    deleteAppointment(id, true, null);
    setOpenDeleteModal(false);
  };
  const lowerAction = [
    {
      name: t('Cancel'),
      icon: null,
      action: () => {
        handleOpenDeleteModal();
      },
    },
    {
      name: t('Edit'),
      icon: null,
      action: () => {
        handleEditCurrentEvent(event);
      },
    },
    {
      name: t('Join'),
      icon: null,
      action: () => {
        history.push(`/doctor/patient/${patient._id}?event=${_id}&tab=2`);
      },
    },
  ];
  const pastActions = [
    {
      name: t('Summary'),
      icon: loadingGenerate ? (
        <ProgressLoader width={17} height={17} color="#000000" />
      ) : (
        <DownloadSummary />
      ),
      action: () => {
        if (!loadingGenerate) {
          handleGenerate(_id);
          setDownloadStarted(true);
        }
      },
    },
    {
      name: 'View',
      icon: <ViewSession />,
      action: () => {
        history.push(`/doctor/patient/${patient._id}?event=${_id}&tab=2`);
      },
    },
    {
      name: 'Reminder',
      icon: null,
      action: () => {
        handleOpen();
      },
    },
  ];
  const actions =
    isPaid === t('pending')
      ? pastActions
      : pastActions?.filter((el) => el?.name !== 'Reminder');
  const isPrevious = new Date(endDate) < new Date();
  const classes = useStyles({ isPaid, isPrevious });
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  async function handleReminder(id: string) {
    await setReminder({
      variables: {
        appointmentId: id,
      },
    });
    handleClose();
  }
  const [downloadStarted, setDownloadStarted] = useState(false);
  return (
    <>
      <Box className={classes.container} mb={2}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.header}
          px={2}
          pt={2}
          style={{ backgroundColor: isEnded ? '#E6E6E6' : 'white' }}
        >
          <Box display="flex" alignItems="center">
            <Skeleton loading={loading}>
              <CalendarAppt style={{ marginRight: '5px' }} />
            </Skeleton>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Skeleton loading={loading}>
                <Typography className={classes.date}>
                  {dayjs(startDate).format('MMM DD')}
                </Typography>
              </Skeleton>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                ml={1}
              >
                <Skeleton loading={loading}>
                  <ClockAppt style={{ marginRight: '5px' }} />
                </Skeleton>
                <Skeleton loading={loading}>
                  <Typography className={classes.date}>
                    {dayjs(startDate).format('h:mm A')} -{' '}
                    {dayjs(endDate).format('h:mm A')}
                  </Typography>
                </Skeleton>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            style={{ gap: '10px' }}
            mb={2}
            pt={2}
          >
            <Skeleton loading={loading}>
              <Typography className={classes.session}>
                {locationLink ? (
                  <Box display="flex" alignItems="center">
                    <VideoAppt style={{ marginRight: '5px' }} />
                    <Text i18nKey="online" className={classes.appointmentType}>
                      Online
                    </Text>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <InPersonAppt style={{ marginRight: '5px' }} />
                    <Text i18nKey="inPerson" className={classes.text}>
                      In-person
                    </Text>
                  </Box>
                )}
              </Typography>
            </Skeleton>
            <Skeleton loading={loading}>
              <Box display="flex" alignItems="center" ml={1}>
                <CurrencyAppt style={{ marginRight: '5px' }} />
                <Typography className={classes.appointmentType}>
                  {sessionType?.rate} {sessionType?.currency}
                </Typography>
              </Box>
            </Skeleton>
            <Box className={classes.chip} ml={0.5}>
              <Skeleton loading={loading}>
                <Typography className={classes.chipText}>
                  {t(`${isPaid as TranslationKeys}`)}
                </Typography>
              </Skeleton>
            </Box>
          </Box>
        </Box>

        {new Date(endDate) > new Date() && !isEnded ? (
          <Box className={classes.lower}>
            {lowerAction?.map((el, key) => (
              <Box
                py={1}
                px={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                textAlign="center"
                style={{
                  borderTop: '1px solid #D0D5DD',
                  borderRight:
                    key < lowerAction.length - 1 && '1px solid #D0D5DD',
                  cursor: 'pointer',
                }}
                onClick={el.action}
              >
                {el.icon && (
                  <Skeleton loading={loading}>
                    <Box mr={1}>{el.icon}</Box>
                  </Skeleton>
                )}
                <Skeleton loading={loading}>
                  <Typography style={{ textAlign: 'center' }}>
                    {el.name}
                  </Typography>
                </Skeleton>
              </Box>
            ))}
          </Box>
        ) : (
          <Box className={classes.lower}>
            {actions?.map((el, key) => (
              <Box
                py={1}
                px={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                textAlign="center"
                style={{
                  borderTop: '1px solid #D0D5DD',
                  borderRight: key < actions.length - 1 && '1px solid #D0D5DD',
                  cursor: 'pointer',
                }}
                onClick={el.action}
              >
                {el.icon && (
                  <Skeleton loading={loading}>
                    <Box mr={1}>{el.icon}</Box>
                  </Skeleton>
                )}
                <Skeleton loading={loading}>
                  <Typography style={{ textAlign: 'center' }}>
                    {el.name}
                  </Typography>
                </Skeleton>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <ModalDelete
        text="session"
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => handelDelete(_id)}
      />
      <BasicModal
        open={open}
        onClose={handleClose}
        isRegularModal
        title=""
        titlePadding="3rem"
        handleClose={handleClose}
        isIcon={false}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          px={8}
          pb={4}
        >
          <ReminderLogo />
          <Box>
            <Text
              i18nKey="Send Payment Reminder"
              className={classes.paymentTitle}
            >
              Send Payment Reminder
            </Text>
            <Text
              i18nKey="Are you sure you want to send reminders to"
              className={classes.paymentText}
            >
              Are you sure you want to send reminders to
            </Text>
            <Text
              i18nKey="complete payment process?"
              className={classes.paymentText}
            >
              complete payment process?
            </Text>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Button className={classes.btnC} onClick={handleClose}>
              <Text i18nKey="cancel">Cancel</Text>
            </Button>
            <Button
              className={classes.btnSC}
              onClick={() => handleReminder(_id)}
            >
              {loadingReminder ? (
                <ProgressLoader width={20} height={20} />
              ) : (
                <Text i18nKey="send">Send</Text>
              )}
            </Button>
          </Box>
        </Box>
      </BasicModal>
      {downloadStarted && loadingGenerate && (
        <LoadingSummary loading={loadingGenerate} />
      )}
      {downloadStarted && !loadingGenerate && errorGenerate && (
        <ErrorSnackBar
          handleClick={() => handleGenerate(_id)}
          setDownloadStarted={setDownloadStarted}
        />
      )}
      {downloadStarted && !loadingGenerate && !errorGenerate && (
        <SuccessSnackBar
          handleClick={() => handleGenerate(_id)}
          setDownloadStarted={setDownloadStarted}
        />
      )}
    </>
  );
};

export default ApptCard;
