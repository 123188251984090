import { gql, useMutation } from '@apollo/client';

const REMOVE_RELATED_PATIENT = gql`
  mutation removeRelatedPatient($patientId: ID, $doctorId: ID) {
    removeRelatedPatient(patientId: $patientId, doctorId: $doctorId)
  }
`;
const useRemoveRelatedPatient = () => {
  const [removePatient] = useMutation(REMOVE_RELATED_PATIENT, {
    refetchQueries: [{ query: PATIENTS_RELATED }],
  });
  async function handleRemoveRelatedPatient(
    patientId: String,
    doctorId: String
  ) {
    try {
      return await removePatient({
        variables: { patientId, doctorId },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleRemoveRelatedPatient };
};

export { REMOVE_RELATED_PATIENT };
export default useRemoveRelatedPatient;
export const PATIENTS_RELATED = gql`
  query patientsRelated {
    patientsRelated {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
    }
  }
`;
