import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import { useSnackbar } from 'hooks/useSnackbar';
import Text from 'Text';
import { useForm } from 'react-hook-form';
import { InfoBlock } from 'pages/AdminPage/TabsContent/InfoBlock';
import { useStyles } from 'pages/AdminPage/TabsContent/style';
import { BlockNames } from 'pages/AdminPage/TabsContent/PersonalInformation';
import { GET_PATIENT_AUTH } from 'contexts/AuthContext';
import { useMutation, useQuery } from '@apollo/client';
import { FormInput } from 'pages/AdminPage/ManageTags/TagSelect';
import Input from 'components/ui/Inputs';
import { EDIT_PATIENT_OWNER } from 'pages/PatientProfile/GeneralInfoPatient';
import { useTranslation } from 'react-i18next';

import {
  timezoneLabels,
  buildTzOptions,
} from '@eggmed/common/utils/timezoneOption';
import { getTimeZones } from '@vvo/tzdb';
import { useUpdatePatientTimezone } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useUpdatePatientActivity';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';

export const RegionalTat = () => {
  const { patient } = useAuth();

  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { patientId: patient?._id },
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const [timeZone, setTimeZone] = useState('');
  const [selectEvent, setSelectEvent] = React.useState<any>('');

  const [editableBlock, setEditableBlock] = useState(null);
  const [currentSchema, setCurrentSchema] = useState<any>();
  const [updatePatientTimeZone] = useUpdatePatientTimezone();
  const timeZones = getTimeZones();
  const tzOptions = buildTzOptions(timeZones);
  useEffect(() => {
    if (patient?.timeZone) {
      const index = timeZones.findIndex((tz) => tz.name === patient?.timeZone);
      setTimeZone(tzOptions[index]);
      setSelectEvent(tzOptions[index]);
    }
  }, [patient, timezoneLabels]);
  const { timeZone: timezone, formatDate } = data?.patient || {};
  const { register, control, handleSubmit, reset, errors, watch } = useForm({
    // resolver: yupResolver(currentSchema),
    defaultValues: {
      timezone,
      formatDate,
    },
  });
  useEffect(() => {
    if (data?.patient) {
      reset({
        timezone,
        formatDate,
      });
    }
  }, [reset, data?.patient]);
  const { triggerSnack } = useSnackbar();
  const [editPatient] = useMutation(EDIT_PATIENT_OWNER, {
    refetchQueries: [{ query: GET_PATIENT_AUTH }],
    awaitRefetchQueries: false,
  });
  const onSubmit = async (formData) => {
    try {
      if (editableBlock === BlockNames.timeZone) {
        const index = tzOptions.findIndex((o) => o === timeZone);
        setSelectEvent(timeZone);
        await updatePatientTimeZone({
          variables: {
            timeZone: timeZones[index]?.name,
          },
        });
        setEditableBlock(null);
        triggerSnack();
        return;
      }
      await editPatient({
        variables: {
          patientId: patient?._id,
          EditPatientInput: formData,
          picture: undefined,
        },
      });
      setEditableBlock(null);
      triggerSnack();
    } catch (error) {
      console.error(error);
    }
  };
  const commonInputProps = {
    inputRef: register,
    variant: 'filled',
    control,
  };
  return (
    <Box mt={6} width="50%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InfoBlock
          title={t('Time zone')}
          content={selectEvent}
          blockName={BlockNames.timeZone}
          isEditable={editableBlock === BlockNames.timeZone}
          editableBlock={editableBlock}
          setEditableBlock={setEditableBlock}
          loading={loading}
        >
          <>
            <Skeleton loading={loading}>
              <AutoCompleteAddNew
                defaultValue={timeZone}
                value={timeZone}
                onChange={(event, value) => {
                  setTimeZone(value as string);
                }}
                options={tzOptions}
                getOptionLabel={(option) => option}
              />
            </Skeleton>
          </>
        </InfoBlock>
        <InfoBlock
          title={t('Date format')}
          content={formatDate}
          blockName={BlockNames.formatDate}
          isEditable={editableBlock === BlockNames.formatDate}
          editableBlock={editableBlock}
          setEditableBlock={setEditableBlock}
          loading={loading}
          isBorderBottom={false}
        >
          <>
            <Skeleton loading={loading}>
              <InputLabel
                style={{
                  marginBottom: '12.04px',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 500,
                  fontStyle: 'normal',
                  fontSize: '1rem',
                  color: '#425364',
                }}
              >
                <Text className={classes.label} i18nKey="Date format">
                  Date format
                </Text>
              </InputLabel>
            </Skeleton>
            <Skeleton loading={loading}>
              <Input
                type="select"
                name="formatDate"
                data-cy="gender-input"
                {...commonInputProps}
                options={[t('12-hour format'), t('24-hour format')]}
                defaultValue={formatDate}
              />
            </Skeleton>
          </>
        </InfoBlock>
      </form>
    </Box>
  );
};
