import { gql, useMutation } from '@apollo/client';

export const UPDATE_RATE = gql`
  mutation updateRate(
    $rateId: ID
    $updateRate: UpdateRate
    $isSelect: Boolean
  ) {
    updateRate(rateId: $rateId, updateRate: $updateRate, isSelect: $isSelect) {
      session
      duration
      currency
      rate
      selected
    }
  }
`;

export default function useUpdateRate() {
  const [updateRate, result] = useMutation(UPDATE_RATE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          rates() {
            return data?.updateRate;
          },
        },
      });
    },
  });
  return { updateRate, ...result };
}
