import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '3rem',
  },
  item: {
    paddingLeft: '3rem',
  },
  divider: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
    marginBottom: '1rem',
  },
  noRefillRequest: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#464665',
    maxWidth: '20rem',
  },
  scroll: {
    maxHeight: '70vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      paddingBottom: '1rem',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '20px',
    letterSpacing: '0.44px',
    color: '#425364',
    paddingBottom: '1rem',
  },
  card: {
    borderRadius: '8px',
    padding: '1rem 1rem 1rem 3rem',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '12px',
    },
  },

  read: {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    backgroundColor: '#1F61DC',
    [theme.breakpoints.down('sm')]: {
      minWidth: '6.3px',
      minHeight: '6.3px',
    },
  },
  cardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#425364',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '19.5px',
    },
  },
  date: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#A4A4A4',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '12px',
    },
  },
  notificationContent: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#666666',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '15px',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  },
  actionMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 500,
    marginTop: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#666666',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '15px',
    },
  },

  AccessCardButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    borderRadius: '20px',
    height: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '1rem',
    marginTop: '8px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      height: '20px',
      paddingInline: '10px',
    },
  },
  cardButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#39C38D',
    border: '1px solid #39C38D',
    borderRadius: '5px',
    display: 'flex',
    padding: '10px',
    width: '124px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    fontWeight: 700,
    backgroundColor: '#39C38D',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      height: '20px',
      paddingInline: '10px',
    },
  },
  mainButton: {
    fontWeight: 700,
    backgroundColor: '#39C38D',
  },
  AccessMainButton: {
    fontWeight: 700,
    backgroundColor: '#1F61DC',
    border: '1px solid #1F61DC',
    color: '#FFFFFF',
  },
  cardIcon: {
    minWidth: '70px',
    minHeight: '70px',
    [theme.breakpoints.only('sm')]: {
      minWidth: '55px',
      minHeight: '55px',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '43px',
      minHeight: '43px',
    },
  },
  leftSide: {
    paddingInline: '1.5rem',
    gap: '2rem',
    paddingTop: '10px',
    justifyContent: 'space-between',

    [theme.breakpoints.only('lg')]: {
      marginRight: '2rem',
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '1rem',
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '23px',
      gap: '20px',
    },
    [theme.breakpoints.only('xs')]: {
      paddingInline: '1rem',
      marginRight: '15px',
      gap: '10px',
    },
  },
  moreButton: {
    cursor: 'pointer',
  },
  Middle: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      gap: '0.2rem',
      paddingLeft: '40px',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: '0px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  moreIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
    },
  },
  cancelButton: {
    border: '1px solid #D7E3F1',
    backgroundColor: '#D7E3F1',
    color: '#273142',
    fontWeight: 500,
  },
}));
