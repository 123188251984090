import { gql, useMutation } from '@apollo/client';

export const DELETE_USER = gql`
  mutation removeTag($userId: ID, $isDoctor: Boolean) {
    deleteUser(userId: $userId, isDoctor: $isDoctor)
  }
`;

export default function useDeleteAccount() {
  const [deleteUser, result] = useMutation(DELETE_USER);
  return { deleteUser, ...result };
}
