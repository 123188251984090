import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

const concatName = (
  firstName?: string,
  middleName?: string | null | undefined,
  lastName?: string
): string => {
  if (middleName) {
    return `${capitalizeFirstLetter(firstName) || ''} ${
      capitalizeFirstLetter(middleName) || ''
    } ${capitalizeFirstLetter(lastName) || ''}`;
  }
  return `${capitalizeFirstLetter(firstName) || ''} ${
    capitalizeFirstLetter(lastName) || ''
  }`;
};

export const participantNames = (participant) => {
  if (participant?.length === 1) {
    return concatName(
      participant[0].firstname,
      participant[0].middlename,
      participant[0].lastname
    );
  } else {
    const firstNames = participant.slice(0, 3).map((p) => p.firstname);
    if (participant.length > 3) {
      return firstNames.join(', ') + ', ...'; // Add ', ...' if there are more than 3 participants
    } else {
      return firstNames.join(', ');
    }
  }
};

export default concatName;
