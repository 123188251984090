import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID } from '../activityOperations/useLogActivity';

const EDIT_QUESTIONNAIRE = gql`
  mutation updateQuestionnaire(
    $id: ID!
    $questionnaireInput: QuestionnaireInput
  ) {
    updateQuestionnaire(id: $id, questionnaireInput: $questionnaireInput) {
      _id
    }
  }
`;

const useEditQuestionnaire = () => {
  return useMutation(EDIT_QUESTIONNAIRE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          patientActivityByFilter() {
            return data?.patientActivityByFilter;
          },
        },
      });
    },
    refetchQueries: [GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID],
  });
};

export default useEditQuestionnaire;
