import { useSubscription, gql } from '@apollo/client';
import { log } from '../../utils/log';

const CHANGE_IN_VIDEO_ROOM_SUBSCRIPTION = gql`
  subscription changeInVideoRoom($roomId: String!) {
    changeInVideoRoom(roomId: $roomId)
  }
`;

interface IUseListenVideoRoomArgs {
  roomId: string;
  handleAction: () => void;
}

const useListenVideoRoomChange = ({
  roomId,
  handleAction,
}: IUseListenVideoRoomArgs) => {
  const result = useSubscription(CHANGE_IN_VIDEO_ROOM_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      handleAction();
    },
  });
  log('error', result?.error);
  return result;
};

export default useListenVideoRoomChange;
