import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
});