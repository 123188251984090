import React from 'react';
import useStyles from '../styles';
import { ReactComponent as ArrowRight } from '../../../assets/onboarding/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../../assets/onboarding/x.svg';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import Lottie from 'lottie-react';
import AnimateWelcome from '../animations/welcome.json';

export const CustomTooltipPatient = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
}) => {
  const classes = useStyles();
  const { patient } = useAuth();

  if (index === 0) {
    return (
      <div {...tooltipProps} className={classes.initRoot}>
        <Lottie
          animationData={AnimateWelcome}
          loop={true}
          style={{ width: '89px', height: '89px', margin: '0 auto' }}
        />
        <h4 className={classes.initTitle}>
          Welcome {capitalizeFirstLetter(patient?.firstname)} ⭐
        </h4>
        <p className={classes.initP}>
          Let’s take a quick tour to help you get familiar with our features
        </p>
        <div className={classes.vBtns}>
          <button {...primaryProps} className={classes.startBtn}>
            Start
          </button>
          <button {...closeProps} className={classes.skipBtn}>
            Skip
          </button>
        </div>
      </div>
    );
  }

  if (index === 1) {
    return (
      <div {...tooltipProps} className={classes.initRoot}>
        <Lottie
          animationData={AnimateWelcome}
          loop={true}
          style={{ width: '89px', height: '89px', margin: '0 auto' }}
        />
        <h4 className={classes.initTitle}>
          Welcome back {capitalizeFirstLetter(patient?.firstname)} ⭐
        </h4>
        <p className={classes.initP}>
          Let's complete your tour of Eggmed and get you all set up
        </p>
        <div className={classes.vBtns}>
          <button {...primaryProps} className={classes.startBtn}>
            Continue
          </button>
          <button {...closeProps} className={classes.skipBtn}>
            Skip
          </button>
        </div>
      </div>
    );
  }

  return (
    <div {...tooltipProps} className={classes.root}>
      {step.content}
      <div className={classes.bottom}>
        <p className={classes.steps}>
          {index - 1} of {size - 2}
        </p>
        <div className={classes.btns}>
          {continuous && (
            <button {...primaryProps} className={classes.nextBtn}>
              {index === 7 ? 'Finish' : 'Next'}
              {index !== 7 && <ArrowRight />}
            </button>
          )}
          <button {...closeProps} className={classes.closeBtn}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
