import { gql, useQuery } from '@apollo/client';
import { getDoctors } from './__generated__/getDoctors';

export default function useGetDoctors() {
  const result = useQuery<getDoctors>(
    gql`
      query getDoctors {
        doctors {
          _id
          firstname
          middlename
          email
          lastname
          picture
        }
      }
    `
  );
  return result;
}
