import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as DefaultIcon } from 'assets/default.svg';
import { IDoctorTemplates } from './FormModal';
import ScrollBar from 'components/ui/ScrollBar';
import Menu from 'components/ui/Menu';
import { defaultFroms } from '../commun/constants';
import FormCard, { CardType } from './FormCard';
import { ReactComponent as CheckIcon } from 'assets/patientActivities/check.svg';
import useMakeDefaultTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useMakeDefaultTemplate';
import { DOCTOR_TEMPLATES } from './Questionaires';
import FormPage from 'pages/FormsPage';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as FormIcon } from 'assets/newForm.svg';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyForm } from 'assets/emptyState/form.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'assets/TrashIcon';
import { ReactComponent as PreviewIcon } from 'assets/patientActivities/view.svg';
import { ReactComponent as EditIcon } from 'assets/patientActivities/edit.svg';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copyTemp.svg';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';

interface IQuestionaireModalProps {
  handleNext: () => void;
  title: string;
  patientId?: string;
  assignText?: string;
  doctorTemplates?: IDoctorTemplates[];
  openAssignModal: (form: IDoctorTemplates) => void;
  openDeleteModal: (form: IDoctorTemplates) => void;
  isNotes?: boolean;
  setNotes?: (notes: boolean) => void;
  appointmentId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  loading?: boolean;
}

function QestionaireModal({
  handleNext,
  patientId,
  assignText,
  doctorTemplates,
  openAssignModal,
  openDeleteModal,
  isNotes = false,
  appointmentId,
  open,
  setOpen,
  loading,
}: IQuestionaireModalProps) {
  const { t } = useTranslation();
  const classes = useStyles({ selected: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [formToAssign, setFormToAssign] =
    React.useState<IDoctorTemplates>(null);
  const [menuType, setMenuType] = React.useState<CardType>(null);
  const [editMode, setEditMode] = React.useState(false);
  const [handleMakeDefault] = useMakeDefaultTemplate();
  const [handleCreateDoctorTemplate] = useCreateDoctorTemplate();

  const handleDefault = () => {
    handleMakeDefault({
      variables: { id: formToAssign._id },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setAnchorEl(null);
  };
  const handleCopy = async (form: IDoctorTemplates) => {
    await handleCreateDoctorTemplate({
      variables: {
        doctorTemplateInput: {
          title: form.title,
          description: form.description,
          questions: form.questions,
          lastOpenedAt: new Date(),
          type: isNotes ? 'note' : 'form',
        },
      },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setAnchorEl(null);
  };

  const notesMenuElements = [
    {
      name: 'Edit',
      onClick: () => {
        handleEditForm(formToAssign, false);
        setAnchorEl(null);
        setEditMode(true);
      },
      avatar: <EditIcon />,
    },
    {
      name: 'Preview',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Make default',
      onClick: handleDefault,
      avatar: <DefaultIcon />,
      accessoryRight: formToAssign?.isDefault && <CheckIcon />,
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDeleteForm(formToAssign);
        setAnchorEl(null);
      },
      avatar: <TrashIcon fill="#B42318" width="18px" height="20px" />,
      isRed: true,
    },
  ];
  const { setBlocks, handleChangeText, handleSelectedTemplate } =
    useEditorContext();
  const menuElements = [
    {
      name: 'Edit',
      onClick: () => {
        setBlocks(formToAssign?.blocks, true, formToAssign?._id);
        handleNext();
        handleChangeText(formToAssign.title);
        handleSelectedTemplate(formToAssign.formType);
        // handleEditForm(formToAssign, false);
        setAnchorEl(null);
        setEditMode(true);
      },
      avatar: <EditIcon />,
    },
    {
      name: 'View',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
        setEditMode(false);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Delete',
      onClick: () => {
        handleDeleteForm(formToAssign);
        setAnchorEl(null);
      },
      avatar: <TrashIcon fill="#B42318" width="18px" height="20px" />,
      isRed: true,
    },
  ];
  const defaultMenuElements = [
    {
      name: 'View',
      onClick: () => {
        handleEditForm(formToAssign, true);
        setAnchorEl(null);
        setEditMode(false);
      },
      avatar: <PreviewIcon />,
    },
    {
      name: 'Copy',
      onClick: () => {
        handleCopy(formToAssign);
        setAnchorEl(null);
      },
      avatar: <CopyIcon />,
    },
  ];
  const menuTypes = {
    [CardType.default]: () => defaultMenuElements,
    [CardType.personal]: () => menuElements,
    [CardType.notes]: () => notesMenuElements,
  };
  const handleOpenAssignModal = (
    target: HTMLElement,
    form: IDoctorTemplates
  ) => {
    setFormToAssign(form);
    setAnchorEl(target);
  };
  const handleAssignForm = async (form: IDoctorTemplates) => {
    openAssignModal(form);
  };
  const [previewMode, setPreviewMode] = React.useState<Boolean>(false);
  const handleEditForm = async (form: IDoctorTemplates, preview: Boolean) => {
    await setPreviewMode(preview);
    await setFormToAssign(form);
    setOpen(true);
  };
  const handleDeleteForm = async (form: IDoctorTemplates) => {
    openDeleteModal(form);
  };

  return (
    <>
      <Box className={classes.formModal}>
        <Box
          boxSizing="border-box"
          width="100%"
          px="2rem"
          py="1rem"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            className={classes.button}
            onClick={() => {
              setBlocks(null, false,null);
              handleNext();
            }}
          >
            <FormIcon style={{ marginRight: '5px' }} />
            {isNotes ? t('Create template') : t('Create a new form')}
          </Button>
        </Box>
        <Box
          width="100%"
          height="calc(100% - 78px)"
          className={classes.ScrollBar}
        >
          <Box className={classes.myFormSection}>
            <Box width="100%" mb="10px">
              <Text i18nKey="myForms" className={classes.sectionTitle}>
                My forms
              </Text>
            </Box>
            <ScrollBar height="100%" maxHeight="30vh">
              <Box
                width="100%"
                display="flex"
                flexWrap="wrap"
                boxSizing="border-box"
                style={{ gap: '8px' }}
                justifyContent="space-between"
                alignItems="start"
                className={classes.myFormsBox}
              >
                {!loading && doctorTemplates && doctorTemplates.length === 0 && (
                  <Box
                    py="5rem"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <EmptyState
                      icon={<EmptyForm />}
                      title="No forms yet"
                      text={`to add a new form`}
                      onClick={handleNext}
                      isText
                    />
                  </Box>
                )}
                {doctorTemplates &&
                  doctorTemplates.map((form) => {
                    return (
                      <FormCard
                        form={form}
                        handleAssignForm={handleAssignForm}
                        handleOpenAssignModal={handleOpenAssignModal}
                        cardType={isNotes ? CardType.notes : CardType.personal}
                        setMenuType={setMenuType}
                      />
                    );
                  })}
              </Box>
            </ScrollBar>
          </Box>
          {/* <Box
            style={{ borderTop: '1px solid #D0D5DD' }}
            width="100%"
            my="2rem"
          /> */}
          {/* {!isNotes && (
            <Box className={classes.myFormSection}>
              <Box width="100%" mb="10px">
                <Text i18nKey="formTemplates" className={classes.sectionTitle}>
                  Form templates
                </Text>
              </Box>
              <ScrollBar height="100%" maxHeight="30vh">
                <Box
                  width="100%"
                  display="flex"
                  flexWrap="wrap"
                  boxSizing="border-box"
                  style={{ gap: '8px' }}
                  justifyContent="space-between"
                  alignItems="start"
                  className={classes.myFormsBox}
                >
                  {defaultFroms.map((form) => {
                    return (
                      <FormCard
                        form={form}
                        handleAssignForm={handleAssignForm}
                        handleOpenAssignModal={handleOpenAssignModal}
                        cardType={CardType.default}
                        setMenuType={setMenuType}
                      />
                    );
                  })}
                </Box>
              </ScrollBar>
            </Box>
          )} */}

          <Menu<any>
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorEl}
            handleMenuClose={() => setAnchorEl(null)}
            MenuElements={menuTypes[menuType || CardType.default]()}
            className={classes.menu}
          />
        </Box>
      </Box>
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        isRegularModal
        isSlide
        divider
        title={formToAssign?.title}
      >
        <FormPage
          isNotes={isNotes}
          forms={{
            formName: formToAssign?.title,
            formDescription: formToAssign?.description,
            formQuestions: formToAssign?.questions,
            formId: formToAssign?._id && formToAssign?._id,
            doctorId: formToAssign?.doctor?._id,
            patientId,
            assignText,
            appointmentId,
            previewMode: previewMode,
            source: formToAssign?.source,
            blocks: formToAssign?.blocks,
          }}
          editMode={editMode}
        />
      </BasicModal>
    </>
  );
}

export default QestionaireModal;
