import React from 'react';
import { useProvider } from '../../state/Provider';

import useStyles from './style';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyInbox } from 'assets/emptyState/inbox.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  isEmpty?: boolean;
}

const EmptyMessageIllustration = ({ isEmpty = false }: IProps) => {
  const { t } = useTranslation();
  const { patient } = useAuth();
  const isPatient = !!patient;
  const classes = useStyles({ patient: isPatient });
  const { handleOpenCreateNewThreadModal } = useProvider();
  const handleOpenNewThreadModal = () => {
    handleOpenCreateNewThreadModal();
  };
  return (
    // <EmptyState
    //   icon={<EmptyInbox />}
    //   title="No messages"
    //   text="to compose a thread"
    //   onClick={handleOpenNewThreadModal}
    //   isText
    // />
    <EmptyState
      icon={<EmptyInbox />}
      title={
        isEmpty ? t('No messages') : t('Select a conversation to get started')
      }
      text={isEmpty && t('to compose a thread')}
      onClick={handleOpenNewThreadModal}
      isText={true}
      noActionText={isEmpty ? false : true}
      hasAction={isEmpty ? true : false}
    />
  );
};

export default EmptyMessageIllustration;
