import React from 'react';

import ControllerInput, { FormInput } from './FormInput';
import AreaInput from './AreaInput';
import CountryInput from './CountryInput';
import PhoneNumberInput from './PhoneInput';
import SelectInput from './SelectInput';
import SelectAutoCompleteInput from './SelectAutoCompleteInput';
import CheckboxInput from './CheckboxInput';
import DraftjsInput from './DraftjsInput';
import ReactDatePicker from './ReactDatePicker';
import CustomDate from './CustomDate';
import AutoCompleteAdd from './AutoCompleteAdd';
import AutoCompleteAddNew from './AutoCompleteAddnew';
import UploadFilesInput from './UploadFilesInput';
import CustomDateRange from './CustomDateRange';

const Inputs = ({ type, ...props }: any): JSX.Element => {
  switch (type) {
    case 'text':
      return <FormInput {...props} />;
    case 'textcontrolled':
      return <ControllerInput {...props} />;
    case 'password':
      return <FormInput type="password" {...props} />;
    case 'number':
      return <FormInput type="number" {...props} />;
    case 'area':
      return <AreaInput {...props} />;
    case 'country':
      return <CountryInput {...props} />;
    case 'phone':
      return <PhoneNumberInput {...props} />;
    case 'date':
      return <CustomDate {...props} />;
    case 'dateRange':
      return <CustomDateRange {...props} />;
    case 'select':
      return <SelectInput {...props} />;
    case 'autocomplete':
      return <SelectAutoCompleteInput {...props} />;
    case 'checkbox':
      return <CheckboxInput {...props} />;
    case 'draftjs':
      return <DraftjsInput {...props} />;
    case 'customDate':
      return <ReactDatePicker {...props} />;
    case 'autocompletemultiple':
      return <AutoCompleteAdd {...props} />;
    case 'autocompleteadd':
      return <AutoCompleteAddNew {...props} />;
    case 'uploadFiles':
      return <UploadFilesInput {...props} />;
    default:
      return <FormInput {...props} />;
  }
};

export default Inputs;
