import React, { FC, useEffect, useRef, useMemo } from 'react';
import ScrollBar from 'components/ui/ScrollBar';
import { Divider } from '@material-ui/core';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from './style';
import MessageBubble from './MessageBubble ';
import HeadMessageList from './HeadMessageList';
import { GetThreadMessages_getThreadMessages } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import { useProvider } from 'pages/InboxPage/state/Provider';

interface IMessagesListProps {
  data: GetThreadMessages_getThreadMessages[];
  patient?: boolean;
  setFilesHidden: (value: boolean) => void;
}
interface IUserProps {
  _id: string;
  user?: { _id: string };
}
const MessagesList: FC<IMessagesListProps> = ({
  data,
  setFilesHidden,
  patient,
}) => {
  const classes = useStyles({ patient });
  const { user } = useAuth();
  const { _id } = user;

  const messagesEndRef = useRef<any>();
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView();
  };
  function isTimeGapSignificant(currentMessage, previousMessage) {
    const timeDifference =
      currentMessage?.createdAt - previousMessage?.createdAt;
    return timeDifference > 5 * 60 * 1000;
  }
  useEffect(scrollToBottom, [data]);
  const MessagesMap = useMemo(() => {
    return data
      .map((item: GetThreadMessages_getThreadMessages, index: number) => {
        const isSameUser =
          index > 0 && data[index - 1]?.user?._id === item.user?._id;
        const isRecent = isTimeGapSignificant(item, data[index - 1]);
        return (
          <MessageBubble
            isAvatarHidden={isSameUser && !isRecent}
            key={item._id}
            isOwner={
              _id === item.user?._id ||
              (user as IUserProps)?.user?._id === item.user?._id
            }
            data={item}
          />
        );
      })
      .reverse();
  }, [data]);
  return (
    <div className={classes.fullHeight}>
      <HeadMessageList setFilesHidden={setFilesHidden} patient={patient} />
      <Divider />
      <ScrollBar className={classes.messageListScroll}>
        <div ref={messagesEndRef} />
        {data && MessagesMap}
      </ScrollBar>
    </div>
  );
};

export default MessagesList;
