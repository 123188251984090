import React, { useEffect, useState } from 'react';
import DoctorProfile from 'pages/DoctorProfilePage/DoctorProfile';
import { Box } from '@material-ui/core';

const PatientDoctorProfile = () => {
  return (
    <Box>
      <DoctorProfile isPatient />
    </Box>
  );
};

export default PatientDoctorProfile;
