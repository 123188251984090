import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { Patient_patient } from '@eggmed/graphql-client/operations/patientOperations/__generated__/Patient';
import { Avatar as AvatarMUI } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  localVideo: {
    maxHeight: '100%',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 100px)',
    maxWidth: '100%',
    // objectFit: 'cover',
    transition: 'all 0.3s',
    transform: 'scaleX(-1)',
    position: 'absolute',
    top: 50,
    left: 15,
    objectFit: 'cover',
    borderRadius: '8px',
    // borderRadius: '20px',
  },
  established: {
    margin: '15px',
    maxHeight: '17%',
    maxWidth: '17%',
    height: 'auto',
    width: 'auto',
    zIndex: 1000,
    top: 'unset',
    bottom: '65px',
  },
  chatOpened: {
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    maxWidth: '80%',
    transition: 'all 0.3s',
    position: 'absolute',
  },
  remoteVideo: {
    maxHeight: '100%',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 120px)',
    maxWidth: '100%',
    transition: 'all 0.3s',
    position: 'absolute',
    top: 50,
    left: 15,
    objectFit: 'cover',
    borderRadius: '8px',
  },
  remoteVideoDisabled: {
    display: 'none',
  },
  showVideoContainer: {
    backgroundColor: 'black',
    zIndex: 10000,
    width: '17vw',
    height: '17vh',
  },
  avatarEstablished: {
    width: '80px',
    height: '80px',
  },
  avatar: {
    width: '200px',
    height: '200px',
  },
}));

export interface IVideoComponent {
  localRef: React.Ref<any>;
  remoteRef: React.Ref<any>;
  established: string;
  chatOpened: boolean;
  remoteUserData: Partial<Patient_patient>;
  userData: Partial<Patient_patient>;
  isVideoOff: boolean;
  isWide?: boolean;
  remoteVideoOff?: boolean;
}

export default function VideoComponent({
  localRef,
  remoteRef,
  established,
  chatOpened,
  remoteUserData,
  userData,
  isVideoOff,
  isWide = true,
  remoteVideoOff = false,
}: IVideoComponent): JSX.Element {
  const classes = useStyles({ isWide });
  const isEstablished = established === 'established';
  const isChatOpen = established !== 'established' && chatOpened;
  return (
    <div>
      {isVideoOff && (
        <div
          className={clsx(
            isEstablished && classes.established,
            classes.localVideo,
            isChatOpen && classes.chatOpened,
            isVideoOff && classes.remoteVideoDisabled
          )}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AvatarMUI
            src={userData?.picture}
            className={clsx(
              isEstablished ? classes.avatarEstablished : classes.avatar
            )}
            alt={userData?.firstname}
          />
        </div>
      )}

      <video
        className={clsx(
          isEstablished && classes.established,
          classes.localVideo,
          isChatOpen && classes.chatOpened,
          isVideoOff && classes.remoteVideoDisabled
        )}
        id="video1"
        ref={localRef}
        playsInline
        autoPlay
        muted
      />

      {remoteVideoOff && (
        <div
          className={clsx(classes.remoteVideo)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AvatarMUI
            src={remoteUserData?.picture}
            className={clsx(classes.avatar)}
            alt={remoteUserData?.firstname}
          />
        </div>
      )}
      <video
        className={clsx(
          established === 'established' && chatOpened && classes.chatOpened,
          classes.remoteVideo,
          (remoteVideoOff || established === 'initial') &&
            classes.remoteVideoDisabled
        )}
        id="video2"
        data-cy={established}
        ref={remoteRef}
        playsInline
        autoPlay
      />
    </div>
  );
}

VideoComponent.defaultProps = {
  isWide: true,
};
