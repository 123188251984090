import { gql, useMutation } from '@apollo/client';

const GENERATE_ACCOUNT = gql`
  mutation generateAccount($userInput: GenerateInput) {
    generateAccount(userInput: $userInput) {
      email
      password
      patient {
        _id
        firstname
        lastname
        email
      }
    }
  }
`;

export default function useGenerateAccount() {
  const [generateAccount, result] = useMutation(GENERATE_ACCOUNT, {
    refetchQueries: [{ query: PATIENTS_RELATED }],
  });

  return { generateAccount, ...result };
}

export const PATIENTS_RELATED = gql`
  query patientsRelated {
    patientsRelated {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
    }
  }
`;
