import { ApolloError, QueryTuple } from '@apollo/client';
import { CreateThreadConversation } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/CreateThreadConversation';
import {
  GetAllThreadConversationByUser,
  GetAllThreadConversationByUser_getAllThreadConvertationByUser,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import { getFilesByThreadConversation_getFilesByThreadConversation } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getFilesByThreadConversation';
import {
  getThreadConversationById,
  getThreadConversationByIdVariables,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getThreadConversationById';
import {
  GetThreadMessages,
  GetThreadMessages_getThreadMessages,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import { PushMessageVariables } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/PushMessage';
import { typeConversationEnum } from '@eggmed/graphql-client/__generated__/globalTypes';
import { GetAutoCompleteSearchThreadConversation } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAutoCompleteSearchThreadConversation';

export enum IModalType {
  'createThead',
  'deleteThead',
}

export enum ThreadConversationTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  INTERNAL = 'INTERNAL',
}

interface ResponseType<T> {
  data: T;
  loading: boolean;
  error: ApolloError | undefined;
}
export interface IInboxPageContext {
  isEmptyConversationList: boolean;
  typeConversationList: ThreadConversationTypeEnum;
  modal: boolean;
  modalType: IModalType;
  selected: string | undefined;
  archivedConversationListData: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
  unArchivedConversationListData: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
  listFiLes: getFilesByThreadConversation_getFilesByThreadConversation[];
  messagesData: GetThreadMessages_getThreadMessages[];
  searchThreadData: ResponseType<GetAutoCompleteSearchThreadConversation>;
  messageHidden: boolean;
  setMessageHidden: (hiddenMessage: boolean) => void;
  handleCloseCreateNewThreadModal: () => void;
  handleOpenCreateNewThreadModal: () => void;
  handelPaginationConversationList: (isArchived: boolean) => void;
  handleSelectedThead: (threadId: string) => void;
  useGetAllThreadConversationByUser: (
    isArchived: boolean
  ) => ResponseType<GetAllThreadConversationByUser>;
  useLazyGetThreadConversationById: () => QueryTuple<
    getThreadConversationById,
    getThreadConversationByIdVariables
  >;
  useGetThreadMessages: (threadId: string) => ResponseType<GetThreadMessages>;
  handlePushMessage: (
    threadId: string,
    message: any
  ) => ResponseType<PushMessageVariables>;
  useGetThreadConversationById: (
    threadId: string
  ) => ResponseType<getThreadConversationById>;
  handelIsEmptyConversationList: (isEmptyConversationList: boolean) => void;
  handelDeleteThreadConversation: (
    threadConversationID: string
  ) => ResponseType<any>;
  handelUpdateIsArchivedThreadConversation: (
    threadId: string,
    isArchived: boolean
  ) => ResponseType<any>;
  handelCreateThreadConversation: (
    form: any
  ) => ResponseType<CreateThreadConversation>;
  handelSelectTypeConversationList: (type: typeConversationEnum | null) => void;
  handelSearchThread: (search: string) => void;
  loading?: boolean;
  sendMessageLoading?: boolean;
}
