import { gql, useMutation } from '@apollo/client';

const DELETE_CONSULTATION = gql`
  mutation deleteConsultation($consultationId: ID!) {
    deleteConsultation(consultationId: $consultationId) {
      doctor
    }
  }
`;

export default function useDeleteConsultation() {
  const [deleteConsultation, result] = useMutation(DELETE_CONSULTATION, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentConsultation() {
            return data?.deleteConsultation;
          },
        },
      });
    },
  });
  return { deleteConsultation, ...result };
}
