import { gql, useQuery } from '@apollo/client';

const GET_STAFF_BY_USER_ID = gql`
  query getStaffByUserId($userId: ID!) {
    getStaff(userId: $userId) {
      _id
      user {
        _id
        email
        firstname
        lastname
        middlename
        role
        picture
        phoneNumber
        birthday
        address
        description
        streetadress1
        streetadress2
        city
        country
        zipcode
        gender
        createdAt
      }
      organization
      practice
      staffOf {
        _id
        noUsedFeatures
        firstname
        middlename
        username
        lastname
        email
        picture
      }
    }
  }
`;

const useGetStaffByUserId = (userId: string) => {
  const { data, loading, error } = useQuery(GET_STAFF_BY_USER_ID, {
    variables: {
      userId,
    },
  });
  return { data, loading, error };
};

export { GET_STAFF_BY_USER_ID };
export default useGetStaffByUserId;
