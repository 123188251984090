import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilledTextFieldProps } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';

import country from 'utils/countries';
import { FormInput } from './FormInput';

interface ICountryInput extends FilledTextFieldProps {
  control: Control<any>;
  defaultValue: any;
  name: string;
  isStripeSupportedCountry?: boolean;
}

export default function CountryInput({
  control,
  defaultValue,
  name,
  isStripeSupportedCountry = false,
  ...props
}: ICountryInput) {
  const targetCountries = [
    'United States',
    'France',
    'Canada',
    'Germany',
    'United Kingdom',
  ];

  const stripeSupportedCountries = country?.filter((country) =>
    targetCountries.includes(country.label)
  );
  return (
    <Controller
      control={control}
      name={name}
      defaultValues={defaultValue}
      render={({ onChange, onBlur }) => {
        return (
          <Autocomplete
            getOptionLabel={(option) => option.label}
            id="county-autocomplete"
            options={
              isStripeSupportedCountry ? stripeSupportedCountries : country
            }
            getOptionSelected={(option, value) =>
              option?.label?.toLowerCase() ===
              value?.label?.label?.toLowerCase()
            }
            defaultValue={defaultValue}
            renderOption={(option) => (
              <>
                <ReactCountryFlag
                  countryCode={option.code}
                  svg
                  style={{ paddingRight: '10px' }}
                />
                {option.label} ({option.code})
              </>
            )}
            renderInput={(params) => (
              <FormInput {...props} placeholder="" name={name} {...params} />
            )}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
}
