import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateFinances from '../animations/finance.json';

export const Finance = () => {
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateFinances}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Finances</h3>
        <p className={classes.description}>
          Monitor your finances and review your clients’ payment history and
          upcoming payments.
        </p>
      </div>
    </div>
  );
};
