import React from 'react';
import useStyles from '../style';
import { Box, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import { useTranslation } from 'react-i18next';
import useEditUser from 'graphql/operations/userOperations/useUpdateUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER } from 'pages/AdminPage';
import { InfoBlock } from '../TabsContent/InfoBlock';
import { BlockNames } from '../TabsContent/PersonalInformation';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useSnackbar } from 'hooks/useSnackbar';

const Language = ({ isPatient = false }: { isPatient?: boolean }) => {
  const { updateUser } = useEditUser();
  const { triggerSnack } = useSnackbar();
  const { user } = useAuth();
  const leng = {
    English: 'en',
    French: 'fr',
  };
  const langMap = {
    English: 'English',
    Français: 'French',
    French: 'Français',
  };
  const { i18n } = useTranslation();
  const { data, loading } = useQuery(GET_CURRENT_USER, {
    variables: { userId: user._id || user?.user?._id },
  });
  const { register, control, errors, reset, watch, setValue, handleSubmit } =
    useForm({
      defaultValues: {
        language: langMap[data?.user?.language],
      },
    });
  React.useEffect(() => {
    if (data?.user?.language) {
      reset({
        language: langMap[data?.user?.language],
      });
    }
  }, [reset, data?.user?.language]);
  const classes = useStyles();

  const commonProps = { register, control, errors };
  const selectedLanguage = watch()?.language;

  const { t } = useTranslation();
  const [editableBlock, setEditableBlock] = React.useState(null);
  async function onSubmit(data) {
    await updateUser({
      variables: {
        userId: user._id || user?.user?._id,
        updateUser: { language: langMap[data?.language] },
      },
    });
    i18n.changeLanguage(leng[langMap[selectedLanguage]] || 'en');
    setEditableBlock(null);
    triggerSnack();
  }
  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid
        item
        xs={12}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: isPatient && '6rem' }}
      >
        <Skeleton loading={loading}>
          <Text
            i18nKey="Language"
            style={{
              fontFamily: 'Inter, sans-serif',
              fontStyle: 'normal',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#344054',
            }}
          >
            Regional settings
          </Text>
        </Skeleton>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        xl={4}
        md={12}
        sm={12}
        style={{ marginLeft: '2rem' }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoBlock
            title={t('Language')}
            content={t(data?.user?.language)}
            blockName={BlockNames.language}
            isEditable={editableBlock === BlockNames.language}
            editableBlock={editableBlock}
            setEditableBlock={setEditableBlock}
            loading={false}
          >
            <Input
              className={classes.inputMui2}
              type="select"
              name="language"
              variant="filled"
              options={['English', 'Français']}
              {...commonProps}
            />
          </InfoBlock>
        </form>
      </Grid>{' '}
    </Grid>
  );
};

export default Language;
