import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: 'white',
    marginLeft: theme.spacing(1),
  },
  customArrow: {
    color: 'white',
  },
}));
const Tooltpip = ({ children, title, placement, isLarge = false }) => {
  const classes = useStyles();
  if (isLarge)
    return (
      <Tooltip
        title={title}
        placement={placement}
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        arrow
      >
        {children}
      </Tooltip>
    );
  return <>{children}</>;
};

export default Tooltpip;
