import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';

interface IFormCheckbox {
  register: any;
  control: any;
  question: string;
  options: string[];
  name: string;
  handleCheckboxChange: any;
  questionIndex: number;
}

export const FormCheckbox = ({
  register,
  control,
  question,
  options,
  name,
  handleCheckboxChange,
  questionIndex,
}: IFormCheckbox) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <FormGroup>
        {options.map((el) => (
          <Controller
            name={el}
            control={control}
            register={register}
            defaultValue={false}
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    onChange={(e) => {
                      onChange(e.target.checked);
                      handleCheckboxChange(questionIndex, el);
                    }}
                    onBlur={onBlur}
                    checked={value}
                    inputRef={ref}
                    name={name}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    disableRipple
                  />
                }
                label={el}
              />
            )}
          />
        ))}
      </FormGroup>
    </Box>
  );
};
