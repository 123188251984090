import BasicModal from 'components/ui/Modal';
import React from 'react';
import { useProvider } from '../state/Provider';
import CreateNewThreadModals from './CreateNewThreadModals';
import { IContactProps } from './CreateNewThreadModals';

const Modals = ({
  isPatient,
  ...props
}: {
  isPatient?: boolean;
  contact?: IContactProps[];
  subject?: string;
  message?: string;
}) => {
  const { modal, modalType, handleCloseCreateNewThreadModal } = useProvider();

  return <CreateNewThreadModals isPatient={isPatient} {...props} />;
};

export default Modals;
