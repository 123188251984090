import React, { useState } from 'react';
import { Box, Grid, Typography, Checkbox } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as DescriptionIcon } from 'assets/icons/descriptionIcon.svg';
import clsx from 'clsx';
import InputBlock from '../EventForm/InputBlock';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from '../EventForm/styles';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';
import DateRange from 'components/ui/Inputs/DateRange';
import TimeInput from 'components/ui/Inputs/TimeInput';
import FrequencyComponent from '../EventForm/FrequencyComponent';
import { getDayName, combineDates } from 'utils';
import Accordion from 'components/ui/Accordion';
import { ReactComponent as FreqIcon } from 'assets/patientActivities/freq.svg';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import Switch from 'components/ui/Switch';
import BasicModal from 'components/ui/Modal';
import { ConflictModal } from '../Conflicts/ConflictModal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { convertToRawAndStringify } from '@eggmed/common/utils/DraftUtils';
import { gql, useQuery } from '@apollo/client';
import { Availability } from './Availability';
import { Notes } from '../EventForm/Notes';
import { DateAndTime } from './DateAndTime';
import { ReactComponent as FrequencyIcon } from 'assets/Schedule/new/freq.svg';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const EventTab = ({ errorMsg }: { errorMsg?: string }) => {
  const { t } = useTranslation();
  const { register, control, errors, watch, reset, getValues, setValue } =
    useFormContext();
  const {
    frequencyExpand,
    handleFrequencyAccordionChange,
    eventRange,
    start,
    end,
    handleChangeStart,
    handleChangeEnd,
    handleChangeEndDate,
    handleChangeStartDate,
    currentEvent,
  } = useProvider();
  const commonProps = { register, errors, control, watch };
  const classes = useStyles();
  const [openConflict, setOpenConflict] = React.useState<boolean>(false);

  const onClose = () => {
    setOpenConflict(false);
  };

  const { doctor } = useAuth();
  const {
    title,
    descriptionEvent,
    repeatOn,
    endOns,
    repeatEvery,
    numberOfRepeat,
    isImpact,
  } = getValues() || {};
  const [isImpactChecked, setIsImpactChecked] = useState<boolean>(
    currentEvent?.isImpact ?? true
  );
  const eventInput = {
    title: title === '' ? '(No title)' : title,
    description: descriptionEvent && convertToRawAndStringify(descriptionEvent),
    repeatOn: frequencyExpand ? repeatOn : undefined,
    endOn: frequencyExpand ? new Date(endOns) : undefined,
    repeatEvery: frequencyExpand ? repeatEvery : undefined,
    numberOfRepeat: frequencyExpand ? Number(numberOfRepeat) : undefined,
    startDate: combineDates(eventRange?.startDate, start),
    endDate: combineDates(eventRange?.endDate, end),
    isImpact,
    doctor: doctor?._id,
  };
  const { data } = useQuery(OVERLAP_EVENTS, {
    variables: {
      eventInput,
    },
  });
  React.useEffect(() => {
    if (eventRange?.endDate && !currentEvent?.endOn) {
      setValue('endOns', new Date(eventRange?.endDate));
    } else {
      setValue('endOns', new Date(currentEvent?.endOn));
    }
  }, [eventRange?.endDate, currentEvent?.endOn]);

  return (
    <>
      <Box>
        <Availability
          register={register}
          isImpactChecked={isImpactChecked}
          setIsImpactChecked={setIsImpactChecked}
        />

        <Box>
          <Text i18nKey="eventTitle" className={classes.label}>
            Event title
          </Text>
          <InputBlock
            Icon={null}
            type="text"
            name="title"
            data-cy="add-appointment-title"
            contentClassName={classes.eventTitle}
            {...commonProps}
          />
        </Box>

        <DateAndTime
          eventRange={eventRange}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeStart={handleChangeStart}
          handleChangeEnd={handleChangeEnd}
          start={start}
          end={end}
        />

        <Box className={classes.frequencyContainer} mb={2}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" sx={{ gridGap: '1rem' }}>
              <FrequencyIcon />
              <Text i18nKey="frequency" className={classes.freqTitle}>
                Frequency
              </Text>
            </Box>
            <Checkbox
              name="frequencyExpanded"
              size="medium"
              defaultChecked={frequencyExpand}
              onChange={handleFrequencyAccordionChange}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
              style={{ backgroundColor: 'transparent' }}
            />
          </Box>
          <Text
            i18nKey="Set up recurring events"
            className={classes.freqDesc}
            style={{
              height: !frequencyExpand ? 'unset' : 0,
              visibility: !frequencyExpand ? 'visible' : 'hidden',
              overflow: !frequencyExpand ? 'unset' : 'hidden',
              transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            Set up recurring events
          </Text>

          <Box
            pr="9px"
            pl="52px"
            py={frequencyExpand && 3}
            style={{
              height: frequencyExpand ? 'unset' : 0,
              visibility: frequencyExpand ? 'visible' : 'hidden',
              overflow: frequencyExpand ? 'unset' : 'hidden',
              transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            <FrequencyComponent
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              defaultValue={getDayName(eventRange?.startDate)}
              setOpenConflict={setOpenConflict}
              data={data}
              dateRange={eventRange}
              name={'endOns'}
              isEvent={true}
            />
          </Box>
        </Box>

        <Notes name="descriptionEvent" {...commonProps} />

        {errorMsg && (
          <Box display="flex" alignItems="center" mt={2}>
            <ErrorIcon style={{ color: 'red', marginRight: '10px' }} />
            <Typography style={{ color: 'red' }}>{errorMsg}</Typography>
          </Box>
        )}
      </Box>
      <BasicModal
        open={openConflict}
        onClose={onClose}
        handleClose={onClose}
        isRegularModal
        isSlide
        className={classes.conflict}
        title={t('Manage conflicts')}
        divider
        titlePadding="4.5rem"
      >
        <ConflictModal onClose={onClose} data={data?.overlapEvents} />
      </BasicModal>
    </>
  );
};

export default EventTab;
export const OVERLAP_EVENTS = gql`
  query overlapEvents($eventInput: addEventInput) {
    overlapEvents(eventInput: $eventInput) {
      description
      doctor {
        _id
        firstname
        lastname
        email
        picture
      }
      title
      startDate
      endDate
      isImpact
      doctorGoing
    }
  }
`;
