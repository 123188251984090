import { gql, useQuery } from '@apollo/client';

interface IUseGetDoctorArgs {
  id: string;
}

const GET_DOCTOR_SUBSCRIPTION = gql`
  query fetchDoctorSubscriptions {
    subscription {
      subscription
      endDate
      type
      cost
      status
      cancelAtPeriodEnd
    }
  }
`;

export default function useGetSubscription() {
  const { data, error, loading, refetch } = useQuery(GET_DOCTOR_SUBSCRIPTION);
  return { data, error, loading, refetch };
}

export { GET_DOCTOR_SUBSCRIPTION };
