import React from 'react';
import { Box } from '@material-ui/core';
import EditButtons from 'components/EditButtons';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';

interface IStepsProps {
  step: number;
  handleSubmit: () => void;
  handleClose: () => void;
  steps: React.ReactElement<any, any>[];
  submitText?: string;
  buttonVisible?: number[];
  divider?: boolean;
  cancelText?: string;
  handleNext?: () => void;
  displayPreview?: boolean;
  fullHeight?: boolean;
  loading?: boolean;
}

enum StepsName {
  Preview = 'Preview',
  Questionnaire = 'QestionaireModal',
  FormType = 'FormType',
}

function Steps({
  step,
  handleSubmit,
  handleClose,
  steps,
  submitText = 'Submit',
  buttonVisible = [],
  displayPreview = false,
  divider = true,
  cancelText = 'Cancel',
  fullHeight = false,
  handleNext,
  loading,
}: IStepsProps) {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  return (
    <div>
      {steps?.map((el, index) => {
        const name = el?.type.displayName || el?.type.name || 'Component';
        const condition =
          name !== StepsName.Preview &&
          name !== StepsName.Questionnaire &&
          name !== StepsName.FormType;

        return (
          <Box
            className={
              condition && !fullHeight ? classes.boxHeight : classes.fullHeight
            }
            display={step === index ? 'block' : 'none'}
          >
            {el}
          </Box>
        );
      })}
      <Box
        className={classes.buttonsControlled}
        display={!buttonVisible.includes(step) ? 'none' : 'flex'}
        alignItems="center"
        width="100%"
        borderTop={divider && '0.5px solid #EAECF0'}
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <Box className={classes.container} px="2rem">
          <Button
            className={classes.cancel}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            buttonType="reset"
          >
            {cancelText}
          </Button>
          {displayPreview && (
            <Button
              className={classes.cancel}
              onClick={(e) => {
                e.preventDefault();
                handleNext();
              }}
              buttonType="button"
            >
              {t('Preview')}
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.assignButton}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            buttonType="submit"
          >
            {loading ? <ProgressLoader width={20} height={20} /> : submitText}
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default Steps;
