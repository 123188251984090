import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useModal from 'hooks/useModal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import CardWithBottomBlueBorder from 'components/ui/Card/CardWithBottomBlueBorder';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import DropdownMenu from 'pages/PatientListPage/DropdownMenu';
import AccessListHeader from './AccessListHeader';
import useGetPatientAccess from '@eggmed/graphql-client/operations/patientAccessControlOperations/useGetPatientAccess';
import { ReactComponent as NoPatientAccessIcon } from 'assets/illustrations/noPatientAccess.svg';
import Switch from 'components/ui/Switch';
import useGiveAllAccessToDoctor from '@eggmed/graphql-client/operations/patientAccessControlOperations/useGiveDoctorAllAccess';
import useRemoveAllAccessToDoctor from '@eggmed/graphql-client/operations/patientAccessControlOperations/useRemoveAllAccessFromDoctor';

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  approved: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1F61DC',
  },
  denied: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
  },
}));
interface IAccessPermissionData {
  _id: string;
  firstname: string;
  lastname: string;
  middlename: string;
  picture: string;
  permissions: string[];
  hasAccess: boolean;
}
const PatientAccessPage = ({ limit }: { limit?: number }): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useModal();
  const [dropDownEL, setDropDownEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<IAccessPermissionData>();
  const [page, setPage] = useState<number>(1);
  const { data, loading } = useGetPatientAccess();
  const { handleRemoveAllAccessToDoctor } = useRemoveAllAccessToDoctor();
  const { handleGiveAllAccessToDoctor } = useGiveAllAccessToDoctor();
  const doctors = data?.patientAccess?.access.map(
    ({ doctor, permissions, hasAccess }) => {
      const { _id, firstname, lastname, middlename, picture } = doctor || {};
      return {
        _id,
        firstname,
        lastname,
        middlename,
        picture,
        permissions,
        hasAccess,
      };
    }
  );

  const handleUpdateAccess = (doctor: String, hasAccess: boolean) => {
    hasAccess
      ? handleRemoveAllAccessToDoctor(doctor)
      : handleGiveAllAccessToDoctor(doctor);
  };

  const columns: IColumn<IAccessPermissionData>[] = [
    {
      id: 'firstname',
      label: <Box fontSize="1rem">Doctor Name</Box>,
      align: 'left',
      minWidth: 170,
      format: (value, { firstname, middlename, lastname, picture, _id }) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => visitDoctorProfile(_id)}
          >
            <Avatar alt="Patient picture" src={picture}>
              {`${firstname && firstname[0].toUpperCase()}${
                lastname && lastname[0].toUpperCase()
              }`}
            </Avatar>
            <Box pl={2}>
              {`${firstname} ${middlename || ''} ${lastname}`}
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">General info</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">Immunization</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">Allergies</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">Labs</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">Drugs</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">Conditions</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: <Box fontSize="1rem">History</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { hasAccess }) => {
        return hasAccess ? (
          <Typography className={classes.approved}>Approved</Typography>
        ) : (
          <Typography className={classes.denied}>Denied</Typography>
        );
      },
    },
    {
      id: 'permissions',
      label: null,
      align: 'left',
      minWidth: 100,
      disableSorting: true,
      format: (value, { _id, hasAccess }) => {
        return (
          <Switch
            defaultChecked={hasAccess}
            checked={hasAccess}
            onClick={() => handleUpdateAccess(_id, hasAccess)}
          />
        );
      },
    },
  ];

  const handleCloseDropDownMenu = () => {
    setDropDownEl(null);
  };
  const goToDoctorAccess = () => {
    history.push(`/patient/access_control/${currentRow?._id}`);
    setDropDownEl(null);
  };

  function handlePagination(e: unknown, pageNumber: number) {
    setPage(pageNumber);
  }
  function handleModalOpen() {
    dispatch({ type: 'open' });
  }
  const visitDoctorProfile = (id) => {
    history.push(`/patient/doctor/${id}`);
  };
  return (
    <>
      {data?.patientAccess?.access.length > 0 ? (
        <CardWithBottomBlueBorder>
          <Grid>
            <Table<IAccessPermissionData>
              loading={loading}
              page={page}
              columns={columns}
              limit={limit}
              handlePagination={handlePagination}
              data={doctors || []}
              pagination
              sorting
              EmptyState={<h1> no data</h1>}
            />
          </Grid>
          <DropdownMenu
            goToPatientDetails={goToDoctorAccess}
            anchorEl={dropDownEL}
            handleClose={handleCloseDropDownMenu}
            handleDeletePatient={handleModalOpen}
          />
        </CardWithBottomBlueBorder>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingInline: '1rem' }}
        >
          <NoPatientAccessIcon style={{ width: '400px', height: '400px' }} />
        </Grid>
      )}
    </>
  );
};

export default PatientAccessPage;
