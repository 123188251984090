import React, { createContext, useContext } from 'react';

export const Context = createContext(undefined);

const NotificationProvider = ({ children }) => {
  const count = 10;
  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(count);
  const [page, setPage] = React.useState(1);

  return (
    <Context.Provider
      value={{ start, setStart, limit, setLimit, page, setPage, count }}
    >
      {children}
    </Context.Provider>
  );
};
function useProvider() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Notification useProvider must be used within a Provider');
  }
  return context;
}
export { useProvider };
export default NotificationProvider;
