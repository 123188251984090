import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_SUBSCRIPTION } from './useGetSubscription';

export const CHANGE_PLAN = gql`
  mutation cancelSubscription(
    $subscriptionId: String
    $interval: String
    $priceId: String
  ) {
    changeSubscriptionPlan(
      subscriptionId: $subscriptionId
      interval: $interval
      priceId: $priceId
    ) {
      endDate
      type
      cost
    }
  }
`;

export default function useChangePlan() {
  const [changeSubscriptionPlan, { loading, error }] = useMutation(
    CHANGE_PLAN,
    {
      refetchQueries: [
        {
          query: GET_DOCTOR_SUBSCRIPTION,
        },
      ],
    }
  );

  return { changeSubscriptionPlan, loading, error };
}
