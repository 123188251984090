import { gql, useMutation } from '@apollo/client';
import {
  UpdateIsArchivedThreadConversation,
  UpdateIsArchivedThreadConversationVariables,
} from './__generated__/UpdateIsArchivedThreadConversation';

const UPDATES_IS_ARCHIVED_THREAD_CONVERSATION_MUTATION = gql`
  mutation UpdateIsArchivedThreadConversation(
    $threadConversationID: ID!
    $isArchived: Boolean!
  ) {
    updateIsArchivedThreadConvertation(
      threadConvertationID: $threadConversationID
      isArchived: $isArchived
    )
  }
`;
const useUpdateIsArchivedThreadConversation = () => {
  const [updateIsArchivedThreadConversation] = useMutation<
    UpdateIsArchivedThreadConversation,
    UpdateIsArchivedThreadConversationVariables
  >(UPDATES_IS_ARCHIVED_THREAD_CONVERSATION_MUTATION);
  async function handleUpdateIsArchivedThreadConversation(
    threadConversationID: string,
    isArchived: boolean
  ) {
    try {
      return await updateIsArchivedThreadConversation({
        variables: { threadConversationID, isArchived },
      });
    } catch (e) {
      return e;
    }
  }
  return handleUpdateIsArchivedThreadConversation;
};

export { UPDATES_IS_ARCHIVED_THREAD_CONVERSATION_MUTATION };
export default useUpdateIsArchivedThreadConversation;
