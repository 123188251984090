import React from 'react';

import TaskPageProvider from './state/Provider';
import TaskPage from './TaskPage';

export interface ITaskGlobalPage {
  isDashboard?: boolean;
}
export default function index({ ...props }: ITaskGlobalPage) {
  return (
    <TaskPageProvider isAppt={false} patientParam={undefined}>
      <TaskPage {...props} />
    </TaskPageProvider>
  );
}
