import React, { ReactElement } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import AreaInput from '../Inputs/AreaInput';
import { FormInput } from '../Inputs/FormInput';

const useStyles = makeStyles((theme) => ({
  labels: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
}));
// name 33 caracters
// Details 90 caracters
interface IEditData {
  name: string;
  details: string;
}
interface IEditListItemProps {
  nameLabel: string;
  detailsLabel: string;
  initialName: string;
  initialDetails: string;
  handleEdit: (data: IEditData) => void;
  closeEditMode: () => void;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required()
    .test('len', 'Must be lower then 33 characters', (val) =>
      val ? val?.toString().length < 33 : false
    ),
  details: yup
    .string()
    .test('len', 'Must be lower then 90 characters', (val) =>
      val ? val?.toString().length < 90 : false
    ),
});

export default function EditListItem({
  nameLabel,
  detailsLabel,
  initialName,
  initialDetails,
  handleEdit,
  closeEditMode,
}: IEditListItemProps): ReactElement {
  const classes = useStyles();
  const { register, errors, getValues, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialName,
      details: initialDetails,
    },
  });

  async function handleSubmit() {
    const { name, details } = getValues();
    const result = await trigger();
    if (result) {
      handleEdit({ name, details });
    }
  }
  return (
    <Box
      display="flex"
      width="100vw"
      flexDirection="column"
      position="relative"
      pb={2}
    >
      <Box display="flex" flexDirection="row-reverse">
        <IconButton onClick={() => handleSubmit()}>
          <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
        </IconButton>
        <IconButton onClick={closeEditMode}>
          <HighlightOffOutlinedIcon style={{ color: 'red' }} />
        </IconButton>
      </Box>
      <Box style={{ paddingTop: '0px' }} className={classes.labels}>
        {nameLabel}
      </Box>
      <FormInput
        name="name"
        fullWidth
        variant="filled"
        inputRef={register}
        type="text"
        helperText={
          errors.name && (
            <span>
              <ErrorIcon className={classes.errorIcon} />
              {errors?.name?.message}
            </span>
          )
        }
        error={!!errors?.name}
      />
      <Box className={classes.labels}>{detailsLabel}</Box>

      <AreaInput
        name="details"
        fullWidth
        variant="filled"
        inputRef={register}
        multiline
        type="text"
        helperText={
          errors.details && (
            <span>
              <ErrorIcon className={classes.errorIcon} />
              {errors?.details?.message}
            </span>
          )
        }
        error={!!errors?.details}
      />
    </Box>
  );
}
