import AppConfig from './appConfig';
import DebugConfig from './debugConfig';

const DEV = process.env.NODE_ENV === 'development';
export const config =
  process.env.NODE_ENV === 'development' ? DebugConfig : AppConfig;
const ALLOW_CONSOLE_LOGS = config.ALLOW_CONSOLE_LOGS === 'true';

export function log(SEVERITY = 'log', ...params: any) {
  if (DEV || ALLOW_CONSOLE_LOGS) {
    switch (SEVERITY) {
      case 'error':
        console.error(...params);
        break;
      case 'warn':
        console.warn(...params);
        break;
      default:
        console.log(SEVERITY, ...params);
        break;
    }
  }
}
