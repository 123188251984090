import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Switch from 'components/ui/Switch';
import { useStyles } from './styles';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copy.svg';
import TrashIcon from 'assets/TrashIcon';
import Input from 'components/ui/Inputs';
import Options from './Options';
import { IQuestion } from './FormsPage';
import { NotesTypes, Types } from './Constants';
import { QuestionTypeEnum } from './Constants';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import IconSelect from './inputs/IconSelect';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Email } from 'assets/form/blocks/email-icon.svg';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as DateIcon } from 'assets/patientActivities/inputs/date.svg';

import Address from './Address';

import { ReactComponent as Signature } from 'assets/Signature.svg';
interface IQuestionCardProps {
  question: IQuestion;
  index: number;
  updateQuestion: (index: number, question: IQuestion) => void;
  removeQuestion: (index: number) => void;
  handleCopyQuestion: (index: number) => void;
  setFocusedQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  isNotes?: boolean;
  changeQuestion?: any;
  deleteBlock: (index: number) => void;
  getBlockIndex: () => number;
  addNewQuestion?: (type: string, question: string) => void;
}

function QuestionCard({
  question,
  index,
  updateQuestion,
  removeQuestion,
  handleCopyQuestion,
  setFocusedQuestionIndex,
  isNotes,
  changeQuestion,
  deleteBlock,
  getBlockIndex,
  addNewQuestion,
}: IQuestionCardProps): React.ReactElement {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState<string>(
    question?.type || ''
  );

  const handleType = (type) => {
    updateQuestion(index, { ...question, type });
  };
  const handleQuestion = (newQuestion) => {
    updateQuestion(index, { ...question, question: newQuestion });
  };
  const handleOptions = (updatedOptions) => {
    updateQuestion(index, { ...question, options: updatedOptions });
  };
  const handleAddOption = () => {
    if (question.options.length >= 50) return;
    const updatedOptions = [
      ...question.options,
      `Option ${question.options.length + 1}`,
    ];
    handleOptions(updatedOptions);
  };
  const handleRemoveOption = (optionIndex) => {
    const updatedOptions = question.options.filter(
      (_, index) => index !== optionIndex
    );
    if (updatedOptions.length > 0) {
      handleOptions(updatedOptions);
    }
  };
  const handleMinRange = (min) => {
    updateQuestion(index, { ...question, range: [min, question.range[1]] });
  };
  const handleMaxRange = (max) => {
    updateQuestion(index, { ...question, range: [question.range[0], max] });
  };
  const handleUpdateOption = (updatedOption, optionIndex) => {
    if (updatedOption.length > 0) {
      const updatedOptions = [...question.options];
      updatedOptions[optionIndex] = updatedOption;
      handleOptions(updatedOptions);
    }
  };
  const handleRequired = (isRequired) => {
    updateQuestion(index, { ...question, required: isRequired });
  };
  const handleDelete = () => {
    removeQuestion(index);
    setOpen(false);
  };
  useEffect(() => {
    handleType(selectedValue);
  }, [selectedValue]);
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      data-cy={`question-box-${index}`}
      onClick={() => setFocusedQuestionIndex(index)}
    >
      <Box width="100%" className={classes.CardBox} border="3px solid #2F80ED">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.question}
              mb="9px"
            >
              <Input
                variant="filled"
                type="text"
                // value={question.question}
                onChange={(e: any) => {
                  handleQuestion(e?.target?.value);
                  changeQuestion(e?.target?.value);
                }}
                defaultValue={question.question}
                data-cy={`question-${index}`}
                style={{ height: '40px', marginBottom: '9px' }}
                inputProps={{
                  style: {
                    height: '40px',
                    padding: '0 14px',
                    fontSize: '16px',
                  },
                }}
              />
              <Input
                variant="filled"
                type="text"
                onChange={(e: any) => {
                  handleQuestion(e?.target?.value);
                  changeQuestion(e?.target?.value);
                }}
                defaultValue={'Description'}
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    height: '40px',
                    padding: '0 14px',
                    fontSize: '16px',
                  },
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="flex-start"
            >
              {question.type != QuestionTypeEnum.ShortAnswer &&
                question.type != QuestionTypeEnum.LongAnswer &&
                question.type != QuestionTypeEnum.Date &&
                question.type != QuestionTypeEnum.DateRange &&
                question.type != QuestionTypeEnum.TextBox &&
                question.type != QuestionTypeEnum.Phone &&
                question.type != QuestionTypeEnum.Email &&
                question.type != QuestionTypeEnum.Address &&
                question.type != QuestionTypeEnum.Signature &&
                question.type != QuestionTypeEnum.TextBox && (
                  <Options
                    range={question.range}
                    type={question.type}
                    options={question.options}
                    handleOptions={handleUpdateOption}
                    handleAddOption={handleAddOption}
                    handleRemoveOption={handleRemoveOption}
                    handleMinRange={handleMinRange}
                    handleMaxRange={handleMaxRange}
                  />
                )}
              {question.type === QuestionTypeEnum.ShortAnswer && (
                <Input
                  variant="filled"
                  type="text"
                  style={{
                    height: '40px',
                    maxWidth: '80%',
                    marginBottom: '9px',
                  }}
                  value={'Short answer'}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                      fontSize: '14px',
                    },
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.LongAnswer && (
                <Input
                  variant="filled"
                  type="text"
                  VerticalAlignTop
                  style={{
                    height: '80px',
                    maxWidth: '80%',
                  }}
                  value={t('Long answer')}
                  inputProps={{
                    style: {
                      height: '80px',
                      padding: '0 14px',
                      fontSize: '14px',
                    },
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.TextBox && (
                <Input
                  variant="filled"
                  type="text"
                  style={{
                    height: '40px',
                    maxWidth: '80%',
                    marginBottom: '9px',
                  }}
                  value={t('Long answer text')}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                      fontSize: '14px',
                    },
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.Date && (
                <Input
                  variant="filled"
                  type="text"
                  value="Date"
                  style={{
                    height: '40px',
                    maxWidth: '40%',
                    marginBottom: '9px',
                  }}
                  inputProps={{
                    style: {
                      height: '40px',
                      padding: '0 14px',
                      fontSize: '14px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="email">
                          <DateIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.DateRange && (
                <>
                  <Input
                    variant="filled"
                    type="text"
                    value="Date"
                    style={{
                      height: '40px',
                      maxWidth: '40%',
                      marginBottom: '9px',
                      marginRight: '2px',
                    }}
                    inputProps={{
                      style: {
                        height: '40px',
                        padding: '0 14px',
                        fontSize: '14px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="email">
                            <DateIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                  <Input
                    variant="filled"
                    type="text"
                    value="Date"
                    style={{
                      height: '40px',
                      maxWidth: '40%',
                      marginBottom: '9px',
                    }}
                    inputProps={{
                      style: {
                        height: '40px',
                        padding: '0 14px',
                        fontSize: '14px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="email">
                            <DateIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </>
              )}
              {question.type === QuestionTypeEnum.Email && (
                <Input
                  variant="filled"
                  type="email"
                  value="Email adress"
                  style={{
                    maxWidth: '80%',
                    marginBottom: '9px',
                  }}
                  inputProps={{
                    style: {
                      padding: '10px 14px',
                      fontSize: '14px',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="email">
                          <Email />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.Phone && (
                <PhoneInput
                  inputStyle={{
                    height: '40px',
                    maxWidth: '80%',
                    marginBottom: '9px',
                  }}
                  inputProps={{
                    style: {
                      height: '40px',
                      fontSize: '14px',
                      width: '80%',
                    },
                  }}
                  disabled
                />
              )}
              {question.type === QuestionTypeEnum.Address && <Address />}
              {question.type === QuestionTypeEnum.Signature && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  style={{ backgroundColor: '#F2F4F7' }}
                  width="80%"
                  py="4rem"
                >
                  <Signature style={{ marginBottom: '5px' }} />
                  <Typography className={classes.signText}>
                    Sign here
                  </Typography>
                  <Box mt={1}>
                    <Typography className={classes.signDesc}>
                      By Inserting your signature
                    </Typography>
                    <Typography className={classes.signDesc}>
                      you are e-signing this form
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            justifyContent="flex-start"
            alignItems="flex-end"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box mb="1.5rem" className={classes.select}>
              <IconSelect
                values={isNotes ? NotesTypes : Types}
                selectedValue={question?.type}
                setSelectedValue={setSelectedValue}
                data-cy="emoji-select"
              />
            </Box>
            <Box display="flex" width="80%">
              <Box
                borderRight="0.5px solid #EAECF0"
                display="flex"
                alignItems="center"
                pr="1rem"
                maxHeight="24px"
                width="100%"
              >
                <Switch
                  name="required"
                  data-cy={`required-${index}`}
                  checked={question.required}
                  onChange={(e) => handleRequired(e.target.checked)}
                />
                <Typography className={classes.requiredText}>
                  {isNotes ? 'Exclude' : 'Required'}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                pl="1rem"
                style={{ gap: '1rem' }}
              >
                <Box
                  className={classes.pointer}
                  onClick={() =>
                    addNewQuestion(question?.type, question?.question)
                  }
                  data-cy={`copy-${index}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CopyIcon />
                </Box>
                <Box
                  className={classes.pointer}
                  onClick={() => {
                    setOpen(true);
                    deleteBlock(getBlockIndex());
                  }}
                  data-cy={`delete-${index}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <TrashIcon width="24px" height="24px" fill="#EB5757" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <ModalDelete
          open={open}
          onClose={() => setOpen(false)}
          onDelete={handleDelete}
          text="question"
        />
      </Box>
    </Box>
  );
}

export default QuestionCard;
