import React from 'react';
import DeleteHandler from 'components/DeleteHandler';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const formSchema = {
  certificationLocation: yup
    .string()
    .required('Certification location is a required field'),
  certificationName: yup
    .string()
    .required('Certification name is a required field'),
  yearOfDegree: yup.date().required('Certification year is a required field'),
  educationLevel: yup
    .string()
    .required('Level of education is a required field'),
};

export const fieldsSchema = yup.object().shape({
  array: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});
export const CertificationsForm = (handleDelete) => {
  const { t } = useTranslation();
  return [
    {
      name: 'certificationLocation',
      label: t('School/College/University'),
      type: 'textcontrolled',
      variant: 'filled',
      placeholder: 'Harvard University',
      'data-cy': 'certificationLocation',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'educationLevel',
      label: t('Level of education'),
      type: 'select',
      variant: 'filled',
      placeholder: t("Bachelor's"),
      options: [
        "Bachelor's",
        "Master's",
        'Doctorate',
        'Post Doctorate',
        'PHD',
        'Other',
      ],
      'data-cy': 'educationLevel',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'certificationName',
      label: t('Major'),
      type: 'textcontrolled',
      variant: 'filled',
      placeholder: 'Psychology',
      'data-cy': 'certificationName',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'yearOfDegree',
      label: t('Certification year'),
      type: 'date',
      variant: 'filled',
      placeholder: '2015',
      'data-cy': 'yearOfDegree',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      type: 'custom',
      label: t('Remove'),
      Custom: DeleteHandler,
      handleAction: handleDelete,
      'data-cy': 'remove',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
  ];
};
