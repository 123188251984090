import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/patientActivities/ArrowBlack.svg';
import { useStyles } from 'pages/PatientActivities/styles';
import Text from 'Text';

interface StepProps {
  selected: number;
  setSelected: (key: number) => void;
}
const FirstStepAct = ({ selected, setSelected }: StepProps) => {
  const classes = useStyles({});

  return (
    <Box className={classes.reflectionModal}>
      <Box style={{ paddingInline: '2rem' }}>
        <Box pt={3}>
          <Text i18nKey='newActivity' className={classes.modalTitle}>New activity</Text>
        </Box>
        <Box my={5}>
          {activityType?.map((act, key) => (
            <Box
              className={
                key === 0
                  ? classes.guidedA
                  : key === 3
                  ? classes.freeA
                  : classes.reflectionsA
              }
              style={{
                border:
                  selected === key
                    ? '2px solid rgba(45, 199, 109, 1)'
                    : '1px solid rgba(188, 198, 211, 1)',
              }}
              onClick={() => setSelected(key)}
              data-cy="guided-reflections"
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Typography className={classes.guidedTitle}>
                    {act.name}
                  </Typography>
                </Box>
                <ArrowIcon />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FirstStepAct;
export const activityType = [
  { name: 'Form', value: 'form' },
  { name: 'Reflections', value: 'reflection' },
  { name: 'Sleep check-ins', value: 'Sleep' },
  { name: 'Mood check-ins', value: 'Mood' },
];
