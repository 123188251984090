export const FormatText = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
};

export const truncateText = (text: string, maxLength: number): string => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export const capitalizedText = (text: string) => {
  if (!text || text.length === 0) {
    return null;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};
