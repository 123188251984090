import React, { ReactElement, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Grid, Box, makeStyles } from '@material-ui/core';
import Tabs from 'components/ui/Tabs';
import Password from 'pages/AdminPage/TabsContent/Password';
import Payment from 'pages/AdminPage/TabsContent/BillingInformation';
import Language from 'pages/AdminPage/Language';
import EmailNotifications from 'pages/AdminPage/TabsContent/EmailNotifications';
import { ContactInfo } from './ContactInfo';
import { RegionalTat } from './RegionalTat';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import PracticeInformation from 'pages/AdminPage/TabsContent/PracticeInformation';

enum REDIRECT {
  notification = 'Notification',
}
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '16px',
    minHeight: 'calc(100vh - 220px)',
  },
}));

const PatientSettings = (): ReactElement => {
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Settings') });
  }, [headerTitleDispatcher]);
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('query');
  const tabSelected = queryParams.get('tab');
  const [customValue, setCustomValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== customValue) {
      setCustomValue(Number(tabSelected));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (customValue !== null && customValue !== undefined) {
        history.push(`/patient/settings?tab=${customValue}`);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [customValue]);
  React.useEffect(() => {
    if (redirectStatus === REDIRECT.notification) {
      setCustomValue(3);
    }
  }, [redirectStatus]);
  return (
    <Box className={classes.container} pt={4} pb={'4rem'} px={'10rem'}>
      <Box>
        <Tabs
          tabsBar={[
            t('Personal info'),
            t('Time and scheduling'),
            t('Login & security'),
            t('Notifications'),
            t('Payments'),
            t('Language'),
            t('Signature'),
          ]}
          tabsContent={[
            <ContactInfo />,
            <RegionalTat />,
            <Password isPatient />,
            <EmailNotifications isPatient />,
            <Payment isPatient />,
            <Language isPatient />,
            <PracticeInformation isPatient />,
          ]}
          tabsMode="simple"
          setCustomValue={setCustomValue}
          customValue={customValue}
        />
      </Box>
    </Box>
  );
};
export default PatientSettings;

export const GET_CURRENT_USER = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      language
    }
  }
`;
