import * as React from 'react';
import { appointmentPageContext, IAction } from 'shared';
import {
  CHANGE_TAB,
  OPEN_PRESCRIPTION_ADD,
  CLOSE_PRESCRIPTION_ADD,
  OPEN_REFERRAL_ADD,
  CLOSE_REFERRAL_ADD,
  EDIT_REFERRAL_MODE,
  DELETE_REFERRAL_MODE,
  CLOSE_DELETE_MODAL,
  OPEN_LAB_ADD,
  EDIT_LAB_MODE,
  OPEN_CONSULTATION_ADD,
  EDIT_CONSULTATION_MODE,
  OPEN_PRESCRIPTION_DRUG_ADD,
  DELETE_PRESCRIPTION_DRUG_MODE,
  EDIT_PRESCRIPTION_DRUG_MODE,
  OPEN_IMAGING_ADD,
  EDIT_IMAGING_MODE,
  DELETE_IMAGING_MODE,
} from './actions';

export const Context = React.createContext<appointmentPageContext | undefined>(
  undefined
);

export function reducer(state: appointmentPageContext, action: IAction): any {
  switch (action.type) {
    case CHANGE_TAB: {
      return { ...state, currentTab: action.payload };
    }
    case OPEN_PRESCRIPTION_ADD: {
      return {
        ...state,
        modalType: 'prescription',
        modal: true,
        prescriptionDrugEditMode: false,
      };
    }
    case OPEN_PRESCRIPTION_DRUG_ADD: {
      return {
        ...state,
        modalType: 'prescriptionDrug',
        modal: true,
        prescriptionDrugEditMode: false,
      };
    }
    case OPEN_LAB_ADD: {
      return { ...state, modalType: 'lab', modal: true, labEditMode: false };
    }
    case CLOSE_PRESCRIPTION_ADD: {
      return { ...state, modal: false };
    }
    case OPEN_REFERRAL_ADD: {
      return {
        ...state,
        modalType: 'referral',
        modal: true,
        referralEditMode: false,
      };
    }
    case CLOSE_REFERRAL_ADD: {
      return { ...state, modalType: 'referral', modal: false };
    }
    case EDIT_REFERRAL_MODE: {
      return {
        ...state,
        modalType: 'referral',
        referralEditMode: true,
        currentReferral: action.payload,
        modal: true,
      };
    }
    case EDIT_LAB_MODE: {
      return {
        ...state,
        modalType: 'lab',
        labEditMode: true,
        currentLab: action.payload,
        modal: true,
      };
    }
    case EDIT_PRESCRIPTION_DRUG_MODE: {
      return {
        ...state,
        modalType: 'prescriptionDrug',
        prescriptionDrugEditMode: true,
        currentPrescriptionDrug: action.payload,
        modal: true,
      };
    }
    case EDIT_CONSULTATION_MODE: {
      return {
        ...state,
        consultationEditMode: true,
        modalType: 'consultation',
        modal: true,
      };
    }
    case DELETE_REFERRAL_MODE: {
      return {
        ...state,
        modalType: 'deleteReferral',
        referralEditMode: false,
        currentReferral: action.payload,
        modal: true,
      };
    }
    case DELETE_IMAGING_MODE: {
      return {
        ...state,
        modalType: 'deleteImaging',
        imagingEditMode: false,
        currentImaging: action.payload,
        modal: true,
      };
    }
    case DELETE_PRESCRIPTION_DRUG_MODE: {
      return {
        ...state,
        modalType: 'deletePrescriptionDrug',
        prescriptionDrugEditMode: false,
        currentPrescriptionDrug: action.payload,
        modal: true,
      };
    }
    case CLOSE_DELETE_MODAL:
      return {
        ...state,
        currentReferral: {},
        modal: false,
      };
    case OPEN_CONSULTATION_ADD:
      return {
        ...state,
        modalType: 'consultation',
        modal: true,
        consultationEditMode: false,
      };
    case OPEN_IMAGING_ADD:
      return {
        ...state,
        modalType: 'imaging',
        modal: true,
        imagingEditMode: false,
      };
    case EDIT_IMAGING_MODE:
      return {
        ...state,
        modalType: 'imaging',
        modal: true,
        imagingEditMode: true,
        currentImaging: action.payload,
      };
    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}
