import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useStyles from '../style';
import Tabs from 'components/ui/Tabs';
import Text from 'Text';
import Input from 'components/ui/Inputs';
import AllTemplates from './templatesTabs/AllTemplates';
import { TemplateType } from './templatesTabs/Columns';
import { templates } from './templatesTabs/constants';
import SearchBar from './templatesTabs/SearchBar';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import Button from 'components/ui/Button';
import NoteTakingModal from 'components/ui/Modal/NoteTakingModal';
import NewForm from 'pages/PatientActivities/Questionaires/NewForm';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { gql, useQuery } from '@apollo/client';

function ManageTemplates() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [filteredTemplates, setFilteredTemplates] = React.useState(templates);
  const { setBlocks, handleChangeText, handleSelectedTemplate } = useEditorContext();

  const handleSearch = (query: string) => {
    const filtered = templates.filter((template) => {
      const nameMatches = template.name
        .toLowerCase()
        .includes(query.toLowerCase());
      const tagMatches = template.tags.some((tag) =>
        tag.title.toLowerCase().includes(query.toLowerCase())
      );
      return nameMatches || tagMatches;
    });

    setFilteredTemplates(filtered);
    setTab(0);
  };
  const [formType, setFormType] = React.useState('');
  React.useEffect(() => {
    if (tab === 2) {
      setFormType('intake-form');
    }
    if (tab === 3) {
      setFormType('assessment-form');
    }
  }, [tab]);
  const { data, loading } = useQuery(DOCTOR_TEMPLATES_BY_FORM, {
    variables: {
      formType,
    },
  });
  const concatenatreTemplate = [
    ...templates,
    ...(data?.doctorTemplateByTypeForm?.map((el) => {
      return {
        ...el,
        name: el?.title,
        type: el?.formType,
      };
    }) || []),
  ];
  function handleOpenModal() {
    setOpen(true);
  }
  function handleCloseModal() {
    setOpen(false);
  }


  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <Text
          i18nKey="Templates"
          style={{
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '20px',
            color: '#344054',
          }}
        >
          Templates
        </Text>
        {/* <Input
          type="text"
          variant="filled"
          style={{
            height: '40px',
            marginBlock: '1rem',
            width: '320px',
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          placeholder="Search all templates and tags"
        /> */}
        <Box mb={4}>
          <SearchBar templates={concatenatreTemplate} onSearch={handleSearch} />
        </Box>
        <Tabs
          tabsMode="simple"
          tabsBar={[
            'All templates',
            'Note-taking',
            'Intake',
            'Assessments',
            'Other',
          ]}
          tabClassHeader={classes.headerTab}
          componetAlignedRightWithTabs={
            <Button
              className={classes.addBtn}
              onClick={() => {
                setBlocks(null, false, '');
                handleChangeText('Untitled template');
                handleSelectedTemplate(null);
                handleOpenModal();
              }}
            >
              <AddTaskIcon
                style={{
                  paddingRight: '6px',
                }}
              />
              <Text i18nKey="add">Add</Text>
            </Button>
          }
          customValue={tab}
          setCustomValue={setTab}
          tabsContent={[
            <AllTemplates type={TemplateType.all} data={filteredTemplates} />,
            <AllTemplates
              type={TemplateType.notes}
              data={templates.filter(
                (template) => template.type === TemplateType.notes
              )}
            />,
            <AllTemplates
              type={TemplateType.intake}
              loading={loading}
              data={data?.doctorTemplateByTypeForm}
              handleOpenModal={handleOpenModal}
            />,
            <AllTemplates
              type={TemplateType.assessment}
              data={data?.doctorTemplateByTypeForm}
              loading={loading}
              handleOpenModal={handleOpenModal}
            />,
            <AllTemplates
              type={TemplateType.other}
              data={templates.filter(
                (template) => template.type === TemplateType.other
              )}
            />,
          ]}
        />
      </Grid>
      <NoteTakingModal
        open={open}
        handleClose={() => {
          handleCloseModal();
          handleChangeText('Untitled template');
        }}
      >
        <NewForm
          handleBack={() => {
            handleCloseModal();
            handleChangeText('Untitled template');
          }}
        />
      </NoteTakingModal>
    </Grid>
  );
}

export default ManageTemplates;
export const DOCTOR_TEMPLATES_BY_FORM = gql`
  query doctorTemplateByTypeForm($formType: String) {
    doctorTemplateByTypeForm(formType: $formType) {
      _id
      title
      description
      lastOpenedAt
      isDefault
      questions {
        _id
        question
        type
        options
        description
        isEdited
        isDescription
        required
        range
      }
      doctor {
        _id
      }
      formType
      blocks
    }
  }
`;
