import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PdfIcon } from 'assets/icons/pdficon.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/printerIcon.svg';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  document: {
    paddingTop: '10px',
    paddingLeft: '30px',
    width: '100%',
    height: '90%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    margin: '50px',
    maxWidth: '80vw',
    boxShadow: '0px 0px 13px 0px rgba(161, 176, 196, 1)',
    borderBottom: '5px solid #1F61DC',
    outline: '0px',
    position: 'relative',
    height: '90%',
    overflow: 'hidden',
  },
  text: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
  },
  listRoot: {
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    maxHeight: '50vh',
  },
  title: {
    color: 'white',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    paddingLeft: '10px',
  },
  icon: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
  },
  Input: {
    marginTop: theme.spacing(2),
  },
}));

interface IViewerHeaderProps {
  editSection: boolean;
  handlePrint: () => void;
  handleDownload: () => void;
  closeModal: () => void;
}
export default function ViewerHeader({
  editSection,
  handlePrint,
  handleDownload,
  closeModal,
}: IViewerHeaderProps) {
  const classes = useStyles();
  return (
    <Box p={2} display="flex" alignItems="center">
      <PdfIcon /> <span className={classes.title}>Patient History</span>
      <Box
        ml="auto"
        display="flex"
        alignItems="center"
        style={{ marginRight: editSection ? '20vw' : '' }}
      >
        <PrinterIcon
          style={{
            marginRight: '10px',
            cursor: 'pointer',
          }}
          onClick={handlePrint}
        />
        <Button variant="contained" size="small" onClick={handleDownload}>
          Download
        </Button>
        <CloseIcon
          style={{
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            marginLeft: '10px',
          }}
          onClick={closeModal}
        />
      </Box>
    </Box>
  );
}
