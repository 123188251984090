import { gql } from '@apollo/client';

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
    userData @client {
      user {
        _id
        email
        firstname
        middlename
        username
        lastname
        role
        organizationId
        doctorId
      }
      token
    }
  }
`;

export const USER_DATA = gql`
  query IsUserLoggedInData {
    isLoggedIn @client
    userData @client {
      user {
        _id
        email
        firstname
        middlename
        username
        lastname
        role
        organizationId
        doctorId
      }
      token
    }
  }
`;
