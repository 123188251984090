import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import Card from './Card';
import { SubscriptionType } from './SubscriptionPage';
import useUpdateSubscription from '@eggmed/graphql-client/operations/subscriptionOperations/useUpdateSubscription';
import { GET_DOCTOR_SUBSCRIPTION } from '@eggmed/graphql-client/operations/subscriptionOperations/useGetSubscription';
import useGetSubscription from '@eggmed/graphql-client/operations/subscriptionOperations/useGetSubscription';

interface Props {
  setStep: (step: number) => void;
  setType: (type: string) => void;
  setAmount: (amount: number) => void;
  doctorId: string;
  handleCloseModal?: () => void;
}

function SubscriptionModal({
  setStep,
  setType,
  setAmount,
  doctorId,
  handleCloseModal,
}: Props) {
  const classes = useStyles();
  const { data, loading } = useGetSubscription();
  const { editSubscription } = useUpdateSubscription(doctorId);
  const handleClick = async (type, amount) => {
    if (type === SubscriptionType.FREE) {
      await editSubscription({
        variables: {
          subscriptionInput: {
            doctor: doctorId,
            hasTrial: true,
            endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
          },
        },
        refetchQueries: [
          { query: GET_DOCTOR_SUBSCRIPTION, variables: { doctor: doctorId } },
        ],
      });
      handleCloseModal();
    }
    if (type === SubscriptionType.REGULAR) {
      setStep(1);
      setType(type);
      setAmount(amount);
    }
    // if (type === SubscriptionType.PREMIUM) {
    //   setStep(1);
    //   setType(type);
    //   setAmount(amount);
    // }
  };

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height="100%"
        style={{ gap: '3rem' }}
      >
        {data?.subscription?.plan === SubscriptionType.FREE &&
          !data?.subscription.endDate && (
            <Card
              title="Free"
              pricing="$0"
              text="Free trial for 30 days"
              amount={0}
              onClick={handleClick}
              type={SubscriptionType.FREE}
            />
          )}
        <Card
          title="Regular"
          pricing="$20/month"
          text="Your regular subscription"
          amount={20}
          onClick={handleClick}
          type={SubscriptionType.REGULAR}
        />
        {/* <Card
          title="Premium"
          pricing="$100 for life"
          text="Premium lifetime access"
          amount={100}
          onClick={handleClick}
          type={SubscriptionType.PREMIUM}
        /> */}
      </Box>
    </Box>
  );
}

export default SubscriptionModal;
