import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import { useToast } from 'hooks/useToast';
import useStyles from './styles';
import clsx from 'clsx';

interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  title?: string;
  titlePadding?: string;
  isIcon?: boolean;
  className?: string;
  titleClass?: string;
}

export default function NewRegularModal({
  children,
  handleClose,
  title,
  titlePadding,
  isIcon,
  className,
  titleClass,
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, disablePadding: false });
  return (
    <Box className={clsx(className, classes.newRegularModal)}>
      {isIcon && (
        <CancelIcon className={classes.cancelIconSlide} onClick={handleClose} />
      )}

      {title && (
        <Box width="100%">
          <Typography
            className={clsx(titleClass, classes.slideTitle)}
            style={{ paddingInline: titlePadding }}
          >
            {title &&
              title[0].toUpperCase() +
                title.slice(1, title.length).toLowerCase()}
          </Typography>
        </Box>
      )}
      <Box flex={1} pt={!title && titlePadding}>
        {children}
      </Box>
    </Box>
  );
}
