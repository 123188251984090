import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, Box, Typography, Avatar } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import { log } from 'config/log';
import { GET_DOCTOR, EDIT_DOCTOR } from './graphql';
import useStyles from './styles';
import PersonalInfo from './PersonalInformation';
import Card from './Card';
import About from './AboutDoctor';
import Certifications from './Certifications';
import BasicModal from 'components/ui/Modal';
import EditPersonalInfo from './modalTabs/EditPersonalInfo';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import AboutDoctor from './modalTabs/AboutDoctor';
import CertificationsModal from './modalTabs/CertificationModal';
import { useToast } from 'hooks/useToast';
import { useParams } from 'react-router-dom';
import DataLoader from 'components/ui/DataLoader';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import useUploadPicture from './modalTabs/useUploadPicture';
import ContactInfo from './ContactInfo';
import EditContactInfo from './modalTabs/EditContactInfo';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

export interface ICertification {
  certificationName?: string;
  yearOfDegree?: string;
  certificationLocation?: string;
  educationLevel?: string;
}

export enum TypeEnum {
  EDIT = 'edit',
  ABOUT = 'about',
  CERTIFICATION = 'certification',
  CONTACT = 'contact',
}

const schema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
  firstname: yup.string().required('First name is a required field'),
  lastname: yup.string().required('Last name is a required field'),
});

interface IDoctorProfileProps {
  isPatient?: boolean;
}

const DoctorProfile = ({
  isPatient = false,
}: IDoctorProfileProps): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const {
    data: doctorData,
    loading,
    error,
  } = useQuery(GET_DOCTOR, {
    variables: { DoctorId: id },
  });
  const classes = useStyles({ isPatient });
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState<TypeEnum>(null);
  const [editDoctor, { loading: loadingMutation }] = useMutation(EDIT_DOCTOR, {
    refetchQueries: [{ query: GET_DOCTOR, variables: { DoctorId: id } }],
    awaitRefetchQueries: false,
  });

  const {
    register,
    getValues,
    control,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      birthday: timeConverter(
        doctorData?.doctor?.birthday || new Date().getMilliseconds().toString()
      ),
      languages:
        doctorData?.doctor?.languages &&
        doctorData?.doctor?.languages.map((lang) => {
          return { name: lang, id: lang };
        }),

      patientGroup:
        doctorData?.doctor?.patientGroup &&
        doctorData?.doctor?.patientGroup.map((el) => {
          return { name: el, id: el };
        }),
      focusArea:
        doctorData?.doctor?.focusArea &&
        doctorData?.doctor?.focusArea.map((el) => {
          return { name: el, id: el };
        }),
      ...doctorData?.doctor,
    },
  });
  const {
    openUpload,
    setOpenUpload,
    fileObjects,
    onAddPicture,
    onDeletePicture,
    onSave,
  } = useUploadPicture(editDoctor, id, setValue);
  register({ name: 'picture' });
  useEffect(() => {
    if (doctorData?.doctor) {
      reset({
        name: `${doctorData?.doctor.firstname} ${doctorData?.doctor.lastname}`,
        ...doctorData?.doctor,
        about: editorStateFromStringifiedRaw(
          doctorData?.doctor?.about,
          !tryParseJSON(doctorData?.doctor?.about)
        ),
        birthday: doctorData?.doctor?.birthday
          ? timeConverter(doctorData?.doctor?.birthday)
          : '',
        languages:
          doctorData?.doctor?.languages &&
          doctorData?.doctor?.languages.map((lang) => {
            return { name: lang, id: lang };
          }),
        patientGroup:
          doctorData?.doctor?.patientGroup &&
          doctorData?.doctor?.patientGroup.map((el) => {
            return { name: el, id: el };
          }),
        focusArea:
          doctorData?.doctor?.focusArea &&
          doctorData?.doctor?.focusArea.map((el) => {
            return { name: el, id: el };
          }),
      });
    }
  }, [reset, doctorData]);

  async function handleEditDoctor() {
    const data = await getValues();
    await trigger();
    const editData = {
      languages:
        data?.languages?.length > 0
          ? data.languages.map((lang) => lang.name)
          : undefined,
      experience: data.experience,
      specialty: data.specialty,
      gender: data.gender,
      birthday: data.birthday,
      firstname: data.firstname,
      middlename: data.middlename,
      lastname: data.lastname,
      displayName: data.displayName,
      pronouns: data.pronouns,
    };
    Object.keys(errors).length == 0 &&
      (await editDoctor({
        variables: {
          doctorID: id,
          DoctorInput: editData,
        },
      }),
      handleEditSuccess());
  }
  function handleEditSuccess() {
    triggerSnack();
    setOpen(null);
  }
  async function handleEditAbout() {
    const data = getValues();
    await trigger();
    const editData = {
      about: convertToRawAndStringify(data.about),
      patientGroup: data.patientGroup.map((el) => el.name),
      focusArea: data.focusArea.map((el) => el.name),
    };
    Object.keys(errors).length == 0 &&
      (await editDoctor({
        variables: {
          doctorID: id,
          DoctorInput: editData,
        },
      }),
      handleEditSuccess());
  }
  const handleContact = async () => {
    const data = getValues();
    await trigger();
    const editData = {
      phone: data.phone || undefined,
      email: data.email,
      address: data.address,
      city: data.city,
      zipcode: data.zipcode,
      country: data.country,
      suite: data.suite,
      state: data.state,
    };
    Object.keys(errors).length == 0 &&
      (await editDoctor({
        variables: {
          doctorID: id,
          DoctorInput: editData,
        },
      }),
      handleEditSuccess());
  };
  const handleOpenEditPErsonalInfo = () => {
    setOpen(TypeEnum.EDIT);
  };
  const handleOpenEditContactInfo = () => {
    setOpen(TypeEnum.CONTACT);
  };
  const handleOpenEditAbout = () => {
    setOpen(TypeEnum.ABOUT);
  };
  const handleOpenEditCertifications = () => {
    setOpen(TypeEnum.CERTIFICATION);
  };
  const handleClose = () => {
    setOpen(null);
  };
  async function handleAddEditCertifications(
    data: ICertification[],
    editedData: ICertification[],
    allData: ICertification[]
  ) {
    const editData = {
      certifications: allData.map(
        ({
          certificationName,
          yearOfDegree,
          certificationLocation,
          educationLevel,
        }) => {
          return {
            certificationName,
            yearOfDegree,
            certificationLocation,
            educationLevel,
          };
        }
      ),
    };
    Object.keys(errors).length == 0 &&
      (await editDoctor({
        variables: {
          doctorID: id,
          DoctorInput: editData,
        },
      }));
    handleEditSuccess();
  }
  const commonProps = { control, errors, register };
  const { certifications, about, focusArea, patientGroup } =
    doctorData?.doctor || {};
  const emptyAbout = convertStringToEditorState(about)
    .getCurrentContent()
    .hasText();

  const isEmpty =
    !emptyAbout && focusArea?.length == 0 && patientGroup?.length == 0;
  const modalType = {
    [TypeEnum.EDIT]: () => (
      <EditPersonalInfo
        handleClose={handleClose}
        handleEditDoctor={handleEditDoctor}
        doctor={doctorData?.doctor}
        loading={loadingMutation}
        {...commonProps}
      />
    ),
    [TypeEnum.ABOUT]: () => (
      <AboutDoctor
        handleClose={handleClose}
        handleEditDoctor={handleEditAbout}
        loading={loadingMutation}
        supportVideo={doctorData?.doctor?.supportVideo}
        {...commonProps}
      />
    ),
    [TypeEnum.CERTIFICATION]: () => (
      <CertificationsModal
        handleClose={handleClose}
        doctor={doctorData?.doctor}
        handleAddEditCertifications={handleAddEditCertifications}
        loading={loadingMutation}
      />
    ),
    [TypeEnum.CONTACT]: () => (
      <EditContactInfo
        handleClose={handleClose}
        handleEditDoctor={handleContact}
        doctor={doctorData?.doctor}
        loading={loadingMutation}
        {...commonProps}
      />
    ),
  };
  const modalTitle = (type) => {
    switch (type) {
      case TypeEnum.EDIT:
        return t('Edit personal information');
      case TypeEnum.ABOUT:
        return t('Edit about information');
      case TypeEnum.CERTIFICATION:
        return t('Edit degrees and certifications');
      case TypeEnum.CONTACT:
        return t('Edit contact information');
    }
  };
  return (
    <DataLoader
      loading={loading}
      error={error}
      data={doctorData}
      isPatient={isPatient}
    >
      <>
        <Box
          width="100%"
          display="flex"
          flexDirection="Column"
          justifyContent=""
          style={{ gap: '1rem' }}
        >
          <Box display="flex" flexDirection="row" style={{ gap: '1rem' }}>
            <Box display="flex" flexGrow={1} maxWidth="400px" minWidth="400px">
              <Card
                handleOpen={handleOpenEditPErsonalInfo}
                isPatient={isPatient}
                cy="edit-personal"
                loading={loading}
              >
                <PersonalInfo
                  doctor={doctorData?.doctor}
                  setOpen={setOpen}
                  isPatient={isPatient}
                  openUploadModal={setOpenUpload}
                  loading={loading}
                />
              </Card>
            </Box>
            <Card
              handleOpen={handleOpenEditAbout}
              isPatient={isPatient}
              cy="edit-about"
              editButton={!isEmpty}
              loading={loading}
            >
              <About
                handleOpen={handleOpenEditAbout}
                doctor={doctorData?.doctor}
                setOpen={setOpen}
                isPatient={isPatient}
                isEmpty={isEmpty}
                loading={loading}
              />
            </Card>
          </Box>
          <Box display="flex" flexDirection="row" style={{ gap: '1rem' }}>
            <Box display="flex" flexGrow={1} maxWidth="400px" minWidth="400px">
              <Card
                handleOpen={handleOpenEditContactInfo}
                isPatient={isPatient}
                cy="edit-personal"
                loading={loading}
              >
                <ContactInfo
                  doctor={doctorData?.doctor}
                  isPatient={isPatient}
                  loading={loading}
                />
              </Card>
            </Box>
            <Card
              handleOpen={handleOpenEditCertifications}
              isPatient={isPatient}
              cy="edit-certifications"
              editButton={certifications?.length > 0 || loading}
              loading={loading}
            >
              <Certifications
                handleOpen={handleOpenEditCertifications}
                doctor={doctorData?.doctor}
                setOpen={setOpen}
                isPatient={isPatient}
                loading={loading}
              />
            </Card>
          </Box>
        </Box>
        <BasicModal
          handleClose={handleClose}
          onClose={handleClose}
          open={open != null}
          isRegularModal
          isSlide
          divider
          title={modalTitle(open)}
        >
          <Box className={classes.modalBox}>
            {open && <>{modalType[open]()}</>}
          </Box>
        </BasicModal>
        <DropzoneDialogBase
          dialogTitle="Upload your picture"
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          fileObjects={fileObjects}
          filesLimit={1}
          cancelButtonText="cancel"
          submitButtonText="submit"
          maxFileSize={5000000}
          open={openUpload}
          onAdd={onAddPicture}
          onDelete={onDeletePicture}
          onClose={() => setOpenUpload(false)}
          onSave={onSave}
          showPreviews
          showFileNamesInPreview={false}
          showAlerts={['error', 'info']}
        />
      </>
    </DataLoader>
  );
};
export default DoctorProfile;
