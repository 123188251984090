import React from 'react';
import useUpdateNotificationSettings from '@eggmed/graphql-client/operations/notificationOperations/useUpdateNotificationSettings';
import useGetNotifications from '@eggmed/graphql-client/operations/notificationOperations/useGetNotifications';

function useHandleTopic({ elements, deleteId, setAnchor, setDeleteId }) {
  const [topicOn, setTopicOn] = React.useState<boolean>(true);
  const { data, loading, error } = useGetNotifications();
  const updateNotificationSetting = useUpdateNotificationSettings();
  const userEvents = data?.getNotificationSettingsByUser?.events;
  const getEvent = () => {
    const notification = elements?.find((element) => element.id === deleteId);
    const notificationEvent = notification?.event.split('-')[1];
    const eventNeeded = data?.notificationEvents.find((e) =>
      e.event.some((e) => e === notificationEvent)
    );
    return eventNeeded;
  };
  const turnOffNotification = async () => {
    const eventToRemove = getEvent();
    const removedEvent = userEvents?.filter(
      (e) => e?._id !== eventToRemove?._id
    );
    const removedEventId = removedEvent?.map((e) => e._id);
    await updateNotificationSetting({ events: removedEventId });
    setAnchor(null);
    setDeleteId(null);
  };
  const turnOnNotification = async () => {
    const eventToAdd = getEvent();
    const addedEvent = [...userEvents, eventToAdd];
    const addedEventId = addedEvent?.map((e) => e._id);
    await updateNotificationSetting({ events: addedEventId });
    setAnchor(null);
    setDeleteId(null);
  };
  React.useEffect(() => {
    const eventToRemove = getEvent();
    const removedEvent = userEvents?.find((e) => e?._id === eventToRemove?._id);
    if (removedEvent) {
      setTopicOn(true);
    } else {
      setTopicOn(false);
    }
  }, [deleteId]);
  return {
    turnOffNotification,
    turnOnNotification,
    getEvent,
    userEvents,
    topicOn,
  };
}

export default useHandleTopic;
