import { gql, useMutation } from '@apollo/client';
import {
  referralDoctorFragment,
  referralMainInformationsFragment,
} from './fragments';

const ADD_REFERRAL = gql`
  mutation addReferral($referralInput: addReferralInput!) {
    addReferral(referralInput: $referralInput) {
      ...referral
      appointment
      referredTo {
        ...referralDoctor
      }
    }
  }
  ${referralDoctorFragment}
  ${referralMainInformationsFragment}
`;

export default function useAddReferral() {
  const [createReferral, result] = useMutation(ADD_REFERRAL, {
    update: (cache, { data: { addReferral } }) => {
      cache.modify({
        fields: {
          appointmentReferrals(referrals) {
            return [...referrals, addReferral];
          },
        },
      });
    },
  });
  return { createReferral, ...result };
}
