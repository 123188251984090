import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Typography,
  BoxProps,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/deleteModalIcon.svg';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    [theme.breakpoints.only('xl')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '50vw',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '1rem',
  },
  deleteButton: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    minWidth: '80px',
    minHeight: '40px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#C81C10',
      boxShadow: 'unset',
    },
  },
  cancelButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid #D0D5DD',
    color: '#344054',
    backgroundColor: 'transparent',
    minWidth: '80px',
    minHeight: '40px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#344054',
    marginBottom: '25px',
  },
}));
interface IDeleteModalContentProps extends BoxProps {
  handleDelete: () => void;
  handleClose: () => void;
  text?: string;
  isPatient?: boolean;
  message?: string;
  loading?: boolean;
}
export default function DeleteModalContent({
  handleDelete,
  handleClose,
  text,
  isPatient = false,
  message = 'remove',
  loading = false,
  ...props
}: IDeleteModalContentProps): ReactElement {
  const classes = useStyles({ isPatient });
  const { t } = useTranslation();
  return (
    <Box className={classes.deleteModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={{
          padding: '1rem',
          boxSizing: 'border-box',
          gap: '20px',
        }}
      >
        <DeleteIcon />
        <Typography className={classes.title}>
          {t('Remove this')} {t(text)}?
        </Typography>
      </Box>
      <Box
        height="8vh"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="0.5px solid rgba(161, 176, 196, 1)"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <Box className={classes.buttonsContainer}>
          <Button className={classes.cancelButton} onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? <ProgressLoader width={20} height={20} /> : t('Delete')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
