import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import useStyles from '../styles';
import AddEditForm from 'components/AddEditForm';
import { CertificationsForm, fieldsSchema } from './CertificationForm';
import { IDoctor } from '../PersonalInformation';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import { ICertification } from '../DoctorProfile';
import { useTranslation } from 'react-i18next';

interface ICertificationModalProps {
  handleClose: () => void;
  doctor: IDoctor;
  handleAddEditCertifications: (
    data: ICertification[],
    editedData: ICertification[],
    allData: ICertification[]
  ) => void;
  loading?: boolean;
}

const EditDoctorCertifications = ({
  handleClose,
  doctor,
  handleAddEditCertifications,
  loading,
}: ICertificationModalProps): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const { t } = useTranslation();

  return (
    <Box className={classes.scroll}>
      <AddEditForm
        wrapper
        loading={loading}
        onClose={handleClose}
        defaultValues={
          doctor?.certifications.map((el) => {
            return {
              ...el,
              yearOfDegree: timeConverter(
                el?.yearOfDegree || new Date().getMilliseconds().toString()
              ),
            };
          }) || []
        }
        addButton={true}
        deleteButton={true}
        handleAdd={handleAddEditCertifications}
        handleDelete={() => {}}
        FormOptions={CertificationsForm}
        cols={5}
        xl={3}
        margin={4}
        marginTop={2}
        mx="auto"
        buttonLabel={t('Add')}
        right="4rem"
        left="4rem"
        mode="edit"
        cellHeight={100}
        lastField="Treatment"
        schema={fieldsSchema}
        fields={[
          { fieldName: 'Condition', size: 6 },
          { fieldName: 'Type', size: 3 },
          { fieldName: 'Date', size: 3 },
          // { fieldName: 'Treatment', size: 6 },
        ]}
        // width="95%"
      />
    </Box>
  );
};
export default EditDoctorCertifications;
