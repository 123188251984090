import { gql, useMutation } from '@apollo/client';

export const TOGGLE_REMINDER = gql`
  mutation toggleReminder($reminderInput: ReminderInput) {
    toggleReminder(reminderInput: $reminderInput) {
      isEmail
      isText
      emailInt
      emailPeriod
      textInt
      textPeriod
    }
  }
`;

export default function useToggleReminder() {
  const [toggleReminder, result] = useMutation(TOGGLE_REMINDER, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          patientReminder() {
            return data?.patientReminder;
          },
        },
      });
    },
  });
  return { toggleReminder, ...result };
}
