import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as IntakeFormIcon } from 'assets/form/templates/intake.svg';
import { ReactComponent as AssessmentFormIcon } from 'assets/form/templates/assessment.svg';
import { ReactComponent as NoteTakingIcon } from 'assets/form/templates/note-taking.svg';
import { ReactComponent as OtherIcon } from 'assets/form/templates/other.svg';
import { ReactComponent as Radio } from 'assets/form/templates/radio.svg';
import { ReactComponent as ClickedRadio } from 'assets/form/templates/clicked-radio.svg';
import clsx from 'clsx';
import useStyles from './styles';
import { useEditorContext } from '../EditorContextProvider';

const templates = [
  {
    name: 'Intake form',
    value: 'intake-form',
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <IntakeFormIcon />,
  },
  {
    name: 'Assessment form',
    value: 'assessment-form',
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <AssessmentFormIcon />,
  },
  {
    name: 'Note-taking',
    value: 'note-taking',
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <NoteTakingIcon />,
  },
  {
    name: 'Other',
    value: 'other',
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <OtherIcon />,
  },
];

const TemplateItem = ({
  name,
  icon,
  clicked,
  notClicked,
  selected,
  onSelect,
}) => {
  const classes = useStyles();
  // const { editMode } = useEditorContext();
  return (
    <Box
      className={clsx(classes.templateItem, selected && classes.selected)}
      onClick={onSelect}
    >
      <Box>{selected ? clicked : notClicked}</Box>
      <Typography className={classes.templateName}>{name}</Typography>
      <Box>{icon}</Box>
    </Box>
  );
};

//------------------------------------------------------------------------------\

export const SaveTemplateAs = () => {
  const classes = useStyles();
  const { selectedTemplate, handleSelectedTemplate } = useEditorContext();

  return (
    <Box className={classes.stpRoot}>
      <Typography className={classes.stpTitle} variant="h6">
        {'Save this template as'.toUpperCase()}
      </Typography>
      <Box className={classes.templates}>
        {templates.map((template) => (
          <TemplateItem
            key={template.value}
            name={template.name}
            icon={template.icon}
            clicked={template.clicked}
            notClicked={template.notClicked}
            selected={selectedTemplate === template.value}
            onSelect={() => handleSelectedTemplate(template.value)}
          />
        ))}
      </Box>
    </Box>
  );
};
