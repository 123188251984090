import React from 'react';
import SchedulePageProvider from 'pages/SchedulePage/state/SchedulePageProvider';
import PatientHome from './PatientHome';
import InboxPageProvider from 'pages/InboxPage/state/Provider';

const PatientDashboard = () => {
  return (
    <SchedulePageProvider>
      <InboxPageProvider>
        <PatientHome />
      </InboxPageProvider>
    </SchedulePageProvider>
  );
};

export default PatientDashboard;
