import {
  FormLabel,
  Box,
  FilledTextFieldProps,
  FormHelperText,
} from '@material-ui/core';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { Control, Ref, FieldErrors } from 'react-hook-form';
import clsx from 'clsx';
import useStyles from './styles';

interface IInboxInputProps extends Omit<FilledTextFieldProps, 'variant'> {
  files?: React.ChangeEvent<HTMLInputElement>[];
  handleAction?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isActionable?: boolean;
  name: string;
  register?: (ref: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  label?: string;
  iconClassName?: React.CSSProperties;
  LabelIcon?: any;
  type: string;
  options?: any[];
  renderOption?: any;
  renderTags?: any;
  multiple?: boolean;
  variant?: any;
  SelectProps?: any;
  onChange?: any;
  readOnly?: boolean;
  customInput?: any;
  className?: string;
}

export default function InboxInput({
  name,
  register,
  control,
  errors,
  LabelIcon,
  iconClassName,
  label,
  className,
  ...props
}: IInboxInputProps): JSX.Element {
  const classes = useStyles();
  return (
    <Box>
      {label && (
        <FormLabel className={clsx(classes.label)}>
          {LabelIcon && <LabelIcon className={iconClassName} />} {label || ''}
        </FormLabel>
      )}
      <Input
        inputRef={register}
        control={control}
        name={name}
        className={clsx(className, classes.input)}
        variant="filled"
        helperText={
          errors &&
          errors[name] && (
            <FormHelperText className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {errors[name]?.message}
            </FormHelperText>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </Box>
  );
}

InboxInput.defaultProps = {};
