import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CheckedIcon from 'assets/icons/checkedIcon';
import UnCheckedIcon from 'assets/icons/uncheckedIcon';

interface ICheckProps {
  checkedValue?: boolean;
  handleChange?: (row?: { _id: string }) => void;
  props?: any;
  color?: string;
  disabled?: boolean;
  onClick?: (e) => void;
}

const Checked = ({
  checkedValue,
  handleChange,
  props,
  color,
  onClick,
  disabled = false,
}: ICheckProps) => {
  return (
    <Checkbox
      checkedIcon={<CheckedIcon color={color && color} />}
      icon={<UnCheckedIcon color={color && color} />}
      checked={checkedValue}
      {...props}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'primary checkbox' }}
      data-cy="checkbox-input"
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default Checked;
