// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBsAaLpehr4_LzT8iBhRzGK5MVbCzTdLMA',
  authDomain: 'eggmed-app-f6e01.firebaseapp.com',
  databaseURL: 'https://eggmed-app-f6e01.firebaseio.com',
  projectId: 'eggmed-app-f6e01',
  storageBucket: 'eggmed-app-f6e01.appspot.com',
  messagingSenderId: '177973888563',
  appId: '1:177973888563:web:df2eb993dedafecde94546',
  measurementId: 'G-D2R235GC14',
};

export default firebase.initializeApp(firebaseConfig);

export const googleAuth = new firebase.auth.GoogleAuthProvider();
googleAuth.addScope('https://www.googleapis.com/auth/contacts.readonly');
