import { Box } from '@material-ui/core';
import PatientDashboard from 'pages/PatientDashboard';
import React, { ReactElement } from 'react';
import { IconProps } from './patientDashboard/AppointmentIcon';

const TrashIcon = ({
  fill = '#425364',
  width = '100%',
  height = '100%',
  className,
  style,
}: IconProps): ReactElement => {
  return (
    <Box width={width} height={height} className={className} style={style}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.59998H2.3H12.7"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4008 3.6V12.7C11.4008 13.0448 11.2638 13.3754 11.02 13.6192C10.7762 13.863 10.4456 14 10.1008 14H3.60078C3.256 14 2.92534 13.863 2.68154 13.6192C2.43775 13.3754 2.30078 13.0448 2.30078 12.7V3.6M4.25078 3.6V2.3C4.25078 1.95522 4.38775 1.62456 4.63154 1.38076C4.87534 1.13696 5.206 1 5.55078 1H8.15078C8.49556 1 8.82622 1.13696 9.07002 1.38076C9.31382 1.62456 9.45078 1.95522 9.45078 2.3V3.6"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.55078 6.84998V10.75"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.15039 6.84998V10.75"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default TrashIcon;
