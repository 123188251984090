import React, { FC } from 'react';
import { GetAutoCompleteSearchThreadConversation_getAutoCompleteSearchThreadConversation } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAutoCompleteSearchThreadConversation';
import { Avatar, Box, Typography } from '@material-ui/core';
import concatName from 'utils/userUtils';
import useStyles from './styles';

interface ISearchItemListCard {
  data: GetAutoCompleteSearchThreadConversation_getAutoCompleteSearchThreadConversation;
}
const SearchItemListCard: FC<ISearchItemListCard> = ({ data }) => {
  const classes = useStyles({ isPatient: false });
  const { creator, subject } = data;
  const { firstname, middlename, lastname, picture } = creator;
  return (
    <Box key={data._id} className={classes.searchItemListCardContainer}>
      <Avatar alt={firstname.toUpperCase()} src={picture} />
      <Box className={classes.noWarpText}>
        <Box className={classes.TitleItemConversation}>
          {concatName(firstname, middlename, lastname)}
        </Box>
        <Box>
          <Typography className={classes.secondaryTextSearchItemListCard}>
            {subject}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchItemListCard;
