import React from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as Participant } from 'assets/users-01.svg';
import { ReactComponent as Icon } from 'assets/video-recorder.svg';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import AddEventPage from 'pages/SchedulePage/AddEventPage/index';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Skeleton from 'pages/PatientGeneralInfo/../../components/ui/Skeleton';
import { useTranslation } from 'react-i18next';

const chipColor = {
  paid: {
    background: '#ECFDF3',
    color: '#067647',
    border: '1px solid #ABEFC6',
  },
  pending: {
    background: '#FFD8AD',
    color: '#AE6209',
    border: '1px solid #FFD8AD ',
  },
  overdue: {
    background: '#FFC6C5',
    color: '#D71300',
    border: '1px solid #FFC6C5 ',
  },
  waived: {
    background: 'rgba(86, 127, 189, 0.40)',
    color: '#567FBD',
    border: '1px solid rgba(86, 127, 189, 0.40) ',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    background: '#F4F3FF',
  },
};
const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(20, 122, 243, 0.49)',
    borderRadius: '16px',
  },
  header: {
    background:
      'linear-gradient(54deg, rgba(20, 122, 243, 0.22) -14.81%, rgba(63, 191, 127, 0.22) 121.69%)',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
  lower: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  chip: {
    borderRadius: '16px',
    background: ({ isPaid }: { isPaid: string }) =>
      chipColor[isPaid]?.background,
    border: ({ isPaid }: { isPaid: string }) => chipColor[isPaid]?.border,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 8px',
  },
  chipText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    color: ({ isPaid }: { isPaid: string }) => chipColor[isPaid]?.color,
    textTransform: 'capitalize',
  },
  date: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    color: '#101828',
  },
  session: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
}));
const ApptCard = ({ event, loading }) => {
  const { deleteAppointment } = useDeleteAppointment();
  const { t } = useTranslation();
  const { doctor } = useAuth();
  const {
    handleCloseEditMode,
    handleUpdateAppointment,
    handleAddAppointment,
    modalAddOpen,
    currentEvent,
    editMode,
    loadingAddAppointment,
    loadingEditAppointment,
    handleAddEvent,
    handleUpdateEvent,
    clickableEvent,
    loadingEditEvent,
    handleEditCurrentEvent,
  } = useProvider();
  const history = useHistory();
  const {
    startDate,
    locationLink,
    isPaid,
    patient,
    _id,
    doctor: doctorData,
  } = event || {};
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  function handleOpenDeleteModal() {
    setOpenDeleteModal(true);
  }
  const handelDelete = (id: string) => {
    deleteAppointment(id, true, null);
    setOpenDeleteModal(false);
  };
  const lowerAction = [
    {
      name: t('Cancel'),
      icon: null,
      action: () => {
        handleOpenDeleteModal();
      },
    },
    {
      name: t('Edit'),
      icon: null,
      action: () => {
        handleEditCurrentEvent(event);
      },
    },
    {
      name: t('Join'),
      icon: <Icon />,
      action: () => {
        history.push(`/doctor/patient/${patient._id}?event=${_id}&tab=2`);
      },
    },
  ];
  const classes = useStyles({ isPaid });

  return (
    <>
      <Box className={classes.container} mb={2}>
        <Box display="flex" className={classes.header} px={2} py={2}>
          <Skeleton loading={loading}>
            <Participant />
          </Skeleton>
          <Box display="flex" flexDirection="column" ml={1}>
            <Skeleton loading={loading}>
              <Typography className={classes.date}>
                {dayjs(startDate)
                  // .tz(doctorData?.timeZone)
                  .format('dddd D MMMM - h:mm A')}
              </Typography>
            </Skeleton>
            <Box display="flex" alignItems="center">
              <Skeleton loading={loading}>
                <Typography className={classes.session}>
                  {locationLink ? t('Online session') : t('In person session')}{' '}
                </Typography>
              </Skeleton>
              <Box ml={2} mt={1} className={classes.chip}>
                <Skeleton loading={loading}>
                  <Typography className={classes.chipText}>{isPaid}</Typography>
                </Skeleton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.lower}>
          {lowerAction?.map((el, key) => (
            <Box
              py={1}
              px={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              textAlign="center"
              style={{
                borderRight: key !== 2 && '1px solid rgba(20, 122, 243, 0.49)',
                cursor: 'pointer',
              }}
              onClick={el.action}
            >
              {el.icon && (
                <Skeleton loading={loading}>
                  <Box mr={1}>{el.icon}</Box>
                </Skeleton>
              )}
              <Skeleton loading={loading}>
                <Typography style={{ textAlign: 'center' }}>
                  {el.name}
                </Typography>
              </Skeleton>
            </Box>
          ))}
        </Box>
      </Box>
      <ModalDelete
        text={t('session')}
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => handelDelete(_id)}
      />
    </>
  );
};

export default ApptCard;
