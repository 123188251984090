import { makeStyles } from '@material-ui/core/styles';
import doctorBackground from 'assets/background/signupmedicalbackground.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    gap: '25px',
    marginBottom: '2rem',
  },
  topGrid: {
    marginInline: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '20px',
    height: '100%',
    maxHeight: '143px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '2rem',
  },
  bottomGrid: {
    marginInline: theme.spacing(2),
    borderRadius: '20px',
    backgroundColor: 'white',
    height: '100%',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBlock: '2rem',
    paddingInline: '18rem',
  },
  helpTitle: {
    fontFamily: 'Roboto',
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 400,
    color: '#273142',
  },
  helpIcon: {
    marginBottom: theme.spacing(5.5),
  },
  bottomGridContainer: {
    borderRadius: '11px',
    backgroundColor: '#F2F2F2',
    paddingBottom: '1.5rem',
    paddingInline: '4rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  arrowIcon: {
    cursor: 'pointer',
  },
  contentTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    color: '#273142',
    cursor: 'pointer',
  },
  helpText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#828282',
  },
}));

export default useStyles;
