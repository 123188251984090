import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import { ReactComponent as Email } from 'assets/patientActivities/email-icon.svg';

interface IFormEmail {
  register: any;
  control: any;
  errors: any;
  question: string;
  name: string;
  handleInputChange: any;
  questionIndex: number;
}

export const FormEmail = ({
  register,
  control,
  errors,
  question,
  name,
  handleInputChange,
  questionIndex,
}: IFormEmail) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <Input
        variant="filled"
        type="email"
        style={{
          marginBottom: '9px',
        }}
        inputProps={{
          style: {
            padding: '10px 14px',
            fontSize: '14px',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="email">
                <Email />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          handleInputChange(questionIndex, e.target.value);
        }}
        helperText={
          errors &&
          errors[name] && (
            <Box display="flex" alignItems="center" data-cy="error-message">
              {errors[name]?.message}
            </Box>
          )
        }
        error={errors && !!errors[name]}
      />
    </Box>
  );
};
