import React, { ReactElement, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from './style';
import { ReactComponent as UpArrowIcon } from 'assets/ArrowUp.svg';
import { ReactComponent as DownArrowIcon } from 'assets/ArrowDown.svg';

interface IHelpContentProps {
  title?: string;
  children?: ReactElement;
}

const HelpContent = ({ title, children }: IHelpContentProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Box width="100%" mt="2rem">
      <Box width="100%" className={classes.titleBox}>
        <Typography
          className={classes.contentTitle}
          onClick={() => setOpen(!open)}
        >
          {title}
        </Typography>
        {open ? (
          <UpArrowIcon
            className={classes.arrowIcon}
            onClick={() => setOpen(!open)}
          />
        ) : (
          <DownArrowIcon
            className={classes.arrowIcon}
            onClick={() => setOpen(!open)}
          />
        )}
      </Box>
      <Box width="100%" display={open ? 'block' : 'none'}>
        {children}
      </Box>
    </Box>
  );
};

export default HelpContent;
