export function handleEditFields(
  inputsCount: number[],
  fieldsStructure: any[],
  handleDelete: any
) {
  let fieldsKeysWithIndex: any = [];
  inputsCount.forEach((object: any, index: any) => {
    const fields = fieldsStructure.map((element: any) => {
      return {
        handleDelete,
        ...object,
        ...element,
        name: `${element.name}${object.formId}`,
      };
    });
    fieldsKeysWithIndex = [...fieldsKeysWithIndex, ...fields];
  });
  return fieldsKeysWithIndex;
}

export function changeKeyName(object: any, oldKey: string, index: number) {
  const newKey = `${oldKey}${index}`;
  if (oldKey !== newKey) {
    Object.defineProperty(
      object,
      newKey,
      Object.getOwnPropertyDescriptor(object, oldKey) || {}
    );
    delete object[oldKey];
  }
  return object;
}

export function handleFormatResult(
  inputsNumberArray: number[],
  values: any
): any[] {
  const keys = Object.keys(values);
  return inputsNumberArray.map((input: any, inputIndex: any) => {
    let newValueObject: any = {};
    keys.forEach((key) => {
      if (key.indexOf(input.formId) !== -1) {
        const keyName = key.split(input.formId)[0];
        newValueObject[keyName] = values[key];
      }
    });
    return newValueObject;
  });
}
export interface IChangeKeyValueProps {
  key: string;
  callback: (value: any) => any;
  object: any;
}
export function changeKeyValue({
  key,
  callback,
  object,
}: IChangeKeyValueProps): any {
  const objectClone = { ...object };
  objectClone[key] = callback(objectClone[key]);
  return objectClone;
}
export function isDescriptionEmpty(eventInfo) {
  if (!eventInfo) return true;
  const desc = JSON.parse(eventInfo);
  if (!desc.blocks || desc.blocks.length === 0) {
    return true;
  }
  if (desc.blocks.length === 1) {
    const firstBlock = desc.blocks[0];
    return !firstBlock.text.trim();
  }

  return false;
}

export const getTagColor = (tag: string) => {
  switch (tag) {
    case 'red':
      return {
        border: '1px solid #FECDCA',
        backgroundColor: '#FFFBFA',
        text: '#912018',
      };
    case 'blue':
      return {
        border: '1px solid #E0F2FE',
        backgroundColor: '#F0F9FF',
        text: '#004491',
      };
    case 'orange':
      return {
        border: '1px solid #FEF0C7',
        backgroundColor: '#FFFCF5',
        text: '#93370D',
      };
    case 'green': {
      return {
        border: '1px solid #DCFAE6',
        backgroundColor: '#F6FEF9',
        text: '#085D3A',
      };
    }
    case 'purple': {
      return {
        border: '1px solid #D0D5DD',
        backgroundColor: '#FBFAFF',
        text: '#2D31A6',
      };
    }
    default:
      return {
        border: '1px solid #CCCCCC',
        backgroundColor: '#FEFEFE',
        text: 'gray',
      };
  }
};
