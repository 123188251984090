import React from 'react';
import {
  Box,
  BoxProps,
  Avatar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/tasks/deleteIcon.svg';
import TrashIcon from 'assets/TrashIcon';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { formatToFullDate } from 'utils';
import DeleteHandler from 'components/DeleteHandler';
import AttachmentInput from './AttachmentInput';
import { config } from 'config';
interface IAttachmentPreviewProps extends BoxProps {
  url: string;
  type: string;
  pictureUrl: string;
  name: string;
  date: Date;
  fileName: string;
  handleDeleteTaskAttachment?: (
    taskId: string,
    attachmentUrl: string,
    closeModal: () => void
  ) => void;
  taskId?: string;
  closeModal: () => void;
}

const useStyles = makeStyles((theme) => ({
  authorName: {
    color: '#425364',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: theme.spacing(1),
  },
  fontBold: {
    fontWeight: 'bold',
  },
  picturePreview: {
    width: theme.typography.pxToRem(190),
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  downloadIcon: {
    color: '#BCC6D3',
    marginLeft: 'auto',
    cursor: 'pointer',
    width: '23px',
    height: '23px',
  },
}));

export default function AttachmentPreview({
  url,
  type,
  pictureUrl,
  name,
  date,
  fileName,
  taskId,
  handleDeleteTaskAttachment,
  closeModal,
  ...props
}: IAttachmentPreviewProps) {
  const classes = useStyles();
  function handleDownload() {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.target = '_blank';
    link.click();
  }
  const newUrl = url?.replace(config.S3URL, '');

  return (
    <Box position="relative" {...props}>
      <Box display="flex" alignItems="center">
        <Avatar src={pictureUrl}>{name[0].toUpperCase()}</Avatar>
        <Typography className={clsx(classes.fontBold, classes.authorName)}>
          {name}
        </Typography>
        <Typography className={classes.authorName}>
          {formatToFullDate(date.toDateString())}
        </Typography>
        <Box marginLeft="auto" display="flex" alignItems="center">
          <DeleteHandler
            value={{ _id: taskId || '' }}
            style={{
              padding: '0px',
              marginRight: '10px',
              position: 'relative',
              top: '0px',
            }}
            handleAction={(id: string, _, handleClose) => {
              handleDeleteTaskAttachment?.(id, url, handleClose || (() => {}));
              closeModal();
            }}
            DeleteIcon={<TrashIcon fill="#BCC6D3" width="23px" height="23px" />}
            handleDelete={() => {}}
          />
          <GetAppIcon
            onClick={handleDownload}
            className={classes.downloadIcon}
          />
        </Box>
      </Box>
      {type.split('/')[0] === 'image' ? (
        <img
          src={
            newUrl && !newUrl.startsWith(config.S3URL)
              ? config.S3URL + newUrl
              : newUrl
          }
          alt="attachment-preview"
          className={classes.picturePreview}
        />
      ) : (
        <Box ml={5}>
          <AttachmentInput my={2} name={fileName} createdAt={new Date(date)} />
        </Box>
      )}
    </Box>
  );
}
