import React, { FC } from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import IconButton from 'components/ui/IconButton';
import { ReactComponent as PdfIcon } from 'assets/inbox/pdf.svg';
import { ReactComponent as DocIcon } from 'assets/inbox/word.svg';
import { ReactComponent as FileIcon } from 'assets/inbox/file.svg';
import { ReactComponent as Mp3Icon } from 'assets/inbox/mp3.svg';
import { ReactComponent as PptIcon } from 'assets/inbox/ppt.svg';
import { ReactComponent as XlsxIcon } from 'assets/inbox/xlsx.svg';
import { ReactComponent as ZipIcon } from 'assets/inbox/zip.svg';
import { ReactComponent as TextIcon } from 'assets/inbox/text.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/green/downloadIcon.svg';
import {
  getFilesByThreadConversation_getFilesByThreadConversation,
  getFilesByThreadConversation_getFilesByThreadConversation_content_files,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getFilesByThreadConversation';
import { ReactComponent as ReturnIcon } from 'assets/returnArrow.svg';

import useStyles from './style';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyFiles } from 'assets/emptyState/files.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { _file } from './utils';

interface IFileList {
  data: getFilesByThreadConversation_getFilesByThreadConversation[];
  setFilesHidden: (value: boolean) => void;
}
interface IFileItem {
  data: any;
}

export const getIconByMimeTypeAndUrl = (mimeType: string, url?: string) => {
  switch (true) {
    case _file.isPdf(mimeType):
      return <PdfIcon />;
    case _file.isImage(mimeType):
      return url ? (
        <img
          src={url}
          alt="image"
          style={{ width: 40, height: 40, borderRadius: '4px' }}
        />
      ) : (
        <FileIcon />
      );
    case _file.isPowerPoint(mimeType):
      return <PptIcon />;

    case _file.isExcel(mimeType):
      return <XlsxIcon />;

    case _file.isMp3(mimeType):
      return <Mp3Icon />;

    case _file.isTxt(mimeType):
      return <TextIcon />;

    case _file.isZip(mimeType):
      return <ZipIcon />;

    case _file.isWord(mimeType):
      return <DocIcon />;

    default:
      return <FileIcon />;
  }
};

const FileList: FC<IFileList> = ({ data, setFilesHidden }) => {
  const { t } = useTranslation();
  const classes = useStyles({ patient: false });
  const FilesList = (
    data: getFilesByThreadConversation_getFilesByThreadConversation[]
  ) => {
    let filesArray = [];
    data.map(
      (item: getFilesByThreadConversation_getFilesByThreadConversation) => {
        item.content.files.map(
          (
            file: getFilesByThreadConversation_getFilesByThreadConversation_content_files
          ) => {
            filesArray.push({
              ...item,
              content: {
                ...item.content,
                files: file,
              },
            });
          }
        );
      }
    );
    return filesArray;
  };

  const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const FileItem = ({ data }: IFileItem) => {
    const { content } = data;
    const { files } = content;
    const { fileName, mimeType, url } = files;
    return (
      <ListItem style={{ gap: '10px' }}>
        <ListItemIcon className={classes.icon}>
          {getIconByMimeTypeAndUrl(mimeType, url)}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography className={classes.fileNameText}>{fileName}</Typography>
          }
          secondary={
            <Typography className={classes.fileDescriptionText}>
              {mimeType}, 9 Mb
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => onDownload(url, fileName)}>
            <DownloadIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  return (
    <div style={{ height: '100%', maxHeight: '100%' }}>
      <div className={classes.headerFiles}>
        <div onClick={() => setFilesHidden(true)}>
          <ReturnIcon className={classes.fileReturnIcon} />
        </div>
        <Text i18nKey="files" className={classes.boldText}>
          Files
        </Text>
      </div>
      <Divider />
      <List style={{ maxHeight: 'calc(100% - 90px)', overflow: 'auto' }}>
        {FilesList(data).length === 0 && (
          <Box pt="15rem" px={2}>
            <EmptyState
              icon={<EmptyFiles />}
              title={t('No files')}
              text={t('Files that you exchange will appear here')}
              noActionText={true}
              hasAction={false}
            />
          </Box>
        )}
        {FilesList(data).map(
          (file: getFilesByThreadConversation_getFilesByThreadConversation) => (
            <FileItem data={file} key={file._id} />
          )
        )}
      </List>
    </div>
  );
};

export default FileList;
