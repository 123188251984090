import React from 'react';
import { Button, ButtonTypeMap, ExtendButtonBase } from '@material-ui/core';
import Edit from 'assets/icons/edit.svg';

export default function EditButton({
  ...props
}: ExtendButtonBase<ButtonTypeMap<any, 'button'>>): JSX.Element {
  return (
    <Button
      style={{
        backgroundColor: 'white',
        color: '#425364',
        // cursor: 'pointer',
        width: '74px',
        // borderRadius:"12px",
        offset: '0px, 2px',
        boxShadow: 'rgba(37, 51, 66, 0.15)',
      }}
      {...props}
      variant="contained"
    >
      <img
        src={Edit}
        width="15px"
        height="25px"
        style={{ paddingRight: '5px' }}
        alt="edit-icon"
      />{' '}
      Edit
    </Button>
  );
}
