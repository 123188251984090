import { FetchResult, gql, useSubscription } from '@apollo/client';

import { log } from '../../utils/log';

// TODO: move to utils
import { filterPeerConnection } from 'pages/MeetingRoomPage/state/utils';
// import PeerConnection from 'utils/RTC/PeerConnection';
import useSendRtcAnswer from './useSendRtcAnswer';
import useSendRtcIceCandidate from './useSendRtcIceCandidate';
import useSendRtcOffer from './useSendRtcOffer';

const ANSWER_SUBSCRIPTION = gql`
  subscription OnAnswerReceived($roomId: String!) {
    answerReceived(roomId: $roomId) {
      sender
      answer {
        type
        sdp
      }
    }
  }
`;
const OFFER_SUBSCRIPTION = gql`
  subscription OnOfferReceived($roomId: String!) {
    offerReceived(roomId: $roomId) {
      sender
      offer {
        type
        sdp
      }
    }
  }
`;
const ICE_CANDIDATE_SUBSCRIPTION = gql`
  subscription OnIceCandidateReceived($roomId: String!) {
    iceCandidateReceived(roomId: $roomId) {
      sender
      iceCandidate {
        candidate
        sdpMid
        sdpMLineIndex
      }
    }
  }
`;

const NEW_USER_ON_THE_VIDEO_ROOM_SUBSCRIPTION = gql`
  subscription OnNewUserOnTheVideoRoom($roomId: String!, $userId: String!) {
    newUserOnTheVideoRoom(roomId: $roomId, userId: $userId) {
      userId
      roomId
      # userData {
      #   firstname
      #   lastname
      # }
    }
  }
`;

interface IUseSignalingArgs {
  peerSet: Set<any>;
  roomId: string;
  createNewPeerConnection: (
    userId: string,
    isFromSubscription: boolean
  ) => any | undefined;
  polite: boolean;
  userId: string;
  unMountConnections?: any;
  setRemoteUserData: (userData: {
    firstname: string;
    lastname: string;
  }) => void;
}

interface IUseSignalingReturn {
  handleSendRtcOffer: (
    offer: any,
    recipientId: string,
    roomId: string
  ) => Promise<
    FetchResult<unknown, Record<string, any>, Record<string, any>> | undefined
  >;
  handleSendRtcIceCandidate: (
    candidate: any,
    sdpMid: any,
    sdpMLineIndex: any,
    recipientId: string,
    roomId: string
  ) => Promise<
    FetchResult<unknown, Record<string, any>, Record<string, any>> | undefined
  >;
  handleSendRtcAnswer: (
    answer: any,
    recipientId: string,
    roomId: string
  ) => Promise<unknown>;
}

export const useSignaling = ({
  peerSet,
  roomId,
  createNewPeerConnection,
  userId,
  polite,
  unMountConnections,
  setRemoteUserData,
}: IUseSignalingArgs): IUseSignalingReturn => {
  const handleSendRtcAnswer = useSendRtcAnswer();
  const handleSendRtcIceCandidate: any = useSendRtcIceCandidate();
  const handleSendRtcOffer: any = useSendRtcOffer();
  useSubscription(NEW_USER_ON_THE_VIDEO_ROOM_SUBSCRIPTION, {
    variables: { roomId, userId },
    onSubscriptionData: (data) => {
      if (!data.subscriptionData.data.newUserOnTheVideoRoom) return;
      const { userId: newUserId } =
        data.subscriptionData.data.newUserOnTheVideoRoom || {};
      // setRemoteUserData(userData); // This one implimenting a bug so much rendering ( Fix IT in the future)
      const currentPeer = filterPeerConnection(peerSet, newUserId);
      if (currentPeer) {
        return;
      }
      unMountConnections();
      if (!polite) {
        setTimeout(() => createNewPeerConnection(newUserId, true), 2000);
      } else {
        createNewPeerConnection(newUserId, true);
      }
    },
  });
  useSubscription(ANSWER_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      const senderId = data?.subscriptionData?.data?.answerReceived?.sender;
      const answer = data?.subscriptionData?.data?.answerReceived?.answer;
      const currentPeer = filterPeerConnection(peerSet, senderId);
      if (!currentPeer) return;
      currentPeer.setRemoteAnswerStatus(true);
      currentPeer?.setRemoteDescription(answer);
    },
  });
  useSubscription(OFFER_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      const senderId = data.subscriptionData.data.offerReceived.sender;
      const { offer } = data.subscriptionData.data.offerReceived;
      // Get the peerConnection instance
      const currentPeer = filterPeerConnection(peerSet, senderId);
      if (!currentPeer) return;
      // Perfect negotiation Conditions
      const readyForOffer =
        !currentPeer.getOfferStatus() &&
        (currentPeer.getSignalingState() === 'stable' ||
          currentPeer.getRemoteAnswerStatus());
      const offerCollision = !readyForOffer;
      const ignoreOffer = !polite && offerCollision;

      if (ignoreOffer) return;

      currentPeer.setRemoteDescription(offer);
      currentPeer.createAnswer();
    },
  });
  useSubscription(ICE_CANDIDATE_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      const currentPeer = filterPeerConnection(
        peerSet,
        data.subscriptionData.data.iceCandidateReceived.sender
      );
      if (!currentPeer) return;
      log(
        'Ice candidate',
        data.subscriptionData.data.iceCandidateReceived.iceCandidate
      );
      currentPeer.addIceCandidate(
        data.subscriptionData.data.iceCandidateReceived.iceCandidate
      );
    },
  });
  return { handleSendRtcOffer, handleSendRtcIceCandidate, handleSendRtcAnswer };
};

export default useSignaling;
