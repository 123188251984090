import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import { Autocomplete } from '@material-ui/lab';
import ReactCountryFlag from 'react-country-flag';
import { FormInput } from 'components/ui/Inputs/FormInput';
import country from 'utils/countries';
import { useStyles } from '../styles';

interface IFormAddress {
  response?: any;
  register?: any;
  errors?: any;
  control?: any;
  question?: string;
  handleAddress?: any;
  questionIndex?: number;
  name?: string;
}

export const FormAddress = ({
  register,
  control,
  question,
  handleAddress,
  questionIndex,
  name,
  response,
}: IFormAddress) => {
  const { address } = response || {};
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>

      <Box className={classes.wrapperBox}>
        <Typography className={classes.titleStyle}>Address</Typography>
        <Controller
          control={control}
          name="address1"
          render={({ onChange, value }) => (
            <Input
              variant="filled"
              inputRef={register}
              type="text"
              name="address"
              style={{
                maxWidth: '100%',
                marginBottom: '9px',
                gap: '10px',
              }}
              inputProps={{
                style: {
                  padding: '10px 14px',
                  fontSize: '16px',
                  width: '100%',
                  lineHeight: '20px',
                  fontWeight: 400,
                },
              }}
              onChange={(e) =>
                handleAddress(questionIndex, e.target.value, e.target.name)
              }
              value={address?.address}
            />
          )}
        />
      </Box>

      <Box className={classes.wrapperBoxCountry}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Typography className={classes.titleStyle}>City</Typography>
          <Controller
            control={control}
            name="city"
            render={({ onChange, value }) => (
              <Input
                variant="filled"
                inputRef={register}
                type="text"
                name="city"
                style={{
                  maxWidth: '100%',
                  marginBottom: '9px',
                }}
                inputProps={{
                  style: {
                    padding: '10px 14px',
                    fontSize: '16px',
                    width: '100%',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleAddress(questionIndex, e.target.value, e.target.name);
                }}
                value={address?.city}
              />
            )}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Typography className={classes.titleStyle}>State</Typography>

          <Input
            variant="filled"
            inputRef={register}
            type="text"
            name="state"
            style={{
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '16px',
                width: '100%',
                lineHeight: '20px',
                fontWeight: 400,
              },
            }}
            onChange={(e) =>
              handleAddress(questionIndex, e.target.value, e.target.name)
            }
            value={address?.state}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          gap: '6px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Typography className={classes.titleStyle}>ZIP Code</Typography>
          <Controller
            control={control}
            name="zipCode"
            render={({ onChange, value }) => (
              <Input
                variant="filled"
                inputRef={register}
                type="text"
                name="zipCode"
                style={{
                  height: '40px',
                  maxWidth: '100%',
                  marginBottom: '9px',
                }}
                inputProps={{
                  style: {
                    padding: '10px 14px',
                    fontSize: '16px',
                    width: '100%',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleAddress(questionIndex, e.target.value, e.target.name);
                }}
                value={address?.zipCode}
              />
            )}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Typography className={classes.titleStyle}>Country</Typography>

          {response ? (
            <Input
              variant="filled"
              type="text"
              name="country"
              style={{ height: '40px' }}
              onBlur={(e) =>
                handleAddress(questionIndex, e.target.value, e.target.name)
              }
              inputProps={{
                style: {
                  padding: '10px 14px',
                  fontSize: '16px',
                  width: '100%',
                  lineHeight: '20px',
                  fontWeight: 400,
                },
              }}
              value={address?.country?.label}
            />
          ) : (
            <Controller
              control={control}
              name="country"
              render={({ onChange, value }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.label}
                  id="country-autocomplete"
                  options={country}
                  value={value || response?.country}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleAddress(questionIndex, newValue, 'country');
                  }}
                  renderOption={(option) => (
                    <>
                      <ReactCountryFlag
                        countryCode={option.code}
                        svg
                        style={{ paddingRight: '10px' }}
                      />
                      {option.label} ({option.code})
                    </>
                  )}
                  renderInput={(params) => (
                    <FormInput placeholder="" {...params} variant="filled" />
                  )}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
