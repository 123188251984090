import React from 'react';
import BasicModal from './index';
import { ReactComponent as CheckValidationIcon } from 'assets/CheckValidationIcon.svg';
import { makeStyles } from '@material-ui/core';

interface IModal {
  open: boolean;
  handleClose: () => void;
}
const useStyles = makeStyles({
  succesfullIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
  },
});
const SuccessModal = ({ open, handleClose }: IModal) => {
  const classes = useStyles();
  return (
    <BasicModal open={open} onClose={handleClose}>
      <CheckValidationIcon className={classes.succesfullIcon} />
    </BasicModal>
  );
};

export default SuccessModal;
