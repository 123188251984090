import { useMutation, gql, useQuery } from '@apollo/client';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ProgressLoader from 'components/ui/ProgressLoader';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_DOCTOR } from 'pages/DoctorProfilePage/graphql';
import React, { useState } from 'react';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import SignatureCanvas from 'react-signature-canvas';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { ReactComponent as Trash } from 'assets/ClearTrash.svg';
import dayjs from 'dayjs';
import { TranslationKeys } from 'shared/interfaces/i18';
import { useSnackbar } from 'hooks/useSnackbar';
import { InfoBlock } from './InfoBlock';
import { BlockNames } from './PersonalInformation';
import { GET_PATIENT } from 'pages/PatientProfilePage';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

const useStyles = makeStyles((theme) => ({
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  topsection: {
    backgroundColor: '#F5FBFF',
    paddingBlock: '16px',
    borderBottom: '1px solid #D0D5DD',
  },
  rootRadio: {
    maxWidth: '22px',
    marginLeft: '-8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconPatient: {
    cursor: 'pointer',
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  editTxt: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#101828',
    textTransform: 'capitalize',
    textUnderlineOffset: '2px',
  },
  radioLabel: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#475467',
  },
  label: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#344054',
  },
  clearTxt: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#B42318',
  },
  paragraph: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  date: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    // marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(1),
  },
  content: {
    color: '#D92D20',
  },
}));

const PracticeInformation = ({
  isPatient = false,
}: {
  isPatient?: boolean;
}) => {
  const [editableBlock, setEditableBlock] = useState<BlockNames | null>(null);
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  const { doctor, patient } = useAuth();
  const [editMode, setEditMode] = React.useState(false);
  const { triggerSnack } = useSnackbar();

  const [signature, { loading }] = useMutation(SIGNATURE);
  const refetchQueries = isPatient
    ? [{ query: GET_PATIENT, variables: { patientId: patient?._id } }]
    : [{ query: GET_DOCTOR, variables: { DoctorId: doctor?._id } }];
  const [setSignature, { loading: loadingSignature }] = useMutation(
    SET_SIGNATURE,
    {
      refetchQueries,
    }
  );
  const variables = isPatient
    ? { patientId: patient?._id }
    : { DoctorId: doctor?._id };
  const { data, loading: loadingQuery } = useQuery(
    isPatient ? GET_PATIENT : GET_DOCTOR,
    {
      variables,
    }
  );
  const dataUser = isPatient ? data?.patient : data?.doctor;
  const methods = useForm({
    defaultValues: {
      name: editMode
        ? dataUser?.signature?.name
        : isPatient
        ? patient?.firstname + ' ' + patient?.lastname
        : doctor?.firstname + ' ' + doctor?.lastname,
    },
  });
  const { register, control, errors, watch, reset, getValues } = methods;
  const commonProps = { register, control, errors };
  React.useEffect(() => {
    if (dataUser?.signature?.name) {
      reset({
        name: editMode
          ? dataUser?.signature?.name
          : isPatient
          ? patient?.firstname + ' ' + patient?.lastname
          : doctor?.firstname + ' ' + doctor?.lastname,
      });
    }
  }, [dataUser?.signature?.name, reset, editMode]);
  const [signatures, setSignatures] = React.useState([]);
  const [selectedSignature, setSelectedSignature] = React.useState(0);
  async function handleGenerate() {
    if (watch()?.name === '') {
      setSignatures([]);
      setSelectedSignature(0);
      return;
    }
    const sign = await signature({
      variables: {
        name: watch()?.name,
      },
    });
    setSignatures(sign.data.generateSignature);
  }
  const handleSignatureSelect = (index) => {
    setSelectedSignature(index);
  };
  const handleUpload = async (data) => {
    if (selectedSignature === null) {
      return;
    }
    const selectedSVG = signatures[selectedSignature];
    const blob = new Blob([selectedSVG], { type: 'image/svg+xml' });
    const file = new File([blob], 'signature.svg', { type: 'image/svg+xml' });
    try {
      if (selectedSVG) {
        await setSignature({
          variables: {
            signatureInput: {
              picture: file,
              isGenerated: true,
              name: data?.name,
              index: selectedSignature,
            },
          },
        });
        triggerSnack();
        handleClose();
      }
    } catch (error) {
      console.error('Error uploading signature:', error);
    }
  };

  const sigCanvasRef = React.useRef(null);
  const saveSignature = async () => {
    sigCanvasRef.current.getCanvas().toBlob(async (blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      try {
        await setSignature({
          variables: {
            signatureInput: {
              picture: file,
              isGenerated: false,
              name: undefined,
              index: undefined,
            },
          },
        });
        triggerSnack();
        handleClose();
      } catch (error) {
        console.error('Error uploading signature:', error);
      }
    });
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    handleGenerate();
  }, [watch()?.name, open]);
  React.useEffect(() => {
    if (dataUser?.signature?.index) {
      setSelectedSignature(dataUser?.signature?.index);
    }
  }, [dataUser?.signature?.index, open]);
  function handleClose() {
    setOpen(false);
    setEditMode(false);
    setSelectedSignature(0);
    setEditableBlock(null);
  }
  const [checked, setChecked] = React.useState(0);
  React.useEffect(() => {
    if (dataUser?.signature?.isGenerated) {
      setChecked(0);
    } else {
      setChecked(1);
    }
  }, [dataUser?.signature?.isGenerated]);
  function toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (open && sigCanvasRef.current && dataUser?.signature?.picture) {
        toDataURL(data?.doctor?.signature?.picture).then((dataUrl) => {
          sigCanvasRef.current.clear();
          sigCanvasRef.current.fromDataURL(dataUrl);
        });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [open, dataUser?.signature?.picture]);
  React.useEffect(() => {
    if (
      checked === 1 &&
      sigCanvasRef.current &&
      dataUser?.signature?.picture &&
      !dataUser?.signature?.isGenerated
    ) {
      sigCanvasRef.current.clear();
      sigCanvasRef.current.fromDataURL(dataUser.signature.picture);
    }
  }, [checked, data?.doctor?.signature?.picture]);
  function handleChange(event) {
    setChecked(Number(event.target.value));
  }
  const options = [
    {
      name: 'Generate',
      value: 0,
    },
    {
      name: 'Draw',
      value: 1,
    },
  ];

  function submit(data) {
    if (checked === 0) {
      handleUpload(data);
    } else {
      saveSignature();
    }
  }

  const updatedAt = `${t('Updated')} ${dayjs(
    dataUser?.signature?.updatedAt
  ).format(i18n.language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY')}`;
  const showContent = dataUser?.signature?.picture && !loadingQuery;

  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
        {!isPatient && (
          <Skeleton loading={loadingQuery}>
            <Text
              i18nKey="Practice information"
              style={{
                fontFamily: 'Inter, sans-serif',
                fontStyle: 'normal',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '20px',
                color: '#344054',
              }}
            >
              Practice information
            </Text>
          </Skeleton>
        )}
      </Grid>
      <Grid item xs={12} xl={5} lg={7} md={12} sm={12}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)}>
            <InfoBlock
              title={'Signature'}
              content={showContent ? updatedAt : t('No signature recorded')}
              contentClassName={!showContent && classes.content}
              description={t(
                "The signature you'll use for forms and contracts"
              )}
              blockName={BlockNames.signature}
              isEditable={editableBlock === BlockNames.signature}
              editableBlock={editableBlock}
              setEditableBlock={setEditableBlock}
              loading={loadingQuery}
              showAddButtonInitially={!showContent}
              buttonClick={() => {
                setEditMode(showContent ? true : false);
                setOpen(true);
              }}
              disabledButton={loadingSignature}
            >
              <Box
                sx={{
                  width: '100%',
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box className={classes.topsection}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px={'2rem'}
                    width="50%"
                  >
                    {options.map((el, key) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                      >
                        <Radio
                          className={classes.rootRadio}
                          color="default"
                          disableRipple
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.iconRadio,
                                classes.checkedIconRadio
                              )}
                            />
                          }
                          icon={<span className={classes.iconRadio} />}
                          checked={checked === el.value}
                          onChange={handleChange}
                          value={el.value}
                          data-cy="male-input"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                        <Typography
                          className={classes.radioLabel}
                          style={{
                            fontWeight: checked === el?.value ? 700 : 500,
                          }}
                        >
                          {' '}
                          {t(`${el?.name as TranslationKeys}`)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  {checked === 0 && (
                    <Box px="4.1rem">
                      <Text i18nKey="Your name" className={classes.label}>
                        Your name
                      </Text>
                      <InputBlock
                        {...commonProps}
                        name="name"
                        type="text"
                        style={{ width: '50%' }}
                        onChange={() => setSelectedSignature(0)}
                      />
                    </Box>
                  )}
                </Box>
                <Box mt={4} mb={2} px={checked === 0 ? '4.1rem' : '2rem'}>
                  {checked === 0 && (
                    <Box>
                      <Text
                        i18nKey="Pick preferred option"
                        className={classes.label}
                      >
                        Pick preferred option
                      </Text>
                      <div style={{ width: '100%' }}>
                        {signatures?.length > 0 && !loading
                          ? signatures.map((signature, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Radio
                                  className={classes.rootRadio}
                                  color="default"
                                  disableRipple
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.iconRadio,
                                        classes.checkedIconRadio
                                      )}
                                    />
                                  }
                                  icon={<span className={classes.iconRadio} />}
                                  checked={selectedSignature === index}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    handleSignatureSelect(index);
                                  }}
                                  data-cy="male-input"
                                  inputProps={{ 'aria-label': 'A' }}
                                />
                                <img
                                  src={`data:image/svg+xml;base64,${btoa(
                                    signature
                                  )}`}
                                  alt={`Signature ${index + 1}`}
                                  width="30%"
                                  height="30%"
                                  style={{ marginTop: '15px' }}
                                />
                              </div>
                            ))
                          : loading && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '50px',
                                  height: '50px',
                                }}
                              >
                                <ProgressLoader
                                  width={20}
                                  height={20}
                                  color="blue"
                                />
                              </div>
                            )}
                      </div>
                    </Box>
                  )}
                  {checked === 1 && (
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width={500}
                        mb={1}
                      >
                        <Text
                          i18nKey="Draw your signature"
                          className={classes.label}
                        >
                          Draw your signature
                        </Text>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => sigCanvasRef.current.clear()}
                        >
                          <Trash style={{ marginRight: '5px' }} />
                          <Text i18nKey="Clear" className={classes.clearTxt}>
                            Clear
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          border: '1px solid black',
                          width: 500,
                          height: 200,
                        }}
                      >
                        <SignatureCanvas
                          ref={sigCanvasRef}
                          penColor="black"
                          canvasProps={{
                            width: 500,
                            height: 200,
                            className: 'sigCanvas',
                          }}
                        />{' '}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box mt="auto" pb={2} px={'2rem'}>
                  <Text i18nKey="SignatureNotice" className={classes.paragraph}>
                    By clicking Create, I agree that the signature will be the
                    electronic representation of my signature for all purposes
                    when I use them on forms, including legally binding
                    contracts.{' '}
                  </Text>
                </Box>
              </Box>
            </InfoBlock>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

const SIGNATURE = gql`
  mutation generateSignature($name: String) {
    generateSignature(name: $name)
  }
`;

const SET_SIGNATURE = gql`
  mutation setSignature($signatureInput: SignatureInput) {
    setSignature(signatureInput: $signatureInput)
  }
`;

export default PracticeInformation;
