export function generateArrayOfYears() {
  const min = new Date().getFullYear();
  const max = min + 10;
  const years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString());
  }
  return years;
}
export function generateArrayOfYearsEnd() {
  const min = new Date().getFullYear();
  const max = min + 10;
  const years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString().slice(2));
  }
  return years;
}
export const months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
