import { gql, useMutation } from '@apollo/client';
import { log } from '../../utils/log';

const LEAVE_ROOM_MUTATION = gql`
  mutation leaveVideoRoom($patientId: String, $roomId: String!) {
    leaveVideoRoom(patientId: $patientId, roomId: $roomId)
  }
`;

const useLeaveVideoRoom = () => {
  const [leaveVideoRoom, result] = useMutation<unknown, any>(
    LEAVE_ROOM_MUTATION
  );

  async function handleLeaveVideoRoom(patientId: string, roomId: string) {
    try {
      return await leaveVideoRoom({
        variables: {
          patientId,
          roomId,
        },
      });
    } catch (e) {
      return log('error', e);
    }
  }

  return { handleLeaveVideoRoom, ...result };
};

export default useLeaveVideoRoom;
