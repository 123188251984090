import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { QuestionTypeEnum } from './Constants';
import { useHistory, useLocation } from 'react-router-dom';
import FormGenerator from './FormGenerator';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import FormPreview from './FormPreview';
import Steps from 'pages/PatientActivities/commun/Steps';
import BasicModal from 'components/ui/Modal';
import AssignModal from 'pages/PatientActivities/Questionaires/AssignModal';
import { useToast } from 'hooks/useToast';
import useAssignQuestionaire from 'pages/PatientActivities/Questionaires/useAssignQuestionaire';
import SaveModal from './SaveModal';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import useEditDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditDoctorTemplate';
import useEditQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditQuestionnaire';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import TopSection from './TopSection';
import NotesTopSection from './NotesTopSection';
import ScrollBar from 'components/ui/ScrollBar';
import EditButtons from 'components/EditButtons';
import { useStyles } from './styles';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

export enum SaveOptions {
  Update = 'Update the existing form',
  Save = 'Save as a new form',
}
export interface IQuestion {
  _id?: string;
  __typename?: string;
  question: string;
  type: QuestionTypeEnum | string;
  options: string[];
  description?: string;
  required: boolean;
  isDescription?: boolean;
  range: number[];
  responses?: any;
}

function FormsPage({
  isNotes,
  forms,
  handleClose,
  settings,
  editMode = true,
}: {
  isNotes?: boolean;
  forms?: any;
  handleClose?: () => void;
  settings?: boolean;
  editMode?: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const question: IQuestion = {
    question: t('Untitled question'),
    type: isNotes ? QuestionTypeEnum.TextBox : QuestionTypeEnum.ShortAnswer,
    options: ['Option 1', 'Option 2'],
    description: '',
    required: false,
    isDescription: false,
    range: [1, 3],
  };
  const [step, setStep] = React.useState(0);
  const [questions, setQuestions] = React.useState<IQuestion[]>([question]);
  const [assignOpen, setAssignOpen] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);
  const [initialQuestions, setInitialQuestions] =
    React.useState<IQuestion[]>(questions);
  const { triggerToast } = useToast();
  const [title, setTitle] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState<SaveOptions>(
    SaveOptions.Update
  );
  const [viewMode, setViewMode] = React.useState(false);
  const [previewMode, setPreviewMode] = React.useState(false);
  const { triggerSnack } = useSnackbar();
  const linkProps = forms;
  React.useEffect(() => {
    if (linkProps) {
      setTitle(linkProps.formName);
      setDesc(linkProps.formDescription);
      if (!!linkProps.formQuestions) {
        const dataForm = JSON.parse(linkProps.blocks)?.map((el) => {
          if (el?.data?.questions?.length > 0) {
            return { ...el?.data?.questions[0] };
          } else {
            if (el.type === 'paragraph') {
              return {
                question: el?.data?.text,
                range: [1, 2],
                type: el?.type,
              };
            }
            if (el?.type === 'table') {
              return {
                question: el?.data?.content,
                range: [1, 2],
                type: el?.type,
              };
            }
            if (el?.type === 'list') {
              return {
                question: el?.data?.items,
                range: [1, 2],
                type: el?.type,
              };
            }
            if (el?.type === 'Orderedlist') {
              return {
                question: el?.data?.items,
                range: [1, 2],
                type: el?.type,
              };
            }
          }
        });
        setQuestions(dataForm);
        setInitialQuestions(dataForm);
      }
      if (linkProps.previewMode) {
        setStep(1);
        setPreviewMode(true);
      }
      if (linkProps.fiewMode) {
        setViewMode(true);
      }
    }
  }, [linkProps]);

  const { handleAssignQuestionaire } = useAssignQuestionaire();
  const history = useHistory();
  const handleNext = () => {
    setStep((step) => step + 1);
  };
  // const settingsHandleBack = () => {
  //   const settings = linkProps.settings;
  //   if (isNotes && settings) {
  //     history.push('/doctor/admin', { tab: 7, subTab: 0 });
  //   }
  //   if (!isNotes && settings) {
  //     history.push('/doctor/admin', { tab: 7, subTab: 1 });
  //   }
  //   if (isNotes && !settings) {
  //     history.push(
  //       `/doctor/patient/${linkProps.patientId}?event=${linkProps.appointmentId}`
  //     );
  //   }
  //   if (!isNotes && !settings) {
  //     history.push(`/doctor/patient/${linkProps.patientId}`, {
  //       tab: 1,
  //     });
  //   }
  // };
  const handleBack = () => {
    previewMode && handleClose();
    step > 0 ? setStep((step) => step - 1) : handleClose();
    //  settingsHandleBack();
  };
  const handleAssign = () => {
    setAssignOpen(false);
    history.push(`/doctor/patient/${linkProps.patientId}`, {
      tab: 1,
    });
  };
  const handleSubmit = async () => {
    await handleAssignQuestionaire(
      questions,
      linkProps.doctorId,
      linkProps.patientId,
      title,
      desc
    );
    triggerSnack();
    setTimeout(() => handleAssign(), 500);
  };
  const headerTitleDispatcher = useHeaderPageNameDispatch();

  React.useEffect(() => {
    headerTitleDispatcher?.({
      type: 'setTitle',
      payload: isNotes ? 'Appointments' : 'Forms',
    });

    if (!!!linkProps?.formId) {
      setSelectedValue(SaveOptions.Save);
    }
  }, [headerTitleDispatcher, linkProps?.formId]);

  const [handleCreateDoctorTemplate] = useCreateDoctorTemplate();
  const [handleEditDoctorTemplate] = useEditDoctorTemplate();
  const [handleEditQuestionnaire] = useEditQuestionnaire();
  const handleSaveDoctorTemplate = async () => {
    const editQuestionnaire = !!linkProps.editQuestionnaire;
    const questionPayload = questions.map(
      ({
        question,
        type,
        options,
        description,
        required,
        range,
        isDescription,
      }) => {
        return {
          question,
          type,
          options,
          description,
          required,
          range: range?.map((el) => Number(el)),
          isDescription,
        };
      }
    );
    if (selectedValue === SaveOptions.Save && !editQuestionnaire) {
      await handleCreateDoctorTemplate({
        variables: {
          doctorTemplateInput: {
            title,
            description: desc,
            questions: questionPayload,
            lastOpenedAt: new Date(),
            type: isNotes ? 'note' : 'form',
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES],
      });
    } else if (selectedValue === SaveOptions.Update && !editQuestionnaire) {
      await handleEditDoctorTemplate({
        variables: {
          id: linkProps?.formId,
          doctorTemplateInput: {
            title,
            description: desc,
            questions: questionPayload,
            lastOpenedAt: new Date(),
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES],
      });
    } else {
      await handleEditQuestionnaire({
        variables: {
          id: linkProps?.formId,
          questionnaireInput: {
            title,
            description: desc,
            questions: questionPayload,
          },
        },
      });
    }
    triggerSnack();
    setTimeout(() => handleSave(), 500);
  };
  const handleSave = () => {
    setOpenSave(false);
    handleClose();
    // settingsHandleBack();
  };
  const handleSubmitFrom = async () => {
    if (!!linkProps.editQuestionnaire) {
      handleSaveDoctorTemplate();
    } else {
      setOpenSave(true);
    }
  };
  const handleEditTitle = (title: string) => {
    setTitle(title);
  };
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxHeight: settings ? '100vh' : 'calc(100vh - 58px)',
        backgroundColor: 'white',
        maxWidth: !editMode && '40vw',
      }}
    >
      <ScrollBar>
        <Box width="100%">
          <Steps
            step={step}
            steps={[
              <FormGenerator
                questions={questions}
                setQuestions={setQuestions}
                question={question}
                isNotes={isNotes}
              />,
              <FormPreview
                questions={questions}
                handleBack={() =>
                  history.push(`/doctor/patient/${linkProps.patientId}`, {
                    tab: 1,
                  })
                }
                title={title}
                source={linkProps?.source}
                isSigned={linkProps?.isPatientSigned}
                patientId={linkProps?.patientId}
                description={desc}
                viewMode={viewMode}
                editMode={editMode}
              />,
            ]}
            buttonVisible={[]}
            submitText="Save"
            handleSubmit={handleSubmitFrom}
            handleClose={handleBack}
            divider={false}
          />
        </Box>
      </ScrollBar>
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="1px solid #EAECF0"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        {!viewMode && (
          <Box width="100%">
            {isNotes || linkProps?.settings ? (
              <NotesTopSection
                title={title}
                handleSubmitFrom={handleSaveDoctorTemplate}
                handleEditTitle={handleEditTitle}
                handleBack={handleBack}
                step={step}
                handleNext={handleNext}
              />
            ) : (
              <TopSection
                handleBack={handleBack}
                handleNext={handleNext}
                handleAssign={() => setAssignOpen(true)}
                title={title}
                description={desc}
                step={step}
                previewMode={previewMode}
                handleSubmitFrom={handleSubmitFrom}
              />
            )}
          </Box>
        )}
      </Box>
      <BasicModal
        isRegularModal
        isIcon={false}
        isNew
        open={assignOpen}
        onClose={() => setAssignOpen(false)}
        handleClose={() => setAssignOpen(false)}
      >
        <AssignModal
          handleClose={() => setAssignOpen(false)}
          handleSubmit={handleSubmit}
          text={linkProps?.assignText}
        />
      </BasicModal>
      <BasicModal
        isRegularModal
        isNew
        open={openSave}
        onClose={() => setOpenSave(false)}
        handleClose={() => setOpenSave(false)}
      >
        <SaveModal
          newForm={!!!linkProps?.formId}
          handleClose={() => setOpenSave(false)}
          handleSubmit={handleSaveDoctorTemplate}
          title={title}
          setTitle={setTitle}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      </BasicModal>
    </Box>
  );
}

export default FormsPage;
