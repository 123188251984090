import { gql, useMutation } from '@apollo/client';
import { editImaging, editImagingVariables } from './__generated__/editImaging';

const EDIT_IMAGING_REQUEST = gql`
  mutation editImaging($imagingInput: editImagingInput!, $imagingId: ID!) {
    editImaging(imagingInput: $imagingInput, imagingId: $imagingId) {
      _id
      type
      name
      notes
    }
  }
`;

export default function useEditImaging() {
  const [updateImaging, result] = useMutation<
    editImaging,
    editImagingVariables
  >(EDIT_IMAGING_REQUEST, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentImaging() {
            return data?.editImaging;
          },
        },
      });
    },
  });
  return { updateImaging, ...result };
}
