import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateMessages from '../animations/messages.json';

export const Messages = () => {
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateMessages}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Messages</h3>
        <p className={classes.description}>
          Access conversations with your provider.
        </p>
      </div>
    </div>
  );
};
