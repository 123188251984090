import { gql, useMutation } from '@apollo/client';

export const CREATE_RATE = gql`
  mutation createRate($rateInput: RateInput) {
    createRate(rateInput: $rateInput) {
      session
      duration
      currency
      rate
    }
  }
`;
export default function useCreateRate() {
  const [createRate, result] = useMutation(CREATE_RATE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          rates() {
            return data?.createRate;
          },
          selectedRate() {
            return data?.createRate;
          },
        },
      });
    },
  });
  return { createRate, ...result };
}
