import { useMutation, gql } from '@apollo/client';

const PAYMENT_INTENT = gql`
  mutation paymentIntent($paymentInput: addPaymentInput) {
    stripeIntent(paymentInput: $paymentInput) {
      clientSecret
      paymentMethod
    }
  }
`;

export default function usePaymentIntent() {
  const [paymentIntent, result] = useMutation(PAYMENT_INTENT);
  return { paymentIntent, ...result };
}
