import React, { ReactElement } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as CancelIcon } from 'assets/icons/Vector.svg';
import { useToast } from 'hooks/useToast';
import useStyles from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  title?: string;
  titlePadding?: string;
  isIcon?: boolean;
  className?: string;
  titleClass?: string;
}

export default function RegularModal({
  children,
  handleClose,
  title,
  titlePadding,
  isIcon,
  className,
  titleClass,
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const { t } = useTranslation();
  const classes = useStyles({ openSuccess, disablePadding: false });
  return (
    <Box className={clsx(className, classes.modalBox)}>
      {isIcon && (
        <CancelIcon className={classes.cancelIcon} onClick={handleClose} />
      )}

      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <Typography
          className={clsx(titleClass, classes.title)}
          style={{ paddingInline: titlePadding }}
        >
          {title &&
            t(
              title[0].toUpperCase() +
                title.slice(1, title.length).toLowerCase()
            )}
        </Typography>
      </Grid>
      {children}
    </Box>
  );
}
