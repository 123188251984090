import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Box, Divider, Typography } from '@material-ui/core';
import useStyles from './styles';
import InputBlock from './InputBlock';
import Input from 'components/ui/Inputs';
import { IpatientProps } from 'pages/PatientGeneralInfo/types';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

export interface IContactModal {
  patient: IpatientProps;
}

const ContactModal = ({ patient }: IContactModal): JSX.Element => {
  const classes = useStyles();
  const { register, control, errors } = useFormContext();
  const commonProps = { register, control, errors };
  const { t } = useTranslation();
  return (
    <Box px={'2rem'}>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Email')}
            {...commonProps}
            name="email"
            type="email"
            defaultValue={patient?.email}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Text i18nKey="phoneNumber" className={classes.labelText}>
            Phone number
          </Text>
          <Input
            control={control}
            inputRef={register()}
            type="phone"
            name="phone"
            variant="filled"
            helperText={
              errors &&
              errors?.phone && (
                <span>
                  {/* <ErrorIcon className={classes.errorIcon} /> */}
                  {errors?.phone?.message}
                </span>
              )
            }
            error={errors && !!errors?.phone}
            defaultValue={patient?.phone}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <InputBlock
            label={t('Street address 1')}
            {...commonProps}
            name="address"
            type="text"
            defaultValue={patient?.address}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <InputBlock
            label={t('Street address 2')}
            {...commonProps}
            name="suite"
            type="text"
            defaultValue={patient?.suite}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('City')}
            {...commonProps}
            name="city"
            type="text"
            defaultValue={patient?.city}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('State/province')}
            {...commonProps}
            name="state"
            type="text"
            defaultValue={patient?.state}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Zip code')}
            {...commonProps}
            name="zipcode"
            type="number"
            defaultValue={patient?.zipcode}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Text i18nKey="country" className={classes.labelText}>
            Country
          </Text>
          <Input
            inputRef={register}
            variant="filled"
            control={control}
            type="country"
            name="country"
            data-cy="country-input"
            {...commonProps}
            defaultValue={{ label: patient?.country }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider className={classes.divider} />
          <Text
            i18nKey="emergencyContact"
            className={classes.sectionTitle}
            style={{ marginBottom: 0 }}
          >
            Emergency contact
          </Text>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Name')}
            {...commonProps}
            name="emergencyName"
            type="text"
            defaultValue={patient?.emergencyName}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Relationship')}
            {...commonProps}
            name="relationship"
            type="select"
            options={[
              'spouse',
              'parent',
              'child',
              'sibling',
              'partner',
              'friend',
              'guardian',
              'relative',
              'neighbor',
              'colleague',
              'healthcare proxy',
            ]}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Email')}
            {...commonProps}
            name="emergencyEmail"
            type="email"
            defaultValue={patient?.emergencyEmail}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Text i18nKey="phoneNumber" className={classes.labelText}>
            Phone number
          </Text>
          <Input
            control={control}
            inputRef={register()}
            type="phone"
            name="emergencyPhone"
            variant="filled"
            helperText={
              errors &&
              errors?.phone && (
                <span>
                  {/* <ErrorIcon className={classes.errorIcon} /> */}
                  {errors?.phone?.message}
                </span>
              )
            }
            error={errors && !!errors?.phone}
            defaultValue={patient?.emergencyPhone}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider className={classes.divider} />
          <InputBlock
            label={t('Preferred language')}
            {...commonProps}
            name="preferredLanguage"
            type="select"
            options={[
              'english',
              'spanish',
              'french',
              'german',
              'chinese',
              'hindi',
              'arabic',
              'russian',
              'portuguese',
              'japanese',
              'korean',
              'italian',
              'other',
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactModal;
