import React, { ReactElement } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Avatar,
  Divider,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from 'components/ui/Switch';
import { IDateRange } from 'pages/SchedulePage/AddEventPage/types';
import DateRange from 'components/ui/Inputs/DateRange';
import { convertDateLikeMeet } from 'utils/dateUtils';
import AccessType from './AccessType';
import Button from 'components/ui/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import { useForm, FormProvider } from 'react-hook-form';
import useGetPatientAccess from '@eggmed/graphql-client/operations/patientAccessControlOperations/useGetPatientAccess';
import useUpdatePatientAccess from '@eggmed/graphql-client/operations/patientAccessControlOperations/useUpdatePatientAccess';
import { useToast } from 'hooks/useToast';
import { useSnackbar } from 'hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '46px',
    letterSpacing: '0.44px',
    color: '#425364',
    paddingTop: '1rem',
    paddingLeft: '5rem',
  },
  tabs: { paddingBottom: '1rem', paddingLeft: '5rem' },
  avatar: {
    width: theme.typography.pxToRem(75),
    height: theme.typography.pxToRem(75),
    marginRight: '18px',
  },
  doctorName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '37px',
    color: '#666666',
    whiteSpace: 'nowrap',
  },
  doctorJob: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#666666',
  },
  revokeAccess: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#425364',
  },
  saveButton: {
    paddingInline: '2rem',
    height: theme.typography.pxToRem(35),
    borderRadius: '30px',
    background: '#1F61DC',
    color: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    '&:hover': {
      background: '#1E41CC',
    },
  },
  cancelButton: {
    paddingInline: '1.5rem',
    height: theme.typography.pxToRem(35),
    borderRadius: '30px',
    background: '#D7E3F1',
    color: '#273142',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    '&:hover': {
      background: '#C7D3E1',
    },
  },
}));
export enum TypeEnum {
  a_general = 'a_general',
  a_history = 'a_history',
  a_immunization = 'a_immunization',
  a_allergies = 'a_allergies',
  a_conditions = 'a_conditions',
  a_labs = 'a_labs',
  a_drugs = 'a_drugs',
}
interface IGetDoctorProps {
  id: string;
}

interface IDataProps {
  permissions: string[];
}

const DoctorAccessPage = (): ReactElement => {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IGetDoctorProps>();
  const { data: doctorData } = useGetDoctor(params);
  const [dateRange, setDateRange] = React.useState<IDateRange>({
    startDate: new Date(Date.now()),
    endDate: new Date(
      new Date(convertDateLikeMeet(Date.now())).setHours(
        convertDateLikeMeet(Date.now()).getHours() + 1
      )
    ),
  });
  function handleChangeDate(
    name: 'endDate' | 'startDate',
    value: string | number
  ): void {
    setDateRange((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange[name] = value;
      return { ...oldDateRange, [name]: value };
    });
  }
  const methods = useForm();
  const handleUpdatePatientAccess = useUpdatePatientAccess();
  const onSubmit = (data: IDataProps) => {
    const updateInput = { doctor: params.id, permissions: data.permissions };
    handleUpdatePatientAccess(updateInput);
    triggerSnack();
    setTimeout(() => history.push('/patient/access_control'), 1500);
  };
  const { data, loading } = useGetPatientAccess();
  const doctorPermission = data?.patientAccess?.access?.find(
    (e) => e?.doctor?._id === params.id
  )?.permissions;

  if (loading) return <div></div>;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          style={{
            minHeight: '50vh',
            paddingBottom: '2rem',
            background: 'white',
            borderRadius: '45px',
            height: '100%',
          }}
        >
          <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
            <Typography className={classes.title}>Access control</Typography>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            xl={12}
            md={12}
            sm={12}
            style={{ paddingInline: '5rem', marginTop: '1rem' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              xs={12}
              lg={8}
              xl={8}
              md={8}
              sm={10}
              alignItems="center"
            >
              <Box display="flex" style={{ alignItems: 'center' }}>
                <Avatar
                  className={classes.avatar}
                  src={doctorData?.doctor.picture}
                />
                <Box>
                  <Typography className={classes.doctorName}>
                    {doctorData?.doctor.firstname}
                    {'  '} {doctorData?.doctor.lastname}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              xs={12}
              lg={3}
              xl={3}
              md={3}
              sm={10}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box
                style={{
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                }}
              >
                <Typography className={classes.revokeAccess}>
                  Access or revoke all
                </Typography>
                <Switch name="channels" />
              </Box>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            style={{ marginTop: '2rem', marginInline: '5rem' }}
          />
          <Grid
            container
            xs={12}
            lg={12}
            xl={12}
            md={12}
            sm={12}
            style={{
              paddingInline: '5rem',
              paddingBlock: '1rem',
              width: '100%',
              height: '100%',
            }}
          >
            <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              wrap="wrap"
              style={{ gap: '10px' }}
            >
              <AccessType
                title="General Info"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                   ${doctorData?.doctor.lastname} for your general info`}
                type={TypeEnum.a_general}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="History"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your medical history`}
                type={TypeEnum.a_history}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="Immunization"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your immunization`}
                type={TypeEnum.a_immunization}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="Allergies"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your allergies records`}
                type={TypeEnum.a_allergies}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="Drugs"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your Drugs`}
                type={TypeEnum.a_drugs}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="Labs"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your Labs records`}
                type={TypeEnum.a_labs}
                access={doctorPermission}
                register={methods.register}
              />
              <AccessType
                title="Conditions"
                desc={`You will allow or revoke access to Doctor ${doctorData?.doctor.firstname}
                  ${doctorData?.doctor.lastname} for your conditions records`}
                type={TypeEnum.a_conditions}
                access={doctorPermission}
                register={methods.register}
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            lg={12}
            xl={12}
            md={12}
            sm={12}
            justifyContent="flex-end"
            style={{ gap: '9px', display: 'flex', paddingInline: '5rem' }}
          >
            <Button className={classes.saveButton} type="submit">
              Save
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={() => history.push('/patient/access_control/')}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

export default DoctorAccessPage;
