import React from 'react';
import { Typography, Box, InputAdornment } from '@material-ui/core';
import { ReactComponent as Refund } from 'assets/Refund.svg';
import Table from 'components/ui/Table';
import Checked from 'pages/AdminPage/Payment/Checked';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import useStyles from './styles';

export const RefundModal = ({
  isLoading,
  appointment,
  columns,
  toggled,
  handleChange,
  refundForms,
}) => {
  const classes = useStyles({});

  return (
    <Box
      sx={{
        width: {
          xs: '50vw',
          lg: '43vw',
          xl: '35vw',
        },
      }}
      px="2rem"
      mt={4}
    >
      <Typography className={classes.reviewSection}>
        Please review the applicable fees to finalize the session{' '}
      </Typography>
      <Box mt={6}>
        <Table
          loading={isLoading}
          limit={1}
          page={1}
          columns={columns as any}
          data={[appointment] || []}
        />
      </Box>
      <Box
        px={2}
        py={2}
        className={classes.refundSection}
        display="flex"
        mt={2}
        position="relative"
        flexDirection="column"
      >
        <Box display="flex">
          <Refund />
          <Box ml={1} display="flex" flexDirection="column">
            <Typography className={classes.refundTitle}>Refund</Typography>
            <Typography className={classes.refundDescription}>
              Issue a full or partial refund to your client
            </Typography>
          </Box>
        </Box>
        <Box position="absolute" top={3} right={3}>
          <Checked
            checkedValue={toggled}
            handleChange={(e) => handleChange(e)}
          />
        </Box>
        {toggled && (
          <Box display="flex" flexDirection="column" ml={'40px'}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.refundAmount}>
                Refund amount
              </Typography>
              <Box ml={2} width="80px">
                <InputBlock
                  label=""
                  {...refundForms}
                  name="refund"
                  type="number"
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  style={{}}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
