import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { RouteConfig } from '@sentry/react/dist/reactrouter';

import routes from 'routes';
import history from './routerHistory';

export default (): void => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV4Instrumentation(
          history,
          routes as unknown as RouteConfig[]
        ),
      }),
    ],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_SENTRY_ON
      ? Number(process.env.REACT_APP_SENTRY_TRACING)
      : 0,
  });
};
