import React from 'react';
import { ReactComponent as CalmIcon } from 'assets/patientActivities/calm.svg';
import { ReactComponent as SadIcon } from 'assets/patientActivities/sad.svg';
import { ReactComponent as AnxiousIcon } from 'assets/patientActivities/anxious.svg';
import { ReactComponent as PanickedIcon } from 'assets/patientActivities/panicked.svg';
import { ReactComponent as HappyIcon } from 'assets/patientActivities/happy.svg';
import { ReactComponent as TiredIcon } from 'assets/patientActivities/tired.svg';
import { ReactComponent as ConfidentIcon } from 'assets/patientActivities/confident.svg';
import { ReactComponent as UnsureIcon } from 'assets/patientActivities/unsure.svg';
import { ReactComponent as GoodIcon } from 'assets/patientActivities/good.svg';
import { IEmoji } from './Form';
import { QuestionTypeEnum } from 'pages/FormsPage/Constants';
import { IPatientResponse } from '../index';
import { ReactComponent as OkaySelectedIcon } from 'assets/patientActivities/okay-selected.svg';

import { ReactComponent as OkayMood } from 'assets/OkayMood.svg';
import { ReactComponent as AwesomeMood } from 'assets/AwesomeMood.svg';
import { ReactComponent as AwfullMood } from 'assets/AwfullMood.svg';
import { ReactComponent as BadMood } from 'assets/BadMood.svg';
import { ReactComponent as GoodMood } from 'assets/BadMood.svg';
import { ReactComponent as GoodMoodEmoji } from 'assets/GoodMoodEmoji.svg';

import { ReactComponent as AwfulSleep } from 'assets/AwfulSleep.svg';
import { ReactComponent as BadSleep } from 'assets/BadSleep.svg';
import { ReactComponent as GoodSleep } from 'assets/GoodSleep.svg';
import { ReactComponent as GreatSleep } from 'assets/GreatSleep.svg';
import { ReactComponent as OkaySleep } from 'assets/OkaySleep.svg';

export const Moods: IEmoji[] = [
  {
    label: 'Okay',
    icon: <OkayMood width={44} height={44} />,
  },
  {
    label: 'Great',
    icon: <AwesomeMood width={44} height={44} />,
  },
  {
    label: 'Terrible',
    icon: <AwfullMood width={44} height={44} />,
  },
  {
    label: 'Bad',
    icon: <BadMood width={44} height={44} />,
  },
  {
    label: 'Good',
    icon: <GoodMoodEmoji width={44} height={44} />,
  },
];

export const Sleep: IEmoji[] = [
  {
    label: 'Terrible',
    icon: <AwfulSleep width={44} height={44} />,
  },
  {
    label: 'Bad',
    icon: <BadSleep width={44} height={44} />,
  },
  {
    label: 'Good',
    icon: <GoodSleep width={44} height={44} />,
  },
  {
    label: 'Great',
    icon: <GreatSleep width={44} height={44} />,
  },
  {
    label: 'Okay',
    icon: <OkaySleep width={44} height={44} />,
  },
];

/* New Emojis */
export const _Moods: IEmoji[] = [
  {
    label: 'Calm',
    icon: <CalmIcon />,
  },
  {
    label: 'Sad',
    icon: <SadIcon />,
  },
  {
    label: 'Anxious',
    icon: <AnxiousIcon />,
  },
  {
    label: 'Panicked',
    icon: <PanickedIcon />,
  },
  {
    label: 'Happy',
    icon: <HappyIcon />,
  },
  {
    label: 'Tired',
    icon: <TiredIcon />,
  },
  {
    label: 'Confident',
    icon: <ConfidentIcon />,
  },
  {
    label: 'Unsure',
    icon: <UnsureIcon />,
  },
];

export const _Sleep: IEmoji[] = [
  {
    label: 'Not great',
    icon: <SadIcon />,
  },
  {
    label: 'Okay',
    icon: <OkaySelectedIcon />,
  },
  {
    label: 'Good',
    icon: <CalmIcon />,
  },
  {
    label: 'Great',
    icon: <HappyIcon />,
  },
];
export const days = [
  { name: 'Mon', value: 'monday' },
  { name: 'Tue', value: 'tuesday' },
  { name: 'Wed', value: 'wednesday' },
  { name: 'Thu', value: 'thursday' },
  { name: 'Fri', value: 'friday' },
  { name: 'Sat', value: 'saturday' },
  { name: 'Sun', value: 'sunday' },
];

export const defaultFroms = [
  {
    title: 'GAD-7',
    source:
      'Source: Spitzer, R. L., Kroenke, K., Williams, J. B., & Löwe, B. (2006). A brief measure for assessing generalized anxiety disorder: the GAD-7. Archives of internal medicine, 166(10), 1092–1097.',
    description:
      'Over the last 2 weeks, how often have you been bothered by the following problems?',
    questions: [
      {
        question: 'Feeling nervous, anxious, or on edge',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Not being able to stop or control worrying',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Worrying too much about different things',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Trouble relaxing',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Being so restless that it’s hard to sit still',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Becoming easily annoyed or irritable',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question: 'Feeling afraid as if something awful might happen',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
      {
        question:
          'If you checked off any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 3],
      },
    ],
  },
  {
    title: 'PHQ-9',
    source:
      'Source: Kroenke, K., & Spitzer, R. L. (2002). The PHQ-9: A new depression diagnostic and severity measure. Psychiatric Annals, 32(9), 509–515.',
    description:
      'Over the last 2 weeks, how often have you been bothered by any of the following?',
    questions: [
      {
        question: 'Little interest or pleasure in doing things',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question: 'Feeling down, depressed, or hopeless',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question: 'Feeling down, depressed, or hopeless',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question: 'Trouble falling or staying asleep, or sleeping too much',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question: 'Feeling tired or having little energy',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question: 'Poor appetite or overeating',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question:
          'Feeling bad about yourself—or that you are a failure or have let yourself or your family down',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question:
          'Trouble concentrating on things, such as reading the newspaper or watching television',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question:
          'Moving or speaking so slowly that other people could have noticed? Or the opposite—being so fidgety or restless that you have been moving around a lot more than usual',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question:
          'Thoughts that you would be better off dead or of hurting yourself in some way',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'Several days',
          'Over half the days',
          'Nearly every day',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
      {
        question:
          'If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not difficult at all',
          'Somewhat difficult',
          'Very difficult',
          'Extremely difficult',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 4],
      },
    ],
  },
  {
    title: 'PHQ-15',
    source:
      'Source: Kroenke, K., & Spitzer, R. L. (2002). The PHQ-9: A new depression diagnostic and severity measure. Psychiatric Annals, 32(9), 509–515.',
    description:
      'During the past 4 weeks, how much have you been bothered by any of the following problems?',
    questions: [
      {
        question: 'Stomach pain',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Back pain',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Pain in your arms, legs, or joints (knees, hips, etc.)',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Menstrual cramps or other problems with your periods',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not bothered at all',
          'Bothered a little',
          'Bothered a lot',
          'Not applicable',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Headaches',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Chest pain',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Dizziness',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Fainting spells',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Feeling your heart pound or race',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Shortness of breath',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Pain or problems during sexual intercourse',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not bothered at all',
          'Bothered a little',
          'Bothered a lot',
          'Not applicable',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Constipation, loose bowels, or diarrhea',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Nausea, gas, or indigestion',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Feeling tired or having low energy',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
      {
        question: 'Trouble sleeping',
        type: QuestionTypeEnum.MultipleChoice,
        options: ['Not bothered at all', 'Bothered a little', 'Bothered a lot'],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 6],
      },
    ],
  },
  {
    title: 'PCL-5',
    source:
      'Source: Weathers, F.W., Litz, B.T., Keane, T.M., Palmieri, P.A., Marx, B.P., & Schnurr, P.P. (2013). The PTSD Checklist for DSM-5 (PCL-5). Scale available from the National Center for PTSD.',
    description: 'In the past month, how much were you bothered by:',
    questions: [
      {
        question:
          'Repeated, disturbing, and unwanted memories of the stressful experience?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Repeated, disturbing dreams of the stressful experience?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },

      {
        question:
          'Feeling very upset when something reminded you of the stressful experience?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Avoiding memories, thoughts, or feelings related to the stressful experience? ',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)? ',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Trouble remembering important parts of the stressful experience?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Blaming yourself or someone else for the stressful experience or what happened after it?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Having strong negative feelings such as fear, horror, anger, guilt, or shame?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Loss of interest in activities that you used to enjoy?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Feeling distant or cut off from other people?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Irritable behavior, angry outbursts, or acting aggressively?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question:
          'Taking too many risks or doing things that could cause you harm?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Being “superalert” or watchful or on guard?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Feeling jumpy or easily startled?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Having difficulty concentrating?',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
      {
        question: 'Trouble falling or staying asleep? ',
        type: QuestionTypeEnum.MultipleChoice,
        options: [
          'Not at all',
          'A little bit',
          'Moderately',
          'Quit a bit',
          'Extremely',
        ],
        description: '',
        isEdited: false,
        isDescription: false,
        required: true,
        range: [0, 2],
      },
    ],
  },
];
