import React, { ReactElement } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from 'components/ui/Button';
import clsx from 'clsx';
import { useProvider as useAuth } from 'contexts/AuthContext';
import { ReactComponent as DeleteIcon } from 'assets/Vector.svg';
import useRemoveDoctorFromStaff from '@eggmed/graphql-client/operations/staffOperations/useDeleteStaff';
import { gql, useMutation } from '@apollo/client';
import { INVITED_STAFF } from './Staff';

const useStyles = makeStyles((theme) => ({
  Modalcontainer: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '2.5rem',
  },

  inputLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    color: '#425364',
  },
  buttonBox: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  button: {
    width: '6rem',
    height: '36px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  removeButton: {
    background: '#FE443F',
    '&:hover': {
      background: '#DF121E',
    },
  },
  inputbox: {
    marginBottom: theme.spacing(3),
  },
  buttonGrid: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalBox: {
    backgroundColor: 'white',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '35vw',
    height: 'auto',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  confirmation: {
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
  },
}));
const DeleteStaff = ({ handleClose, staff, invited }): ReactElement => {
  const classes = useStyles();
  const { user } = useAuth();
  const [deleteInvitedStaff] = useMutation(DELETE_INVITED_STAFF);
  const handleRemoveDoctorFromStaff = useRemoveDoctorFromStaff();
  const handleDeleteStaff = () => {
    handleRemoveDoctorFromStaff(user?.doctorId, staff?.id);
    handleClose();
  };
  const handleDeleteInvitedStaff = async () => {
    await deleteInvitedStaff({
      variables: {
        id: staff.id,
      },
      refetchQueries: [
        {
          query: INVITED_STAFF,
        },
      ],
    });
    handleClose();
  };
  return (
    <Grid container className={classes.Modalcontainer}>
      <Grid item style={{ marginBottom: '10px' }}>
        <Typography className={classes.confirmation}>
          Do you really want to remove{' '}
          <span style={{ fontWeight: 700 }}>{staff.name}</span> from your staff
          members?
        </Typography>
        <Typography className={classes.confirmation}>
          This process cannot be undone.
        </Typography>
      </Grid>
      <Grid item className={classes.buttonGrid}>
        <Box className={classes.buttonBox}>
          <Button
            className={classes.button}
            onClick={handleClose}
            type="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={invited ? handleDeleteInvitedStaff : handleDeleteStaff}
            className={clsx(classes.button, classes.removeButton)}
            type="done"
          >
            Remove
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
const DELETE_INVITED_STAFF = gql`
  mutation deleteInvitedStaff($id: ID!) {
    deleteInvitedStaff(id: $id) {
      _id
    }
  }
`;
export default DeleteStaff;
