import useCreateQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateQuestionnaire';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import { QUESTIONNAIRE_BY_FILTER } from './Questionaires';

function useAssignQuestionaire() {
  const [handleCreateQuestionnaire] = useCreateQuestionnaire();
  const handleAssignQuestionaire = async (
    questions: IQuestion[],
    doctorId: string,
    patientId: string,
    title: string,
    description: string,
    formType?: string,
    blocks?: string
  ) => {
    const questionPayload = questions.map(
      ({
        question,
        type,
        options,
        description,
        required,
        range,
        isDescription,
      }) => {
        return {
          question,
          type,
          options,
          description,
          required,
          range,
          isDescription,
        };
      }
    );

    const payload = {
      title,
      description,
      questions: questionPayload,
      assignedAt: new Date(),
      doctor: doctorId,
      patient: patientId,
      formType,
      blocks,
    };
    await handleCreateQuestionnaire({
      variables: { questionnaireInput: payload },
      refetchQueries: [
        {
          query: QUESTIONNAIRE_BY_FILTER,
          variables: { patientId, page: '1', limit: '5' },
        },
      ],
    });
  };
  return { handleAssignQuestionaire };
}

export default useAssignQuestionaire;
