export function log(SEVERITY = 'log', ...params: any) {
  switch (SEVERITY) {
    case 'error':
      console.error(...params);
      break;
    case 'warn':
      console.warn(...params);
      break;
    default:
      console.log(SEVERITY, ...params);
      break;
  }
}
