import { withStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const EggmedPagination = withStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
      '& li:first-child .MuiPaginationItem-outlined': {
        backgroundColor: 'white',
        color: 'black',
        '& svg': {
          fontSize: '2.25rem',
        },
      },
      '& li:last-child .MuiPaginationItem-outlined': {
        backgroundColor: 'white',
        color: 'black',
        '& svg': {
          fontSize: '2.25rem',
        },
      },
      '& .MuiPaginationItem-outlined': {
        border: 'none',
        borderRadius: '8px',
      },
      '& .MuiPaginationItem-root': {
        color: '#475467',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        height: '35px',
        width: '40px',
      },
      '& .MuiPaginationItem-ellipsis': {
        border: 'none',
        borderRadius: '8px',
        padding: '6px 6px',
        fontSize: '1.2rem',
      },
      //   C4C4C4
      '& .MuiPaginationItem-page.Mui-selected': {
        backgroundColor: '#0000000a',
        color: '#182230',
      },
    },
  })
)(Pagination);
export default EggmedPagination;
