/*eslint-disable*/
import React, { ReactElement, useRef, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ListItemText, Typography, BoxProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditListItem from './EditListitem';

const useStyles = makeStyles((theme) => ({
  listSecond: {
    position: 'relative',
    left: '-1vw',
    width: '100%',
    overflow: 'hidden',
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#F2F7FC',
      borderRadius: '4px',
    },
  },
  textWrap: (props: any) => ({
    width: props.type !== 'minimal' ? '80%' : '50%',
    wordWrap: 'break-word',
    cursor: 'pointer',
  }),
  secondaryAction: (props: any) => ({
    position: props.type !== 'minimal' ? 'static' : 'absolute',
    transform: props.type !== 'minimal' ? 'translateY(0%)' : 'translateY(-50%)',
  }),
}));

export interface IListItemWithActionsProps extends BoxProps {
  AvatarIcon?: ReactElement;
  primaryData: string;
  secondaryData?: string;
  handleAction?: () => void;
  rightActions?: ReactElement;
  type?: 'normal' | 'minimal';
  editable?: boolean;
  element?: any;
  handleEdit?: any;
  setEditableIndex?: any;
}
export default function ListItemWithActions({
  AvatarIcon,
  primaryData,
  secondaryData,
  handleAction,
  setEditableIndex,
  handleEdit,
  element = { _id: '' },
  rightActions,
  type = 'minimal',
  editable = false,
  ...props
}: IListItemWithActionsProps): ReactElement {
  const classes = useStyles({ type });
  const [wrappedText, setWrapper] = useState(false);
  const node = useRef<any>();
  const { _id: id } = element;
  const handleEditData = ({ name, details }: any) => {
    if (name !== primaryData || details !== secondaryData) {
      const editedFields = { name, details };
      handleEdit(editedFields, id);
    }
    setEditableIndex(null);
  };

  return (
    <ListItem
      ref={node}
      alignItems="flex-start"
      {...props}
      className={classes.listItem}
    >
      {editable ? (
        <EditListItem
          nameLabel="Report name"
          detailsLabel="Report details"
          initialName={primaryData || ''}
          initialDetails={secondaryData || ''}
          handleEdit={handleEditData}
          closeEditMode={() => setEditableIndex(null)}
        />
      ) : (
        <>
          {AvatarIcon && <ListItemAvatar>{AvatarIcon}</ListItemAvatar>}
          <ListItemText
            onClick={handleAction}
            className={classes.listSecond}
            disableTypography
            primary={
              <Typography className={classes.textWrap} noWrap>
                {primaryData}
              </Typography>
            }
            secondary={
              type !== 'minimal' &&
              secondaryData && (
                <Box display="flex">
                  <Typography
                    className={classes.textWrap}
                    color="textSecondary"
                    noWrap={!wrappedText}
                  >
                    {secondaryData}
                  </Typography>
                </Box>
              )
            }
          />

          <ListItemSecondaryAction className={classes.secondaryAction}>
            <Box display="flex" flexDirection="column">
              {rightActions}
              {type !== 'minimal' &&
              secondaryData &&
              secondaryData.length > 33 ? (
                wrappedText ? (
                  <ExpandLessIcon onClick={() => setWrapper(false)} />
                ) : (
                  <ExpandMoreIcon onClick={() => setWrapper(true)} />
                )
              ) : (
                <></>
              )}
            </Box>
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  );
}
