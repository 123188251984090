import React, { ReactElement } from 'react';
import { useStyles } from '../styles';
import { Box, Typography, FormControl } from '@material-ui/core';
import { ReactComponent as NextArrowIcon } from 'assets/patientActivities/forwardArrow.svg';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/eyeIcon.svg';
import { ReactComponent as FetherIcon } from 'assets/patientActivities/fether.svg';
import { ReactComponent as ImageIcon } from 'assets/patientActivities/image.svg';
import { ReactComponent as EmojiIcon } from 'assets/patientActivities/emoji.svg';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import ScrollBar from 'components/ui/ScrollBar';
import Input from 'components/ui/Inputs';
import { useFormContext, Controller } from 'react-hook-form';
import Switch from 'components/ui/SwitchNew';
import Frequency from './Frequency';
import EmojiSelect from './EmojiSelectNew';
import { ReactComponent as NotesIcon } from 'assets/patientActivities/notes.svg';
import { Sleep, Moods } from './constants';
import { IPatientActivities } from '../index';
import ErrorIcon from '@material-ui/icons/Error';
import { ReactComponent as ReflectionIcon } from 'assets/ReflectionIcon.svg';
import { ReactComponent as MoodIcon } from 'assets/Moodicon.svg';
import { ReactComponent as SleepIcon } from 'assets/SleepIcon.svg';
import { ReactComponent as ActivityFormBg } from 'assets/activityFormBg.svg';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

interface FormProps {
  name: string;
  handleNext: () => void;
  handleBack?: () => void;
  selectedValues?: string[];
  setSelectedValues?: (values: string[]) => void;
  selected?: boolean;
  reflectionForm?: boolean;
  sleepForm?: boolean;
  moodForm?: boolean;
  sleepCheck?: boolean;
  notes?: boolean;
  upload?: boolean;
  defaultValues?: IPatientActivities;
}
export interface IEmoji {
  label: string;
  icon: ReactElement;
}
function Form({
  name,
  handleNext,
  selectedValues,
  setSelectedValues,
  handleBack,
  selected = true,
  reflectionForm = false,
  sleepCheck = false,
  notes = false,
  upload = false,
  sleepForm = false,
  moodForm = false,
  defaultValues,
}: FormProps) {
  const classes = useStyles({ selected: false });
  const { t } = useTranslation();
  const { control, register, errors, watch } = useFormContext();
  const commonProps = { control, errors, register, variant: 'filled' };
  const handleTitle = (selected, reflectionForm, sleepForm, moodForm) => {
    if (reflectionForm && selected) return t('Guided reflection');
    if (reflectionForm && !selected) return t('Free reflection');
    if (sleepForm) return t('Sleep check-in');
    if (moodForm) return t('Mood check-in');
    return 'modal title';
  };
  const ModalTitle = handleTitle(selected, reflectionForm, sleepForm, moodForm);
  React.useEffect(() => {
    if (defaultValues && setSelectedValues && defaultValues?.options) {
      setSelectedValues(defaultValues?.options);
    }
  }, [defaultValues]);
  const modalIcon = {
    'Free reflection': <ReflectionIcon />,
    'Réflection libre': <ReflectionIcon />,
    'Guided reflection': <ReflectionIcon />,
    'Réflexion guidée': <ReflectionIcon />,
    'Sleep check-in': <SleepIcon />,
    'Évaluation du sommeil': <SleepIcon />,
    'Mood check-in': <MoodIcon />,
    "Évaluation d'humeur": <MoodIcon />,
  };
  return (
    <Box className={classes.reflectionModal} data-cy="form-box">
      <Box className={classes.titleContainer} style={{ paddingInline: '2rem' }}>
        <ActivityFormBg className={classes.activityHeaderBg} />
        {modalIcon[ModalTitle]}
        <Typography className={classes.modalTitleForm}>{ModalTitle}</Typography>
      </Box>
      <ScrollBar
        className={classes.scrollBar}
        style={{ position: 'relative', zIndex: 2 }}
      >
        <Box width="100%" paddingY="2rem">
          <Box display="flex" alignItems="center" mb={1}>
            <Typography className={classes.labelText}>
              {reflectionForm && !selected && 'Free reflection'}
              {/* {reflectionForm && <span className={classes.required}>*</span>} */}
            </Typography>
          </Box>
          <Box>
            {
              reflectionForm && !selected && (
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to share a free reflection without being asked a question.'
                  )}`}
                </Typography>
              )
              // :
              //  (
              //   <FormControl style={{ width: '100%' }}>
              //     <Input
              //       data-cy="question"
              //       name="question"
              //       type="text"
              //       multiline
              //       minRows={2}
              //       maxRows={2}
              //       inputRef={register}
              //       defaultValue={defaultValues?.question}
              //       helperText={
              //         errors &&
              //         errors['question'] &&
              //         watch()?.question === '' && (
              //           <Box
              //             display="flex"
              //             alignItems="center"
              //             data-cy="error-message"
              //             className={classes.error}
              //           >
              //             <ErrorIcon className={classes.errorQuestionIcon} />
              //             {errors['question']?.message}
              //           </Box>
              //         )
              //       }
              //       error={
              //         errors && !!errors['question'] && watch()?.question === ''
              //       }
              //       {...commonProps}
              //     />
              //   </FormControl>
              // )
            }
            {reflectionForm && selected && (
              <>
                <Box display="flex" mb={1} style={{ gap: '5px' }}>
                  <Typography className={classes.labelText}>
                    Question
                    <span className={classes.required}>*</span>
                  </Typography>
                </Box>
                <FormControl style={{ width: '100%' }}>
                  <Input
                    data-cy="question"
                    name="question"
                    type="text"
                    multiline
                    minRows={2}
                    maxRows={2}
                    inputRef={register}
                    defaultValue={defaultValues?.question}
                    helperText={
                      errors &&
                      errors['question'] &&
                      watch()?.question === '' && (
                        <Box
                          display="flex"
                          alignItems="center"
                          data-cy="error-message"
                          className={classes.error}
                        >
                          <ErrorIcon className={classes.errorQuestionIcon} />
                          {t(errors['question']?.message)}
                        </Box>
                      )
                    }
                    error={
                      errors && !!errors['question'] && watch()?.question === ''
                    }
                    {...commonProps}
                  />
                </FormControl>
              </>
            )}
          </Box>

          {sleepCheck && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
              mt={3}
              zIndex={2}
            >
              <Switch
                name="sleep"
                inputRef={register}
                data-cy="sleep-switch"
                defaultChecked={!defaultValues?.sleep}
                disabled
              />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.labelText}>
                  {t('Sleeping Hours')}
                </Typography>
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to share sleep and wake up times'
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}
          {sleepForm && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
              mt={3}
              zIndex={2}
            >
              <Switch
                name="isTags"
                // inputRef={register}
                data-cy="tags-switch"
                defaultChecked={true}
                disabled={true}
              />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.labelText}>
                  {t('What impacted their sleep?')}
                </Typography>
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to share what impacted their sleep'
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}
          {moodForm && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
              mt={3}
              zIndex={2}
            >
              <Switch
                name="isTags"
                // inputRef={register}
                data-cy="tags-switch"
                defaultChecked={true}
                disabled={true}
              />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.labelText}>
                  {t('What words capture this feeling?')}
                </Typography>
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to share what impacted their day'
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}
          {upload && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
              mt={3}
              zIndex={2}
            >
              <Switch
                name="upload"
                inputRef={register}
                data-cy="photo-switch"
                defaultChecked={!defaultValues?.photo}
              />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.labelText}>Photo</Typography>
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to upload a photo'
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}
          {notes && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
              mt={3}
              zIndex={2}
            >
              <Switch
                name="notes"
                inputRef={register}
                data-cy="notes-switch"
                defaultChecked={!defaultValues?.notes}
              />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.labelText}>Notes</Typography>
                <Typography className={classes.Desc}>
                  {`${capitalizeFirstLetter(name)} ${t(
                    'will be prompted to share free mood notes.'
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}

          <Frequency defaultValues={defaultValues?.frequency} />
        </Box>
      </ScrollBar>
    </Box>
  );
}

export default Form;
