import { gql, useMutation } from '@apollo/client';

export const EDIT_TAG = gql`
  mutation editTag($tagId: ID, $tagInput: editTagInput) {
    editTag(tagId: $tagId, tagInput: $tagInput) {
      tag
      color
    }
  }
`;

export default function useEditTag() {
  const [editTag, result] = useMutation(EDIT_TAG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          doctorTags() {
            return data?.editTag;
          },
        },
      });
    },
  });
  return { editTag, ...result };
}
