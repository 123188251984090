import React, { ReactElement } from 'react';
import { makeStyles, Menu as MenuMUI, MenuProps } from '@material-ui/core';
import MenuTab from './MenuTabs';
import { MenuElements } from './MenuElement';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '1.2rem',
    borderRadius: '15px',
    overflow: 'visible',
    marginLeft: '1.3rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.typography.pxToRem(650),
    maxHeight: '100%',
    boxShadow: '0px 0px 13px 0px #A1B0C4',
  },
  list: {
    padding: '0',
    // width: theme.typography.pxToRem(461),
  },
}));

interface Props<MenuType> extends MenuProps {
  handleMenuClose: () => void;
  selected?: boolean;

  menuElements?: MenuElements[];
  unreadNotifications?: MenuElements[];
}

const Menu = <T extends unknown>({
  anchorEl,
  open,
  handleMenuClose,
  menuElements,
  unreadNotifications,
  ...props
}: Props<T>): ReactElement => {
  const classes = useStyles();

  return (
    <MenuMUI
      {...props}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleMenuClose}
      disableScrollLock={false}
      classes={{
        paper: classes.paper,
        list: classes.list,
      }}
    >
      <MenuTab menuElements={menuElements} />
    </MenuMUI>
  );
};

export default Menu;
