import React from 'react';
import { Box } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';


const SelectEdited = withStyles({
  select: {
    height: '16px',
  },
  outlined: {
    backgroundColor: 'white !important',
  },
})(Select);
export const LimitComponent = ({
  limit,
  handleLimitChange,
}: {
  limit: number;
  handleLimitChange: (e: any) => void;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <span style={{ paddingRight: '5px' }}>Show</span>
      <SelectEdited
        native
        variant="outlined"
        value={limit}
        data-cy="limit-select"
        style={{ height: '40px' }}
        onChange={handleLimitChange}
        // label="Age"
        inputProps={{
          name: 'limit',
          id: 'outlined-limit-native-simple',
        }}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
      </SelectEdited>
      <span style={{ paddingLeft: '5px' }}> entries</span>
    </Box>
  );
};
