import { getPatientDoctors } from './__generated__/getPatientDoctors';
import { gql, useQuery } from '@apollo/client';

const GET_CONTACT = gql`
  query getContact($role: String!) {
    patientUser {
      _id
      firstname
      middlename
      username
      lastname
      picture
    }
    getStaffsByDoctor {
      _id
      user {
        _id
        email
        firstname
        lastname
        middlename
        role
        picture
      }
      organization
      practice
      staffOf {
        _id
        noUsedFeatures
        firstname
        middlename
        username
        lastname
        email
        picture
      }
    }
    getUserByRole(role: $role) {
      _id
      firstname
      middlename
      username
      lastname
      role
      picture
      organizationId
      doctorId
    }
  }
`;

const GET_PATIENT_DOCTORS = gql`
  query getPatientDoctors {
    patientDoctors {
      _id
      firstname
      middlename
      username
      lastname
      role
      picture
      organizationId
      doctorId
    }
  }
`;

const useGetContact = (role: string) => {
  const { data, loading, error } = useQuery(GET_CONTACT, {
    variables: { role },
  });
  return { data, loading, error };
};

export const useGetPatientDoctors = () => {
  const { data, loading, error } =
    useQuery<getPatientDoctors>(GET_PATIENT_DOCTORS);
  return { data, loading, error };
};

export { GET_CONTACT, GET_PATIENT_DOCTORS };
export default useGetContact;
