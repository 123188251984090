import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { IDrug } from 'pages/PatientDrugs';
import { useToast } from 'hooks/useToast';
import { useSnackbar } from 'hooks/useSnackbar';

const GET_DRUGS = gql`
  query getDrugs($patientId: ID!) {
    drugs(patientId: $patientId) {
      _id
      patientId
      name
      type
      frequency
      dosage
      since
    }
  }
`;

const ADD_DRUG = gql`
  mutation addDrug($DrugInput: DrugInput!) {
    addDrug(DrugInput: $DrugInput)
  }
`;
const EDIT_DRUG = gql`
  mutation editDrug($DrugInput: DrugInput!, $drugId: ID!) {
    editDrug(DrugInput: $DrugInput, drugId: $drugId)
  }
`;

const DELETE_DRUG = gql`
  mutation deleteDrug($drugId: ID!) {
    deleteDrug(drugId: $drugId)
  }
`;
export default function useDrugs({ patientId }: { patientId: string }) {
  const { push } = useHistory();
  const { triggerToast } = useToast();
  const { triggerSnack } = useSnackbar();
  const { data, error, loading } = useQuery(GET_DRUGS, {
    variables: { patientId },
  });
  const [addDrug, { loading: loadingAddDrug }] = useMutation(ADD_DRUG, {
    refetchQueries: [
      { query: GET_DRUGS, variables: { patientId } },
      { query: GET_PATIENT, variables: { patientId } },
    ],
    awaitRefetchQueries: false,
  });

  const [editDrug, { loading: loadingEditDrug }] = useMutation(EDIT_DRUG, {
    refetchQueries: [{ query: GET_DRUGS, variables: { patientId } }],
    awaitRefetchQueries: false,
  });

  const [deleteDrug] = useMutation(DELETE_DRUG, {
    refetchQueries: [{ query: GET_DRUGS, variables: { patientId } }],
    awaitRefetchQueries: false,
  });

  async function handleDeleteDrug(drugId: string) {
    try {
      await deleteDrug({ variables: { drugId } });
    } catch (error) {
      push('/error');
    }
  }
  async function handleAddDrug({
    name,
    type,
    frequency,
    dosage,
    since,
  }: IDrug) {
    try {
      await addDrug({
        variables: {
          DrugInput: { patientId, name, type, frequency, dosage, since },
        },
      });
      triggerSnack();
    } catch (error) {
      push('/error');
    }
  }
  async function handleEditDrug({
    name,
    type,
    frequency,
    dosage,
    since,
    _id,
  }: IDrug) {
    try {
      await editDrug({
        variables: {
          DrugInput: {
            patientId,
            name,
            type,
            frequency,
            dosage,
            since,
          },
          drugId: _id,
        },
      });
      triggerSnack();
    } catch (error) {
      push('/error');
    }
  }
  return {
    handleDeleteDrug,
    handleEditDrug,
    handleAddDrug,
    data,
    error,
    loading,
    addDrug,
    editDrug,
    deleteDrug,
    loadingEditDrug,
    loadingAddDrug,
  };
}
