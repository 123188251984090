import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  daySelected: {
    fontFamily: 'Inter, sans-serif',
    backgroundColor: '#147AF3',
    border: '1px solid #147AF3',
    borderRadius: '50%',
    width: '46px',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  day: {
    fontFamily: 'Inter, sans-serif',
    backgroundColor: '#fff',
    border: '1px solid #D0D5DD',
    borderRadius: '50%',
    width: '46px',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dayName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'rgba(66, 83, 100, 1)',
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
}));

interface Option {
  name: string;
  value: string;
  selected?: boolean;
}

function convertOptionsWithSelectedAttribute(
  options: Option[],
  value: string[]
): Option[] {
  return options.map((option) => {
    const included = value?.indexOf(option.value);
    return {
      ...option,
      selected: included !== -1 && included !== undefined ? true : false,
    };
  });
}
interface IChipSelectorProps {
  options: Option[];
  value: string[];
  handleChange: (array: string[]) => void;
}
export default function ChipSelector({
  handleChange,
  options,
  value,
}: IChipSelectorProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentOptions, setCurrentOptions] = useState<Option[]>(
    convertOptionsWithSelectedAttribute(options, value)
  );
  React.useEffect(() => {
    setCurrentOptions(convertOptionsWithSelectedAttribute(options, value));
  }, [value]);
  function handleEdit(option: Option, index: number): void {
    const newOptions = [...currentOptions];
    newOptions.splice(index, 1, {
      ...option,
      selected: !option.selected,
    });
    setCurrentOptions(newOptions);
    const filteredOptions = newOptions
      .filter((filtredOption: any) => filtredOption.selected)
      .map((mappedOption: any) => mappedOption.value);
    handleChange(filteredOptions);
  }
  return (
    <Box display="flex" flexWrap="wrap" gridGap="1rem">
      {currentOptions?.map((option: Option, index: number) => (
        <Box
          onClick={() => {
            handleEdit(option, index);
          }}
          className={option.selected ? classes.daySelected : classes.day}
        >
          <Typography
            className={classes.dayName}
            style={{ color: option.selected && 'white', userSelect: 'none' }}
          >
            {t(option.name.slice(0, 2).toLowerCase())}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
