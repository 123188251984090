import React, { ReactElement, useEffect } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as ErrorServer } from 'assets/illustrations/ErrorServer.svg';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineheight: '150%',
    color: '#425364',
    marginTop: theme.spacing(4),
  },
}));
const ErrorPage = ({ isPatient }: { isPatient?: boolean }): ReactElement => {
  const headerTitleDispatcher = useHeaderPageNameDispatch() || undefined;
  useEffect(() => {
    if (!isPatient) {
      headerTitleDispatcher({ type: 'setTitle', payload: 'Error' });
    }
  }, [headerTitleDispatcher, isPatient]);
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column"
      my={30}
    >
      <ErrorServer />
      <Typography className={classes.text}> Something Went Wrong </Typography>
    </Box>
  );
};

export default ErrorPage;
