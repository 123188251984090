import { gql, useSubscription } from '@apollo/client';
interface Idata {
  data: [
    {
      _id: string;
      content: string;
      date: string;
      event: string;
      title: string;
      type: string;
      user: string;
      read: boolean;
      action: string;
      appointmentId: string;
    }
  ];
}
const SUBSCRIPTION_TO_NEW_NOTIFICATION = gql`
  subscription subscriptionNewNotification($userId: ID!) {
    subscriptionNewNotification(userId: $userId) {
      _id
      user
      type
      title
      content
      event
      date
      read
      action
      appointmentId
    }
  }
`;

const useSubscriptionNewNotification = (
  userId: string,
  event: (data: Idata) => void
) => {
  const result = useSubscription(SUBSCRIPTION_TO_NEW_NOTIFICATION, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      if (subscriptionData?.data?.subscriptionNewNotification)
        event(subscriptionData?.data?.subscriptionNewNotification);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_NEW_NOTIFICATION };
export default useSubscriptionNewNotification;
