import { Box, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Terms from 'pages/TermsPage';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';

const DoctorTerms = (): ReactElement => {
  const headerTitleDispatcher = useHeaderPageNameDispatch() || undefined;
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Terms of service' });
  }, [headerTitleDispatcher]);
  return <Terms isLoggedIn />;
};

export default DoctorTerms;
