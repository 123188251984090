import {
  Box,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  withStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { ReactComponent as RecentActivityIcon } from 'assets/patientActivities/recent-title.svg';
import { GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID } from 'pages/PatientActivities';
import { gql, useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useGetDoctors from 'graphql/operations/doctorOperations/useGetDoctors';
import RecentActivityCard, {
  ActivitiesTypes,
  RecentActivityCardLoading,
} from 'pages/PatientActivities/RecentActivityCard';
import clsx from 'clsx';
import { ReactComponent as ArrowLeft } from 'assets/patient-profile/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/patient-profile/arrow-right.svg';
import Button from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import Menu from 'components/ui/Menu';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/sleep-2.svg';
import { ReactComponent as ReflectionIcon } from 'assets/patientActivities/reflection.svg';
import BasicModal from 'components/ui/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { CreateEditForm } from './CreateEditForm';
import { ReactComponent as AssignedActivityIcon } from 'assets/patientActivities/assigned-act.svg';
import { AssignedCard, AssignedCardLoading } from './AssignedCard';
import Checked from 'pages/AdminPage/Payment/Checked';
import { ReactComponent as CloseFilter } from 'assets/patient-profile/x-close.svg';
import useLogActivity from '@eggmed/graphql-client/operations/activityOperations/useLogActivity';
import { getCheckedTags } from './utils';
import { moodTags, sleepTags } from './CreateEditForm/tags';
import useUpdatePatientActivity from '@eggmed/graphql-client/operations/patientActivitiesOperations/useUpdatePatientActivity';
import useEditQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditQuestionnaire';
import { Empty } from './Empty';
import { ReactComponent as EmptyAssigned } from 'assets/patientActivities/assigned-illus.svg';
import { ReactComponent as EmptyRecent } from 'assets/patientActivities/activity-illus.svg';
import { useTranslation } from 'react-i18next';
import { useToast } from 'hooks/useToast';
import Text from 'Text';
import { useSnackbar } from 'hooks/useSnackbar';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';

export enum IActivitySource {
  ASSIGN = 'Assign',
  LOG = 'Log',
  EDIT = 'Edit',
}

export type IActivityToOpen = {
  source: IActivitySource;
  type: ActivitiesTypes;
  assignedAt?: any;
  activity?: any;
};

export const schema = yup.object().shape({});

const reflectionSchema = yup.object().shape({
  notes: yup.string().required(),
});

const FormInput = withStyles((theme) => ({
  input: {
    minWidth: '60px',
    width: '100%',
    height: '22px',
    position: 'relative',
    backgroundColor: ' #FFF',
    borderRadius: '6px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 4,
      border: '1px solid #0265DC',
    },
  },
}))(InputBase);

function PatientActivities() {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const { patient } = useAuth();
  const patientId = patient?._id;
  const { data: doctors } = useGetDoctors();
  const { logActivity, loading: loadingLog } = useLogActivity();
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();

  const { data: assignedAct, loading: loadingAssigned } = useQuery(
    GET_PATIENT_ACTIVITY,
    {
      variables: { patientId, isPending: true },
    }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [createEditOpen, setCreateEditOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<ActivitiesTypes[]>([]);
  const [activityToOpen, setActivityToOpen] = useState<IActivityToOpen>();
  const [formResponse, setFormResponse] = useState(activityToOpen?.activity);
  const [limit, setLimit] = useState<number>(4);
  const [skip, setSkip] = useState<number>(0);
  useEffect(() => {
    setFormResponse(activityToOpen?.activity);
  }, [activityToOpen]);

  const isEditMode = activityToOpen?.source === IActivitySource.EDIT;

  const { data, loading: loadingRecent } = useQuery(
    GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
    {
      variables: { patientId, doctorId: doctors && doctors?.doctors[0]?._id },
    }
  );

  const [handleUpdatePatientActivity, { loading: updateLoading }] =
    useUpdatePatientActivity();
  const [handleEditQuestionnaire, { loading: formLoading }] =
    useEditQuestionnaire();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCreateEdit = () => {
    setCreateEditOpen(true);
  };

  const handleCloseCreateEdit = () => {
    setCreateEditOpen(false);
    toggleSignature(false);
  };

  const MenuElements = [
    {
      cy: 'mood-check-in',
      avatar: <MoodIcon />,
      name: t('Mood check-in'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.MOOD,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
    {
      cy: 'sleep-check-in',
      avatar: <SleepIcon />,
      name: t('Sleep check-in'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.SLEEP,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
    {
      cy: 'reflection',
      avatar: <ReflectionIcon />,
      name: t('Reflection'),
      onClick: () => {
        setActivityToOpen({
          source: IActivitySource.LOG,
          type: ActivitiesTypes.REFLECTION,
        });
        handleOpenCreateEdit();
        handleMenuClose();
      },
    },
  ];

  const values = [
    ActivitiesTypes.MOOD,
    ActivitiesTypes.SLEEP,
    ActivitiesTypes.REFLECTION,
  ];

  const valuesMapping = {
    moodCheckin: t('Mood check-ins'),
    sleepCheckin: t('Sleep check-ins'),
    reflection: t('Reflections'),
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const doctorAndPatientId = {
    doctor: doctors && doctors?.doctors[0]?._id,
    patient: patientId,
  };

  const classes = useStyles();

  const schemaSelector = (activityToOpen: ActivitiesTypes) => {
    switch (activityToOpen) {
      case ActivitiesTypes.MOOD:
        return schema;
      case ActivitiesTypes.SLEEP:
        return schema;
      case ActivitiesTypes.REFLECTION:
        return reflectionSchema;
      default:
        return schema;
    }
  };

  const methods = useForm({
    resolver: yupResolver(schemaSelector(activityToOpen?.type)),
    defaultValues: {
      selectedEmoji: 'Okay',
      submitDate: new Date(),
    },
  });

  async function handleLogActivity(data: any) {
    const { patientActivitiesInput, currentDate } = data;
    await logActivity({
      variables: {
        patientActivitiesInput,
        currentDate,
      },
    });
    triggerSnack();
    handleCloseCreateEdit();
  }

  useEffect(() => {
    if (isEditMode) {
      methods.reset({
        selectedEmoji: activityToOpen?.activity?.patientResponse[0]?.response,
      });
    } else {
      methods.reset({
        selectedEmoji: 'Okay',
      });
    }
  }, [activityToOpen]);

  const moodSubmit = async (data: any) => {
    const { notes, selectedEmoji, files, submitDate } = data;

    const patientResponse = [
      {
        question: t('How are you feeling?'),
        response: selectedEmoji,
        image: files && files[0],
        notes: notes,
        tags: getCheckedTags(moodTags, selectedEmoji, data),
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: t('How are you feeling?'),
        type: 'moodCheckin',
        assignedAt: new Date(),
        patientResponse,
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };
      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };

  const sleepSubmit = async (data: any) => {
    const { notes, selectedEmoji, bedtime, wakeup, files, submitDate } = data;

    const patientResponse = [
      {
        question: t(
          'How would you describe the quality of your sleep last night?'
        ),
        response: selectedEmoji,
        sleepHours: bedtime,
        wakeHours: wakeup,
        image: files && files[0],
        notes: notes,
        tags: getCheckedTags(sleepTags, selectedEmoji, data),
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: t(
          'How would you describe the quality of your sleep last night?'
        ),
        type: 'sleepCheckin',
        assignedAt: new Date(),
        patientResponse,
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };

      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };

  const reflectionSubmit = async (data: any) => {
    const { entry, notes, files, submitDate } = data;

    const patientResponse = [
      {
        question: entry,
        response: notes,
        image: files && files[0],
        notes: notes,
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: entry,
        type: 'reflection',
        reflectionType: 'free',
        assignedAt: new Date(),
        patientResponse,
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };

      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };
  const { open, toggleSignature } = useEditorContext();
  const formSubmit = async (data) => {
    // const { ...rest } = formResponse;
    // const questions = rest.questions.map(({ ...rest }) => rest);
    const { data: dataSign } =
      JSON.parse(formResponse.blocks).find((el) => el.type === 'Signature') ||
      {};
    const isSign = !!dataSign && open;
    const payload = {
      title: formResponse.title,
      description: formResponse.description,
      patientResponseDate: new Date(),
      blocks: formResponse.blocks,
      questions: [],
      assignedAt: formResponse.assignedAt,
      doctor: formResponse.doctor._id,
      patient: patientId,
      isPatientSigned: isSign,
    };
    if (!!!dataSign) {
      await handleEditQuestionnaire({
        variables: { id: formResponse._id, questionnaireInput: payload },
        refetchQueries: [
          { query: QUESTIONNAIRE_BY_PATIENT_ID, variables: { patientId } },
          GET_PATIENT_ACTIVITY,
        ],
      });
    } else {
      if (isSign) {
        await handleEditQuestionnaire({
          variables: { id: formResponse._id, questionnaireInput: payload },
          refetchQueries: [
            { query: QUESTIONNAIRE_BY_PATIENT_ID, variables: { patientId } },
            GET_PATIENT_ACTIVITY,
          ],
        });
      } else return;
    }

    triggerSnack();
    handleCloseCreateEdit();
  };

  const onSubmit = async (data: any) => {
    switch (activityToOpen.type) {
      case ActivitiesTypes.MOOD:
        return await moodSubmit(data);
      case ActivitiesTypes.SLEEP:
        return await sleepSubmit(data);
      case ActivitiesTypes.REFLECTION:
        return await reflectionSubmit(data);
      case ActivitiesTypes.FORM:
        return await formSubmit(data);
      default:
        return;
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    setSelectedValues(value as ActivitiesTypes[]);
  };

  const recentActivity = React.useMemo(
    () =>
      data?.patientActivitiesByPatientDoctorId
        ?.filter((activity) => {
          const activityDate = new Date(
            activity?.patientResponse[0]?.createdAt
          );
          const currentDate = new Date();
          const dateCondition = activityDate <= currentDate;
          if (selectedValues.length === 0) {
            return dateCondition;
          } else {
            return dateCondition && selectedValues.includes(activity?.type);
          }
        })
        ?.sort((activityA, activityB) => {
          const dateA = new Date(
            activityA?.patientResponse[0]?.createdAt
          ).getTime() as number;
          const dateB = new Date(
            activityB?.patientResponse[0]?.createdAt
          ).getTime() as number;
          return dateB - dateA;
        }),
    [data, selectedValues]
  );
  const paginatedActivities = React.useMemo(() => {
    return recentActivity?.slice(skip, skip + limit);
  }, [recentActivity, limit, skip]);

  const handleNext = () => {
    if (skip + limit < recentActivity.length) {
      setSkip(skip + limit);
    }
  };
  const handlePrevious = () => {
    if (skip > 0) {
      setSkip(Math.max(0, skip - limit));
    }
  };
  return (
    <>
      <Box display="flex" alignItems="flex-start" gridGap="1rem">
        {/** assigned act */}

        <Box className={clsx(classes.background, classes.assignedWrapper)}>
          <Box display="flex" alignItems="center" gridGap="1rem">
            <AssignedActivityIcon />
            <Text i18nKey="assignedActivities" className={classes.title}>
              Assigned activities
            </Text>
          </Box>
          <Box display="flex" flexDirection="column" gridGap="1rem" mt={3}>
            {loadingAssigned ? (
              <>
                {[1, 2, 3, 4].map(() => (
                  <AssignedCardLoading />
                ))}
              </>
            ) : assignedAct &&
              assignedAct?.patientActivityByFilter.length > 0 ? (
              assignedAct?.patientActivityByFilter.slice(0, 4).map((el) => (
                <AssignedCard
                  activityType={el?.type}
                  assignedAt={el?.assignedAt}
                  formTitle={el?.title}
                  openComplete={() => {
                    setActivityToOpen({
                      source: IActivitySource.ASSIGN,
                      type: el?.type ?? ActivitiesTypes.FORM,
                      assignedAt: el?.assignedAt,
                      activity: el,
                    });
                    handleOpenCreateEdit();
                  }}
                />
              ))
            ) : (
              <Box
                height="70vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Empty
                  icon={<EmptyAssigned />}
                  title={t('No assigned activity yet')}
                  description={t(
                    'Here you will view the activities assigned by your provider'
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/**  Activities */}
        <Box className={clsx(classes.background, classes.recentWrapper)}>
          <Box className={classes.titleBar}>
            <Box className={classes.titleBarLeft}>
              <Box display="flex" alignItems="center" gridGap="1rem">
                <RecentActivityIcon />
                <Text i18nKey="activities" className={classes.title}>
                  Activities
                </Text>
                <Box>
                  <div className={clsx('splide__arrows', classes.arrows)}>
                    <button
                      className={clsx(
                        'splide__arrow splide__arrow--prev',
                        classes.arrow
                      )}
                      disabled={skip === 0}
                      onClick={handlePrevious}
                    >
                      <ArrowLeft />
                    </button>
                    <button
                      className={clsx(
                        'splide__arrow splide__arrow--next',
                        classes.arrow
                      )}
                      disabled={
                        recentActivity && recentActivity.length - skip <= limit
                      }
                      onClick={handleNext}
                    >
                      <ArrowRight />
                    </button>
                  </div>
                </Box>
              </Box>
              <Box>
                <FormControl>
                  <Select
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    input={<FormInput />}
                    renderValue={(selected) => (
                      <Box className={classes.selectedFilters}>
                        {(selected as ActivitiesTypes[]).map((value) => (
                          <Box key={value} className={classes.selectedFilter}>
                            <Typography className={classes.selectedFilterText}>
                              {valuesMapping[value]}
                            </Typography>
                            <CloseFilter />
                          </Box>
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      getContentAnchorEl: () => null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {values.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checked
                          color="#0265DC"
                          checkedValue={selectedValues.indexOf(value) != -1}
                          handleChange={() => {}}
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ gap: '1rem' }}
                        >
                          {valuesMapping[value]}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Button
                className={classes.addBtn}
                buttonType="reset"
                onClick={handleClick}
              >
                <AddIcon />
                <Text
                  style={{
                    color: 'white',
                    textTransform: 'unset',
                    display: 'flex',
                  }}
                  i18nKey="logActivity"
                >
                  {t('Log activity')}
                </Text>
              </Button>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                handleMenuClose={handleMenuClose}
                MenuElements={MenuElements}
              />
            </Box>
          </Box>

          <Grid container spacing={3}>
            {loadingRecent ? (
              <>
                {[1, 2, 3, 4].map(() => (
                  <Grid item xl={6} lg={6} md={6}>
                    <Box>
                      <RecentActivityCardLoading />
                    </Box>
                  </Grid>
                ))}
              </>
            ) : paginatedActivities && paginatedActivities.length > 0 ? (
              paginatedActivities?.map((element, id) => (
                <Grid item xl={6} lg={6} md={6} key={id}>
                  <Box>
                    <RecentActivityCard
                      {...element}
                      doctorAndPatientId={doctorAndPatientId}
                      isFromClient={true}
                      setActivityToOpen={setActivityToOpen}
                      handleOpenCreateEdit={handleOpenCreateEdit}
                    />
                  </Box>
                </Grid>
              ))
            ) : (
              <Box
                width="100%"
                height="70vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Empty
                  icon={<EmptyRecent />}
                  title={t("No activity yet. Let's get started.")}
                  description={t('To log your first activity')}
                  isRecent={true}
                  anchorEl={anchorEl}
                  handleMenuClose={handleMenuClose}
                  MenuElements={MenuElements}
                  handleClick={handleClick}
                />
              </Box>
            )}
          </Grid>

          <ul
            className={clsx(
              'splide__pagination splide__pagination--ltr',
              classes.pagination
            )}
            role="tablist"
          ></ul>
        </Box>
      </Box>
      <FormProvider {...methods}>
        <BasicModal
          open={createEditOpen}
          onClose={handleCloseCreateEdit}
          handleClose={handleCloseCreateEdit}
          isFromModal
          divider
          onSubmit={onSubmit}
          loading={loadingLog || updateLoading || formLoading}
          hasTitle={false}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '50vw',
                xl: '35vw',
              },
              height: '70vh',
            }}
          >
            <CreateEditForm
              activityType={activityToOpen}
              isEditMode={isEditMode}
              formResponse={formResponse}
              setFormResponse={setFormResponse}
            />
          </Box>
        </BasicModal>
      </FormProvider>
    </>
  );
}

export default PatientActivities;

export const GET_PATIENT_ACTIVITY = gql`
  query patientActivityByFilter(
    $patientId: ID!
    $doctorId: ID
    $type: String
    $date: Date
    $startDate: Date
    $endDate: Date
    $isCompleted: Boolean
    $isPending: Boolean
  ) {
    patientActivityByFilter(
      patientId: $patientId
      doctorId: $doctorId
      type: $type
      date: $date
      startDate: $startDate
      endDate: $endDate
      isCompleted: $isCompleted
      isPending: $isPending
    ) {
      title
      questions {
        _id
        question
        type
        options
        description
        required
        range
        responses
        isEdited
        isDescription
      }
      _id
      question
      options
      type
      notes
      photo
      sleep
      frequency {
        startDate
        endDate
        repetition {
          value
          unit
        }
        repeatOn
        frequencyType
      }
      assignedAt
      patientResponse {
        _id
        question
        response
        image
        sleepHours
        wakeHours
        notes
        createdAt
        tags
      }
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
      }
      activityUrl
      bookmarked
      formType
      blocks
    }
  }
`;

export const QUESTIONNAIRE_BY_PATIENT_ID = gql`
  query questionnairesByPatientId($patientId: ID) {
    questionnairesByPatientId(patientId: $patientId) {
      _id
      title
      description
      assignedAt
      patientResponseDate
      questions {
        _id
        question
        type
        options
        description
        isEdited
        isDescription
        required
        range
        responses
      }
      doctor {
        _id
        firstname
        lastname
      }
      patient {
        _id
      }
    }
  }
`;
