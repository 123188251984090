export const DATE_ACTION = 'setDate';
export const VIEW_ACTION = 'setView';
export const EVENT_ACTION = 'setEvent';
export const EDIT_MODE_ACTION = 'setEditMode';
export const MODAL_ADD_ACTION = 'setModalAddOpen';
export const DATE_RANGE_ACTION = 'setDateRange';
export const CURRENT_DATE_ACTION = 'setCurrentDate';
export const CLOSE_NOT_USED = 'setNotUsed';
export const RESET_CURRENT_EVENT = 'resetCurrentEvent';
export const SET_BACK = 'setBack';
export const OPEN_PATIENT_MODAL = 'openPatientModal';
export const CLOSE_PATIENT_MODAL = 'closePatientModal';
export const CHANGE_TAGS = 'changeTags';
export const ADD_PATIENT = 'addPatient';
export const Manage_CONFLICT = 'manageConflict';
