import React from 'react';
import { useStyles } from '../Subscription';
import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import Skeleton from 'components/ui/Skeleton';
import CustomButton from 'components/ui/Button';
import Text from 'Text';
import { subscriptionProps } from 'contexts/AuthContext';
import { ReactComponent as Boost } from 'assets/Boost.svg';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

interface Props {
  loading: boolean;
  subscriptionData: subscriptionProps;
  handleOpen: () => void;
}
const LastDayTrial = ({ loading, subscriptionData, handleOpen }: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  return (
    <Box>
      <Skeleton loading={loading}>
        <Box className={classes.canceledBox}>
          <Skeleton loading={loading}>
            <Typography className={classes.trialLabel}>
              {t('Trial ending today')}
            </Typography>
          </Skeleton>
          <Typography className={classes.trialDescription}>
            {t('Activte your subscription to keep using Eggmed')}{' '}
          </Typography>
          <CustomButton
            className={classes.addBtn}
            buttonType="reset"
            onClick={handleOpen}
          >
            <Text i18nKey="activatePlan" style={{ fontWeight: 500 }}>
              {t('Activate your plan')}
            </Text>
          </CustomButton>
        </Box>
      </Skeleton>

      <Skeleton loading={loading}>
        <Box mt={4}>
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="35%"
          >
            <Box>
              <Typography className={classes.currentPln}>
                {t('Free trial')}
              </Typography>
              <Typography className={classes.expireLabel}>
                {t('Activate your plan to continue using Eggmed')}{' '}
              </Typography>
            </Box>
            <CustomButton
              className={classes.activateBtn}
              buttonType="reset"
              onClick={handleOpen}
            >
              <Boost />
              <Text i18nKey="Activate" style={{ fontWeight: 500 }}>
                Activate
              </Text>
            </CustomButton>
          </Box>
          <Box borderBottom="1px solid #EAECF0" pb={4} width="35%">
            <Box
              px={4}
              mt={2}
              display="flex"
              alignItems="center"
              style={{ gap: '3rem' }}
            >
              <Box display="flex" flexDirection="column">
                <Box mt={3}>
                  <Typography className={classes.trialDate}>
                    {t('Trial end date')}{' '}
                  </Typography>
                  <Typography
                    className={classes.trialValue}
                    style={{ color: '#B42318' }}
                  >
                    {dayjs(subscriptionData?.endDate).format(
                      i18n.language === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>{' '}
          </Box>
        </Box>
      </Skeleton>
    </Box>
  );
};

export default LastDayTrial;
