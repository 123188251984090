import { gql, useMutation } from '@apollo/client';

const DELETE_REFERRAL = gql`
  mutation deleteReferral($referralId: ID!) {
    deleteReferral(referralId: $referralId) {
      _id
    }
  }
`;

export default function useDeleteReferral() {
  const [deleteReferral, result] = useMutation(DELETE_REFERRAL, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentReferrals() {
            return data?.deleteReferral;
          },
        },
      });
    },
  });
  return { deleteReferral, ...result };
}
