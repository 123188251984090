import { gql, useMutation } from '@apollo/client';

const ADD_PRESCRIPTION = gql`
  mutation addPrescription($prescriptionInput: addPrescriptionInput!) {
    addPrescription(prescriptionInput: $prescriptionInput) {
      _id
      appointmentDate
      # advice
      startDate
      endDate
      drugs {
        _id
        name
        type
        dosage
        duration
        frequency
        advice
        durationType
      }
      allowGenerate
    }
  }
`;

export default function useAddPrescription() {
  const [createPrescription, result] = useMutation(ADD_PRESCRIPTION, {
    update: (cache, { data: { addPrescription } }) => {
      cache.modify({
        fields: {
          appointmentPrescription() {
            return addPrescription;
          },
        },
      });
    },
  });
  return { createPrescription, ...result };
}
