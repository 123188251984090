import { gql, useMutation } from '@apollo/client';

export const DELETE_CREDIT_CARD_BY_ID = gql`
  mutation deleteCreditCardbyID($creditCardId: ID!) {
    deleteCreditCardbyID(creditCardId: $creditCardId) {
      cardNumber
      expirationCard
      securityCode
    }
  }
`;
export default function useDeleteCreditCardByID() {
  const [deleteCreditCardbyID] = useMutation(DELETE_CREDIT_CARD_BY_ID, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          userCreditCard() {
            return data?.deleteCreditCardByID;
          },
        },
      });
    },
  });
  return { deleteCreditCardbyID };
}
