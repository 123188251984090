import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/errorSummary.svg';
import Text from 'Text';
import { useStyles } from './styles';

function ErrorSnackBar({
  handleClick,
  setDownloadStarted,
}: {
  handleClick: () => void;
  setDownloadStarted: (value: boolean) => void;
}) {
  const classes = useStyles({});
  useEffect(() => {
    const timer = setTimeout(() => {
      setDownloadStarted(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [setDownloadStarted]);
  return (
    <Box className={classes.errorContainer}>
      <ErrorIcon />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text
          i18nKey="Download-failed"
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600,
            color: '#912018',
          }}
        >
          Download failed
        </Text>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Text
            i18nKey="clickHere"
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              color: '#147AF3',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            Click here
          </Text>
          <Text
            i18nKey="try-again"
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 400,
              color: '#912018',
              marginLeft: '4px',
            }}
          >
            to try again
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorSnackBar;
