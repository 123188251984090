import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateEnd from '../animations/final.json';

export const End = () => {
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateEnd}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>You are all set 🌟</h3>
        <p className={classes.description}>Let’s get started!</p>
      </div>
    </div>
  );
};
