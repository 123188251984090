import React, { ReactElement } from 'react';
import { Control, Ref, FieldErrors } from 'react-hook-form';
import { BoxProps, FormLabel, Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

import Input from 'components/ui/Inputs';
import useStyles from './styles';

interface IPrescriptionInputBlockProps extends BoxProps {
  name: string;
  register?: (ref: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  label?: string;
  iconClassName?: string;
  LabelIcon?: any;
  type: string;
  value?: any;
  options?: string[];
  labelClassName?: string;
  draftClassName?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  InputProps?: { rows: number };
}

export default function InputBlock({
  name,
  register,
  control,
  className,
  errors,
  LabelIcon,
  iconClassName,
  label,
  labelClassName,
  draftClassName,
  ...props
}: IPrescriptionInputBlockProps): ReactElement {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" mb={1} {...props}>
      <FormLabel
        className={clsx(
          className,
          classes.label,
          classes.labelSpacing,
          labelClassName
        )}
      >
        {LabelIcon && <LabelIcon className={iconClassName} />}
        {label || '\xa0'}
      </FormLabel>
      <Input
        inputRef={register}
        control={control}
        name={name}
        variant="filled"
        className={draftClassName}
        helperText={
          errors &&
          errors[name] && (
            <span>
              <ErrorIcon className={classes.errorIcon} />
              {errors[name]?.message}
            </span>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </Box>
  );
}
