import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const CREATE_CREDIT_CARD = gql`
  mutation createCreditCard($creditCardInput: CreditCardInput) {
    createCreditCard(creditCardInput: $creditCardInput) {
      nameOnCard
      cardNumber
      expirationCard
      cardZipCode
      securityCode
      LastName
      MiddleName
      Phone
      Email
      StreetAddress1
      StreetAddress2
      City
      ZipCode
      Country
      month
      year
      ProvinceState
      FirstName
      selectedCreditCard
    }
  }
`;
export default function useCreateCreditCard(): {
  createCreditCard: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  loading: boolean;
} {
  const [createCreditCard, { loading }] = useMutation(CREATE_CREDIT_CARD, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          userCreditCard() {
            return data?.addCreditCard;
          },
        },
      });
    },
  });
  return { createCreditCard, loading };
}
