import { MutationResult, useMutation } from '@apollo/client';
import { EDIT_SESSION } from '../../queries/appointment';

interface IUseEditReturn extends MutationResult<any> {
  updateSession: (id: string, data: any) => Promise<any>;
}
export default function useEditSession(): IUseEditReturn {
  const [editSession, result] = useMutation(EDIT_SESSION, {
    update: (cache, { data: { sessionEdit: sessionEditResult } }) => {
      cache.modify({
        fields: {
          appointmentsByDate(appointments) {
            const editedAppointments = appointments?.result?.map(
              (appointment: any) => {
                if (appointment?._id === sessionEditResult?._id)
                  return sessionEditResult;
                return appointments;
              }
            );
            return { ...appointments, result: editedAppointments };
          },
          doctorUpcomingAppointments(upcomingAppointment) {
            return [...upcomingAppointment?.result, sessionEditResult];
          },
          conflictsAppointments(appointments) {
            return [...appointments, sessionEditResult];
          },
          availableTimes(times) {
            const date = new Date(sessionEditResult?.startDate);
            const hour = date.getHours().toString().padStart(2, '0');
            const minute = date.getMinutes().toString().padStart(2, '0');
            const formattedTime = hour + ':' + minute;
            return times?.filter((el) => el !== formattedTime);
          },
        },
      });
    },
  });
  async function updateSession(id: string, data: any) {
    try {
      const resultUpdateSession = await editSession({
        variables: { sessionId: id, sessionInput: data },
      });
      return resultUpdateSession;
    } catch (e) {
      console.log('error', e);
    }
  }

  return {
    updateSession,
    ...result,
  };
}
