import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { convertToRawAndStringify } from '@eggmed/common/utils/DraftUtils';
import { PATIENTS_RELATED } from 'pages/PatientListPage';

const GET_CONDITIONS = gql`
  query getConditions($patientId: ID!) {
    conditions(patientId: $patientId) {
      patientId
      _id
      treatments
      name
      dateAdded
      dateEnded
      type
    }
  }
`;
const ADD_CONDITION = gql`
  mutation addCondition($ConditionInput: ConditionInput!) {
    addCondition(ConditionInput: $ConditionInput)
  }
`;
const EDIT_CONDITION = gql`
  mutation editCondition($ConditionInput: ConditionInput!, $conditionId: ID!) {
    editCondition(ConditionInput: $ConditionInput, conditionId: $conditionId)
  }
`;

const DELETE_CONDITION = gql`
  mutation deleteCondition($conditionId: ID!) {
    deleteCondition(conditionId: $conditionId)
  }
`;

export default function useConditions({ patientId }: any) {
  const { push } = useHistory();
  const { data, error, loading } = useQuery(GET_CONDITIONS, {
    variables: { patientId },
  });
  const [addCondition, { loading: loadingAddMutation }] = useMutation(
    ADD_CONDITION,
    {
      refetchQueries: [
        { query: GET_CONDITIONS, variables: { patientId } },
        { query: GET_PATIENT, variables: { patientId } },
        { query: PATIENTS_RELATED },
      ],
      awaitRefetchQueries: false,
    }
  );
  const [editCondition, { loading: loadingEditMutation }] = useMutation(
    EDIT_CONDITION,
    {
      refetchQueries: [{ query: GET_CONDITIONS, variables: { patientId } }],
      awaitRefetchQueries: false,
    }
  );
  const [deleteCondition] = useMutation(DELETE_CONDITION, {
    refetchQueries: [{ query: GET_CONDITIONS, variables: { patientId } }],
    awaitRefetchQueries: false,
  });
  async function handleDeleteCondition(conditionId: string) {
    try {
      await deleteCondition({ variables: { conditionId } });
    } catch (error) {
      push('/error');
    }
  }
  async function handleAddCondition({
    treatments,
    name,
    dateAdded,
    dateEnded,
    status,
    type,
  }: any) {
    try {
      await addCondition({
        variables: {
          ConditionInput: {
            patientId,
            treatments: treatments && convertToRawAndStringify(treatments),
            dateEnded,
            name,
            dateAdded,
            type,
          },
        },
      });
    } catch (error) {
      push('/error');
    }
  }
  async function handleEditCondition({
    treatments,
    name,
    dateAdded,
    dateEnded,
    type,
    status,
    _id,
  }: any) {
    try {
      await editCondition({
        variables: {
          ConditionInput: {
            patientId,
            dateEnded,
            treatments: convertToRawAndStringify(treatments),
            name,
            dateAdded,
            type,
            status,
          },
          conditionId: _id,
        },
      });
    } catch (error) {
      push('/error');
    }
  }
  return {
    handleEditCondition,
    handleAddCondition,
    handleDeleteCondition,
    data,
    error,
    loading,
    addCondition,
    deleteCondition,
    editCondition,
    loadingEditMutation,
    loadingAddMutation,
  };
}
