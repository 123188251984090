import React from 'react';
import { Box, Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as RatesLogo } from 'assets/emptyState/payment.svg';
import Button from 'components/ui/Button';
import BasicModal from 'components/ui/Modal';
import RatesModal from './RatesModal';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import Table from 'components/ui/Table';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DropDownMenu from './DropDownMenu';
import Checked from './Checked';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useToast } from 'hooks/useToast';
import { IRates } from './type';
import { IColumn } from 'components/ui/Table/types';
import { useRate } from './useRate';
import EmptyComponent from './EmptyComponent';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { useSnackbar } from 'hooks/useSnackbar';

export const schema = yup.object().shape({
  session: yup.string().required('Session is a required field'),
  duration: yup
    .number()
    .typeError('Duration must be a number')
    .required('Duration is a required field'),
  currency: yup.string().required('Currency is a required field'),
  rate: yup
    .number()
    .typeError('Session rates must be a number')
    .required('Session rates is a required field'),
});

export const useStyles = makeStyles<Theme>((theme) => ({
  textTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '18px',
    color: '#425364',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20px',
    color: '#425364',
  },
  description: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    color: '#425364',
  },
  btnAdd: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  btn: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    marginTop: theme.spacing(2),
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#147AF3',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '17px',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
}));

const Rates = () => {
  async function handleChange(row: IRates) {
    await updateRate({
      variables: {
        rateId: (row as IRates)?._id,
        updateRate: {},
        isSelect: true,
      },
    });
  }
  const columns: IColumn<IRates>[] = [
    {
      id: 'session',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="name">Name</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 170,
      format: (value, row) => (
        <>
          <Checked
            checkedValue={(row as { selected: boolean })?.selected === true}
            handleChange={() => handleChange(row)}
            disabled={row?.isDefault}
          />
          {value}
        </>
      ),
    },
    {
      id: 'duration',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => value,
    },
    {
      id: 'currency',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="currency">Currency</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'rate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="pricing">Pricing</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'isDefault',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="default">Default</Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => (value ? 'Yes' : 'No'),
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem">Action</Box>,
      minWidth: 10,
      align: 'left',
      format: (value, row) => (
        <MoreHorizIcon
          data-cy="more-action"
          style={{ cursor: 'pointer', fontSize: '24px', color: '#425364' }}
          onClick={(e) => handleClickDrop(e, row)}
        />
      ),
    },
  ];
  const classes = useStyles();
  const { t } = useTranslation();
  const { doctor } = useAuth();
  const {
    createRate,
    createLoading,
    updateRate,
    updateLoading,
    handleDelete,
    dropDownEL,
    setDropDownEl,
    currentRow,
    openDelete,
    setopenDelete,
    data,
    handleClickDrop,
    editMode,
    open,
    handleClick,
    handleClose,
    handleEdit,
    deleteLoading,
  } = useRate();
  const { triggerToast } = useToast();
  const { triggerSnack } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: editMode ? currentRow : {},
  });

  async function onSubmit(data: IRates) {
    const { duration, rate, ...rest } = data || {};
    if (editMode) {
      triggerSnack();
      await updateRate({
        variables: {
          rateId: (currentRow as { _id: string })?._id,
          updateRate: {
            ...rest,
            duration: parseInt(duration, 10),
            rate: parseInt(rate, 10),
          },
          isSelect: false,
        },
      });
      return handleClose();
    }
    triggerSnack();
    await createRate({
      variables: {
        rateInput: {
          ...rest,
          doctor: doctor?._id,
          duration: parseInt(duration, 10),
          rate: parseInt(rate, 10),
        },
      },
    });
    handleClose();
  }
  if (data?.rates?.length === 0) {
    return (
      <EmptyComponent
        logo={<RatesLogo />}
        labelText="No pay rates"
        labelDescription="to add your pay rates"
        btnLabel="Start now"
        handleClick={handleClick}
        methods={methods}
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
        modal={<RatesModal currentRow={currentRow} editMode={editMode} />}
        modalTitle={'New Pay Rate'}
      />
    );
  }
  return (
    <Box pb={6}>
      <Text i18nKey="Services" className={classes.title}>
        Services
      </Text>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text
          i18nKey="Manage your services and associated rates and duration"
          className={classes.description}
        >
          Manage your services and associated rates and duration
        </Text>
        <Button className={classes.addBtn} onClick={handleClick}>
          <AddTaskIcon
            style={{
              paddingRight: '6px',
            }}
          />
          <Text i18nKey="add">Add</Text>
        </Button>
      </Box>

      <Box mt={2}>
        <Table
          loading={false}
          page={0}
          columns={columns}
          limit={5}
          data={data?.rates || []}
        />
      </Box>

      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          divider
          title={editMode ? t('Edit session') : t('Add new session')}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={createLoading || updateLoading}
        >
          <Grid container style={{ marginBottom: '5vh' }}>
            <RatesModal currentRow={currentRow} editMode={editMode} />
          </Grid>
        </BasicModal>
      </FormProvider>
      <DropDownMenu
        handleEdit={() => handleEdit()}
        anchorEl={dropDownEL}
        handleClose={() => setDropDownEl(null)}
        handleDelete={() => setopenDelete(true)}
        row={currentRow}
      />
      <BasicModal
        open={openDelete}
        onClose={() => setopenDelete(false)}
        className={classes.deleteModal}
        isRegularModal
        handleClose={() => setopenDelete(false)}
      >
        <DeleteModalContent
          text="session"
          handleClose={() => setopenDelete(false)}
          handleDelete={() => handleDelete()}
          p={4}
          loading={deleteLoading}
        />
      </BasicModal>
    </Box>
  );
};

export default Rates;
