import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import InputBlock from './InputBlock';
import { IContactModal } from './ContactModal';
import { useTranslation } from 'react-i18next';

const DemographicsModal = ({ patient }: IContactModal): JSX.Element => {
  const { register, control, errors } = useFormContext();
  const commonProps = { register, control, errors };
  const { t } = useTranslation();
  return (
    <Box px={'2rem'}>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Date of birth')}
            {...commonProps}
            name="birthday"
            type="date"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Gender')}
            {...commonProps}
            name="gender"
            type="select"
            options={[
              'male',
              'female',
              'non-binary',
              'prefer not to say',
              'other',
            ]}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Relationship status')}
            {...commonProps}
            name="relationshipStatus"
            type="select"
            options={[
              'single',
              'in a relationship',
              'married',
              'domestic partnership',
              'separated',
              'divorced',
              'widowed',
              'engaged',
              'open relationship',
              'prefer not to say',
              'other',
            ]}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Employment status')}
            {...commonProps}
            name="employmentStatus"
            type="select"
            options={[
              'employed full time',
              'employed part time',
              'self employed',
              'unemployed',
              'retired',
              'student',
              'homemaker',
              'prefer not to say',
              'other',
            ]}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Race and ethnicity')}
            {...commonProps}
            name="raceAndEthnicity"
            type="select"
            options={[
              'white',
              'black or african american',
              'asian',
              'native american or alaska native',
              'native hawaiian or other pacific islander',
              'middle eastern or north african',
              'biracial multiracial',
              'prefer not to say',
              'other',
            ]}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            label={t('Religion')}
            {...commonProps}
            name="religion"
            type="select"
            options={[
              'christian',
              'muslim',
              'jewish',
              'hindu',
              'buddhist',
              'sikh',
              'atheist',
              'prefer not to say',
              'other',
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DemographicsModal;
