import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_SUBSCRIPTION } from './useGetSubscription';

export const SUBSCRIBE = gql`
  mutation subscribeEggmed($subscribeInput: SubscribeInput) {
    subscribeEggmed(subscribeInput: $subscribeInput)
  }
`;

export default function useSubscribe() {
  const [subscribeEggmed, { loading, error }] = useMutation(SUBSCRIBE, {
    refetchQueries: [
      {
        query: GET_DOCTOR_SUBSCRIPTION,
      },
    ],
  });

  return { subscribeEggmed, loading, error };
}
