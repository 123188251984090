import React, { ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/ui/Button';
import { classicNameResolver } from 'typescript';

const SelectEdited = withStyles({
  select: {
    height: '16px',
  },
  outlined: {
    backgroundColor: 'white !important',
  },
})(Select);
interface IPatientListProps {
  handleOpen: () => void;
  limit: number;
  setLimit: (number: number) => void;
}

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
}));

export default function AccessListHeader({
  handleOpen,
  limit,
  setLimit,
}: IPatientListProps): ReactElement {
  function handleLimitChange(event: React.ChangeEvent<any>) {
    setLimit(parseInt(event.target.value, 10));
  }
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.header}>
      <Box display="flex" alignItems="center">
        <span style={{ paddingRight: '5px' }}>Show</span>
        <SelectEdited
          native
          variant="outlined"
          value={limit}
          data-cy="limit-select"
          style={{ height: '40px' }}
          onChange={handleLimitChange}
          inputProps={{
            name: 'limit',
            id: 'outlined-limit-native-simple',
          }}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </SelectEdited>
        <span style={{ paddingLeft: '5px' }}> entries</span>
      </Box>
      <Button
        data-cy="add-patient-button"
        onClick={handleOpen}
        type="success"
        variant="contained"
        style={{ backgroundColor: '#1F61DC', display: 'none' }}
      >
        MANAGE ACCESS
      </Button>
    </Box>
  );
}
