import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Table, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../Pagination';
import { calculatePaginationTabs, getLimitData } from './tableutils';
import TableHeadRow from './TableHeadRow';
import TableBodyRow from './TableBodyRow';
import { IColumn } from './types';

const useStyles = makeStyles({
  table: {},
});

interface ITableProps<T> {
  columns: IColumn<T>[];
  data: T[];
  handlePagination?: (event: any, page: number) => void;
  limit: number;
  page: number;
  pagination?: boolean;
  downSortIcon?: any;
  upSortIcon?: any;
  sorting?: boolean;
  loading: boolean;
  type?: 'rounded' | 'normal' | 'gray';
  count?: number;
  EmptyState?: string | ReactElement;
  stateText?: string;
  textDetails?: string;
  className?: string;
  fullData?: boolean;
  onRowClick?: (row: T) => void;
  onMouseEnterRow?: (row: T) => void;
  onMouseLeaveRow?: (row: T) => void;
}

export default function CustomizedTables<T>({
  columns,
  data,
  handlePagination,
  downSortIcon,
  upSortIcon,
  limit,
  page,
  sorting = false,
  pagination = false,
  loading = false,
  type = 'rounded',
  count,
  EmptyState,
  stateText,
  textDetails,
  fullData = true,
  className,
  onRowClick,
  onMouseEnterRow,
  onMouseLeaveRow,
}: ITableProps<T>): ReactElement {
  const classes = useStyles();
  const [tableData, setTableData] = useState<T[]>(data);
  useEffect(() => {
    setTableData(data);
  }, [data]);
  const limitedData = getLimitData<T>(page, limit, tableData, fullData);
  if (data?.length === 0 && !loading) return <>{EmptyState}</>;
  return (
    <>
      <Box>
        <TableContainer data-cy="table-container">
          <Table
            classes={{ root: classes.table }}
            aria-label="customized table"
          >
            <TableHeadRow<T>
              type={type}
              columns={columns}
              tableData={tableData}
              initialData={data}
              setTableData={setTableData}
              DownSortIcon={downSortIcon}
              UpSortIcon={upSortIcon}
              loading={loading}
              sorting={sorting}
            />
            <TableBodyRow<T>
              type={type}
              loading={loading}
              columns={columns}
              sorting={sorting}
              data={limitedData}
              stateText={stateText}
              textDetails={textDetails}
              className={className}
              onClick={onRowClick}
              onMouseEnterRow={onMouseEnterRow}
              onMouseLeaveRow={onMouseLeaveRow}
            />
          </Table>
        </TableContainer>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={1.5}
        >
          {pagination && (
            <Pagination
              count={calculatePaginationTabs<T>(limit, count || data.length)}
              onChange={handlePagination}
              page={page}
              variant="outlined"
              shape="rounded"
            />
          )}
        </Box>
      </Box>
    </>
  );
}
