import { gql, useMutation } from '@apollo/client';

const DELETE_DOCTOR_TEMPLATE = gql`
  mutation deleteDoctorTemplateById($id: ID!) {
    deleteDoctorTemplateById(id: $id) {
      _id
    }
  }
`;

const useDeleteDoctorTemplate = () => {
  return useMutation(DELETE_DOCTOR_TEMPLATE);
};

export default useDeleteDoctorTemplate;
