import React from 'react';
import AppointmentListPageProvider from 'pages/AppointmentListPage/state/Provider';
import AppointmentPage from './AppointmentPage';
import AppointmentPageProvider from './state/Provider';

export default function index() {
  return (
    <AppointmentListPageProvider>
      <AppointmentPageProvider>
        <AppointmentPage />
      </AppointmentPageProvider>
    </AppointmentListPageProvider>
  );
}
