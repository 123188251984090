import React, { ReactElement } from 'react';
import { Box, Backdrop, Modal, Slide } from '@material-ui/core';
import { useToast } from 'hooks/useToast';
import useStyles from './styles';
import clsx from 'clsx';

interface IProps {
  children: React.ReactElement;
  handleClose?: () => void;
  className?: string;
  open?: boolean;
  direction?: 'left' | 'up' | 'right' | 'down';
}

export default function NoteTakingModal({
  open,
  handleClose,
  children,
  className,
  direction = 'up',
}: IProps): ReactElement {
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess, disablePadding: false });
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // className={classes.slide}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableScrollLock={false}
    >
      <Slide direction={direction} in={open} mountOnEnter unmountOnExit>
        <Box style={{ outline: 'none' }}>
          <Box className={clsx(className)}>
            <Box>{children}</Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}
