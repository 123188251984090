import { gql, useMutation } from '@apollo/client';
import {
  addPrescriptionDrug,
  addPrescriptionDrugVariables,
} from './__generated__/addPrescriptionDrug';

const ADD_PRESCRIPTION_DRUG = gql`
  mutation addPrescriptionDrug(
    $prescriptionDrugInput: PrescriptionDrugsInput!
    $prescriptionId: ID
  ) {
    addPrescriptionDrug(
      prescriptionDrugInput: $prescriptionDrugInput
      prescriptionId: $prescriptionId
    ) {
      _id
      appointmentDate
      startDate
      endDate
      drugs {
        _id
        name
        type
        dosage
        duration
        frequency
        advice
        durationType
      }
    }
  }
`;

export default function useAddPrescriptionDrug() {
  const [createPrescriptionDrug, result] = useMutation<
    addPrescriptionDrug,
    addPrescriptionDrugVariables
  >(ADD_PRESCRIPTION_DRUG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentPrescription() {
            return data?.addPrescriptionDrug;
          },
        },
      });
    },
  });
  return { createPrescriptionDrug, ...result };
}
