import React, { ReactNode } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
interface IAccordionProps {
  AccoridonSummary: JSX.Element;
  children: ReactNode;
  style?: any;
  square?: boolean;
  defaultExpanded?: boolean;
  summaryClassName?: string;
  className?: string;
  expanded?: boolean;
  onChange?: () => void;
}
const useStyles = makeStyles(() => ({
  summary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
}));
const AccordionContainer = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const Accordion = ({
  children,
  AccoridonSummary,
  summaryClassName,
  className,
  ...props
}: IAccordionProps) => {
  const classes = useStyles();
  return (
    <AccordionContainer square defaultExpanded {...props} className={className}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        className={clsx(classes.summary, summaryClassName)}
      >
        {AccoridonSummary}
      </AccordionSummary>
      {children}
    </AccordionContainer>
  );
};

export default Accordion;
