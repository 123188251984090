import { useMutation, gql } from '@apollo/client';
import { log } from 'config/log';

interface IMutationVariables {
  patientId: string;
  EditPatientInput: any;
  picture: File | undefined;
}

const EDIT_PATIENT = gql`
  mutation updatePatient(
    $patientId: ID!
    $EditPatientInput: EditPatientInput!
    $picture: Upload
  ) {
    editPatient(
      patientId: $patientId
      EditPatientInput: $EditPatientInput
      picture: $picture
    ) {
      _id
      firstname
      lastname
      middlename
      phone
      doctorId
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      username
      weight {
        value
        type
      }
      height {
        value
        type
      }
      languages
      bloodType
    }
  }
`;

export default function useEditPatient() {
  const [editPatient, result] = useMutation(EDIT_PATIENT, {
    update: (cache, { data: { editPatient: editPatientData } }) => {
      cache.modify({
        fields: {
          patient() {
            try {
              return editPatientData;
            } catch (e) {
              return log('error', e);
            }
          },
        },
      });
    },
    refetchQueries: [{ query: PATIENTS_RELATED }],
  });

  async function updatePatient(
    variables: IMutationVariables,
    callback: (e: Error) => void
  ) {
    try {
      const resultData = await editPatient({ variables });
      return resultData;
    } catch (e) {
      return callback(e as Error);
    }
  }

  return { updatePatient, editPatient, ...result };
}

export const PATIENTS_RELATED = gql`
  query patientsRelated {
    patientsRelated {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
      updatedAt
      conditions {
        name
      }
    }
  }
`;
