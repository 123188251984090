/* eslint-disable*/
import { useMutation } from '@apollo/client';
import { PATIENT_GOING } from '../../queries/appointment';
import { PATIENT_NOT_GOING } from '../../queries/appointment';
import { DOCTOR_GOING } from '../../queries/appointment';
import { DOCTOR_NOT_GOING } from '../../queries/appointment';

const useUpdateAppointment = () => {
  const [patientIsGoing] = useMutation(PATIENT_GOING);
  const [patientIsNotGoing] = useMutation(PATIENT_NOT_GOING);
  const [doctorIsGoing] = useMutation(DOCTOR_GOING);
  const [doctorIsNotGoing] = useMutation(DOCTOR_NOT_GOING);
  async function handlePatientIsGoing(appointmentId) {
    try {
      return await patientIsGoing({
        variables: { appointmentId },
      });
    } catch (e) {
      return e;
    }
  }
  async function handlePatientIsNotGoing(appointmentId) {
    try {
      return await patientIsNotGoing({
        variables: { appointmentId },
      });
    } catch (e) {
      return e;
    }
  }
  async function handleDoctorIsGoing(appointmentId) {
    try {
      return await doctorIsGoing({
        variables: { appointmentId },
      });
    } catch (e) {
      return e;
    }
  }
  async function handleDoctorIsNotGoing(appointmentId) {
    try {
      return await doctorIsNotGoing({
        variables: { appointmentId },
      });
    } catch (e) {
      return e;
    }
  }
  return {
    handlePatientIsGoing,
    handlePatientIsNotGoing,
    handleDoctorIsGoing,
    handleDoctorIsNotGoing,
  };
};
export default useUpdateAppointment;
