import { useEffect } from 'react';
import useUpdateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useChangeGoingStat';
import useGetAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGetAppointment';

interface IProps {
  handleEditCurrentEvent: (event: any) => void;
  handleOpenAddModal: () => void;
  isDoctor: boolean;
}


async function roleBaseAction<T>(
  isDoctor: boolean,
  doctorAction: (payload: T) => Promise<void>,
  patientAction: (payload: T) => Promise<void>,
  payload: T
) {
  if (isDoctor) {
    await doctorAction(payload);
  } else {
    await patientAction(payload);
  }
}

export function useHandleMailsAction({
  handleEditCurrentEvent,
  handleOpenAddModal,
  isDoctor,
}: IProps) {
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');
  const status = queryParams.get('status');
  const state = queryParams.get('state');
  const {
    handleDoctorIsGoing,
    handleDoctorIsNotGoing,
    handlePatientIsGoing,
    handlePatientIsNotGoing,
  } = useUpdateAppointment();

  const { data } = useGetAppointment({ appointmentId: action });
  useEffect(() => {
    if (action && status !== 'reshedule') {
      if (status === 'accept') {
        roleBaseAction<string>(
          isDoctor,
          handleDoctorIsGoing,
          handlePatientIsGoing,
          action
        );
      } else {
        roleBaseAction<string>(
          isDoctor,
          handleDoctorIsNotGoing,
          handlePatientIsNotGoing,
          action
        );
      }
    }
  }, []);

  useEffect(() => {
    if (data?.appointment && action && status === 'reshedule') {
      handleEditCurrentEvent(data?.appointment);
    }
  }, [action, data?.appointment, status]);

  useEffect(() => {
    if (state === 'newAppointment') {
      handleOpenAddModal();
    }
  }, [state]);
}
