import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useProvider } from 'pages/InboxPage/state/Provider';
import useStyles from './style';
import MessagesList from './MessagesList';
import FileList from './FileList';
import InputMessage from './InputMessage';
import EmptyMessageIllustration from './EmptyMessageIllustration';
import useHeightObserver from 'hooks/useHeightObserver';
interface Props {
  patient?: boolean;
  setMessageHidden?: (value: boolean) => void;
}

const Message = ({ patient, setMessageHidden }: Props) => {
  const [height, setHeight] = useState<number>();
  const classes = useStyles({ patient, height });
  const [filesHidden, setFilesHidden] = React.useState(true);
  const { isEmptyConversationList, messagesData, listFiLes, selected } =
    useProvider();
  const isSelected = !isEmptyConversationList && !!selected;

  const observedElementRef = useHeightObserver((height) => {
    setHeight(height);
  });

  return (
    <Grid container className={classes.fullHeight}>
      <Grid
        item
        xl={isSelected ? 9 : 12}
        lg={isSelected ? 9 : 12}
        md={12}
        xs={12}
        sm={12}
        className={filesHidden ? classes.messageListContainer : classes.hidden}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.fullHeight}
        >
          <Box className={classes.msgContentWrapper}>
            {isEmptyConversationList && (
              <EmptyMessageIllustration isEmpty={isEmptyConversationList} />
            )}
            {messagesData && !isEmptyConversationList && selected ? (
              <MessagesList
                data={messagesData}
                patient={patient}
                setFilesHidden={setFilesHidden}
              />
            ) : (
              <EmptyMessageIllustration isEmpty={isEmptyConversationList} />
            )}
          </Box>
          <div ref={observedElementRef} className={classes.msgInputWrapper}>
            {isSelected && <InputMessage />}
          </div>
        </Box>
      </Grid>
      {isSelected && (
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          xs={12}
          sm={12}
          className={filesHidden ? classes.hidden : classes.RightList}
          style={{ maxHeight: '100%' }}
        >
          <FileList data={listFiLes} setFilesHidden={setFilesHidden} />
        </Grid>
      )}
    </Grid>
  );
};
export default Message;
