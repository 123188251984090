import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { FormInput } from './FormInput';

export default function SelectInput({
  options,
  control,
  defaultValue,
  name,
  ...props
}: any) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue && defaultValue}
      name={name}
      {...props}
      render={({ onChange, ...params }) => (
        <Autocomplete
          onChange={(e, data) => onChange(data)}
          getOptionLabel={(option: any) => option}
          id="autocomplete"
          options={options}
          defaultValue={defaultValue || params.value}
          renderOption={(option: any) => <span>{option}</span>}
          renderInput={(propsParams) => (
            <FormInput
              name={name}
              variant="filled"
              {...props}
              {...propsParams}
            />
          )}
          {...props}
          {...params}
        />
      )}
    />
  );
}
