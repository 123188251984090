import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as AttachIcon } from 'assets/patientActivities/attachment-img.svg';
import { useStyles } from '../styles';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { Controller } from 'react-hook-form';
import { ReactComponent as DotsIcon } from 'assets/patientActivities/dots.svg';
import { ReactComponent as DeleteIcon } from 'assets/patientActivities/delete.svg';
import Menu from 'components/ui/Menu';
import Text from 'Text';
export const AttachImages = ({ control, setValue, watch }) => {
  const classes = useStyles();
  const [openDoc, setOpenDoc] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  const watchedFiles = watch('files');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSave = () => {
    setValue(
      'files',
      fileObjects.map((fileObject) => fileObject.file)
    );
    handleClose();
  };

  const handleAdd = (newFiles) => {
    setFileObjects((prev) => [...prev, ...newFiles]);
  };

  const handleDelete = (deleteFileObj) => {
    setFileObjects((prev) =>
      prev.filter((fileObj) => fileObj.data !== deleteFileObj.data)
    );
  };

  const handleClose = () => {
    setOpenDoc(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const MenuElements = [
    {
      cy: 'delete-image',
      avatar: <DeleteIcon />,
      name: 'Delete image',
      onClick: () => {
        setValue('files', undefined);
        setFileObjects([]);
        handleMenuClose();
      },
      isRed: true,
    },
  ];

  return (
    <>
      {watchedFiles && fileObjects?.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gridGap="0.5rem"
        >
          {fileObjects.map((fileObject, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              className={classes.imgContainer}
            >
              <img
                src={fileObject.data}
                alt={`Preview ${index}`}
                width="120"
                height="120"
                className={classes.img}
              />
              <Box className={classes.blueStrip}>
                <Box
                  height="80%"
                  onClick={handleClick}
                  style={{ cursor: 'pointer' }}
                >
                  <DotsIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                handleMenuClose={handleMenuClose}
                MenuElements={MenuElements}
              />
            </Box>
          ))}
        </Box>
      )}

      {(!watchedFiles || fileObjects?.length === 0) && (
        <Box
          className={classes.attachBox}
          onClick={() => {
            setOpenDoc(true);
          }}
        >
          <AttachIcon />
          <Text i18nKey='attachImage' className={classes.attachImg}>Attach image</Text>
        </Box>
      )}

      <Controller
        name="files"
        control={control}
        render={() => (
          <DropzoneDialogBase
            dialogTitle="Upload your picture"
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            fileObjects={fileObjects}
            filesLimit={1}
            cancelButtonText="cancel"
            submitButtonText="submit"
            maxFileSize={5000000}
            open={openDoc}
            onSave={() => handleSave()}
            onClose={handleClose}
            onAdd={handleAdd}
            onDelete={handleDelete}
            showPreviews
            showFileNamesInPreview={false}
            showAlerts={['error', 'info']}
          />
        )}
      />
    </>
  );
};
