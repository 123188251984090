import { gql, useMutation } from '@apollo/client';
import { updateTask, updateTaskVariables } from './__generated__/updateTask';
import { TASKS_LIST_QUERY } from './useDeleteTask';

const EDIT_TASK = gql`
  mutation updateTask($taskId: ID!, $taskInput: editTaskInput!) {
    editTask(taskId: $taskId, taskInput: $taskInput) {
      _id
      title
      description
      status
      dueDate
      priority
      attachments {
        createdAt
        fileName
        fileType
        url
        user {
          firstname
          middlename
          lastname
          picture
          role
          _id
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export default function useUpdateTask() {
  const [editTask, result] = useMutation<updateTask, updateTaskVariables>(
    EDIT_TASK,
    {
      refetchQueries: [{ query: TASKS_LIST_QUERY }],
      awaitRefetchQueries: false,
    }
  );

  return { editTask, ...result };
}
