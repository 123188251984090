import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT_ACCESS } from './useGetPatientAccess';

const ADD_DOCTOR_ACCESS = gql`
  mutation addDoctorToPatient($accessInput: AccessInput) {
    addDoctorToPatient(accessInput: $accessInput) {
      _id
    }
  }
`;
interface IAccessInput {
  doctor: string;
  permissions: string[];
  requestId: string;
}
const useAddDoctorAccess = () => {
  const [addAccess] = useMutation(ADD_DOCTOR_ACCESS, {
    refetchQueries: [{ query: GET_PATIENT_ACCESS }],
  });
  async function handleAddDoctorAccess(accessInput: IAccessInput) {
    try {
      return await addAccess({
        variables: { accessInput },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleAddDoctorAccess };
};

export { ADD_DOCTOR_ACCESS };
export default useAddDoctorAccess;
