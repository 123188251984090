import React from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { ReactComponent as MoonStarIcon } from 'assets/patient-profile/response/moon-star.svg';
import { ReactComponent as SunIcon } from 'assets/patient-profile/response/sun.svg';
import { useStyles } from './Feed';
import {
  types,
  handleEmoji,
  emojis,
} from 'pages/PatientActivities/RecentActivityCard';
import { getTypeFromText } from './utils';
import { useStyles as useStyle } from 'pages/PatientActivities/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

export const options = ['Not great', 'Okay', 'Good', 'Great'];
const ActivityTypeContent = ({ el }) => {
  const classes = useStyles();
  const classname = useStyle({});
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  dayjs.locale(language || 'en');

  const activityType = getTypeFromText(el?.feed);

  const responseContent = {
    moodCheckin: (
      <Box
        ml={2}
        display="flex"
        alignItems="center"
        className={classes.responseBox}
      >
        {handleEmoji(el?.description?.response, emojis)}
        <Typography className={classes.response}>
          {t(el?.description?.response)}
        </Typography>
      </Box>
    ),
    sleepCheckin: (
      <Box display="flex" flexDirection="column">
        <Box className={classname.sleepBar} ml={2}>
          {options.map((option) => (
            <Box
              pr={2}
              className={
                option === el?.description?.response
                  ? classname.sleepEmojiSelected
                  : classname.sleepEmoji
              }
            >
              {handleEmoji(option, emojis)}
            </Box>
          ))}
        </Box>
      </Box>
    ),
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.desriptionBox}
      ml={6}
    >
      <Box display="flex" alignItems="center">
        {types[getTypeFromText(el?.feed)]?.icon}
        <Box display="flex" alignItems="center" ml={1}>
          <Typography className={classes.question}>
            {t(el?.description?.question)}
          </Typography>
          {responseContent[activityType]}
        </Box>
      </Box>
      {activityType === 'sleepCheckin' && el?.description?.wakeHours && (
        <Box
          display="flex"
          justifyContent="flex-start"
          className={classname.sleepData}
          my={2}
        >
          <Box className={classname.sleepBedTime}>
            <MoonStarIcon />
            {t('Bed time')}:
            <span style={{ fontWeight: 700 }}>
              {dayjs(new Date(Number(el?.description?.sleepHours))).format(
                language === 'fr' ? 'HH:mm' : 'h:mmA'
              )}
            </span>
          </Box>
          <Box className={classname.sleepWakeupTime}>
            <SunIcon />
            {t('Wake up')}:
            <span style={{ fontWeight: 700 }}>
              {dayjs(new Date(Number(el?.description?.wakeHours))).format(
                'h:mmA'
              )}
            </span>
          </Box>
          <Box className={classname.sleepTotal}>
            {t('Wake up')}:{' '}
            <span style={{ fontWeight: 700 }}>
              {dayjs(new Date(Number(el?.description?.sleepHours))).diff(
                new Date(Number(el?.description?.wakeHours)),
                'hour'
              )}{' '}
              {t('hours')}
            </span>
          </Box>
        </Box>
      )}
      {activityType === 'reflection' && el?.description?.response && (
        <Box mt={1}>
          <Typography className={classes.notes}>
            {el?.description?.response}
          </Typography>
        </Box>
      )}
      {activityType !== 'reflection' && el?.description?.notes && (
        <Box mt={1}>
          <Typography className={classes.notes}>
            {el?.description?.notes}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ActivityTypeContent;
