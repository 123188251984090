import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '80vw',
    backgroundColor: 'white',
    height: '70vh',
    borderRadius: '10px',
    overflow: 'hidden',
    paddingBlock: '5rem',
    paddingInline: '4rem',
    boxSizing: 'border-box',
  },
  grid: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    height: '100%',
    paddingInline: theme.spacing(2),
    paddingTop: '4rem',
    paddingBottom: '2rem',
    boxSizing: 'border-box',
    border: 'none',
    backgroundColor: 'white',
    borderRadius: '18px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  title: {
    color: 'black',
    fontSize: '25px',
    fontWeight: 400,
  },
  pricing: {
    color: '#1F61DC',
    fontSize: '45px',
    marginTop: '1rem',
    fontWeight: 700,
  },
  text: {
    fontSize: '25px',
    marginTop: '2rem',
    fontWeight: 400,
    color: 'black',
  },
  subButton: {
    paddingInline: '1rem',
    textTransform: 'none',
    color: 'white',
    fontSize: '18px',
    fontWieght: 600,
    marginBottom: '2rem',
    marginTop: '1rem',
    backgroundColor: '#1F61DC',
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    marginTop: '-2px',
  },
  label1: {
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '23px',
    color: theme.palette.black.dark,
    [theme.breakpoints.only('md')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  inputMui2: {
    width: '100%',

    background: theme.palette.gray.contrastText,
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  mediumLongInputNameOncard: {
    width: '100%',
    background: theme.palette.gray.contrastText,
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  PaymentBox: {
    width: '40vw',
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  scrollBox: {
    boxSizing: 'border-box',
    paddingInline: '3rem',
    paddingBottom: '2rem',
  },
  backText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21),
    fontWeight: 700,
    color: 'rgba(45, 199, 109, 1)',
    marginLeft: '8px',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    color: 'rgba(31, 97, 220, 1)',
    lineHeight: theme.typography.pxToRem(46),
  },
  paymentTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '0.300349px',
    color: '#425364',
    marginBlock: theme.spacing(1),
  },
  paymentText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '0.300349px',
    color: '#425364',
  },
}));
