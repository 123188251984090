import {
  Box,
  Grid,
  BoxProps,
  IconButton,
  Typography,
  Theme,
} from '@material-ui/core';
import React, { useState, useEffect, ReactElement, useRef } from 'react';
import { Element, animateScroll, Events, scrollSpy } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import EditButtons from '../EditButtons/editButtonsModal';
import useFormFieldsArray from '../../hooks/useFormFieldsArray';
import { ReactComponent as AddIcon } from 'assets/ConsultationPage/btnAddIcon.svg';
import ScrollBar from 'components/ui/ScrollBar';
import clsx from 'clsx';
import { useToast } from 'hooks/useToast';

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#707090',
  },
  container: {
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && '#EFF6FF',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
  },
  locationTitle: {
    color: ' #1F61DC',
    fontSize: theme.typography.pxToRem(40),
    padding: `${theme.typography.pxToRem(25)} 3rem`,

    lineHeight: '52px',
    fontWeight: 'bold',
  },
  button: {
    width: '160px',
    color: 'white',
    position: 'absolute',
    left: '0rem',
    textTransform: 'none',
    border: '1px dashed #39B54A',
    backgroundColor: '#39B54A',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    '&:hover': {
      background: '#39B54A',
    },
  },
  actionsBlock: {
    width: '98%',
    marginLeft: theme.spacing(2),
  },
  fixedBlock: {
    marginRight: theme.spacing(6),
  },

  iconButton: {
    position: 'absolute',
    top: '1vh',
    right: '2vw',
  },
  closeIcon: {
    fontSize: '44px',
    color: '#425364',
  },
  margin: {
    marginLeft: theme.spacing(3),
  },
  boxResponsive: {},
  addIcon: { paddingRight: '5px', display: 'flex', alignItems: 'center' },
  scroll: {
    padding: theme.spacing(2),
    overflowX: 'hidden',
    [theme.breakpoints.only('sm')]: {
      height: '42vh',
      maxHeight: '42vh',
    },
    [theme.breakpoints.only('xs')]: {
      height: '42vh',
      maxHeight: '42vh',
    },
    [theme.breakpoints.only('md')]: {
      height: '42vh',
      maxHeight: '42vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '42vh',
      maxHeight: '42vh',
    },
    [theme.breakpoints.only('xl')]: {
      height: '50vh',
      maxHeight: '50vh',
    },
  },

  footer: {
    padding: `0 2rem`,
  },
}));

interface IAddEditFormProps extends BoxProps {
  defaultValues?: any[];
  handleAdd: (newValues: any[], editedValues: any[], allValues: any[]) => void;
  onClose: () => void;
  handleDelete?: any;
  buttonLabel?: ReactElement | string;
  title?: string;
  fields: { fieldName: string; size: number }[];
  wrapper?: boolean;
  cols: number;
  mode?: 'edit' | 'add';
  cellHeight?: number;
  FormOptions: any;
  schema?: any;
  lastField?: any;
  xl?: any;
  left?: any;
  right?: any;
  mx?: string;
  newWidth?: string;
  classNameCustom?: string;
  widthReponsive?: string;
  marginTitle?: number;
  styleScrollContainer?: any;
  addButton?: boolean;
  deleteButton?: boolean;
  isPatient?: boolean;
  loading: boolean;
  isEditableDraftjs?: boolean;
  isConditions?: boolean;
}

export default function AddEditForm({
  isConditions = false,
  isEditableDraftjs = false,
  addButton,
  defaultValues,
  handleAdd,
  onClose,
  handleDelete,
  buttonLabel,
  wrapper = true,
  title,
  fields,
  schema,
  newWidth,
  lastField,
  margin,
  marginTop,
  marginBottom,
  classNameCustom,
  right,
  left,
  widthReponsive,
  width = '100%',
  cols = 4,
  mx,
  mode = 'edit',
  styleScrollContainer,
  FormOptions,
  marginTitle = -2,
  xl,
  deleteButton = true,
  isPatient = false,
  loading,
  ...props
}: IAddEditFormProps): ReactElement {
  const { openSuccess, triggerToast } = useToast();
  const scrollElement = useRef<any>(HTMLElement);
  const scrollHeightRef = useRef<any>(
    document.getElementById('scrollContainer')?.scrollHeight
  );
  const classes = useStyles({ widthReponsive, openSuccess });
  const [currentValues, setCurrentValues] = useState(defaultValues);
  useEffect(() => {
    setCurrentValues(defaultValues);
  }, [defaultValues]);
  let scrollHeight: any =
    document.getElementById('scrollContainer')?.scrollHeight;
  useEffect(() => {
    Events.scrollEvent.register('begin', () => {});
    Events.scrollEvent.register('end', () => {});
    scrollSpy.update();
    const currentScrollHeight =
      document.getElementById('scrollContainer')?.scrollHeight;
    scrollHeightRef.current = currentScrollHeight;
    scrollHeight = currentScrollHeight;
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    if (scrollHeightRef.current !== scrollHeight) {
      animateScroll.scrollTo(scrollHeight, {
        containerId: 'scrollContainer',
        smooth: 'true',
        duration: 1000,
        isDynamic: true,
        spy: true,
        hashSpy: true,
        delay: 0,
        ignoreCancelEvents: true,
      });
      scrollHeightRef.current = scrollHeight;
    }
  }, [scrollHeight]);

  const {
    formRender,
    triggerSubmit,
    addField,
    handleSubmit,
    formRef,
    onSubmit,
  } = useFormFieldsArray({
    defaultValues: {
      array:
        mode === 'edit'
          ? currentValues && currentValues.length > 0
            ? currentValues
            : [{ AtleastoneElementHereToPreviewAndEdit: '' }]
          : [],
    },
    inputs: FormOptions(handleDelete),
    fieldArrayName: 'array',
    handleAction: handleAdd,
    schema,
    lastField,
    margin,
    marginTop,
    marginBottom,
    triggerToast,
    deleteButton,
    isEditableDraftjs,
    isConditions,
  });
  function handleTriggerSubmit() {
    triggerSubmit();
  }

  return (
    <Box
      className={classes.container}
      style={{
        height: isConditions && '93vh',
      }}
    >
      <Box
        style={{
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
        }}
      >
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: '100%',
            maxHeight: '100%',
            minHeight: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <ScrollBar sx={{ flex: 1 }}>
            <Element
              name="scroll"
              id="scrollContainer"
              ref={scrollElement}
              style={{
                boxSizing: 'border-box',
                paddingBottom: '3rem',
              }}
            >
              {formRender}
            </Element>
          </ScrollBar>
          <Box
            height="76px"
            display="flex"
            alignItems="center"
            width="100%"
            borderTop="1px solid #EAECF0"
            style={{
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
            }}
          >
            <EditButtons
              addButton={addButton}
              handleOpen={onClose}
              editable
              loading={loading}
              classNameIcon={classes.addIcon}
              handleEdit={handleTriggerSubmit}
              handleAdd={addField}
              buttonLabel={buttonLabel}
              widthResponsive
              isPatient={isPatient}
              type="submit"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}
AddEditForm.defaultProps = {
  defaultValues: [],
  handleDelete: () => {},
  wrapper: true,
  mode: 'edit',
  cellHeight: '',
};
