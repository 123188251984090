import { Option } from 'components/ui/Inputs/AutoCompleteAdd';

interface IDisplaySelectedFirst {
  firstValue: any;
  secondValue: any;
  options: Option[];
  values: Option[];
}
export function displaySelectedFirst({
  firstValue,
  secondValue,
  options,
  values,
}: IDisplaySelectedFirst) {
  let firstIndex = values?.indexOf(firstValue);
  firstIndex =
    firstIndex === -1
      ? values?.length + options?.indexOf(firstValue)
      : firstIndex;
  let secondIndex = values?.indexOf(secondValue);
  secondIndex =
    secondIndex === -1
      ? values?.length + options?.indexOf(secondValue)
      : secondIndex;
  return firstIndex - secondIndex;
}
