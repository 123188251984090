import { useSubscription, gql, ApolloError } from '@apollo/client';

const CAMERA_STATE_SUBSCRIPTION = gql`
  subscription onCameraStateChanged($roomId: String!, $userId: String!) {
    cameraStateChanged(roomId: $roomId, userId: $userId)
  }
`;

interface IUseListenCameraStateArgs {
  roomId: string;
  userId: string;
  onEvent: (state: 'initial' | 'established') => void;
}

const useListenCameraState = ({
  roomId,
  userId,
  onEvent,
}: IUseListenCameraStateArgs): {
  loading: boolean;
  data?: any;
  error?: ApolloError | undefined;
} => {
  const result = useSubscription(CAMERA_STATE_SUBSCRIPTION, {
    variables: { roomId, userId },
    onSubscriptionData: (data: any) => {
      console.log('Subscriptiion camera', data);
      onEvent(data?.subscriptionData?.data?.cameraStateChanged);
    },
  });

  return result;
};

export default useListenCameraState;
