import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  h3: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    borderBottom: '1px solid #EAECF0',
  },
  canceling: {
    marginRight: theme.spacing(2),
    backgroundColor: 'transparent',
    border: '1px solid rgba(130, 130, 130, 1)',
    color: '#425364',
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '21px',
    minWidth: '94px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    borderRadius: '5px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(130, 130, 130, 1)',
    },
  },
  saveing: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    minWidth: '94px',
    border: '1px solid #1F61DC',
    borderRadius: '5px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#1D31AA',
    },
    '&:disabled': {
      backgroundColor: '#D0D5DD',
      color: 'white',
      border: '1px solid #D0D5DD',
    },
  },
  defaultTexting: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
  },
  defaultText: {
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
    color: '#175CD3',
    fontSize: '12px',
  },
  defaultBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFF8FF',
    border: '1px solid #B2DDFF',
    borderRadius: '16px',
    padding: '2px 8px',
  },
  editbtnForm: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    color: '#101828',
    textDecoration: 'underline',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  stripeFormLabel: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    color: '#253521',
  },
  stripeInfo: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#000000',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.44px',
    color: '#425364',
  },
  topsection: {
    backgroundColor: '#F5FBFF',
    paddingBlock: '16px',
    borderBottom: '1px solid #D0D5DD',
  },
  addTagBtn: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    padding: '0 !important',
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  addTag: {
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    padding: '5px 25px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  cardLabel: {
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: '#101828',
  },
  expireLabel: {
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#475467',
    fontFamily: 'Inter',
  },
  h3Tittle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(36),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.44px',
    color: theme.palette.black.dark,
    [theme.breakpoints.only('sm')]: {
      fontSize: theme.typography.pxToRem(25),
    },
  },
  label: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    color: theme.palette.black.dark,
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
  },
  label1: {
    width: '100%',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '23px',
    color: theme.palette.black.dark,
  },
  margintop3: {
    marginTop: '8px',
  },
  marginelft: {
    marginLeft: '5px',
  },
  marginelft1: {
    marginLeft: '15px',
  },

  input: {
    width: '100%',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  inputNames: {
    width: '100%',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  inputMui: {
    width: '50%',
    borderRadius: '8px',
    marginTop: '12.04px',
    [theme.breakpoints.only('md')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  inputMui2: {
    width: '100%',

    borderRadius: '8px',
    marginTop: '12.04px',
  },
  longInput: {
    width: '100%',
    height: '52px',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  btnBoxColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(5),
    marginBlock: '2rem',
  },
  headerBox: {
    display: 'flex',
    width: '100%',
  },
  btnClose: {
    display: 'flex',
    position: 'absolute',
    right: '2rem',
  },
  btnBoxCol: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(6),
  },
  btn: {
    backgroundColor: '#1F61DC',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '5px',
    padding: '5px 25px',
    color: 'white',
    textTransform: 'unset',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  description: { color: '#425364' },
  longInputBilling: {
    width: '100%',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  longInputEdit: {
    width: '100%',
    height: '52px',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  closeIcon: {
    position: 'absolute',
    right: '4.5rem',
    top: '2rem',
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      right: '1.5rem',
      top: '2rem',
    },
  },
  mediumLongInput: {
    width: '100%',
    height: '52px',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  mediumLongInputNameOncard: {
    width: '100%',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  paasswordStyle: {
    '& .MuiInputBase-input ': {
      color: '#737D8B',
    },
  },
  mediumLongInput1: {
    width: '97%',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  inputCardNumber: {
    width: '662px',
    height: '52px',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  CardBande: {
    width: '310px',
    height: '25px',
    backgroundColor: '#E2E1DD',
    opacity: '0.42',
    marginTop: '41px',
  },
  card: {
    postion: 'relative',
    width: '100%',
    height: '179px',
    backgroundColor: '#F8FAFB',
    borderRadius: '19px',
    border: '1px solid ${theme.palette.gary.dark}',
    opacity: '0.46',
  },
  card1: {
    width: '100%',
    height: '179px',
    backgroundColor: '#F8FAFB',
    borderRadius: '19px',
    border: '2px solid #2DC76D',
    postion: 'relative',
  },
  cardH1: {
    color: theme.palette.black.dark,
    width: '53.79',
    height: '17.02',
    opacity: '0.42',
    marginLeft: '-191.28px',
  },
  cardH2: {
    color: theme.palette.black.dark,
    marginLeft: '-8.28px',
    marginTop: '10px',
    opacity: '0.66',
    mixBlendMode: 'normal',
    fontFamily: 'Helvetica',
    letterSpacing: '1.66022px',
    lineHeight: '32px',
  },
  cardP: {
    height: '12px',
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '12px',
    color: theme.palette.black.dark,
    opacity: '0.5',
    mixBlendMode: 'normal',
    paddingLeft: '31.28px',
    marginTop: '15px',
  },
  cardP2: {
    height: '12px',
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '12px',
    color: theme.palette.black.dark,
    opacity: '0.5',
    mixBlendMode: 'normal',
    paddingLeft: '140.28px',
    marginTop: '15px',
  },
  cardH6: {
    marginLeft: '-151.28px',
    marginTop: '-5px',
    height: '15px',
    fontSize: '13px',
    lineHeight: '15px',
    color: theme.palette.black.dark,
  },
  card2h6: {
    paddingLeft: '210.28px',
    marginTop: '-45px',
    height: '15px',
    fontSize: '13px',
    lineHeight: '15px',
    color: theme.palette.black.dark,
  },

  button: {
    width: '139px',
    height: '36px',
    borderRadius: '5px',
  },
  buttonAddCreditCard: {
    borderRadius: '7px',
    color: '#2DC76D',
    lineHeight: '19px',
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  buttonDeleteCreditCard: {
    marginTop: '8px',
    width: '312px',
    borderRadius: '7px',
    height: '48px',
    backgroundColor: '#F8FAFB',
    border: `1px solid ${theme.palette.gray.dark}`,
    boxSizing: 'border-box',
    color: theme.palette.gray.main,
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    justifyContent: 'center',
    opacity: '0.46',
  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  dividerCredit: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  buttonDeleteCreditCardSelected: {
    marginTop: '8px',
    width: '312px',
    borderRadius: '7px',
    height: '48px',
    backgroundColor: theme.palette.gray.contrastText,
    border: `1px solid ${theme.palette.gray.dark}`,
    boxSizing: 'border-box',
    color: theme.palette.gray.main,
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    justifyContent: 'center',
  },
  h3Billing: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: '0.44px',
    color: '#101828',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    marginBottom: 5,
    marginTop: 10,
  },
  deleteIcon: {
    marginTop: '-5px',
    marginRight: '10px',
    fontSize: '30px',
  },

  cardSpace: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioP1: {
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    color: theme.palette.black.dark,
  },
  radioP2: {
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    color: theme.palette.black.dark,
  },
  p: {
    width: '316px',
    fontFamily: 'Roboto',
    fontStyle: 'normale',
    fontSize: '20px',
    lineHeight: '39px',
    color: theme.palette.black.dark,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: '26px',
  },
  marginTopEdit: {
    marginTop: '8px',
  },
  AddPaper: {
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : 'white',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    width: '100%',
    height: '80vh',
    borderRadius: '19px',
    [theme.breakpoints.up('sm')]: {
      width: '90vw',
      height: '80vh',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45vw',
      height: '60vh',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45vw',
      height: '50vh',
    },
  },
  billingContainer: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '43vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
  },
  paper: {
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : 'white',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    paddingInline: theme.spacing(4),
    // overflowX: 'hidden',
    // overflowY: 'hidden',
    width: '40vw',
    borderRadius: '19px',
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
      height: '80vh',
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      height: '80vh',
      padding: theme.spacing(0),
    },
    [theme.breakpoints.only('lg')]: {
      width: '55vw',
    },
  },
  paperDelete: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 5),
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  gridContainer: {
    padding: '20px 40px',
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(6),
    },
  },
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
  },
  rootRadio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  adminContainer: {
    padding: '48px',
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && '#EFF6FF',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    [theme.breakpoints.only('xs')]: {
      padding: '30px',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: 'white',
    border: '1px solid #39B54A',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#39B54A,#39B54A 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f5f8fa',
    },
  },
  date: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  dateFlex: {
    flex: 1,
  },
  cancel: {
    width: '139px',
    height: '36px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid #425364',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#425364',
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
  },
  save: {
    width: '139px',
    height: '36px',
    minWidth: '81px',
    borderRadius: '5px',
    backgroundColor: '#1F61DC',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1C31AC',
    },
  },
  templateTabTitle: {
    color: 'var(--2sd-Blue, #425364)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '192.687%',
    marginBlock: '1.5rem !important',
  },
  addNewTemplate: {
    width: '18%',
    minHeight: '182px',
    borderRadius: '5px',
    border: '1px solid var(--Gray-4, #BDBDBD)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '1rem',

    [theme.breakpoints.only('md')]: {
      width: '23%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '32%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '48%',
    },
  },
  TemplateBox: {
    width: '18%',
    borderRadius: '5px',
    border: '1px solid var(--Gray-4, #BDBDBD)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    marginBottom: '1rem',

    [theme.breakpoints.only('md')]: {
      width: '23%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '32%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '48%',
    },
  },
  imageBox: {
    overflow: 'hidden',
    maxHeight: '122px',
    minHeight: '122px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderBottom: '1px solid var(--Gray-4, #A4A4A4)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100px',
      minHeight: '100px',
    },
  },
  templateInfo: {
    width: '100%',
    height: '100%',
    paddingBlock: '12px',
    paddingInline: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  templateName: {
    color: 'var(--2sd-Blue, #425364)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    linHeight: '192.687%',
  },
  templateLastOpened: {
    color: 'var(--TAB-TEXTE, #A4A4A4)',
    fontFamily: 'Roboto',
    fontSize: '7px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '192.687%',
    flex: 1,
  },
  menu: {
    width: '10rem',
    borderRadius: '8px',
    padding: '0',
  },
  Sidebar: {
    backgroundColor: 'white',
    borderRight: '1px solid #EAECF0',
    boxSizing: 'border-box',
  },
  ListItem: {
    borderRadius: '8px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#EFF6FF',
    },
    '& .Mui-disabled': {
      opacity: 1,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  disabled: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
