import { gql, useMutation } from '@apollo/client';

export const REMOVE_TAG = gql`
  mutation removeTag($tagId: ID) {
    removeTag(tagId: $tagId)
  }
`;

export default function useRemoveTag() {
  const [removeTag, result] = useMutation(REMOVE_TAG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          doctorTags() {
            return data?.removeTag;
          },
        },
      });
    },
  });
  return { removeTag, ...result };
}
