import { gql, useMutation } from '@apollo/client';

const REFUND_SESSION = gql`
  mutation refundSession($paymentIntent: String,$amount:Int) {
    refundSession(paymentIntent: $paymentIntent,amount:$amount) {
      isPaid
    }
  }
`;

const useRefundAppointment = () => {
  const [refundSession, { loading: loadingRefund }] =
    useMutation(REFUND_SESSION);
  async function handleRefund(paymentIntent: string, amount: number) {
    try {
      const refund = await refundSession({
        variables: { paymentIntent, amount },
      });
      return refund;
    } catch (e) {
      return e;
    }
  }
  return { handleRefund, loadingRefund };
};

export default useRefundAppointment;
