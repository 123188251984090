import { gql, useMutation } from '@apollo/client';

const LEAVE_WAITING_ROOM_MUTATION = gql`
  mutation leaveWaitingRoom($patientId: String, $roomId: String!) {
    leaveWaitingRoom(patientId: $patientId, roomId: $roomId)
  }
`;

const useLeaveWaitingRoom = () => {
  const [leaveWaitingRoom, result] = useMutation<unknown, any>(
    LEAVE_WAITING_ROOM_MUTATION
  );

  async function handleLeaveWaitingRoom(patientId: string, roomId: string) {
    try {
      return await leaveWaitingRoom({
        variables: {
          patientId,
          roomId,
        },
      });
    } catch (e) {
      return console.log('error', e);
    }
  }

  return { handleLeaveWaitingRoom, ...result };
};

export default useLeaveWaitingRoom;
