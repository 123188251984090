import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { Box } from '@material-ui/core';

export function WrapperContent({
  patientId,
  children,
}: {
  patientId: string;
  children: React.ReactElement;
}) {
  const {
    data,
    loading: loadingPatient,
    error: errorPatient,
  } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });

  return (
    <Box
      py={4}
      pt={1}
      mt={2}
      style={{
        backgroundColor: 'white',
      }}
    >
      {React.cloneElement(children, { data, loadingPatient, errorPatient })}
    </Box>
  );
}
