import React from 'react';
import { Grid, Box, Typography, Avatar, Button } from '@material-ui/core';
import { ReactComponent as WaiveLogo } from 'assets/WaiveLogo.svg';
import useStyles from './styles';
import Text from 'Text';

function WaiveModal({ handleCloseWaive, handleWaive }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      px={8}
      pb={4}
    >
      <WaiveLogo />
      <Box>
        <Text i18nKey='waivePayment' className={classes.paymentTitle}>Waive payment</Text>
        <Text i18nKey='Are you sure you want to waive payment ?' className={classes.paymentText}>
          Are you sure you want to waive payment ?
        </Text>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Button className={classes.btnC} onClick={handleCloseWaive}>
          <Text i18nKey='cancel'>Cancel</Text>
        </Button>
        <Button className={classes.btnSC} onClick={() => handleWaive()}>
          <Text i18nKey='waive'>Waive</Text>
        </Button>
      </Box>
    </Box>
  );
}

export default WaiveModal;
