import dayjs from 'dayjs';
import i18next from 'i18next';

enum Days {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
interface IProps {
  startDate: Date;
  endDate: Date;
}

export function getHoursArray(events: IProps[]) {
  const hoursArray = [];

  events?.forEach((event) => {
    const startHour = event?.startDate?.getHours();
    const endHour = event?.endDate?.getHours();

    for (let hour = startHour; hour < endHour; hour++) {
      hoursArray.push(hour);
    }
  });

  return hoursArray;
}

export function checkDateValidity(schedule: Record<string, IProps[]>) {
  const days = Object.keys(schedule);

  for (const day of days) {
    const events = schedule[day];

    for (let i = 0; i < events?.length; i++) {
      const event1 = events[i];

      for (let j = i + 1; j < events?.length; j++) {
        const event2 = events[j];

        if (isOverlap(event1, event2)) {
          const overlapText = `${day}: Events overlap on ${event1.startDate.toLocaleString()} - ${event1.endDate.toLocaleString()} and ${event2.startDate.toLocaleString()} - ${event2.endDate.toLocaleString()}`;
          return overlapText;
        }
      }

      if (isInvalid(event1)) {
        const text = `${day}: Select future date and time`;
        return text;
      }
    }
  }

  return '';
}

export function isOverlap(event1: IProps, event2: IProps) {
  const startEvent1 = new Date(event1.startDate).getHours();
  const endEvent1 = new Date(event1.endDate).getHours();
  const startEvent2 = new Date(event2.startDate).getHours();
  const endEvent2 = new Date(event2.endDate).getHours();
  return (
    (startEvent2 >= startEvent1 && startEvent2 < endEvent1) ||
    (endEvent2 > startEvent1 && endEvent2 <= endEvent1)
  );
}

export function checkValidity(events) {
  for (let i = 0; i < events?.length; i++) {
    const event1 = events[i];

    for (let j = i + 1; j < events?.length; j++) {
      const event2 = events[j];

      if (isOverlap(event1, event2)) {
        const overlapText = `Events overlap on ${event1.startDate.toLocaleString()} - ${event1.endDate.toLocaleString()} and ${event2.startDate.toLocaleString()} - ${event2.endDate.toLocaleString()}`;
        return overlapText;
      }
    }

    if (isInvalid(event1)) {
      const text = `Select future date and time`;
      return text;
    }
  }
}
export function isInvalid(event: IProps) {
  return (
    new Date(event.startDate).getTime() >= new Date(event.endDate).getTime()
  );
}

export function changeDateFormat(date: Date) {
  const inputDate = new Date(date);
  const language = i18next.language;

  if (language === 'fr') {
    return inputDate.toLocaleDateString('fr-FR',  {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  }

  return inputDate.toLocaleDateString('en-US',  {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

export function changeDateTime(date: Date) {
  const inputDate = new Date(date);
  const language = i18next.language; 
  if (language === 'fr') {
    return inputDate.toLocaleTimeString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
  });
  }

  return inputDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function convertTimeZone(dateTime) {
  const startingTime = new Date();
  const offsetMinutesStart = -startingTime.getTimezoneOffset();
  const offsetHour = offsetMinutesStart / 60;
  const offset = offsetHour;

  const start = new Date(dateTime);

  start.setHours(start.getHours() - (offset + 1));
  return start;
}

export function transformDate(data): any {
  const obj = Object.keys(data).reduce((result, day) => {
    result[day] = data[day]?.map((entry) => {
      const start = new Date(entry.startDate);
      const end = new Date(entry.endDate);

      return {
        startDate: new Date(
          start.getTime() + start.getTimezoneOffset() * 60000
        ),
        endDate: new Date(end.getTime() + end.getTimezoneOffset() * 60000),
      };
    });
    return result;
  }, {});
  return obj;
}
export function convertDatesToUtc(dayObj) {
  const startingTime = new Date();
  const offset = -startingTime.getTimezoneOffset() / 60;
  const utcOffset = offset * 60;

  const result = {};
  for (const dayName in dayObj) {
    if (dayObj.hasOwnProperty(dayName)) {
      const day = dayObj?.[dayName];
      const convertedDay = day?.map((timeRange) => {
        const startDate = new Date(timeRange.startDate);
        const endDate = new Date(timeRange.endDate);
        const startUtc = startDate.getTime() + utcOffset * 60 * 1000;
        const endUtc = endDate.getTime() + utcOffset * 60 * 1000;

        return {
          startDate: new Date(startUtc).toISOString(),
          endDate: new Date(endUtc).toISOString(),
        };
      });

      result[dayName] = convertedDay;
    }
  }
  return result;
}

export function convertDateFormat(data) {
  return data?.map((el) =>
    el?.map((element) => {
      return {
        startDate: new Date(element?.startDate),
        endDate: new Date(element?.endDate),
      };
    })
  );
}

export function arrayHasElements(array: Days[]) {
  return array?.length > 0;
}

export function defaultArray(rest: Days) {
  return [
    {
      day: Days.Monday,
      checked: arrayHasElements(transformDate(rest)?.Monday),
    },
    {
      day: Days.Tuesday,
      checked: arrayHasElements(transformDate(rest)?.Tuesday),
    },
    {
      day: Days.Wednesday,
      checked: arrayHasElements(transformDate(rest)?.Wednesday),
    },
    {
      day: Days.Thursday,
      checked: arrayHasElements(transformDate(rest)?.Thursday),
    },
    {
      day: Days.Friday,
      checked: arrayHasElements(transformDate(rest)?.Friday),
    },
    {
      day: Days.Saturday,
      checked: arrayHasElements(transformDate(rest)?.Saturday),
    },
    {
      day: Days.Sunday,
      checked: arrayHasElements(transformDate(rest)?.Sunday),
    },
  ];
}
export function getLastUpdatedString(date: any, t: (key: string) => string): string {
  if (date === null) {
    return t('Never updated');  
  }
  const lastUpdatedTime = dayjs(date);
  return `${t('Last updated')} ${lastUpdatedTime.fromNow()}`; 
}