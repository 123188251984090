import { gql, useQuery } from '@apollo/client';

interface IUseAppointmentByDateArgs {
  startDate: string | Date | number | undefined;
  endDate: string | Date | number | undefined;
}

export default function useGetAppointments({
  startDate,
  endDate,
}: IUseAppointmentByDateArgs) {
  const { data, error, loading } = useQuery(GET_APPOINTMENTS_BY_DATE, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
  });
  return { data, error, loading };
}

export const GET_APPOINTMENTS_BY_DATE = gql`
  query getAppointmentsByDate($startDate: Date, $endDate: Date) {
    appointmentsByDate(startDate: $startDate, endDate: $endDate) {
      result {
        _id
        description
        title
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
        tag
        color
      }
        members
      }
    }
  }
`;
