import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Terms from 'pages/TermsPage';

const index = (): React.ReactElement => {
  return (
    <Box
      style={{
        paddingInline: '1rem',
        backgroundColor: '#fff',
        borderRadius: '20px',
        marginBottom: '2rem',
      }}
    >
      <Terms isLoggedIn />
    </Box>
  );
};

export default index;
