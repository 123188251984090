import React, { ReactElement } from 'react';
import { Control, Ref, FieldErrors } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';
import { BoxProps, Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const getI18nKey = (label) => {
  const labelToKeyMap = {
    'First name': 'firstName',
    'Middle name': 'middleName',
    'Last name': 'lastName',
    Specialty: 'specialty',
    'Date of birth': 'dateOfBirth',
    Gender: 'gender',
    'Display name': 'displayName',
    Pronouns: 'pronouns',
    'Phone number': 'phoneNumber',
    'Email address': 'emailAddress',
    'Experience (in years)': 'experienceInYears',
    'Address 1': 'adresse1',
    'Address 2 (optional)': 'adresse2',
    State: 'state',
    'Zip code': 'zipCode',
    Street: 'street',
    'Apt, suite (optional)': 'Apt',
  };
  return labelToKeyMap[label] || label; // Fallback to label itself if no key is found
};

interface IStaffProfileInputBlock extends BoxProps {
  name: string;
  register?: (ref: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  label?: string;
  type: string;
  value?: any;
  defaultValue?: any;
  options?: string[];
}

export default function InputBlock({
  name,
  register,
  control,
  errors,
  label,
  defaultValue,
  ...props
}: IStaffProfileInputBlock): ReactElement {
  const i18nKey = getI18nKey(label);
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      {label && (
        <Text i18nKey={i18nKey} className={classes.labels}>
          {label}
        </Text>
      )}
      <Input
        inputRef={register}
        control={control}
        name={name}
        defaultValue={defaultValue}
        variant="filled"
        helperText={
          errors &&
          errors[name] && (
            <Box data-cy="error-message" className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {t(errors[name]?.message)}
            </Box>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </>
  );
}
