import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query appointments($limit: String, $page: String) {
    appointments(page: $page, limit: $limit) {
      result {
        _id
        description
        title
        appointmentUri
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        isPaid
        isPrepay
        sessionType {
          session
          rate
          duration
          currency
        }
      }
      count
    }
    allAppointments {
      _id
      description
      title
      appointmentUri
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
        phone
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      isPaid
      isPrepay
      sessionType {
        session
        rate
        duration
        currency
      }
      isEnded
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query appointment($appointmentId: ID!) {
    appointment(appointmentId: $appointmentId) {
      _id
      description
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
      }
      title
      conditionType {
        tag
        color
      }
      locationLink
      location
      endDate
      startDate
      members
    }
  }
`;

export const ADD_APPOINTMENT = gql`
  mutation addAppointment($appointmentInput: addAppointmentInput!) {
    addAppointment(appointmentInput: $appointmentInput) {
      _id
      description
      doctor {
        _id
        firstname
        lastname
        email
        picture
        timeZone
      }
      patient {
        _id
        firstname
        lastname
        email
        picture
      }
      title
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      sessionType {
        rate
      }
      isPaid
    }
  }
`;
export const DELETE_APPOINTMENT = gql`
  mutation deleteAppointment($appointmentId: ID!, $isOne: Boolean) {
    deleteAppointment(appointmentId: $appointmentId, isOne: $isOne) {
      _id
      startDate
      doctor {
        timeZone
      }
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: ID!, $isOne: Boolean) {
    deleteEvent(eventId: $eventId, isOne: $isOne) {
      _id
      startDate
      doctor {
        timeZone
      }
    }
  }
`;

export const EDIT_APPOINTMENT = gql`
  mutation editAppointment(
    $appointmentInput: editAppointmentInput!
    $appointmentId: ID!
    $isOne: Boolean
  ) {
    editAppointment(
      appointmentInput: $appointmentInput
      appointmentId: $appointmentId
      isOne: $isOne
    ) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        timeZone
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
      isPrepay
      sessionType {
        rate
        session
        currency
        duration
      }
      isPaid
    }
  }
`;

export const EDIT_SESSION = gql`
  mutation sessionEdit($sessionInput: editAppointmentInput!, $sessionId: ID!) {
    sessionEdit(sessionInput: $sessionInput, sessionId: $sessionId) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        timeZone
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
      isPrepay
      sessionType {
        rate
        session
        currency
        duration
      }
      isPaid
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation editEvent(
    $eventInput: editEventInput!
    $eventId: ID!
    $isOne: Boolean
  ) {
    editEvent(eventInput: $eventInput, eventId: $eventId, isOne: $isOne) {
      title
      description
      startDate
      endDate
      isImpact
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        timeZone
      }
    }
  }
`;
export const DOCTOR_GOING = gql`
  mutation doctorIsGoing($appointmentId: ID!) {
    doctorIsGoing(appointmentId: $appointmentId) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
    }
  }
`;
export const DOCTOR_NOT_GOING = gql`
  mutation doctorIsNotGoing($appointmentId: ID!) {
    doctorIsNotGoing(appointmentId: $appointmentId) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
    }
  }
`;
export const PATIENT_GOING = gql`
  mutation patientIsGoing($appointmentId: ID!) {
    patientIsGoing(appointmentId: $appointmentId) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
    }
  }
`;
export const PATIENT_NOT_GOING = gql`
  mutation patientIsNotGoing($appointmentId: ID!) {
    patientIsNotGoing(appointmentId: $appointmentId) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      doctorGoing
      patientGoing
    }
  }
`;
export const GET_DOCTOR_APPOINTMENTS = gql`
  query doctorAppointments($patientId: String, $limit: Int, $start: Int) {
    doctorAppointments(patientId: $patientId, limit: $limit, start: $start) {
      appointments {
        _id
        startDate
        endDate
        patient {
          _id
        }
        doctor {
          timeZone
        }
        sessionType {
          session
          duration
          rate
          currency
        }
        locationLink
        isPaid
      }
      count
    }
  }
`;

export const ADD_EVENT = gql`
  mutation addEvent($eventInput: addEventInput!) {
    addEvent(eventInput: $eventInput) {
      description
      doctor {
        _id
        firstname
        lastname
        email
        picture
        timeZone
      }
      title
      startDate
      endDate
      isImpact
    }
  }
`;
