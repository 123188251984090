import { gql, useQuery } from '@apollo/client';

const GET_THREAD_CONVERSATION_BY_USERS = gql`
  query getThreadConversationByUsers($participant: ID!) {
    getThreadConversationByUsers(participant: $participant) {
      _id
      subject
      type
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      lastMessage {
        user {
          _id
          firstname
          middlename
          username
        }
        content {
          text
          files {
            _id
            url
            fileName
            mimeType
          }
        }
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
        createdAt
        updatedAt
      }
      unReadItMessage {
        id
        threadConversation
        user
        lastMessageReadIt
      }
      lastUpdateTime
      isArchived
      createdAt
      updatedAt
    }
  }
`;

const useGetThreadConversationByUsers = (participant: string) =>
  useQuery(GET_THREAD_CONVERSATION_BY_USERS, {
    variables: {
      participant,
    },
  });
export { GET_THREAD_CONVERSATION_BY_USERS };
export default useGetThreadConversationByUsers;
