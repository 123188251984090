import { gql, useMutation } from '@apollo/client';
import { log } from '../../utils/log';

const JOIN_VIDEO_ROOM_WITH_PATIENT = gql`
  mutation joinVideoRoomWithPatient($patientId: String!, $appointmentId: ID!) {
    joinVideoRoomWithPatient(
      patientId: $patientId
      appointmentId: $appointmentId
    )
  }
`;

const useJoinVideoRoomWithPatient = () => {
  const [joinVideoRoomWithPatient, result] = useMutation(
    JOIN_VIDEO_ROOM_WITH_PATIENT
  );

  async function handleJoinVideoRoomWithPatient(
    patientId: string,
    appointmentId: string,
    handleError?: (e: any) => void
  ) {
    try {
      const resultData = await joinVideoRoomWithPatient({
        variables: { patientId, appointmentId },
      });
      log('log', `Doctor joining video room with ${patientId}`);
      return resultData;
    } catch (e) {
      log('error', 'Error while joining video room', e);
      return handleError && handleError(e);
    }
  }
  return { ...result, handleJoinVideoRoomWithPatient };
};

export default useJoinVideoRoomWithPatient;
