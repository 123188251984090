import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  BoxProps,
  FormLabel,
  Chip,
  FormHelperText,
  useMediaQuery,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SuccessButton from 'components/ui/Button/SuccesButton';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { formatToFullDateWithoutTime } from 'utils';
import { Editor } from 'draft-js';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScrollBar from 'components/ui/ScrollBar';
import Button from 'components/ui/Button';
import DeleteHandler from 'components/DeleteHandler';

import useAddTaskAttachment from '@eggmed/graphql-client/operations/tasksOperations/useAddTaskAttachment';
import { FormInput } from 'components/ui/Inputs/FormInput';
import EditableInput from './EditableInput';
import { useProvider } from '../state/Provider';
import { getTasks_tasks } from '../__generated__/getTasks';
import PriorityChip from '../components/PriorityChip';
import TaskInput from '../TaskAdd/TaskInput';
import EditableComponent from './isEditable';
import AssignedTo from 'components/ui/AssignedTo';
import CustomSelect, {
  CustomSelectInput,
  RegisteredSelect,
} from '../components/CustomSelect';
import AttachmentPreview from '../components/AttachmentPreview';
import AttachmentInput from '../components/AttachmentInput';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from '../TaskAdd/styles';
import EditButtons from 'components/EditButtons';
import { useToast } from 'hooks/useToast';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { IConvetArrayType } from 'pages/SchedulePage/AddEventPage/utils';
import { convertString } from '../List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface ITaskViewProps extends getTasks_tasks, Omit<BoxProps, 'title'> {
  closeModal: () => void;
  changeTaskPosition: any;
  getList: (id: string, data: any) => any;
  taskList: any;
}

export default function TaskView({
  title,
  _id,
  description,
  status,
  dueDate,
  priority,
  attachments,
  createdAt,
  updatedAt,
  assignedTo,
  patients,
  closeModal,
  changeTaskPosition,
  getList,
  taskList,
  ...props
}: ITaskViewProps) {
  const { t } = useTranslation();
  const { triggerToast } = useToast();
  function handleGetStatusById(id) {
    return taskList?.find((list) =>
      list?.tasks?.some((task) => task._id === id)
    )?.name;
  }
  const {
    handleEditTask,
    loadingEditTask,
    handleDeleteTaskAttachment,
    handleDeleteTask,
    handleAddTaskAttachment,
    users,
    doctors,
    patients: dataPatient,
  } = useProvider();
  const { user } = useAuth();
  const classes = useStyles();
  const [values, setValues] = useState(convertObjectsFormat(assignedTo, ''));
  const [patientValues, setPatientValues] = useState(
    convertObjectsFormat(patients, 'Patients')
  );

  const getValuesRef = React.useRef<any>({
    title,
    description:
      description && editorStateFromStringifiedRaw(description!, false),
    status,
    dueDate: new Date(dueDate).toString(),
    priority,
    attachments,
  });

  const { register, control, errors, getValues, setValue, reset, watch } =
    useForm({
      defaultValues: {
        title,
        description:
          description && editorStateFromStringifiedRaw(description!, false),
        status: handleGetStatusById(_id),
        dueDate: new Date(dueDate).toString(),
        priority,
        attachments,
      },
    });
  const sourceList = getList(_id, taskList);
  const commonProps = { register, control, errors, variant: 'filled' };
  const currentFormField = getValuesRef.current;

  function handleUpdate() {
    const formValues = getValues();
    getValuesRef.current = { ...getValuesRef.current, ...formValues };
    reset(getValuesRef.current);
    const editedData = { ...getValuesRef.current };
    delete editedData.attachments;
    const destinationList = taskList?.find(
      (el) => el.name === editedData.status
    );

    const updatedTask = {
      ...editedData,
      description:
        editedData?.description &&
        convertToRawAndStringify(editedData.description),
      assignedTo: values?.map(({ _id }) => _id),
      patients: patientValues?.map(({ _id }) => _id),
    };
    handleEditTask(_id, updatedTask);
    if (destinationList?.name !== sourceList?.name)
      changeTaskPosition(
        destinationList?.tasks?.length,
        sourceList._id,
        destinationList._id,
        taskList,
        _id
      );
  }
  const handleSave = () => {
    handleUpdate();
    closeModal();
  };
  const theme = useTheme();

  const isResponsive = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  return (
    <Box {...props} className={classes.responsiveMobile}>
      <ScrollBar className={classes.scroll}>
        <Box px="2rem">
          <Box mt={-2}>
            <TaskInput
              type="text"
              name="title"
              label={t('Title')}
              data-cy="title-input"
              {...commonProps}
            />
          </Box>
          <Box className={classes.labelSpacingAssigned}>
            <Text
              i18nKey="assignedTo"
              className={clsx(classes.label, classes.spacingIcon)}
            >
              Assigned To
            </Text>
          </Box>
          <Symptoms
            options={users}
            values={values}
            setValues={(valuesHere) => {
              setValues(valuesHere);
              const editedData = { ...getValuesRef.current };
              delete editedData.attachments;
              const updatedTask = {
                ...editedData,
                description: convertToRawAndStringify(editedData.description),
                assignedTo: valuesHere?.map(({ id }) => id),
              };
              handleEditTask(_id, updatedTask);
            }}
          />

          <Box width="100%">
            <Box className={classes.labelSpacingAssigned}>
              <Text
                i18nKey="relatedClients"
                className={clsx(classes.label, classes.spacingIcon)}
                style={{ marginBottom: '-5px' }}
              >
                Clients
              </Text>
            </Box>
            <Symptoms
              options={convertObjectsFormat(dataPatient, 'Patients')}
              values={patientValues}
              setValues={(valuesHere) => {
                setPatientValues(valuesHere);
                const editedData = { ...getValuesRef.current };
                delete editedData.attachments;
                const updatedTask = {
                  ...editedData,
                  description: convertToRawAndStringify(editedData.description),
                  patients: valuesHere?.map(({ id }) => id),
                };
                handleEditTask(_id, updatedTask);
              }}
            />
          </Box>
          <Box width="100%">
            <TaskInput
              onChange={(date: any) => {
                setValue('dueDate', date);
                handleUpdate();
              }}
              type="date"
              label={t('Due date')}
              className={classes.inputDate}
              name="dueDate"
              customInput={
                <Box className={classes.inputWrapperCustom}>
                  <PriorityChip
                    data-cy="date-view"
                    className={
                      watch()?.dueDate ? classes.dateChip : classes.noDate
                    }
                    priority={
                      watch()?.dueDate &&
                      formatToFullDateWithoutTime(watch()?.dueDate)
                    }
                  />
                </Box>
              }
              {...commonProps}
            />
          </Box>
          <Box display="flex" mt={4}>
            <FormLabel className={clsx(classes.label)}>
              <Text className={clsx(classes.label)} i18nKey="priority">
                Priority
              </Text>
            </FormLabel>
          </Box>
          <RegisteredSelect
            renderValue={(value: any) => (
              <PriorityChip
                data-cy="priority-view"
                className={classes.chip}
                priority={value}
              />
            )}
            name="priority"
            renderOption={(value: any) => (
              <PriorityChip
                data-cy="select-priority"
                className={classes.chip}
                priority={value}
              />
            )}
            data-cy="select-priority"
            onUpdate={handleUpdate}
            options={['high', 'low', 'medium']}
            control={control}
            register={register}
            style={{ marginTop: '10px', width: '100%' }}
          />
          {errors && errors['priority'] && (
            <FormHelperText className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {(errors as any)['priority'].message}
            </FormHelperText>
          )}
          <Box display="flex" mt={4} pl="5px">
            <FormLabel className={clsx(classes.label, classes.labelMargin)}>
              <Text
                className={clsx(classes.label, classes.labelMargin)}
                i18nKey="status"
              >
                Status
              </Text>
            </FormLabel>
          </Box>
          <RegisteredSelect
            style={{ marginTop: '10px', width: '100%' }}
            renderValue={(value: any) => (
              <PriorityChip
                data-cy="status-view"
                className={classes.chip}
                priority={convertString[value]}
              />
            )}
            name="status"
            renderOption={(value: any) => (
              <PriorityChip
                data-cy="select-status"
                className={classes.chip}
                priority={convertString[value]}
              />
            )}
            options={['overdue', 'pending', 'completed']}
            control={control}
            register={register}
            onUpdate={handleUpdate}
          />
          {errors && errors['status'] && (
            <FormHelperText className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {(errors as any)['status'].message}
            </FormHelperText>
          )}
          <Box mt={2}>
            <TaskInput
              {...commonProps}
              className={classes.draftjsInput}
              type="draftjs"
              name="description"
              label="Description"
            />
            {errors && errors['description'] && (
              <Box>
                <FormHelperText className={classes.error}>
                  <ErrorIcon className={classes.errorIcon} />
                  {(errors as any)['description'].message}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel className={clsx(classes.label)}>
              <Text className={clsx(classes.label)} i18nKey="files">
                Files
              </Text>
            </FormLabel>
            <AttachmentInput
              data-cy="file-add"
              my={1}
              name={t('Upload a file')}
              isActionable
              createdAt={new Date()}
              handleAction={(event: any) => {
                const newFile = event.target?.files[0];
                handleAddTaskAttachment(_id, [newFile]);
              }}
            />
          </Box>
          <Box width="100%" className={classes.filesBox} mt={4}>
            <Typography className={classes.label}>
              <Text className={classes.label} i18nKey="attachedFiles">
                Attached files
              </Text>
            </Typography>

            <Box mt={2} display="flex" flexDirection="column">
              {attachments && attachments?.length > 0 ? (
                attachments.map((attachment) => {
                  const { url, createdAt, fileType, fileName } =
                    attachment || {};
                  const { firstname, lastname, picture } = user || {};
                  return (
                    <AttachmentPreview
                      data-cy="attachment-preview"
                      my={1}
                      width="100%"
                      name={`${firstname} ${lastname}`}
                      url={url || ''}
                      pictureUrl={picture || ''}
                      type={fileType || 'image/nothing'}
                      date={new Date(createdAt)}
                      handleDeleteTaskAttachment={handleDeleteTaskAttachment}
                      fileName={fileName || ''}
                      taskId={_id}
                      closeModal={closeModal}
                    />
                  );
                })
              ) : (
                <Text i18nKey="noAttachement">No attachment</Text>
              )}
            </Box>
          </Box>
        </Box>
      </ScrollBar>
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="1px solid #EAECF0"
      >
        <EditButtons
          handleEdit={handleSave}
          handleOpen={closeModal}
          editable
          px="2rem"
          isSlide
          loading={loadingEditTask}
        />
      </Box>
    </Box>
  );
}
