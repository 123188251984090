import {
  CHANGE_STATUS_EMPTY_CONVERSATION_LIST,
  CLOSE_MODAL_CREATE_NEW_THEAD,
  OPEN_MODAL_CREATE_NEW_THEAD,
  SELECTED_THREAD_ACTION,
} from './action';

export default function useLocalStateDispatcher(dispatch: any) {
  function handleOpenCreateNewThreadModal() {
    dispatch({ type: OPEN_MODAL_CREATE_NEW_THEAD });
  }
  function handleCloseCreateNewThreadModal() {
    dispatch({ type: CLOSE_MODAL_CREATE_NEW_THEAD });
  }
  function handleSelectedThead(threadId: string) {
    dispatch({ type: SELECTED_THREAD_ACTION, payload: threadId });
  }
  function handelIsEmptyConversationList(isEmptyConversationList: boolean) {
    dispatch({
      type: CHANGE_STATUS_EMPTY_CONVERSATION_LIST,
      payload: isEmptyConversationList,
    });
  }

  return {
    handleOpenCreateNewThreadModal,
    handleCloseCreateNewThreadModal,
    handleSelectedThead,
    handelIsEmptyConversationList,
  };
}
