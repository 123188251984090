import React, { ReactElement } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import useStyles from './styles';
import Stepper from './stepper';
import { IDoctor } from './PersonalInformation';
import { ReactComponent as EmptyCertificationIllustration } from 'assets/illustrations/emptyCertifications.svg';
import { ReactComponent as CertIcon } from 'assets/certDoctor.svg';
import { TypeEnum } from './DoctorProfile';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyCert } from 'assets/emptyState/certification.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface ICertificationProps {
  doctor: IDoctor;
  setOpen: (value: TypeEnum) => void;
  isPatient: boolean;
  loading?: boolean;
  handleOpen: () => void;
}

const Certifications = ({
  doctor,
  setOpen,
  isPatient,
  loading,
  handleOpen,
}: ICertificationProps): ReactElement => {
  const classes = useStyles({ isPatient });
  const { certifications, firstname, lastname } = doctor || {};
  const { t } = useTranslation();
  return (
    <Box className={classes.personalInfo}>
      <Box style={{ padding: '24px 25px 25px 25px', boxSizing: 'border-box' }}>
        <Box>
          <Skeleton loading={loading} type="text" width="7rem" height="2.5rem">
            <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
              <CertIcon />
              <Text
                i18nKey="educationAndTraining"
                className={classes.cardtitle}
              >
                Education and training
              </Text>
            </Box>
          </Skeleton>
        </Box>
        {certifications?.length === 0 && !loading ? (
          <Box py="5rem">
            <EmptyState
              icon={<EmptyCert />}
              title={t('No information yet')}
              text={
                isPatient
                ? `${firstname} ${lastname} ${t('have yet to add their education and training')}`
                : t('to add your education and trainings')
              }
              onClick={handleOpen}
              isText={isPatient ? false : true}
              noActionText={isPatient ? true : false}
              hasAction={isPatient ? false : true}
            />
          </Box>
        ) : (
          <Box
            mt="2rem"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            style={{ gap: '50px', flexWrap: 'wrap' }}
          >
            <Stepper certifs={certifications} loading={loading} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Certifications;
