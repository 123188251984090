import { gql, useMutation } from '@apollo/client';
import { PushMessage, PushMessageVariables } from './__generated__/PushMessage';

const PUSH_MESSAGE_MUTATION = gql`
  mutation PushMessage(
    $threadId: ID!
    $content: contentInput!
    $parentMessageId: ID
  ) {
    pushMessage(
      threadConvertationID: $threadId
      content: $content
      parentMessageId: $parentMessageId
    ) {
      participants {
        user {
          _id
        }
      }
    }
  }
`;

const usePushMessage = () => {
  const [pushMessage, { loading }] = useMutation<
    PushMessage,
    PushMessageVariables
  >(PUSH_MESSAGE_MUTATION);
  async function handlePushMessage(
    threadId: string,
    content: any,
    parentMessageId?: string
  ) {
    try {
      return await pushMessage({
        variables: { threadId, content, parentMessageId },
      });
    } catch (e) {
      return e;
    }
  }

  return { handlePushMessage, loading };
};

export { PUSH_MESSAGE_MUTATION };
export default usePushMessage;
