import { gql, useMutation } from '@apollo/client';

const DELETE_QUESTIONNAIRE = gql`
  mutation deleteQuestionnaire($id: ID!) {
    deleteQuestionnaire(id: $id) {
      _id
    }
  }
`;

const useDeleteQuestionnaire = () => {
  return useMutation(DELETE_QUESTIONNAIRE);
};

export default useDeleteQuestionnaire;
