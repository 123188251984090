export function setCryptoKey(value: string) {
  if (!value) {
    return console.error('Error while setting Key');
  }
  const currentCryptoKey = value;
  const useCryptoOffset = true;
  if (currentCryptoKey) {
    console.log('Encryption is ON');
  } else {
    console.log('Encryption is OFF');
  }
  (window as any).worker.postMessage({
    operation: 'setCryptoKey',
    currentCryptoKey,
    useCryptoOffset,
  });
}

export function enableEncryption() {
  (window as any).worker.postMessage({
    operation: 'enableEncryption',
  });
}

export function disableEncryption() {
  (window as any).worker.postMessage({
    operation: 'disableEncryption',
  });
}
