import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as ReminderLogo } from 'assets/ReminderLogo.svg';
import { useStyles } from '../styles';

interface IReminderModalProps {
  handleClose: () => void;
  handleSubmit: () => void;
}

function ReminderModal({
  handleClose,
  handleSubmit,
}: IReminderModalProps): React.ReactElement {
  const classes = useStyles({ selected: false });
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      px={8}
      pb={4}
    >
      <ReminderLogo />
      <Box>
        <Typography className={classes.paymentTitle}>Send reminder</Typography>
        <Typography className={classes.paymentText}>
          Are you sure you want to send a reminder?
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Button className={classes.btnC} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.btnSC} onClick={() => handleSubmit()}>
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default ReminderModal;
