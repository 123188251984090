import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as TerribleIcon } from 'assets/patientActivities/aweful.svg';
import { ReactComponent as BadIcon } from 'assets/patientActivities/bad.svg';
import { ReactComponent as OkayIcon } from 'assets/patientActivities/neutral.svg';
import { ReactComponent as GoodIcon } from 'assets/patientActivities/good-2.svg';
import { ReactComponent as GreatIcon } from 'assets/patientActivities/great.svg';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type IEmojiName = 'Terrible' | 'Bad' | 'Okay' | 'Good' | 'Great';

interface IEmojiItem {
  icon: any;
  name: IEmojiName;
}

const emojiList: IEmojiItem[] = [
  {
    icon: (className?: string) => <TerribleIcon className={className} />,
    name: 'Terrible',
  },
  {
    icon: (className?: string) => <BadIcon className={className} />,
    name: 'Bad',
  },
  {
    icon: (className?: string) => <OkayIcon className={className} />,
    name: 'Okay',
  },
  {
    icon: (className?: string) => <GoodIcon className={className} />,
    name: 'Good',
  },
  {
    icon: (className?: string) => <GreatIcon className={className} />,
    name: 'Great',
  },
];

export const EmojiResponse = ({ onChange, value, setIsEditable }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gridGap="1rem"
      mb={3}
    >
      {emojiList.map((emoji) => (
        <Box
          className={classes.emojiBlock}
          onClick={() => {
            onChange(emoji.name);
            setIsEditable(true);
          }}
        >
          {emoji.icon(value === emoji.name && classes.emojiIconSelected)}
          <Typography
            className={clsx(
              classes.emojiText,
              value === emoji.name && classes.emojiTextSelected
            )}
          >
            {t(`${emoji.name}`)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
