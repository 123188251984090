import React from 'react';
import {
  makeStyles,
  MenuItem,
  Box,
  Typography,
  Avatar,
} from '@material-ui/core';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { fromNow } from 'utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  NotifItem: {
    padding: 0,
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#EFF6FF',
    },
  },
  CardElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBlock: '8px',
    paddingInline: '16px',
    flex: 1,
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: ({ patient }: { patient: boolean }) =>
      patient ? '#1F61DC' : '#425364',
  },
  notifContent: {
    fontFamily: 'Inter, sans-serif',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: theme.typography.pxToRem(468),
    color: '#475467',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  button: {
    cursor: 'pointer',
    minWidth: '19px',
  },
  notifIcon: {
    minHeight: '52px',
    minWidth: '52px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
  },
  date: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#1F61DC',
  },
  NotifButton: {
    background: 'transparent',
    border: '1px solid #1F61DC',
    color: '#1F61DC',
    borderRadius: '16px',
    paddingInline: '12px',
    height: theme.typography.pxToRem(30),
    fontWeight: 500,
    fontSize: '12px',
    cursor: 'pointer',
    marginTop: '5px',
    textTransform: 'none',
  },
  unread: {
    width: '8px',
    aspectRatio: '1/1',
    borderRadius: '100%',
    background: '#147AF3',
  },
}));

function NotifCard({
  element,
  key,
  setAnchor,
  setDeleteId,
  setCardId,
  IsPatient,
  anchor,
}) {
  const {
    onClick,
    title,
    content,
    type,
    id,
    date,
    read,
    appointmentId,
    avatar,
  } = element;
  const { t } = useTranslation();

  const [more, setMore] = React.useState(false);
  const classes = useStyles({ patient: IsPatient });
  const history = useHistory();
  function Click(event) {
    onClick && onClick();
    onclick();
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
    setDeleteId(id);
    setCardId(key);
  };
  const notificationTypes = {
    new_Appointment: {
      onclick: () => {
        IsPatient
          ? history.push('/patient/appointment', { appointmentId })
          : history.push('/doctor/schedule', { appointmentId });
      },
    },
    update_Appointment: {
      onclick: () => {
        IsPatient
          ? history.push('/patient/appointment', { appointmentId })
          : history.push('/doctor/schedule', { appointmentId });
      },
    },
    new_PatientRequest: {
      onclick: () => {
        IsPatient
          ? history.push('/patient/access_control')
          : history.push('/doctor/patient');
      },
    },
    add_RefillRequest: {
      onclick: () => {
        !IsPatient && history?.push('/doctor/refillRequests');
      },
    },
  };
  const { onclick } = notificationTypes[type] || {};
  function handleCardClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    handleClick(e);
  }
  const handleMouseEnter = () => {
    setMore(true);
  };

  const handleMouseLeave = () => {
    !anchor && setMore(false);
  };
  return (
    <MenuItem key={key} onClick={Click} className={classes.NotifItem}>
      <Box
        className={classes.CardElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box display="flex" alignItems="center" gridGap="1.5rem">
          <Box
            style={{
              minWidth: '8px',
              minHeight: '8px',
            }}
          >
            {!read && <div className={classes.unread} />}
          </Box>
          <Box>
            <Avatar src={avatar} className={classes.notifIcon} />
          </Box>
          <Box className={classes.card}>
            <Typography className={classes.title}>{t(title)}</Typography>
            <Typography className={classes.notifContent}>{content}</Typography>
            <Typography className={classes.date}>{fromNow(date)}</Typography>
          </Box>
        </Box>
        <Box className={classes.button} onClick={more && handleCardClick}>
          {more && <MoreIcon style={{ transform: 'rotate(90deg)' }} />}
        </Box>
      </Box>
    </MenuItem>
  );
}
export default NotifCard;
