import React, { useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/paragraph';
import { useEditorContext } from './EditorContextProvider';
import './editor.css';
import { CustomToolbar } from './Toolbar';
import { CustomSidebar } from './Sidebar';

interface IEditorjsTypeProps {
  handleSave: () => void;
  handleClose: () => void;
}

const Editorjs = ({ handleClose, handleSave }: IEditorjsTypeProps) => {
  const { initEditor, blocks } = useEditorContext();
  const ref = useRef<any>();
  React.useEffect(() => {
    if (!ref.current) {
      initEditor();
    }

    return () => {
      if (ref.current) {
        ref.current.destroy();
        ref.current = null;
      }
    };
  }, [blocks]);

  return (
    <div style={{ backgroundColor: 'rgb(244, 242, 238)' }}>
      <CustomToolbar handleClose={handleClose} handleSave={handleSave} />
      <div style={{ display: 'flex' }}>
        <CustomSidebar />
        <div
          id="editorjs"
          style={{
            flex: 8,
            backgroundColor: '#F9FAFB',
            borderRadius: '16px',
            marginRight: '1rem',
            marginBottom: '1rem',
            paddingTop: '2rem',
          }}
        />
      </div>
    </div>
  );
};

export default Editorjs;
