import { makeStyles } from '@material-ui/core/styles';
import CreatePatientIllustration from 'assets/illustrations/createPatientIllustration.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '0' : '3rem',
    maxWidth: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? 'auto' : '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    gap: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  draft: {
    height: '150px',
    maxHeight: '150px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '16px',
    border: '1px solid #EAECF0',
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    flexGrow: 1,
  },
  editButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    display: 'flex',
    alignItems: 'center',
    color: 'transparent',
    cursor: 'pointer',
  },
  skeletonEdit: {
    position: 'absolute',
    right: '27px',
    top: '20px',
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginTop: '10px',
    border: '2px solid #EAECF0',
    textTransform: 'capitalize',
    [theme.breakpoints.only('xs')]: {
      margin: '0px',
    },
  },
  buttons: {
    height: '8vh',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid #EAECF0',

    [theme.breakpoints.down('lg')]: {
      height: '12vh',
    },
  },
  personalInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
  },
  name: {
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: 700,
    color: '#101828',
    textTransform: 'capitalize',
    marginTop: '20px',
  },
  speciality: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#475467',
    textTransform: 'capitalize',
    marginTop: '0',
  },
  city: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    color: '#475467',
    textTransform: 'capitalize',
    marginTop: '0',
  },
  experience: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  },
  experienceTitle: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 600,
    color: '#425364',
  },
  experienceText: {
    fontSize: '16px',
    lineHeight: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 700,
    color: '#101828',
  },
  focusTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 600,
    color: '#101828',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '14px',
  },
  infoItemStart: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  infoText: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#666666',
  },
  aboutText: {
    marginBottom: '-1rem',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    fontWeight: 400,
    color: '#757F8D',
  },
  cardtitle: {
    fontSize: '25px',
    lineHeight: '20px',
    fontWeight: 600,
    color: '#101828',
  },
  infoTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#666666',
  },
  aboutTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#273142',
    marginLeft: '5px',
  },
  focusArea: {
    backgroundColor: 'white',
    borderRadius: '6px',
    padding: '3px 8px ',
    border: '1px solid #D0D5DD',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#344054',
    textTransform: 'none',
  },
  modalHeader: {
    backgroundImage: `url(${CreatePatientIllustration})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    height: theme.typography.pxToRem(128),
    display: 'flex',
    alignItems: 'center',
    padding: '0 3rem',
  },
  headerAvatar: {
    width: '70px',
    height: '70px',
    textTransform: 'capitalize',
  },
  headerText: {
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    textTransform: 'capitalize',
    color: '#425364',
  },
  scroll: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 'calc(100vh - 58px)',
    borderTop: '1px solid #EAECF0',
  },
  modalBox: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '45vw',
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#344054',
  },
  tab: {
    paddingInline: '3rem',
    marginTop: '40px',
    marginBottom: '20px',
  },
  icon: {
    marginRight: '5px',
  },
  ScrollContainer: {
    flex: 1,
  },
  specialityEmpty: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#1F61DC',
    marginTop: '5px',
    width: 'fit-content',
  },
  emptyAbout: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#425364',
    marginTop: '10px',
  },
  emptyAboutButton: {
    paddingBlock: '5px',
    paddingInline: '10px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: 'white',
    marginTop: '1.5rem',
    backgroundColor: '#1F61DC',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    fontFamily: 'Roboto',
    marginLeft: '3px',
    letterSpacing: '0.03333em',
    marginTop: '4px',
    display: 'block',
  },
  badge: {
    cursor: 'pointer',
  },
}));
export default useStyles;
