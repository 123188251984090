import React, { useRef, ReactElement } from 'react';
import { Box, Grid, Button, makeStyles, Typography } from '@material-ui/core';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import ErrorIcon from '@material-ui/icons/Error';
import Input from '../components/ui/Inputs';
import clsx from 'clsx';
import { useSnackbar } from './useSnackbar';

const useStyles = makeStyles((theme) => ({
  height: {
    marginTop: theme.spacing(2),
    height: theme.typography.pxToRem(200),
    maxHeight: theme.typography.pxToRem(200),
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '3px',
  },
  errorDraft: {
    fontFamily: 'Roboto',
    marginTop: '4px',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
    color: 'red',
  },
  label: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#344054',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  deleteContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  filed: {
    marginTop: '10px',
  },
  container: {
    padding: '0.5rem 2rem',
    position: 'relative',
    boxSizing: 'border-box',

    [theme.breakpoints.up('xl')]: {
      padding: '1rem 2rem',
    },
  },
  deleteLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    color: '#B42318',
  },
  editor: {
    minHeight: '35vh',
  },
}));
export function editedFields(defaultValues: any, values: any, dirty: any) {
  const editedFieldsSet: any = new Set();
  defaultValues.forEach((value: any, index: number) => {
    Object.keys(value).forEach((key: string) => {
      if (dirty.has(`${key}${index}`)) {
        editedFieldsSet.add(values[index]);
      }
    });
  });
  return [...editedFieldsSet];
}
function filterNewValues(valuesArray: any[]) {
  const array = valuesArray || [];
  return array.filter(({ _id }: any) => _id === '');
}
interface IUseFormFieldsArrayProps {
  defaultValues?: any;
  fieldArrayName: string;
  inputs: any;
  handleAction: any;
  schema?: any;
  lastField: string;
  margin: any;
  marginTop: any;
  marginBottom: any;
  triggerToast: (start: number, end: number) => void;
  deleteButton?: boolean;
  isEditableDraftjs?: boolean;
  isConditions?: boolean;
}

interface IUseFormFieldsArrayReturn {
  formRender: ReactElement;
  formRef: any;
  triggerSubmit: () => void;
  addField: () => void;
  removeField: (index: number) => void;
  handleSubmit: any;
  fields: () => any;
  onSubmit: (data: any, e: any) => void;
}

export default function useFormFieldsArray({
  isConditions = false,
  isEditableDraftjs = false,
  defaultValues,
  inputs,
  fieldArrayName,
  handleAction,
  lastField,
  margin,
  marginTop,
  marginBottom,
  triggerToast,
  schema = yup.object().shape({}),
  deleteButton,
}: IUseFormFieldsArrayProps): IUseFormFieldsArrayReturn {
  const classes = useStyles();
  const { triggerSnack } = useSnackbar();
  const formRef = useRef<any>();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState,
    watch,
    errors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    fields,
    append,
    remove,
    // prepend, swap, move, insert
  } = useFieldArray({
    control,
    name: fieldArrayName,
  });
  // Needs to get Data before data because its using proxy for performance
  const { dirtyFields } = formState;
  const onSubmit = (data: any, e: any) => {
    const editedIndexes =
      dirtyFields && dirtyFields.array ? Object.keys(dirtyFields.array) : [];
    const newValues = filterNewValues(data[fieldArrayName]);
    const editedValues =
      data[fieldArrayName] &&
      data[fieldArrayName]?.filter(
        (element: any, index: number) =>
          editedIndexes.includes(index.toString()) && element._id !== ''
      );
    setTimeout(
      handleAction(newValues, editedValues, data[fieldArrayName]),
      500
    );

    triggerSnack();
  };

  const addField = () => {
    append({});
  };

  const removeField = (index: number) => {
    if (fields.length === 1) {
      handleAction([], [], []);
      remove(index);
    } else {
      remove(index);
    }
  };

  function triggerSubmit() {
    handleSubmit(onSubmit)();
  }
  function getCustom(inputs: any, index: number) {
    if (index + 1 < inputs.length && inputs[index + 1].type === 'custom')
      return true;
    return false;
  }

  const formRender = (
    <>
      {fields.map((item, index) => {
        const fieldName = `${fieldArrayName}[${index}]`;
        return (
          <Grid container key={item.id} className={classes.container}>
            <Grid
              container
              spacing={2}
              key={item.id}
              style={{
                margin: 0,
                marginTop: '1.5rem',
                border: !isConditions && '1px solid #D0D5DD',
                borderRadius: '8px',
                padding: !isConditions && '1rem',
              }}
            >
              {inputs.map(
                (
                  {
                    label,
                    type,
                    rows,
                    col,
                    condition,
                    xl = col,
                    lg = col,
                    md = col,
                    sm = col,
                    xs = col,
                    length,
                    Custom,
                    handleAction,
                    handleDelete,
                    tileStyle,
                    style,
                    name,
                    ...props
                  }: any,
                  inputIndex: number
                ) => {
                  return (
                    <>
                      {type !== 'custom' && (
                        <Grid
                          item
                          key={props.name}
                          xl={xl}
                          lg={lg}
                          sm={sm}
                          md={md}
                          xs={xs}
                          style={{ paddingInline: isConditions && 0 }}
                        >
                          <Box>
                            {Custom ? (
                              <>
                                <Typography
                                  className={classes.label}
                                  style={{
                                    fontSize: isConditions && '14px',
                                    fontWeight: isConditions && 400,
                                  }}
                                >
                                  {label}
                                </Typography>

                                <Box
                                  className={clsx(
                                    classes.deleteContainer,
                                    classes.filed
                                  )}
                                >
                                  <Box width="100%">
                                    <Input
                                      size="small"
                                      control={control}
                                      name={`${fieldName}.${name}`}
                                      defaultValue={item[name]}
                                      {...props}
                                      type={type}
                                      inputRef={register}
                                      className={clsx(
                                        type === 'draftjs' && classes.height
                                      )}
                                      error={
                                        errors[fieldArrayName] &&
                                        errors[fieldArrayName][index] &&
                                        errors[fieldArrayName][index][name]
                                      }
                                      helperText={
                                        errors[fieldArrayName] &&
                                        errors[fieldArrayName][index] &&
                                        errors[fieldArrayName][index][name] && (
                                          <Box className={classes.error}>
                                            <ErrorIcon
                                              className={classes.errorIcon}
                                            />
                                            {
                                              errors[fieldArrayName][index][
                                                name
                                              ]?.message
                                            }
                                          </Box>
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              </>
                            ) : condition ? (
                              condition(watch, index) && (
                                <Box>
                                  <Typography
                                    className={classes.label}
                                    style={{
                                      fontSize: isConditions && '14px',
                                      fontWeight: isConditions && 400,
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                  <Box className={classes.filed}>
                                    <Input
                                      size="small"
                                      // style={{ marginBottom: '10px' }}
                                      control={control}
                                      name={`${fieldName}.${name}`}
                                      defaultValue={item[name]}
                                      {...props}
                                      type={type}
                                      inputRef={register}
                                      className={clsx(
                                        type === 'draftjs' && classes.height
                                      )}
                                      error={
                                        errors[fieldArrayName] &&
                                        errors[fieldArrayName][index] &&
                                        errors[fieldArrayName][index][name]
                                      }
                                      helperText={
                                        errors[fieldArrayName] &&
                                        errors[fieldArrayName][index] &&
                                        errors[fieldArrayName][index][name] && (
                                          <Box className={classes.error}>
                                            <ErrorIcon
                                              className={classes.errorIcon}
                                            />
                                            {
                                              errors[fieldArrayName][index][
                                                name
                                              ]?.message
                                            }
                                          </Box>
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              )
                            ) : (
                              <Box mt={type === 'draftjs' && -2}>
                                <Typography
                                  className={classes.label}
                                  style={{
                                    fontSize: isConditions && '14px',
                                    fontWeight: isConditions && 400,
                                    marginTop: isConditions && '1rem',
                                  }}
                                >
                                  {label}
                                </Typography>
                                <Box className={classes.filed}>
                                  <Input
                                    size="small"
                                    // style={{ marginBottom: '10px' }}
                                    control={control}
                                    name={`${fieldName}.${name}`}
                                    defaultValue={item[name]}
                                    {...props}
                                    type={type}
                                    inputRef={register}
                                    className={clsx(
                                      type === 'draftjs' && classes.height
                                    )}
                                    editorClassName={
                                      isEditableDraftjs &&
                                      type === 'draftjs' &&
                                      classes.editor
                                    }
                                    error={
                                      errors[fieldArrayName] &&
                                      errors[fieldArrayName][index] &&
                                      errors[fieldArrayName][index][name]
                                    }
                                    helperText={
                                      errors[fieldArrayName] &&
                                      errors[fieldArrayName][index] &&
                                      errors[fieldArrayName][index][name] && (
                                        <Box className={classes.error}>
                                          <ErrorIcon
                                            className={classes.errorIcon}
                                          />
                                          {
                                            errors[fieldArrayName][index][name]
                                              ?.message
                                          }
                                        </Box>
                                      )
                                    }
                                  />
                                  {type === 'draftjs' &&
                                    errors[fieldArrayName] &&
                                    errors[fieldArrayName][index] &&
                                    errors[fieldArrayName][index][name] && (
                                      <Box
                                        className={clsx(
                                          classes.error,
                                          classes.errorDraft
                                        )}
                                      >
                                        <ErrorIcon
                                          className={classes.errorIcon}
                                        />
                                        {
                                          errors[fieldArrayName][index][name]
                                            ?.message
                                        }
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )}
                      {type === 'custom' && deleteButton && (
                        <Grid
                          item
                          key={props.name}
                          xl={xl}
                          lg={lg}
                          sm={sm}
                          md={md}
                          xs={xs}
                        >
                          <Box
                            className={classes.deleteButton}
                            onClick={() => removeField(index)}
                          >
                            <Custom
                              {...{
                                inputs,
                                index: inputIndex,
                                value: item,
                                handleDelete: () => {
                                  removeField(index);
                                },
                                handleAction,
                              }}
                            />
                            <span className={classes.deleteLabel}>{label}</span>
                          </Box>
                        </Grid>
                      )}
                    </>
                  );
                }
              )}
            </Grid>
            <input
              type="hidden"
              value={item?._id}
              ref={register}
              name={`${fieldName}._id`}
            />
          </Grid>
        );
      })}
    </>
  );
  return {
    formRender,
    formRef,
    triggerSubmit,
    addField,
    removeField,
    handleSubmit,
    fields: getValues,
    onSubmit,
  };
}
