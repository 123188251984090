import DeleteHandler from 'components/DeleteHandler';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

export const MedicationConditionsForm = (handleDeleteCondition: any) => {
  const { t } = useTranslation();

  return [
    {
      name: 'name',
      label: t('Name'),
      Custom: DeleteHandler,
      handleAction: handleDeleteCondition,
      type: 'textcontrolled',
      variant: 'filled',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
      'data-cy': 'condition-name',
      placeholder: t('Hypertension'),
    },
    {
      name: 'type',
      label: t('Type'),
      type: 'select',
      variant: 'filled',
      options: ['Past', 'Current'],
      defaultValue: 'Current',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
      'data-cy': 'condition-type',
    },
    {
      name: 'dateAdded',
      label: t('Since'),
      type: 'date',
      variant: 'filled',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
      'data-cy': 'condition-date',
    },
    {
      name: 'dateEnded',
      label: t('To'),
      type: 'date',
      variant: 'filled',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
      condition: (
        watcher: (names?: string | string[]) => string,
        index?: number
      ) => {
        return (
          watcher(`array[${index}].type`)?.toUpperCase() ===
          t('past').toUpperCase()
        );
      },
      'data-cy': 'condition-dateEnded',
    },
    {
      name: 'treatments',
      label: t('Treatment'),
      type: 'draftjs',
      variant: 'filled',
      col: 12,
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
      'data-cy': 'condition-treatments',
      placeholder: t('Prescription medication and lifestyle changes'),
    },
    {
      type: 'custom',
      Custom: DeleteHandler,
      style: { position: 'absolute', left: 0, top: 0 },
      handleAction: handleDeleteCondition,
      col: 0,
    },
  ];
};
