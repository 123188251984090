import { gql, useMutation } from '@apollo/client';
import {
  deletePrescription,
  deletePrescriptionVariables,
} from './__generated__/deletePrescription';

const DELETE_PRESCRIPTION = gql`
  mutation deletePrescription($prescriptionId: ID!) {
    deletePrescription(prescriptionId: $prescriptionId) {
      _id
      appointmentDate
      startDate
      endDate
      drugs {
        _id
        name
        type
        dosage
        duration
        frequency
        advice
      }
    }
  }
`;

export default function useDeletePrescription() {
  const [deletePrescription, result] = useMutation<
    deletePrescription,
    deletePrescriptionVariables
  >(DELETE_PRESCRIPTION, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentPrescription() {
            try {
              return data?.deletePrescription;
            } catch (e) {}
          },
        },
      });
    },
  });

  return { deletePrescription, ...result };
}
