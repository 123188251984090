import { Box, Grid } from '@material-ui/core';
import React from 'react';
import NotificationChannelCard from './NotificationChannelCard';
import NotificationEventCard from './NotificationEventCard';
import useStyles from './styles';

function NotificationBlock({
  channel,
  index,
  loadingNotifs,
  data,
  register,
  isPatient,
  settingsIcons,
}) {
  const [checked, setChecked] = React.useState(
    data?.getNotificationSettingsByUser?.channelSettings?.some(
      (el) => el?.channel?._id === channel._id
    )
  );

  return (
    <Box
      mt="1rem"
      style={{
        border: '1px solid #D0D5DD',
        borderRadius: '8px',
        paddingBottom: '1rem',
      }}
    >
      <NotificationChannelCard
        index={index}
        id={channel._id}
        loading={loadingNotifs}
        icon={settingsIcons[channel.label]}
        label={channel.label}
        register={register}
        defaultChecked={checked}
        checked={checked}
        setChecked={setChecked}
      />
      {checked && (
        <Grid item xl={12} lg={12} md={12}>
          <Box
            style={{
              borderBottom: '1px solid #F4F2EE',
              marginInline: '1rem',
              marginBottom: '1rem',
            }}
          />
          <EventsComponent
            index={index}
            data={data}
            loadingNotifs={loadingNotifs}
            register={register}
            isPatient={isPatient}
            settingsIcons={settingsIcons}
            channel={channel.label}
            notifSettings={data?.getNotificationSettingsByUser?.channelSettings?.find(
              (el) => el?.channel?._id === channel?._id
            )}
          />
        </Grid>
      )}
    </Box>
  );
}

const EventsComponent = ({
  loadingNotifs,
  data,
  register,
  isPatient,
  settingsIcons,
  index,
  notifSettings,
  channel,
}) => {
  const events =
    channel === 'Text'
      ? data?.notificationEvents?.filter((e) => e.title !== 'Activities')
      : data?.notificationEvents;
  return (
    <Box>
      {loadingNotifs &&
        [1, 2, 3, 4, 5].map((e) => (
          <NotificationEventCard loading={loadingNotifs} />
        ))}
      {events?.map((event: any) => (
        <NotificationEventCard
          index={index}
          loading={loadingNotifs}
          icon={settingsIcons[event.patientTitle || event.title]}
          title={isPatient ? event.patientTitle : event.title}
          description={isPatient ? event.patientDescription : event.description}
          register={register}
          defaultChecked={notifSettings?.events.some(
            (el) => el._id === event._id
          )}
          id={event._id}
        />
      ))}
    </Box>
  );
};

export default NotificationBlock;
