import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Switch from 'components/ui/Switch';
import Skeleton from 'components/ui/Skeleton';
import useStyles from './styles';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const getI18nKeyText = (title) => {
  const labelToKeyMap = {
    'Prescription refills': 'prescriptionRefills',
    'Access permissions': 'accessPermissions',
  };
  return labelToKeyMap[title] || title; // Fallback to label itself if no key is found
};
const getI18nKeyDescription = (description) => {
  const labelToKeyMap = {
    'These notifications when the client books a new session or updates a session or cancels or accept a session.':
      'theseNotificationsWhenTheClientBooksANewSessionOrUpdatesASessionOrCancelsOrAcceptASession',
    'These notifications when the client requests to refill a prescription.':
      'theseNotificationsWhenTheClientRequestsToRefillAPrescription',
    'These notifications when the client or the staff sends you a direct message.':
      'These notifications when the client or the staff sends you a direct message',
    'These notifications when the client revokes or gives you an access permission to their personal information.':
      'These notifications when the client revokes or gives you an access permission to their personal information',
    'These notifications when the client responds to an activity':
      'These notifications when the client responds to an activity',
  };
  return labelToKeyMap[description] || description; // Fallback to label itself if no key is found
};
interface IProps {
  loading: boolean;
  id?: string;
  icon?: React.ReactElement;
  title?: string;
  description?: string;
  register?: any;
  defaultChecked?: boolean;
  index?: number;
}
const eventNames = {
  0: 'pushEvents',
  1: 'emailEvents',
  2: 'textEvents',
};

function NotificationEventCard({
  loading,
  id,
  icon,
  title,
  description,
  register,
  defaultChecked,
  index,
}: IProps) {
  const i18nKeyText = getI18nKeyText(title);
  const i18nKeyDesc = getI18nKeyDescription(description);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.channel}>
      <Box
        display="flex"
        justifyItems="center"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Box>
          <Skeleton loading={loading} type="text" width="10rem" height="35px">
            <Text i18nKey={i18nKeyText} className={classes.eventName}>
              {title}
            </Text>
          </Skeleton>
          <Skeleton loading={loading} type="text" width="50rem" height="25px">
            <Text i18nKey={i18nKeyDesc} className={classes.eventDesc}>
              {t(description)}
            </Text>
          </Skeleton>
        </Box>
      </Box>
      {!loading && (
        <Switch
          name={eventNames[index]}
          inputRef={register}
          defaultChecked={defaultChecked}
          value={id}
        />
      )}
    </Box>
  );
}

export default NotificationEventCard;
