import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: '7px',
    '& > .MuiFormHelperText-contained': {
      marginLeft: '0',
    },
  },
  label: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#425364',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  error: {
    '&.Mui-error': {
      color: 'red',
      marginLeft: '3px',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
}));

export default useStyles;
