import React from 'react';
import { gql } from '@apollo/client';
import { AddPaymentMethod } from './AddPaymentMethod';
import { MakePayment } from './MakePayment';
import { Box } from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import EditButtons from 'components/EditButtons';
import useBilling from 'pages/AdminPage/Billing/useBilling';

interface Iprops {
  closeModal: () => void;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  doctor: {
    firstname: string;
    lastname: string;
    picture: string;
    email?: string;
    _id: string;
  };
  startDate: string;
  endDate: string;
  _id: string;
  isPending?: boolean;
}

const PaymentModal = ({
  closeModal,
  step,
  setStep,
  doctor,
  startDate,
  endDate,
  _id: appointmentId,
  isPending = false,
}: Iprops) => {
  const {
    editMode,
    dropDownEL,
    createCreditCard,
    user,
    loading,
    handleClose,

    handleDelete,
  } = useBilling();
  const stripe = useStripe();
  const elements = useElements();
  async function handleSubmit(event) {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${user?.firstname} ${user?.lastname}`,
      },
    });
    await createCreditCard({
      variables: {
        creditCardInput: {
          paymentMethodToken: paymentMethod.id,
        },
      },
    });
    setStep(1);
  }

  const stepContent = {
    1: (
      <MakePayment
        doctor={doctor || {}}
        closeModal={closeModal}
        startDate={startDate}
        endDate={endDate}
        isPending={isPending}
        setStep={setStep}
        appointmentId={appointmentId}
      />
    ),
    2: (
      <Box style={{ width: '20vw' }} px={4}>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box mt={3}>
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                    color: 'black',
                    '::placeholder': {
                      color: 'grey',
                    },
                  },
                  invalid: {
                    color: 'red',
                  },
                },
              }}
            />
          </Box>
          <Box mt={4}>
            <Box
              height="6vh"
              display="flex"
              alignItems="center"
              width="100%"
              borderTop="1px solid #EAECF0"
              style={{
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
              }}
              // mx="5rem"
              // mt={5}
              // mb={2}
              py={1}
            >
              <EditButtons
                editable
                isPatient={true}
                type="submit"
                loading={loading}
                handleOpen={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
                editMode={editMode}
                handleDelete={handleDelete as any}
                px={'0rem'}
                // isSlide
              />
            </Box>
          </Box>
        </form>
      </Box>
    ),
  };

  return <>{stepContent[step]}</>;
};

export default PaymentModal;

export const APPOINTMENT = gql`
  query appointment($appointmentId: ID!) {
    appointment(appointmentId: $appointmentId) {
      sessionType {
        rate
        session
        duration
      }
      isPrepay
      invitation {
        invitation_id
      }
      isPaid
      _id
      startDate
      endDate
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
    }
  }
`;
