
import i18next from 'i18next';

export function splitText(text: string) {
  const { language } = i18next;
  let newText = text.replace(
    /(sleep|mood|reflection|message|task|session)/g,
    (match, p1) => {
      if (p1 === 'sleep') {
        return language === 'fr' ? '<span style="color: #0265DC;">Évaluation du sommeil</span>' : '<span style="color: #0265DC;">Sleep check-in</span>';
      } else if (p1 === 'mood') {
        return language === 'fr' ? `<span style="color: #0265DC;">Évaluation d'humeur</span>` : '<span style="color: #0265DC;">Mood check-in</span>';
      } else if (p1 === 'reflection') {
        return language === 'fr' ? `<span style="color: #0265DC;">Réflexion</span>` : '<span style="color: #0265DC;">Reflection</span>';
      } else if (p1 === 'message') {
        return '<span style="color: #0265DC;">message</span>';
      } else if (p1 === 'task') {
        return language === 'fr' ? `<span style="color: #0265DC;">Tâche</span>` : '<span style="color: #0265DC;">task</span>';
      } else if (p1 === 'session') {
        return language === 'fr' ? `<span style="color: #0265DC;">Séance</span>` :  '<span style="color: #0265DC;">session</span>';
      }
    }
  );
  newText = newText.replace(/Checkin/g, '');
  return newText;
}

export function getTypeFromText(text: string) {
  return text?.split('logged a')[1]?.trim() || text?.split('a enregistré une')[1]?.trim();
}
