import { gql, useQuery } from '@apollo/client';

export const PATIENT_ACCESS_DOCTOR = gql`
  query access($filter: FilterListInput) {
    patients(filter: $filter) {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
    }
  }
`;

export const DOCTOR_ACCESS_PATIENT = gql`
  query doctorsAccessPatient {
    doctors {
      _id
      firstname
      lastname
      middlename
      phone
      email
      picture
      address
      city
      zipcode
      country
      suite
      about
      gender
      noUsedFeatures
      username
    }
  }
`;
export const PATIENTS = gql`
  query allPatients {
    allPatients {
      _id
      firstname
      lastname
      middlename
      phone
      email
      picture
      address
      city
      zipcode
      country
      suite
      gender
    }
  }
`;

const useGetPatientAccess = (filter) => {
  const { data, error, loading, refetch } = useQuery(PATIENT_ACCESS_DOCTOR, {
    variables: { filter },
  });
  return { data, error, loading, refetch };
};

const useGetDoctorAccess = () => {
  const { data, error, loading } = useQuery(DOCTOR_ACCESS_PATIENT);
  return { data, error, loading };
};

const useGetAllPatient = () => {
  const { data, error, loading, refetch } = useQuery(PATIENTS);
  return { data, error, loading, refetch };
};

export { useGetPatientAccess, useGetDoctorAccess, useGetAllPatient };
