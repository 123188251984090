import { log } from 'config';

export function setupReceiverTransform(receiver: any) {
  try {
    const receiverStreams = receiver.createEncodedStreams();
    const readableStream =
      receiverStreams.readable || receiverStreams.readableStream;
    const writableStream =
      receiverStreams.writable || receiverStreams.writableStream;
    (window as any).worker.postMessage(
      {
        operation: 'decode',
        readableStream,
        writableStream,
      },
      [readableStream, writableStream]
    );
  } catch (e) {
    log('error', e, 'Stream already created');
  }
}

export function setupSenderTransform(sender: any) {
  console.log('sender transform working');
  const senderStreams = sender.createEncodedStreams();
  const readableStream = senderStreams.readable || senderStreams.readableStream;
  const writableStream = senderStreams.writable || senderStreams.writableStream;
  (window as any).worker.postMessage(
    {
      operation: 'encode',
      readableStream,
      writableStream,
    },
    [readableStream, writableStream]
  );
}
