import { gql, useMutation } from '@apollo/client';

export const CHECK_PASSWORD = gql`
  mutation checkPassword($password: String) {
    checkPassword(password: $password)
  }
`;

export default function useCheckPassword() {
  const [checkPassword] = useMutation(CHECK_PASSWORD);
  return { checkPassword };
}
