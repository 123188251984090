export function sortByKey<T>(data: T[], key: string, type: 'up' | 'down') {
  const upType = 'up';
  function Upsort(firstObject: any, secondObject: any) {
    if (firstObject[key] > secondObject[key]) return 1;

    if (firstObject[key] < secondObject[key]) return -1;

    return 0;
  }
  function DownSort(firstObject: any, secondObject: any) {
    if (firstObject[key] < secondObject[key]) return 1;

    if (firstObject[key] > secondObject[key]) return -1;

    return 0;
  }
  const sortedData = data?.slice().sort(type === upType ? Upsort : DownSort);
  return sortedData;
}

export function calculatePaginationTabs<T>(limit: number, dataLength: number) {
  const pages = dataLength / limit;
  return Math.ceil(pages);
}

export function getLimitData<T>(
  page: number,
  limit: number,
  data: T[],
  fullData: boolean
): T[] {
  const startingIndex = page === 1 ? 0 : (page - 1) * limit;
  const limitedData =
    data && fullData
      ? data?.slice(startingIndex, limit + startingIndex)
      : data?.slice(0, limit);
  return page ? limitedData : data;
}
