import React, { useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as GenralInfoIcon } from 'assets/patientabs/genralInfoIcon.svg';
import { ReactComponent as AddressIcon } from 'assets/icons/address.svg';
import { ReactComponent as BasicInfoIcon } from 'assets/icons/basicinfo.svg';
import { ReactComponent as HeightIcon } from 'assets/icons/heighticongray.svg';
import { ReactComponent as WeightIcon } from 'assets/icons/weighticongray.svg';
import { ReactComponent as BloodIcon } from 'assets/icons/bloodicongray.svg';
import { ReactComponent as DescriptionIcon } from 'assets/icons/descriptionGreenIcon.svg';
import { ReactComponent as InchIcon } from 'assets/inch.svg';
import { ReactComponent as FtIcon } from 'assets/ft.svg';
import { Box } from '@material-ui/core';
import { heightTypeEnum } from './AddPatientModalForm';
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(27),
    fontWeight: 900,
    color: '#425364',
    marginTop: '0.5em',
    overflowWrap: 'anywhere',
  },
  descriptionInput: {
    height: theme.typography.pxToRem(270),
    maxHeight: theme.typography.pxToRem(270),
  },
  error: {
    fontSize: '12px',
    color: 'red',
    marginLeft: '3px',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  titleWrapper: {
    // backgroundColor: '#F8FAFB',
    padding: '10px',
    // border: '1px solid #D7E3F1',
    borderRadius: '12px',
  },
  fieldIcon: {
    color: '#CED5D8',
  },
  inputStyle: {
    height: '278px',
    maxHeight: '278px',
    marginTop: '10px',
  },
  optionalLabel: {
    whiteSpace: 'nowrap',
  },
  hideOverflow: {
    '& .MuiInputBase-root': {
      overflow: 'hidden',
    },
    '& .MuiFilledInput-input': {
      padding: '16px 0 16px 16px',
    },
  },
}));

const inputTopSpacing = { marginTop: '20px' };
const styles = '11px';

export const GeneralInformationForm = (
  errors: any,
  defaultValues: any,
  setHeightType: React.Dispatch<React.SetStateAction<string>>,
  heightType: string
) => {
  const classes = useStyles();
  const generalInfo = [
    {
      type: 'custom',
      Custom: () => (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <GenralInfoIcon />
          <Box ml={2} width="100%" className={classes.titleWrapper}>
            <span className={classes.sectionTitle}>General information</span>
          </Box>
        </Box>
      ),
      tileStyle: { height: '80px' },
      col: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'firstname',
      col: 6,
      lg: 6,
      md: 12,
      sm: 12,
      xs: 12,
      type: 'text',
      'data-cy': 'firstname-input',
      label: 'First name',
      placeholder: 'John',
      variant: 'filled',
      defaultValue: defaultValues.firstname,
      helperText: errors.firstname && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.firstname?.message}
        </span>
      ),
      error: !!errors?.firstname,
    },
    {
      name: 'middlename',
      col: 6,
      lg: 6,
      sm: 12,
      md: 12,
      xs: 12,
      type: 'text',
      'data-cy': 'middlename-input',
      label: 'Middle name',
      variant: 'filled',
      placeholder: 'Patricio',
    },
    {
      name: 'lastname',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'text',
      'data-cy': 'lastname-input',
      label: 'Last name',
      variant: 'filled',
      defaultValue: defaultValues.lastname,
      helperText: errors.lastname && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.lastname?.message}
        </span>
      ),
      error: !!errors?.lastname,
      placeholder: 'Doe',
    },
    {
      name: 'birthday',
      col: 6,
      sm: 12,
      xs: 12,
      lg: 6,
      md: 12,
      type: 'date',
      label: 'Date of birth',
      'data-cy': 'date-input',
      defaultValue: defaultValues.birthday,
      variant: 'filled',
      helperText: errors.birthday && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.birthday?.message}
        </span>
      ),
      error: !!errors?.birthday,
    },
    {
      name: 'gender',
      col: 6,
      sm: 12,
      xs: 12,
      lg: 6,
      md: 12,
      type: 'select',
      defaultValue: defaultValues.gender,
      'data-cy': 'gender-input',
      options: ['male', 'female', 'other', 'prefer not to say'],
      label: 'Gender',
      variant: 'filled',
      helperText: errors.gender && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.gender?.message}
        </span>
      ),
      error: !!errors?.gender,
    },
  ];
  const address = [
    {
      type: 'custom',
      Custom: () => (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <AddressIcon />
          <Box ml={2} width="100%" className={classes.titleWrapper}>
            <span className={classes.sectionTitle}>Address</span>
          </Box>
        </Box>
      ),
      col: 12,
      lg: 12,
      md: 12,
      xs: 12,
    },
    {
      name: 'country',
      col: 12,
      sm: 12,
      xs: 12,
      lg: 12,
      md: 12,
      type: 'country',
      label: 'Country',
      'data-cy': 'country-input',
      defaultValue: { label: defaultValues.country },
      variant: 'filled',
      helperText: errors.country && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.country?.message}
        </span>
      ),
      error: !!errors?.country,
    },

    {
      name: 'address',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'text',
      label: 'Address 1',
      defaultValue: defaultValues.address,
      variant: 'filled',
      'data-cy': 'address-input',
      helperText: errors.address && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.address?.message}
        </span>
      ),
      error: !!errors?.address,
      placeholder: '123 Main Street',
    },
    {
      name: 'suite',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'text',
      defaultValue: defaultValues.suite,
      'data-cy': 'suite-input',
      label: 'Address 2 (optional)',
      variant: 'filled',
      helperText: errors.suite && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.suite?.message}
        </span>
      ),
      error: !!errors?.suite,
      placeholder: 'Apt 4B',
    },
    {
      name: 'city',
      col: 12,
      sm: 12,
      xs: 12,
      lg: 12,
      md: 12,
      type: 'text',
      label: 'City',
      'data-cy': 'city-input',
      defaultValue: defaultValues.city,
      variant: 'filled',
      helperText: errors.city && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.city?.message}
        </span>
      ),
      error: !!errors?.city,
      placeholder: 'New York',
    },

    {
      name: 'state',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'text',
      defaultValue: defaultValues.state,
      label: 'State',
      variant: 'filled',
      'data-cy': 'state-input',
      helperText: errors.state && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.state?.message}
        </span>
      ),
      error: !!errors?.state,
      placeholder: 'New York',
    },
    {
      name: 'zipcode',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'number',
      defaultValue: defaultValues.zipcode,
      label: 'Zip code',
      variant: 'filled',
      'data-cy': 'zipcode-input',
      helperText: errors.zipcode && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.zipcode?.message}
        </span>
      ),
      error: !!errors?.zipcode,
      placeholder: '10001',
    },
  ];
  const HeightType =
    heightType === heightTypeEnum.cm
      ? [
          {
            name: 'height',
            col: 2,
            lg: 6,
            md: 4,
            sm: 12,
            xs: 6,
            type: 'number',
            step: 1,
            style: inputTopSpacing,
            defaultValue: defaultValues.height,
            variant: 'filled',
            'data-cy': 'height-input',
            helperText: errors.height && (
              <span className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.height?.message}
              </span>
            ),
            placeholder: '150',
          },
        ]
      : [
          {
            name: 'ft',
            col: 1,
            lg: 3,
            md: 6,
            sm: 6,
            xs: 6,
            type: 'number',
            style: inputTopSpacing,
            className: classes.hideOverflow,
            defaultValue: defaultValues.ft,
            variant: 'filled',
            'data-cy': 'height-input',
            InputProps: {
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ backgroundColor: 'red' }}
                >
                  <FtIcon style={{ marginRight: '-14.5px' }} />
                </InputAdornment>
              ),
            },
            helperText: errors.ft && (
              <span className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.ft?.message}
              </span>
            ),
            placeholder: '150',
          },
          {
            name: 'inch',
            col: 1,
            lg: 3,
            md: 6,
            sm: 6,
            xs: 6,
            type: 'number',
            style: inputTopSpacing,
            className: classes.hideOverflow,
            defaultValue: defaultValues.inch,
            variant: 'filled',
            'data-cy': 'height-input',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" style={{ marginTop: '-1px' }}>
                  <InchIcon style={{ marginRight: '-14.5px' }} />
                </InputAdornment>
              ),
            },
            helperText: errors.inch && (
              <span className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.inch?.message}
              </span>
            ),
            error: !!errors?.inch,
            placeholder: '150',
          },
        ];
  const basicInfo = [
    {
      type: 'custom',
      Custom: () => (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <BasicInfoIcon />
          <Box ml={2} width="100%" className={classes.titleWrapper}>
            <span className={classes.sectionTitle}>Physical information</span>
          </Box>
        </Box>
      ),
      tileStyle: { height: '80px' },
      col: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'heightType',
      col: 3,
      lg: 6,
      md: heightType === heightTypeEnum.inch ? 12 : 8,

      sm: 12,
      xs: 6,
      type: 'select',
      options: ['inch', 'cm'],
      value: heightType,
      defaultValue: defaultValues.heightType,
      onChange: (e) => {
        setHeightType(e.target.value);
      },
      'data-cy': 'heightype-input',
      label: 'Height',
      variant: 'filled',
      helperText: errors.height && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.height?.message}
        </span>
      ),
      error: !!errors?.height,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <HeightIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
    },
    ...HeightType,
    {
      name: 'weightType',
      col: 3,
      md: 8,
      lg: 6,
      xs: 6,
      sm: 12,
      type: 'select',
      options: ['kg', 'lb'],
      label: 'Weight',
      variant: 'filled',
      'data-cy': 'weightype-input',
      defaultValue: defaultValues.weightType,
      helperText: errors.weight && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.weight?.message}
        </span>
      ),
      error: !!errors?.weight,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <WeightIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
    },
    {
      name: 'weight',
      col: 2,
      md: 4,
      lg: 6,
      xs: 6,
      sm: 12,
      type: 'number',
      style: inputTopSpacing,
      // className: classes.inputHeight,
      variant: 'filled',
      'data-cy': 'weight-input',
      defaultValue: defaultValues.weight,
      helperText: errors.weight && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.weight?.message}
        </span>
      ),
      error: !!errors?.weight,
      placeholder: '80',
      // InputProps: {
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <WeightIcon className={classes.fieldIcon} />
      //     </InputAdornment>
      //   ),
      // },
    },
    {
      name: 'bloodType',
      col: 3,
      md: 8,
      lg: 6,
      xs: 12,
      sm: 12,
      type: 'select',
      label: 'Blood Group',
      'data-cy': 'bloodtype-input',
      defaultValue: defaultValues.bloodType,
      options: ['A', 'AB', 'B', 'O', 'Other'],
      variant: 'filled',
      helperText: errors.bloodType && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.bloodType?.message}
        </span>
      ),
      error: !!errors?.bloodType,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <BloodIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
    },
  ];
  const description = [
    {
      type: 'custom',
      Custom: () => (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <DescriptionIcon />
          <Box ml={2} width="100%" className={classes.titleWrapper}>
            <span className={classes.sectionTitle}>Contact information</span>
          </Box>
        </Box>
      ),
      tileStyle: { height: '80px' },
      col: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      name: 'phone',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'phone',
      label: 'Phone',
      'data-cy': 'phone-input',
      variant: 'filled',
      stylePhone: styles,
      defaultValue: defaultValues.phone,
      helperText: errors.phone && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.phone?.message}
        </span>
      ),
      error: !!errors?.phone,
      placeholder: '(555) 123-4567',
    },
    {
      name: 'email',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 12,
      type: 'text',
      label: 'Email',
      'data-cy': 'email-input',
      defaultValue: defaultValues.email,
      variant: 'filled',
      helperText: errors.email && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.email?.message}
        </span>
      ),
      error: !!errors?.email,
      placeholder: 'name@company.com',
    },
    {
      name: 'description',
      type: 'draftjs',
      label: 'Description',
      'data-cy': 'description-input',
      col: 12,
      lg: 12,
      md: 12,
      xs: 12,
      rows: 2,
      sm: 12,
      inputClassName: classes.inputStyle,
      // label: 'Description',
      defaultValue: defaultValues.description,
      variant: 'filled',
      helperText: errors.description && (
        <span className={classes.error}>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.description?.message}
        </span>
      ),
      error: !!errors?.description,
      placeholder:
        'I enjoy outdoor activities and lead a healthy lifestyle. Looking for guidance on maintaining a balanced diet.',
    },
  ];
  return { generalInfo, address, description, basicInfo };
};

export const AlergiesAndReactionsForm = [
  {
    name: 'category',
    type: 'select',
    variant: 'filled',
    'data-cy': 'category-input',
    options: ['Drug alergies', 'Food Alergies', 'Other alergies'],
  },
  {
    name: 'allergies',
    type: 'select',
    variant: 'filled',
    'data-cy': 'allergie-input',
    options: ['paltins', 'pinicillns'],
  },
  {
    name: 'reactions',
    type: 'text',
    'data-cy': 'reaction-input',
    variant: 'filled',
  },
];
