import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';
import TimeRange from 'components/ui/Inputs/TimeRange';
import { ReactComponent as DeleteIcon } from 'assets/deleteIcon.svg';
import { ReactComponent as AddTime } from 'assets/AddTime.svg';
import ScrollBar from 'components/ui/ScrollBar';
import EditButtons from 'components/EditButtons';
import CalendarRange from 'pages/SchedulePage/CalendarDatePicker/CalendarRangeDate';
import { checkValidity } from '../utils';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from '../style';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface IOverrideProps {
  override: { startDate: Date; endDate: Date | number }[];
  setOverride: (override: any) => void;
  setIsChanged: (changed: boolean) => void;
  startDate: Date;
  endDate: Date;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date | null) => void;
  setDateOverride: (dateOverride: any) => void;
  handleClose: () => void;
}

const OverrideModal = ({
  setOverride,
  override,
  setIsChanged,
  handleClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setDateOverride,
}: IOverrideProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const errorState = checkValidity(override);
  const [indexing, setIndexing] = React.useState<number>();
  function handleEdit() {
    if (override?.length > 0 && !errorState) {
      setDateOverride((previousDate) => [...previousDate, override]);
      handleClose();
    }
  }
  function handleChangeDate(
    name: 'endDate' | 'startDate',
    value: string | number
  ): void {
    setOverride((oldDateRange) => {
      const newDateRange = [...oldDateRange];
      newDateRange[indexing][name] = value;
      return newDateRange;
    });
  }
  function handleRemove(_index) {
    setOverride((over) => {
      const copied = [...over];
      return copied.filter((el, index) => index !== _index);
    });
  }
  function handleAdd() {
    if (override?.length > 0) {
      setOverride((previous) => {
        const lastItem = previous[previous.length - 1];
        const startDate = new Date(
          new Date(
            new Date().setHours(new Date(lastItem.endDate).getHours() + 1)
          ).setMinutes(0, 0, 0)
        );
        const endDate = new Date(
          new Date(startDate).setHours(new Date(startDate).getHours() + 3)
        );
        const newOverrideArray = [...previous, { startDate, endDate }];
        return newOverrideArray;
      });
    } else {
      setOverride([
        {
          startDate: new Date(new Date(startDate).setMinutes(0, 0, 0)),
          endDate: new Date(new Date(endDate).setMinutes(0, 0, 0)),
        },
      ]);
    }
  }
  return (
    <>
      <ScrollBar maxHeight="70vh">
        <Box p="3rem">
          <Box mb={2}>
            <CalendarRange
              color="#1F61DC"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setIsChanged={setIsChanged}
              minDate={new Date()}
            />
          </Box>
          <Text
            i18nKey="whatHoursAreYouAvailable"
            style={{
              color: 'var(--2-sd-blue, #425364)',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontWeight: 600,
            }}
          >
            What hours are you available?
          </Text>
          <Box display="flex" alignItems={override?.length == 0 && 'center'}>
            <Box display="flex" flexDirection="column" mt={1}>
              {override?.length > 0 ? (
                override?.map((el, index) => (
                  <Box display="flex" alignItems="center" mb={1}>
                    <TimeRange
                      onChange={handleChangeDate}
                      onInputClick={() => {
                        setIndexing(index);
                      }}
                      showTimeSelect
                      dateFormat="HH:mm"
                      range={[
                        override[index]?.startDate,
                        override[index]?.endDate,
                      ]}
                      name=""
                    />
                    {override?.length !== 1 && (
                      <Box ml={2}>
                        <DeleteIcon
                          width="31px"
                          height="33px"
                          onClick={() => handleRemove(index)}
                          cursor="pointer"
                          data-cy="remove-override"
                        />
                      </Box>
                    )}
                  </Box>
                ))
              ) : (
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#425364',
                  }}
                >
                  {t('Unavailable')}
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              mt={override?.length > 0 && 2}
            >
              <AddTime
                onClick={() => handleAdd()}
                cursor="pointer"
                width="21px"
                height="23px"
                data-cy="add-overrideData"
              />
            </Box>
          </Box>
          {errorState && (
            <Box display="flex" alignItems="center" mt={2}>
              <ErrorIcon style={{ color: 'red', marginRight: '10px' }} />
              <Typography className={classes.error}>{errorState}</Typography>
            </Box>
          )}
        </Box>
      </ScrollBar>

      {<Divider />}
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="0.5px solid rgba(161, 176, 196, 1)"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <EditButtons
          editable
          isPatient={false}
          type="submit"
          loading={false}
          handleOpen={(e) => {
            e.preventDefault();
            handleClose();
          }}
          handleEdit={() => {
            handleEdit();
          }}
        />
      </Box>
    </>
  );
};

export default OverrideModal;
