import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import useGetAllThreadConversationByUser from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetAllThreadConversationByUser';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import MessagesDasbhoardElement from './MessageDashboardItem';
import useStyles from './styles';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as EmptyInbox } from 'assets/emptyState/inbox.svg';
import EmptyState from './EmptyState';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/dashboard/plus.svg';

const MessagesDasbhoard = ({
  loading,
  handleOpenCreateNewThreadModal,
}: {
  loading: boolean;
  handleOpenCreateNewThreadModal?: () => void;
}) => {
  const classes = useStyles();
  const { data } = useGetAllThreadConversationByUser(false, null, 3, 0);
  const { t } = useTranslation();
  return (
    <Box
      height="253px"
      borderRadius="12px"
      position="relative"
      className={classes.LastMessageBoxDoctor}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton loading={loading} type="text" width="120px" height="25px">
          <Text
            i18nKey="recentMessages"
            className={classes.consultationMessage}
          >
            Recent messages
          </Text>
        </Skeleton>
        <Skeleton loading={loading} type="text" width="100px" height="21px">
          {data?.getAllThreadConvertationByUser &&
            data?.getAllThreadConvertationByUser.length !== 0 && (
              <Box
                display="flex"
                alignItems="center"
                gridGap={6}
                onClick={handleOpenCreateNewThreadModal}
              >
                <PlusIcon
                  style={{
                    cursor: 'pointer',
                  }}
                />
                <Text
                  i18nKey="newMessage"
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#147AF3',
                    cursor: 'pointer',
                  }}
                >
                  New message
                </Text>
              </Box>
            )}
        </Skeleton>
      </Box>
      <Box pt={3}>
        {loading ? (
          [1, 2]?.map((el, key) => (
            <Box
              display="flex"
              alignItems="center"
              py={2}
              borderBottom={key !== 3 && '1px solid #EAECF0'}
            >
              <Skeleton
                loading={loading}
                type="circle"
                width="40px"
                height="40px"
              >
                <></>
              </Skeleton>
              <Box ml={2}>
                <Skeleton
                  loading={loading}
                  type="text"
                  width="100px"
                  height="21px"
                >
                  <></>
                </Skeleton>
                <Skeleton
                  loading={loading}
                  type="text"
                  width="120px"
                  height="21px"
                >
                  <></>
                </Skeleton>
              </Box>
            </Box>
          ))
        ) : !data?.getAllThreadConvertationByUser ||
          data?.getAllThreadConvertationByUser.length === 0 ? (
          <EmptyState
            icon={<EmptyInbox />}
            title={t('Your inbox is empty')}
            text={t('to send your first message.')}
            onClick={() => handleOpenCreateNewThreadModal()}
            isClickLeft
            isText
          />
        ) : (
          data?.getAllThreadConvertationByUser?.map(
            (
              item: GetAllThreadConversationByUser_getAllThreadConvertationByUser,
              index: number
            ) => (
              <>
                <MessagesDasbhoardElement data={item} index={index} />
                {index != 2 && (
                  <Box ml={0} className={classes.dividerWidth}>
                    <Divider className={classes.divider} />
                  </Box>
                )}
              </>
            )
          )
        )}
      </Box>
    </Box>
  );
};

export default MessagesDasbhoard;
