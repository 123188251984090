import React, { ReactElement, useEffect, useState } from 'react';
import { Avatar, Box, Typography, Badge } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as FlagIcon } from 'assets/flag.svg';
import { ReactComponent as PatientsIcon } from 'assets/patients.svg';
import { ReactComponent as GiftIcon } from 'assets/gift.svg';
import { ReactComponent as PersonIcon } from 'assets/person.svg';
import { ReactComponent as PlusIcon } from 'assets/bluePlus.svg';
import { ReactComponent as LangIcon } from 'assets/languages.svg';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import { gql, useQuery } from '@apollo/client';
import { TypeEnum } from './DoctorProfile';
import { ReactComponent as UploadIcon } from 'assets/editImage.svg';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

export interface IDoctor {
  _id?: string;
  email?: string;
  gender?: string;
  country?: string;
  address?: string;
  suite?: string;
  city?: string;
  zipcode?: string;
  phone?: string;
  state?: string;
  birthday?: string;
  picture?: string;
  firstname?: string;
  lastname?: string;
  middlename?: string;
  about?: string;
  specialty?: string;
  experience?: string;
  patientGroup?: string[];
  languages?: string[];
  focusArea?: string[];
  certifications?: ICertifications[];
  pronouns?: string;
  showBirthday?: boolean;
  showPhone?: boolean;
  showAdress?: boolean;
  showEmail?: boolean;
  supportVideo?: boolean;
  displayName?: string;
}

interface ICertifications {
  certificationLocation: string;
  certificationName: string;
  educationLevel: string;
  yearOfDegree: Date;
}
interface IPersonalProps {
  doctor: IDoctor;
  setOpen: (value: TypeEnum) => void;
  isPatient: boolean;
  openUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}

const PersonalInfo = ({
  doctor,
  setOpen,
  isPatient,
  openUploadModal,
  loading,
}: IPersonalProps): ReactElement => {
  const classes = useStyles({ isPatient });
  const {
    gender,
    city,
    birthday,
    picture,
    firstname,
    lastname,
    middlename,
    experience,
    specialty,
    pronouns,
    languages,
  } = doctor || {};
  const birthDay = timeConverter(
    birthday || new Date().getMilliseconds().toString()
  );
  const { t } = useTranslation();
  const { data } = useQuery(DOCTOR_NUMBER_OF_APPOINTMENTS, {
    variables: { doctorId: doctor?._id },
  });
  const [numberOfAppointments, setNumberOfAppointments] = useState(null);
  useEffect(() => {
    if (data) {
      setNumberOfAppointments(data?.doctorNumberOfAppointments);
    }
  }, [data]);
  return (
    <Box className={classes.personalInfo}>
      <Box
        style={{
          padding: '2px 25px 25px 25px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          style={{
            width: 'calc(100% - 30px)',
          }}
        >
          <Skeleton
            loading={loading}
            type="circle"
            width="100px"
            height="100px"
          >
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                !isPatient && (
                  <UploadIcon
                    className={classes.badge}
                    onClick={() => openUploadModal(true)}
                  />
                )
              }
            >
              <Avatar alt="Remy Sharp" src={picture} className={classes.avatar}>
                {firstname && firstname[0]} {lastname && lastname[0]}
              </Avatar>
            </Badge>
          </Skeleton>
          <Box display="flex" flexDirection="column" ml="1.5rem">
            <Skeleton
              loading={!!!firstname}
              type="text"
              width="10rem"
              height="1.5rem"
            >
              <Typography className={classes.name}>
                {`${firstname} ${middlename} ${lastname}`}
              </Typography>
            </Skeleton>
            <Skeleton
              loading={!!!firstname}
              type="text"
              width="7rem"
              height="1.5rem"
            >
              {specialty ? (
                <Typography className={classes.speciality}>
                  {specialty}
                </Typography>
              ) : (
                !isPatient && (
                  <Box
                    className={classes.specialityEmpty}
                    onClick={() => setOpen(TypeEnum.EDIT)}
                  >
                    <PlusIcon />
                    <Text i18nKey="addASpecialty" style={{ marginTop: '2px' }}>
                      Add a specialty
                    </Text>
                  </Box>
                )
              )}
            </Skeleton>
            <Skeleton
              loading={!!!firstname}
              type="text"
              width="7rem"
              height="1.5rem"
            >
              <Typography className={classes.city}>{city && city}</Typography>
            </Skeleton>
          </Box>
        </Box>

        <Box mt="23px" style={{ display: 'flex', gap: '1.5rem' }}>
          <Skeleton loading={loading} type="rect" width="164px" height="54px">
            <Box className={classes.experience}>
              <FlagIcon />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography className={classes.experienceTitle}>
                  {experience ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: '3px' }}
                    >
                      {' '}
                      {experience}
                      <Text i18nKey="years" className={classes.experienceTitle}>
                        {' '}
                        years
                      </Text>
                    </Box>
                  ) : (
                    '—'
                  )}
                </Typography>

                <Text i18nKey="experience" className={classes.experienceText}>
                  Experience
                </Text>
              </Box>
            </Box>
          </Skeleton>
          <Box
            style={{
              height: '67px',
              borderRight: '1px solid #E0E0E0',
            }}
          />
          <Skeleton loading={loading} type="rect" width="164px" height="54px">
            <Box className={classes.experience}>
              <PatientsIcon />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography className={classes.experienceTitle}>
                  {numberOfAppointments && numberOfAppointments > 150
                    ? '150+'
                    : numberOfAppointments
                    ? numberOfAppointments
                    : '—'}
                </Typography>
                <Text i18nKey="appointments" className={classes.experienceText}>
                  Appointments
                </Text>
              </Box>
            </Box>
          </Skeleton>
        </Box>
        <Box>
          <Box className={classes.info}>
            <Box className={classes.infoItem}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <Box minWidth="22px">
                  <PersonIcon />
                </Box>
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="4rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {capitalizeFirstLetter(t(gender)) || '—'}
                  {pronouns && ` (${capitalizeFirstLetter(t(pronouns))})`}
                </Typography>
              </Skeleton>
            </Box>
            <Box className={classes.infoItem}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <LangIcon />
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="5rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {languages?.length > 0
                    ? languages
                        .map((lang) => capitalizeFirstLetter(t(lang)))
                        .join(', ')
                    : '—'}
                </Typography>
              </Skeleton>
            </Box>
            <Box className={classes.infoItem}>
              <Skeleton
                loading={loading}
                type="rect"
                width="1.2rem"
                height="1.2rem"
              >
                <GiftIcon />
              </Skeleton>
              <Skeleton
                loading={loading}
                type="text"
                width="5rem"
                height="1.5rem"
              >
                <Typography className={classes.infoText}>
                  {birthday ? birthDay : '—'}
                </Typography>
              </Skeleton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default PersonalInfo;

const DOCTOR_NUMBER_OF_APPOINTMENTS = gql`
  query doctorNumberOfAppointments($doctorId: ID!) {
    doctorNumberOfAppointments(doctorId: $doctorId)
  }
`;
