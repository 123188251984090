/* eslint-disable*/
import { useMutation, gql } from "@apollo/client";
import {
  deleteLabRequestVariables,
  deleteLabRequest,
} from "./__generated__/deleteLabRequest";

const DELETE_LAB_REQUEST = gql`
  mutation deleteLabRequest($labRequestId: ID!) {
    deleteLabRequest(labRequestId: $labRequestId) {
      _id
    }
  }
`;

export default function useDeleteLabRequest() {
  const [deleteLabRequest, result] = useMutation<
    deleteLabRequest,
    deleteLabRequestVariables
  >(DELETE_LAB_REQUEST, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          labRequestByAppointment(labs) {
            try {
              const newLabs = labs.filter((lab: any) => {
                return (
                  lab.__ref.split("LabRequest:")[1] !==
                  data?.deleteLabRequest?._id
                );
              });
              return newLabs;
            } catch (e) {
              return labs;
            }
          },
        },
      });
    },
  });

  return { deleteLabRequest, ...result };
}
