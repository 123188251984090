import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const CancelButton = withStyles((theme) =>
  createStyles({
    root: {
      color: 'white',
      backgroundColor: '#97A4BA',
      width: '4.625rem',
      height: '2.263125rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#425364',
        borderColor: '#425364',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#425364',
        borderColor: '#425364',
      },
    },
  })
)(Button);
export default CancelButton;
