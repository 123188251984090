import React, { ReactElement } from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import TrashIcon from 'assets/TrashIcon';
import { ReactComponent as ViewProfileIcon } from 'assets/person.svg';

interface IDropDownMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  goToPatientDetails: () => void;
  handleDeletePatient: () => void;
}

export default function DropdownMenu({
  anchorEl,
  handleClose,
  goToPatientDetails,
  handleDeletePatient,
}: IDropDownMenuProps): ReactElement {
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      disableScrollLock={false}
    >
      <MenuItem data-cy="view-profile" onClick={goToPatientDetails}>
        <ViewProfileIcon style={{ marginRight: '10px' }} />
        Profile
      </MenuItem>
      <MenuItem
        data-cy="delete-item"
        onClick={() => {
          handleDeletePatient();
          handleClose();
        }}
      >
        <Box style={{ marginRight: '10px', width: '18px', height: '18px' }}>
          <TrashIcon />
        </Box>
        Remove client
      </MenuItem>
      {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
    </Menu>
  );
}
