import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Chip, Tooltip, useTheme } from '@material-ui/core';
import { getTagColor } from 'utils/objectUtils';
import { useStyles } from './styles';

const TagsCell = ({ tags }) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTagsCount, setVisibleTagsCount] = useState(0);
  const tagWidthsRef = useRef<number[]>([]);
  const theme = useTheme();

  const calculateVisibleTags = useCallback(() => {
    if (!containerRef?.current || tagWidthsRef?.current?.length === 0) return;

    const availableWidth = containerRef?.current?.offsetWidth;
    let accumulatedWidth = 0;
    let count = 0;

    for (let width of tagWidthsRef.current) {
      if (accumulatedWidth + width > availableWidth) break;
      accumulatedWidth += width;
      count += 1;
    }

    // Ensure at least 3 tags are displayed, unless there are fewer than 3 tags available
    setVisibleTagsCount(Math.max(3, count));
  }, []);

  // Measure tag widths on initial render
  useEffect(() => {
    const measureDiv = document.createElement('div');
    measureDiv.className = classes.hiddenTagMeasure;
    document.body.appendChild(measureDiv);
    if (tags?.length > 0) {
      tagWidthsRef.current = tags?.map((tag) => {
        const chip = document.createElement('div');
        chip.className = classes.chip;
        chip.style.backgroundColor = getTagColor(tag.color).backgroundColor;
        chip.style.border = getTagColor(tag.color).border;
        chip.style.color = getTagColor(tag.color).text;
        chip.textContent = tag.title;
        measureDiv.appendChild(chip);
        const width = chip.offsetWidth + theme.spacing(0.5); // Adding margin
        measureDiv.removeChild(chip);
        return width;
      });

      document.body.removeChild(measureDiv);
      calculateVisibleTags();
    }
  }, [tags, classes, theme, calculateVisibleTags]);

  // Recalculate on window resize
  useEffect(() => {
    const handleResize = () => {
      calculateVisibleTags();
    };

    window.addEventListener('resize', handleResize);
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateVisibleTags]);

  return (
    <div className={classes.tagsContainer} ref={containerRef}>
      {tags?.slice(0, visibleTagsCount)?.map((tag, index) => (
        <Chip
          key={index}
          label={tag.title}
          className={classes.chip}
          style={{
            backgroundColor: getTagColor(tag.color).backgroundColor,
            border: getTagColor(tag.color).border,
            color: getTagColor(tag.color).text,
          }}
          size="small"
        />
      ))}
      {visibleTagsCount < tags?.length && (
        <Tooltip
          classes={{ tooltip: classes.customTooltip }}
          title={
            <div className={classes.tooltipContent}>
              {tags?.slice(visibleTagsCount)?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag.title}
                  className={`${classes.tooltipChip} ${classes.tooltipChipSpacing}`}
                  style={{
                    backgroundColor: getTagColor(tag.color).backgroundColor,
                    border: getTagColor(tag.color).border,
                    color: getTagColor(tag.color).text,
                  }}
                  size="small"
                />
              ))}
            </div>
          }
          arrow
        >
          <Chip label="..." size="small" className={classes.seeMoreChip} />
        </Tooltip>
      )}
    </div>
  );
};

export default TagsCell;
