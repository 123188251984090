import React from 'react';
import AppointmentListPageProvider from 'pages/AppointmentListPage/state/Provider';
import DoctorFinance from './DoctorFinance';
import InboxPageProvider from 'pages/InboxPage/state/Provider';

const index = ({
  isAll = true,
  patientId,
}: {
  isAll?: boolean;
  patientId?: string;
}) => {
  return (
    <AppointmentListPageProvider>
      <InboxPageProvider>
        <DoctorFinance isAll={isAll} patientId={patientId} />
      </InboxPageProvider>
    </AppointmentListPageProvider>
  );
};

export default index;
