import React from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  InputBase,
  withStyles,
} from '@material-ui/core';
import { IEmoji } from 'pages/PatientActivities/commun/Form';
interface CustomSelectProps {
  values: IEmoji[];
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}
const FormInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    maxHeight: '40px',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: '8px',
      border: '1px solid #082CAF',
    },
  },
}))(InputBase);
function IconSelect({
  selectedValue,
  setSelectedValue,
  values,
}: CustomSelectProps) {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    setSelectedValue(value as string);
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <Select
        value={selectedValue}
        onChange={handleChange}
        input={<FormInput />}
        MenuProps={{
          disableScrollLock: false,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        inputProps={{
          name: 'age',
          id: 'outlined-age-native-simple',
        }}
      >
        {values.map((value) => (
          <MenuItem key={value.label} value={value.label}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              style={{
                gap: '1rem',
                marginLeft: '10px',
              }}
            >
              <Box
                maxWidth="25px"
                minWidth="25px"
                maxHeight="25px"
                minHeight="25px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {value.icon && value.icon}
              </Box>

              {value.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default IconSelect;
