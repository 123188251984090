import { gql, useMutation } from '@apollo/client';

const EDIT_NOTES = gql`
  mutation editNotes($notesId: ID!, $notesInput: NoteInput!) {
    editNotes(notesId: $notesId, notesInput: $notesInput) {
      _id
    }
  }
`;

const useEditNotes = () => {
  return useMutation(EDIT_NOTES);
};

export default useEditNotes;
