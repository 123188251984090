import { gql, useMutation } from '@apollo/client';

export const GENERATE_INVOICE_PDF = gql`
  mutation GenerateInvoicePdf($invoiceId: ID!) {
    generateInvoicePdf(invoiceId: $invoiceId) {
      invoicePdf
    }
  }
`;

export default function useGenerateInvoicePdf() {
  const [generateInvoicePdf, { data, error, loading }] =
    useMutation(GENERATE_INVOICE_PDF);

  return { generateInvoicePdf, data, error, loading };
}
