/* eslint-disable */
import { useMutation } from '@apollo/client';
import { ADD_EVENT } from '../../queries/appointment';

export default function useAddEvent() {
  const [createEvent, result] = useMutation(ADD_EVENT, {
    update: (cache, { data: { addEvent } }) => {
      cache.modify({
        fields: {
          eventsByDate(events) {
            return [...events?.result, addEvent];
          },
          conflictsAppointments(events) {
            return [...events, addEvent];
          },
          availableTimes(times) {
            const startDate = new Date(addEvent?.startDate);
            const endDate = new Date(addEvent?.endDate);

            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: addEvent?.doctor?.timeZone,
            });

            const doctorFormattedDateStart = doctorFormatter.format(startDate);
            const doctorFormattedDateEnd = doctorFormatter.format(endDate);

            if (addEvent?.isImpact) {
              const existsBeforeStart = times?.some(
                (el) => el < doctorFormattedDateStart
              );
              const existsAfterEnd = times?.some(
                (el) => el > doctorFormattedDateEnd
              );

              if (!existsBeforeStart) {
                return times?.filter((el) => el > doctorFormattedDateEnd);
              } else if (!existsAfterEnd) {
                return times?.filter((el) => el < doctorFormattedDateStart);
              } else {
                return times?.filter(
                  (el) =>
                    el < doctorFormattedDateStart || el > doctorFormattedDateEnd
                );
              }
            } else {
              return times;
            }
          },
        },
      });
    },
  });
  async function addEvent(data: any, sideEffectCallback: any) {
    try {
      const result = await createEvent({
        variables: {
          eventInput: data,
        },
      });
      return result;
    } catch (e) {
      return sideEffectCallback(e);
    }
  }
  return { addEvent, ...result };
}
