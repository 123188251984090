import React from 'react';
import {
  Avatar,
  Box,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { ReactComponent as MemberIcon } from 'assets/icons/memberIcon.svg';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';
import useStyles from 'pages/SchedulePage/AddEventPage/EventForm/styles';
import PreviewMember from './PreviewMember';
import Text from 'Text';

export interface IDoctorProps {
  id: string;
  name: string;
  type: string;
  firstname: string;
  lastname: string;
  picture?: string;
  email: string;
  __typename?: string;
  _id: string;
  middlename?: string;
}
interface IPatientSelectProps {
  register: (name: string) => void;
  control: Control;
  errors: DeepMap<Record<string, any>, FieldError>;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  options: IDoctorProps[];
}

export default function PatientSelect({
  errors,
  ...props
}: IPatientSelectProps) {
  const classes = useStyles();
  return (
    <Box className={classes.sideAppointmentPatient}>
      <FormLabel
        className={clsx(classes.bottomSpacingPatient, classes.labelPatient)}
      >
        <Text className={classes.labelPatient} i18nKey='provider'>Provider</Text>
      </FormLabel>
      <Box
        display="flex"
        alignItems="right"
        className={clsx(classes.topSpacingPatient)}
      >
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          className={classes.selectDoctor}
        >
          <Controller
            {...props}
            name="doctor"
            render={({ onChange, onBlur, value, ...controllerProps }) => (
              <>
                <AutoCompleteAddNew
                  errors={errors?.doctor}
                  noOptionsText="Add a new patient bellow"
                  data-cy="auto-complete-add"
                  renderOption={(option: { picture: string; name: string }) => (
                    <Box
                      data-cy="auto-complete-add-option"
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar className={classes.avatar} src={option.picture} />
                      <Box ml={2} className={classes.text}>
                        <Typography>{option.name}</Typography>
                      </Box>
                    </Box>
                  )}
                  getOptionSelected={(
                    option: { _id: string },
                    value: { _id: string }
                  ) => {
                    return option?._id === value?._id;
                  }}
                  defaultValue={value}
                  onBlur={onBlur}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  getOptionLabel={(option: any) => value?.name || option.name}
                  {...controllerProps}
                  {...props}
                />
              </>
            )}
          />
          <Box>
            {errors?.doctor && (
              <FormHelperText className={classes.error}>
                {' '}
                <ErrorIcon className={classes.errorIcon} />
                {errors.doctor?.message}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
