import { gql, useMutation } from '@apollo/client';

export const DELETE_PATIENT_REQUEST = gql`
  mutation declinePatientRequest($id: ID!) {
    declinePatientRequest(id: $id) {
      _id
    }
  }
`;

const useDeletePatientRequest = () => {
  const [deletePatientRequest] = useMutation(DELETE_PATIENT_REQUEST);
  async function handleDeletePatientRequest(id: string) {
    try {
      return await deletePatientRequest({
        variables: { id },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleDeletePatientRequest };
};

export default useDeletePatientRequest;
