import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#FFFFFF',
    color: '#475467',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '10px',
    display: 'flex',
  },
  type: {
    backgroundColor: '#F9FAFB',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #EAECF0',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  actions: {
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionsVisible: {
    opacity: 1,
  },
  seeMoreChip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    color: '#475467',
    marginRight: '0.5rem',
    borderRadius: '6px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    minWidth: '300px',
  },
  hiddenTagMeasure: {
    position: 'absolute',
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    height: 0,
    overflow: 'hidden',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    borderRadius: 6,
    whiteSpace: 'nowrap',
  },
  tooltipChip: {
    borderRadius: 6,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipChipSpacing: {
    margin: theme.spacing(0.25),
  },
  dropdown: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    zIndex: 1000,
    borderRadius: theme.shape.borderRadius,
    padding: '10px 8px',
    boxSizing: 'border-box',
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  templateItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  tagItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  tag: {
    display: 'inline-block',
    padding: '2px 6px',
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    fontSize: '12px',
  },
  clearButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  titleBox: {
    backgroundColor: '#EAECF0',
    width: '100%',
    padding: '4px',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    maxHeight: '26px',
  },
  countBox: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    color: '#182230',
    fontSize: '10px',
    fontFamily: 'Inter',
    fontWeight: 700,
  },
  templateTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#475467',
  },
  templateType: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#98A2B3',
  },
  tagCountBox: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    color: '#182230',
    fontSize: '10px',
    fontFamily: 'Inter',
    fontWeight: 700,
  },
}));
