import React, { ReactElement, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Toggle } from 'assets/toggle.svg';
import { ReactComponent as ToggleOff } from 'assets/toggleOff.svg';
import { ReactComponent as OneWaySync } from 'assets/oneWaySync.svg';
import { ReactComponent as Alert } from 'assets/alert.svg';
import { ReactComponent as TwoWaySync } from 'assets/twoWaySync.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import { ReactComponent as GoogleCalendar } from 'assets/googleCalender.svg';
import { ReactComponent as Disconnect } from 'assets/disconnect.svg';
import { ReactComponent as Connect } from 'assets/connect.svg';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as Copy } from 'assets/copyLogo.svg';

import useStyles from './style';
import Text from 'Text';
import Button from 'components/ui/Button';
import InputBlock from './InputBlock';
import { useSnackbar } from 'hooks/useSnackbar';
import DisconnectModal from './DisconnectModal';
import BasicModal from 'components/ui/Modal';
import HIPAAModal from './HIPAAModal';
import useClipBoard from 'hooks/useClipBoard';

const CalendarSync = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleCopyToClipboard } = useClipBoard();

  const [isCalendarSyncToggled, setIsCalendarSyncToggled] =
    useState<boolean>(true);
  const [isCalendarSync, setIsCalendarSync] = useState<boolean>(true);
  const { triggerSnack } = useSnackbar();
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [openHIPAACompliance, setOpenHIPAACompliance] = useState(false);
  const theme = useTheme();

  const isLarge = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const nameOptions = [
    'client initials',
    'first initial, last name',
    'first name, last initial',
  ];
  const handleToggle = () => {
    setIsCalendarSyncToggled(!isCalendarSyncToggled);
  };
  const schema = yup.object().shape({
    name: yup.string(),
    url: yup.string().url('Must be a valid URL'),
  });

  const { register, control, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: 'eggmed session/event',
      url: 'https://app.eggmed.com/ical/fa0qp2DXSsZGi8fVhxoUi1gvrc52-GC7mVGMUlJI',
    },
  });
  const commonProps = { register, control, errors };
  return (
    <>
      <Box className={classes.containerCal}>
        <Box style={{ gap: '8px' }}>
          <Text i18nKey="Calendar sync" className={classes.h3}>
            Calendar sync
          </Text>
          <Typography className={classes.subtitle}>
            {t('one-way or two-way sync')}
          </Typography>
        </Box>
        <Box>
          <Box
            className={classes.wrapper}
            // style={{
            //   // paddingBottom: isCalendarSyncToggled && '2rem',
            // }}
          >
            <Box
              className={classes.calSync}
              style={{
                borderBottom: isCalendarSyncToggled && '1px solid #D0D5DD',
                paddingBottom: isCalendarSyncToggled && '2rem',
              }}
            >
              <Text i18nKey="Calendar sync" className={classes.h2}>
                Calendar sync
              </Text>
              {isCalendarSyncToggled ? (
                <Toggle onClick={handleToggle} />
              ) : (
                <ToggleOff onClick={handleToggle} />
              )}
            </Box>
            {isCalendarSyncToggled && (
              <Box>
                <Box
                  style={{
                    padding: '2rem 0 2rem 0',
                    borderBottom: '1px solid #D0D5DD',
                  }}
                >
                  <Controller
                    control={control}
                    name="name"
                    defaultValue="eggmed session/event"
                    render={({ onChange, value, name }) => (
                      <InputBlock
                        style={{
                          width: '30rem',
                          gap: '6px',
                        }}
                        label={t('Name displayed')}
                        value={value}
                        name={name}
                        onChange={(e) => {
                          const optionValue = e.target.value;
                          onChange(optionValue);
                          if (nameOptions.includes(optionValue)) {
                            setOpenHIPAACompliance(true);
                          }
                        }}
                        {...commonProps}
                        type="select"
                        options={['eggmed session/event', ...nameOptions]}
                      />
                    )}
                  />
                </Box>
                <Box
                  style={{
                    padding: '2rem 0 2rem 0',
                    borderBottom: '1px solid #D0D5DD',
                    gap: '20px',
                  }}
                >
                  <Box
                    style={{
                      gap: '4px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <OneWaySync />
                      <Text i18nKey="One-way sync" className={classes.h3}>
                        One-way sync
                      </Text>
                    </Box>
                    <Typography className={classes.subtitle}>
                      {t('One-way-text')}
                    </Typography>
                  </Box>
                  <Box className={classes.calendarUrl}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <Calendar style={{ height: '24px', width: '24px' }} />
                      <Typography className={classes.calendarText}>
                        {t('Calendar')}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Controller
                        control={control}
                        name="url"
                        defaultValue="https://app.eggmed.com/ical/fa0qp2DXSsZGi8fVhxoUi1gvrc52-GC7mVGMUlJI"
                        render={({ onChange, value, name }) => (
                          <InputBlock
                            type="text"
                            value={value}
                            name={name}
                            onChange={onChange}
                            {...commonProps}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    className={classes.copyBtn}
                                    onClick={() => {
                                      handleCopyToClipboard(value);
                                      triggerSnack(
                                        t('Link copied successfully')
                                      );
                                    }}
                                  >
                                    <Copy
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                    <Typography className={classes.copyText}>
                                      {t('copy URL')}
                                    </Typography>
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              style: {
                                padding: '10px 14px 10px 14px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                textOverflow: 'ellipsis',
                                width: isLarge
                                  ? '33vw'
                                  : isMedium
                                  ? '20vw'
                                  : isSmall
                                  ? '18vw'
                                  : '55vw',
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" className={classes.alert}>
                    <Alert />
                    <Typography className={classes.alertText}>
                      {t(
                        'Anyone with this URL can access the calendar information.'
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2rem 0 0 0',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <TwoWaySync />
                    <Text i18nKey="Two-way sync" className={classes.h3}>
                      Two-way sync
                    </Text>
                  </Box>
                  <Typography className={classes.subtitle}>
                    {t('two-way-sync-text')}
                  </Typography>
                  <Box className={classes.calenderChoice}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <GoogleCalendar />
                      <Typography className={classes.calendarText}>
                        {t('Google Calendar')}
                      </Typography>
                      {isCalendarSync && (
                        <Chip className={classes.chip} label={t('Synced')} />
                      )}
                    </Box>
                    {isCalendarSync ? (
                      <Button
                        className={classes.disconnectBtn}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenDisconnectModal(true);
                        }}
                      >
                        <Disconnect style={{ width: '20px', height: '20px' }} />
                        <Typography style={{ marginLeft: '0.5rem' }}>
                          {t('Disconnect')}
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        className={classes.connectBtn}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCalendarSync(true);
                          triggerSnack('Calendar synced successfully');
                        }}
                      >
                        <Connect style={{ width: '20px', height: '20px' }} />
                        <Typography style={{ marginLeft: '0.5rem' }}>
                          {t('Connect')}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box className={classes.infoBox}>
                    <Box
                      style={{
                        gap: '10px',
                      }}
                    >
                      <Box className={classes.infoboxTitle}>
                        <Info />
                        <Typography className={classes.infoTitle}>
                          {t('Important notice')}
                        </Typography>
                      </Box>
                      <Typography>{t('notice-text')}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <BasicModal
        isRegularModal
        open={openDisconnectModal}
        onClose={() => setOpenDisconnectModal(false)}
        handleClose={() => setOpenDisconnectModal(false)}
      >
        <DisconnectModal
          handleClose={() => setOpenDisconnectModal(false)}
          handleDisconnect={() => {
            setIsCalendarSync(false);
            setOpenDisconnectModal(false);
          }}
        />
      </BasicModal>
      <BasicModal
        isRegularModal
        open={openHIPAACompliance}
        onClose={() => setOpenHIPAACompliance(false)}
        handleClose={() => setOpenHIPAACompliance(false)}
      >
        <HIPAAModal handleClose={() => setOpenHIPAACompliance(false)} />
      </BasicModal>
    </>
  );
};

export default CalendarSync;
