import React, { useEffect } from 'react';
import Steps from 'pages/PatientActivities/commun/Steps';
import FirstStep from './FirstStepAct';
import ActivityModal from 'pages/PatientActivities/commun/ActivityModal';
import { activityType } from './FirstStepAct';
import { TypeEnum } from 'pages/PatientActivities/commun/Activity';
import { IPatientActivities } from 'pages/PatientActivities';
import { useProvider as useFormProvider } from 'pages/PatientActivities/Questionaires/ActivityProvider';
import FormModal from 'pages/PatientActivities/Questionaires/FormModal';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';

interface Props {
  doctorAndPatientId: { doctor: string; patient: string };
  defaultValues: IPatientActivities;
  patientName: string;
  handleCloseAssign: () => void;
  patient: IPatientData;
  setSelectedTab: (value: number) => void;
}
const AssignModal = ({
  doctorAndPatientId,
  defaultValues,
  patientName,
  handleCloseAssign,
  patient,
  setSelectedTab,
}: Props) => {
  const { data, openAssignModal, handleDeleteModal } = useFormProvider();
  const [step, setStep] = React.useState(0);
  const [selected, setSelected] = React.useState(null);
  const handleClose = () => {
    step > 0 ? setStep((st) => st - 1) : handleCloseAssign();
  };
  const handleSubmit = () => {
    if (step === 0 && selected === null) return;
    setStep((st) => st + 1);
    setSelectedTab(selected);
  };
  return (
    <>
      <Steps
        step={step}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        steps={[
          <FirstStep selected={selected} setSelected={setSelected} />,
          <>
            {selected === 0 ? (
              <FormModal
                handleClose={handleClose}
                title={`Assign form to ${patient?.firstname}`}
                patientId={patient?._id}
                patient={patient}
                assignText={`Assign this form to ${patient?.firstname} ${patient?.lastname}?`}
                doctorTemplates={data}
                openAssignModal={openAssignModal}
                openDeleteModal={handleDeleteModal}
              />
            ) : (
              <ActivityModal
                name={patientName}
                handleClose={handleClose}
                doctorAndPatientId={doctorAndPatientId}
                defaultValues={defaultValues}
                type={activityType[selected]?.value as TypeEnum}
                handleCloseAssign={handleCloseAssign}
              />
            )}
          </>,
        ]}
        buttonVisible={[0]}
        submitText={'Continue'}
        cancelText={'Cancel'}
      />
    </>
  );
};

export default AssignModal;
