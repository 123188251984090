import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/ui/Button';
import ReportList from 'components/Report/ReportList';
import ListItemWithActions from 'components/ui/ListItemWithActions';
import DeleteHandler from 'components/DeleteHandler';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as FolderIcon } from 'assets/icons/foldericon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/bluedeleteIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadButton.svg';
import { ReactComponent as FileDocument } from 'assets/FileDocument.svg';
import { ReactComponent as Upload } from 'assets/Upload.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
    position: 'relative',
    left: theme.spacing(6.5),
  },
  textDoc: {
    fontSize: '16px',
    color: '#425364',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    left: theme.spacing(6.5),
    top: '5px',
    [theme.breakpoints.only('lg')]: {
      position: 'relative',
      left: theme.spacing(0.5),
    },
    [theme.breakpoints.only('md')]: {
      position: 'relative',
      left: theme.spacing(0.5),
    },
    [theme.breakpoints.only('xs')]: {
      position: 'relative',
      left: theme.spacing(3.5),
    },
  },
  folderList: {
    width: '100%',
  },
  btn: {
    fontSize: '14px',
    color: '#FFFFFF',
    weight: '400',
    lineHeight: '16px',
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 18px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  icon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    '&:hover': {
      fill: 'red',
    },
  },
  iconUpload: {
    paddingRight: '13px',
    marginLeft: theme.spacing(3),
  },
  titleWrapper: {
    // backgroundColor: '#F8FAFB',
    padding: '10px',
    // border: '1px solid #D7E3F1',
    borderRadius: '12px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#425364',
    lineHeight: '37px',
    marginTop: '0.5em',
  },
  preview: {
    display: 'none',
  },
  upload: {
    border: '1px dashed #9CB2CD',
    borderRadius: theme.typography.pxToRem(6),
    height: theme.typography.pxToRem(159),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    marginTop: theme.typography.pxToRem(22),
    marginBottom: theme.typography.pxToRem(22),
    backgroundColor: 'rgba(107, 119, 154, 0.05)',
    flexDirection: 'column',
  },
  drag: {
    fontSize: theme.typography.pxToRem(24),
    color: '#425364',
    lineHeight: '17px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function PatientReports({
  reports,
  loading,
  setCurrentReport,
  setOpen,
  handleAddReport,
  addReportLoading,
  handleDeleteReport,
  files,
  notShow = true,
}: any) {
  const classes = useStyles();
  const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.target = '_blank';
    link.click();
  };
  return (
    <Box width="100%" pt={2}>
      {notShow && (
        <Box px={2}>
          <DropzoneAreaBase
            fileObjects={files}
            onAdd={handleAddReport}
            classes={{ text: classes.drag }}
            previewGridClasses={{
              item: classes.preview,
            }}
            dropzoneClass={classes.upload}
            onDelete={handleDeleteReport}
            showAlerts={false}
          />
        </Box>
      )}
      {addReportLoading ? (
        <Box display="flex" flexDirection="column" alignContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <ReportList>
          {reports &&
            reports.map((element: any) => {
              return (
                <Box key={element.name}>
                  <Skeleton loading={loading}>
                    <ListItemWithActions
                      handleAction={() => {
                        setCurrentReport(element);
                        setOpen(true);
                      }}
                      primaryData={element.name}
                      secondaryData={element.details}
                      AvatarIcon={<FolderIcon />}
                      rightActions={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyItems="space-between"
                        >
                          <DownloadIcon
                            className={classes.icon}
                            onClick={() =>
                              onDownload(element.attachment, element.name)
                            }
                          />
                          <DeleteHandler
                            handleDelete={() => {}}
                            DeleteIcon={<DeleteIcon className={classes.icon} />}
                            handleAction={handleDeleteReport}
                            value={element}
                            iconButton={false}
                            text="document"
                          />
                        </Box>
                      }
                    />
                  </Skeleton>
                </Box>
              );
            })}
        </ReportList>
      )}
    </Box>
  );
}
