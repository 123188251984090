import React, { useState } from 'react';
import Table from 'components/ui/Table';
import { Box } from '@material-ui/core';
import Menu from 'components/ui/Menu';
import MenuElements from './MenuElements';
import Columns, { TemplateType } from './Columns';
import BasicModal from 'components/ui/Modal';
import DuplicateModal from './DuplicateModal';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import useDeleteDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteDoctorTemplate';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import { useSnackbar } from 'hooks/useSnackbar';

interface Tag {
  title: string;
  color: string;
}

export interface Itemplate {
  id: string;
  name: string;
  type: string;
  isDefault: boolean;
  isAutoSent: boolean;
  isScored: boolean;
  tags: Tag[];
  lastUsed: string;
}

function AllTemplates({
  type,
  data,
  loading = false,
  handleOpenModal,
}: {
  type: TemplateType;
  data: Itemplate[];
  loading?: boolean;
  handleOpenModal?: () => void;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<string | null>(null);
  const [currentRow, setCurrentRow] = useState<any | null>(null);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  function handlePagination(e: unknown, pageNumber: number) {
    setPage(pageNumber);
  }
  const { triggerSnack } = useSnackbar();

  const [handleDeleteDoctorTemplate, { loading: loadingDelete }] =
    useDeleteDoctorTemplate();

  const handleDefaultTemplate = () => {};
  const handleViewTemplate = () => {};
  const handleEditTemplate = () => {
    handleEditFields(currentRow);
    setAnchor(null);
  };
  const handleManageTags = () => {};
  const handleDuplicateTemplate = () => {
    setOpenDuplicateModal(true);
  };
  const handleDeleteTemplate = async () => {
    await handleDeleteDoctorTemplate({
      variables: { id: currentRow._id },
      refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
    });
    setAnchor(null);
    triggerSnack('Template successfully deleted');
  };
  const handleOpenDropDown = (e: React.MouseEvent<HTMLElement>, row: any) => {
    e.stopPropagation();
    setCurrentRow(row);
    setAnchor(e.currentTarget);
  };
  const { handleChangeText, handleSelectedTemplate, setBlocks } =
    useEditorContext();
  const handleEditFields = (row: any) => {
    setBlocks(row?.blocks, true, row?._id);
    handleChangeText(row?.title);
    handleSelectedTemplate(row?.formType);
    handleOpenModal();
  };
  const handleViewFields = (row: any) => {};

  const menuProps = {
    handleDefaultTemplate,
    handleViewTemplate,
    handleEditTemplate,
    handleManageTags,
    handleDuplicateTemplate,
    handleDeleteTemplate,
    isDefault: currentRow?.isDefault,
    isAutoSent: currentRow?.isAutoSent,
    TemplateType: currentRow?.type,
  };
  const columnProps = {
    hoveredRowIndex,
    handleOpenDropDown,
    handleEditFields,
    handleViewFields,
    type,
  };

  const columns = Columns(columnProps);

  const menuElements = MenuElements(menuProps);

  return (
    <Box mt="2rem">
      <Table
        loading={loading}
        page={page}
        columns={columns as any}
        limit={limit}
        handlePagination={handlePagination}
        data={data || []}
        pagination
        sorting
        onRowClick={(row) => console.log(row)}
        EmptyState={<div>empty</div>}
        onMouseEnterRow={(row) => setHoveredRowIndex(row?.id)}
        onMouseLeaveRow={() => setHoveredRowIndex(null)}
      />
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        handleMenuClose={() => setAnchor(null)}
        MenuElements={menuElements}
      />
      <BasicModal
        open={openDuplicateModal}
        onClose={() => setOpenDuplicateModal(false)}
        isRegularModal
        isNew
      >
        <DuplicateModal
          title={currentRow?.name}
          handleClose={() => setOpenDuplicateModal(false)}
        />
      </BasicModal>
    </Box>
  );
}

export default AllTemplates;
