export function capitalizeFirstLetter(str: string) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function convertObjFormat(user, obj) {
  return {
    ...obj,
    firstname: capitalizeFirstLetter(obj.firstname),
    lastname: capitalizeFirstLetter(obj.lastname),
    name: `${capitalizeFirstLetter(obj?.firstname)}  ${capitalizeFirstLetter(
      obj?.lastname
    )}`,
    doctorId: obj._id,
    id: user._id,
    _id: user._id,
  };
}
