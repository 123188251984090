import { gql } from '@apollo/client';

export const GET_APPOINTMENTS = gql`
  query appointments($limit: String, $page: String) {
    appointments(page: $page, limit: $limit) {
      result {
        _id
        description
        title
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
      }
      count
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query appointment($appointmentId: ID!) {
    appointment(appointmentId: $appointmentId) {
      _id
      description
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
      }
      title
      conditionType {
        tag
        color
      }
      locationLink
      location
      endDate
      startDate
      members
      sessionType {
        session
        duration
        rate
        currency
      }
      isPrepay
      numberOfRepeat
      repeatEvery
      endOn
      repeatOn
    }
  }
`;

export const ADD_APPOINTMENT = gql`
  mutation addAppointment($appointmentInput: addAppointmentInput!) {
    addAppointment(appointmentInput: $appointmentInput) {
      description
      doctor {
        _id
        firstname
      }
      patient {
        _id
        firstname
      }
      title
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
    }
  }
`;
export const DELETE_APPOINTMENT = gql`
  mutation deleteAppointment($appointmentId: ID!, $isOne: Boolean) {
    deleteAppointment(appointmentId: $appointmentId, isOne: $isOne) {
      _id
    }
  }
`;

export const EDIT_APPOINTMENT = gql`
  mutation editAppointment(
    $appointmentInput: editAppointmentInput!
    $appointmentId: ID!
  ) {
    editAppointment(
      appointmentInput: $appointmentInput
      appointmentId: $appointmentId
    ) {
      _id
      description
      title
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
    }
  }
`;
