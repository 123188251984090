import React, { useEffect, useState } from 'react';
import { Grid, Box, makeStyles, Avatar, Typography } from '@material-ui/core';
import PatientTabsBar from './PatientTabsBar';
import PatientTabsContent from './PatientTabsContent';
import PatientCard from './PatientCard';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { useProvider as useAuth } from 'contexts/AuthContext';
import { Feed } from 'components/PatientProfile/PatientNewTabs/Feed';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import { convertMsToDateMonth } from 'utils';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { ReactComponent as BirthIcon } from 'assets/CloclProfile.svg';
import { ReactComponent as GenderIcon } from 'assets/GenderIcon.svg';
import { ReactComponent as PronounsIcon } from 'assets/PronounsIcon.svg';
import { calculateAge } from 'utils/dateUtils';
import { ReactComponent as PhonePatient } from 'assets/PhonePatient.svg';
import { ReactComponent as AdressPatient } from 'assets/AdressPatient.svg';
import { ReactComponent as MailPatient } from 'assets/MailPatient.svg';
import { ReactComponent as EditPatientLogo } from 'assets/EditPatientLogo.svg';
import { ReactComponent as UploadIconPatient } from 'assets/UploadIconPatient.svg';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { EDIT_PATIENT_OWNER } from './GeneralInfoPatient';
import { GET_PATIENT_AUTH } from 'contexts/AuthContext';
import { ReactComponent as CalendarUpcoming } from 'assets/CalendarUpcoming.svg';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import { GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS } from 'pages/DashboardPage';
import ApptCard from 'pages/PatientAppointmentPage/ApptCardPatient';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptySession } from 'assets/emptyState/session.svg';
import { changeRes, transformArrayTimeZone } from 'utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 0,
  },
  boxwhite: {
    backgroundColor: 'white',
    borderRadius: '16px',
  },
  picture: {
    width: 100,
    height: 100,
    fontSize: '2rem',
  },
  title: {
    fontWeight: 700,
    fontSize: '22px',
    color: '#101828',
    fontFamily: 'Roboto',
  },
  joinedDate: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: '25px',
    fontFamily: 'Inter',
    color: '#101828',
  },
  bluetext: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#2F80ED',
  },
}));

interface IFileProp {
  data: string;
  file: File;
}

const PatientProfile = () => {
  const history = useHistory();
  const { handleOpenAddModal } = useProvider();
  const { data: patientUpcomingAppointment, loading } = useQuery(
    GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS,
    {
      variables: {
        filter: {
          limit: 1,
          skip: 0,
        },
      },
    }
  );
  const { id: patientId } = useParams<{ id: string }>();
  const { patient } = useAuth();
  const [value, setValue] = useState(0);
  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };
  const {
    register,
    getValues,
    control,
    reset,
    handleSubmit,
    setValue: setValues,
  } = useForm({
    mode: 'onChange',
  });
  const [open, setOpen] = useState(false);
  const [fileObjects, setFileObjects] = React.useState<IFileProp[]>([]);
  function onDeletePicture(deleteFileObj: IFileProp) {
    const filltredArray = fileObjects.filter(
      (value: IFileProp, index: number) => value !== deleteFileObj
    );
    setFileObjects(filltredArray);
  }
  function onAddPicture(newFileObjs: IFileProp[]) {
    if (fileObjects.length > 0) return null;
    setFileObjects([].concat(fileObjects, newFileObjs));
    return null;
  }
  function editPictureValue(file: File) {
    setValues('picture', file);
  }
  function onSave() {
    setOpen(false);
    editPictureValue(fileObjects[0]?.file);
    handleEdit();
    setFileObjects([]);
  }
  const [editPatient] = useMutation(EDIT_PATIENT_OWNER, {
    refetchQueries: [{ query: GET_PATIENT_AUTH }],
    awaitRefetchQueries: false,
  });
  function handleEdit() {
    const data = getValues();
    editPatient({
      variables: {
        patientId: patientId,
        EditPatientInput: {},
        picture: fileObjects[0]?.file,
      },
    });
  }
  const { data, loading: loadingQuery } = useQuery(GET_PATIENT, {
    variables: { patientId: patientId || patient?._id },
  });

  const {
    firstname,
    lastname,
    picture,
    birthday,
    pronouns,
    gender,
    phone,
    email,
    country,
    city,
    suite,
    address,
    state,
    zipcode,
    emergencyName,
    createdAt,
  } = data?.patient || {};
  const completed = data?.patient?.completed;
  const classes = useStyles();
  const { t } = useTranslation();
  React.useEffect(() => {
    document.title = 'Profile';
  }, []);
  const { data: doctors } = useGetDoctors();
  const doctorId = doctors && doctors?.doctors[0]?._id;
  const generalInfo = [
    {
      icon: <BirthIcon />,
      text:
        (birthday &&
          calculateAge(birthday as string) +
            ' ' +
            t('years') +
            ' ' +
            t('old')) ||
        '—',
    },
    {
      icon: <PronounsIcon />,
      text: pronouns || '—',
    },
    {
      icon: <GenderIcon />,
      text: gender || '—',
    },
  ];
  const contactInfo = [
    {
      icon: <PhonePatient />,
      text: phone || '—',
    },
    {
      icon: <MailPatient />,
      text: email || '—',
    },
    {
      icon: <AdressPatient />,
      text: `${address ? `${address}, ` : ''}
      ${city ? `${city}, ` : ''}
      ${state ? `${state} ` : ''}
      ${zipcode ? `${zipcode}, ` : ''}
      ${country ? `${country}` : ''}
      ${
        !!!address && !!!city && !!!state && !!!zipcode && !!!country ? '—' : ''
      }`,
    },
  ];
  const timezone =
    patientUpcomingAppointment?.doctorUpcomingAppointments?.result &&
    patientUpcomingAppointment?.doctorUpcomingAppointments?.result[0]?.patient
      ?.timeZone;
  const session =
    patientUpcomingAppointment?.doctorUpcomingAppointments?.result;
  const upcomingSession = transformArrayTimeZone(session, timezone);
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xl={3} lg={4}>
        <Box className={classes.boxwhite} px={4} py={2} position="relative">
          <Box display="flex" alignItems="center" maxWidth="calc(100% - 30px)">
            <Box position="relative">
              <Skeleton loading={loadingQuery} type="circle">
                <Avatar src={picture} className={classes.picture}>
                  {firstname && firstname[0]} {lastname && lastname[0]}
                </Avatar>
              </Skeleton>
              <Box
                position="absolute"
                bottom={0}
                right={0}
                style={{ cursor: 'pointer' }}
                onClick={() => setOpen(true)}
              >
                <UploadIconPatient />
              </Box>
            </Box>
            <Box flex={1} ml={2}>
              <Skeleton loading={loadingQuery}>
                <Typography className={classes.title}>
                  {capitalizeFirstLetter(firstname)}{' '}
                  {capitalizeFirstLetter(lastname)}
                </Typography>
              </Skeleton>
              <Skeleton loading={loadingQuery}>
                <Typography className={classes.joinedDate}>
                  {t('Joined')} {convertMsToDateMonth(createdAt)}
                </Typography>
              </Skeleton>
            </Box>
          </Box>

          <Box mt={4} borderBottom="1px solid #E0E0E0" pb={4}>
            {generalInfo?.map((el, key) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                style={{ gap: '10px' }}
                py={1}
              >
                {el?.icon}
                <Skeleton loading={loadingQuery}>
                  <Typography className={classes.joinedDate}>
                    {capitalizeFirstLetter(el?.text)}{' '}
                  </Typography>
                </Skeleton>
              </Box>
            ))}
          </Box>
          <Box mt={4} borderBottom="1px solid #E0E0E0" pb={4}>
            {contactInfo?.map((el, key) => (
              <Box
                key={key}
                display="flex"
                alignItems="flex-start"
                style={{ gap: '10px' }}
                py={1}
              >
                {el?.icon}
                <Skeleton loading={loadingQuery}>
                  <Typography className={classes.joinedDate}>
                    {t(capitalizeFirstLetter(el?.text))}{' '}
                  </Typography>
                </Skeleton>
              </Box>
            ))}
          </Box>
          <Box
            mt={6}
            display="flex"
            alignItems="center"
            style={{ gap: '10px' }}
            mb={2}
          >
            <Skeleton loading={loadingQuery}>
              <Typography className={classes.boldText}>
                {t('Emergency contact')}
              </Typography>
            </Skeleton>
            <Skeleton loading={loadingQuery}>
              <Typography className={classes.joinedDate}>
                {emergencyName || '—'}
              </Typography>
            </Skeleton>
          </Box>
          <Box
            position="absolute"
            top="2rem"
            right="2rem"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/patient/settings')}
          >
            <EditPatientLogo />
          </Box>
        </Box>
        <Box className={classes.boxwhite} px={4} py={2} mt={3}>
          <Box display="flex">
            <CalendarUpcoming style={{ marginRight: '10px' }} />
            <Box display="flex" flexDirection="column" mt={-0.5}>
              <Skeleton loading={loadingQuery}>
                <Typography className={classes.sectionTitle}>
                  {t('Upcoming session')}
                </Typography>
              </Skeleton>
              {patientUpcomingAppointment?.doctorUpcomingAppointments?.result
                ?.length > 0 && (
                <Box
                  onClick={() => handleOpenAddModal()}
                  style={{ cursor: 'pointer' }}
                >
                  <Skeleton loading={loadingQuery}>
                    <Typography className={classes.bluetext}>
                      + {t('Schedule new appointment')}
                    </Typography>
                  </Skeleton>
                </Box>
              )}
            </Box>
          </Box>
          <Box mt={4}>
            {loading && (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                p="1rem"
                border="1px solid #EAECF0"
                borderRadius="12px"
                height="100px"
                flex="1"
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Skeleton loading={true} type="circle">
                      <Box
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '100%',
                        }}
                      />
                    </Skeleton>
                    <Skeleton
                      loading={true}
                      type="text"
                      width="50px"
                      height="21px"
                    >
                      <Box
                        style={{
                          width: '50px',
                          height: '10px',
                        }}
                      />
                    </Skeleton>
                  </Box>
                  <Box display="flex" flexDirection="column" ml={2}>
                    <Skeleton
                      loading={true}
                      type="text"
                      width="100px"
                      height="21px"
                    >
                      <Box
                        style={{
                          width: '100px',
                          height: '21px',
                          borderRadius: '8px',
                        }}
                      />
                    </Skeleton>
                    <Skeleton
                      loading={true}
                      type="text"
                      width="120px"
                      height="21px"
                    >
                      <Box
                        style={{
                          width: '120px',
                          height: '21px',
                          borderRadius: '8px',
                        }}
                      />
                    </Skeleton>
                  </Box>
                </Box>
              </Box>
            )}
            {patientUpcomingAppointment?.doctorUpcomingAppointments?.result
              ?.length === 0 &&
              !loading && (
                <Box pb="2rem" width="100%">
                  <EmptyState
                    icon={<EmptySession />}
                    title="No sessions yet. Let's get started."
                    text="to schedule your first session."
                    onClick={() => handleOpenAddModal()}
                    isText
                  />
                </Box>
              )}
            {upcomingSession?.map((element) => (
              <ApptCard event={element} loading={loading} />
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xl={9} lg={8}>
        <Box className={classes.boxwhite} px={4} py={2}>
          <Feed patientId={patient?._id} doctorUrl={doctorId} />
        </Box>
      </Grid>
      <DropzoneDialogBase
        dialogTitle="Upload your picture"
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={fileObjects}
        filesLimit={1}
        cancelButtonText="cancel"
        submitButtonText="submit"
        maxFileSize={5000000}
        open={open}
        onAdd={onAddPicture}
        onDelete={onDeletePicture}
        onClose={() => {
          setOpen(false);
          setFileObjects([]);
        }}
        onSave={onSave}
        showPreviews
        showFileNamesInPreview={false}
        showAlerts={['error', 'info']}
      />
    </Grid>
  );
};

export default PatientProfile;
