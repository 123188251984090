import { gql, useSubscription } from '@apollo/client';

const PATIENT_LEAVED_WAITING_ROOM_SUBSCRIPTION = gql`
  subscription OnPatientLeaveWaitingRoom($roomId: String) {
    userLeavedWaitingRoom(roomId: $roomId) {
      roomId
      patient {
        _id
        firstname
        lastname
        email
        picture
      }
      waitingRoom {
        firstname
        lastname
      }
    }
  }
`;

const useListenLeavedWaitingRoom = ({
  roomId,
  handlePatientWaitingListsJoined,
}: any) => {
  const { error } = useSubscription(PATIENT_LEAVED_WAITING_ROOM_SUBSCRIPTION, {
    variables: { roomId },
    onSubscriptionData: (data) => {
      handlePatientWaitingListsJoined(
        data.subscriptionData?.data?.userLeavedWaitingRoom?.waitingRoom
      );
    },
  });
};

export default useListenLeavedWaitingRoom;
