import { gql, useMutation } from '@apollo/client';
import { GET_FINANCES } from './useGetFinances';
import { GET_INVOICE_REFERENCE } from './useGetInvoiceReference';

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($invoiceInput: InvoiceInput) {
    createInvoice(invoiceInput: $invoiceInput) {
      doctor {
        _id
      }
      patient {
        _id
      }
      appointment {
        _id
      }
      session {
        rate
      }
      amountPaid
      issueDate
      dueDate
      invoiceRef
      doctorInfo {
        firstname
        lastname
        phone
        address
        suite
        city
        zipcode
        country
        state
      }
      patientInfo {
        firstname
        lastname
        phone
        address
        suite
        city
        zipcode
        country
        state
      }
      invoicePdf
    }
  }
`;

export default function useCreateInvoice({
  page = 1,
  filter = '',
  patient = '',
}) {
  const queryObject = {
    limit: '5',
    page: page.toString(),
    filter,
    patient,
  };
  const [createInvoice, { data, error, loading }] = useMutation(
    CREATE_INVOICE,
    {
      refetchQueries: [
        { query: GET_FINANCES, variables: queryObject },
        { query: GET_INVOICE_REFERENCE },
      ],
      awaitRefetchQueries: true,
    }
  );

  return { createInvoice, data, error, loading };
}
