export type IMoodSleepTags = {
  [key: string]: string[];
};

export const moodTags: IMoodSleepTags = {
  Terrible: [
    'Irritated',
    'Anxious',
    'Scared',
    'Terrified',
    'Overwhelmed',
    'Repulsed',
    'Embarassed',
    'Frustrated',
    'Annoyed',
    'Envious',
    'Stressed',
    'Worried',
    'Guilty',
    'Discouraged',
    'Irritated',
    'Lonely',
    'Discouraged',
    'Dissapointed',
    'Exhausted',
    'Gloomy',
  ],
  Bad: [
    'Upset',
    'Nervous',
    'Scared',
    'Terrified',
    'Self-conscious',
    'Overwhelmed',
    'Repulsed',
    'Embarassed',
    'Frustrated',
    'Annoyed',
    'Envious',
    'Stressed',
    'Worried',
    'Guilty',
    'Discouraged',
    'Irritated',
    'Lonely',
    'Discouraged',
    'Tired',
    'Dissapointed',
    'Sad',
  ],
  Okay: [
    'Satisfied',
    'Calm',
    'Peaceful',
    'Apathetic',
    'Tired',
    'Content',
    'Relaxed',
  ],
  Good: ['Impressed', 'Excited', 'Happy', 'Satisfied', 'Relaxed', 'Delighted'],
  Great: [
    'Amazed',
    'Thrilled',
    'Surprised',
    'Enthusiastic',
    'Joyful',
    'Happy',
    'Brave',
    'Proud',
    'Confident',
    'Optimistic',
    'Entertained',
    'Content',
    'Relaxed',
    'Thankful',
    'Peaceful',
    'Calm',
  ],
};

export const sleepTags: IMoodSleepTags = {
  Terrible: [
    'Stress',
    'Caffeine',
    'Alcohol',
    'Tossing and turning',
    'Interrupted sleep',
    'Restless mind',
    'Insomnia',
    'Not tired',
    'Woke up early',
    'Woke up late',
    'Shared bed',
    'Children',
    'Pets',
    'Noise',
    'Room temperature',
    'Light',
    'Nightmare',
    'Medication',
    'Anxiety',
    'Overthinking',
    'Work',
    'Pain',
  ],
  Bad: [
    'Stress',
    'Caffeine',
    'Alcohol',
    'Tossing and turning',
    'Interrupted sleep',
    'Restless mind',
    'Insomnia',
    'Not tired',
    'Woke up early',
    'Woke up late',
    'Shared bed',
    'Children',
    'Pets',
    'Noise',
    'Room temperature',
    'Light',
    'Nightmare(s)',
  ],
  Okay: [
    'Exercise',
    'Self-care',
    'Food',
    'Busy schedule',
    'Journaling',
    'Nap',
    'Health condition',
    'Meditation',
    'Screen time',
    'Relaxation',
    'Reading',
    'Travel',
    'Bathroom',
    'Fell asleep quickly',
    'Restful sleep',
    'Dream(s)',
  ],
  Good: [
    'Exercise',
    'Self-care',
    'Food',
    'Busy schedule',
    'Journaling',
    'Nap',
    'Health condition',
    'Meditation',
    'Screen time',
    'Relaxation',
    'Reading',
    'Travel',
    'Bathroom',
    'Fell asleep quickly',
    'Restful sleep',
    'Dream(s)',
  ],
  Great: [
    'Exercise',
    'Self-care',
    'Food',
    'Busy schedule',
    'Journaling',
    'Nap',
    'Health condition',
    'Meditation',
    'Screen time',
    'Relaxation',
    'Reading',
    'Travel',
    'Bathroom',
    'Fell asleep quickly',
    'Restful sleep',
    'Dream(s)',
  ],
};
