import React from 'react';
import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/greenEye.svg';
import { ReactComponent as AssignIcon } from 'assets/patientActivities/assign.svg';
import { ReactComponent as DescIcon } from 'assets/patientActivities/desc.svg';

interface ITopSectionProps {
  handleBack: () => void;
  handleNext: () => void;
  handleAssign: () => void;
  title: string;
  description: string;
  step: number;
  handleSubmitFrom: () => void;
  previewMode?: boolean;
}

function TopSection({
  handleBack,
  handleNext,
  handleAssign,
  title,
  description,
  step,
  handleSubmitFrom,
  previewMode,
}: ITopSectionProps): React.ReactElement {
  const classes = useStyles();
  return (
    <Box className={classes.Bottonscontainer} px="3.5rem">
      {step !== 1 && (
        <Button
          className={classes.cancelButton}
          onClick={handleBack}
          data-cy="preview-button"
        >
          <Typography className={classes.cancelText}>Cancel</Typography>
        </Button>
      )}
      {!previewMode && (
        <Button className={classes.cancelButton} onClick={handleSubmitFrom}>
          <Typography className={classes.cancelText}>Save form</Typography>
        </Button>
      )}
      {step != 1 && (
        <Button
          className={classes.cancelButton}
          onClick={handleNext}
          data-cy="preview-button"
        >
          <Typography className={classes.previewText}>Preview</Typography>
        </Button>
      )}
    </Box>
  );
}

export default TopSection;
