import { gql, useMutation } from '@apollo/client';
import {
  CreateThreadConversation,
  CreateThreadConversationVariables,
} from './__generated__/CreateThreadConversation';

const CREATE_THREAD_CONVERSATION_MUTATION = gql`
  mutation CreateThreadConversation(
    $subject: String!
    $message: contentInput!
    $contact: [ID]
    $type: typeConversationEnum
  ) {
    createThreadConvertation(
      subject: $subject
      message: $message
      contact: $contact
      type: $type
    ) {
      _id
      subject
      isArchived
      createdAt
      lastUpdateTime
      type
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      updatedAt
    }
  }
`;

const useCreateThreadConversation = () => {
  return useMutation<
    CreateThreadConversation,
    CreateThreadConversationVariables
  >(CREATE_THREAD_CONVERSATION_MUTATION);
};

export { CREATE_THREAD_CONVERSATION_MUTATION };
export default useCreateThreadConversation;
