import React, { useState } from 'react';
import Input from 'components/ui/Inputs';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../styles';
import PatientSelect from 'pages/SchedulePage/AddEventPage/EventForm/PatientSelect';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { useQuery } from '@apollo/client';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

export const BillTo = ({ methods }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, control, errors } = methods;
  const commonProps = { register, control, errors };
  const editMode = false;
  const [inputValue, setInputValue] = useState('');
  const { data, loading } = useQuery(PATIENTS_RELATED);

  const relatedPatient = data?.patientsRelated;

  const [options, setOptions] = React.useState(
    convertObjectsFormat(relatedPatient, 'Patients')
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  React.useEffect(() => {
    if (relatedPatient) {
      setOptions(convertObjectsFormat(relatedPatient, 'Patients'));
    }
  }, [relatedPatient]);

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PatientSelect
          data-cy="add-appointment-patient"
          options={options}
          loading={loading}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          errors={errors}
          editMode={editMode}
          label={t('Client name')}
          hasAddBtn={false}
          {...commonProps}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Email')}
          {...commonProps}
          name="patientEmail"
          type="email"
          disabled
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <Text i18nKey="phoneNumber" className={classes.labelText}>
          Phone number
        </Text>
        <Input
          control={control}
          inputRef={register()}
          type="phone"
          name="patientPhone"
          variant="filled"
          helperText={
            errors && errors?.phone && <span>{errors?.phone?.message}</span>
          }
          error={errors && !!errors?.phone}
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Text i18nKey="country" className={classes.labelText}>
          Country
        </Text>
        <Input
          inputRef={register}
          variant="filled"
          control={control}
          type="country"
          name="patientCountry"
          data-cy="country-input"
          {...commonProps}
          defaultValue={{ label: '' }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label={t('Street')}
          {...commonProps}
          name="patientAddress"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label={t('Apt, suite (optional)')}
          {...commonProps}
          name="patientSuite"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label={t('City')}
          {...commonProps}
          name="patientCity"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('State')}
          {...commonProps}
          name="patientState"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Zip code')}
          {...commonProps}
          name="patientZipcode"
          type="number"
          defaultValue={''}
        />
      </Grid>
    </Grid>
  );
};
