import React, { useEffect } from 'react';
import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as EditTitleIcon } from 'assets/edittitle.svg';
import BasicModal from 'components/ui/Modal';
import Input from 'components/ui/Inputs';
import { useToast } from 'hooks/useToast';
import Text from 'Text';
import { useSnackbar } from 'hooks/useSnackbar';

interface INotesTopSectionProps {
  title: string;
  handleSubmitFrom: () => void;
  handleEditTitle: (title: string) => void;
  handleBack: () => void;
  step: number;
  handleNext: () => void;
}

function NotesTopSection({
  title,
  handleSubmitFrom,
  handleEditTitle,
  handleBack,
  step,
  handleNext,
}: INotesTopSectionProps): React.ReactElement {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState(title);
  useEffect(() => {
    setTitleValue(title);
  }, [title]);
  const { triggerNack } = useSnackbar();
  const handleSave = () => {
    handleEditTitle(titleValue);
    triggerNack();
    setTimeout(() => setOpen(false), 1000);
  };
  return (
    <Box className={classes.Bottonscontainer}>
      <Button
        className={classes.cancelButton}
        onClick={handleBack}
        data-cy="preview-button"
      >
        <Typography className={classes.cancelText}>
          {step != 1 ? 'Cancel' : 'Back'}
        </Typography>
      </Button>
      {step != 1 && (
        <Button
          className={classes.cancelButton}
          onClick={handleNext}
          data-cy="preview-button"
        >
          <Typography className={classes.previewText}>Preview</Typography>
        </Button>
      )}
      <Button className={classes.saveButton} onClick={handleSubmitFrom}>
        <Typography className={classes.assignText}>
          <Text i18nKey="save">Save</Text>
        </Typography>
      </Button>
    </Box>
  );
}

export default NotesTopSection;
