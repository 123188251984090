import React, { ReactElement, useState } from 'react';
import { makeStyles, Box, Avatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/icons/Vector.svg';
import Text from 'Text';

interface IformData {
  firstname: string;
  middlename: string;
  lastname: string;
  email: string;
  practice: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '25px',
    background: '#F8FAFB',
    dispaly: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: theme.typography.pxToRem(300),
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    borderRadius: '50%',
    width: theme.typography.pxToRem(115),
    height: theme.typography.pxToRem(115),
    marginInline: 'auto',
    overflow: 'hidden',
    fontSize: '48px',
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    width: '12px',
    cursor: 'pointer',
  },
  label: {
    marginTop: '21px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#425364',
    width: '100%',
    textAlign: 'center',
  },
  email: {
    textAlign: 'center',
    marginTop: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#425364',
    width: '100%',
  },
  Profilebutton: {
    cursor: 'pointer',
    height: '44px',
    background: '#2DC76D',
    border: 'none',
    borderRadius: '0px 0px 24px 24px',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    width: '100%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IStaffCardProps {
  id?: string;
  firstname: string;
  middlename?: string;
  lastname: string;
  picture: string;
  email: string;
  openDeleteModal?: (id: string, firstname: string, isInvited) => void;
  disabled?: boolean;
  isInvited?: boolean;
  staffId?: string;
}

const StaffCard = ({
  id,
  staffId,
  firstname,
  lastname,
  picture,
  email,
  openDeleteModal,
  disabled = false,
  isInvited = false,
}: IStaffCardProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item xs={12} lg={3} xl={3} md={4} sm={6} className={classes.center}>
      <Box className={classes.card}>
        <Box style={{ padding: '1rem' }}>
          <Box
            className={classes.close}
            onClick={() => openDeleteModal(id, firstname, isInvited)}
          >
            <DeleteIcon className={classes.deleteIcon} />
          </Box>

          <Avatar className={classes.imageContainer} src={picture}>
            {`${firstname[0].toUpperCase()} ${lastname[0].toUpperCase()}`}
          </Avatar>
          <Box className={classes.label}>
            {firstname} {lastname}
          </Box>
          <Box className={classes.email}>{email}</Box>
        </Box>
        <button
          className={classes.Profilebutton}
          disabled={disabled}
          style={{
            background: disabled && '#ABE2B7',
            color: disabled && 'white',
            cursor: disabled && 'default',
          }}
          onClick={() => history.push(`/doctor/Staff/${staffId}`)}
        >
          <Text i18nKey='viewProfile'>View Profile</Text>
        </button>
      </Box>
    </Grid>
  );
};

export default StaffCard;
