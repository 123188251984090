import React from 'react';
import { useStyles } from '../../styles';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from 'assets/patientActivities/ArrowBlack.svg';
import EditButtons from 'components/EditButtons';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

interface IFirstStep {
  setSelected: (selected: boolean) => void;
  name: string;
  selected: boolean;
}

function FirstStep({ setSelected, name, selected }: IFirstStep) {
  const classes = useStyles({ selected });
  const { t } = useTranslation();
  return (
    <Box className={classes.reflectionModal}>
      <Box style={{ paddingInline: '2rem' }}>
        <Box pt={3}>
          <Typography className={classes.modalTitle}>
            {t('Reflection check-ins')}
          </Typography>
        </Box>
        <Box my={5}>
          <Box
            className={classes.guided}
            onClick={() => setSelected(true)}
            data-cy="guided-reflections"
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Typography className={classes.guidedTitle}>
                  {t('Guided reflections')}
                </Typography>
                <Typography className={classes.guidedText}>{`${t(
                  'Prompt'
                )} ${capitalizeFirstLetter(name)} ${t(
                  'to enter a reflection based on questions you ask.'
                )}`}</Typography>
              </Box>
              <ArrowIcon />
            </Box>
          </Box>
          <Box
            className={classes.free}
            onClick={() => setSelected(false)}
            data-cy="free-reflections"
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Typography className={classes.guidedTitle}>
                  {t('Free reflections')}
                </Typography>
                <Typography className={classes.guidedText}>{`${t(
                  'Prompt'
                )} ${capitalizeFirstLetter(name)} ${t(
                  'to share a reflection without being asked a question'
                )} `}</Typography>
              </Box>
              <ArrowIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FirstStep;
