import { gql, useMutation } from '@apollo/client';

const MAKE_DEFAULT = gql`
  mutation makeDefaultDoctorTemplate($id: ID!) {
    makeDefaultDoctorTemplate(id: $id) {
      _id
    }
  }
`;

const useMakeDefaultTemplate = () => {
  return useMutation(MAKE_DEFAULT);
};

export default useMakeDefaultTemplate;
