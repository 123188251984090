import { gql, useMutation } from '@apollo/client';
import { INVITED_STAFF } from 'pages/AdminPage/TabsContent/Staff';

const CREATE_STAFF_INVITATION = gql`
  mutation createStaffInvitation($staff: StaffInvitationInput) {
    createStaffInvitation(staff: $staff) {
      _id
      email
      doctor
      practice
      firstname
      middlename
      lastname
    }
  }
`;

const useCreateStaffInvitation = () => {
  const [createStaffInvitation, { loading }] = useMutation(
    CREATE_STAFF_INVITATION
  );
  async function handleCreateStaffInvitation(staff) {
    try {
      return await createStaffInvitation({
        variables: { staff },
        refetchQueries: [{ query: INVITED_STAFF }],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleCreateStaffInvitation, loading };
};

export { CREATE_STAFF_INVITATION };
export default useCreateStaffInvitation;
