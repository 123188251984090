import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PriorityIcon from 'assets/PriorityIcon.svg';
import down from 'assets/dashboard/down.svg';
import { Typography } from '@material-ui/core';
import { convertType } from './utils';

export enum ActivityType {
  reflection = 'reflection',
  moodCheckin = 'moodCheckin',
  sleepCheckin = 'sleepCheckin',
  questionnaires = 'questionnaires',
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '0.5px solid #A1B0C4FF',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '44px',
    borderRadius: '6px',
    paddingInline: theme.spacing(5),
    color: '#425364',
    textTransform: 'none',
    positon: 'relative',
    fontSize: '17px',
  },
  calendar: {
    position: 'absolute',
    left: '15px',
  },
  down: {
    position: 'absolute',
    right: '5px',
  },
  paper: {
    width: '158px',
    marginTop: theme.spacing(8),
  },
}));
interface IMenuProps {
  menuName?: string;
  setMenuName?: (menuName: string) => void;
  MenuValue?: string;
  setMenuValue?: (menuValue: string) => void;
  className?: string;
  classArrow?: string;
}

export default function SimpleMenu({
  menuName,
  setMenuName,
  setMenuValue,
  className,
  classArrow,
}: IMenuProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        className={clsx(className, classes.root)}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={PriorityIcon} className={classes.calendar} alt="calendar" />
        <Typography style={{ textTransform: 'capitalize' }}>
          {convertType(menuName)}
        </Typography>
        <img src={down} className={clsx(classes.down, classArrow)} alt="down" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock={false}
      >
        <MenuItem
          onClick={() => {
            setMenuName('All');
            setMenuValue('');
            handleClose();
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName(ActivityType.reflection);
            setMenuValue(ActivityType.reflection);
            handleClose();
          }}
        >
          Reflection
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName(ActivityType.moodCheckin);
            setMenuValue(ActivityType.moodCheckin);
            handleClose();
          }}
        >
          Mood check-ins
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName(ActivityType.sleepCheckin);
            setMenuValue(ActivityType.sleepCheckin);
            handleClose();
          }}
        >
          Sleep check-ins
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName(ActivityType.questionnaires);
            setMenuValue(ActivityType.questionnaires);
            handleClose();
          }}
        >
          Forms
        </MenuItem>
      </Menu>
    </div>
  );
}
