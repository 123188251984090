import React, { ReactElement } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from 'components/ui/Button';
import clsx from 'clsx';
import { ReactComponent as Alert } from 'assets/biggerAlert.svg';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  modalDisconTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
  },
  Modalcontainer: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '25vw',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    // width: '6rem',
    // height: '36px',
    color: '#344054',
    width: '90px',
    height: '44px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '10px 16px 10px 16px',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Inter, sans-serif',
  },
  disconnectBtn: {
    width: '124px',
    height: '44px',
    padding: '10px 16px 10px 16px',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #D92D20',
    color: 'white',
    backgroundColor: '#D92D20',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontFamily: 'Inter, sans-serif',
    '&:hover': {
      background: '#D92D20',
    },
  },
  modalBox: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '25vw',
    backgroundColor: 'white',
    borderRadius: '12px',
    overflow: 'hidden',
    height: 'auto',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  subtitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
  },
}));
const DisconnectModal = ({ handleClose, handleDisconnect }): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const hanldleDisconnectCalendar = () => {
    triggerSnack('Calendar disconnected successfully');
    handleDisconnect();
  };
  const handleClosePopUp = () => {
    handleClose();
  };
  return (
    <Box className={classes.modalBox}>
      <Box display="flex">
        <Alert />
        <Box ml={2}>
          <Typography className={classes.modalDisconTitle}>
            {t('Disconnect calendar')}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('diconnectCalendar')}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={4}>
        <Button className={classes.button} onClick={handleClosePopUp}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={hanldleDisconnectCalendar}
          className={classes.disconnectBtn}
        >
          {t('Disconnect')}
        </Button>
      </Box>
    </Box>
  );
};

export default DisconnectModal;
