import React, { ReactElement, useEffect } from 'react';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import Autocomplete, {
  AutocompleteCloseReason,
  createFilterOptions,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { FormInput } from './FormInput';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 221,
      fontSize: 13,
    },
    button: {
      fontSize: 13,
      width: '100%',
      textAlign: 'left',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 'fit-content',
      zIndex: 9999999999999999999999999999999999999,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    chip: {
      backgroundColor: 'white',
      color: '#344054',
      border: '1px solid #D0D5DD',
      marginBlock: '4px',
      borderRadius: '6px',
      fontFamily: 'Roboto',
      fontStyle: 'Regular',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      '& .MuiChip-deleteIcon': {
        fill: '#425364',
      },
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
);

export interface Option {
  name: string;
  description?: string;
  color?: string;
  picture?: any;
  id: string | number;
}

export interface IAutoCompteAddProps {
  values: Option[];
  setValues: (values: Option[]) => void;
  options: Option[];
  renderPreview?: (option: Option) => ReactElement;
  renderOption?: (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  renderPreviewWrapper?: (content: any) => ReactElement;
  groupBy?: any;
  hasErrors?: boolean;
}
// Get option label
const filter = createFilterOptions<any>();
export default function AutoCompleteAdd({
  values,
  setValues,
  options,
  renderOption,
  renderPreview,
  renderPreviewWrapper,
  hasErrors,
  ...props
}: IAutoCompteAddProps): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pendingValue, setPendingValue] = React.useState<any[]>(values);
  const [key, setValueKey] = React.useState('autocomplete');
  const handleClose = (
    event: React.ChangeEvent<any>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValues(pendingValue);
  };

  useEffect(() => {
    setPendingValue(values);
    setValueKey(pendingValue && pendingValue[0]);
  }, [values]);

  const handleChipDelete = (deletedOption: Option) => {
    const newValues = values.filter((option) => option.id !== deletedOption.id);
    setValues(newValues);
  };
  return (
    <>
      <Autocomplete
        id="autocomplete-id"
        disableClearable
        onClose={handleClose}
        multiple
        renderTags={(value: Option[]) =>
          value.map((option, index) => (
            <Chip
              label={t(
                option?.name?.[0].toUpperCase() +
                  option?.name?.slice(1).toLowerCase()
              )}
              key={index}
              onDelete={() => handleChipDelete(option)}
              className={classes.chip}
            />
          ))
        }
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal,
          popper: classes.popper,
          tag: classes.chip,
        }}
        value={pendingValue}
        key={key}
        onChange={(event, newValue) => {
          setPendingValue(newValue);
        }}
        disableCloseOnSelect
        getOptionSelected={(option: Option, value: Option) =>
          option?.id === value?.id
        }
        filterOptions={(argumentsOptions, params) => {
          const filtered = filter(argumentsOptions, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              label: params.inputValue,
              type: 'Symptoms',
              name: params.inputValue,
              id: Math.random(),
            });
          }
          return filtered;
        }}
        renderOption={renderOption}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <FormInput
            ref={params.InputProps.ref}
            variant="filled"
            error={hasErrors}
            {...params}
          />
        )}
        {...props}
      />
    </>
  );
}

interface LabelType {
  name: string;
  color: string;
  description?: string;
}
