import { gql, useMutation } from '@apollo/client';
import { GET_CURRENT_USER } from 'pages/AdminPage/index';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

export const UPDATE_PASSWORD_USER = gql`
  mutation changePassword($passwordInput: PasswordInput!) {
    changePassword(passwordInput: $passwordInput) {
      password
      passwordConfirm
    }
  }
`;
export default function useEditPasswordUser() {
  const {
    user: { _id: id },
  } = useAuth();
  const [changePassword] = useMutation(UPDATE_PASSWORD_USER, {
    refetchQueries: [{ query: GET_CURRENT_USER, variables: { userId: id } }],
    awaitRefetchQueries: false,
  });
  return { changePassword };
}
