import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_SUBSCRIPTION } from './useGetSubscription';

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($subscriptionId: String) {
    cancelSubscription(subscriptionId: $subscriptionId)
  }
`;

export default function useCancelSubscription() {
  const [cancelSubscription, { loading, error }] = useMutation(
    CANCEL_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: GET_DOCTOR_SUBSCRIPTION,
        },
      ],
    }
  );

  return { cancelSubscription, loading, error };
}
