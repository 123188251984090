import React from 'react';
import Skeleton from 'components/ui/Skeleton';
import IconButton, {
  IconButtonProps,
  IconButtonTypeMap,
} from '@material-ui/core/IconButton';

interface IIconButtonProps extends IconButtonProps {
  isLoading?: boolean;
}
export default function index({
  isLoading,
  children,
  ...props
}: IIconButtonProps) {
  return (
    <Skeleton loading={isLoading}>
      <IconButton {...props}>{children}</IconButton>
    </Skeleton>
  );
}
