import React from 'react';
import { Box, Button, Avatar, Typography } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as ProviderIcon } from 'assets/ProvidersIcon.svg';
import { ReactComponent as EyeMessages } from 'assets/EyeMessages.svg';
import useGetAllThreadConversationByUser from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetAllThreadConversationByUser';
import { formatDateMessage } from 'utils';
import { useHistory } from 'react-router-dom';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyInbox } from 'assets/emptyState/inbox.svg';
import Skeleton from 'components/ui/Skeleton';
import UpcomingMessagesSkeleton from './UpcomingMessagesSkeleton';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { truncateText } from 'utils/FormatText';
const RecentMessages = () => {
  const classes = useStyles();
  const { data, loading } = useGetAllThreadConversationByUser(
    false,
    null,
    3,
    0
  );
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Box
      className={classes.upcomingBox}
      px={3}
      pt={3}
      pb={1.5}
      width="100%"
      height="100%"
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ProviderIcon style={{ marginRight: '10px' }} />
          <Text i18nKey="messages" className={classes.textTitle}>
            Messages
          </Text>
        </Box>
        <Button
          className={classes.buttonBook}
          onClick={() => history.push('/patient/inbox')}
        >
          <EyeMessages style={{ marginRight: '5px' }} />
          <Text
            style={{
              color: '#0265DC',
              fontWeight: 600,
              fontSize: '16px',
              textTransform: 'none',
            }}
            i18nKey="allMessages"
          >
            All messages
          </Text>
        </Button>
      </Box>
      <Box mt={2}>
        {loading && [1, 2, 3].map(() => <UpcomingMessagesSkeleton />)}
        {!loading &&
          (!data?.getAllThreadConvertationByUser ||
            data?.getAllThreadConvertationByUser?.length === 0) && (
            <Box py="5rem" width="100%">
              <EmptyState
                icon={<EmptyInbox />}
                title={t('Inbox is empty')}
                text={t('to send a message.')}
                isText
                onClick={() => history.push('/patient/inbox')}
              />
            </Box>
          )}
        {data?.getAllThreadConvertationByUser?.map((el, index) => (
          <Box
            display="flex"
            alignItems="center"
            py={2}
            borderBottom={index !== 2 && '1px solid #EAECF0'}
            position="relative"
          >
            <Avatar src={el?.creator?.picture} className={classes.picture}>
              {el?.creator?.firstname && el?.creator?.firstname[0]}{' '}
              {el?.creator?.lastname && el?.creator?.lastname[0]}
            </Avatar>
            <Box display="flex" flexDirection="column" ml={2}>
              <Typography className={classes.doctorLastName}>
                {el?.creator?.firstname} {el?.creator?.lastname}
              </Typography>
              <Typography className={classes.subject}>
                {truncateText(el?.subject, 40)}
              </Typography>
              <Typography className={classes.content}>
                {truncateText(el?.lastMessage?.content?.text, 40) ||
                  'Attachment'}
              </Typography>
            </Box>
            <Box position="absolute" top={20} right={5}>
              <Typography className={classes.timeUpdated}>
                {formatDateMessage(el?.updatedAt)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RecentMessages;
