import React, { ReactElement, useState } from 'react';
import { makeStyles, Box, Avatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddStaffIcon } from 'assets/addStaff.svg';
import BasicModal from 'components/ui/Modal';
import StaffModal from './AddStaffModal';
import useGetStaffByDoctor from '@eggmed/graphql-client/operations/staffOperations/useGetStaffByDoctor';
import DeleteStaff from './DeleteStaffModal';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useCreateStaffInvitaion from '@eggmed/graphql-client/operations/staffOperations/useCreateStaffInvitation';
import { useToast } from 'hooks/useToast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { gql, useQuery } from '@apollo/client';
import StaffCard from './StaffCard';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';

interface IformData {
  firstname: string;
  middlename: string;
  lastname: string;
  email: string;
  practice: string;
}

const useStyles = makeStyles((theme) => ({
  h3: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  gridContainerBilling: {
    padding: '0px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  cardContainer: {
    width: '100%',
    marginTop: '3rem',
    minHeight: theme.typography.pxToRem(404),
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingInline: '1rem',
    },
  },
  card: {
    borderRadius: '25px',
    background: '#F8FAFB',
    dispaly: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: theme.typography.pxToRem(300),
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    borderRadius: '50%',
    width: theme.typography.pxToRem(115),
    height: theme.typography.pxToRem(115),
    marginInline: 'auto',
    overflow: 'hidden',
    fontSize: '48px',
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    width: '12px',
    cursor: 'pointer',
  },
  label: {
    marginTop: '21px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#425364',
    width: '100%',
    textAlign: 'center',
  },
  email: {
    textAlign: 'center',
    marginTop: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#425364',
    width: '100%',
  },
  Profilebutton: {
    cursor: 'pointer',
    height: '44px',
    background: '#2DC76D',
    border: 'none',
    borderRadius: '0px 0px 24px 24px',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    width: '100%',
  },
  addStaff: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #BCC6D3',
    background: '#F8FAFB',
    borderRadius: '24px',
    cursor: 'pointer',
    gap: '1rem',
    width: '100%',
    height: theme.typography.pxToRem(300),
  },
  addStaffImage: {
    width: theme.typography.pxToRem(60.5),
    height: theme.typography.pxToRem(64),
    overflow: 'hidden',
  },
  AddStaffLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    color: '#2DC76D',
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteModalBox: {
    overflow: 'hidden',
    width: '35vw',
    height: 'auto',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  modalBox: {
    borderRadius: '12px',
    overflow: 'hidden',
    width: '35vw',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      height: '60vh',
    },
  },
}));

const schema = yup.object().shape({
  firstname: yup.string().required('First name is a required field'),
  lastname: yup.string().required('Last name is a required field'),
  email: yup.string().email().required('Email is a required field'),
  practice: yup.string().required('Practice is a required field'),
});

const Staff = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.only('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const { triggerSnack } = useSnackbar();

  const spacing = () => {
    switch (true) {
      case isExtraLarge:
        return 9;
      case isLarge:
        return 5;
      case isMedium:
        return 3;
      case isSmall:
        return 1;
      case isExtraSmall:
        return 9;
      default:
        return 1;
    }
  };
  const cardSpacing = spacing();
  const { user } = useAuth();
  const { data, loading, error } = useGetStaffByDoctor();
  const [invited, setInvited] = useState(false);
  const { data: invitedStaff } = useQuery(INVITED_STAFF);
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [staff, setStaff] = useState({ id: '', name: '' });
  const [errorState, setErrorState] = useState(null);
  function handleClose() {
    setModalState(false);
    setDeleteModalState(false);
  }
  function openModal() {
    setModalState(true);
  }
  function openDeleteModal(id, name, invited) {
    setStaff({ id, name });
    setDeleteModalState(true);
    setInvited(invited);
  }
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { triggerToast, openSuccess } = useToast();
  const { handleCreateStaffInvitation, loading: staffLoading } =
    useCreateStaffInvitaion();
  const onSubmit = async (data: IformData) => {
    const { firstname, middlename, lastname, email, practice } = data;
    const invitationInput = {
      email,
      doctor: user.doctorId,
      practice: practice.toLowerCase(),
      firstname,
      middlename,
      lastname,
    };
    try {
      const res = await handleCreateStaffInvitation(invitationInput);
      if (res instanceof Error) {
        setErrorState(res.message);
        return;
      }
      triggerSnack();
      setTimeout(() => handleClose(), 1000);
    } catch (e) {
      console.log(e);
      setErrorState(e);
    }
  };
  return (
    <Grid container className={classes.gridContainerBilling}>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <Text i18nKey="staffSettings" className={classes.h3}>
          Team settings
        </Text>
      </Grid>
      <Box width="100%" display="flex" justifyContent="center">
        <Grid container className={classes.cardContainer} spacing={cardSpacing}>
          <Grid
            item
            xs={12}
            lg={3}
            xl={3}
            md={4}
            sm={6}
            className={classes.center}
          >
            <Box
              className={classes.addStaff}
              onClick={openModal}
              data-cy="add-staff"
            >
              <Box className={classes.addStaffImage}>
                <AddStaffIcon />
              </Box>
              <Box className={classes.AddStaffLabel}>
                <Text i18nKey="addStaffMember">Add staff member</Text>
              </Box>
            </Box>
          </Grid>
          {data?.getStaffsByDoctor.map((staff) => (
            <StaffCard
              staffId={staff?.user?._id}
              id={staff?._id}
              firstname={staff?.user?.firstname}
              lastname={staff?.user?.lastname}
              picture={staff?.user?.picture}
              email={staff?.user?.email}
              openDeleteModal={openDeleteModal}
            />
          ))}
          {invitedStaff?.invitedStaffByDoctor?.map((staff) => (
            <StaffCard
              id={staff?._id}
              firstname={staff.firstname}
              lastname={staff.lastname}
              picture={staff.picture}
              email={staff.email}
              openDeleteModal={openDeleteModal}
              disabled
              isInvited
            />
          ))}
        </Grid>
      </Box>
      <FormProvider {...methods}>
        <BasicModal
          open={modalState}
          onClose={handleClose}
          handleClose={handleClose}
          className={classes.modalBox}
          title={t('Add staff member')}
          titlePadding="3rem"
          isFromModal
          loading={staffLoading}
          onSubmit={onSubmit}
        >
          <StaffModal error={errorState} setError={setErrorState} />
        </BasicModal>
      </FormProvider>
      <BasicModal
        open={deleteModalState}
        onClose={handleClose}
        isRegularModal
        handleClose={handleClose}
        title="Remove Staff"
        titlePadding="2.5rem"
      >
        <Box className={classes.deleteModalBox}>
          <DeleteStaff
            handleClose={handleClose}
            staff={staff}
            invited={invited}
          />
        </Box>
      </BasicModal>
    </Grid>
  );
};

export const INVITED_STAFF = gql`
  query invitedStaffByDoctor {
    invitedStaffByDoctor {
      _id
      email
      firstname
      middlename
      lastname
    }
  }
`;

export default Staff;
