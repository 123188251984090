import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const SuccessButton = withStyles((theme) =>
  createStyles({
    root: {
      color: 'white',
      backgroundColor: '#1F61DC',
      width: '4.625rem',
      height: '2.263125rem',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.done.main,
        borderColor: theme.palette.done.main,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.done.main,
        borderColor: theme.palette.done.main,
      },
    },
  })
)(Button);
export default SuccessButton;
