import { gql, useSubscription } from '@apollo/client';
import {
  subscriptionArchivedThread,
  subscriptionArchivedThreadVariables,
  subscriptionArchivedThread_subscriptionArchivedThread,
} from './__generated__/subscriptionArchivedThread';

const SUBSCRIPTION_TO_ARCHIVED_THREAD = gql`
  subscription subscriptionArchivedThread($userId: ID!) {
    subscriptionArchivedThread(userId: $userId) {
      type
      newArchivedThread {
        _id
        subject
        createdAt
        lastUpdateTime
        type
        updatedAt
        isArchived
        unReadItMessage {
          id
          threadConversation
          user
          lastMessageReadIt
        }
        creator {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        lastMessage {
          user {
            _id
            firstname
            middlename
            username
          }
          content {
            text
            files {
              _id
              url
              fileName
              mimeType
            }
          }
        }
        participants {
          user {
            _id
            firstname
            middlename
            username
            lastname
            role
            picture
            organizationId
            doctorId
          }
          isAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const useSubscriptionThreadArchived = (
  userId: string,
  event: (data: subscriptionArchivedThread_subscriptionArchivedThread) => void
) => {
  const result = useSubscription<
    subscriptionArchivedThread,
    subscriptionArchivedThreadVariables
  >(SUBSCRIPTION_TO_ARCHIVED_THREAD, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      if (subscriptionData?.data?.subscriptionArchivedThread)
        event(subscriptionData?.data?.subscriptionArchivedThread);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_ARCHIVED_THREAD };
export default useSubscriptionThreadArchived;
