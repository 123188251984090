import React from 'react';
import NotificationPage from './NotificationsPage';
import NotificationProvider from './Provider';

const PatientNotification = () => {
  React.useEffect(() => {
    document.title = 'Notifications';
  }, []);
  return (
    <NotificationProvider>
      <NotificationPage />
    </NotificationProvider>
  );
};

export default PatientNotification;
