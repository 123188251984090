import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import clsx from 'clsx';
import PriorityIcon from 'assets/PriorityIcon.svg';
import down from 'assets/dashboard/down.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '158px',
    height: '44px',
    borderRadius: '6px',
    paddingLeft: '-25px',
    color: '#425364',
    fontSize: '17px',
    textTransform: 'capitalize',
    positon: 'relative',
  },
  calendar: {
    position: 'absolute',
    left: '15px',
  },
  down: {
    position: 'absolute',
    right: '5px',
  },
  paper: {
    width: '158px',
    marginTop: theme.spacing(8),
  },
}));
interface IMenuProps {
  menuName?: string;
  setMenuName?: (menuName: string) => void;
  MenuValue?: string;
  setMenuValue?: (menuValue: string) => void;
  className?: string;
  classArrow?: string;
}

export default function SimpleMenu({
  menuName,
  setMenuName,
  setMenuValue,
  className,
  classArrow,
}: IMenuProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        className={clsx(className, classes.root)}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={PriorityIcon} className={classes.calendar} alt="calendar" />
        {t(menuName)}{' '}
        <img src={down} className={clsx(classes.down, classArrow)} alt="down" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock={false}
      >
        <MenuItem
          onClick={() => {
            setMenuName('Priority');
            setMenuValue('');
            handleClose();
          }}
        >
          <Text i18nKey="all">All</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName('high');
            setMenuValue('high');
            handleClose();
          }}
        >
          <Text i18nKey="high">High</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName('medium');
            setMenuValue('medium');
            handleClose();
          }}
        >
          <Text i18nKey="medium">Medium</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuName('low');
            setMenuValue('low');
            handleClose();
          }}
        >
          <Text i18nKey="low">Low</Text>
        </MenuItem>
      </Menu>
    </div>
  );
}
