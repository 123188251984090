import React from 'react';
import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface IOptionRender {
  selected: boolean;
  name: string;
}

export default function OptionRender({ selected, name }: IOptionRender) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box width="100%" display="flex" alignItems="center">
      <div className={clsx(classes.text)}>
        <Typography>
          {t(name?.[0].toUpperCase() + name?.slice(1)?.toLowerCase())}
        </Typography>
      </div>
      <Box ml="auto">
        <Button
          size="small"
          style={{
            backgroundColor: selected ? '#E9F9F0' : '#EFF6FF',
            color: selected ? '#2DC76D' : '#1F61DC',
          }}
        >
          {selected ? (
            <>
              <DoneIcon className={classes.iconSelected} />
              Added
            </>
          ) : (
            <>
              <AddIcon className={classes.iconSelected} />
              Add
            </>
          )}
        </Button>
      </Box>
    </Box>
  );
}
