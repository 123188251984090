import { ApolloError, FetchResult } from '@apollo/client';
import React from 'react';

export interface IDoctorWaitingPageContext {
  waitingPatients: any[];
  handleJoinDoctorWaitingRoom: any;
  handleLeaveDoctorWaitingRoom: any;
  handleLeaveWaitingRoom: any;
  waitingMembersData: any[];
  // handleJoinVideoRoomWithPatient: (
  //   patientId: string,
  //   appointmentId: string,
  //   handleError?: ((e: any) => void) | undefined
  // ) => Promise<
  //   FetchResult<any, Record<string, any>, Record<string, any>> | undefined
  // >;
  // joinVideoRoomWithPatientData: any;
  roomError: ApolloError | undefined;
  roomLoading: boolean;
  setWaitingPatients: (patients: any[]) => void;
  roomData: any;
  isWidget: boolean;
  roomId: string;
}

export const Context = React.createContext<
  IDoctorWaitingPageContext | undefined
>(undefined);
