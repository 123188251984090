import React from 'react';
import { useStyles } from 'pages/PatientActivities/styles';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditButtons from 'components/EditButtons';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import ScrollBar from 'components/ui/ScrollBar';
import Input from 'components/ui/Inputs';
import Switch from 'components/ui/Switch';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/';
import ErrorIcon from '@material-ui/icons/Error';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import BasicModal from 'components/ui/Modal';
import FormPage from 'pages/FormsPage';
import Text from 'Text';

const schema = yup.object().shape({
  templateName: yup.string().required('Template name is a required field'),
});

function NewTemplate({
  handleBack,
  patientId,
  appointmentId,
  settings = false,
}: {
  handleBack: () => void;
  patientId?: string;
  appointmentId?: string;
  settings?: boolean;
}) {
  const { doctor } = useAuth();
  const classes = useStyles({ selected: false });
  const history = useHistory();
  const { control, register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = React.useState(false);
  const [forms, setForms] = React.useState(null);
  const onSubmit = (data) => {
    setOpen(true);
    setForms({
      formName: data.templateName,
      formDescription: data.description,
      doctorId: doctor._id,
      patientId,
      appointmentId,
      settings,
    });
  };
  return (
    <Box
      style={{
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        boxSizing: 'border-box',
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box className={classes.reflectionModal}>
          <ScrollBar sx={{ flex: 1 }}>
            <Box
              width="100%"
              style={{
                boxSizing: 'border-box',
                padding: '2rem',
              }}
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                position="relative"
              >
                <Box mb={1}>
                  <Text i18nKey='templateName' className={classes.InputLabel}>
                    Template name
                  </Text >
                </Box>
                <Input
                  variant="filled"
                  type="text"
                  name="templateName"
                  inputRef={register}
                  control={control}
                />
                <Box className={classes.errorBox}>
                  {errors && errors.templateName && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.templateName?.message}
                    </span>
                  )}
                </Box>
              </Box>
            </Box>
          </ScrollBar>
          <Box
            height="76px"
            display="flex"
            alignItems="center"
            width="100%"
            borderTop="1px solid #EAECF0"
            style={{
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
            }}
          >
            <EditButtons
              px="2rem"
              editable
              isSlide
              isPatient={false}
              type="Start"
              submitText="Next"
              cancelText="Back"
              loading={false}
              handleOpen={(e) => {
                e.preventDefault();
                handleBack();
              }}
            />
          </Box>
        </Box>
      </form>
      <BasicModal
        open={open}
        onClose={() => {
          setOpen(false);
          handleBack();
        }}
        handleClose={() => {
          setOpen(false);
          handleBack();
        }}
        isRegularModal
        isSlide
        divider
        title={''}
        titlePadding="0rem"
      >
        <FormPage isNotes={true} forms={forms} settings={true} />
      </BasicModal>
    </Box>
  );
}

export default NewTemplate;
