import { Box, Typography, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from 'components/ui/Button';
import React from 'react';
import { BlockNames } from './PersonalInformation';
import Skeleton from 'components/ui/Skeleton';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { ReactComponent as DeleteIcon } from 'assets/trash-01.svg';
import { IStyleProps } from './Password';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Text from 'Text';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ProgressLoader from 'components/ui/ProgressLoader';

const getI18nKey = (label) => {
  const labelToKeyMap = {
    'First name': 'firstName',
    'Middle name': 'middleName',
    'Last name': 'lastName',
    Specialty: 'specialty',
    'Date of birth': 'dateOfBirth',
    Gender: 'gender',
    'Display name': 'displayName',
    Pronouns: 'pronouns',
    'Phone number': 'phoneNumber',
    'Email address': 'emailAddress',
    'Experience (in years)': 'experienceInYears',
    'Address 1': 'adresse1',
    'Address 2 (optional)': 'adresse2',
    State: 'state',
    'Zip code': 'zipCode',
    Street: 'street',
    'Apt, suite (optional)': 'Apt',
  };
  return labelToKeyMap[label] || label; // Fallback to label itself if no key is found
};

interface IInfoBlockProps {
  title: string;
  description?: string;
  content?: string;
  contentClassName?: string;
  extraContent?: string;
  isEditable: boolean;
  blockName: BlockNames;
  children: React.ReactElement;
  editableBlock: BlockNames | null;
  setEditableBlock: React.Dispatch<React.SetStateAction<BlockNames | null>>;
  loading?: boolean;
  isBorderBottom?: boolean;
  isDelete?: boolean;
  disabledButton?: boolean;
  buttonLoading?: boolean;
  showAddButtonInitially?: boolean;
  buttonClick?: () => void;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    borderBottom: ({ isBorderBottom }: { isBorderBottom: boolean }) =>
      isBorderBottom && '1px solid #D0D5DD',
    opacity: ({
      isEditable,
      editableBlock,
    }: {
      isEditable: boolean;
      editableBlock: BlockNames | null;
    }) => (!isEditable && editableBlock !== null ? 0.5 : 1),
    cursor: ({
      isEditable,
      editableBlock,
    }: {
      isEditable: boolean;
      editableBlock: BlockNames | null;
    }) => !isEditable && editableBlock !== null && 'not-allowed',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    color: '#101828',
    fontSize: '18px',
  },
  subtitle: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: '#475467',
  },
  deactivateBtn: {
    fontFamily: 'Inter, sans-serif',
    padding: '9px',
    color: 'white',
    backgroundColor: '#D92D20',
    borderRadius: '8px',
    border: '1px solid #D92D20',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    cursor: ({ editableBlock }: IStyleProps) => editableBlock && 'not-allowed',
    '&:hover': {
      backgroundColor: '#C81C10',
      border: '1px solid #C81C10',
    },
    '& svg': {
      marginRight: '0.4rem',
    },
  },
  textCheck: {
    fontSize: '16px',
    color: '#000000',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    justifyContent: 'flex-end',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  editTxt: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#101828',
    textTransform: 'capitalize',
    textUnderlineOffset: '2px',
  },
  btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  cancelDelete: {
    backgroundColor: 'transparent',
    border: '1px solid rgba(130, 130, 130, 1)',
    color: '#425364',
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '31px',
    height: '45px',
    width: '100%',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    borderRadius: '5px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(130, 130, 130, 1)',
    },
  },
  cancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    boxSizing: 'border-box',
    border: '1px solid #D0D5DD',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'capitalize',
    width: 'unset',
    height: 'unset',
    marginRight: theme.spacing(2),

    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  save: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    minWidth: '80px',
    maxHeight: '40px',
    minHeight: '40px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
    '&:disabled': {
      border: '1px solid #D0D5DD',
      backgroundColor: '#D0D5DD',
      color: 'white',
    },
  },
  delete: {
    backgroundColor: '#D92D20',
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '31px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    // minWidth: '94px',
    border: '1px solid #D92D20',
    borderRadius: '5px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    transition: 'all 0.3s',
    width: '100%',

    '&:hover': {
      backgroundColor: '#D92D20',
    },
    '&:disabled': {
      color: 'white',
      backgroundColor: '#D0D5DD',
      border: '1px solid #D0D5DD',
    },
  },
  childrenContainer: {
    marginTop: '1rem',
  },
  children: {},
}));

export const InfoBlock = ({
  title,
  description,
  content,
  extraContent,
  isEditable,
  blockName,
  children,
  editableBlock,
  setEditableBlock,
  loading,
  isBorderBottom = true,
  isDelete = false,
  disabledButton = false,
  contentClassName,
  showAddButtonInitially = false,
  buttonClick,
  buttonLoading = false,
}: IInfoBlockProps) => {
  const classes = useStyles({ isEditable, editableBlock, isBorderBottom });
  const [checked, setChecked] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Skeleton type="text" width="100px" height="20px" loading={loading}>
            <Typography
              className={clsx(
                classes.subtitle,
                !isEditable && contentClassName
              )}
            >
              {isEditable ? description : capitalizeFirstLetter(content) || '-'}
            </Typography>
          </Skeleton>
          <Skeleton
            type="text"
            width="100px"
            height="20px"
            loading={loading && extraContent !== undefined}
          >
            <Typography className={classes.subtitle}>
              {!isEditable && extraContent
                ? capitalizeFirstLetter(extraContent)
                : ''}
            </Typography>
          </Skeleton>
        </Box>
        {!isEditable ? (
          isDelete ? (
            <Button
              className={classes.deactivateBtn}
              onClick={() => setEditableBlock(blockName)}
              disabled={!isEditable && editableBlock !== null}
            >
              <DeleteIcon />
              {t('Delete')}
            </Button>
          ) : (
            <Skeleton type="text" width="30px" height="20px" loading={loading}>
              <Button
                className={classes.editBtn}
                onClick={() => {
                  setEditableBlock(blockName);
                  buttonClick();
                }}
                disabled={!isEditable && editableBlock !== null}
              >
                <Text
                  i18nKey={showAddButtonInitially ? 'Add' : 'edit'}
                  className={classes.editTxt}
                >
                  {showAddButtonInitially ? 'Add' : 'Edit'}
                </Text>
              </Button>
            </Skeleton>
          )
        ) : null}
      </Box>
      {isEditable ? (
        isDelete ? (
          <Box className={classes.childrenContainer}>
            <Box className={classes.children}>{children}</Box>
            <Box display="flex" alignItems="center" ml={-1} mt={3}>
              <Checkbox
                name="isPrepay"
                size="medium"
                defaultChecked={checked}
                onChange={() => setChecked((s) => !s)}
                checkedIcon={<CheckedIcon />}
                icon={<UncheckedIcon />}
                style={{ backgroundColor: 'transparent' }}
              />
              <Typography className={classes.textCheck}>
                {t('Permanently delete my account')}
              </Typography>
            </Box>
            <Box
              width="100%"
              mt={4}
              display={'flex'}
              flexDirection="column"
              style={{ gap: '20px' }}
            >
              <Button
                type="submit"
                buttonType="submit"
                data-cy="submit"
                className={classes.delete}
                disabled={!checked}
              >
                <DeleteIcon style={{ marginRight: '8px' }} />
                {t('Delete account')}
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setEditableBlock(null);
                }}
                buttonType="reset"
                type="cancel"
                className={classes.cancelDelete}
              >
                {t('Cancel')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className={classes.childrenContainer}>
            <Box className={classes.children}>{children}</Box>
            <Box className={classes.btns}>
              <Button
                className={classes.cancel}
                style={{ marginRight: '5px' }}
                onClick={(e) => {
                  e.preventDefault();
                  setEditableBlock(null);
                }}
                buttonType="reset"
                type="cancel"
              >
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                buttonType="submit"
                data-cy="submit"
                className={classes.save}
                disabled={disabledButton || buttonLoading}
              >
                {buttonLoading ? (
                  <ProgressLoader width={20} height={20} />
                ) : (
                  <Text i18nKey="save">Save</Text>
                )}
              </Button>
            </Box>
          </Box>
        )
      ) : null}
    </Box>
  );
};
