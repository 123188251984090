import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Text from 'Text';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import { useStyles } from './styles';
import TagsCell from './TagsCell';
import Switch from 'components/ui/SwitchNew';

export enum TemplateType {
  all = 'All',
  notes = 'Note-taking',
  intake = 'Intake',
  assessment = 'Assessment',
  other = 'Other',
}

interface IProps {
  hoveredRowIndex: string | null;
  handleOpenDropDown: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  handleEditFields: (row: any) => void;
  handleViewFields: (row: any) => void;
  type: TemplateType;
}

function Columns({
  hoveredRowIndex,
  handleEditFields,
  handleViewFields,
  handleOpenDropDown,
  type,
}: IProps) {
  const classes = useStyles();
  const columns = [
    {
      id: 'title',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="name" style={{ whiteSpace: 'noWrap' }}>
            Name
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 1000,
      format: (
        value,
        { name, title, isDefault, isAutoSent, isScored, type }
      ) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            style={{
              cursor: 'pointer',
              maxWidth: '400px', // Set max width here
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {title || name}
            </Typography>
            <Box pl={1} display="flex" alignItems="center">
              {isDefault && type === 'Note-taking' && (
                <Tooltip title="Default Template">
                  <Typography variant="caption" className={classes.type}>
                    Default
                  </Typography>
                </Tooltip>
              )}

              {isAutoSent && (
                <Tooltip title="Auto-sent">
                  <Typography variant="caption" className={classes.type}>
                    Auto-send
                  </Typography>
                </Tooltip>
              )}
              {isScored && (
                <Tooltip title="Scored template">
                  <Typography variant="caption" className={classes.type}>
                    Scored
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        );
      },
    },

    {
      id: 'tags',
      minWidth: 1000,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Tags" style={{ whiteSpace: 'noWrap' }}>
            Tags
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { tags }) => {
        return <TagsCell tags={tags} />;
      },
    },
    {
      id: 'lastUsed',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="lastUsed" style={{ whiteSpace: 'noWrap' }}>
            Last Used
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {value ?? '-'}
          </Typography>
        );
      },
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      format: (value, row) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={`${classes.actions} ${
              hoveredRowIndex === row?.id ? classes.actionsVisible : ''
            }`}
          >
            <Eye
              data-cy="view-action"
              onClick={(e) => handleViewFields(row)}
              style={{ cursor: 'pointer', marginRight: '1rem' }}
            />
            <EditIcon
              data-cy="edit-action"
              onClick={(e) => handleEditFields(row)}
              style={{ cursor: 'pointer', marginRight: '1rem' }}
            />
          </Box>
          <Box
            onClick={(e) => handleOpenDropDown(e, row)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ cursor: 'pointer', width: '18px' }}
          >
            <MoreIcon data-cy="more-action" />
          </Box>
        </Box>
      ),
    },
  ];
  const allColumns = [
    ...columns.slice(0, 1),
    {
      id: 'type',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="type" style={{ whiteSpace: 'noWrap' }}>
            Type
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {value ?? '-'}
          </Typography>
        );
      },
    },
    ...columns.slice(1),
  ];
  const intakeColumn = [
    ...columns.slice(0, 1),
    {
      id: 'isAutoSent',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Auto-send" style={{ whiteSpace: 'noWrap' }}>
            Auto-send
          </Text>
        </Box>
      ),
      align: 'center',
      format: (value) => {
        return <Switch defaultValue={value} defaultChecked={value} />;
      },
    },
    ...columns.slice(1),
  ];
  const getColumnType = () => {
    switch (type) {
      case TemplateType.all:
        return allColumns;
      case TemplateType.intake:
        return intakeColumn;
      default:
        return columns;
    }
  };

  return getColumnType();
}

export default Columns;
