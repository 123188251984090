import { makeStyles, Theme } from '@material-ui/core/styles';
import EggmedLogoSubscribe from 'assets/EggmedSubscribeLogo.svg';

const useStyles = makeStyles<Theme>((theme) => ({
  switchAccountAvatar: {
    width: '30px',
    height: '30px',
  },
  selectPlanBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 18px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    width: '100%',
    height: '48px',

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  pricing: {
    display: 'flex',
    alignItems: 'baseline',
  },
  currency: {
    fontWeight: 600,
    fontSize: '36px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  price: {
    fontWeight: 600,
    fontSize: '60px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  priceLabel: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  features: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  featureName: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
    marginLeft: '10px',
  },
  trialDate: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#878787',
    fontFamily: 'Inter',
  },
  trialValue: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  editbt: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#101828',
    fontFamily: 'Inter',
    textDecoration: 'underline',
  },
  billingDs: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  billingName: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#101828',
    fontFamily: 'Inter',
  },
  headerLabel: {
    fontWeight: 700,
    fontSize: '30px',
    color: 'white',
    fontFamily: 'Inter',
  },
  savesInfo: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#147AF3',
    fontFamily: 'Inter',
  },
  headerDesc: {
    fontWeight: 500,
    fontSize: '20px',
    color: 'white',
    fontFamily: 'Inter',
  },
  saveBox: {
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '52px',
    padding: '8px 40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  billingCard: {
    border: '1px solid #EAECF0',
    backgroundColor: 'white',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '16px',
  },
  billingType: {
    backgroundColor: '#CAE8FF',
    width: '293px',
    height: '56px',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
  },
  boxxx: {
    backgroundImage: `url(${EggmedLogoSubscribe})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    height: 'calc(100vh - 139px)',
  },

  activateBtn: {
    border: '1px solid #0265DC',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#0265DC',
    fontWeight: 600,
    fontSize: '16px',
    paddingInline: '10px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #0265DC',
    },
  },
  booksess: {
    borderRadius: '8px',
    padding: '6px 16px',
    color: '#0265DC',
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: 'white',
    },
  },
  agegroup: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    fontFamily: 'Inter',
  },
  rootRadio: {
    maxWidth: '22px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconPatient: {
    cursor: 'pointer',
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  root: {
    // marginLeft: '-1vw',
    height: '70px',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
    paddingRight: '26px',
    [theme.breakpoints.only('xl')]: {
      alignContent: 'center',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '7px solid #147AF3',
      backgroundColor: '#147AF3',
      borderColor: '#147AF3',
      borderStyle: 'solid',
      borderWidth: '7px',
      // paddingLeft: theme.spacing(6),
      // clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)',
    },
    [theme.breakpoints.only('lg')]: {
      alignContent: 'center',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '7px solid #147AF3',
      backgroundColor: '#147AF3',
      borderColor: '#147AF3',
      borderStyle: 'solid',
      borderWidth: '7px',
      // paddingLeft: theme.spacing(6),
      // clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)',
    },
    [theme.breakpoints.only('md')]: {
      alignContent: 'center',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '7px solid #147AF3',
      backgroundColor: '#147AF3',
      borderColor: '#147AF3',
      borderStyle: 'solid',
      borderWidth: '7px',
      paddingLeft: theme.spacing(6),
      clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%)',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '1.5vw',
      marginLeft: 0,
      alignContent: 'center',
      display: 'flex',
      alignItems: 'center',
      height: '125px',
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1.5vw',
      marginLeft: 0,
      alignContent: 'center',
      display: 'flex',
      alignItems: 'center',
      height: '125px',
    },
  },
  icons: {
    width: '2.4rem',
    height: '2.4rem',
    margin: '10px',
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'all 0.3s',
    [theme.breakpoints.only('xs')]: {
      margin: '2px',
    },
    '&:hover': {
      border: '2px solid #4266eb',
    },
  },
  profilePic: {
    margin: '10px 0 10px 10px',
  },
  marginSkeleton: {
    margin: '10px 0 10px 10px !important',
  },
  picOutline: {
    border: '2px solid #082CAF',
  },
  displayMeetingCard: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      marginRight: theme.spacing(2),
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      border: '1px solid #1C9A51',
    },
  },
  iconsSidebar: {
    width: '2.2rem',
    height: '2.2rem',
    margin: '10px',
    cursor: 'pointer',
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  list: {
    width: 0,
  },
  fullList: {
    width: 'auto',
  },
  iconsNotif: {
    width: '2rem',
    height: '2rem',
    cursor: 'pointer',
    display: 'block',
  },
  iconsSetting: {
    width: '2.2rem',
    height: '2.2rem',
    margin: '10px',
    cursor: 'pointer',
    display: 'none',
  },
  chip: {
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(10),
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.1),
    paddingLeft: theme.typography.pxToRem(5),
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  hoverElement: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      marginRight: theme.spacing(2),
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      border: '1px solid #41A8FF',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  doctorName: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: '14px',
  },
  btnNewAppointment: {
    backgroundColor: '#1F61DC',
    borderRadius: '99px',
    color: 'white',
    height: '46px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    [theme.breakpoints.only('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
  },
  locationTitle: {
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '2.75rem',
    fontWeight: 'bold',
    marginRight: '85px',
    [theme.breakpoints.only('sm')]: {
      fontSize: '2rem',
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
      display: 'none',
    },
  },
  rightHeader: {
    height: '100%',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    // position: 'absolute',
    // right: '1vw',
    // gap: theme.spacing(1),
  },
  menu: {
    borderRadius: '10px',
  },
  blueCalendar: {
    marginRight: '10px',
    '& path': {
      stroke: '#0265DC',
    },
  },
}));

export default useStyles;
