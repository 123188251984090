import React from 'react';
import useStyles from './styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ReactComponent as AlertIcon } from 'assets/Schedule/alert.svg';
import { ReactComponent as DropIcon } from 'assets/Schedule/dropdown.svg';
import { ReactComponent as CloseIcon } from 'assets/Schedule/close.svg';
import { ReactComponent as RemoveIcon } from 'assets/Schedule/remove.svg';
import { useForm } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import { useRate } from 'pages/AdminPage/Payment/useRate';
import { schema } from 'pages/AdminPage/Payment/Rates';
import { yupResolver } from '@hookform/resolvers';
import { SELECTED_RATE } from './SessionSelect';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ISelectProps } from './SessionSelect';

interface IAddDefaultSession {
  doctorId?: string;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setSession: (data: ISelectProps) => void;
}

function AddDefaultSession({
  doctorId,
  setDisplay,
  setSession,
}: IAddDefaultSession) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { register, control, errors, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  const commonProps = {
    control,
    errors,
    inputRef: register,
    variant: 'filled',
  };
  const { createRate, createLoading } = useRate();
  async function onSubmit() {
    const data = getValues();
    const { duration, rate, ...rest } = data || {};
    await createRate({
      variables: {
        rateInput: {
          ...rest,
          doctor: doctorId,
          duration: parseInt(duration, 10),
          rate: parseInt(rate, 10),
        },
      },
      refetchQueries: [
        { query: SELECTED_RATE, variables: { doctor: doctorId } },
      ],
    });
    setDisplay(false);
    setSession(data as ISelectProps);
  }
  return (
    <Box
      width="100%"
      className={classes.defaultSessionBox}
      style={{ backgroundColor: open && 'var(--Input-grey, #F8FAFB)' }}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.defaultSessionTitle}
      >
        <Box display="flex" alignItems="center">
          <AlertIcon style={{ marginRight: '5px' }} />
          <Typography className={classes.title}>
            Payment will be waived unless you{' '}
            <span className={classes.linkTitle}>
              add your default session rate
            </span>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            onClick={() => setOpen(!open)}
            boxSizing="border-box"
            borderRight="1px solid rgba(242, 153, 74, 0.40)"
            paddingRight="10px"
            marginRight="10px"
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
          >
            {open ? <CloseIcon /> : <DropIcon />}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            marginRight="5px"
            onClick={() => setDisplay(false)}
          >
            <RemoveIcon />
          </Box>
        </Box>
      </Box>
      {open && (
        <Box boxSizing="border-box" padding="18px" width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.sessionLabel}>Name</Typography>
              <Input
                type="text"
                name="session"
                style={{
                  height: '40px',
                }}
                inputProps={{
                  style: {
                    height: '40px',
                    padding: '0 14px',
                  },
                }}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.sessionLabel}>
                Session duration (minutes)
              </Typography>
              <Input
                type="select"
                name="duration"
                options={['15', '30', '45', '60']}
                className={classes.selectInput}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.sessionLabel}>
                Session currency
              </Typography>
              <Input
                name="currency"
                type="select"
                options={['USD', 'EUR']}
                className={classes.selectInput}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.sessionLabel}>
                Session rate
              </Typography>
              <Input
                type="number"
                name="rate"
                style={{
                  height: '40px',
                }}
                inputProps={{
                  style: {
                    height: '40px',
                    padding: '0 14px',
                  },
                }}
                {...commonProps}
              />
            </Grid>
          </Grid>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt="18px"
            style={{ gap: '10px' }}
          >
            <Button
              className={classes.cancelBtn}
              onClick={() => setDisplay(false)}
            >
              Cancel
            </Button>
            <Button
              className={classes.saveBtn}
              onClick={onSubmit}
              disabled={createLoading}
            >
              {createLoading ? (
                <ProgressLoader width={20} height={20} />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AddDefaultSession;
