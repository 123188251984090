import { gql, useMutation } from '@apollo/client';
import { GET_PATIENT_ACCESS } from './useGetPatientAccess';

const UPDATE_PATIENT_ACCESS = gql`
  mutation updatePermissionsToDoctor($accessInput: UpdateInput) {
    updatePermissionsToDoctor(accessInput: $accessInput) {
      _id
    }
  }
`;
interface IAccessInput {
  doctor: string;
  permissions: string[];
}
const useUpdatePermissionsToDoctor = () => {
  const [updatePermissions] = useMutation(UPDATE_PATIENT_ACCESS, {
    refetchQueries: [{ query: GET_PATIENT_ACCESS }],
  });
  async function handleUpdatePermissionsToDoctor(accessInput: IAccessInput) {
    try {
      return await updatePermissions({
        variables: { accessInput },
      });
    } catch (e) {
      return e;
    }
  }
  return handleUpdatePermissionsToDoctor;
};

export { UPDATE_PATIENT_ACCESS };
export default useUpdatePermissionsToDoctor;
