import React, { useRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Avatar,
  Typography,
  Divider,
} from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { dateFormatDependancy, hourFormat } from 'utils/dateUtils';
import useStyles from './styles';
import { ReactComponent as CalendarUpcoming } from 'assets/CalendarUpcoming.svg';
import { ReactComponent as PlusBook } from 'assets/PlusBook.svg';
import { ReactComponent as CalendarSession } from 'assets/CalendarSession.svg';
import { ReactComponent as ClockSession } from 'assets/ClockSession.svg';
import { ReactComponent as OnlineSession } from 'assets/OnlineSession.svg';
import { ReactComponent as CurrencySession } from 'assets/CurrencySession.svg';
import FullCalendar from '@fullcalendar/react';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import PaymentModal, {
  APPOINTMENT,
} from 'pages/PatientAppointmentPage/PaymentModal';
import BasicModal from 'components/ui/Modal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import CalendarDatePicker from 'pages/SchedulePage/CalendarDatePicker';
import AddEventPage from 'pages/PatientAppointmentPage/AddEventPage';
import { PhysicalInformation } from './PhysicalInformation';
import LatestContactedDoctors from './LatestContactedDoctors';
import { useHistory } from 'react-router-dom';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as SuccessPaymentLogo } from 'assets/illustrations/SuccessPaymentLogo.svg';
import Modals from 'pages/InboxPage/modals/index';
import { GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS } from 'pages/DashboardPage';
import dayjs from 'dayjs';
import { ReactComponent as VideoRoom } from 'assets/videoRoomIcon.svg';
import { ReactComponent as CancelSession } from 'assets/CancelSession.svg';
import { ReactComponent as RescheduleSession } from 'assets/RescheduleSession.svg';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptySession } from 'assets/emptyState/session.svg';
import UpcomingSessionSkeleton from './UpcomingSessionSkeleton';
import { changeRes, transformArrayTimeZone } from 'utils';
import Text from 'Text';
import { Trans, useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

const UpcomingSessionCard = ({
  upcomingSession,
  isLarge,
  loadingSessions,
  refetch,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loadingAddAppointment,
    loadingEditAppointment,
    handleOpenAddModal,
    handleCloseEditMode,
    editMode,
    handleUpdateAppointment,
    currentEvent,
    handleAddAppointment,
    handleEditCurrentEvent,
  } = useProvider();
  const { deleteAppointment } = useDeleteAppointment();
  React.useEffect(() => {
    if (upcomingSession) {
      refetch();
    }
  }, [upcomingSession, refetch]);
  const { patient } = useAuth();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  function handleModalClose() {
    setOpen(false);
  }
  const [selectedId, setSelectedId] = React.useState('');
  function handleOpen(id) {
    setSelectedId(id);
    setOpen(true);
  }
  async function handleDelete(id: string) {
    await deleteAppointment(id, true, null);
    handleModalClose();
    return;
  }
  const sessions = upcomingSession?.doctorUpcomingAppointments?.result;
  const timezone = sessions && sessions[0]?.patient?.timeZone;
  const upcomingSessions = transformArrayTimeZone(sessions, timezone);
  return (
    <Box
      className={classes.upcomingBox}
      px={3}
      width="100%"
      height="100%"
      boxSizing="border-box"
    >
      <Box py={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <CalendarUpcoming style={{ marginRight: '10px' }} />
            <Text i18nKey="upcomingSessions" className={classes.textTitle}>
              Upcoming sessions
            </Text>
          </Box>
          <Button
            className={classes.buttonBook}
            onClick={() => handleOpenAddModal()}
          >
            <PlusBook style={{ marginRight: '5px' }} />
            <Text
              style={{
                color: '#0265DC',
                fontWeight: 600,
                fontSize: '16px',
                textTransform: 'none',
              }}
              i18nKey="bookSession"
            >
              Book session
            </Text>
          </Button>
        </Box>
        <Box
          mt={6}
          display="flex"
          alignItems="center"
          style={{ gap: '40px' }}
          // justifyContent="center"
          // justifyContent="space-between"
        >
          {loadingSessions && [1, 2, 3].map(() => <UpcomingSessionSkeleton />)}
          {upcomingSession?.doctorUpcomingAppointments?.result?.length === 0 &&
            !loadingSessions && (
              <Box py="2rem" width="100%">
                <EmptyState
                  icon={<EmptySession />}
                  title={t("No sessions yet. Let's get started.")}
                  text={t('to schedule your first session.')}
                  onClick={() => handleOpenAddModal()}
                  isText
                />
              </Box>
            )}
          {upcomingSessions?.map((element, index) => (
            <>
              <Box flexDirection="column" display="flex">
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    flexDirection="column"
                    gridGap={5}
                  >
                    <Avatar
                      src={element?.doctor?.picture}
                      className={classes.picture}
                    >
                      {element?.doctor?.firstname &&
                        element?.doctor?.firstname[0]}{' '}
                      {element?.doctor?.lastname &&
                        element?.doctor?.lastname[0]}
                    </Avatar>
                    <Box>
                      <Typography className={classes.doctorLastName}>
                        {element?.doctor?.firstname}
                      </Typography>
                      <Typography className={classes.doctorLastName}>
                        {element?.doctor?.lastname}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" ml={2}>
                    <Box display="flex" alignItems="center" mb={0}>
                      <Box display="flex" alignItems="center" mr={1}>
                        <CalendarSession style={{ marginRight: '10px' }} />
                        <Typography className={classes.labelTxt}>
                          {dayjs(element?.startDate).format('MMM D')}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mr={1}>
                        <ClockSession style={{ marginRight: '10px' }} />
                        <Typography className={classes.labelTxt}>
                          {dateFormatDependancy(
                            element?.startDate,
                            patient?.formatDate
                            // element?.patient?.timeZone
                          )}{' '}
                          -{' '}
                          {dateFormatDependancy(
                            element?.endDate,
                            patient?.formatDate
                            // element?.patient?.timeZone
                          )}
                        </Typography>
                      </Box>{' '}
                    </Box>

                    <Box display="flex" alignItems="center" mb={0}>
                      {element?.locationLink ? (
                        <OnlineSession style={{ marginRight: '10px' }} />
                      ) : (
                        <InPersonAppt style={{ marginRight: '10px' }} />
                      )}
                      {element?.locationLink ? (
                        <Text
                          i18nKey="onlineSession"
                          className={classes.labelTxt}
                        >
                          {' '}
                          Online session
                        </Text>
                      ) : (
                        <Text
                          i18nKey="inPersonSession"
                          className={classes.labelTxt}
                        >
                          {' '}
                          In-person session
                        </Text>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <CurrencySession style={{ marginRight: '10px' }} />
                      <Typography className={classes.labelTxt}>
                        {element?.sessionType?.rate}{' '}
                        {element?.sessionType?.currency}{' '}
                      </Typography>
                      <Box ml={1}>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: chipColor[element?.isPaid]?.color,
                            backgroundColor:
                              chipColor[element?.isPaid]?.background,
                            border: chipColor[element?.isPaid]?.border,
                            padding: '5px 10px',
                            borderRadius: '16px',
                          }}
                        >
                          {capitalizeFirstLetter(t(element?.isPaid))}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column"></Box>
                </Box>
                <Box mt={2}>
                  <Button
                    className={classes.joinBtn}
                    onClick={() =>
                      history.push(
                        `/meeting/${element?.invitation?.invitation_id}`
                      )
                    }
                    style={{
                      // visibility: !element?.locationLink
                      //   ? 'hidden'
                      //   : 'visible',
                      display: !element?.locationLink && 'none',
                    }}
                  >
                    {' '}
                    <VideoRoom style={{ marginRight: '10px' }} />
                    <Text style={{ marginRight: '10px' }} i18nKey="join">
                      Join
                    </Text>
                  </Button>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                    style={{ gap: '10px' }}
                  >
                    <Button
                      className={classes.WhiteBtn}
                      onClick={() => handleOpen(element?._id)}
                    >
                      {' '}
                      <CancelSession
                        style={{ marginRight: '10px', minWidth: '20px' }}
                      />
                      <Text
                        style={{
                          color: '#344054',
                          textTransform: 'none',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                        i18nKey="cancel"
                      >
                        Cancel
                      </Text>
                    </Button>
                    <Button
                      className={classes.WhiteBtn}
                      onClick={() => handleEditCurrentEvent(element)}
                    >
                      {' '}
                      <RescheduleSession
                        style={{ marginRight: '10px', minWidth: '20px' }}
                      />
                      <Text
                        style={{
                          color: '#344054',
                          textTransform: 'none',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                        i18nKey="reschedule"
                      >
                        Reschedule
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Box>
              {((isLarge && (index === 0 || index === 1)) || index === 0) && (
                <Divider
                  orientation="vertical"
                  style={{ height: '200px', width: '1px' }}
                />
              )}
            </>
          ))}
        </Box>
      </Box>
      <AddEventPage
        closeModal={handleCloseEditMode}
        editMode={editMode}
        patientId={patient?._id}
        eventData={currentEvent}
        addEvent={editMode ? handleUpdateAppointment : handleAddAppointment}
        loadingAppointment={
          editMode ? loadingEditAppointment : loadingAddAppointment
        }
      />
      <BasicModal
        isRegularModal
        open={open}
        onClose={handleModalClose}
        handleClose={handleModalClose}
        className={classes.deleteModal}
      >
        <Box>
          <DeleteModalContent
            text="session"
            handleClose={handleModalClose}
            handleDelete={() => handleDelete(selectedId)}
          />
        </Box>
      </BasicModal>
    </Box>
  );
};

export default UpcomingSessionCard;
