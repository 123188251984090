import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';

const SEND_RTC_ICE_CANDIDATE_MUTATION = gql`
  mutation SendRtcIceCandidate(
    $recipientId: String!
    $candidate: String!
    $sdpMid: String!
    $sdpMLineIndex: Int!
    $roomId: String!
  ) {
    sendRtcIceCandidate(
      args: {
        iceCandidate: {
          candidate: $candidate
          sdpMid: $sdpMid
          sdpMLineIndex: $sdpMLineIndex
        }
        recipientId: $recipientId
      }
      roomId: $roomId
    ) {
      success
    }
  }
`;

const useSendRtcIceCandidate = () => {
  const [sendRtcIceCandidate] = useMutation<unknown, any>(
    SEND_RTC_ICE_CANDIDATE_MUTATION
  );

  async function handleSendRtcIceCandidate(
    candidate: any,
    sdpMid: any,
    sdpMLineIndex: any,
    recipientId: string,
    roomId: string
  ) {
    try {
      return await sendRtcIceCandidate({
        variables: { candidate, sdpMLineIndex, sdpMid, recipientId, roomId },
      });
    } catch (e) {
      log('error', 'Error while sending rtc ice candidate', e);
      return e;
    }
  }

  return handleSendRtcIceCandidate;
};

export default useSendRtcIceCandidate;
