import React, { ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import PatientGeneralInformations from './PatientGeneralInfo';
import useModal from 'hooks/useModal';
import { HistoryTabProvider } from 'pages/PatientTabHistory/HistoryTabContext';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import PatientActivities from 'pages/PatientActivities';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
import TaskPageProvider from 'pages/Taskpage/state/Provider';
import TaskPage from 'pages/Taskpage/TaskPage';
import PatientConsultations from 'pages/PatientConsultations';
import WrapperComponent from './WrapperComponent';
import PatientConditions from 'pages/PatientConditions';
import PatientAllergies from 'pages/PatientAllergies';
import PatientHistory from 'pages/PatientTabHistory';
import PatientImmunization from 'pages/PatientImmunization';
import PatientDrugs from 'pages/PatientDrugs';
import PatientLabs from 'pages/PatientLabs';
import DoctorInbox from 'pages/InboxPage/DoctorInbox';
import FinancePage from 'pages/DoctorFinancePage/index';
import SettingTab from './SettingTab';
import { WrapperContent } from './WrapperContent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}
interface IvalueProps {
  value: string | number;
}
export interface IPatientProps {
  patientId?: string;
  isPatient?: boolean;
}
const useStyles = makeStyles((theme) => ({
  mobileButton: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      right: ({ value }: IvalueProps) =>
        value === 0 || value == 3 ? '1.25rem' : '1.25rem',
      top: '1.75rem',
    },
  },
}));

export const tabs = {
  general: 0,
  activities: 1,
  appointments: 2,
  tasks: 3,
  records: 4,
  billing: 5,
  settings: 6,
  message: 7,
};

export function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;
  const { dispatch } = useModal();
  const classes = useStyles({ value });
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      style={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
interface IPatientTabsContentProps {
  value: number | string;
  isPatient: boolean;
  patientId?: string;
}

export default function PatientTabsContent({
  value,
  isPatient,
  patientId,
}: IPatientTabsContentProps): ReactElement {
  const {
    doctor: { _id: doctorId },
  } = useAuth();
  return (
    <HistoryTabProvider initialState={{ open: false, currentTab: 0 }}>
      <>
        <TabPanel value={value} index={0}>
          <WrapperContent patientId={patientId}>
            <PatientGeneralInformations
              isPatient={isPatient}
              generalInformation
              patientId={patientId}
            />
          </WrapperContent>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InboxPageProvider>
            <WrapperContent patientId={patientId}>
              <PatientActivities patientId={patientId} doctorId={doctorId} />
            </WrapperContent>
          </InboxPageProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InboxPageProvider>
            <WrapperContent patientId={patientId}>
              <PatientConsultations patientId={patientId} doctorId={doctorId} />
            </WrapperContent>
          </InboxPageProvider>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TaskPageProvider isAppt={true} patientParam={patientId}>
            <WrapperContent patientId={patientId}>
              <TaskPage />
            </WrapperContent>
          </TaskPageProvider>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <WrapperComponent patientId={patientId} isPatient={isPatient}>
            <WrapperContent patientId={patientId}>
              <>
                <PatientConditions patientId={patientId} />
                {/* <PatientAllergies patientId={patientId} /> */}
                {/* <PatientHistory patientId={patientId} /> */}
                {/* <PatientLabs patientId={patientId} /> */}
                <PatientDrugs patientId={patientId} />
                {/* <PatientImmunization patientId={patientId} /> */}
              </>
            </WrapperContent>
          </WrapperComponent>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <WrapperContent patientId={patientId}>
            <FinancePage isAll={false} patientId={patientId} />
          </WrapperContent>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <WrapperContent patientId={patientId}>
            <SettingTab patientId={patientId} />
          </WrapperContent>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <InboxPageProvider userPatient={patientId}>
            <WrapperContent patientId={patientId}>
              <DoctorInbox />
            </WrapperContent>
          </InboxPageProvider>
        </TabPanel>
      </>
    </HistoryTabProvider>
  );
}

TabPanel.defaultProps = {
  children: <div />,
};
