import { Box, Button, Typography, InputBase } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as HeadingIcon } from 'assets/form/blocks/heading.svg';
import { ReactComponent as ParagraphIcon } from 'assets/form/blocks/paragraph.svg';
import { ReactComponent as QuoteIcon } from 'assets/form/blocks/quote.svg';
import { ReactComponent as BulletlistIcon } from 'assets/form/blocks/bullet-list.svg';
import { ReactComponent as NumberedlistIcon } from 'assets/form/blocks/numbered-list.svg';
import { ReactComponent as TableIcon } from 'assets/form/blocks/table.svg';
import { ReactComponent as ImageIcon } from 'assets/form/blocks/image.svg';
import { ReactComponent as HorizontalLineIcon } from 'assets/form/blocks/horizontal-line.svg';
import { ReactComponent as PageBreakIcon } from 'assets/form/blocks/page-break.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/form/blocks/multiple-choice.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/form/blocks/single-choice.svg';
import { ReactComponent as DropdownIcon } from 'assets/form/blocks/dropdown.svg';
import { ReactComponent as DateIcon } from 'assets/form/blocks/date.svg';
import { ReactComponent as DateRangeIcon } from 'assets/form/blocks/date-range.svg';
import { ReactComponent as ShortTextIcon } from 'assets/form/blocks/short-text.svg';
import { ReactComponent as LongTextIcon } from 'assets/form/blocks/long-text.svg';
import { ReactComponent as SignatureIcon } from 'assets/form/blocks/signature.svg';
import { ReactComponent as AddressIcon } from 'assets/form/blocks/address.svg';
import { ReactComponent as EmailIcon } from 'assets/form/blocks/email.svg';
import { ReactComponent as PhoneNumberIcon } from 'assets/form/blocks/phone.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/form/blocks/linear-scale.svg';
import { useEditorContext } from '../EditorContextProvider';
import useStyles from './styles';

const Element = ({ name, icon, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.element} onClick={onClick}>
      <Typography className={classes.elementName}>{name}</Typography>
      <Box style={{ marginLeft: '10px' }}>{icon}</Box>
    </Button>
  );
};

//------------------------------------------------------------------------------\

const sections = [
  {
    title: 'Display text',
    elements: [
      {
        name: 'Heading 1',
        icon: <HeadingIcon />,
        blockName: 'header',
        level: 1,
      },
      {
        name: 'Heading 2',
        icon: <HeadingIcon />,
        blockName: 'header',
        level: 2,
      },
      {
        name: 'Heading 3',
        icon: <HeadingIcon />,
        blockName: 'header',
        level: 3,
      },
      { name: 'Paragraph', icon: <ParagraphIcon />, blockName: 'paragraph' },
      { name: 'Quote', icon: <QuoteIcon />, blockName: 'quote' },
      {
        name: 'Bullet list',
        icon: <BulletlistIcon />,
        blockName: 'list',
      },
      {
        name: 'Numbered list',
        icon: <NumberedlistIcon />,
        blockName: 'Orderedlist',
      },
    ],
  },
  {
    title: 'Layout & media',
    elements: [
      { name: 'Table', icon: <TableIcon />, blockName: 'table' },
      { name: 'Image', icon: <ImageIcon />, blockName: 'image' },
      {
        name: 'Horizontal line',
        icon: <HorizontalLineIcon />,
        blockName: 'hr',
      },
      { name: 'Page break', icon: <PageBreakIcon />, blockName: 'page' },
    ],
  },
  {
    title: 'Input fields',
    elements: [
      {
        name: 'Multiple choice',
        icon: <MultipleChoiceIcon />,
        blockName: 'Multiple_choice',
      },
      {
        name: 'Single choice',
        icon: <SingleChoiceIcon />,
        blockName: 'Single_choice',
      },
      {
        name: 'Linear scale',
        icon: <LinearScaleIcon />,
        blockName: 'LineaireScale',
      },
      { name: 'Dropdown', icon: <DropdownIcon />, blockName: 'DropDown' },
      { name: 'Date', icon: <DateIcon />, blockName: 'Date' },
      { name: 'Date range', icon: <DateRangeIcon />, blockName: 'DateRange' },
      {
        name: 'Short text',
        icon: <ShortTextIcon />,
        blockName: 'Short_answer',
      },
      { name: 'Long text', icon: <LongTextIcon />, blockName: 'Long_answer' },
      { name: 'Signature', icon: <SignatureIcon />, blockName: 'Signature' },
    ],
  },
  {
    title: 'Contact information',
    elements: [
      { name: 'Email', icon: <EmailIcon />, blockName: 'Email' },
      {
        name: 'Phone number',
        icon: <PhoneNumberIcon />,
        blockName: 'Phone',
      },
      { name: 'Address', icon: <AddressIcon />, blockName: 'Address' },
    ],
  },
];

//------------------------------------------------------------------------------\

export const BlocksPicker = () => {
  const classes = useStyles();
  const [filterText, setFilterText] = useState('');
  const { addBlock } = useEditorContext();

  const filteredSections = sections
    .map((section) => ({
      ...section,
      elements: section.elements.filter((element) =>
        element.name.toLowerCase().includes(filterText.toLowerCase())
      ),
    }))
    .filter((section) => section.elements.length > 0);

  return (
    <Box className={classes.bpRoot}>
      <InputBase
        className={classes.inputBase}
        placeholder="Search elements"
        classes={{ root: classes.input }}
        inputProps={{ 'aria-label': 'search elements' }}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <Box className={classes.sections}>
        {filteredSections.map((section, index) => (
          <Box className={classes.section} key={index}>
            <Typography className={classes.bpTitle} variant="h6">
              {section.title?.toUpperCase()}
            </Typography>
            <Box className={classes.elements}>
              {section.elements.map((element, i) => (
                <Element
                  key={i}
                  name={element.name}
                  icon={element.icon}
                  onClick={() => {
                    addBlock(element?.blockName, element.level);
                  }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
