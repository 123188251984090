import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Box, Typography, Button, Theme } from '@material-ui/core';
import VideoActions from 'components/VideoActions';
import MediaDevice from 'utils/Media/MediaDevice';
import Text from '../../Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme>((theme) => ({
  setupText: {
    position: 'absolute',
    top: '10px',
    left: '20px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#EAECF0',
    margin: 0,
  },
  videoContainer: {
    width: '100%',
    height: ({ isDoctor }: { isDoctor: boolean }) =>
      isDoctor ? '100%' : '100%',
    borderRadius: '8px',
    backgroundColor: '#101828',
    objectFit: 'cover',
  },
  infoContainer: {
    width: ({ isDoctor }: { isDoctor: boolean }) =>
      isDoctor ? '100%' : '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: ({ isDoctor }: { isDoctor: boolean }) =>
      isDoctor && '#101828',
    borderRadius: '20px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  blueText: {
    color: '#425364',
  },
  logo: {
    width: 150,
    margin: '20px',
  },
  informationText: {
    position: 'absolute',
    color: 'white',
    top: '45%',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      left: '35%',
    },
    [theme.breakpoints.up('md')]: {
      left: '45%',
    },
    [theme.breakpoints.only('xs')]: {
      left: '30%',
    },
    [theme.breakpoints.only('lg')]: {
      left: ({ isDoctor }: { isDoctor: boolean }) => (isDoctor ? '40%' : '33%'),
    },
    [theme.breakpoints.only('xl')]: {
      left: ({ isDoctor }: { isDoctor: boolean }) => (isDoctor ? '40%' : '37%'),
    },
  },
  actionWrapper: {
    position: 'absolute',
    color: 'white',
    top: '45%',
    left: '16%',
    backgroundColor: '#2DC76D',
  },
}));

interface IMeetingTestingProps {
  setTestingDone?: (done: boolean) => void;
  isStarted?: boolean;
  showButton?: boolean;
  isDoctor?: boolean;
}

export default function MeetingTesting({
  setTestingDone,
  isDoctor = false,
  isStarted = false,
  showButton = false,
}: IMeetingTestingProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles({ isDoctor });
  const videoLocalRef = useRef<any>();
  const [isStarting, setStart] = useState(false);
  const [mute, setMute] = useState(false);
  const [videoOff, setVideoOff] = useState(false);

  const mediaDevice = useRef<any>();

  function onLocalStreamReceived(stream: any) {
    if (stream) {
      videoLocalRef.current.srcObject = stream;
    }
  }

  function toggleVideo() {
    setVideoOff(!videoOff);
    mediaDevice.current.toggle('Video');
  }
  function toggleMute() {
    setMute(!mute);

    mediaDevice.current.toggle('Audio');
  }

  function stopTheCameraDeps() {
    if (mediaDevice.current) {
      mediaDevice.current.stop(() => {
        mediaDevice.current = null;
        videoLocalRef.current = null;
        mediaDevice.current.stream = null;
      });
      mediaDevice.current.stream = null;
    }
  }

  useEffect(() => {
    if (!mediaDevice.current) {
      mediaDevice.current = new MediaDevice();
      mediaDevice.current.start(onLocalStreamReceived);
    }
    setTestingDone && setTestingDone(true);
    return () => {
      stopTheCameraDeps();
    };
  }, []);

  return (
    <Box className={classes.infoContainer}>
      {isDoctor && <p className={classes.setupText}>{t('cameraSetup')}</p>}
      <div
        style={{
          transform: 'scaleX(-1)',
          width: !isDoctor ? '100%' : '95%',
          height: !isDoctor ? '100%' : '60%',
          margin: isDoctor && '10% auto 0',
        }}
      >
        <video
          playsInline
          autoPlay
          ref={videoLocalRef}
          className={classes.videoContainer}
          muted
        />
      </div>
      {!isStarting && showButton && (
        <Button
          onClick={() => {
            setTestingDone && setTestingDone(true);
            setStart(true);
          }}
          data-cy="start-testing-button"
          className={classes.actionWrapper}
        >
          Start testing
        </Button>
      )}

      {!isStarting && !isStarted && (
        <Text i18nKey="cameraDesactivated" className={classes.informationText}>
          Camera Deactivated
        </Text>
      )}
      {isStarting ||
        (isStarted && (
          <VideoActions
            videoOff={videoOff}
            mute={mute}
            handleChangeMute={toggleMute}
            setVideoOff={toggleVideo}
            isDoctor={isDoctor}
            isPreview={true}
          />
        ))}
    </Box>
  );
}

MeetingTesting.defaultProps = {
  isStarted: false,
  showButton: false,
  setTestingDone: null,
};
