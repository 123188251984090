import { Box, Typography } from '@material-ui/core';
import React from 'react';

export default function ChatMessage({ messageText, messageTime, from }: any) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={from === 'external' ? 'flex-start' : 'flex-end'}
    >
      <Typography style={{ color: 'white' }}>{messageTime}</Typography>
      <Box
        alignItems="right"
        p={1}
        my={1}
        borderRadius="10px"
        bgcolor={from === 'external' ? '#373E4E' : 'hsla(224, 16%, 18%, 1)'}
      >
        <Typography style={{ color: 'white' }}>{messageText}</Typography>
      </Box>
    </Box>
  );
}
