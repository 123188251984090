import { gql, useQuery, useLazyQuery } from '@apollo/client';

const GET_THREAD_MESSAGES = gql`
  query GetThreadMessages($ThreadConversationID: ID!, $limit: Int, $skip: Int) {
    getThreadMessages(
      ThreadConversationID: $ThreadConversationID
      limit: $limit
      skip: $skip
    ) {
      _id
      user {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      parentMessageId
      content {
        text
        files {
          _id
          url
          fileName
          mimeType
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const useLazyGetThreadMessages = () => {
  const lazy = useLazyQuery(GET_THREAD_MESSAGES);
  return lazy;
};

const useGetThreadMessages = (
  threadConversationID: string,
  limit?: number,
  skip?: number
) => {
  const { data, loading, error } = useQuery(GET_THREAD_MESSAGES, {
    variables: {
      ThreadConversationID: threadConversationID,
      limit,
      skip,
    },
    fetchPolicy: 'cache-and-network',
  });
  return { data, loading, error };
};

export { GET_THREAD_MESSAGES, useLazyGetThreadMessages };
export default useGetThreadMessages;
