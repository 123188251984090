import React, { useRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Avatar,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { hourFormat } from 'utils/dateUtils';
import useStyles from './styles';
import FullCalendar from '@fullcalendar/react';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import PaymentModal, {
  APPOINTMENT,
} from 'pages/PatientAppointmentPage/PaymentModal';
import BasicModal from 'components/ui/Modal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import CalendarDatePicker from 'pages/SchedulePage/CalendarDatePicker';
import AddEventPage from 'pages/PatientAppointmentPage/AddEventPage';
import { PhysicalInformation } from './PhysicalInformation';
import LatestContactedDoctors from './LatestContactedDoctors';
import { useHistory } from 'react-router-dom';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as SuccessPaymentLogo } from 'assets/illustrations/SuccessPaymentLogo.svg';
import Modals from 'pages/InboxPage/modals/index';
import { GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS } from 'pages/DashboardPage';
import dayjs from 'dayjs';
import UpcomingSessionCard from './UpcomingSessionCard';
import RecentActivities from './RecentActivities';
import ProviderCard from './ProviderCard';
import RecentMessages from './RecentMessages';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import { useTranslation } from 'react-i18next';

const PatientHome = () => {
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Home') });
  }, [headerTitleDispatcher]);
  const calendarRef = useRef<FullCalendar | null>(null);
  const classes = useStyles();
  const history = useHistory();
  const { patient } = useAuth();
  const patientId = patient?._id;
  const { data: doctors, loading: loadingDoctors } = useGetDoctors();

  const [open, setOpen] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  function handleClose() {
    setOpenSuccess(false);
  }
  function closeEditModal() {
    setOpen(false);
  }
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('appointmentId');

  enum RedirectStatus {
    SUCCEEDED = 'succeeded',
  }
  React.useEffect(() => {
    if (id) {
      setOpen(true);
    }
  }, [id]);
  const redirectStatus = queryParams.get('redirect_status');
  useEffect(() => {
    if (redirectStatus === RedirectStatus.SUCCEEDED) {
      setOpen(false);
      setOpenSuccess(true);
      history.replace(history.location.pathname);
    }
  }, [redirectStatus, openSuccess]);

  useEffect(() => {
    let timeout;
    if (openSuccess) {
      timeout = setTimeout(() => {
        setOpenSuccess(false);
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [openSuccess]);

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.only('xl'), {
    defaultMatches: true,
  });
  const {
    data: upcomingSession,
    loading: loadingSessions,
    refetch,
  } = useQuery(GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS, {
    variables: {
      filter: { limit: isLarge ? 3 : 2, skip: 0 },
    },
  });
  return (
    <Box className={classes.flexColumn}>
      <Box className={classes.flexRow}>
        <UpcomingSessionCard
          isLarge={isLarge}
          upcomingSession={upcomingSession}
          loadingSessions={loadingSessions}
          refetch={refetch}
        />
        <Box className={classes.leftCard}>
          <ProviderCard doctors={doctors} loadingDoctors={loadingDoctors} />
        </Box>
      </Box>
      <Box className={classes.flexRow}>
        <RecentActivities
          isLarge={isLarge}
          patientId={patientId}
          doctors={doctors}
        />
        <Box className={classes.leftCard}>
          <RecentMessages />
        </Box>
      </Box>
    </Box>
  );
};

export const PATIENT_APPOINTMENT = gql`
  query patientAppointmentByDate(
    $patientId: ID!
    $date: String
    $filter: Filter
  ) {
    patientAppointmentByDate(date: $date) {
      startDate
      endDate
      description
      title
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      patient {
        firstname
        middlename
        lastname
        picture
        email
        phone
        _id
      }
      doctor {
        firstname
        middlename
        lastname
        picture
        email
        phone
        _id
      }
      doctorGoing
      _id
    }
    prescriptions(patientId: $patientId, filter: $filter) {
      prescriptions {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          durationType
          frequency
          advice
          expireAt
          _id
        }
        createdAt
        prescriptionUri
      }
      current {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          frequency
          advice
          expireAt
        }
        createdAt
        prescriptionUri
      }
      previous {
        _id
        doctor {
          firstname
          lastname
          picture
        }
        drugs {
          name
          dosage
          type
          duration
          frequency
          advice
          expireAt
        }
        createdAt
        prescriptionUri
      }
    }
  }
`;

export default PatientHome;
