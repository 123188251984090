import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './styles';
import Response from './Response';
import { IQuestion } from './FormsPage';
import Switch from 'components/ui/Switch';
import { ReactComponent as EditIcon } from 'assets/editIcon.svg';

function QuestionPreview({
  question,
  index,
  setFocusedQuestionIndex,
  isFastPreview = false,
  isNotes = false,
  ignoreRequired = false,
  handleRequired,
  handleInputChange,
  handleAddress,
  handleCheckboxChange,
  viewMode,
  editMode = true,
  noMargin = false,
}: {
  question: IQuestion;
  index?: number;
  setFocusedQuestionIndex?: React.Dispatch<React.SetStateAction<number>>;
  isFastPreview?: boolean;
  isNotes?: boolean;
  ignoreRequired?: boolean;
  handleRequired?: (required: boolean) => void;
  handleInputChange?: (index: number, value: string) => void;
  handleCheckboxChange?: (index: number, value: string) => void;
  handleAddress?: (index: number, value: string, field: string) => void;
  viewMode?: boolean;
  editMode: boolean;
  noMargin?: boolean;
}): React.ReactElement {
  const classes = useStyles({ editMode, noMargin });
  return (
    <Box
      width="100%"
      className={classes.PreviewCardBox}
      border={isFastPreview ? '1px solid #E0E0E0' : 'none'}
      onClick={() => {
        setFocusedQuestionIndex && setFocusedQuestionIndex(index);
      }}
    >
      {isFastPreview && (
        <Box
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EditIcon />
        </Box>
      )}
      {typeof question?.question === 'string' && (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="95%"
          mb={isNotes ? 2 : 0}
        >
          <Typography className={classes.QuestionTitle}>
            {question?.question[0]?.toUpperCase() +
              question?.question
                ?.slice(1, question.question.length)
                .toLowerCase()}
          </Typography>
        </Box>
      )}
      {viewMode ? (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          style={{ gap: '5px' }}
        >
          {question?.responses?.map((res, index) => (
            <>
              <Typography className={classes.Response}>
                {index < question.responses.length - 1 ? ` ${res}, ` : res}
              </Typography>
            </>
          ))}
        </Box>
      ) : (
        <Response
          question={question}
          isFastPreview={isFastPreview}
          isNotes={isNotes}
          index={index}
          handleInputChange={handleInputChange}
          handleCheckboxChange={handleCheckboxChange}
          handleAddress={handleAddress}
          noMargin={noMargin}
        />
      )}
    </Box>
  );
}

export default QuestionPreview;
