import { useQuery } from "@apollo/client";
import { GET_APPOINTMENT } from "../../queries/appointment";

interface IUseAppointmentsArgs {
  appointmentId: string;
}

export default function useGetAppointment({
  appointmentId,
}: IUseAppointmentsArgs) {
  const { data, error, loading } = useQuery(GET_APPOINTMENT, {
    variables: { appointmentId },
  });
  return { data, error, loading };
}
