/*eslint-disable*/
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { log } from 'config/log';
import { IS_LOGGED_IN } from './queries/auth';
import { generateKeyPair } from 'utils/tweetnacl';

export const errorCodes = {
  INACTIVE: 'INACTIVE_USER',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

const randomNumber = Math.random().toString();
const keyPairs = generateKeyPair();

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('auth');
  localStorage.removeItem('connected');
}

const userId = sessionStorage.getItem('meetId') || randomNumber;
const token = localStorage.getItem('token');
log('Env:', process.env.NODE_ENV);
log('[Eggmed Dashboard TEST]');
const webSocketUrl =
  process.env.REACT_APP_SUBSCRIPTION_URL || 'ws://localhost:8000/graphql';

const graphqlEndpoint =
  process.env.REACT_APP_API_URL || 'http://localhost:8000/graphql';

const wsLink = new GraphQLWsLink(
  createClient({
    url: webSocketUrl,
    connectionParams: {
      meetId: userId,
      token,
      publicKey: JSON.stringify(keyPairs.publicKey),
    },
    keepAlive: 30000,
  })
);
const uploadLink = createUploadLink({
  uri: graphqlEndpoint,
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((graphqlError): void => {
      const { message, locations, path } = graphqlError;
      if (
        graphqlError?.extensions?.code === errorCodes.UNAUTHENTICATED ||
        message === 'No Doctor with that id'
      ) {
        logout();
        window.location.href = '/login';
      }

      if (graphqlError?.extensions.code === errorCodes.INACTIVE) {
        logout();
        window.location.href = '/login?inactif=true';
      }

      log(
        'error',
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) log('error', `[Network error]: ${networkError}`);
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Eggmed: 'eggmed-rules',
      authorization: token ? `Bearer ${token}` : undefined,
      publicKey: JSON.stringify(keyPairs.publicKey),
    },
  };
});
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: {
          read() {
            return localStorage.getItem('token');
          },
        },
        patients: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        appointments: {
          merge: false,
        },
      },
    },
  },
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(errorLink.concat(uploadLink as any) as any) as any
);
const client = new ApolloClient({
  link: splitLink as any,
  connectToDevTools: true,
  cache,
});
cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem('token'),
  },
});
export { cache, keyPairs };
export default client;
