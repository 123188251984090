import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import Button from 'components/ui/Button';
import { ReactComponent as ArchiveIcon } from 'assets/icons/green/archiveIcon.svg';
import { useProvider } from 'pages/InboxPage/state/Provider';
import concatName from 'utils/userUtils';
import useStyles from './style';
import { ReactComponent as InfoIcon } from 'assets/infoIcon.svg';
import { ReactComponent as GreyArchiveIcon } from 'assets/archive.svg';
import { ReactComponent as FileICon } from 'assets/file.svg';
import { ReactComponent as ReturnIcon } from 'assets/returnArrow.svg';
import Popover from '@material-ui/core/Popover';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { ReactComponent as GroupChatIcon } from 'assets/GroupChatIcon.svg';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
interface IHeadMessageListProps {
  setFilesHidden: (value: boolean) => void;
  patient?: boolean;
}

const HeadMessageList: React.FC<IHeadMessageListProps> = ({
  setFilesHidden,
  patient,
}) => {
  const { user } = useAuth();
  const classes = useStyles({ patient });
  const [open, setOpen] = React.useState(false);
  function handleCloseModal() {
    setOpen(false);
  }
  function handleOpenModal() {
    setOpen(true);
  }
  const {
    useLazyGetThreadConversationById,
    selected,
    handelUpdateIsArchivedThreadConversation,
    setMessageHidden,
    handleSelectedThead,
  } = useProvider();
  const [getThreadById, { data }] = useLazyGetThreadConversationById();
  useEffect(() => {
    if (selected) {
      getThreadById({
        variables: {
          threadConversationID: selected,
        },
      });
    }
  }, [selected]);
  const participants = data?.getThreadConvertationById?.participants;
  const users = participants
    ?.map(({ user }) => user)
    .filter((el) => el?._id !== (user?._id || user?.user?._id));

  const handelArchivedStatus = async (
    selectedThread: string | undefined,
    Status: boolean
  ) => {
    if (selectedThread) {
      await handelUpdateIsArchivedThreadConversation(selectedThread, Status);
      handleSelectedThead(null);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlefileClick = () => {
    setFilesHidden(false);
    handleClose();
  };
  const handlemessageClick = () => {
    setMessageHidden(true);
  };
  const { t } = useTranslation();
  return (
    <Grid container className={classes.HeadMessageList}>
      <Grid container xl={9} lg={9} md={9} xs={10} sm={10} alignItems="center">
        <Grid item xs={1} sm={1}>
          <Box onClick={handlemessageClick}>
            <ReturnIcon className={classes.returnIcon} />
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={11} sm={11}>
          <Box display="flex" alignItems="center">
            <GroupChatIcon />
            <Box ml={2} display="flex" flexDirection="column">
              <Typography className={classes.HeadMessageListSenderName}>
                {data?.getThreadConvertationById &&
                  data?.getThreadConvertationById.subject}
              </Typography>
              <Box display="flex" alignItems="center">
                {users?.map((el) => (
                  <Typography
                    className={classes.HeadMessageListSubject}
                    style={{ marginRight: '2px' }}
                  >
                    {concatName(el?.firstname, el?.middlename, el?.lastname)},
                  </Typography>
                ))}
                <Text i18nKey="you" className={classes.HeadMessageListSubject}>
                  You
                </Text>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        xs={2}
        sm={2}
        className={classes.HeadMessageListBtnContainer}
      >
        <Box onClick={handleClick}>
          <InfoIcon className={classes.infoBtn} />
        </Box>
        {selected && (
          <Button
            variant="outlined"
            startIcon={<ArchiveIcon />}
            className={classes.ArchiveBtn}
            onClick={handleOpenModal}
          >
            {data?.getThreadConvertationById.isArchived
              ? t('Unarchive')
              : t('Archive')}
          </Button>
        )}
      </Grid>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={false}
      >
        <Box
          display="flex"
          className={classes.popupitems}
          onClick={handlefileClick}
        >
          <FileICon />
          <Typography className={classes.popupTitle}>Shared files</Typography>
        </Box>
        {selected && (
          <Box
            display="flex"
            className={classes.popupitems}
            onClick={() =>
              handelArchivedStatus(
                selected,
                !data?.getThreadConvertationById.isArchived
              )
            }
          >
            <GreyArchiveIcon />
            <Typography className={classes.popupTitle}>
              {data?.getThreadConvertationById.isArchived
                ? 'Unarchive'
                : 'Archive'}
            </Typography>
          </Box>
        )}
      </Popover>
      <ModalDelete
        text="conversation"
        open={open}
        message={
          data?.getThreadConvertationById.isArchived ? 'unarchive' : 'archive'
        }
        onClose={handleCloseModal}
        onDelete={() => {
          handelArchivedStatus(
            selected,
            !data?.getThreadConvertationById.isArchived
          );
          handleCloseModal();
        }}
      />
    </Grid>
  );
};

export default HeadMessageList;
