import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import React from 'react';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as SkipIcon } from '../../../assets/onboarding/x-square.svg';

interface ISkipTourProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    [theme.breakpoints.only('xl')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '50vw',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    boxSizing: 'border-box',
    paddingInline: '0.8rem',
    marginTop: '30px',
  },
  deleteButton: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    minWidth: '80px',
    minHeight: '40px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#C81C10',
      boxShadow: 'unset',
    },
  },
  cancelButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid #D0D5DD',
    color: '#344054',
    backgroundColor: 'transparent',
    minWidth: '80px',
    minHeight: '40px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#101828',
  },
  desc: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#475467',
    marginTop: '3px',
  },
}));

const SkipTour: React.FC<ISkipTourProps> = ({
  open,
  onClose,
  onDelete,
  loading = false,
}) => {
  const classes = useStyles();

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      handleClose={onClose}
      isRegularModal
      isNew
      isIcon
      titlePadding="0"
    >
      <Box p={3} minWidth={500}>
        <Box display="flex" alignItems="flex-start" gridGap="1rem">
          <SkipIcon />
          <Box>
            <Typography className={classes.title}>Skip tour</Typography>
            <Typography className={classes.desc}>
              Are you sure you want to skip the tour?
            </Typography>
          </Box>
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button className={classes.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={onDelete}
            disabled={loading}
          >
            {loading ? <ProgressLoader width={20} height={20} /> : 'Skip'}
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
};
export default SkipTour;
