import React, { ReactElement } from 'react';
import { makeStyles, Typography, Grid, Divider } from '@material-ui/core';
import { ReactComponent as NoNotificationIllustration } from 'assets/illustrations/noNotification.svg';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as SettingsIcon } from 'assets/settingsIcon.svg';
import Menu from '../ui/Menu';
import useDeleteNotification from '@eggmed/graphql-client/operations/notificationOperations/useDeleteNotification';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NextIcon } from 'assets/next.svg';
import Elements, { MenuElements } from './MenuElement';
import { useProvider as useAuth } from 'contexts/AuthContext';
import { ReactComponent as RemoveNotificationIcon } from 'assets/notifications/removeNotification.svg';
import { ReactComponent as TurnOffTopicIcon } from 'assets/notifications/turnOffNotification.svg';
import { ReactComponent as TopicOnIcon } from 'assets/topicOn.svg';
import { Link } from 'react-router-dom';
import useHandleTopic from 'pages/PatientNotificationPage/notificationTabs/useHandleTopic';
import Text from '../../Text';

const useStyles = makeStyles((theme) => ({
  settings: {
    height: '48px',
    backgroundColor: '#ffff',
    paddingLeft: '1rem',
    paddingRight: '2rem',
    borderBottom: '1px solid #E0E0E0',
  },
  Settingstitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  triangle: {
    width: '0',
    height: '0',
    borderLeft: '17px solid transparent',
    borderRight: '17px solid transparent',
    borderBottom: '17px solid white',
    marginTop: '-17px',
    marginRight: '20px',
  },
  illustration: {
    padding: '2rem',
    width: theme.typography.pxToRem(425),
    height: theme.typography.pxToRem(300),
  },
  viewAll: {
    fontFamily: 'Inter, sans-serif',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#147AF3',
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    gap: '10px',
  },
  tabs: {
    marginBlock: 0,
    paddingInline: '2rem',
  },
  divider: {
    backgroundColor: '#D0D5DD',
  },
  settingsLink: {
    display: 'flex',
  },
}));

interface IMenuTabsProps {
  menuElements: MenuElements[];
}

const MenuTabs = ({ menuElements }: IMenuTabsProps): ReactElement => {
  const { patient } = useAuth();
  const isPatient = !!patient;
  const link = !isPatient
    ? '/doctor/admin?query=Notification'
    : '/patient/settings?query=Notification';
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [deleteId, setDeleteId] = React.useState<string>();
  const [cardId, setCardId] = React.useState<number>();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const isMenuOpen = Boolean(anchor);
  const history = useHistory();
  const { handleDeleteNotification } = useDeleteNotification();
  const handleUpdateMenuItems = () => {
    menuElements?.splice(cardId, 1);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const { turnOffNotification, turnOnNotification, topicOn } = useHandleTopic({
    elements: menuElements,
    deleteId,
    setAnchor,
    setDeleteId,
  });
  const MenuElementsData = [
    {
      avatar: <RemoveNotificationIcon />,
      name: 'Delete notification',
      onClick: () => {
        handleClose();
        handleOpenDeleteModal();
      },
    },
    {
      avatar: topicOn ? <TurnOffTopicIcon /> : <TopicOnIcon />,
      name: `Turn ${topicOn ? 'off' : 'on'} this notification type`,
      onClick: () => {
        if (topicOn) {
          turnOffNotification();
        } else {
          turnOnNotification();
        }
      },
    },
  ];
  const handleClose = () => {
    setAnchor(null);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const redirect = () => {
    patient
      ? history.push('/patient/notifications')
      : history.push('/doctor/notifications');
  };
  return (
    <Grid container wrap="nowrap" direction="column">
      <Grid container justifyContent="flex-end">
        <div className={classes.triangle} />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.settings}
      >
        <Typography className={classes.Settingstitle}>Notifications</Typography>
        <Link className={classes.settingsLink} to={link}>
          <SettingsIcon />
        </Link>
      </Grid>
      <Grid container justifyContent="flex-start">
        <ScrollBar maxHeight="54vh" overflow="hidden">
          {menuElements?.length > 0 ? (
            <Elements
              menuElements={menuElements}
              setAnchor={setAnchor}
              setDeleteId={setDeleteId}
              setCardId={setCardId}
              anchor={anchor}
            />
          ) : (
            <NoNotificationIllustration className={classes.illustration} />
          )}
        </ScrollBar>
      </Grid>
      <Divider className={classes.divider} />
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{
          height: '57px',
          paddingInline: '31px',
          backgroundColor: 'white',
          borderRadius: '0px 0px 8px 8px',
        }}
      >
        <Text i18nKey="viewAll" className={classes.viewAll} onClick={redirect}>
          View All <NextIcon style={{ width: '7px', height: '14px' }} />
        </Text>
      </Grid>
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        handleMenuClose={handleClose}
        MenuElements={MenuElementsData}
      />
      <ModalDelete
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => {
          handleDeleteNotification(deleteId);
          handleUpdateMenuItems();
          handleCloseDeleteModal();
        }}
      />
    </Grid>
  );
};
export default MenuTabs;
