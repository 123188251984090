import React from 'react';
import { useStyles } from '../Subscription';
import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import Skeleton from 'components/ui/Skeleton';
import CustomButton from 'components/ui/Button';
import Text from 'Text';
import { subscriptionProps } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  subscriptionData: subscriptionProps;
  handleOpen: () => void;
}
const SubscriptionCanceled = ({
  loading,
  subscriptionData,
  handleOpen,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Skeleton loading={loading}>
        <Box className={classes.canceledBox}>
          <Skeleton loading={loading}>
            <Typography className={classes.trialLabel}>
              {t('Subscription expired')}
            </Typography>
          </Skeleton>
          <Typography className={classes.trialDescription}>
            {t('Reactivate your subscription to keep using Eggmed')}{' '}
          </Typography>
          <CustomButton
            className={classes.addBtn}
            buttonType="reset"
            onClick={handleOpen}
          >
            <Text i18nKey="activatePlan" style={{ fontWeight: 500 }}>
              {t('Activate your plan')}
            </Text>
          </CustomButton>
        </Box>
      </Skeleton>
      {/* <Box mt={4}>
        <Box display="flex" justifyContent="space-between" width={'35%'}>
          <Box>
            <Typography className={classes.planLabel}>
              Renewal information
            </Typography>
            <Typography className={classes.expireLabel}>
              You canceled your subscription. It won't renew automatically.{' '}
            </Typography>
          </Box>
        </Box>
        <Box borderBottom="1px solid #EAECF0" pb={4} width="35%">
          <Box
            px={4}
            mt={2}
            display="flex"
            alignItems="center"
            style={{ gap: '3rem' }}
          >
            <Box display="flex" flexDirection="column">
              <Box mt={3}>
                <Typography className={classes.trialDate}>
                  Subscription end date{' '}
                </Typography>
                <Typography className={classes.trialValue}>
                  {dayjs(subscriptionData?.endDate).format('MM/DD/YYYY')}
                </Typography>
              </Box>
            </Box>
          </Box>{' '}
        </Box>
      </Box> */}
    </Box>
  );
};

export default SubscriptionCanceled;
