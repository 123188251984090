import { Box, Avatar, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { fromNow } from 'utils';
import { ReactComponent as MoreIcon } from 'assets/notifications/blueMore.svg';
import { useHistory } from 'react-router-dom';

interface IDrugs {
  createdAt: string;
}
interface IRefillProps {
  patient: {
    firstname: string;
    lastname: string;
    picture: string;
    _id: string;
  };
  drugs: IDrugs[];
  _id: string;
}

const RefillCard = ({ patient, drugs, _id: cardId }: IRefillProps) => {
  const history = useHistory();
  const { firstname, lastname, picture, _id } = patient || {};
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center">
        <Avatar src={picture} className={classes.cardIcon} />
        <Box display="flex" flexDirection="column" ml={2}>
          <Box display="flex" alignItems="baseline" style={{ gap: '6px' }}>
            <Typography className={classes.cardTitle}>
              Refill Requests
            </Typography>
            <Typography className={classes.date}>
              {fromNow(drugs && drugs[0]?.createdAt)}
            </Typography>
          </Box>
          <Typography className={classes.notificationContent}>
            {`${firstname} ${lastname} has Requested to refill a prescription `}
          </Typography>
          <button
            className={classes.cardButton}
            onClick={() =>
              history.push(`/doctor/patient/${_id}?cardId=${cardId}`, {})
            }
            style={{ color: 'white' }}
          >
            See Request
          </button>
        </Box>
      </Box>
      <Box className={classes.moreButton}>
        <MoreIcon className={classes.moreIcon} />
      </Box>
    </Box>
  );
};

export default RefillCard;
