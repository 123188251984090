import { gql, useMutation } from '@apollo/client';

const GENERATE_TOKEN_STAFF_BY_DOCTOR = gql`
  mutation GenerateTokenStaffByDoctor($doctorId: ID!) {
    generateTokenStaffByDoctor(doctorId: $doctorId)
  }
`;

const useGenerateTokenStaffByDoctor = () => {
  const [generateTokenStaffByDoctor] = useMutation(
    GENERATE_TOKEN_STAFF_BY_DOCTOR
  );
  async function handleGenerateTokenStaffByDoctor(doctorId: string) {
    try {
      return await generateTokenStaffByDoctor({
        variables: { doctorId },
      });
    } catch (e) {
      return e;
    }
  }
  return handleGenerateTokenStaffByDoctor;
};

export { GENERATE_TOKEN_STAFF_BY_DOCTOR };
export default useGenerateTokenStaffByDoctor;
