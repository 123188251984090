import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormInput } from './FormInput';
import { selectInputCapitalize } from '../../../utils/selectInputCapitalize';
import { useTranslation } from 'react-i18next';

export default function SelectInput({
  control,
  name,
  defaultValue,
  options,
  ...props
}: any): ReactElement {
  const { t } = useTranslation();
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ ...params }) => (
        <FormInput
          select
          {...params}
          name={name}
          {...props}
          SelectProps={{
            MenuProps: {
              disableScrollLock: false,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
          }}
        >
          {options.map((value: string) => (
            <MenuItem
              key={value}
              value={value}
              style={{
                zIndex: 100,
              }}
            >
              {t(selectInputCapitalize(value))}
            </MenuItem>
          ))}
        </FormInput>
      )}
      {...props}
    />
  );
}
