export const colors = ['Red', 'Blue', 'Pink', 'Yellow', 'Green', 'Purple'];

export const colorsMapping = {
  Red: '#EB5757',
  Blue: '#2F80ED',
  Pink: 'Pink',
  Yellow: '#F2C94C',
  Green: '#27AE60',
  Purple: '#BB6BD9',
};
