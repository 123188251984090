import { gql, useSubscription } from '@apollo/client';

const SUBSCRIPTION_TO_DELETED_THREAD = gql`
  subscription subscriptionThreadDeleted($userId: ID!) {
    subscriptionThreadDeleted(userId: $userId) {
      _id
    }
  }
`;

const useSubscriptionThreadDeleted = (
  userId: string,
  event: (data: string) => void
) => {
  const result = useSubscription(SUBSCRIPTION_TO_DELETED_THREAD, {
    variables: {
      userId,
    },
    onSubscriptionData: (data) => {
      const { subscriptionData } = data || {};
      event(subscriptionData?.data?.subscriptionThreadDeleted?._id);
    },
  });
  return result;
};

export { SUBSCRIPTION_TO_DELETED_THREAD };
export default useSubscriptionThreadDeleted;
