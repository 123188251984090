import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Tooltip from 'components/ui/Tooltpip';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';
import Skeleton from 'components/ui/Skeleton';

export type ISidebarLinkProps = {
  element: {
    icon: string;
    path: string;
    title: TranslationKeys;
  };
  pathname: string;
  isSidebarExpanded: boolean;
  expired?: boolean;
  loadingSubscription?: boolean;
  isSettings?: boolean;
};

export const SidebarLink = ({
  element,
  pathname,
  isSidebarExpanded,
  expired,
  loadingSubscription,
  isSettings = false,
}: ISidebarLinkProps) => {
  const classes = useStyles({ isSidebarExpanded });
  const { t } = useTranslation();
  return (
    <Tooltip
      isLarge={isSidebarExpanded}
      title={
        <Box
          style={{
            backgroundColor:
              element.path.split('/')[2] === pathname.split('/')[2] ||
              (pathname === '/doctor' && element.path === '/doctor/dashboard')
                ? ' #ecf3f4'
                : '#f7f7f5',
            color: 'black',
            padding: 20,
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'Inter, sans-serif',
          }}
        >
          {element.title}
        </Box>
      }
      placement="right"
    >
      <Box
        key={element.path}
        className={classes.linkHover}
        style={{ opacity: expired && !isSettings ? 0.5 : 1 }}
      >
        <Link
          key={element.path}
          className={`${classes.link}  ${
            element.path.split('/')[2] === pathname.split('/')[2] ||
            (pathname === '/doctor' && element.path === '/doctor/dashboard')
              ? classes.selected
              : ''
          }`}
          to={
            expired && !isSettings
              ? undefined
              : expired && isSettings && element?.title === 'Settings'
              ? '/doctor/admin?tab=15'
              : element.path
          }
          style={{ cursor: expired && !isSettings && 'default' }}
        >
          <Box
            className={classes.sideBar}
            display="flex"
            alignItems="center"
            width="100%"
          >
            <Box className={classes.imgBox} mr={!isSidebarExpanded && 2}>
              <Skeleton loading={loadingSubscription}>
                <img
                  // className={classes.icon}
                  src={element.icon}
                  alt={element.title}
                />
              </Skeleton>
            </Box>
            <Box className={classes.boxText}>
              <Skeleton loading={loadingSubscription}>
                <Typography className={classes.text}>
                  {t(`${element.title}`)}
                </Typography>
              </Skeleton>
            </Box>
          </Box>
        </Link>
      </Box>
    </Tooltip>
  );
};
