import React from 'react';
import Input from 'components/ui/Inputs';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../styles';

export const FromComponent = ({ methods }) => {
  const classes = useStyles();
  const { register, control, errors } = methods;
  const commonProps = { register, control, errors };

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label="Name on invoice"
          {...commonProps}
          name="nameOnInvoice"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label="Email"
          {...commonProps}
          name="doctorEmail"
          type="email"
          disabled
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <Typography className={classes.labelText}>Phone number</Typography>
        <Input
          control={control}
          inputRef={register()}
          type="phone"
          name="doctorPhone"
          variant="filled"
          helperText={
            errors && errors?.phone && <span>{errors?.phone?.message}</span>
          }
          error={errors && !!errors?.phone}
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.labelText}>Country</Typography>
        <Input
          inputRef={register}
          variant="filled"
          control={control}
          type="country"
          name="doctorCountry"
          data-cy="country-input"
          {...commonProps}
          defaultValue={{ label: '' }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label="Street"
          {...commonProps}
          name="doctorAddress"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label="Apt, suite (optional)"
          {...commonProps}
          name="doctorSuite"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <InputBlock
          label="City"
          {...commonProps}
          name="doctorCity"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label="State"
          {...commonProps}
          name="doctorState"
          type="text"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label="Zip code"
          {...commonProps}
          name="doctorZipcode"
          type="number"
          defaultValue={''}
        />
      </Grid>
    </Grid>
  );
};
