import React from 'react';
import { Box, FilledTextFieldProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/ui/Inputs';
import { useStyles } from './Payouts';
import { useTranslation } from 'react-i18next';

interface IInputBlockProps extends Omit<FilledTextFieldProps, 'variant'> {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  name: string;
  currentRow?: any;
  editMode: boolean;
  type?: string;
  options?: string[];
}

export default function InputBlock({
  label,
  name,
  errors,
  control,
  register,
  currentRow,
  editMode,
  type = 'text',
  ...props
}: IInputBlockProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box mb={2} className={props.className}>
      <Typography className={clsx(classes.textTitle, classes.margin)}>
        {label}
      </Typography>
      <Input
        inputRef={register}
        control={control}
        name={name}
        variant="filled"
        type={type}
        defaultValue={(editMode && currentRow && currentRow[name]) || ''}
        helperText={
          errors &&
          errors[name] && (
            <Box mt={0.5} data-cy="error-message" className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {t(errors[name]?.message)}
            </Box>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </Box>
  );
}
