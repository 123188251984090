import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
  },
  root: {
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : 'white',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    [theme.breakpoints.only('xl')]: {
      width: '38vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '48vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    height: 'auto',
  },
  titleMedication: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 'bold',
    color: theme.palette.black.dark,
  },
  locationTitle: {
    textTransform: 'capitalize',
    color: ' #1F61DC',
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 'bold',
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.5rem',
  },
  labelSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  iconClassName: {
    width: 15,
    height: 15,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  slideItem: {
    width: '400px !important',
  },
  slider: {
    width: '100%',
  },
  iconButton: {
    position: 'absolute',
    top: '1vh',
    right: '2vw',
  },
  closeIcon: {
    fontSize: '44px',
    color: '#425364',
  },
  grid: {
    display: 'grid',
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('xl')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },

    gap: theme.spacing(2),
  },
  gridItem: {
    width: '100%',
  },
  spacing: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  draftClassName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    maxHeight: theme.typography.pxToRem(200),
    height: theme.typography.pxToRem(200),
  },
  errorText: {
    fontSize: '14px',
  },
  form: {
    maxHeight: '70vh',
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'column no-wrap',
    alignItems: 'center',
  },
}));

export default useStyles;
