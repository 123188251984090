import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';

import { log } from 'config';
import { muteAudio } from 'utils/mediaAccessStart';
import PeerConnection from 'utils/RTC/PeerConnection';
import useChangeCameraState from '@eggmed/graphql-client/operations/videoRoomOperations/useChangeCameraState';
import useListenCameraState from '@eggmed/graphql-client/operations/videoRoomOperations/useListenCameraState';
import { Patient_patient } from '@eggmed/graphql-client/operations/patientOperations/__generated__/Patient';
import {
  setCryptoKey,
  enableEncryption,
  disableEncryption,
} from 'utils/encryptionKeyManagment';
import { useSignaling } from '@eggmed/graphql-client/operations/signallingOperations/useSignaling';
import {
  createNewPeerConnection,
  handleMuteAndUnmuteAllPeers,
  handleStopAllPeers,
  handleToggleVideoOnAllPeers,
} from './utils';
import useRoomManagement from './useRoomManagement';
import MediaDevice from 'utils/Media/MediaDevice';

const GET_VIDEO_MEMBERS_QUERY = gql`
  query getVideoRoomMembers($roomId: String) {
    videoRoomMembers(roomId: $roomId)
  }
`;

export interface IRoomContext {
  unMountConnections: () => void;
  membersCount: number;
  established: string;
  videoLocalRef: React.Ref<any>;
  videoRemoteRef: React.Ref<any>;
  handleChangeMute: () => void;
  mute: boolean;
  handleToggleVideo: () => void;
  roomId: string;
  videoOff: boolean;
  setEstablished: (arg: 'initial' | 'established') => void;
  uniqueIdentifier: string;
  userData: Partial<Patient_patient>;
  remoteUserData: any;
  isDoctor: boolean;
  setIsNotified: (isNotified: boolean) => void;
  encryptionKey: string;
  initiateInitialStateConnection?: () => void;
  remoteVideoOff: boolean;
  setRemoteVideoOff: (remoteVideoOff: boolean) => void;
}

export const Context = createContext<IRoomContext | undefined>(undefined);
const worker = new Worker('/worker.js', { name: 'E2EE worker' });

interface IProviderMeetingRoomProps {
  polite?: boolean;
  children: JSX.Element;
  roomId: string;
  userData: Partial<Patient_patient>;
  encryptionKey: string;
  isMobile?: boolean;
  id?: string;
  mediaDevice?: any;
}

export default function Provider({
  children,
  roomId,
  userData,
  polite = false,
  encryptionKey,
  isMobile = false,
  id,
}: IProviderMeetingRoomProps): JSX.Element {
  const [_isMobile, setIsMobile] = useState(isMobile);
  const videoLocalRef = useRef<any>();
  const videoRemoteRef = useRef<any>();
  const { data: videoRoomMembers, refetch } = useQuery(
    GET_VIDEO_MEMBERS_QUERY,
    {
      variables: { roomId },
      fetchPolicy: 'no-cache',
    }
  );
  const uniqueIdentifier = id;

  const [established, setEstablished] = useState<'initial' | 'established'>(
    'initial'
  );
  (window as any).worker = worker;
  const [mute, setMute] = useState<boolean>(false);
  const [remoteVideoOff, setRemoteVideoOff] = useState<boolean>(false);
  const [isVideoOff, setVideoOff] = useState<boolean>(false);
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [remoteUserData, setRemoteUserData] = useState<any>({
    firstname: 'R',
    lastname: 'U',
  });
  const pc = useRef<Set<PeerConnection>>(new Set());
  const currentMembers = useRef<Set<string>>(new Set());
  const { handleChangeCameraState } = useChangeCameraState();
  useListenCameraState({
    roomId,
    userId: uniqueIdentifier,
    onEvent: (state: 'established' | 'initial') => {
      log('info', 'Camera state changed', state);
      setRemoteVideoOff(() => !remoteVideoOff);
    },
  });

  function unMountConnections() {
    const peersToCleanUp = pc.current;
    handleStopAllPeers(peersToCleanUp);
    pc.current = new Set();

    currentMembers.current = new Set();
    setRemoteVideoOff(false);
  }

  useSubscription(
    gql`
      subscription newUserOnTheVideoRoom($roomId: String!, $userId: String!) {
        newUserOnTheVideoRoom(roomId: $roomId, userId: $userId) {
          userId
          isMobile
        }
      }
    `,
    {
      variables: {
        roomId,
        userId: uniqueIdentifier,
      },
      onSubscriptionData: () => {
        refetch();
      },
    }
  );

  useRoomManagement({
    roomId,
    unMountConnections,
    uniqueIdentifier,
    refetch,
    handleCreationNewConnection,
    isNotified,
    videoRoomMembers,
    polite,
    setIsMobile,
    isMobile,
    disableEncryption,
  });

  const { handleSendRtcOffer, handleSendRtcIceCandidate, handleSendRtcAnswer } =
    useSignaling({
      peerSet: pc.current,
      roomId,
      createNewPeerConnection: handleCreationNewConnection,
      userId: uniqueIdentifier,
      polite,
      unMountConnections,
      setRemoteUserData: () => {},
    });

  useEffect(() => {
    if (_isMobile) {
      disableEncryption();
    } else {
      if (videoRoomMembers && videoRoomMembers.videoRoomMembers.length >= 2) {
        enableEncryption();
        if (encryptionKey && !((encryptionKey as any) instanceof Error)) {
          setCryptoKey(encryptionKey);
        }
      }
    }
  }, [encryptionKey, _isMobile, videoRoomMembers]);

  function onLocalStreamReceived(stream: any) {
    if (mute) muteAudio(stream);
    videoLocalRef.current.srcObject = stream;
  }

  function onRemoteStreamReceived(stream: any) {
    videoRemoteRef.current.srcObject = stream;
    setEstablished('established');
  }

  function handleIceCandidate(data: any) {
    const { candidate, sdpMid, sdpMLineIndex } = data?.candidate || {};
    handleSendRtcIceCandidate(
      candidate,
      sdpMid,
      sdpMLineIndex,
      uniqueIdentifier,
      roomId
    );
  }

  function handleSendDescription(localDesc: any) {
    if (localDesc.type === 'offer')
      handleSendRtcOffer(localDesc, uniqueIdentifier, roomId);
    if (localDesc.type === 'answer')
      handleSendRtcAnswer(localDesc, uniqueIdentifier, roomId);
  }

  function handleCreationNewConnection(
    userId: string,
    fromSubscription: boolean
  ) {
    if (userId === uniqueIdentifier) {
      return undefined;
    }
    if (currentMembers.current.has(userId)) {
      log('This user already connected');
      return undefined;
    }
    if (fromSubscription) setIsNotified(true);
    console.log('Creating new Peer connection', id, fromSubscription);
    const set = createNewPeerConnection({
      userId,
      onLocalStreamReceived,
      onRemoteStreamReceived,
      handleIceCandidate,
      handleSendDescription,
      peerSet: pc.current,
    });
    currentMembers.current.add(userId);
    return set;
  }

  function initiateInitialStateConnection() {
    handleCreationNewConnection('id', false);
  }

  function handleChangeMute() {
    setMute((muteState: boolean) => !muteState);
    handleMuteAndUnmuteAllPeers(pc.current);
  }

  function handleToggleVideo() {
    setVideoOff((videoState: boolean) => !videoState);
    handleToggleVideoOnAllPeers(pc.current);
    handleChangeCameraState(
      uniqueIdentifier,
      roomId,
      isVideoOff ? 'established' : 'initial'
    );
  }
  return (
    <Context.Provider
      value={{
        unMountConnections,
        established,
        videoLocalRef,
        videoRemoteRef,
        handleChangeMute,
        mute,
        handleToggleVideo,
        roomId,
        isDoctor: !polite,
        remoteVideoOff,
        setRemoteVideoOff,
        videoOff: isVideoOff,
        setEstablished,
        uniqueIdentifier,
        userData,
        remoteUserData,
        setIsNotified,
        encryptionKey,
        membersCount: videoRoomMembers?.videoRoomMembers.length || 0,
        initiateInitialStateConnection,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useVideoProvider must be used within a VideoProvider');
  }

  return context;
}

Provider.defaultProps = {
  polite: false,
};
