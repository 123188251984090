import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditButtons from 'components/EditButtons';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import ScrollBar from 'components/ui/ScrollBar';
import Input from 'components/ui/Inputs';
import Switch from 'components/ui/SwitchNew';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/';
import ErrorIcon from '@material-ui/icons/Error';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import BasicModal from 'components/ui/Modal';
import FormPage from 'pages/FormsPage';
import Text from 'Text';
import Editorjs from 'components/Editorjs';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { log } from 'config';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import useEditDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditDoctorTemplate';
import { DOCTOR_TEMPLATES_BY_FORM } from 'pages/AdminPage/TabsContent/ManageTemplates';

const schema = yup.object().shape({
  formName: yup.string().required('Form name is a required field'),
  description: yup.string().required('Description is a required field'),
});

interface IFormTypeProps {
  handleBack: () => void;
  patientId?: string;
  patient?: IPatientData;
  settings?: boolean;
}

function FormType({
  handleBack,
  patientId,
  patient,
  settings = false,
}: IFormTypeProps) {
  const {
    editorRef,
    selectedTemplate,
    formTitle,
    handleChangeText,
    resetBlock,
    editMode,
    templateId,
  } = useEditorContext();
  const { doctor } = useAuth();
  const classes = useStyles({ selected: false });
  const history = useHistory();
  const { control, register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = React.useState(false);
  const [forms, setForms] = React.useState(null);
  const onSubmit = (data) => {
    setOpen(true);
    setForms({
      formName: data?.formName,
      formDescription: data?.description,
      doctorId: doctor?._id,
      patientId: patient?._id,
      assignText: `Assign this form to ${patient?.firstname} ${patient?.lastname}?`,
      settings,
    });
  };
  const [handleCreateDoctorTemplate] = useCreateDoctorTemplate();
  const [handleEditDoctorTemplate] = useEditDoctorTemplate();
  async function handleSave() {
    const { blocks } = await editorRef.current.save();
    const forms = JSON.stringify(blocks);
    log('from handle save', blocks);
    if (!editMode) {
      await handleCreateDoctorTemplate({
        variables: {
          doctorTemplateInput: {
            title: formTitle,
            description: 'description',
            questions: [],
            lastOpenedAt: new Date(),
            type: 'form',
            formType: selectedTemplate,
            blocks: forms,
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
    } else {
      await handleEditDoctorTemplate({
        variables: {
          id: templateId,
          doctorTemplateInput: {
            blocks: forms,
            title: formTitle,
            formType: selectedTemplate,
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES],
      });
    }

    handleBack();
  }
  return (
    <Box
      style={{
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(244, 242, 238)',
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box className={classes.reflectionModal} style={{ width: '100vw' }}>
          <ScrollBar sx={{ flex: 1 }} maxHeight="100vh">
            {/* <Box width="100%" paddingY="2rem" className={classes.scrollBar}>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                position="relative"
              >
                <Box mb={1}>
                  <Text i18nKey='formName' className={classes.InputLabel}>
                    Form name
                  </Text>
                </Box>
                <Input
                  variant="filled"
                  type="text"
                  name="formName"
                  inputRef={register}
                  control={control}
                  error={errors?.formName}
                />
                <Box className={classes.errorBox}>
                  {errors && errors.formName && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.formName?.message}
                    </span>
                  )}
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                mt="30px"
                position="relative"
              >
                <Box mb={1}>
                  <Text i18nKey='description' className={classes.InputLabel}>
                    Description
                  </Text>
                </Box>
                <Input
                  variant="filled"
                  type="text"
                  name="description"
                  multiline
                  minRows={3}
                  maxRows={3}
                  inputRef={register}
                  control={control}
                  error={errors?.description}
                />
                <Box className={classes.errorBox}>
                  {errors && errors.description && (
                    <span data-cy="error-message" className={classes.error}>
                      <ErrorIcon className={classes.errorIcon} />
                      {errors?.description?.message}
                    </span>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                mt="30px"
                style={{ gap: '10px' }}
              >
                <Text i18nKey='saveToMyForms' className={classes.InputLabel}>
                  Save to my forms
                </Text>
                <Switch inputRef={register} name="save" />
              </Box>
            </Box> */}
            <Box className={classes.scrollBarForm}>
              <Editorjs
                handleClose={() => {
                  handleBack();
                  handleChangeText('Untitled template');
                  resetBlock();
                }}
                handleSave={handleSave}
              />
            </Box>
          </ScrollBar>
          {/* <Box
            height="76px"
            display="flex"
            alignItems="center"
            width="100%"
            borderTop="1px solid #EAECF0"
            style={{
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px',
            }}
          >
            <EditButtons
              px="2rem"
              editable
              isPatient={false}
              isSlide
              type="Start"
              submitText="Next"
              cancelText="Back"
              loading={false}
              handleEdit={() => {
                handleSave();
              }}
              handleOpen={(e) => {
                e.preventDefault();
                handleBack();
              }}
            />
          </Box> */}
        </Box>
      </form>
      {/* <BasicModal
        open={open}
        onClose={() => {
          setOpen(false);
          handleBack();
        }}
        handleClose={() => {
          setOpen(false);
          handleBack();
        }}
        isRegularModal
        isSlide
        divider
        title={''}
        titlePadding="0rem"
      >
        <FormPage isNotes={false} forms={forms} settings={true} />
      </BasicModal> */}
    </Box>
  );
}

export default FormType;
