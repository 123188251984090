import { gql, useMutation } from '@apollo/client';
import {
  addTaskAttachment,
  addTaskAttachmentVariables,
} from './__generated__/addTaskAttachment';

const ADD_TASK = gql`
  mutation addTaskAttachment($taskId: ID!, $files: [Upload]) {
    addAttachment(taskId: $taskId, files: $files) {
      _id
      attachments {
        createdAt
        url
        fileType
        fileName
        user {
          firstname
          middlename
          lastname
          picture
          role
          _id
        }
      }
    }
  }
`;

export default function useAddTaskAttachment() {
  const [addTaskAttachmentCall, result] = useMutation<
    addTaskAttachment,
    addTaskAttachmentVariables
  >(ADD_TASK, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          tasks(tasks) {
            return tasks.map((task: any) => {
              const taskId = task.__ref.split('Task:')[1];
              if (taskId === data?.addAttachment?._id) {
                return {
                  ...task,
                  attachments: data?.addAttachment?.attachments,
                };
              }
              return task;
            });
          },
        },
      });
    },
  });
  return { addTaskAttachment: addTaskAttachmentCall, ...result };
}
