import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  read: {
    minWidth: '8px',
    minHeight: '8px',
    borderRadius: '100%',
    backgroundColor: '#147AF3',
    [theme.breakpoints.down('sm')]: {
      minWidth: '6.3px',
      minHeight: '6.3px',
    },
  },
  readBox: {
    minWidth: '8px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '6.3px',
    },
  },
  cardTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: ({ patient }: { patient: boolean }) =>
      patient ? '#1F61DC' : '#425364',
  },
  date: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#1F61DC',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '12px',
    },
  },
  notificationContent: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#475467',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '15px',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  },
  actionMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 500,
    marginTop: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#666666',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '15px',
    },
  },

  AccessCardButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    borderRadius: '20px',
    height: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '1rem',
    marginTop: '8px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      height: '20px',
      paddingInline: '10px',
    },
  },
  cardButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    color: ({ patient }: { patient: boolean }) =>
      patient ? '#1F61DC' : '#39C38D',
    border: ({ patient }: { patient: boolean }) =>
      patient ? '1px solid #1F61DC' : '1px solid #39C38D',
    borderRadius: ({ patient }: { patient: boolean }) =>
      patient ? '20px' : '5px',
    height: '31px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '1rem',
    marginTop: '8px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      height: '20px',
      paddingInline: '10px',
    },
  },
  mainButton: {
    fontWeight: 700,
    backgroundColor: ({ patient }: { patient: boolean }) =>
      patient ? '#1F61DC' : '#39C38D',
  },
  AccessMainButton: {
    fontWeight: 700,
    backgroundColor: '#1F61DC',
    border: '1px solid #1F61DC',
    color: '#FFFFFF',
  },
  Card: {
    borderRadius: '8px',
    paddingInline: '1rem',
    paddingBlock: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#EFF6FF',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '12px',
    },
  },
  cardIcon: {
    minWidth: '52px',
    minHeight: '52px',
    marginLeft: '1rem',
    [theme.breakpoints.only('xs')]: {
      minWidth: '43px',
      minHeight: '43px',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
    marginLeft: '15px',
  },
  leftSide: {
    paddingLeft: '1rem',
    gap: '1.5rem',
    paddingTop: '10px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.5rem',
      gap: '1rem',
    },
  },
  moreButton: {
    cursor: 'pointer',
  },
  Middle: {
    paddingInline: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      gap: '0.2rem',
      paddingLeft: '40px',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: ({ patient }: { patient: boolean }) => !patient && '0px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingLeft: '15px',
      paddingRight: ({ patient }: { patient: boolean }) => !patient && '15px',
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  moreIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
    },
  },
  cancelButton: {
    border: '1px solid #D7E3F1',
    backgroundColor: '#D7E3F1',
    color: '#273142',
    fontWeight: 500,
  },
}));

export default useStyles;
