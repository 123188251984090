function isUpperCase(str: string): boolean {
  return str.toUpperCase() === str;
}

function capitalizeFirstLetter(str: string): string {
  return str.slice(0, 1).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
}

export function selectInputCapitalize(str: string): string {
  return isUpperCase(str) ? str : capitalizeFirstLetter(str);
}
