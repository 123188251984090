import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Divider,
} from '@material-ui/core';
import { useStyles } from './styles';
import { QuestionTypeEnum } from './Constants';
import Input from 'components/ui/Inputs';
import Radio from './inputs/Radio';
import Checked from 'pages/AdminPage/Payment/Checked';
import { useForm } from 'react-hook-form';
import { IQuestion } from './FormsPage';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import TableForm from 'pages/PatientActivitiesPage/CreateEditForm/TableForm';
import ListForm from 'pages/PatientActivitiesPage/CreateEditForm/ListForm';
import { FormAddress } from 'pages/PatientActivitiesPage/CreateEditForm/FormAddress';

function Response({
  question,
  isFastPreview = false,
  isNotes = false,
  handleInputChange,
  handleCheckboxChange,
  handleAddress,
  index,
  noMargin = false,
}: {
  question: IQuestion;
  isFastPreview?: Boolean;
  isNotes?: Boolean;
  index?: number;
  handleInputChange?: (index: number, value: string) => void;
  handleCheckboxChange?: (index: number, value: string) => void;
  handleAddress?: (index: number, value: string, field: string) => void;
  noMargin?: boolean;
}): React.ReactElement {
  const classes = useStyles();
  const { control, register, watch, setValue } = useForm();
  const generateNumbersArray = (range) => {
    const [min, max] = range;
    const numbersArray = [];

    for (let i = min; i <= max; i++) {
      numbersArray.push(i.toString());
    }

    return numbersArray;
  };
  const handleCheckBox = (value) => {
    handleCheckboxChange(index, value);
  };
  const numbersArray = generateNumbersArray(question.range);
  const dividerArray = [
    QuestionTypeEnum.MultipleChoice,
    QuestionTypeEnum.SingleChoice,
    QuestionTypeEnum.Dropdown,
    QuestionTypeEnum.LinearScale,
  ];
  const hasDivider =
    dividerArray.includes(question.type as QuestionTypeEnum) && isNotes;

  const handleDraftJs = () => {
    const values = watch('answer');
    handleInputChange(index, convertToRawAndStringify(values));
  };
  return (
    <Box width="100%" mt={isNotes ? '-20px' : '-1rem'} ml={!noMargin && '3px'}>
      {hasDivider && (
        <Divider style={{ marginBottom: '1rem', marginTop: '5px' }} />
      )}
      <Box width="100%">
        {question.type === QuestionTypeEnum.ShortAnswer && (
          <Input
            variant="filled"
            type="text"
            style={{ height: '40px' }}
            onBlur={(e) => handleInputChange(index, e.target.value)}
            inputProps={{
              style: {
                height: '40px',
                padding: '0 14px',
              },
            }}
            value={question.responses}
          />
        )}
        {question.type === QuestionTypeEnum.Email && (
          <Input
            variant="filled"
            type="text"
            style={{ height: '40px' }}
            onBlur={(e) => handleInputChange(index, e.target.value)}
            inputProps={{
              style: {
                height: '40px',
                padding: '0 14px',
              },
            }}
            value={question.responses}
          />
        )}
        {question.type === QuestionTypeEnum.Phone && (
          <Input
            variant="filled"
            name="phone"
            type="text"
            register={register}
            control={control}
            value={question.responses}
            onBlur={(e) => handleInputChange(index, e.target.value)}
          />
        )}
        {question.type === QuestionTypeEnum.LongAnswer && (
          <TextField
            name="description"
            className={classes.TextFiled}
            variant="outlined"
            onBlur={(e) => handleInputChange(index, e.target.value)}
            multiline
            rows={4}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={question.responses}
          />
        )}
        {question.type === QuestionTypeEnum.Table && (
          <Box width={'100%'}>
            <TableForm data={question.question} />
          </Box>
        )}
        {question.type === QuestionTypeEnum.List && (
          <Box width={'100%'}>
            <ListForm data={question?.question} type="unordered" />
          </Box>
        )}
        {question.type === QuestionTypeEnum.OrderedList && (
          <Box width={'100%'}>
            <ListForm data={question?.question} type="ordered" />
          </Box>
        )}
        {question.type === QuestionTypeEnum.MultipleChoice && (
          <Radio
            options={question.options}
            name="options"
            handleInputChange={handleInputChange}
            selectedValue={question.responses?.[0]}
            index={index}
          />
        )}
        {question.type === QuestionTypeEnum.SingleChoice && (
          <Box width="100%">
            {question.options.map((option) => (
              <Box display="flex" alignItems="center" ml="-10px">
                <Checked
                  color="rgba(31, 97, 220, 1)"
                  handleChange={() => handleCheckBox(option)}
                  checkedValue={question.responses?.includes(option)}
                />
                <Typography className={classes.optionsPreview}>
                  {option}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {question.type === QuestionTypeEnum.Dropdown && !isFastPreview && (
          <Box width="100%">
            <Input
              variant="filled"
              name="type"
              type="select"
              options={question.options}
              // defaultValue={question.responses?.[0]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              register={register}
              control={control}
              value={question.responses}
            />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Address && (
          <Box width="100%">
            <FormAddress
              register={register}
              control={control}
              response={question.responses}
              handleAddress={handleAddress}
            />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Dropdown && isFastPreview && (
          <Box width="100%">
            {question.options.map((option, index) => (
              <Box display="flex" alignItems="center" ml="-10px">
                <Typography
                  className={classes.optionsPreview}
                  style={{ margin: '3px 5px 0 1rem' }}
                >
                  {index + 1}.
                </Typography>
                <Typography className={classes.optionsPreview}>
                  {option}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {question.type === QuestionTypeEnum.Date && (
          <Input
            type="date"
            name="dueDate"
            register={register}
            defaultValue={new Date(question.responses).getTime()}
            onChange={(e) => {
              handleInputChange(index, e);
              setValue('dueDate', e);
            }}
            control={control}
            className={classes.dateInput}
          />
        )}
      </Box>
      <Box width="100%">
        {question.type === QuestionTypeEnum.LinearScale && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="nowrap"
            width="100%"
          >
            <Typography className={classes.linearPreview}>
              {question.options[0]}
            </Typography>
            <Radio
              options={numbersArray}
              handleInputChange={handleInputChange}
              index={index}
              selectedValue={question.responses?.[0]}
              name="range"
              row
              topLabel
            />
            <Typography className={classes.linearPreview}>
              {question.options[1]}
            </Typography>
          </Box>
        )}
        {question.type === QuestionTypeEnum.TextBox && (
          <Input
            type="draftjs"
            name="answer"
            register={register}
            control={control}
            defaultValue={editorStateFromStringifiedRaw(
              question.responses?.[0],
              !tryParseJSON(question.responses?.[0])
            )}
            isBigger
            onBlur={handleDraftJs}
          />
        )}
      </Box>
    </Box>
  );
}

export default Response;
