import { Box } from '@material-ui/core';
import React from 'react';
import { IconProps } from '../patientDashboard/AppointmentIcon';

function dropdown({ fill = '#425364', width = 10, height = 4 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 4L0.669873 0.25L9.33013 0.250001L5 4Z" fill={fill} />
    </svg>
  );
}

export default dropdown;
