import { gql, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS } from './useGetNotifications';

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($id: ID) {
    updateNotification(id: $id) {
      _id
    }
  }
`;

const UPDATE_NOTIFICATION_ACTION = gql`
  mutation notificationAction($id: ID, $action: ACTION_TYPE) {
    notificationAction(id: $id, action: $action) {
      _id
    }
  }
`;

const useUpdateNotification = () => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
  });
  const [updateNotificationAction] = useMutation(UPDATE_NOTIFICATION_ACTION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
  });
  async function handleUpdateNotification(id) {
    try {
      return await updateNotification({
        variables: { id },
      });
    } catch (e) {
      return e;
    }
  }
  async function handleUpdateNotificationAction(id, action) {
    try {
      return await updateNotificationAction({
        variables: { id, action },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleUpdateNotification, handleUpdateNotificationAction };
};

export { UPDATE_NOTIFICATION };
export default useUpdateNotification;
