import { gql, useMutation } from '@apollo/client';
import {
  editConsultation,
  editConsultationVariables,
} from './__generated__/editConsultation';

const EDIT_CONSULTATION_REQUEST = gql`
  mutation editConsultation(
    $consultationInput: editConsultationInput!
    $consultationId: ID!
  ) {
    editConsultation(
      consultationInput: $consultationInput
      consultationId: $consultationId
    ) {
      _id
      patientInput
      personalNotes
      symptoms
    }
  }
`;

export default function useEditConsultation() {
  const [updateConsultation, result] = useMutation<
    editConsultation,
    editConsultationVariables
  >(EDIT_CONSULTATION_REQUEST, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentConsultation() {
            return data?.editConsultation;
          },
        },
      });
    },
  });
  return { updateConsultation, ...result };
}
