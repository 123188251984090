import React from 'react';
import { Box, FilledTextFieldProps, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/ui/Inputs';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface IInputBlockProps extends Omit<FilledTextFieldProps, 'variant'> {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  name: string;
  type?: string;
  options?: string[];
  defaultValue?: any;
  showTimeSelect?: boolean;
}

export default function InputBlock({
  label,
  name,
  errors,
  control,
  register,
  type = 'text',
  defaultValue,
  ...props
}: IInputBlockProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={props.className}>
      <Typography className={classes.labelText}>{label}</Typography>
      <Input
        inputRef={register}
        control={control}
        name={name}
        variant="filled"
        type={type}
        defaultValue={defaultValue || ''}
        helperText={
          errors &&
          errors[name] && (
            <Box
              display="flex"
              alignItems="center"
              data-cy="error-message"
              className={classes.error}
            >
              <ErrorIcon className={classes.errorIcon} />
              {t(errors[name]?.message)}
            </Box>
          )
        }
        error={errors && !!errors[name]}
        {...props}
      />
    </Box>
  );
}
