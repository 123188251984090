import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from '../../queries/appointment';

interface IUseAppointmentArgs {
  startDate?: string | Date | number | undefined;
  endDate?: string | Date | number | undefined;
  page?: string | number;
  limit?: string | number;
}

export default function useGetAppointments({
  startDate,
  endDate,
  page,
  limit,
}: IUseAppointmentArgs) {
  let queryObject = {};
  if (startDate) {
    queryObject = {
      startDate,
      endDate,
    };
  } else {
    queryObject = {
      limit,
      page,
    };
  }
  const { data, error, loading } = useQuery(GET_APPOINTMENTS, {
    variables: queryObject,
    fetchPolicy: 'network-only',
  });
  return { data, error, loading };
}
