import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const LOG_ACTIVITY = gql`
  mutation logActivity(
    $patientActivitiesInput: PatientLogActivitiesInput
    $currentDate: Date
  ) {
    logActivity(
      patientActivitiesInput: $patientActivitiesInput
      currentDate: $currentDate
    ) {
      question
    }
  }
`;

export default function useLogActivity() {
  const [logActivity, { loading }] = useMutation(LOG_ACTIVITY, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          patientActivityByFilter() {
            return data?.patientActivityByFilter;
          },
        },
      });
    },
    refetchQueries: [GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID],
  });
  return { logActivity, loading };
}

export const GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID = gql`
  query patientActivitiesByPatientDoctorId($patientId: ID!, $doctorId: ID!) {
    patientActivitiesByPatientDoctorId(
      patientId: $patientId
      doctorId: $doctorId
    ) {
      _id
      reflectionType
      question
      options
      type
      notes
      sleep
      photo
      assignedAt
      activityUrl
      isEdited
      bookmarked
      patient {
        _id
        firstname
        lastname
      }
      doctor {
        _id
      }
      frequency {
        startDate
        endDate
        frequencyType
        repetition {
          value
          unit
        }
        repeatOn
      }
      patientResponse {
        _id
        question
        response
        image
        sleepHours
        wakeHours
        notes
        createdAt
        tags
      }
    }
  }
`;
