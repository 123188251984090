import React from 'react';
import {
  Box,
  Grid,
  Button,
  Avatar,
  Typography,
  Divider,
} from '@material-ui/core';
import { ReactComponent as RecentIcon } from 'assets/RecentIcon.svg';
import useStyles from './styles';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID } from 'pages/PatientActivities';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';
import RecentActivityCard from 'pages/PatientActivities/RecentActivityCard';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyTask } from 'assets/emptyState/task.svg';
import { useHistory } from 'react-router-dom';
import RecentActivitiesSkeleton from './RecentActivitiesSkeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
 
const RecentActivities = ({ patientId, doctors, isLarge }) => {
  const {
    data,
    error: activitiesError,
    loading: activitiesLoading,
  } = useQuery(GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID, {
    variables: { patientId, doctorId: doctors && doctors?.doctors[0]?._id },
  });
  const recentActivity = React.useMemo(
    () =>
      data?.patientActivitiesByPatientDoctorId
        ?.filter((activity) => {
          const activityDate = new Date(
            activity?.patientResponse[0]?.createdAt
          );
          const currentDate = new Date();

          const dateCondition = activityDate <= currentDate;

          return dateCondition;
        })
        ?.sort((activityA, activityB) => {
          const dateA = new Date(
            activityA?.patientResponse[0]?.createdAt
          ) as any;
          const dateB = new Date(
            activityB?.patientResponse[0]?.createdAt
          ) as any;
          return dateB - dateA;
        }),
    [data]
  );
  const doctorAndPatientId = {
    doctor: doctors && doctors?.doctors[0]?._id,
    patient: patientId,
  };
  const classes = useStyles();
  const { t } =useTranslation();
  const displayItem = isLarge ? 3 : 2;
  const history = useHistory();
  return (
    <Box
      className={classes.upcomingBox}
      px={3}
      py={3}
      width="100%"
      height="100%"
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center">
        <RecentIcon style={{ marginRight: '10px' }} />
        <Text i18nKey='recentActivities' className={classes.textTitle}>Recent activities</Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ gap: '20px' }}
        mt={4}
      >
        {activitiesLoading && [1, 2].map(() => <RecentActivitiesSkeleton />)}
        {recentActivity?.length === 0 && !activitiesLoading && (
          <Box py="2rem" width="100%">
            <EmptyState
              icon={<EmptyTask />}
              title={t("No activities yet")}
              text={t("To log your first activity")}
              isText
              isClickLeft={false}
              onClick={() => {
                history.push('/patient/activities');
              }}
            />
          </Box>
        )}
        {recentActivity?.slice(0, displayItem)?.map((element) => (
          <Box width="50%">
            <RecentActivityCard
              {...element}
              doctorAndPatientId={doctorAndPatientId}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RecentActivities;
