import { gql, useLazyQuery } from '@apollo/client';
import {
  GetAutoCompleteSearchThreadConversation,
  GetAutoCompleteSearchThreadConversationVariables,
} from './__generated__/GetAutoCompleteSearchThreadConversation';

const GET_AUTOCOMPLETE_SEARCH_THREAD_CONVERSATION = gql`
  query GetAutoCompleteSearchThreadConversation($search: String!) {
    getAutoCompleteSearchThreadConversation(search: $search) {
      _id
      subject
      type
      creator {
        _id
        firstname
        middlename
        username
        lastname
        role
        picture
        organizationId
        doctorId
      }
      lastMessage {
        user {
          _id
          firstname
          middlename
          username
        }
        content {
          text
          files {
            _id
            url
            fileName
            mimeType
          }
        }
      }
      participants {
        user {
          _id
          firstname
          middlename
          username
          lastname
          role
          picture
          organizationId
          doctorId
        }
        isAdmin
      }
      unReadItMessage {
        id
        threadConversation
        user
      }
      isArchived
    }
  }
`;

export const useLazySearchThread = () =>
  useLazyQuery<
    GetAutoCompleteSearchThreadConversation,
    GetAutoCompleteSearchThreadConversationVariables
  >(GET_AUTOCOMPLETE_SEARCH_THREAD_CONVERSATION);
