import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Radio from '@material-ui/core/Radio';
import useStyles from './styles';
import clsx from 'clsx';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

interface Props {
  checked: boolean;
  handleChange: () => void;
  commonProps: any;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
const AddPatientModal = ({
  checked,
  handleChange,
  commonProps,
  error,
  setError,
}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    return () => setError('');
  }, []);
  const { t } = useTranslation();
  return (
    <Box>
      <Box px={'2rem'} pt={2}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Text i18nKey="ageGroup" className={classes.agegroup}>
              Age group
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              ml={-1}
              mt={1}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <Radio
                  className={classes.rootRadio}
                  color="default"
                  disableRipple
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.iconRadio,
                        classes.checkedIconRadio
                      )}
                    />
                  }
                  icon={<span className={classes.iconRadio} />}
                  checked={checked === true}
                  onChange={handleChange}
                  value="event"
                  data-cy="male-input"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <Text i18nKey="adult">Adult</Text>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                ml={2}
              >
                <Radio
                  className={classes.rootRadio}
                  color="default"
                  disableRipple
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.iconRadio,
                        classes.checkedIconRadio
                      )}
                    />
                  }
                  icon={<span className={classes.iconRadio} />}
                  checked={checked === false}
                  onChange={handleChange}
                  value="following"
                  data-cy="male-input"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <Text i18nKey="minor">Minor</Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('First name')}
              {...commonProps}
              name="firstname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Last name')}
              {...commonProps}
              name="lastname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Email')}
              {...commonProps}
              name="email"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Phone number (optional)')}
              {...commonProps}
              name="phone"
              type="phone"
            />
          </Grid>
        </Grid>
        {error && (
          <Box display="flex" alignItems="center" mt={1}>
            <ErrorIcon style={{ color: 'red', fontSize: '15px' }} />
            <Typography style={{ color: 'red', fontSize: '14px' }}>
              {error}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddPatientModal;
