export function start(callback: any) {
  console.log('Requesting local stream');
  // startButton.disabled = true;
  const options = { audio: true, video: true };
  return navigator.mediaDevices
    .getUserMedia(options)
    .then(callback)
    .catch(function (e) {
      console.log('getUserMedia() error: ', e);
    });
}

export function muteAudio(stream: any) {
  stream.getTracks().forEach(function (track: any) {
    if (track.readyState === 'live' && track.kind === 'audio') {
      console.log('audio stopped internally');
      track.stop();
    }
  });
}

export function stopVideo(stream: any) {
  stream.getTracks().forEach(function (track: any) {
    if (track.readyState === 'live' && track.kind === 'video') {
      track.stop();
    }
  });
}
