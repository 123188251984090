import { useMutation, gql } from '@apollo/client';
import { GET_FINANCES } from './useGetFinances';

const WaivePayment = gql`
  mutation waivePayment($appointmentId: ID!) {
    waivePayment(appointmentId: $appointmentId) {
      patient {
        firstname
      }
    }
  }
`;

export default function useWaivePayment() {
  const [waivePayment, result] = useMutation(WaivePayment, {
    refetchQueries: [GET_FINANCES],
  });
  return { waivePayment, ...result };
}
