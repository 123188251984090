import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxWhite: {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
  hover: {
    '&:hover': {
      background: '#F9FAFB',
    },
  },
  commonVar: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.6,
    padding: '3px 7px',
    borderRadius: '16px',
    width: 'fit-content',
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  upVar: {
    color: '#067647',
    backgroundColor: '#ECFDF3',
    borderColor: '#ABEFC6',
  },
  downVar: {
    color: '#B42318',
    backgroundColor: '#FEF3F2',
    borderColor: '#FECDCA',
  },
  stableVar: {
    color: '#B54708',
    backgroundColor: '#FFFAEB',
    borderColor: '#FEDF89',
  },
  loadingVar: {
    borderRadius: '16px',
  },
}));

export default useStyles;
