import dashboard from 'assets/sidebar/dashboard.svg';
import task from 'assets/sidebar/Tasks.svg';
import schedule from 'assets/sidebar/schedule.svg';
import patient from 'assets/sidebar/Patients.svg';
import inbox from 'assets/sidebar/MessageIcon.svg';
import appointment from 'assets/sidebar/Appt.svg';
import Setting from 'assets/sidebar/Settings.svg';
import Finance from 'assets/sidebar/Finance.svg';
// import profile from '../../../assets/profile.svg';

const links = [
  { path: '/doctor/dashboard', icon: dashboard, title: 'Dashboard' },
  { path: '/doctor/schedule', icon: schedule, title: 'Schedule' },
  { path: '/doctor/task', icon: task, title: 'Tasks' },
  { path: '/doctor/patient', icon: patient, title: 'Clients' },
  { path: '/doctor/inbox', icon: inbox, title: 'Inbox' },
  // { path: '/doctor/appointment', icon: appointment, title: 'Appointments' },
  { path: '/doctor/finance', icon: Finance, title: 'Finances' },
  { path: '/doctor/admin', icon: Setting, title: 'Settings' },
  // { path: '/doctor', icon: profile, title: 'Profile' },
];
export default links;
