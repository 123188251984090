import { gql, useQuery } from '@apollo/client';

interface IUseGetDoctorArgs {
  id: string;
}

const GET_DOCTOR_BY_ID = gql`
  query fetchDoctor($DoctorId: ID!) {
    doctor(doctorId: $DoctorId) {
      _id
      noUsedFeatures
      firstname
      lastname
      languages
      middlename
      timeZone
      username
      email
      picture
      city
      country
      zipcode
      state
      suite
      phone
      address
      override {
        startDate
        endDate
      }
      availability {
        Monday {
          startDate
          endDate
        }
        Tuesday {
          startDate
          endDate
        }
        Wednesday {
          startDate
          endDate
        }
        Thursday {
          startDate
          endDate
        }
        Friday {
          startDate
          endDate
        }
        Saturday {
          startDate
          endDate
        }
        Sunday {
          startDate
          endDate
        }
      }
    }
  }
`;

export default function useGetDoctor({ id }: IUseGetDoctorArgs) {
  const { data, error, loading } = useQuery(GET_DOCTOR_BY_ID, {
    variables: { DoctorId: id },
  });
  return { data, error, loading };
}

export { GET_DOCTOR_BY_ID };
