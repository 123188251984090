import { gql, useMutation } from '@apollo/client';
import { log } from '../../utils/log';

const SEND_ROOM_MESSAGE_MUTATION = gql`
  mutation sendRoomMessage(
    $newMessage: newMessageInput!
    $roomId: String
    $senderId: String
  ) {
    addMessage(newMessage: $newMessage, roomId: $roomId, senderId: $senderId) {
      text
      from
      date
    }
  }
`;

const useAddMessage = () => {
  const [sendRoomMessage] = useMutation<unknown, any>(
    SEND_ROOM_MESSAGE_MUTATION
  );

  async function handleSendRoomMessage(newMessage: any, roomId: string) {
    try {
      return await sendRoomMessage({
        variables: {
          newMessage,
          roomId,
          senderId: sessionStorage.getItem('meetId'),
        },
      });
    } catch (e) {
      return log('error', e);
    }
  }

  return handleSendRoomMessage;
};

export default useAddMessage;
