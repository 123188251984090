import React, { ReactElement } from 'react';
import { Grid, GridProps } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  },
}));

const BigCard = ({ children, ...props }: GridProps): ReactElement => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} {...props}>
      {children}
    </Grid>
  );
};

export default BigCard;
