import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import Button from 'components/ui/Button';
import useModal from 'hooks/useModal';
import { ReactComponent as GoBackIcon } from 'assets/goback.svg';
import { useParams } from 'react-router-dom';
import PatientReports from '../PatientReports';
import PatientAvatarAndInfo from './PatientAvatar';
import { calculateAge } from 'utils/dateUtils';

import PatientTabsContent from 'components/PatientProfile/PatientNewTabs/PatientTabsContent';
import PatientTabsBar from 'components/PatientProfile/PatientNewTabs/PatientTabsBar';
const useStyles = makeStyles((theme) => ({
  fileUpload: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #D7E3F1',
    borderRadius: '12px',
    // padding: theme.spacing(2),
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    fontSize: '1.375em',
    fontWeight: 'bold',
    color: '#425364',
    marginTop: '0.5em',
  },
  bigTitle: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: '#425364',
    marginTop: '0.5em',
  },
  patientContent: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
    },
  },
  patientAvatar: {
    display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  header: {
    // background: '#F9FAFB',
    borderRadius: '16px',
    background: 'linear-gradient(324deg, #CDECE4  0%, #C7E1F8 100%)',
    border: '1px solid #B6CDF2',
  },
  patientAvatarResponsive: {
    display: 'none',
    [theme.breakpoints.only('lg')]: {
      display: 'block',
      marginTop: theme.spacing(-1),
    },
    [theme.breakpoints.only('md')]: {
      display: 'block',
      marginTop: theme.spacing(-1),
    },
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      marginTop: theme.spacing(-4),
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      marginTop: theme.spacing(-4),
    },
  },
  gridItem: {
    boxSizing: 'border-box',
    overflowX: 'hidden',
    [theme.breakpoints.only('xl')]: {
      position: 'relative',
      marginTop: '20px',
    },
    [theme.breakpoints.only('lg')]: {
      position: 'relative',
      marginTop: '20px',
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginTop: theme.spacing(4),
    },
  },
  container: {
    width: '100%',
    // boxSizing: 'border-box',
    // overflowX: 'hidden',
  },
  patientTabBar: {
    background: 'linear-gradient(324deg, #CDECE4  0%, #C7E1F8 100%)',
    paddingInline: '2rem',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '16px',
    // paddingBlock: theme.spacing(2),
  },
}));

interface IProps {
  loading: boolean;
  firstname: string;
  middlename?: string;
  lastname: string;
  email: string;
  country: string;
  phone: string;
  editable: boolean;
  completed: [string];
  picture: string;
  gender: string;
  birthday: string;
  handleOpen: () => void;
  handleEdit: () => void;
  setOpen: (value: boolean) => void;
  register: any;
  reports: any;
  setCurrentReport: any;
  handleAddReport: any;
  handleDeleteReport: any;
  addReportLoading: boolean;
  patient?: any;
}

export default function PatientInformations({
  loading,
  firstname,
  middlename,
  setCurrentReport,
  lastname,
  completed,
  email,
  country,
  addReportLoading,
  phone,
  gender,
  birthday,
  handleOpen,
  register,
  handleAddReport,
  handleDeleteReport,
  handleEdit,
  picture,
  editable,
  setOpen,
  reports,
  patient,
  ...props
}: IProps): ReactElement {
  const classes = useStyles();
  const { push, replace } = useHistory();
  const location = useLocation<{ tab: number }>();
  const { state, dispatch } = useModal();
  const { id: patientId } = useParams<{ id: string }>();
  const tabs = {
    general: 0,
    activities: 1,
    appointments: 2,
    tasks: 3,
    drugs: 4,
    billing: 5,
    settings: 6,
    messages: 7,
  };
  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };
  function handleGoBack() {
    push('/patient');
  }
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('event');
  const tabSelected = queryParams.get('tab');
  const [value, setValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== null && value !== undefined) {
        if (value === 2 && redirectStatus) {
          push(
            `/doctor/patient/${patientId}?event=${redirectStatus}&tab=${value}`
          );
        } else {
          push(`/doctor/patient/${patientId}?tab=${value}`);
        }
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [value, redirectStatus]);

  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== value) {
      setValue(Number(tabSelected));
    }
  }, [tabSelected]);
  React.useEffect(() => {
    if (redirectStatus) {
      setValue(tabs.appointments);
    }
  }, [redirectStatus]);

  React.useEffect(() => {
    if (location?.state) {
      location?.state?.tab === 1
        ? setValue(tabs.activities)
        : setValue(tabs.drugs);
    }
  }, [location?.state]);

  return (
    <Box className={classes.container}>
      <Box
        style={{
          // background: 'white',
          marginTop: '10px',
          width: '100%',
          borderRadius: '16px',
          // border: '1px solid #EAECF0 ',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        }}
      >
        <Box className={classes.header}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            boxSizing="border-box"
            className={classes.patientTabBar}
          >
            <Box className={classes.patientAvatar} width="100%">
              <PatientAvatarAndInfo
                patientId={patientId}
                loading={loading}
                editable={false}
                picture={picture}
                firstname={firstname}
                lastname={lastname}
                middlename={middlename}
                email={email}
                phone={phone}
                gender={gender}
                age={calculateAge(birthday)}
                completed={completed}
                patient={patient}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        mt={2}
        style={{
          backgroundColor: 'white',
          borderTopRightRadius: '16px',
          borderTopLeftRadius: '16px',
        }}
        py={2}
        px={'2rem'}
      >
        <PatientTabsBar
          isLoading={loading}
          completed={completed}
          value={value}
          handleChange={handleChange}
        />
        <PatientTabsContent value={value} isPatient patientId={patientId} />
      </Box>
    </Box>
  );
}

PatientInformations.fragments = {
  Information: gql`
    fragment Information on Patient {
      firstname
      middlename
      lastname
      birthday
      gender
      address
      email
      bloodType
      phone
      picture
      # allergies
      languages
      # diseases
    }
  `,
};
