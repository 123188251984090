import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  BoxProps,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';
import Card from './Card';
import { useProvider } from './state/Provider';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F9FAFB',
    maxHeight: 'calc(100vh - 170px)',
    // overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
  },
  title: {
    color: '#475467',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  addTask: {
    cursor: 'pointer',
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.black.main,
  },
  droppableArea: {
    minHeight: 80,
    flexGrow: 1,
    overflowY: 'auto',
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menu: {
    width: 240,
  },
  listName: {
    backgroundColor: '#F9FAFB',
    // paddingBottom: theme.spacing(2),
    // paddingTop: theme.spacing(2),
    color: 'white',
    borderRadius: '8px',
  },
}));

interface IKanbanListProps extends BoxProps {
  listId: string;
  list: any;
  isDashboard: any;
  className?: string;
  handleOpenAddTaskModal: () => void;
  loading?: boolean;
}

export const convertString = {
  overdue: 'To do',
  pending: 'In progress',
  completed: 'Done',
};

export default function List({
  className,
  listId,
  list,
  isDashboard,
  handleOpenAddTaskModal,
  loading = false,
  ...rest
}: IKanbanListProps) {
  const classes = useStyles({ type: list?.name });
  const { handleViewTask, handleDeleteTask } = useProvider();
  const { t } = useTranslation();

  return (
    <Skeleton loading={loading}>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Paper elevation={0} className={classes.inner}>
          <Box
            py={1}
            px={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            className={classes.listName}
          >
            <Typography className={classes.title}>
              {t(convertString[list?.name])}
            </Typography>
          </Box>
          <Droppable droppableId={list._id} type="card">
            {(provided: any) => (
              <div ref={provided.innerRef} className={classes.droppableArea}>
                {list.tasks.map((card: any, index: any) => (
                  <Draggable
                    draggableId={card._id}
                    index={index}
                    key={card._id}
                  >
                    {(provided: any, snapshot: any) => (
                      <Card
                        handleDelete={handleDeleteTask}
                        onClick={() => {
                          handleViewTask(card);
                        }}
                        cardId={card._id}
                        dragging={snapshot.isDragging}
                        index={index}
                        list={list}
                        data={card}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style }}
                        data-cy={`task-card-${index}`}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDashboard={isDashboard}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Paper>
      </Box>
    </Skeleton>
  );
}

List.defaultProps = {
  className: '',
};
