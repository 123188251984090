import React from 'react';
import './style.css';
import {
  tryParseJSON,
  editorStateFromStringifiedRaw,
} from '@eggmed/common/utils/DraftUtils';
import { Editor } from 'draft-js';
import dayjs from 'dayjs';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import Skeleton from 'components/ui/Skeleton';
import { useTranslation } from 'react-i18next';

const Stepper = ({ certifs, loading }) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%', paddingLeft: '1rem' }}>
      {!loading ? (
        <ol className="c-timeline">
          {certifs?.map((el) => {
            const date = timeConverter(
              el?.yearOfDegree || new Date().getMilliseconds().toString()
            );
            const title = `${t(el?.educationLevel)} - ${el?.certificationName}`;
            return (
              <li className="c-timeline__itemDoctor">
                <div className="c-timeline__contentDoctor">
                  <div style={{ marginTop: '-15px' }}>
                    <h3 className="c-timeline__titleDoctor">{title}</h3>
                    <p className="c-timeline__dateDoctor">
                      {dayjs(date).format('YYYY')}
                    </p>
                    {!tryParseJSON(el?.location) ? (
                      <p className="c-timeline__descDoctor">
                        {el?.certificationLocation}
                      </p>
                    ) : (
                      <p className="c-timeline__descDoctor">
                        <Editor
                          onChange={() => {}}
                          editorState={editorStateFromStringifiedRaw(
                            el?.advice,
                            false
                          )}
                          readOnly
                        />
                      </p>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      ) : (
        <ol>
          <li className="c-timeline__itemDoctor">
            <div className="c-timeline__contentDoctor skeleton">
              <div>
                <h3 className="c-timeline__titleDoctor">
                  <Skeleton
                    loading={loading}
                    type="text"
                    width="7rem"
                    height="1.5rem"
                  >
                    <></>
                  </Skeleton>
                </h3>

                <p className="c-timeline__descDoctor">
                  <Skeleton
                    loading={loading}
                    type="text"
                    width="6rem"
                    height="1.5rem"
                  >
                    <></>
                  </Skeleton>
                </p>
              </div>
              <p className="c-timeline__dateDoctor">
                <Skeleton
                  loading={loading}
                  type="text"
                  width="5rem"
                  height="1.5rem"
                >
                  <></>
                </Skeleton>
              </p>
            </div>
          </li>
        </ol>
      )}
    </div>
  );
};

export default Stepper;
