import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/successSummary.svg';
import Text from 'Text';
import { useStyles } from './styles';

function SuccessSnackBar({
  handleClick,
  setDownloadStarted,
}: {
  handleClick: () => void;
  setDownloadStarted: (value: boolean) => void;
}) {
  const classes = useStyles({});
  useEffect(() => {
    const timer = setTimeout(() => {
      setDownloadStarted(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [setDownloadStarted]);
  return (
    <Box className={classes.successContainer}>
      <ErrorIcon />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text
          i18nKey="Summary-downloaded"
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600,
            color: '#3B7C0F',
          }}
        >
          Summary downloaded
        </Text>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Text
            i18nKey="Summary-download-text"
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 400,
              color: '#3B7C0F',
            }}
          >
            If your download hasn’t started
          </Text>
          <Text
            i18nKey="click-here"
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              color: '#147AF3',
              textDecoration: 'underline',
              marginLeft: '4px',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            Click here
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default SuccessSnackBar;
