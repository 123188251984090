import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import down from 'assets/dashboard/down.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '0.5px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '180px',
    height: '44px',
    borderRadius: '6px',
    color: '#425364',
    positon: 'relative',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  down: {
    position: 'absolute',
    right: '5px',
  },
  paper: {
    width: '158px',
    marginTop: theme.spacing(8),
  },
}));
interface IMenuProps {
  menuName?: string;
  setMenuName?: (menuName: string) => void;
  MenuValue?: string;
  setMenuValue?: (menuValue: string) => void;
  className?: string;
  classArrow?: string;
  values: string[];
}

export default function SimpleMenu({
  values,
  menuName,
  setMenuName,
  setMenuValue,
  className,
  classArrow,
}: IMenuProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        className={clsx(className, classes.root)}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {menuName}
        <img src={down} className={clsx(classes.down, classArrow)} alt="down" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock={false}
      >
        <MenuItem
          onClick={() => {
            setMenuName('All');
            setMenuValue('');
            handleClose();
          }}
        >
          {t('All')}
        </MenuItem>
        {values.map((value) => (
          <MenuItem
            onClick={() => {
              setMenuName(value);
              setMenuValue(value);
              handleClose();
            }}
            style={{ textTransform: 'capitalize' }}
          >
            {t(value)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
