import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const FocusArea = ({ focusArea, loading }): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const { t } = useTranslation();
  return (
    <Box mt="20px">
      <Skeleton loading={loading} type="text" width="7rem" height="2.5rem">
        <Text i18nKey="focusAreas" className={classes.focusTitle}>
          Focus areas
        </Text>
      </Skeleton>
      {loading ? (
        <Box
          mt="14px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ gap: '5px', flexWrap: 'wrap' }}
        >
          {[1, 2, 3, 4, 5, 6].map((area) => (
            <Skeleton loading={loading} type="text" width="80px" height="30px">
              <Box className={classes.focusArea}></Box>
            </Skeleton>
          ))}
        </Box>
      ) : (
        <Box
          mt="14px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ gap: '5px', flexWrap: 'wrap' }}
        >
          {focusArea.map((area) => (
            <Box className={classes.focusArea}>
              {t(area.charAt(0).toUpperCase() + area.slice(1).toLowerCase())}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
export default FocusArea;
