import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../styles';

interface ISummary {
  name: string;
  email: string;
  phone: string;
  address: string;
}

export const Summary = ({ name, email, phone, address }: ISummary) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.common}>{email}</Typography>
      <Typography className={classes.common}>{phone}</Typography>
      <Typography className={classes.common}>{address}</Typography>
    </Box>
  );
};
