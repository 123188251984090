import React, { ReactElement } from 'react';
import {
  Box,
  FormLabel,
  makeStyles,
  FilledTextFieldProps,
} from '@material-ui/core';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/Error';
import Input from 'components/ui/Inputs';
import useStyles from './styles';

interface IInputBlockProps extends Omit<FilledTextFieldProps, 'variant'> {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  type: string;
  name: string;
  Icon?: any;
  center?: boolean;
  contentClassName?: string;
  labelClassName?: string;
  contentStyle?: any;
  defaultValue?: any;
  margin?: any;
  isPatient?: boolean;
}

export default function InputBlock({
  label,
  name,
  errors,
  Icon,
  contentClassName,
  contentStyle,
  labelClassName,
  control,
  register,
  center = true,
  margin = true,
  isPatient = false,
  ...props
}: IInputBlockProps) {
  const classes = useStyles();
  return (
    <Box my={margin && 1}>
      {label && (
        <FormLabel
          className={clsx(
            classes.bottomSpacing,
            isPatient ? classes.labelPatient : classes.label,
            !isPatient && classes.leftSpacing,
            labelClassName
          )}
        >
          {label}
        </FormLabel>
      )}
      <Box
        style={contentStyle}
        className={clsx(
          center && classes.formControl,
          Icon === undefined && classes.leftSpacing,
          classes.topSpacingDesc,
          contentClassName
        )}
      >
        {Icon && (
          <Icon className={clsx(classes.icon, classes.betweenSpacing)} />
        )}
        <Input
          inputRef={register}
          control={control}
          name={name}
          variant="filled"
          helperText={
            errors &&
            errors[name] && (
              <Box mt={0.5} data-cy="error-message">
                <ErrorIcon className={classes.errorIcon} />
                {errors[name]?.message}
              </Box>
            )
          }
          error={errors && !!errors[name]}
          {...props}
        />
      </Box>
    </Box>
  );
}
