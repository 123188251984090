import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  InputBase,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/form/toolbar/edit.svg';
import { ReactComponent as Valid } from 'assets/form/toolbar/valid.svg';
import { ReactComponent as NotValid } from 'assets/form/toolbar/not-valid.svg';
import { useEditorContext } from '../EditorContextProvider';
import { truncateText } from 'utils/FormatText';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '10px',
    maxWidth: '18.75rem',
  },
  input: {
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#4F4F4F',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '10px 14px',
    gap: '10px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  boxTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  text: {
    textOverflow: ' [..]',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#4F4F4F',
  },
  iconButton: {
    cursor: 'pointer',
  },
  tooltip: {
    borderRadius: '8px',
    backgroundColor: '#101828',
    fontFamily: 'Inter, sans-serif',
    color: '#FFFFFF',
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '12px',
    textAlign: 'left',
  },
}));

const EditableText = () => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };
  const handleValidText = () => {
    // Perform actions with the current text if needed
  };

  const handleBlur = () => {
    setIsEditing(false);
  };
  const { handleChangeText, formTitle } = useEditorContext();

  return (
    <Box
      className={classes.root}
      style={{ marginRight: isEditing ? '7rem' : '14rem' }}
    >
      {isEditing ? (
        <InputBase
          inputRef={inputRef}
          value={formTitle}
          onChange={(e) => handleChangeText(e.target.value)}
          onBlur={handleBlur}
          className={classes.input}
          fullWidth
        />
      ) : (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={formTitle}
          placement="top-start"
        >
          <Box className={classes.boxTitle}>
            <Typography className={classes.text}>
              {truncateText(formTitle, 20)}
            </Typography>
          </Box>
        </Tooltip>
      )}
      <Box onClick={handleEditClick} className={classes.iconButton}>
        {!isEditing && <EditIcon />}
        {isEditing && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Valid onClick={handleValidText} />
            <NotValid onClick={() => setIsEditing(false)} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditableText;
