import React, { ReactElement } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Avatar,
  Divider,
  Grid,
} from '@material-ui/core';
import Switch from 'components/ui/Switch';
import { TypeEnum } from './DoctorAccessPage';

const useStyles = makeStyles((theme) => ({
  AccessType: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#425364',
  },
  AccessDesc: {
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '130%',
    color: '#666666',
  },
}));

interface IAccessTypeProps {
  title: string;
  desc: string;
  type: TypeEnum;
  access: string[];
  register: any;
}

const AccessType = ({
  title,
  desc,
  type,
  access,
  register,
}: IAccessTypeProps): ReactElement => {
  const classes = useStyles();

  return (
    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
      <Typography className={classes.AccessType}>{title}</Typography>
      <Grid
        container
        xs={12}
        lg={12}
        xl={12}
        md={12}
        sm={12}
        justifyContent="space-between"
      >
        <Grid item xs={12} lg={9} xl={9} md={8} sm={12}>
          <Typography className={classes.AccessDesc}>{desc}</Typography>
        </Grid>
        <Grid item xs={12} lg={1} xl={1} md={1} sm={12}>
          <Switch
            name="permissions"
            value={type}
            inputRef={register}
            defaultChecked={access?.some((e) => e == type)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AccessType;
