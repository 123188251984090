import React, { ReactElement } from 'react';
import { InputLabel, makeStyles, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFormContext } from 'react-hook-form';
import InputBlock from 'pages/StaffProfile/editModalTabs/InputBlock';
import ScrollBar from 'components/ui/ScrollBar';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  Modalcontainer: {
    backgroundColor: 'white',
    borderRadius: '12px',
    marginTop: '1rem',

    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '70vh',
    },
  },
  CloseModal: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    cursor: 'pointer',
  },
  CloseIcon: {
    width: theme.typography.pxToRem(23.5),
  },
  title: {
    marginBottom: theme.spacing(6),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '42.5px',
    lineHeight: '50px',
    color: '#1F61DC',
  },
  inputLabel: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#707090',
    marginBottom: theme.spacing(1),
  },
  buttonBox: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  button: {
    width: '6rem',
    height: '36px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  acceptButton: {
    background: '#2DC76D',
  },
  inputbox: {
    marginBottom: theme.spacing(0.5),
    position: 'relative',
  },
  buttonGrid: {
    marginBlock: theme.spacing(8),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginBlock: theme.spacing(0),
      marginBotom: theme.spacing(3),
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginTop: '3px',
    marginLeft: '1px',
    fontFamily: 'Roboto',
    letterSpacing: '0.03333em',
  },
}));
const StaffModal = ({
  error,
  setError,
}: {
  error: string;
  setError: (err: string) => void;
}): ReactElement => {
  const classes = useStyles();
  const { register, control, errors } = useFormContext();
  const { t } = useTranslation();
  const commonProps = {
    inputRef: register,
    control,
    variant: 'filled',
    errors,
  };
  return (
    <Box className={classes.Modalcontainer}>
      <ScrollBar className={classes.ScrollContainer}>
        <Box px="3rem">
          <Grid container spacing={3}>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.inputbox}
            >
              <InputBlock
                label="First name"
                type="text"
                name="firstname"
                {...commonProps}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.inputbox}
            >
              <InputBlock
                label="Middle name"
                type="text"
                name="middlename"
                {...commonProps}
              />
            </Grid>

            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.inputbox}
            >
              <InputBlock
                label="Last name"
                type="text"
                name="lastname"
                {...commonProps}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.inputbox}
            >
              <InputBlock
                type="select"
                name="practice"
                register={register}
                control={control}
                label="Practice"
                options={['Nurse', 'Secretary', 'Other']}
              />
              <Box className={classes.error}>
                {errors && errors.practice && (
                  <span data-cy="error-message" className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t(errors?.practice?.message)}
                  </span>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.inputbox}
            >
              <InputBlock
                label="Email"
                type="text"
                name="email"
                onChange={() => setError('')}
                {...commonProps}
              />
              <Box className={classes.error}>
                {error && (
                  <span data-cy="error-message" className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {t(error)}
                  </span>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ScrollBar>
    </Box>
  );
};

export default StaffModal;
