import { gql, useMutation } from '@apollo/client';
import { addImaging, addImagingVariables } from './__generated__/addImaging';
export interface addImagingInput {
  doctorId: string;
  appointmentId: string;
  patientId: string;
  type?: string | null;
  name?: string | null;
  notes?: (string | null)[] | null;
}
const ADD_IMAGING_REQUEST = gql`
  mutation addImaging($imagingInput: addImagingInput!) {
    addImaging(imagingInput: $imagingInput) {
      _id
      type
      name
      notes
    }
  }
`;

export default function useAddImaging() {
  const [createImaging, result] = useMutation<addImaging, addImagingVariables>(
    ADD_IMAGING_REQUEST,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            appointmentImaging(imaging) {
              return [...imaging, data?.addImaging];
            },
          },
        });
      },
    }
  );
  return { createImaging, ...result };
}
