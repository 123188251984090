import { gql, useMutation } from '@apollo/client';

const DELETE_PATIENT_ACTIVITY = gql`
  mutation deletePatientActivities($id: ID!) {
    deletePatientActivities(id: $id) {
      _id
    }
  }
`;

const useDeletePatientActivity = () => {
  return useMutation(DELETE_PATIENT_ACTIVITY);
};

export default useDeletePatientActivity;
