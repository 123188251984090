import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const UPDATE_CREDIT_CARD_BY_ID = gql`
  mutation updateCreditCardbyID(
    $creditCardId: ID!
    $updateCreditCard: UpdateCreditCard
    $isSelect: Boolean
  ) {
    updateCreditCardbyID(
      creditCardId: $creditCardId
      updateCreditCard: $updateCreditCard
      isSelect: $isSelect
    ) {
      cardNumber
      expirationCard
      month
      year
      securityCode
      selectedCreditCard
    }
  }
`;

export default function useEditCreditCardbyID(): {
  updateCreditCardbyID: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  loading: boolean;
} {
  const [updateCreditCardbyID, { loading }] = useMutation(
    UPDATE_CREDIT_CARD_BY_ID,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            userCreditCard() {
              return data?.editCreditCardbyID;
            },
          },
        });
      },
    }
  );
  return { updateCreditCardbyID, loading };
}
