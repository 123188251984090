import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as HeightIcon } from 'assets/icons/heighticongray.svg';
import { ReactComponent as WeightIcon } from 'assets/icons/weighticongray.svg';
import { ReactComponent as BloodIcon } from 'assets/icons/bloodicongray.svg';
import { ReactComponent as InchIcon } from 'assets/inch.svg';
import { ReactComponent as FtIcon } from 'assets/ft.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(29),
    fontWeight: 900,
    color: '#425364',
    marginTop: '0.5em',
    overflowWrap: 'anywhere',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '19.36px',
    color: '#696F79',
  },
  aboutLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '19.36px',
    color: '#696F79',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  titleWrapper: {
    // backgroundColor: '#F8FAFB',
    padding: '10px',
    // border: '1px solid #D7E3F1',
    borderRadius: '12px',
  },
  fieldIcon: {
    color: '#CED5D8',
  },
  inputStyle: {
    marginTop: theme.spacing(1),
    height: theme.typography.pxToRem(170),
    maxHeight: theme.typography.pxToRem(170),
  },
  hideOverflow: {
    '& .MuiInputBase-root': {
      overflow: 'hidden',
    },
    '& .MuiFilledInput-input': {
      padding: '16px 0 16px 16px',
    },
  },
}));

const inputTopSpacing = { marginTop: '20px', overflowY: 'hidden' };
const styles = 1.2;

export enum heightTypeEnum {
  cm = 'cm',
  inch = 'inch',
}

export const GeneralInformationForm = (
  errors: any,
  defaultValues: any,
  setHeightType: React.Dispatch<React.SetStateAction<heightTypeEnum>>,
  heightType: heightTypeEnum
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonProps = { labelClassname: classes.label };

  const HeightType =
    heightType === heightTypeEnum.cm
      ? [
          {
            name: 'height',
            col: 2,
            lg: 6,
            md: 4,
            sm: 12,
            xs: 6,
            type: 'number',
            style: inputTopSpacing,
            defaultValue: defaultValues.height,
            variant: 'filled',
            'data-cy': 'height-input',
            helperText: errors.height && (
              <span>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.height?.message}
              </span>
            ),
            error: !!errors?.height,
            placeholder: '150',
          },
        ]
      : [
          {
            name: 'ft',
            col: 3,
            lg: 3,
            md: 3,
            sm: 6,
            xs: 6,
            type: 'number',
            style: inputTopSpacing,
            className: classes.hideOverflow,
            defaultValue: defaultValues.ft,
            variant: 'filled',
            'data-cy': 'height-input',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <FtIcon style={{ marginRight: '-14.5px' }} />
                </InputAdornment>
              ),
            },
            helperText: errors.ft && (
              <span>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.ft?.message}
              </span>
            ),
            error: !!errors?.height,
            placeholder: '150',
          },
          {
            name: 'inch',
            col: 3,
            lg: 3,
            md: 3,
            sm: 6,
            xs: 6,
            type: 'number',
            style: inputTopSpacing,
            className: classes.hideOverflow,
            defaultValue: defaultValues.inch,
            variant: 'filled',
            'data-cy': 'height-input',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <InchIcon style={{ marginRight: '-14.5px' }} />
                </InputAdornment>
              ),
            },
            helperText: errors.inch && (
              <span>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.inch?.message}
              </span>
            ),
            error: !!errors?.inch,
            placeholder: '150',
          },
        ];
  const generalInfo = [
    {
      name: 'firstname',
      col: 6,
      lg: 6,
      md: 6,
      sm: 12,
      xs: 12,
      type: 'text',
      'data-cy': 'firstname-input',
      label: t('First name'),
      variant: 'filled',
      defaultValue: defaultValues.firstname,
      placeholder: 'John',
      helperText: errors.firstname && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.firstname?.message}
        </span>
      ),
      error: !!errors?.firstname,
      ...commonProps,
    },
    {
      name: 'middlename',
      col: 6,
      lg: 6,
      sm: 12,
      md: 6,
      xs: 12,
      type: 'text',
      'data-cy': 'middlename-input',
      label: t('Middle name'),
      variant: 'filled',
      placeholder: 'Patricio',
      ...commonProps,
    },
    {
      name: 'lastname',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'text',
      'data-cy': 'lastname-input',
      label: t('Last name'),
      variant: 'filled',
      defaultValue: defaultValues.lastname,
      placeholder: 'Doe',
      helperText: errors.lastname && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.lastname?.message}
        </span>
      ),
      error: !!errors?.lastname,
      ...commonProps,
    },
    {
      name: 'birthday',
      col: 6,
      sm: 12,
      xs: 12,
      lg: 6,
      md: 6,
      type: 'date',
      label: t('Date of birth'),
      'data-cy': 'date-input',
      defaultValue: defaultValues.birthday,
      variant: 'filled',
      helperText: errors.birthday && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.birthday?.message}
        </span>
      ),
      error: !!errors?.birthday,
      ...commonProps,
    },
    {
      name: 'gender',
      col: 12,
      sm: 12,
      xs: 12,
      lg: 12,
      md: 12,
      type: 'select',
      defaultValue: defaultValues.gender,
      'data-cy': 'gender-input',
      options: ['male', 'female', 'other', 'prefer not to say'],
      label: t('Gender'),
      variant: 'filled',
      helperText: errors.gender && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.gender?.message}
        </span>
      ),
      error: !!errors?.gender,
      ...commonProps,
    },
  ];
  const address = [
    {
      name: 'country',
      col: 12,
      sm: 12,
      xs: 12,
      lg: 12,
      md: 12,
      type: 'country',
      label: t('Country'),
      'data-cy': 'country-input',
      defaultValue: defaultValues.country,
      variant: 'filled',
      helperText: errors.country && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.country?.message}
        </span>
      ),
      error: !!errors?.country,
      ...commonProps,
    },

    {
      name: 'address',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'text',
      label: t('Street Address'),
      defaultValue: defaultValues.address,
      placeholder: '123 Main Street',
      variant: 'filled',
      'data-cy': 'address-input',
      helperText: errors.address && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.address?.message}
        </span>
      ),
      error: !!errors?.address,
      ...commonProps,
    },
    {
      name: 'suite',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'text',
      defaultValue: defaultValues.suite,
      placeholder: 'Apt 4B',
      'data-cy': 'suite-input',
      label: t('Street Address 2 (optional)'),
      variant: 'filled',
      helperText: errors.suite && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.suite?.message}
        </span>
      ),
      error: !!errors?.suite,
      ...commonProps,
    },

    {
      name: 'state',
      col: 3,
      lg: 3,
      sm: 12,
      xs: 12,
      md: 3,
      type: 'text',
      defaultValue: defaultValues.state,
      placeholder: 'New York',
      label: t('State'),
      variant: 'filled',
      'data-cy': 'state-input',
      helperText: errors.state && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.state?.message}
        </span>
      ),
      error: !!errors?.state,
      ...commonProps,
    },
    {
      name: 'city',
      col: 3,
      sm: 12,
      xs: 12,
      lg: 3,
      md: 3,
      type: 'text',
      label: t('City'),
      'data-cy': 'city-input',
      defaultValue: defaultValues.city,
      placeholder: 'New York',
      variant: 'filled',
      helperText: errors.city && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.city?.message}
        </span>
      ),
      error: !!errors?.city,
      ...commonProps,
    },
    {
      name: 'zipcode',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'number',
      defaultValue: defaultValues.zipcode,
      placeholder: '10001',
      label: t('Zip code'),
      variant: 'filled',
      'data-cy': 'zipcode-input',
      helperText: errors.zipcode && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.zipcode?.message}
        </span>
      ),
      error: !!errors?.zipcode,
      ...commonProps,
    },
  ];

  const basicInfo = [
    {
      name: 'heightType',
      col: 6,
      lg: 6,
      md: 6,
      sm: 12,
      xs: 12,
      type: 'select',
      options: ['inch', 'cm'],
      value: heightType,
      defaultValue: defaultValues.heightType,
      onChange: (e) => {
        setHeightType(e.target.value);
      },
      'data-cy': 'heightype-input',
      label: t('Height'),
      variant: 'filled',

      error: !!errors?.height,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <HeightIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
      ...commonProps,
    },
    ...HeightType,
    {
      name: 'weightType',
      col: 6,
      md: 6,
      lg: 6,
      xs: 12,
      sm: 12,
      type: 'select',
      options: ['kg', 'lb'],
      label: t('Weight'),
      variant: 'filled',
      'data-cy': 'weightype-input',
      defaultValue: defaultValues.weightType,
      helperText: errors.weight && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.weight?.message}
        </span>
      ),
      error: !!errors?.weight,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <WeightIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
      ...commonProps,
    },
    {
      name: 'weight',
      col: 6,
      md: 6,
      lg: 6,
      xs: 12,
      sm: 12,
      type: 'number',
      style: inputTopSpacing,
      // className: classes.inputHeight,
      variant: 'filled',
      'data-cy': 'weight-input',
      defaultValue: defaultValues.weight,
      placeholder: '80',
      helperText: errors.weight && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.weight?.message}
        </span>
      ),
      error: !!errors?.weight,
      ...commonProps,
      // InputProps: {
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <WeightIcon className={classes.fieldIcon} />
      //     </InputAdornment>
      //   ),
      // },
    },
    {
      name: 'bloodType',
      col: 12,
      md: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      type: 'select',
      label: t('Blood group'),
      'data-cy': 'bloodtype-input',
      defaultValue: defaultValues.bloodType,
      options: ['A', 'AB', 'B', 'O'],
      variant: 'filled',
      helperText: errors.bloodType && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.bloodType?.message}
        </span>
      ),
      error: !!errors?.lastname,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <BloodIcon className={classes.fieldIcon} />
          </InputAdornment>
        ),
      },
      ...commonProps,
    },
  ];
  const description = [
    {
      name: 'email',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'text',
      label: t('Email'),
      'data-cy': 'email-input',
      defaultValue: defaultValues.email,
      placeholder: 'name@company.com',
      variant: 'filled',
      helperText: errors.email && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.email?.message}
        </span>
      ),
      error: !!errors?.email,
      ...commonProps,
    },
    {
      name: 'phone',
      col: 6,
      lg: 6,
      sm: 12,
      xs: 12,
      md: 6,
      type: 'phone',
      label: t('Phone'),
      'data-cy': 'phone-input',
      variant: 'filled',
      stylePhone: styles,
      defaultValue: defaultValues.phone,
      placeholder: '(555) 123-4567',
      helperText: errors.phone && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.phone?.message}
        </span>
      ),
      error: !!errors?.phone,
      ...commonProps,
    },
    {
      name: 'description',
      type: 'draftjs',
      label: t('Description'),
      'data-cy': 'description-input',
      col: 12,
      lg: 12,
      md: 12,
      xs: 12,
      sm: 12,
      labelClassname: classes.aboutLabel,
      inputClassName: classes.inputStyle,
      defaultValue: defaultValues.description,
      placeholder:
        'I enjoy outdoor activities and lead a healthy lifestyle. Looking for guidance on maintaining a balanced diet.',
      variant: 'filled',
      helperText: errors.description && (
        <span>
          <ErrorIcon className={classes.errorIcon} />
          {errors?.description?.message}
        </span>
      ),
      error: !!errors?.description,
    },
  ];
  return { generalInfo, address, description, basicInfo };
};
