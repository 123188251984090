export const permissions = [
  'a_general',
  'a_history',
  'a_immunization',
  'a_allergies',
  'a_conditions',
  'a_labs',
  'a_drugs',
]
  .sort()
  .slice();
