import React, { ReactElement } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from 'components/ui/Button';
import clsx from 'clsx';
import { ReactComponent as Alert } from 'assets/blueAlertTriangle.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modalDisconTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
  },
  Modalcontainer: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '25vw',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    // width: '6rem',
    // height: '36px',
    color: '#344054',
    width: '90px',
    height: '44px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '10px 16px 10px 16px',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Inter, sans-serif',
  },
  confirmBtn: {
    width: '99px',
    height: '44px',
    padding: '10px 16px 10px 16px',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #0265DC',
    color: 'white',
    backgroundColor: '#0265DC',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontFamily: 'Inter, sans-serif',
    '&:hover': {
      background: '#0265DC',
    },
  },
  modalBox: {
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '28vw',
    backgroundColor: 'white',
    borderRadius: '12px',
    overflow: 'hidden',
    height: 'auto',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '70vw',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '25vw',
    },
  },
  subtitle: {
    width: '19vw',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#475467',
  },
  text: {
    [theme.breakpoints.between('sm', 'md')]: {
      width: '70vw',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '21vw',
    },
  },
}));
const HIPAAModal = ({ handleClose }): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.modalBox}>
      <Box display="flex">
        <Alert />
        <Box className={classes.text} ml={2}>
          <Typography className={classes.modalDisconTitle}>
            {t('HIPAA Compliance Reminder')}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('HIPPA_Alert')}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={4}>
        <Button className={classes.button} onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleClose} className={classes.confirmBtn}>
          {t('Confirm')}
        </Button>
      </Box>
    </Box>
  );
};

export default HIPAAModal;
