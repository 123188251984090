import { gql, useMutation } from '@apollo/client';

const LEAVE_DOCTOR_WAITING_ROOM = gql`
  mutation leaveDoctorWaitingRoom($roomId: String) {
    leaveDoctorRoom(roomId: $roomId)
  }
`;

const useLeaveDoctorWaitingRoom = () => {
  const [leaveDoctorWaitingRoom, result] = useMutation(
    LEAVE_DOCTOR_WAITING_ROOM
  );

  async function handleLeaveDoctorWaitingRoom(
    roomId: string,
    handleError?: (e: any) => void
  ) {
    try {
      const resultData = await leaveDoctorWaitingRoom({
        variables: { roomId },
      });
      return resultData;
    } catch (e) {
      return handleError && handleError(e);
    }
  }
  return { ...result, handleLeaveDoctorWaitingRoom };
};

export default useLeaveDoctorWaitingRoom;
