import { Box, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import { Controller } from 'react-hook-form';

interface IFormQuestion {
  register: any;
  control: any;
  errors: any;
  question: string;
  name: string;
  isShort?: boolean;
  handleInputChange: any;
  questionIndex: number;
}

export const FormQuestion = ({
  register,
  control,
  errors,
  question,
  name,
  isShort = false,
  handleInputChange,
  questionIndex,
}: IFormQuestion) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>
      <TextField
        className={classes.textField}
        variant="outlined"
        multiline={!isShort}
        minRows={6}
        maxRows={6}
        onChange={(e) => handleInputChange(questionIndex, e.target.value)}
      />
    </Box>
  );
};
