import { gql, useMutation } from '@apollo/client';
import { log } from '../../utils/log';

const JOINED_VIDEO_ROOM_MUTATION = gql`
  mutation joinedVideoRoom($roomId: String!, $userId: String!) {
    joinedVideoRoom(roomId: $roomId, userId: $userId)
  }
`;

const useJoinedVideoRoom = () => {
  const [joinedVideoRoom, result] = useMutation<unknown, any>(
    JOINED_VIDEO_ROOM_MUTATION
  );

  async function handleJoinedVideoRoom(roomId: string, userId: string) {
    try {
      log('log', `User:${userId} Joining room:${roomId}`);
      return await joinedVideoRoom({
        variables: {
          roomId,
          userId,
        },
      });
    } catch (e) {
      return log(
        'error',
        `User:${userId} Error while Joining room:${roomId}`,
        e
      );
    }
  }
  return { handleJoinedVideoRoom, ...result };
};

export default useJoinedVideoRoom;
