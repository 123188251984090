import { IAction } from 'shared';
import { IAppointmentListPageContext } from 'shared/interfaces/appointment.list.interface';
import {
  OPEN_DROPDOWN_MENU,
  SET_CURRENT_ROW,
  CLOSE_DROPDOWN_MENU,
  SET_PAGE,
} from './actions';

export function reducer(state: IAppointmentListPageContext, action: IAction) {
  switch (action.type) {
    case OPEN_DROPDOWN_MENU: {
      return { ...state, dropDownElement: action.payload };
    }
    case SET_CURRENT_ROW: {
      return { ...state, currentRow: action.payload };
    }
    case CLOSE_DROPDOWN_MENU: {
      return { ...state, dropDownElement: null };
    }
    case SET_PAGE: {
      return { ...state, currentPage: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}
