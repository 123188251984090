import React from 'react';
import { useStyles } from '../styles';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/emptySleep.svg';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/emptyMood.svg';
import { ReactComponent as ReflectionsIcon } from 'assets/patientActivities/emptyReflection.svg';
import { FormProvider } from 'react-hook-form';
import Preview from '../commun/Preview';
import Form from '../commun/Form';
import Steps from '../commun/Steps';
import { IPatientActivities } from '../index';
import { TypeEnum as activityType } from './Activity';
import FirstStep from '../Reflection/ModalSteps/FirstStep';
import useHandlePatientActivities from './useHandlePatientActivities';
import { TypeEnum as patientActivity } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

export interface SleepModalProps {
  name: string;
  handleClose: () => void;
  defaultValues: IPatientActivities;
  doctorAndPatientId: { doctor: string; patient: string };
  type: activityType;
  handleCloseAssign?: () => void;
}
function SleepModal({
  name,
  handleClose,
  defaultValues,
  doctorAndPatientId,
  type,
  handleCloseAssign,
}: SleepModalProps) {
  const { t } = useTranslation();
  const classes = useStyles({ selected: false });
  const {
    selected,
    setSelected,
    step,
    selectedValues,
    setSelectedValues,
    handleNext,
    handleBack,
    handleSubmit,
    onSubmit,
    methods,
    loading,
  } = useHandlePatientActivities({
    defaultValues,
    type,
    doctorAndPatientId,
    handleClose,
    handleCloseAssign,
    filter: convertFilterType(type),
  });
  const iconType = {
    [activityType.SLEEP]: <SleepIcon className={classes.previewIllustration} />,
    [activityType.REFLECTION]: (
      <ReflectionsIcon className={classes.previewIllustration} />
    ),
    [activityType.MOOD]: <MoodIcon className={classes.previewIllustration} />,
  };
  function convertFilterType(type: activityType) {
    switch (type) {
      case activityType.SLEEP:
        return patientActivity.Sleep;
      case activityType.MOOD:
        return patientActivity.Mood;
      case activityType.REFLECTION:
        return patientActivity.Reflection;
      default:
        return patientActivity.Reflection;
    }
  }
  const submitText = () => {
    switch (type) {
      case activityType.SLEEP:
        return t('Assign');
      case activityType.REFLECTION:
        return step === 0 ? t('Continue') : t('Assign');
      case activityType.MOOD:
        return t('Assign');
      default:
        return t('Assign');
    }
  };
  const cancelText = () => {
    if (type === activityType.REFLECTION && step != 0) {
      return t('Back');
    }
    return t('Cancel');
  };

  const closeModal = () => {
    if (type === activityType.REFLECTION && step > 0) {
      handleBack();
    } else {
      handleClose();
    }
  };
  const displayPreview = () => {
    if (type === activityType.REFLECTION && step === 1) {
      return true;
    } else if (type != activityType.REFLECTION && step === 0) {
      return true;
    }
    return false;
  };
  const buttonVisible = {
    [activityType.SLEEP]: [0],
    [activityType.REFLECTION]: [0, 1],
    [activityType.MOOD]: [0],
  };

  const reflectionSteps = [
    <FirstStep setSelected={setSelected} name={name} selected={selected} />,
  ];
  const communSteps = [
    <Form
      selected={selected}
      sleepForm={type === activityType.SLEEP}
      reflectionForm={type === activityType.REFLECTION}
      moodForm={type === activityType.MOOD}
      name={name}
      handleNext={handleNext}
      handleBack={handleBack}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      sleepCheck={type === activityType.SLEEP}
      upload
      notes={type != activityType.REFLECTION}
      defaultValues={defaultValues}
    />,
    <Preview
      sleepPreview={type === activityType.SLEEP}
      reflectionPreview={type === activityType.REFLECTION}
      moodPreview={type === activityType.MOOD}
      question={methods.getValues(t('question'))}
      handleBack={handleBack}
      selectedValues={selectedValues}
      notes={methods.getValues(t('notes'))}
      upload={methods.getValues(t('upload'))}
      sleep={methods.getValues(t('sleep'))}
      illustration={iconType[type]}
    />,
  ];

  return (
    <FormProvider {...methods}>
      <form>
        <Steps
          step={step}
          handleSubmit={
            type === activityType.REFLECTION ? onSubmit : handleSubmit
          }
          handleClose={closeModal}
          steps={
            type === activityType.REFLECTION
              ? [...reflectionSteps, ...communSteps]
              : communSteps
          }
          buttonVisible={buttonVisible[type]}
          submitText={submitText()}
          cancelText={cancelText()}
          handleNext={handleNext}
          displayPreview={displayPreview()}
          loading={loading}
        />
      </form>
    </FormProvider>
  );
}

export default SleepModal;
