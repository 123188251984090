import React, { useCallback, useContext, useState } from 'react';

import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useJoinVideoRoomWithPatient from '@eggmed/graphql-client/operations/videoRoomOperations/useJoinVideoRoomWithPatient';
import DraggableVideoMeet from 'pages/AppointmentPage/DraggableVideoMeet';
import useDoctorWaitingRoomActions from 'pages/DoctorMeetingWaitingPage/state/useDoctorWaitingRoomActions';
import { useIsMobileSubscription } from '@eggmed/graphql-client/operations/videoRoomOperations/usePatientJoinedWaitingRoom';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/AppointmentPage/AppointmentPage';
import MeetingTesting from 'components/MeetingTesting';
import { Rnd } from 'react-rnd';
import Box from '@material-ui/core/Box';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation } from 'react-router-dom';

export const Context = React.createContext<any | undefined>(undefined);
interface ISizeState {
  width: number | string;
  height: number | string;
  x: number;
  y: number;
}
export default function MeetingContext({
  children,
}: {
  children: JSX.Element;
}) {
  const [isRunning, setIsRunning] = React.useState(false);
  const [callRunning, setCallRunning] = React.useState(false);
  const width = 400;
  const height = 280;
  const { width: wWidth, height: wHeight } = useWindowSize();
  const [state, setState] = useState<ISizeState>({
    width,
    height,
    x: 50,
    y: wHeight - (height + 150),
  });
  function startTimer() {
    setIsRunning(true);
  }
  function stopTimer() {
    setIsRunning(false);
  }
  const url = new URL(window.location.href);
  const patientId = url.pathname.split('/').pop();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('event');

  const { data: appointment, loading } = useQuery(GET_PATIENT, {
    variables: { patientId, appointmentId: redirectStatus },
  });
  const storedTimers = JSON.parse(localStorage.getItem('timers')) || [];
  const storedTimer = storedTimers.find(
    (timer) => timer.appointmentId === appointment?.appointment?._id
  );
  const [time, setTime] = React.useState(storedTimer ? storedTimer.timer : 0);
  React.useEffect(() => {
    setState({
      width,
      height,
      x: 50,
      y: window.innerHeight - (height + 25),
    });
  }, [wWidth, wHeight]);
  React.useEffect(() => {
    if (appointment?.appointment?._id) {
      setTime(storedTimer ? storedTimer.timer : 0);
    }
  }, [appointment]);
  React.useEffect(() => {
    let intervalId;

    // Retrieve the previous timers from localStorage if available
    const storedTimers = JSON.parse(localStorage.getItem('timers')) || [];

    // Find the timer for the current appointment
    const storedTimer = storedTimers.find(
      (timer) => timer.appointmentId === appointment?.appointment?._id
    );

    if (
      appointment?.appointment?.isStarted &&
      appointment?.appointment?.isEnded === false
    ) {
      if (storedTimer) {
        setTime(storedTimer.timer);
      } else {
        setTime(0);
      }
      localStorage.setItem(
        `Appointment_${appointment?.appointment?._id}`,
        appointment?.appointment?.isStarted
      );
    }

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime + 1;
          // Update or add the new timer object for this appointment
          const updatedTimers = storedTimers.map((timer) => {
            if (timer.appointmentId === appointment?.appointment?._id) {
              return {
                appointmentId: appointment?.appointment?._id,
                timer: newTime,
              };
            }
            return timer;
          });

          // If no timer object exists, add a new one
          if (!storedTimer) {
            updatedTimers.push({
              appointmentId: appointment?.appointment?._id,
              timer: newTime,
            });
          }

          // Store the updated timers array in localStorage
          localStorage.setItem('timers', JSON.stringify(updatedTimers));

          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);
  const [encryptionKey, setEncryptionKey] = useState('');
  const [callAvailable, setCallAvailable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const {
    handleJoinVideoRoomWithPatient,
    data: joinVideoRoomWithPatientData,
    loading: joiningVideoRoomLoading,
  } = useJoinVideoRoomWithPatient();

  const { doctor } = useAuth();
  const mediaDevice = React.useRef<any>();

  const endCallAction = useCallback(() => {
    setCallAvailable(false);
    setCallRunning(false);
    stopTimer();
    //mediaDevice.current.stop();
  }, []);

  const { roomId } = useDoctorWaitingRoomActions({
    doctorId: doctor?._id,
    roomSlug: doctor?.username,
    handleSetWaitingMembers: () => {},
  });

  return (
    <Context.Provider
      value={{
        endCallAction,
        encryptionKey,
        setEncryptionKey,
        setCallAvailable,
        callAvailable,
        handleJoinVideoRoomWithPatient,
        joiningVideoRoomLoading,
        setIsMobile,
        isRunning,
        setIsRunning,
        startTimer,
        stopTimer,
        callRunning,
        setCallRunning,
        time,
        appointment,
      }}
    >
      {callAvailable &&
        joinVideoRoomWithPatientData?.joinVideoRoomWithPatient &&
        appointment?.appointment?.locationLink && (
          <div style={{ position: 'absolute', zIndex: 100000000 }}>
            <DraggableVideoMeet
              isMobile={isMobile}
              height={415}
              width={465}
              isWide={false}
              roomId={roomId}
              endCallAction={endCallAction}
              encryptionKey={encryptionKey}
              mediaDevice={mediaDevice}
            />
          </div>
        )}
      {!appointment?.appointment?.isEnded &&
        !appointment?.appointment?.isStarted &&
        !callAvailable &&
        redirectStatus &&
        appointment?.appointment?.locationLink &&
        !loading && (
          <Box>
            <Rnd
              size={{ width: state.width, height: state.height }}
              onResize={(e, direction, ref, delta, position) => {
                setState({
                  width: ref.offsetWidth,
                  height: ref.offsetHeight,
                  ...position,
                });
              }}
              position={{ x: state.x, y: state.y }}
              onDragStop={(e, d) => {
                setState({
                  x: d.x,
                  y: d.y,
                  height: state.height,
                  width: state.width,
                });
              }}
              style={{
                zIndex: 10000,
                position: 'absolute', // Ensure the position is absolute
              }}
              bounds="window"
            >
              <MeetingTesting isStarted={true} isDoctor />
            </Rnd>
          </Box>
        )}
      {children}
    </Context.Provider>
  );
}

export function useProvider(): any {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useMeetingProvider must be used within a MeetingProvider');
  }

  return context;
}
