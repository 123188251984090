import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    borderRadius: '8px',
    padding: '0',
    color: '#333',
    overflow: 'hidden',
  },
  initRoot: {
    background: 'white',
    borderRadius: '8px',
    color: '#333',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '25px 70px 20px',
  },
  contentWrapper: {
    maxWidth: '280px',
  },
  textWrapper: {
    paddingTop: '10px',
    paddingInline: '15px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    color: '#344054',
    marginTop: '5px',
    marginBottom: '10px',
  },
  description: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    color: '#344054',
  },
  bold: {
    fontWeight: 700,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '15px',
  },
  steps: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    color: '#98A2B3',
  },
  btns: {
    display: 'flex',
    gap: '10px',
  },
  nextBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    background: 'none',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#147AF3',
  },
  closeBtn: {
    background: 'none',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    position: 'absolute',
    right: '9px',
    top: '10px',
  },
  initTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '19px',
    fontWeight: 600,
    color: '#344054',
    marginBottom: '20px',
  },
  initP: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
    color: '#344054',
    maxWidth: '320px',
    marginTop: '0',
    marginBottom: '30px',
  },
  vBtns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    width: 'fit-content',
    margin: '10px auto',
  },
  startBtn: {
    background: '#147AF3',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
    borderRadius: '8px',
    width: '100%',
    height: '36px',
    minWidth: '85px',
  },
  skipBtn: {
    background: 'none',
    border: 'none',
    padding: '4px 12px',
    cursor: 'pointer',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#147AF3',
    borderRadius: '8px',
    width: '100%',
  },
  animate: {
    background: '#D0F8AB',
  },
}));

export default useStyles;
