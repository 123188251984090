import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  time: {
    color: '#7a90ab',
    fontSize: '13px',
  },
  messagingTxt: {
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    color: '#101828',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  item: {
    borderLeft: '6px solid transparent',
  },
  itemIsSelected: {
    background: '#D4E9FF',
    borderLeft: '6px solid #3794FC',
  },
  Badge: {
    left: '-6px',
    top: '10px',
  },
  Avatar: {
    width: '55px',
    height: '55px',
    borderRadius: '40%',
    marginRight: '5px',
  },
  conversationListTabs: {
    background: '#000',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSelectedText: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: theme.palette.black.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  tab: {
    // background: '#F5F7FB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  addMessageBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    background: '#147AF3',
    borderRadius: '8px',
    boxShadow: 'none',
    color: theme.palette.white.main,
    padding: '10px 12px',

    '&:hover': {
      boxShadow: 'none',
      background: '#0265DC',

      [theme.breakpoints.down('lg')]: {
        background: 'transparent',
        padding: 0,
        minWidth: 'unset',
        '& svg path': {
          stroke: '#0265DC',
        },
      },
    },

    [theme.breakpoints.down('lg')]: {
      background: 'transparent',
      padding: 0,
      minWidth: 'unset',
      '& svg path': {
        stroke: '#0265DC',
      },
    },
  },
  addMessageBtnTextWrapper: {
    marginLeft: '6px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  addMessageBtnText: {
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
  },
  Chip: {
    color: '#9B51E0',
    backgroundColor: 'rgba(143, 97, 241, 0.2)',
    [theme.breakpoints.down('sm')]: {
      height: '22px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '500',
      textTransform: 'lowercase',
    },
  },
  centred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  conversationListContainer: {
    height: '100%',
  },
  TitleItemConversation: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.black.dark,
    marginLeft: '10px',
    textTransform: 'capitalize',
  },
  TitleItemConversationUnread: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: theme.palette.black.dark,
    marginLeft: '10px',
  },
  headerRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '15px',
  },
  SearchInput: {
    margin: '10px 15px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '8px',
      paddingRight: '20px',
    },
  },
  MoreHorizIcon: {
    cursor: 'pointer',
    fontSize: '30px',
    color: '#BCC6D3',
  },
  noWarpText: {
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    marginLeft: '10px',
  },
  ListItemTextConversationItem: {
    padding: '2px',
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  searchItemListCardContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    height: '4rem',
    padding: '8px 4px',
    gap: '10px',
  },
  secondaryTextSearchItemListCard: {
    fontSize: '15px',
    color: theme.palette.gray.main,
  },
  icon: {
    padding: '0px 5px',
  },
}));

export default useStyles;
