import React from 'react';
import dayjs from 'dayjs';
import { Box, List, makeStyles, Typography } from '@material-ui/core';

import { log } from 'config';
import RoomAttended from './RoomAttended';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F8FBFC',
    borderRadius: theme.typography.pxToRem(8),
    border: `1px solid ${theme.palette.gray.light}`,
    height: '70vh',
    maxHeight: '70vh',
    [theme.breakpoints.down('sm')]: {
      height: '30vh',
      maxHeight: '70vh',
    },
  },
}));

interface IWaitingRoomProps {
  patients: any[];
  handleJoinRoom: (id: string, appointmentId: string) => void;
  handleLeave: (id: string) => void;
}

export default function WaitingRoom({
  patients,
  handleJoinRoom,
  handleLeave,
}: IWaitingRoomProps) {
  const classes = useStyles();
  log('Patients', patients);
  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.root}>
        <List dense>
          {patients &&
            patients.length > 0 &&
            patients.map((patient: any) => {
              const appointmentStartDate = dayjs(patient?.startDate);
              const joinedWaitingRoomDate = dayjs(
                patient?.joinedWaitingRoomDate
              );
              const difference = joinedWaitingRoomDate.diff(
                appointmentStartDate,
                'minutes',
                false
              );
              let waitingText = '';
              if (difference < 0) {
                waitingText = 'Early';
              } else {
                waitingText = `Waiting < ${difference}m`;
              }
              return (
                <RoomAttended
                  primaryText={`${patient.firstname} ${patient.lastname}`}
                  picture={patient.picture}
                  secondaryText={waitingText}
                  handleClick={() => {
                    handleJoinRoom(patient?._id, patient?.appointmentId);
                  }}
                  handleRemove={() => {
                    handleLeave(patient?._id);
                  }}
                />
              );
            })}
        </List>
      </Box>
    </Box>
  );
}
