import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const AreaInput = withStyles((theme) => ({
  root: {
    width: '100%',
    // height: '50px',
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    '& .Mui-focused': {
      border: '1px solid #082CAF',
      borderRadius: '8px',
    },
    '& .Mui-error': {
      border: '1px solid red',
    },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },
    '& .MuiFormLabel-root': {
      // color: '#B3BFD0',
    },
    '& .MuiFilledInput-multiline': {
      padding: '16px 12px 12px',
    },
    '& .MuiFilledInput-input': {
      fontFamily: 'Inter, sans-serif',
    },
    '& .MuiFilledInput-underline:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #3f50b5',
    },
    '& .MuiFilledInput-underline:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '0px solid #3f50b5',
    },
    // '& .MuiInputLabel-formControl': {
    //   top: '-10px',
    // },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
  },
}))(TextField);

export default AreaInput;
