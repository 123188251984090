import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
    color: '#101828',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '1rem',
  },
  p: {
    width: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'normale',
    fontSize: '17px',
    lineHeight: '39px',
    color: '#425364',
    [theme.breakpoints.only('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  emailNotification: {
    display: 'flex',
    alignItems: 'center',
    gap: '7rem',
  },

  radio: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  channel: {
    display: 'flex',
    maxWidth: '100%',
    boxSizing: 'border-box',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '6px',
    minHeight: '67px',
    paddingInline: '20px',
    paddingBottom: '3px',
  },
  channels: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#425364',
    marginBottom: '1rem',
  },
  eventName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '20px',
    color: '#101828',
  },
  eventDesc: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#475467',
    marginTop: '3px',
  },
}));

export default useStyles;
