import adapter from 'webrtc-adapter';

export function checkInsertableSupport() {
  const supportsInsertableStreams = !!(RTCRtpSender.prototype as any)
    .createEncodedStreams;
  let supportsTransferableStreams = false;
  try {
    const stream: any = new ReadableStream();
    window.postMessage(stream, '*', [stream]);
    supportsTransferableStreams = true;
  } catch (e) {
    console.log('Transferable streams are not supported.');
    // alert('not supported');
    return true;
  }
  if (!(supportsInsertableStreams && supportsTransferableStreams)) {
    if (adapter.browserDetails.browser === 'chrome') {
      console.log(
        ' Try with Enable experimental Web Platform features enabled from chrome://flags.'
      );
      alert('not supported');
    }
    // alert('not supported');
    console.log('Your browser does not support Insertable Streams.');
    return true;
  }
  return true;
}
