import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/';
import Input from 'components/ui/Inputs';
import ScrollBar from 'components/ui/ScrollBar';
import { generateArrayOfYears, months } from '@eggmed/common/utils/objectUtils';
import * as yup from 'yup';
import EditButtons from 'components/EditButtons';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import usePaymentIntent from '@eggmed/graphql-client/operations/appointmentsOperations/usePaymentIntent';
import { GET_DOCTOR_SUBSCRIPTION } from '@eggmed/graphql-client/operations/subscriptionOperations/useGetSubscription';
import { useHistory } from 'react-router-dom';

export const SchemaBilling = yup.object().shape({
  nameOnCard: yup.string().required('Name On Card is requried field'),
  cardNumber: yup
    .string()
    .min(16)
    .max(16)
    .required('Card Number must be a number'),
  month: yup.string().required('Month is requried field'),
  year: yup.string().required('Year is requried field'),
  securityCode: yup.string().required('Security Code is requried field'),
});

interface Props {
  handleBack: () => void;
  amount: number;
  type: string;
  handleClose: () => void;
  doctorId: string;
}

function AddPaymentModal({
  handleBack,
  amount,
  type,
  handleClose,
  doctorId,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { register, control, errors, handleSubmit } = useForm({
    resolver: yupResolver(SchemaBilling),
  });
  const { paymentIntent, loading: loadingPaymentIntent } = usePaymentIntent();
  const handleEdit = async (paymentInput) => {
    try {
      await paymentIntent({
        variables: {
          paymentInput,
        },
        refetchQueries: [
          { query: GET_DOCTOR_SUBSCRIPTION, variables: { doctor: doctorId } },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const onSubmit = async (data) => {
    const paymentInput = {
      number: data.cardNumber,
      exp_month: parseInt(data.month, 10),
      exp_year: parseInt(data.year, 10),
      cvc: data.securityCode,
      amount: Number(amount) * 100,
      doctorId: doctorId,
      plan: type,
    };
    await handleEdit(paymentInput);
    handleClose();
    history.push({ search: '?redirect_status=succeeded' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.PaymentBox}>
        <Box
          style={{ paddingInline: '3rem', paddingTop: '2rem', gap: '1rem' }}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={handleBack}
            data-cy="back-button"
          >
            <BackArrowIcon />
            <Typography className={classes.backText}>Back</Typography>
          </Box>
          <Typography className={classes.modalTitle}>
            Add payment method
          </Typography>
        </Box>
        <ScrollBar width="100%" maxHeight="60vh" overflow="hidden">
          <Box className={classes.scrollBox}>
            <InputLabel
              className={classes.label1}
              style={{ marginTop: '28px' }}
            >
              Name On Card
            </InputLabel>
            <Input
              className={classes.inputMui2}
              labelName="Name On Card"
              type="text"
              name="nameOnCard"
              variant="filled"
              inputRef={register}
              control={control}
              helperText={
                errors.nameOnCard && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.nameOnCard?.message}
                  </FormHelperText>
                )
              }
            />
            <InputLabel
              className={classes.label1}
              style={{ marginTop: '28px' }}
            >
              Card Number
            </InputLabel>
            <Input
              className={classes.inputMui2}
              labelName="Card Number"
              type="number"
              name="cardNumber"
              variant="filled"
              inputRef={register}
              control={control}
              helperText={
                errors.cardNumber && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.cardNumber?.message}
                  </FormHelperText>
                )
              }
            />
            <InputLabel
              className={classes.label1}
              style={{ marginTop: '28px' }}
            >
              Exp.Month
            </InputLabel>
            <Input
              className={classes.inputMui2}
              type="select"
              name="month"
              inputRef={register}
              variant="filled"
              options={months}
              control={control}
              helperText={
                errors.month && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.month?.message}
                  </FormHelperText>
                )
              }
            />
            <InputLabel
              className={classes.label1}
              style={{ marginTop: '28px' }}
            >
              Exp.Year
            </InputLabel>
            <Input
              className={classes.inputMui2}
              type="select"
              name="year"
              inputRef={register}
              variant="filled"
              options={generateArrayOfYears()}
              control={control}
              helperText={
                errors.year && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.year?.message}
                  </FormHelperText>
                )
              }
            />
            <InputLabel
              className={classes.label1}
              style={{ marginTop: '28px' }}
            >
              Security Code/CVV
            </InputLabel>
            <Input
              className={classes.mediumLongInputNameOncard}
              type="text"
              name="securityCode"
              inputRef={register}
              variant="filled"
              control={control}
              helperText={
                errors.securityCode && (
                  <FormHelperText className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    {errors?.securityCode?.message}
                  </FormHelperText>
                )
              }
            />
          </Box>
        </ScrollBar>
        <Box
          height="10vh"
          display="flex"
          alignItems="center"
          width="100%"
          borderTop="0.5px solid rgba(161, 176, 196, 1)"
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={false}
            type="submit"
            submitText="Pay"
            loading={loadingPaymentIntent}
            handleOpen={(e) => {
              e.preventDefault();
              handleBack();
            }}
          />
        </Box>
      </Box>
    </form>
  );
}

export default AddPaymentModal;
