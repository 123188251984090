import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menubar: {
    position: 'absolute',
    right: -23,
    top: 2,
    transition: 'all 0.3s',
    transform: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      isSidebarExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    '&:hover': {
      background: 'transparent',
    },
  },
  root: {
    position: 'sticky',
    top: '0',
    height: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRight: '0.5px solid #EAECF0',
    [theme.breakpoints.only('xl')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '50px',
      paddingBottom: '15px',
      // justifyContent: 'center',
      // alignContent: 'center',
      // alignItems: 'center',
    },
    [theme.breakpoints.only('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '50px',
      paddingBottom: '15px',
      // justifyContent: 'center',
      // alignContent: 'center',
      // alignItems: 'center',
    },
    [theme.breakpoints.only('md')]: {
      width: '8.11vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      position: 'fixed',
      overflowY: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      position: 'fixed',
      overflowY: 'auto',
    },
  },
  sideBar: {
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      // '&:last-child': {
      //   BorderTop: 'px solid white'
      // },
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      // '&:last-child': {
      //   BorderTop: 'px solid white'
      // },
    },
  },
  boxText: {
    width: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      !isSidebarExpanded ? '80px' : '0px',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      left: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      left: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  logoImgTag: {
    width: '100%',
  },
  logoPicTag: {
    position: 'absolute',
    width: '40vw',
    top: '1vh',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      width: '25vw',
    },

    [theme.breakpoints.up('md')]: {
      left: '1.55vw',
      width: '5vw',
      marginTop: '0.5vw',
      marginLeft: 'unset',
      zIndex: 1000000000,
    },

    [theme.breakpoints.up('lg')]: {
      left: '0.55vw',
    },
  },
  text: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    color: '#182230',
    fontWeight: 600,
    // maxWidth: '6.11vw',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    textDecoration: 'none',
    borderRadius: '6px',
    fontFamily: 'Inter, sans-serif',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#f7f7f5',
    },
    // textAlign: 'center',
    [theme.breakpoints.only('xl')]: {
      // textAlign: 'center',
      // padding: `${theme.spacing(2)}px 0px`,
      padding: `${theme.spacing(1.3)}px 10px`,
      marginInline: theme.spacing(1.4),
      marginBlock: theme.spacing(0.5),
    },
    [theme.breakpoints.only('lg')]: {
      // textAlign: 'center',
      // padding: `${theme.spacing(0.85)}px 0px`,
      padding: `${theme.spacing(1.3)}px 10px`,
      marginInline: theme.spacing(1.4),
      marginBlock: theme.spacing(0.5),
    },
    [theme.breakpoints.only('md')]: {
      // textAlign: 'center',
      padding: `${theme.spacing(1)}px 0px`,
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '4vh',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '4vh',
    },
  },
  linkSettings: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.5vh',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: '15px',
    textDecoration: 'none',
    // textAlign: 'center',
    [theme.breakpoints.only('xl')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('lg')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  linkHover: {
    // '&:hover': {
    //   backgroundColor: '#f7f7f5',
    //   borderRadius: '6px',
    // },
  },
  links: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      !isSidebarExpanded ? '162px' : '71px',
    transform: 'translate(0)',
    transition: 'all 0.3s ease',
  },
  icon: {
    width: '2vw',
    [theme.breakpoints.only('sm')]: {
      width: '31px',
      height: '31px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '31px',
      height: '31px',
    },
  },
  iconSettings: {
    width: '2vw',
    [theme.breakpoints.only('sm')]: {
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '24px',
      height: '24px',
    },
  },
  space: {
    // paddingTop: '10vw',
  },
  selected: {
    backgroundColor: '#ecf3f4',
    // [theme.breakpoints.only('sm')]: {
    //   display: 'none',
    //   // width: '32.8vw',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   display: 'none',
    //   // width: '32.8vw',
    // },
  },
  divider: {
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '7.9rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '7.9rem',
    },
  },
  dividerSettings: {
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '38.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '40rem',
    },
  },
}));
