import { gql, useMutation } from '@apollo/client';

const CREATE_QUESTIONNAIRE = gql`
  mutation createQuestionnaire($questionnaireInput: QuestionnaireInput) {
    createQuestionnaire(questionnaireInput: $questionnaireInput) {
      _id
    }
  }
`;

const useCreateQuestionnaire = () => {
  return useMutation(CREATE_QUESTIONNAIRE);
};

export default useCreateQuestionnaire;
