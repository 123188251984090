import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';

const SEND_RTC_OFFER_MUTATION = gql`
  mutation SendRtcOffer(
    $offer: SessionDescriptionInput!
    $recipientId: String!
    $roomId: String!
  ) {
    sendRtcOffer(
      args: { offer: $offer, recipientId: $recipientId }
      roomId: $roomId
    ) {
      success
    }
  }
`;

const useSendRtcOffer = () => {
  const [sendRtcOffer] = useMutation<unknown, any>(SEND_RTC_OFFER_MUTATION);

  async function handleSendRtcOffer(
    offer: any,
    recipientId: string,
    roomId: string
  ) {
    try {
      return await sendRtcOffer({ variables: { offer, recipientId, roomId } });
    } catch (e) {
      log('error', e);
      return e;
    }
  }

  return handleSendRtcOffer;
};

export default useSendRtcOffer;
