import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import InputBlock from '../Payment/InputBlock';
import { Itags } from './types';
import { colors } from './Constant';
import TagSelect from './TagSelect';
import { useTranslation } from 'react-i18next';

interface Itag {
  currentRow: Itags;
  editMode: boolean;
  selectedColor: string;
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
}
const TagModal = ({
  currentRow,
  editMode,
  selectedColor,
  setSelectedColor,
}: Itag) => {
  const { t } = useTranslation();
  const { register, control, errors } = useFormContext();
  const commonProps = { register, control, errors };
  return (
    <Box
      sx={{
        width: {
          xs: '50vw',
          lg: '50vw',
          xl: '37vw',
        },
      }}
    >
      <Box px="2rem" py={1} display="flex" gridGap="1rem">
        <Box flex={1}>
          <InputBlock
            label={t('Tag')}
            {...commonProps}
            name="tag"
            currentRow={currentRow}
            editMode={editMode}
          />
        </Box>
        <TagSelect
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          values={colors}
        />
      </Box>
    </Box>
  );
};

export default TagModal;
