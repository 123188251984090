import React from 'react';
import useStyles from '../styles';
import Lottie from 'lottie-react';
import AnimateSchedule from '../animations/schedule.json';

export const Schedule = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.animate}>
        <Lottie
          animationData={AnimateSchedule}
          loop={true}
          style={{ width: '280px', height: '105px' }}
        />
      </div>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>Schedule</h3>
        <p className={classes.description}>Create sessions and events.</p>
        <p className={classes.description}>
          When they overlap, use the{' '}
          <span className={classes.bold}>conflicts</span> button to detect and
          resolve scheduling issues.
        </p>
      </div>
    </div>
  );
};
