import React from 'react';
import ReactDOM from 'react-dom';
import MultiChoice from '../components/MultiChoice';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
}

export default class Date {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };

  static get toolbox() {
    return {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>                      
      `,
      title: 'Date',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    const defaultQuestion = {
      question: 'Untitled question',
      type: 'Date',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };

  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };

  private updateDom() {
    if (this.nodes.holder) {
      ReactDOM.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          getBlockIndex={this.getBlockIndex}
        />,
        this.nodes.holder
      );
    }
  }
  // private setQuestionssss = (
  //   updateFn: (questions: Array<any>) => Array<any>
  // ) => {
  //   // Check if the input is a function
  //   if (typeof updateFn === 'function') {
  //     // Call the function with the current questions to get the updated questions
  //     const updatedQuestions = updateFn(this.data.questions);
  //     console.log(updatedQuestions, 'updatedQuestions');
  //     // Update the data with the new questions array
  //     this.data = {
  //       ...this.data,
  //       questions: updatedQuestions,
  //     };

  //     console.log(updatedQuestions, 'updated questions');
  //   } else {
  //     console.error('Expected a function that returns an array of questions');
  //   }
  // };

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    // const onDataChange = (newData: TimelineData) => {
    //   this.data = {
    //     ...newData,
    //   };
    // };

    ReactDOM.render(
      // <MultiChoice
      //   onDataChange={onDataChange}
      //   readOnly={this.readOnly}
      //   data={this.data}
      // />,
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion}
        question={this.data.question}
        isNotes={false} // You can modify this based on your needs
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
      />,
      rootNode
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
}
