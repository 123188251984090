import { gql, useMutation } from '@apollo/client';
import { log } from 'config/log';
import {
  editPrescriptionDrug,
  editPrescriptionDrugVariables,
} from './__generated__/editPrescriptionDrug';

const EDIT_PRESCRIPTION_DRUG = gql`
  mutation editPrescriptionDrug(
    $prescriptionDrugInput: PrescriptionDrugsInput!
    $prescriptionId: ID!
    $prescriptionDrugId: ID!
  ) {
    editPrescriptionDrug(
      prescriptionDrugInput: $prescriptionDrugInput
      prescriptionId: $prescriptionId
      prescriptionDrugId: $prescriptionDrugId
    ) {
      _id
      appointmentDate
      startDate
      endDate
      drugs {
        _id
        name
        type
        dosage
        duration
        frequency
        advice
        durationType
      }
    }
  }
`;

export default function useEditPrescriptionDrug() {
  const [editPrescriptionDrugs, result] = useMutation<
    editPrescriptionDrug,
    editPrescriptionDrugVariables
  >(EDIT_PRESCRIPTION_DRUG, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          appointmentPrescription() {
            try {
              return data?.editPrescriptionDrug;
            } catch (e) {
              return log('error', e);
            }
          },
        },
      });
    },
  });

  return { editPrescriptionDrugs, ...result };
}
