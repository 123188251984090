import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import Header from './Header';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import NotificationProvider from 'pages/PatientNotificationPage/Provider';
import SideBar from './sidebar';
import SchedulePageProvider from 'pages/SchedulePage/state/SchedulePageProvider';
import { HeaderPageNameProvider } from 'components/Layout/HeaderPageNameConext';

interface LayoutProps {
  children: ReactNode;
  isMargin?: boolean;
}
const useStyles = makeStyles<Theme>((theme) => ({
  main: {
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    // marginLeft: '212px',
    width: '100%',
    paddingInline: ({ isMargin }: { isMargin: boolean }) =>
      isMargin ? theme.spacing(4) : 0,
    paddingTop: ({ isMargin }: { isMargin: boolean }) =>
      isMargin ? theme.spacing(2) : 0,
    backgroundColor: ({ isBackgroundGrey }: { isBackgroundGrey: boolean }) =>
      isBackgroundGrey ? '#F4F2EE' : 'white',
    transition: 'margin-left 0.3s ease',
    [theme.breakpoints.only('xl')]: {
      marginLeft: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? '162px' : '71px',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? '162px' : '71px',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'sticky',
    top: '0',
  },
  sidebar: {
    zIndex: 100000,
    position: 'sticky',
    [theme.breakpoints.only('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  root: {},
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'sticky',
    top: '0',
  },
  illustration: {
    minHeight: 'calc(100vh - 110px)',
  },
  fixedHeader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  fixedSidebar: {
    position: 'fixed',
    top: '70px',
    left: 0,
    height: '100%',
    zIndex: 2,
    borderRight: '1px solid #EAECF0',
  },
  scrollableContent: {
    marginLeft: theme.spacing(30), // Adjust based on your sidebar width
    paddingTop: theme.spacing(10), // Adjust based on your header height
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: 'auto',
    height: '100vh',
    boxSizing: 'border-box',
  },
}));

const PatientLayout = ({ children, isMargin = true }: LayoutProps) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(false); // State for sidebar expansion

  const classes = useStyles({
    isSidebarExpanded,
    isMargin,
    isBackgroundGrey: true,
  });
  const { user: authData, loading, patient, role } = useAuth();

  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === 'undefined'
  )
    return <Redirect to="/login" />;
  if (authData.user?.role === 'staff') {
    return <Redirect to="/doctor/dashboard" />;
  }
  if (authData?.doctorId || role === 'doctor' || role === 'staff') {
    return <Redirect to="/doctor/dashboard" />;
  }

  return (
    <HeaderPageNameProvider>
      <SchedulePageProvider>
        <Grid container style={{ flexWrap: 'nowrap' }}>
          <Box className={`${classes.fixedHeader} ${classes.content}`}>
            <Box className={classes.header}>
              <NotificationProvider>
                <Header {...patient} loading={loading} />
              </NotificationProvider>
            </Box>

            <Box display="flex" flexWrap="no-wrap">
              <Box className={`${classes.fixedSidebar} ${classes.sidebar}`}>
                <SideBar
                  sideRespons="normal"
                  isSidebarExpanded={isSidebarExpanded}
                  setIsSidebarExpanded={setIsSidebarExpanded}
                />
              </Box>
              <Box className={classes.main} px={4}>
                {children}
              </Box>
            </Box>
          </Box>
        </Grid>
      </SchedulePageProvider>
    </HeaderPageNameProvider>
  );
};

export default PatientLayout;
