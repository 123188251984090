import React from 'react';
import { FieldValues, FieldError, Control, DeepMap } from 'react-hook-form';
import clsx from 'clsx';
import { Box, Grid, Divider, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { convertMsToDate } from 'utils';
import { ReactComponent as DrugIcon } from 'assets/icons/green/drugIcon.svg';
import { ReactComponent as TypeIcon } from 'assets/icons/green/drugTypeIcon.svg';
import { ReactComponent as DosageIcon } from 'assets/icons/green/drugDosage.svg';
import { ReactComponent as DurationIcon } from 'assets/icons/green/duration.svg';
import { ReactComponent as FrequencyIcon } from 'assets/icons/green/frequency.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/green/sound.svg';
import { ReactComponent as ToTakeIcon } from 'assets/icons/green/drugTake.svg';
import { ReactComponent as DoctorIcon } from 'assets/icons/green/doctorIcon.svg';
import { ReactComponent as PatientIcon } from 'assets/icons/green/patientIcon.svg';
import { ReactComponent as BirthIcon } from 'assets/icons/green/birthIcon.svg';
import { ReactComponent as Illness } from 'assets/icons/green/Illness.svg';
import { ReactComponent as Examination } from 'assets/icons/green/Examination.svg';
import { ReactComponent as Blood } from 'assets/icons/green/blood.svg';
import { ReactComponent as Pulse } from 'assets/icons/green/Pulse.svg';

import { ReactComponent as MetricTypeIcon } from 'assets/icons/green/metricType.svg';
import { ReactComponent as DurationTypeIcon } from 'assets/icons/green/durationType.svg';
import { ReactComponent as DocIcon } from 'assets/icons/green/doc.svg';
import useStyles from './styles';
import InputBlock from 'pages/AppointmentPage/PrescriptionForm/InputBlock';
import Errors from 'pages/AppointmentPage/PrescriptionForm/MedicationForm/Errors';
import { useFormContext } from 'react-hook-form';
import { IDrug } from '.';
import { useTranslation } from 'react-i18next';
interface IDrugModalProps {
  defaultValues: IDrug;
}
export default function DrugModal({ defaultValues }: IDrugModalProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, control, errors } = useFormContext();
  const sinceDate = defaultValues ? new Date(defaultValues.since) : new Date();
  return (
    <Box paddingX="2rem" paddingTop="1.5rem" paddingBottom="2rem">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            // LabelIcon={DrugIcon}
            iconClassName={classes.iconClassName}
            label={t('Drug name')}
            register={register}
            control={control}
            type="text"
            name="name"
            defaultValue={defaultValues && defaultValues?.name}
            errors={errors}
            data-cy="DrugName"
          />
          <Errors errors={errors} name="name" array="drugs" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            // LabelIcon={TypeIcon}
            iconClassName={classes.iconClassName}
            label={t('Type')}
            register={register}
            control={control}
            type="select"
            options={[
              'pill',
              'liquid',
              'injection',
              'powder',
              'cream',
              'tablet',
              'other',
            ]}
            name="type"
            defaultValue={defaultValues && defaultValues?.type}
            errors={errors}
          />
          <Errors errors={errors} name="type" array="drugs" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            // LabelIcon={DosageIcon}
            iconClassName={classes.iconClassName}
            label={t('Dosage')}
            register={register}
            control={control}
            type="text"
            name="dosage"
            defaultValue={defaultValues && defaultValues?.dosage}
            errors={errors}
            data-cy="concentration"
          />
          <Errors errors={errors} name="dosage" array="drugs" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            // LabelIcon={FrequencyIcon}
            iconClassName={classes.iconClassName}
            label={t('Frequency')}
            register={register}
            control={control}
            type="text"
            name="frequency"
            defaultValue={defaultValues && defaultValues?.frequency}
            errors={errors}
            data-cy="frequency"
          />
          <Errors errors={errors} name="frequency" array="drugs" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <InputBlock
            // LabelIcon={DurationIcon}
            iconClassName={classes.iconClassName}
            label={t('Since')}
            register={register}
            control={control}
            type="date"
            name="since"
            defaultValue={sinceDate.toString()}
            errors={errors}
            data-cy="duration"
          />
          <Errors errors={errors} name="since" array="drugs" />
        </Grid>
      </Grid>
    </Box>
  );
}
