import { gql, useMutation } from '@apollo/client';
import {
  deleteTaskAttachment,
  deleteTaskAttachmentVariables,
} from './__generated__/deleteTaskAttachment';

const DELETE_TASK = gql`
  mutation deleteTaskAttachment($taskId: ID!, $url: String) {
    deleteAttachment(taskId: $taskId, url: $url) {
      _id
      attachments {
        createdAt
        url
        fileName
        user {
          firstname
          middlename
          lastname
          picture
          role
          _id
        }
      }
    }
  }
`;

export default function useDeleteTaskAttachment() {
  const [deleteTaskAttachmentCall, result] = useMutation<
    deleteTaskAttachment,
    deleteTaskAttachmentVariables
  >(DELETE_TASK, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          tasks(tasks) {
            return tasks.map((task: any) => {
              const taskId = task.__ref.split('Task:')[1];
              if (taskId === data?.deleteAttachment?._id) {
                return {
                  ...task,
                  attachments: data?.deleteAttachment?.attachments,
                };
              }
              return task;
            });
          },
        },
      });
    },
  });
  return { deleteTaskAttachment: deleteTaskAttachmentCall, ...result };
}
