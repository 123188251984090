import React from 'react';
import { IinputBoxProps } from './types';
import useStyles from './style';
import clsx from 'clsx';
import { InputLabel, FormHelperText, Box } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';

export const InputBox = ({
  labelName,
  type,
  name,
  datacy,
  className,
  defaultValue,
  options,
  errors,
  loading,
  ...props
}: IinputBoxProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Skeleton loading={loading}>
        <InputLabel className={clsx(className, classes.label)}>
          {labelName}
        </InputLabel>
      </Skeleton>
      <Skeleton loading={loading}>
        <Input
          className={clsx(classes.input, classes.marginTop)}
          type={type}
          name={name}
          data-cy={datacy}
          defaultValue={defaultValue}
          options={options}
          helperText={
            (errors as any)[name] && (
              <FormHelperText className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {(errors as any)[name].message}
              </FormHelperText>
            )
          }
          {...props}
        />
      </Skeleton>
    </Box>
  );
};
